import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { TEXT_APPROVED, TEXT_KIV, TEXT_NEW, TEXT_REJECTED } from '../../../Text'
import { Row, Col, Card, CardBody, Media } from "reactstrap";

// customCOlor
import { handleStatusStyle } from '../../../store/CustomFunctions';
import Spinner from 'reactstrap/lib/Spinner';

const initialData = {
    labels: [
        "New",
        "Approved",
        "KIV",
        "Rejected"
    ],
    datasets: [
        {
            data: [0, 0, 0, 0],
            backgroundColor: [
                "rgba(235, 239, 242, 1)",
                "rgba(25, 171, 79, 1)",
                'RGBA(74,163,255,1)',
                'rgba(255, 61, 96, 1)',
            ],
            hoverBackgroundColor: [
                "rgba(235, 239, 242, 0.8)",
                "rgba(25, 171, 79, 0.8)",
                'RGBA(74,163,255,0.8)',
                'rgba(255, 61, 96, 0.8)',
            ],
            hoverBorderColor: "rgba(255, 255, 255, 1)"
        }]
};

let options = {
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
    }
}

const ChartApplicationStatus = (props) => {
    const [data, setData] = useState(initialData)

    useEffect(() => {
        let obj = {
            ...initialData,
            datasets: [
                {
                    ...initialData.datasets[0],
                    data: [props.countProcessing, props.countApproved, props.countKiv, props.countRejected]
                }
            ]
        }
        setData(obj)
    }, [props.countProcessing, props.countApproved, props.countKiv, props.countRejected])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">Application Status</p>

                            {props.loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}><Spinner color="primary" size="sm" /></div> :
                                <Pie width={474} height={260} data={data} options={options} />}
                        </Media>
                    </Media>
                </CardBody>
                <CardBody className="border-top py-3">
                    <Row className="text-center mt-3">
                        <Col xs={3}>
                            <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('processing').color }}></i>{TEXT_NEW}</p>

                            {!props.loading ? <h5 className="mb-0">{props.countProcessing}</h5> : <Spinner size='sm' color="primary" />}
                        </Col>
                        <Col xs={3}>
                            <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle(TEXT_APPROVED.toLowerCase()).color }}></i>{TEXT_APPROVED}</p>

                            {!props.loading ? <h5 className="mb-0">{props.countApproved}</h5> : <Spinner size='sm' color="primary" />}
                        </Col>
                        <Col xs={3}>
                            <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle(TEXT_KIV.toLowerCase()).color }}></i>{TEXT_KIV}</p>

                            {!props.loading ? <h5 className="mb-0">{props.countKiv}</h5> : <Spinner size='sm' color="primary" />}
                        </Col>
                        <Col xs={3}>
                            <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle(TEXT_REJECTED.toLowerCase()).color }}></i>{TEXT_REJECTED}</p>

                            {!props.loading ? <h5 className="mb-0">{props.countRejected}</h5> : <Spinner size='sm' color="primary" />}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default ChartApplicationStatus
