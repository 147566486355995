import React, { useCallback, useEffect, useState } from 'react'
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, Input, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Spinner } from "reactstrap";
import { TEXT_PROMOTION } from '../../../Text';
import classnames from 'classnames';
import "../../Templete/Tables/Styles/datatables.scss";
import { initialDataTable } from './tableColumn';
import ServerSidePaginations from '../../../components/Table/ServerSidePaginations';
import moment from 'moment-timezone';
import { get, post } from '../../../helpers/API';


let initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: 'all',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

const contentBody = {
    path: '/store/promotions',
    title: "Promotions",
    backUrl: "/store/promotions",
    filter: "promotion"
}

const Promotion = (props) => {
    const [timer, setTimer] = useState(null);
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [initialParams, setInitialParams] = useState(initialValue)
    const [visibleModalAddPromos, setVisibleModalAddPromos] = useState(false)
    const [formValues, setFormValues] = useState({ title: "" })

    const getPromotions = useCallback((
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords,
    ) => {
        setLoading(true)
        setDataSource(prev => {
            return {
                ...prev,
                rows: [{
                    title: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                }]
            }
        })

        let params = "";
        let url = new URLSearchParams(params);

        url.set('_limit', limit)
        url.set('_start', page === 0 ? page : (page * 10) - 10)
        url.set('_sort', sortBy)

        if (keywords) {
            url.set('title', keywords)
        }

        get(`/promotions/count?${url.toString()}`).then(count => {
            get(`/promotions?${url.toString()}`).then(response => {
                const mapping = response.map(item => {
                    return {
                        title: !item.title ? "-" : item.title,
                        promo: !item.product_promos ? 0 : item.product_promos.length,
                        expiredOn: !item.end_datetime ? "-" : moment(item.end_datetime).format("DD MMM YYYY"),
                        status: <div className={`badge badge-pill ${!item.is_active ? "badge-soft-danger" : "badge-soft-success"} font-size-12`}>{!item.is_active ? 'Inactive ' : "Active"}</div>,
                        clickEvent: () => {
                            if (props.history !== undefined || props.history !== null) {
                                return props.history.push(`${contentBody.path}/${item.id}`)
                            }
                        }
                    }
                })

                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    page,
                    total: count,
                    category,
                    keywords,
                })
                
                setDataSource(prev => {
                    return {
                        ...prev,
                        rows: mapping
                    }
                })
                setLoading(false);
            }).catch((err) => {
                setLoading(false)
            })
        }).catch((err) => {
            setLoading(false)
        })
    }, [props.history])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getPromotions(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                initialValue.keywords,
            )
        }

        return () => {
            mount = true
        }
    }, [getPromotions])

    const handleSubmitPromos = (e) => {
        e.preventDefault()
        const data = formValues

        post(`/promotions`, data).then(response => {
            getPromotions(
                initialParams.sortBy,
                initialParams.orderBy,
                initialParams.limit,
                initialParams.page,
                initialParams.total,
                initialParams.category,
                initialParams.keywords,
            )
            setVisibleModalAddPromos(!visibleModalAddPromos)
        }).catch((err) => { })
    }

    const handleSearching = (value) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                getPromotions(
                    initialParams.sortBy,
                    initialParams.orderBy,
                    initialParams.limit,
                    0,
                    initialParams.total,
                    initialParams.category,
                    value,
                )
            }, 1000)
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h4>{TEXT_PROMOTION}s</h4>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0">
                                    <Nav tabs className="nav-tabs-custom mb-4">
                                        <NavItem>
                                            <NavLink onClick={() => { setActiveTab('1'); }} className={classnames({ active: activeTab === '1' }, "font-weight-bold p-3")}>All</NavLink>
                                        </NavItem>

                                        <div className='constainer-box-actions'>

                                            <div className="custom-searching-box mr-2">
                                                <Input
                                                    type="text"
                                                    placeholder="Search..."
                                                    onChange={e => handleSearching(e.target.value)}
                                                />
                                                <i className="ri-search-line"></i>
                                            </div>
                                            <div>
                                                <Button
                                                    className="waves-effect mr-1"
                                                    color='light'
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setVisibleModalAddPromos(!visibleModalAddPromos)
                                                    }}
                                                >
                                                    <i className="align-middle ri-add-fill mr-2" />Add Promotions
                                                 </Button>
                                            </div>
                                        </div>
                                    </Nav>


                                    <ServerSidePaginations
                                        loading={loading}
                                        dataSource={dataSource}
                                        getItems={(params) => {
                                            getPromotions(
                                                params.sortBy,
                                                params.orderBy,
                                                params.limit,
                                                params.page,
                                                params.total,
                                                params.category,
                                                params.keywords,
                                            )
                                        }}
                                        type="online_Store_promotion"
                                        emptyMessage={`There are no ${!contentBody.title ? "" : contentBody.title.toLowerCase()} yet`}
                                        obj={contentBody}
                                        initialParams={initialParams}
                                        setLoading={setLoading}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Modal
                        centered={true}
                        isOpen={visibleModalAddPromos}
                        toggle={() => setVisibleModalAddPromos(!visibleModalAddPromos)}
                    >
                        <ModalHeader>
                            Add Promotion
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleSubmitPromos}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Input
                                        onChange={e => {
                                            setFormValues({
                                                title: e.target.value
                                            })
                                        }}
                                        placeholder="e.g: Promo Raya"
                                    />
                                </FormGroup>
                                <div style={{ float: "right" }}>
                                    <Button className="mr-2" color="light" onClick={() => setVisibleModalAddPromos(!visibleModalAddPromos)}>Cancel</Button>
                                    <Button color="primary" type="submit">Submit</Button>
                                </div>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Promotion
