import React, { createContext, useReducer } from 'react'
import { ACTION_TYPE } from './ActionType'
import { completeSiteVisitContent, proceedPaymentContent, setSiteVisitContent, siteVisitContent } from './AssessmentData'

export const NurseContext = createContext()

const NurseProvider = (props) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTION_TYPE.ACTION_GET_NURSE:
                return {
                    ...state,
                    category: action.payload.data.category,
                    formData: action.payload.data,
                    loading: action.payload.loading
                }
            case ACTION_TYPE.ACTION_UPDATE_FORM:
                return {
                    ...state,
                    category: action.payload.data.category,
                    formData: action.payload.data,
                    loading: action.payload.loading
                }
            case ACTION_TYPE.ACTION_SITE_VISIT:
                return {
                    ...state,
                    modalContent: siteVisitContent,
                    mode: action.payload.mode,
                    headerColor: action.payload.headerColor,
                    mode_disabled: action.payload.disabled
                    // mode: action.payload.mode,
                }
            case ACTION_TYPE.ACTION_SET_SITE_VISIT:
                return {
                    ...state,
                    modalContent: setSiteVisitContent,
                    headerColor: action.payload.headerColor,
                    mode: action.payload.mode,
                    mode_disabled: action.payload.disabled
                }
            case ACTION_TYPE.ACTION_COMPLETE_SITE_VISIT:
                return {
                    ...state,
                    modalContent: completeSiteVisitContent,
                    headerColor: action.payload.headerColor,
                    mode: action.payload.mode,
                    mode_disabled: action.payload.disabled
                }
            case ACTION_TYPE.ACTION_PROCEED_PAYMENT:
                return {
                    ...state,
                    modalContent: proceedPaymentContent,
                    headerColor: action.payload.headerColor,
                    mode: action.payload.mode,
                    mode_disabled: action.payload.disabled
                }
            case ACTION_TYPE.ACTION_CANCEL_SITE_VISIT:
                return {
                    ...state,
                    headerColor: '#252b3b',
                    mode: null,
                    mode_disabled: false,
                    modalContent: siteVisitContent,
                }
            case ACTION_TYPE.ACTION_EDIT_MODE:
                return {
                    ...state,
                    editMode: {
                        tab: action.payload.tab,
                        childeTab: action.payload.childeTab,
                        mode: action.payload.mode
                    }
                }
            case ACTION_TYPE.SAVE_PROPS_STATE:
                return {
                    ...state,
                    propsState: action.payload
                }
            default:
                return {
                    ...state,
                    category: action.payload.data.category,
                    formData: action.payload.data,
                    loading: action.payload.loading,
                    mode: null,
                    headerColor: null,
                    modalContent: siteVisitContent,
                    mode_disabled: false,
                }
        }
    }
    let data = {
        propsState: {},
        formData: {},
        headerColor: '#252b3b',
        mode: null,
        mode_disabled: false,
        modalContent: siteVisitContent,
        loading: true,
        category: null,
        editMode: {
            tab: null,
            childeTab: null,
            mode: false
        }
    }
    const [dataSource, dispatch] = useReducer(reducer, data)
    return (
        <NurseContext.Provider value={[dataSource, dispatch]}>
            {props.children}
        </NurseContext.Provider>
    )
}

export default NurseProvider
