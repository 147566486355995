import React, { useEffect, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Button, Spinner, FormFeedback } from 'reactstrap'
import { get, getAPIroot, post, remove, update } from '../../../helpers/API'
import { converPriceWithCommas, formatBytes } from '../../../store/CustomFunctions'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const styles = {
    root: {
        border: '1px solid #eff2f7',
        padding: "20px 24px",
        borderRadius: 4
    },
    imgUpload: {
        minHeight: 150,
        width: 150
    },
    load: {
        position: 'absolute',
        height: 'calc(100% - 30px)',
        width: 'calc(100% - 30px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
        background: '#ffffff80'
    }
}

// let optionPromoType = [];
let optionCategories = []

let initialFormValues = {
    image: null,
    title: '',
    prod_description: '',
    categories: null,
    price: 0,
    corporate_price: 0,
    pack_size: 0,
    sku_code: '',
    promo_type: '',
    is_new_arrival: false,
    is_promotion: false,
    is_prescription_needed: false,
    is_hidden: false,
    is_sold_out: false,
    corporate_only: false,
}

let quillObj = null

export const ProductModal = ({
    visibleModalAddProduct,
    setVisibleModalAddProduct,
    getProducts,
    initialParams,
    selectedItem,
    setSelectedItem
}) => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [loadData, setLoadData] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [isValidate, setValidate] = useState({
        image: false,
        title: false
    })
    const quillImageCallback = () => {
        const input = document.createElement('input');

        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*');
        input.id = "imageDesc"
        input.click();

        input.onchange = () => {
            let file = input.files[0];
            // let fileName = file.name;
            let formData = new FormData();
            formData.append('files', file);
            post('/upload', formData).then(response => {
                const range = quillObj.getEditorSelection();

                let res = getAPIroot() + response[0].url;
                quillObj.getEditor().insertEmbed(range.index, 'image', res);
            }).catch(err => {
                // console.log(err);
            })
        }
    };

    const module = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image', 'video'],
                    [{ 'align': [] }],
                    ['clean'],
                    [{ 'color': [] }]
                ],
                handlers: {
                    image: quillImageCallback
                }
            },
            clipboard: {
                matchVisual: false,
            }
        }
    }, []);

    const selectedId = useMemo(() => {
        return selectedItem.id !== undefined ? selectedItem.id : null
    }, [selectedItem.id]);
    // useEffect(() => {
    //     get(`/discounts`).then(response => {
    //         optionPromoType = [
    //             {
    //                 label: response.discount.title,
    //                 value: response.discount.id
    //             }
    //         ]
    //     }).catch(() => { })
    // }, [])
    useEffect(() => {
        if (selectedId !== null) {
            setLoadData(true)
            get(`/products/${selectedId}`).then(response => {
                let newArray = []
                if (response.categories && response.categories.length > 0) {
                    response.categories.map(item => {
                        newArray.push({
                            label: item.title,
                            value: item.id
                        })
                        return item
                    })
                }

                setFormValues({
                    image: !response.variants && !response.variants[0].image ? null : response.variants[0].image,
                    title: !response.title ? '' : response.title,
                    prod_description: !response.prod_description ? '' : response.prod_description,
                    categories: newArray.length === 0 ? null : newArray,
                    price: !response.variants && !response.variants[0].price ? 0 : converPriceWithCommas(response.variants[0].price),
                    corporate_price: !response.variants && !response.variants[0].corporate_price ? 0 : converPriceWithCommas(response.variants[0].corporate_price),
                    pack_size: !response.variants && !response.variants[0].pack_size ? 0 : response.variants[0].pack_size,
                    sku_code: !response.variants && !response.variants[0].sku_code ? '' : response.variants[0].sku_code,
                    promo_type: !response.promo_type ? '' : response.promo_type,
                    is_new_arrival: response.is_new_arrival,
                    is_promotion: !response.is_promotion ? false : response.is_promotion,
                    is_prescription_needed: !response.is_prescription_needed ? false : response.is_prescription_needed,
                    is_hidden: !response.is_hidden ? false : response.is_hidden,
                    is_sold_out: !response.is_sold_out ? false : response.is_sold_out,
                    corporate_only: !response.corporate_only ? false : response.corporate_only,
                });
                setLoadData(false)
            }).catch(() => { setLoadData(false) })
        }
    }, [selectedId])

    useEffect(() => {
        setIsloading(true)
        get(`/categories`).then(response => {
            setIsloading(false)
            response.map(item => {
                let data = {
                    label: item.title,
                    value: item.id
                }

                optionCategories.push(data)

                return item
            })
        }).catch(() => { })
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        let data = {
            title: formValues.title,
            is_new_arrival: formValues.is_new_arrival,
            is_prescription_needed: formValues.is_prescription_needed,
            is_hidden: formValues.is_hidden,
            is_sold_out: formValues.is_sold_out,
            corporate_only: formValues.corporate_only,
            is_promotion: formValues.is_promotion,
            prod_description: formValues.prod_description,
            variants: [{
                title: formValues.title,
                price: formValues.price,
                corporate_price: formValues.corporate_price,
                pack_size: formValues.pack_size,
                sku_code: formValues.sku_code
            }]
        }

        if (formValues.categories && formValues.categories.length > 0) {
            const obj = {
                ...data,
                categories: formValues.categories.map(x => { return x.value }),
            }
            data = obj
        } else {
            const obj = {
                ...data,
                categories: [],
            }
            data = obj
        }

        if (formValues.promo_type) {
            const obj = {
                ...data,
                promo_type: formValues.promo_type,
            }
            data = obj
        }

        if (formValues.image) {
            const obj = {
                ...data,
                variants: [{
                    ...data.variants[0],
                    image: formValues.image
                }]
            }
            data = obj
        }

        if (selectedItem && selectedItem.id) {
            updateProduct(data)
        } else {
            createNewProduct(data)
        }
    }

    const updateProduct = async (data) => {
        let formData = new FormData();
        formData.append('files', formValues.image);
        if (formValues.image !== null && formValues.image !== undefined && formValues.title !== "") {
            if (formValues && formValues.image && formValues.image.length > 0 && formValues.image[0].id) {
                let newData = {
                    ...data,
                    variants: [{
                        ...data.variants[0],
                        image: formValues.image[0]
                    }]
                }
                await update(`/products/${selectedItem.id}`, newData).then(response => {
                    setFormValues(initialFormValues)
                    setVisibleModalAddProduct(!visibleModalAddProduct)

                    getProducts(
                        initialParams.sortBy,
                        initialParams.orderBy,
                        initialParams.limit,
                        initialParams.page,
                        initialParams.total,
                        initialParams.category,
                        initialParams.keywords
                    )
                }).catch(() => {
                    setFormValues(initialFormValues)
                    setVisibleModalAddProduct(!visibleModalAddProduct)
                })
            } else {
                if (selectedItem.variants && selectedItem.variants.length > 0 && selectedItem.variants[0].image.length > 0 && selectedItem.variants[0].image[0].id) {
                    remove(`/upload/files/${selectedItem.variants[0].image[0].id}`).then(async res => {
                        await post(`/upload`, formData).then(imgRes => {
                            let newData = {
                                ...data,
                                variants: [{
                                    ...data.variants[0],
                                    image: imgRes[0]
                                }]
                            }

                            update(`/products/${selectedItem.id}`, newData).then(response => {
                                setFormValues(initialFormValues)
                                setVisibleModalAddProduct(!visibleModalAddProduct)

                                getProducts(
                                    initialParams.sortBy,
                                    initialParams.orderBy,
                                    initialParams.limit,
                                    initialParams.page,
                                    initialParams.total,
                                    initialParams.category,
                                    initialParams.keywords
                                )
                            }).catch(() => {
                                setFormValues(initialFormValues)
                                setVisibleModalAddProduct(!visibleModalAddProduct)
                            })
                        }).catch(() => { })
                        setSelectedItem({})
                    })
                }
            }
        }

        setVisibleModalAddProduct(!visibleModalAddProduct)
        setSelectedItem({})
    }

    const createNewProduct = (data) => {
        let formData = new FormData();
        formData.append('files', formValues.image);

        setValidate({
            image: formValues.image ? false : true,
            title: formValues.title ? false : true
        })

        if (formValues.image !== null && formValues.image !== undefined && formValues.title !== "") {
            post(`/upload`, formData).then(imgRes => {
                let newData = {
                    ...data,
                    variants: [{
                        ...data.variants[0],
                        image: imgRes[0]
                    }]
                }

                post(`/products`, newData).then(response => {
                    setFormValues(initialFormValues)
                    setVisibleModalAddProduct(!visibleModalAddProduct)

                    getProducts(
                        initialParams.sortBy,
                        initialParams.orderBy,
                        initialParams.limit,
                        initialParams.page,
                        initialParams.total,
                        initialParams.category,
                        initialParams.keywords
                    )
                }).catch(() => {
                    setFormValues(initialFormValues)
                    setVisibleModalAddProduct(!visibleModalAddProduct)
                })
            }).catch(() => { })
            setSelectedItem({})
        }
    }

    const handleUpdateForm = (property, value) => {
        setFormValues(prev => {
            return {
                ...prev,
                [property]: value
            }
        })
    }

    const handleAcceptedFiles = (files) => {
        setValidate(prev => {
            return {
                ...prev,
                image: !files ? true : false
            }
        })

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        let data = [...files]
        if (files !== undefined || files !== null) {
            handleUpdateForm('image', data[0])
        }
    }

    const onEditorStateChange = (content, editor) => {
        setFormValues(prev => {
            return {
                ...prev,
                prod_description: content
            }
        })
    };
    const renderImgProduct = () => {
        if (formValues && formValues.image && formValues.image.length > 0 && formValues.image[0].id) {
            return <img alt="" style={{ height: '8em', width: '100%' }} src={getAPIroot() + formValues.image[0].url} />
        } else if (formValues.image && formValues.image) {
            return <img alt="" style={{ height: '8em', width: '100%' }} src={formValues.image.preview} />
        }
    }
    return (
        <div>
            <Modal
                size="xl"
                isOpen={visibleModalAddProduct}
                centered={true}
            >
                <ModalHeader>
                    {selectedItem && selectedItem.id ? `Edit product (${selectedItem.title})` : "Add New product"}
                </ModalHeader>
                <ModalBody>
                    {loadData && <div style={styles.load}><Spinner /></div>}
                    <Row>
                        <Col md={7}>
                            <div style={styles.root}>
                                <h5 className="font-size-15 mb-4">Product Information</h5>
                                <Row className="mb-3">
                                    <Col md={2}>
                                        <Label className="align-middle">Image</Label>
                                    </Col>
                                    <Col md={10}>
                                        <div>
                                            <Dropzone onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }} >
                                                {({ getRootProps, getInputProps, open }) => (
                                                    <div
                                                        className={isValidate.image ? 'dropzone is-valid' : 'dropzone'}
                                                        style={styles.imgUpload}
                                                    >

                                                        <div {...getRootProps()}>
                                                            <input  {...getInputProps()} />

                                                            {formValues.image ?
                                                                !selectedItem.id ? <img alt="" style={{ height: '8em', width: '100%' }} src={formValues.image.preview} /> :
                                                                    renderImgProduct() :
                                                                <div className="dz-message needsclick" style={{ padding: 37 }}>
                                                                    <div>
                                                                        <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                        <Button style={{ width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0, fontSize: 12 }} onClick={open} color='light'>Upload</Button>
                                                    </div>
                                                )}

                                            </Dropzone>

                                        </div>

                                        {isValidate.image && <p className="mb-0 mt-2 font-size-12" style={{ color: "#ff3d60" }}>Product image is required!</p>}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={2}>
                                        <Label className="align-middle">Title</Label>
                                    </Col>
                                    <Col md={10}>
                                        <Input
                                            invalid={isValidate.title}
                                            type="text"
                                            value={formValues.title}
                                            onChange={(e) => {
                                                handleUpdateForm('title', e.target.value)

                                                if (e.target.value.length === 0) {
                                                    setValidate(prev => {
                                                        return {
                                                            ...prev,
                                                            title: true
                                                        }
                                                    })
                                                } else {
                                                    setValidate(prev => {
                                                        return {
                                                            ...prev,
                                                            title: false
                                                        }
                                                    })
                                                }
                                            }}
                                        />
                                        <FormFeedback>Title is required!</FormFeedback>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={2}>
                                        <Label className="align-middle">Category</Label>
                                    </Col>
                                    <Col md={10}>
                                        <Select
                                            isLoading={isLoading}
                                            value={formValues.categories}
                                            isMulti={true}
                                            onChange={(group) => handleUpdateForm('categories', group)}
                                            placeholder='Select Category...'
                                            options={optionCategories}
                                            classNamePrefix="select2-selection"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={2}>
                                        <Label className="align-middle">Price</Label>
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            type="number"
                                            min={0}
                                            step="1"
                                            value={formValues.price}
                                            onChange={(e) => {
                                                handleUpdateForm('price', e.target.value)
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <Label className="align-middle">Corporate price</Label>
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            type="number"
                                            min={0}
                                            step="1"
                                            value={formValues.corporate_price}
                                            onChange={(e) => {
                                                handleUpdateForm('corporate_price', e.target.value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={2}>
                                        <Label className="align-middle">Pack size</Label>
                                    </Col>
                                    <Col md={10}>
                                        <Input
                                            type="number"
                                            min={0}
                                            value={formValues.pack_size}
                                            onChange={(e) => {
                                                handleUpdateForm('pack_size', e.target.value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={2}>
                                        <Label className="align-middle">SKU code</Label>
                                    </Col>
                                    <Col md={10}>
                                        <Input
                                            type="text"
                                            value={formValues.sku_code}
                                            onChange={(e) => {
                                                handleUpdateForm('sku_code', e.target.value)
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={2}><Label className="align-middle">Descriptions</Label></Col>
                                    <Col md={10}>
                                        <ReactQuill
                                            ref={el => {
                                                quillObj = el
                                            }}
                                            theme="snow"
                                            value={formValues.prod_description}
                                            onChange={(content, delta, source, editor) => onEditorStateChange(content, editor)}
                                            modules={module}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row className="mb-3">
                                        <Col md={2}>
                                            <Label className="align-middle">Promo type</Label>
                                        </Col>
                                        <Col md={10}>
                                            <Select
                                                isLoading={optionPromoType.length === 0 ? true : false}
                                                options={optionPromoType}
                                                placeholder='Select Promo type...'
                                                value={!formValues.promo_type ? null : optionCategories.find(x => x.value === formValues.promo_type)}
                                                onChange={(value) => handleUpdateForm('promo_type', value.value)}
                                            />
                                        </Col>
                                    </Row> */}
                            </div>
                        </Col>
                        <Col md={5}>
                            <div style={styles.root}>
                                <h5 className="font-size-15 mb-4">Settings</h5>

                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch1"
                                        checked={formValues.is_new_arrival}
                                        onChange={(e) => {
                                            handleUpdateForm('is_new_arrival', e.target.checked)
                                        }}
                                    />
                                    <Label className="custom-control-label" htmlFor="customSwitch1">New Arrival</Label>
                                </div>

                                {/* <div className="custom-control custom-switch mb-2" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch2"
                                        checked={formValues.is_promotion}
                                        onChange={(e) => {
                                            handleUpdateForm('is_promotion', e.target.checked)
                                        }}
                                    />
                                    <Label className="custom-control-label" htmlFor="customSwitch2">Promotion</Label>
                                </div> */}

                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch3"
                                        checked={formValues.is_prescription_needed}
                                        onChange={(e) => {
                                            handleUpdateForm('is_prescription_needed', e.target.checked)
                                        }}
                                    />
                                    <Label className="custom-control-label" htmlFor="customSwitch3">Prescription needed</Label>
                                </div>

                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch4"
                                        checked={formValues.is_hidden}
                                        onChange={(e) => {
                                            handleUpdateForm('is_hidden', e.target.checked)
                                        }}
                                    />
                                    <Label className="custom-control-label" htmlFor="customSwitch4">Hidden</Label>
                                </div>

                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch5"
                                        checked={formValues.is_sold_out}
                                        onChange={(e) => {
                                            handleUpdateForm('is_sold_out', e.target.checked)
                                        }}
                                    />
                                    <Label className="custom-control-label" htmlFor="customSwitch5">Sold out</Label>
                                </div>

                                <div className="custom-control custom-switch mb-2" dir="ltr">
                                    <Input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customSwitch6"
                                        checked={formValues.corporate_only}
                                        onChange={(e) => {
                                            handleUpdateForm('corporate_only', e.target.checked)
                                        }}
                                    />
                                    <Label className="custom-control-label" htmlFor="customSwitch6">Corporate only</Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="w-100 mt-3" style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button
                                    disabled={loadData}
                                    color="light"
                                    className="mr-2"
                                    onClick={() => {
                                        setVisibleModalAddProduct(!visibleModalAddProduct);
                                        setFormValues(initialFormValues)
                                        setSelectedItem({})
                                    }}
                                >Cancel</Button>
                                <Button disabled={loadData} color="primary" onClick={(e) => handleSubmit(e)}>{!selectedItem.id ? 'Submit' : 'Save'}</Button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
}
