import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { PrescriptionContext } from '../../../../../store/prescriptions/PrescriptionData'

const ModalPatientBiodata = ({ visible, isClosed, onSubmit }) => {
    const { prescriptionDataLayer } = useContext(PrescriptionContext)
    const [disable, setDisable] = useState(false)
    const [formData, setFormData] = useState({
        allergy: '',
        height: '',
        blood_pressure: '',
        blood_glucose_fasting: '',
        body_temperature: '',
        weight: '',
        heart_rate: '',
        bmi: '',
        blood_glucose_non_fasting: ''
    })

    const updateForm = (field, value) => {
        setFormData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    useEffect(() => {
        if (prescriptionDataLayer.patient && prescriptionDataLayer.patient.patient_biodata) {
            setFormData({
                allergy: prescriptionDataLayer.patient.patient_biodata.allergy,
                height: prescriptionDataLayer.patient.patient_biodata.height,
                blood_pressure: prescriptionDataLayer.patient.patient_biodata.blood_pressure,
                blood_glucose_fasting: prescriptionDataLayer.patient.patient_biodata.blood_glucose_fasting,
                body_temperature: prescriptionDataLayer.patient.patient_biodata.body_temperature,
                weight: prescriptionDataLayer.patient.patient_biodata.weight,
                heart_rate: prescriptionDataLayer.patient.patient_biodata.heart_rate,
                bmi: prescriptionDataLayer.patient.patient_biodata.bmi,
                blood_glucose_non_fasting: prescriptionDataLayer.patient.patient_biodata.blood_glucose_non_fasting
            })
        }
    }, [prescriptionDataLayer.patient])

    useEffect(() => {
        let isMounted = true
        if (isMounted && prescriptionDataLayer.action_button && prescriptionDataLayer.action_button.questionaireView) {
            setDisable(prescriptionDataLayer.action_button.questionaireView)
        }

        return () => isMounted = false
    }, [ prescriptionDataLayer.action_button])

    return (
        <Modal centered isOpen={visible} size="lg" toggle={() => isClosed()}>
            <ModalBody>
                <div>
                    <h4>Patient Assessment</h4>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Heart Rate</Label>
                                <Input type='text' disabled={disable} value={formData.heart_rate} onChange={e => updateForm('heart_rate', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Blood Pressure</Label>
                                <Input disabled={disable} type='text' value={formData.blood_pressure} onChange={e => updateForm('blood_pressure', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Blood Glucose - Fasting (mmol/L)</Label>
                                <Input disabled={disable} type='text' value={formData.blood_glucose_fasting} onChange={e => updateForm('blood_glucose_fasting', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Blood Glucose - Non Fasting (mmol/L)</Label>
                                <Input disabled={disable} type='text' value={formData.blood_glucose_non_fasting} onChange={e => updateForm('blood_glucose_non_fasting', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Height (cm)</Label>
                                <Input disabled={disable} type='text' value={formData.height} onChange={e => updateForm('height', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Weight (kg)</Label>
                                <Input disabled={disable} type='text' value={formData.weight} onChange={e => updateForm('weight', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>BMI</Label>
                                <Input disabled={disable} type='text' value={formData.bmi} onChange={e => updateForm('bmi', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Body Temperature (°C)</Label>
                                <Input disabled={disable} type='text' value={formData.body_temperature} onChange={e => updateForm('body_temperature', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Allergy</Label>
                                <Input disabled={disable} type='text' value={formData.allergy} onChange={e => updateForm('allergy', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                {!disable && <div className='d-flex justify-content-center'>
                    <Button className='w-100 mr-2' color='light' onClick={() => isClosed()}>Cancel</Button>
                    <Button className='w-100' color='primary' onClick={() => onSubmit(formData)}>Save</Button>
                </div>}
            </ModalBody>
        </Modal>
    )
}

export default ModalPatientBiodata