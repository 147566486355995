import React, { useEffect, useMemo, useState } from 'react'
// import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import { Container } from "reactstrap";
// import classnames from 'classnames';
import { get, post } from '../../helpers/API';
import { useLocation } from 'react-router-dom';

// Text
// import { TEXT_BOOKING, TEXT_ORDER, TEXT_PROVIDER } from '../../Text'

// Pie chart
import ProviderContent from './ProviderDashboard/ProviderContent';
import BookingContent from './BookingDashboard/BookingContent';
import OrderContent from './OrderDashboard/OrderContent';
import ClinicContent from './ClinicDashboard/ClinicContent';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

let timeout = null;

let initializeData = {
    providers: {
        totalApplications: {
            loading: true,
            total: 0,
        },
        applicationStatus: {
            loading: true,
            processing: 0,
            approved: 0,
            rejected: 0,
            kiv: 0
        },
        typeOfProvider: {
            loading: true,
            analytics: [
                { key: "Registered Nurse", value: 0 },
                { key: "Medical Assistant", value: 0 },
                { key: "Physiotherapist", value: 0 },
                { key: "General Care Aide", value: 0 }
            ]
        },
    },
    clinicCount: {
        totalClinicOrder: {
            loading: true,
            total: 0,
        },
        corporates: {
            loading: true,
            data: [],
            raw_data: []
        },
        typeOfProvider: {
            loading: true,
            analytics: [
            ]
        },
    },
    bookings: {
        totalBookings: {
            loading: true,
            total: 0,
        },
        bookingStatus: {
            loading: true,
            new: 0,
            completed: 0,
            pending: 0,
            cancelled: 0
        },
        typeOfBooking: {
            loading: true,
            analytics: [
                { key: "Registered Nurse", value: 0 },
                { key: "Medical Assistant", value: 0 },
                { key: "Physiotherapist", value: 0 },
                { key: "General Care Aide", value: 0 }
            ]
        },
        bookingPhysio: {
            loading: true,
            physioOne: 0,
            physioTwo: 0,
            physioThree: 0
        },
        bookingNurse: {
            loading: true,
            listOfBookingNurse: [],
            bookingCompletedNurse: 0,
            bookingPendingNurse: 0,
            bookingAttentionNurse: 0
        }
    },

}

let initializeOrderData = {
    totalOrder: {
        count: 0,
        loading: true,
    },
    totalOrderPending: {
        count: 0,
        loading: true,
    },
    totalDelivered: {
        count: 0,
        loading: true,
    },
    totalRevenue: {
        count: 0,
        loading: true,
    },
    totalRefunded: {
        count: 0,
        loading: true,
    },
    orderGraph: {
        data: [],
        loading: true,
    },
    orderSalesByCategoryGraph: {
        data: [],
        loading: true,
    }
}

let arrMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const NewDashboard = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('1');
    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes('provider')) {
            handleTab('1');
        } else if (pathname.includes('booking')) {
            handleTab('2');
        } else if (pathname.includes('order')) {
            handleTab('3');
        } else if (pathname.includes('clinic')) {
            handleTab('4');
        }
    }, [location.pathname]);
    const [activeTabOrder, setActiveTabOrder] = useState(1);
    const [activeBtnOrder, setActiveBtnOrder] = useState(false)
    // application
    const [applicationCount, setApplicationCount] = useState(initializeData.providers);
    // booking
    const [bookingCount, setBookingCount] = useState(initializeData.bookings)
    const [initialNurseData, setInitialNurseData] = useState({})
    // orders
    const [orderCount, setOrderCount] = useState(initializeOrderData)
    // clinic
    const [clinicCount, setClinicCount] = useState(initializeData.clinicCount);
    const [selectedCorporate, setSelectedCorporate] = useState(null);

    const [selectedYear, setSelectedYear] = useState({ value: moment().format('YYYY'), label: moment().format('YYYY').toString() });
    const [selectedType, setSelectedType] = useState({ value: 'monthly', label: 'Monthly' });
    const [selectedMonth, setSelectedMonth] = useState({ value: moment().month(), label: moment().format('MMMM') });
    const [startDate, selectedStartDate] = useState('');
    const [endDate, selectedEndDate] = useState('');

    // const handleClinicOrder = useCallback(() => {
    //         dispatch({
    //             type: 'SET_CORPORATE_CUSTOMER',
    //             payload: {
    //                 label: corporate_organisation.org_name,
    //                 value: corporate_organisation.id
    //             }
    //         })
    // }, [])

    const renderTab = useMemo(() => {
        return activeTab
    }, [activeTab])

    const fetchingTypeOfProvider = () => {
        get('/nurse-applications/analytics?analyzeby=typeof_providers').then(res => {
            setApplicationCount(prev => {
                return {
                    ...prev,
                    typeOfProvider: {
                        loading: false,
                        analytics: res.analytics,
                    },
                }
            })
        }).catch(err => {
            setApplicationCount(prev => {
                return {
                    ...prev,
                    typeOfProvider: {
                        ...prev.typeOfProvider,
                        loading: false,
                    },
                }
            })
        })
    }

    const fetchingCorporates = () => {
        get('/corporate-organisations').then(res => {
            setClinicCount(prev => {
                return {
                    ...prev,
                    corporates: {
                        loading: false,
                        data: res.map(x => {
                            return {
                                label: x.org_name,
                                value: x.id
                            }
                        }),
                        raw_data: res
                    },
                }
            })
        }).catch(err => {
            setClinicCount(prev => {
                return {
                    ...prev,
                    corporates: {
                        loading: false,
                        ...prev.corporates,
                    },
                }
            })
        })
    }

    const fetchApplicationCount = () => {
        get('/nurse-applications/count').then(count => {
            setApplicationCount(prev => {
                return {
                    ...prev,
                    totalApplications: {
                        loading: false,
                        total: count,
                    },
                }
            })
        }).catch(err => {
            setApplicationCount(prev => {
                return {
                    ...prev,
                    totalApplications: {
                        ...prev.totalApplications,
                        loading: false,
                    },
                }
            })
        })
    }

    const handleCorporateChange = (selectedOption) => {
        setSelectedCorporate(selectedOption);
        if (selectedOption.value) { }
        else {
            setSelectedCorporate(null)
        }
        // console.log(selectedOption.value);
    };

    const yearOptions = () => {
        const currentYear = moment().year();
        const startYear = currentYear - 3;
        const years = [];
        for (let year = currentYear; year >= startYear; year--) {
            years.push({ value: year, label: year.toString() });
        }
        return years;
    };

    const handleYearChange = (selectedYear) => {
        setSelectedYear(selectedYear);
        // console.log(selectedYear.value);
        if (selectedType && selectedType.value === "annual") {
            handleTypeChange(selectedType, selectedYear, null);
            // console.log("executed handleTypeChange")
        } else if (selectedType && selectedType.value === "monthly" && selectedMonth) {
            handleTypeChange(selectedType, selectedYear, selectedMonth);
        }
    };

    const handleTypeChange = (selectedType, selectedYear = null, selectedMonth = null) => {
        setSelectedType(selectedType);
        // console.log(selectedType.value);
        // console.log(selectedYear.value);
        // console.log(selectedMonth.value);
        if (selectedType.value === "annual" && selectedYear) {
            const thisStartDate = moment().year(selectedYear.value).startOf('year').format('YYYY-MM-DD');
            const thisEndDate = moment().year(selectedYear.value).endOf('year').format('YYYY-MM-DD');
            // console.log(thisStartDate)
            // console.log(thisEndDate)
            handleStartDate(thisStartDate);
            handleEndDate(thisEndDate);
            // console.log("Success");
        } else if (selectedType.value === "monthly" && selectedYear && selectedMonth) {
            const thisStartDate = moment().year(selectedYear.value).month(selectedMonth.value).startOf('month').format('YYYY-MM-DD');
            const thisEndDate = moment().year(selectedYear.value).month(selectedMonth.value).endOf('month').format('YYYY-MM-DD');
            console.log(selectedMonth);
            // console.log("executed monthly")
            // console.log(thisStartDate)
            // console.log(thisEndDate)
            handleStartDate(thisStartDate);
            handleEndDate(thisEndDate);
            // console.log("Success");
        }
    };

    const monthOptions = () => {
        const months = moment.months();
        return months.map((month, index) => ({ value: index, label: month }));
    };

    const handleMonthChange = (selectedMonth) => {
        setSelectedMonth(selectedMonth);
        // console.log(selectedYear.value);
        // console.log(selectedMonth.value);
        // console.log(selectedType.value);
        if (selectedType.value === "monthly" && selectedYear != null) {
            handleTypeChange(selectedType, selectedYear, selectedMonth);
            // console.log("executed handleTypeChange from month")
        }
    };

    const handleStartDate = (date) => {
        selectedStartDate(date);
        // console.log(event.target.value);
    };
    const handleEndDate = (date) => {
        selectedEndDate(date);
        // console.log(event.target.value);
    };

    const fetchClinicCount = (corporateID = null, startDate, endDate) => {
        const analytics = [];
        const start = moment.utc(startDate);
        const end = moment.utc(endDate);
        let currentDate = start.clone();
        const diffInDays = end.diff(start, "days") + 1; // Add 1 to include the end date

        setClinicCount((prev) => ({
            ...prev,
            totalClinicOrder: {
                ...prev.totalClinicOrder,
                loading: true,
            },
            typeOfProvider: {
                ...prev.typeOfProvider,
                loading: true,
            },
        }));

        while (currentDate.isSameOrBefore(end)) {
            const formattedDate = currentDate.format("YYYY-MM-DD");
            const url = `/clinic-orders/count${corporateID ? `?corporate_customer._id=${corporateID}` : "?"}&createdAt_gte=${formattedDate + "T00:00:00.000Z"
                }&createdAt_lte=${formattedDate + "T23:59:59.999Z"}`;

            get(url)
                .then(async (count) => {
                    const ordersUrl = `/clinic-orders${corporateID ? `?corporate_customer._id=${corporateID}` : "?"}&createdAt_gte=${formattedDate + "T00:00:00.000Z"
                        }&createdAt_lte=${formattedDate + "T23:59:59.999Z"}`;

                    try {
                        const orders = await get(ordersUrl);
                        analytics.push({
                            key: formattedDate,
                            status: orders.map((order) => order.status),
                            value: count,
                        });

                        if (analytics.length === diffInDays) {
                            const groupedAnalytics =
                                diffInDays > 31
                                    ? _.chain(analytics)
                                        .groupBy((item) => moment(item.key).format("MMMM"))
                                        .map((value, key) => ({ key, value: _.sumBy(value, "value"), status: _.flatMap(value, 'status') }))
                                        .value()
                                    : analytics;

                            setClinicCount((prev) => ({
                                ...prev,
                                totalClinicOrder: {
                                    loading: false,
                                    total: groupedAnalytics.reduce((sum, { value }) => sum + value, 0),
                                },
                                typeOfProvider: {
                                    loading: false,
                                    analytics: groupedAnalytics,
                                },
                            }));
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch((err) => {
                    setClinicCount((prev) => ({
                        ...prev,
                        totalClinicOrder: {
                            ...prev.totalClinicOrder,
                            loading: false,
                        },
                    }));
                });

            currentDate.add(1, "day");
        }
    };

    const fetchingApplicationStatus = () => {
        // fetching data application
        const applicationFormsProcessing = get(`/nurse-applications/count?status=processing`)
        const applicationFormsApproved = get(`/nurse-applications/count?status=approved`)
        const applicationFormsRejected = get(`/nurse-applications/count?status=rejected`)
        const applicationFormsKiv = get(`/nurse-applications/count?status=kiv`)

        axios.all([
            applicationFormsProcessing,
            applicationFormsApproved,
            applicationFormsRejected,
            applicationFormsKiv,
        ]).then(
            axios.spread((...alldata) => {
                setApplicationCount(prev => {
                    return {
                        ...prev,
                        applicationStatus: {
                            loading: false,
                            processing: alldata[0],
                            approved: alldata[1],
                            rejected: alldata[2],
                            kiv: alldata[3],
                        },
                    }
                })
            })
        ).catch(err => {
            setApplicationCount(prev => {
                return {
                    ...prev,
                    bookingStatus: {
                        ...prev.totalApplications,
                        loading: false,
                    },
                }
            })
        })
    }

    const fetchingBookingCount = () => {
        get('/booking-forms/count').then(count => {
            setBookingCount(prev => {
                return {
                    ...prev,
                    totalBookings: {
                        loading: false,
                        total: count
                    }
                }
            })
        }).catch(err => {
            setBookingCount(prev => {
                return {
                    ...prev,
                    totalBookings: {
                        ...prev.totalBookings,
                        loading: false,
                    }
                }
            })
        })
    }

    const fetchingBookingStatus = () => {
        const bookingFormsNew = get(`/booking-forms?booking_status=null`)
        const bookingFormsCompleted = get(`/booking-forms?booking_status=completed`)
        const bookingFormsPending = get(`/booking-forms?booking_status=pending`)
        const bookingFormsCancelled = get(`/booking-forms?booking_status=cancelled`)

        axios.all([
            bookingFormsNew,
            bookingFormsCompleted,
            bookingFormsPending,
            bookingFormsCancelled,
        ]).then(
            axios.spread((...alldata) => {
                setBookingCount(prev => {
                    return {
                        ...prev,
                        bookingStatus: {
                            loading: false,
                            new: alldata[0].length,
                            completed: alldata[1].length,
                            pending: alldata[2].length,
                            cancelled: alldata[3].length,
                        }
                    }
                })
            })
        ).catch(err => {
            setBookingCount(prev => {
                return {
                    ...prev,
                    bookingStatus: {
                        ...prev.bookingStatus,
                        loading: false,
                    }
                }
            })
        })
    }

    const fetchingBookingPhysio = () => {
        const bookingPhysio = get('/analytics/dashboard/booking-count-by-category?category=physio')
        const bookingNurseAll = get('/analytics/dashboard/booking-count-by-category?category=nurse')

        axios.all([
            bookingPhysio,
            bookingNurseAll,
        ]).then(
            axios.spread((...alldata) => {
                setBookingCount((prev) => {
                    return {
                        ...prev,
                        bookingPhysio: {
                            loading: false,
                            physioOne: alldata[0].analytics[0].value,
                            physioTwo: alldata[0].analytics[1].value,
                            physioThree: alldata[0].analytics[2].value
                        }
                    }
                })
            })
        ).catch(err => {
            setBookingCount((prev) => {
                return {
                    ...prev,
                    bookingPhysio: {
                        ...prev.bookingPhysio,
                        loading: false,
                    }
                }
            })
        })
    }

    const fecthingBookingNurse = () => {
        const bookingForms = get('/booking-forms?category=nurse')
        const bookingFormsNurse = get('/analytics/dashboard/booking-count-by-category?category=nurse')

        axios.all([
            bookingForms,
            bookingFormsNurse,
        ]).then(
            axios.spread((...alldata) => {
                let data1 = alldata[0];
                let data2 = alldata[1];
                let i = 0;
                let countCompleted = [];
                let countPending = [];
                let countAttenttion = []
                let customScheduleData = []
                let copy_data = [...data1]

                for (; i < copy_data.length; i++) {
                    if ((copy_data[i].booking_status === "completed") && (copy_data[i].booking_mode === "provider") && (copy_data[i].financial_status === "completed")) {
                        countCompleted.push(copy_data[i])
                    }

                    if ((copy_data[i].booking_status === "pending")) {
                        countPending.push(copy_data[i])
                    }

                    if (copy_data[i].booking_status === "validation_error") {
                        countAttenttion.push(copy_data[i])
                    }

                    if (copy_data[i].schedule_mode === 'custom' && (copy_data[i].custom_schedule_days !== undefined && copy_data[i].custom_schedule_days !== null && copy_data[i].custom_schedule_days !== "")) {
                        customScheduleData.push(copy_data[i])
                    }
                }

                let array_elements = customScheduleData
                let map = array_elements.reduce((prev, cur) => {
                    prev[cur.custom_schedule_days] = (prev[cur.custom_schedule_days] || 0) + 1;
                    return prev;
                }, {});

                let title = Object.keys(map);
                let value = Object.values(map)
                let newArr = title.map((item, idx) => {
                    return {
                        key: `${item}`,
                        value: value[idx]
                    }
                })

                const dataSource = [...data2.analytics, ...newArr];

                const sorting = dataSource.sort((a, b) => {
                    if (a.value < b.value) return 1;
                    if (a.value > b.value) return -1;
                    return 0;
                });

                const dayLabel = sorting.map(x => x.key);
                const dayData = sorting.map(x => x.value);
                setBookingCount((prev) => {
                    return {
                        ...prev,
                        bookingNurse: {
                            loading: false,
                            listOfBookingNurse: sorting,
                            bookingCompletedNurse: countCompleted.length,
                            bookingPendingNurse: countPending.length,
                            bookingAttentionNurse: countAttenttion.length
                        }
                    }
                })
                setInitialNurseData({
                    labels: dayLabel,
                    datasets: [
                        {
                            data: dayData,
                            backgroundColor: [
                                'rgba(237, 250, 252, 1)',
                                'rgba(230, 248, 250, 1)',
                                'rgba(220, 245, 249, 1)',
                                'rgba(210, 243, 248, 1)',
                                'rgba(203, 240, 246, 1)',
                                'rgba(190, 238, 245, 1)',
                                'rgba(186, 235, 243, 1)',
                                'rgba(175, 233, 242, 1)',
                                'rgba(168, 230, 240, 1)',
                                'rgba(158, 228, 238, 1)',
                                'rgba(150, 226, 237, 1)',
                                'rgba(140, 224, 236, 1)',
                                'rgba(133, 221, 234, 1)',
                                'rgba(123, 219, 233, 1)',
                                'rgba(115, 216, 231, 1)',
                                'rgba(110, 214, 222, 1)',
                                'rgba(98, 211, 228, 1)',
                                'rgba(95, 209, 226, 1)',
                                'rgba(81, 206, 225, 1)',
                                'rgba(71, 204, 224, 1)',
                                'rgba(63, 201, 222, 1)',
                                'rgba(53, 198, 221, 1)',
                                'rgba(46, 196, 220, 1)',
                                'rgba(42, 190, 216, 1)',
                                'rgba(35, 186, 209, 1)',
                                'rgba(34, 180, 198, 1)',
                                'rgba(33, 171, 192, 1)',
                                'rgba(32, 160, 182, 1)',
                                'rgba(30, 155, 174, 1)',
                                'rgba(27, 140, 157, 1)',
                            ],
                            hoverBackgroundColor: [
                                'rgba(237, 250, 252, 0.8)',
                                'rgba(230, 248, 250, 0.8)',
                                'rgba(220, 245, 249, 0.8)',
                                'rgba(210, 243, 248, 0.8)',
                                'rgba(203, 240, 246, 0.8)',
                                'rgba(190, 238, 245, 0.8)',
                                'rgba(186, 235, 243, 0.8)',
                                'rgba(175, 233, 242, 0.8)',
                                'rgba(168, 230, 240, 0.8)',
                                'rgba(158, 228, 238, 0.8)',
                                'rgba(150, 226, 237, 0.8)',
                                'rgba(140, 224, 236, 0.8)',
                                'rgba(133, 221, 234, 0.8)',
                                'rgba(123, 219, 233, 0.8)',
                                'rgba(115, 216, 231, 0.8)',
                                'rgba(110, 214, 222, 0.8)',
                                'rgba(98, 211, 228, 0.8)',
                                'rgba(95, 209, 226, 0.8)',
                                'rgba(81, 206, 225, 0.8)',
                                'rgba(71, 204, 224, 0.8)',
                                'rgba(63, 201, 222, 0.8)',
                                'rgba(53, 198, 221, 0.8)',
                                'rgba(46, 196, 220, 0.8)',
                                'rgba(42, 190, 216, 0.8)',
                                'rgba(35, 186, 209, 0.8)',
                                'rgba(34, 180, 198, 0.8)',
                                'rgba(33, 171, 192, 0.8)',
                                'rgba(32, 160, 182, 0.8)',
                                'rgba(30, 155, 174, 0.8)',
                                'rgba(27, 140, 157, 0.8)',
                            ],
                            hoverBorderColor: "rgba(255, 255, 255, 1)"
                        }]
                });
            })
        ).catch(err => {
            setInitialNurseData({})
            setBookingCount((prev) => {
                return {
                    ...prev,
                    bookingNurse: {
                        ...prev.bookingNurse,
                        loading: false
                    }
                }
            })
        })
    }

    const fetchingTypeOfBooking = () => {
        // fetching data booking
        const bookingFormsNurse = get('/booking-forms?category=nurse')
        const bookingFormsMedicalAssistant = get('/booking-forms?category=medical_assistant')
        const bookingFormsPhysio = get('/booking-forms?category=physio')
        const bookingFormsCareAide = get('/booking-forms?category=care_aide')


        axios.all([
            bookingFormsNurse,
            bookingFormsMedicalAssistant,
            bookingFormsPhysio,
            bookingFormsCareAide,
        ]).then(
            axios.spread((...alldata) => {
                setBookingCount((prev) => {
                    return {
                        ...prev,
                        typeOfBooking: {
                            loading: false,
                            analytics: [
                                { key: "Registered Nurse", value: alldata[0].length },
                                { key: "Medical Assistant", value: alldata[1].length },
                                { key: "Physiotherapist", value: alldata[2].length },
                                { key: "General Care Aide", value: alldata[3].length }
                            ]
                        }
                    }
                })
            })
        ).catch(err => {
            // console.log(err);
        })
    }



    // const fetchingOrders = async () => {
    // await get('/orders').then(res => {
    //     let filterSuccessPayment = res.filter(x => x.financial_status === 'paid')
    //     let countTotalPrice = filterSuccessPayment.map(x => { return !x.total_price ? 0 : x.total_price }).reduce((a, b) => a + b, 0)

    //     const putFrontNumberZero = (num) => {
    //         if (num < 10) {
    //             return "0" + num
    //         } else {
    //             return num
    //         }
    //     }

    //     const lineData = res.map(item => {
    //         let date = new Date(item.createdAt)
    //         let result = date.getFullYear() + "-" + putFrontNumberZero(date.getMonth() + 1) + "-" + putFrontNumberZero(date.getDate())
    //         return {
    //             label: result,
    //             value: 1,
    //             year: date.getFullYear(),
    //             month: arrMonth.find((x, i) => (i + 1) === (date.getMonth() + 1))
    //         }
    //     })

    //     let output = []
    //     lineData.forEach(function (item) {
    //         var existing = output.filter((v, i) => {
    //             return item.month === v.month;
    //         });
    //         if (existing.length) {
    //             var existingIndex = output.indexOf(existing[0]);
    //             output[existingIndex].value = output[existingIndex].value + 1
    //         } else {
    //             output.push(item)
    //         }
    //     });

    //     setLineData(output)

    //     setOrderCount(prev => {
    //         return {
    //             ...prev,
    //             totalOrder: res.length,
    //             totalOrderPending: !res.filter(x => x.fulfillment_status === 'pending') ? 0 : res.filter(x => x.fulfillment_status === 'pending').length,
    //             totalDelivered: !res.filter(x => x.fulfillment_status === 'pending') ? 0 : res.filter(x => x.fulfillment_status === 'fulfilled').length,
    //             totalPrice: !countTotalPrice ? 0 : countTotalPrice,
    //         }
    //     })
    // }).catch(() => { })

    // await get('/orders?is_refunded=true').then(res => {
    //     let countTotalPrice = res.map(x => { return !x.total_price ? 0 : x.total_price }).reduce((a, b) => a + b, 0)
    //     setOrderCount(prev => {
    //         return {
    //             ...prev,
    //             loading: false,
    //             totalRefund: !countTotalPrice ? 0 : countTotalPrice
    //         }
    //     })
    // })
    // }

    const fetchingTotalOrders = (tab) => {
        let payload = {
            type: "total_order",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    totalOrder: {
                        count: response.total_order,
                        loading: false
                    },
                }
            })
        })
    }

    const fetchingTotalDelivered = (tab) => {
        let payload = {
            type: "total_delivered",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    totalDelivered: {
                        count: response.total_delivered,
                        loading: false
                    },
                }
            })
        })
    }

    const fetchingTotalPending = (tab) => {
        let payload = {
            type: "total_pending",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    totalOrderPending: {
                        count: response.total_pending,
                        loading: false
                    },
                }
            })
        })
    }

    const fetchingTotalRevenue = (tab) => {
        let payload = {
            type: "sale_revenue",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    totalRevenue: {
                        count: response.sale_revenue,
                        loading: false
                    },
                }
            })
        })
    }

    const fetchingTotalRefunded = (tab) => {
        let payload = {
            type: "total_refunded",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    totalRefunded: {
                        count: response.total_refunded,
                        loading: false
                    },
                }
            })
        })
    }

    const fetchingOrderGraph = (tab) => {
        let payload = {
            type: "order_graph",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    orderGraph: {
                        data: response.order_graph,
                        loading: false
                    },
                }
            })
        })
    }

    const fetchingOrderSalesByCategoryGraph = (tab) => {
        let payload = {
            type: "order_by_category_graph",
            period: tab === 1 ? "all" : tab === 2 ? "weekly" : "monthly"
        }

        post('/analytics', payload).then(response => {
            setOrderCount(prev => {
                return {
                    ...prev,
                    orderSalesByCategoryGraph: {
                        data: response.order_by_category_graph,
                        loading: false
                    },
                }
            })
        })
    }

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {

            if (renderTab === '1') {
                // console.log('load providers');
                fetchingApplicationStatus();
                fetchApplicationCount();
                fetchingTypeOfProvider();
            }
            else if (renderTab === '2') {
                // console.log('load bookings');
                fetchingBookingCount();
                fetchingBookingStatus();
                fetchingTypeOfBooking();
                fetchingBookingPhysio();
                fecthingBookingNurse();
            } else if (renderTab === '3') {
                // console.log('load orders');
                // fetchingOrders();
                fetchingTotalOrders(activeTabOrder);
                fetchingTotalPending(activeTabOrder);
                fetchingTotalDelivered(activeTabOrder);
                fetchingTotalRevenue(activeTabOrder);
                fetchingTotalRefunded(activeTabOrder)
                fetchingOrderGraph(activeTabOrder);
                fetchingOrderSalesByCategoryGraph(activeTabOrder);
            } else if (renderTab === '4') {
                if (!startDate) {
                    fetchClinicCount(null, moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'));
                } else if (!selectedCorporate && startDate.length > 0) {
                    fetchClinicCount(null, startDate, endDate);
                } else if (selectedCorporate && startDate.length > 0) {
                    fetchClinicCount(selectedCorporate.value, startDate, endDate);
                } else if (selectedCorporate) {
                    fetchClinicCount(selectedCorporate.value);
                } else {
                    fetchClinicCount();
                }
                fetchingCorporates();
            }
        }, 500);

    }, [renderTab, activeTabOrder, selectedCorporate, selectedMonth, startDate, endDate]);

    const handleChangeTab = (key) => {
        setActiveTabOrder(key)
        setOrderCount(initializeOrderData)
    }

    const handleTab = (key) => {
        setActiveTab(key)
        if (key === '1') {
            setApplicationCount(initializeData.providers)
        } else if (key === '2') {
            setBookingCount(initializeData.bookings)
            // setCountBookingBar(initializeData.countBookingBar)
            // setBookingCompletedNurse(initializeData.bookingCompletedNurse)
            // setBookingPendingNurse(initializeData.bookingPendingNurse)
            // setBookingAttentionNurse(initializeData.bookingAttentionNurse)
        } else {
            setOrderCount(initializeOrderData)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0 pb-0">
                                    <Nav tabs className="nav-tabs-custom">
                                        <NavItem>
                                            <NavLink onClick={() => { handleTab('1'); }} className={classnames({ active: activeTab === '1' }, "font-weight-bold p-3")}>{TEXT_PROVIDER}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { handleTab('2'); }} className={classnames({ active: activeTab === '2' }, "font-weight-bold p-3")}>{TEXT_BOOKING}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { handleTab('3'); }} className={classnames({ active: activeTab === '3' }, "font-weight-bold p-3")}>{TEXT_ORDER}</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}

                    {activeTab === '1' &&
                        <ProviderContent
                            providers={applicationCount}
                        />
                    }

                    {activeTab === '2' &&
                        <BookingContent
                            bookings={bookingCount}
                            initialNurseData={initialNurseData}
                        />
                    }

                    {activeTab === '3' &&
                        <OrderContent
                            orders={orderCount}
                            arrMonth={arrMonth}
                            activeTabOrder={activeTabOrder}
                            setActiveTabOrder={setActiveTabOrder}
                            activeBtnOrder={activeBtnOrder}
                            setActiveBtnOrder={setActiveBtnOrder}
                            handleChangeTab={handleChangeTab}
                        />
                    }

                    {activeTab === '4' &&
                        <ClinicContent
                            clinic={clinicCount}
                            selectedCorporate={selectedCorporate}
                            onCorporateChange={handleCorporateChange}
                            startDate={startDate}
                            endDate={endDate}
                            onStartChange={handleStartDate}
                            onEndChange={handleEndDate}
                            selectedYear={selectedYear}
                            onYearChange={handleYearChange}
                            yearOptions={yearOptions}
                            onTypeChange={handleTypeChange}
                            monthOptions={monthOptions}
                            selectedMonth={selectedMonth}
                            onMonthChange={handleMonthChange}
                        />
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewDashboard
