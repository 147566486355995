import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import { capitalizeFirstLetter, handleStatusStyle } from '../../store/CustomFunctions'
import { initialDataTableTelemedicine, initialValue, obj, TabTelemedicine } from './InitialState'
import classNames from 'classnames';
import ServerSidePaginations from '../../components/Table/ServerSidePaginations';
import { get } from '../../helpers/API';
import moment from 'moment';
import { PrescriptionContext } from '../../store/prescriptions/PrescriptionData';
import { GlobalContext } from '../../store/PageWrapper/GlobalContext';
import { ACTION_TYPE } from '../../store/prescriptions/ActionType';
import qs from 'qs'


const NewTelemedicineContent = ({ goToPage }) => {
    const { globalData } = useContext(GlobalContext)
    const { dispatch } = useContext(PrescriptionContext)
    const [activeTab, setActiveTab] = useState('waiting_for_consultation');
    const [loading, setLoading] = useState(() => false)
    const [dataSource, setDataSource] = useState(initialDataTableTelemedicine)
    const [initialParams] = useState(initialValue);
    // const [count, setCount] = useState({
    //     waiting_for_consultation: 0,
    //     accepted: 0,
    //     history: 0
    // });

    const memorizedUser = globalData.global.user;
    const memorizedRole = globalData.global.role;
    const memorizedTeleCount = useMemo(() => { return globalData.global.tele_medicine.count }, [globalData.global.tele_medicine.count])
    const memorizedDispatch = useCallback(dispatch, [])
    const memorizedGoToPage = useCallback(goToPage, [])

    const getListTelemedicineConsultation = useCallback(async (
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords
    ) => {
        const is_path = window.location.pathname === '/tele-medicine' || window.location.pathname === '/tele-medicine/consultation'

        let arrayQuery = [];

        if (activeTab === 'waiting_for_consultation' || activeTab === 'accepted') {
            arrayQuery.push({ status: activeTab === 'waiting_for_consultation' ? 'pending' : 'accepted' })
        } else {
            arrayQuery.push({ status: ['completed', 'cancelled'] })
        }

        if (memorizedUser && memorizedUser.doctor && memorizedUser.doctor.id) {
            arrayQuery.push({ doctor: memorizedUser.doctor.id })
        }

        const query = qs.stringify({
            _start: page === 0 ? page : (page * 10) - 10,
            _where: arrayQuery
        })

        setDataSource(prev => {
            return {
                ...prev,
                rows: []
            }
        })

        if (!is_path || !memorizedUser || memorizedRole !== 'doctor') {

            if (memorizedTeleCount === 0 && activeTab === 'waiting_for_consultation') {
                return
            }
            
            return;
        }

        

        setDataSource(prev => {
            return {
                ...prev,
                rows: [{
                    index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                }]
            }
        })

        const _count = await get(`/consultations/count?${query}`)

        const query2 = qs.stringify({
            _sort: 'createdAt:DESC',
            _start: page === 0 ? page : (page * 10) - 10,
            _where: arrayQuery,
            _limit: _count
        })

        const _consultations = await get(`/consultations?${query2}`);

        const filterHasDoctor = _consultations.filter(x => x.doctor);
        const filterConsultWithDoc = filterHasDoctor.filter(x => x.doctor.id === memorizedUser.doctor.id);

        setDataSource(prev => {
            return {
                ...prev,
                rows: filterConsultWithDoc.map((item, ind) => {
                    return {
                        index: ind + 1,
                        consultation_name: <div><p className='m-0'>{item.consultation_name}</p></div>,
                        payment_status: <div className={handleStatusStyle(!item.payment_status ? item.status === 'cancelled' ? 'not_paid' : 'pending' : item.payment_status).badgeClass}>{handleStatusStyle(!item.payment_status ? item.status === 'cancelled' ? 'not_paid' : 'pending' : item.payment_status).text}</div>,
                        status: <div className={handleStatusStyle(item.status).badgeClass}>{handleStatusStyle(item.status).text}</div>,
                        date: moment(item.createdAt).format("MMM DD, YYYY h:mm A"),
                        clickEvent: async () => {
                            memorizedDispatch({
                                type: ACTION_TYPE.RESET_STATE
                            })
                            return memorizedGoToPage(`/tele-medicine/consultation/${item.id}`)
                        }
                    }
                })
            }
        });
    }, [memorizedUser, memorizedRole, memorizedDispatch, activeTab, memorizedGoToPage, memorizedTeleCount])

    useEffect(() => {
        getListTelemedicineConsultation(
            initialValue.sortBy,
            initialValue.orderBy,
            initialValue.limit,
            initialValue.page,
            initialValue.total,
            initialValue.category,
            initialValue.keywords
        )
    }, [getListTelemedicineConsultation])

    return (
        <React.Fragment>
            <h4 className='mb-4'>{`Telemedicine`}</h4>
            <Card className='card'>
                <CardBody className='pt-0'>
                    <Nav tabs className="nav-tabs-custom mb-4">
                        {TabTelemedicine.map((item, index) => {
                            return (
                                <NavItem key={index}>
                                    <NavLink onClick={() => { setActiveTab(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3 align-middle")}>
                                        {capitalizeFirstLetter(item.name)}
                                        {/* {<Badge
                                            color="light"
                                            pill
                                            className='align-middle ml-2'
                                        >
                                            {count[item.key]}
                                        </Badge>} */}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                    <ServerSidePaginations
                        loading={loading}
                        dataSource={dataSource}
                        getItems={(params) => { }}
                        emptyMessage="There are no consultation yet"
                        obj={obj}
                        initialParams={initialParams}
                        setLoading={setLoading}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default NewTelemedicineContent