import React from 'react'
import { Card, CardBody, Media } from 'reactstrap'
import Spinner from 'reactstrap/lib/Spinner'
import { TEXT_GENERAL_CARE_AIDE } from '../../Text'

const GeneralCareAideCount = (props) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{TEXT_GENERAL_CARE_AIDE}</p>
                            {!props.loading ? <h4 className="mb-0">{!props.typeOfProvider ? 0 : props.typeOfProvider.find(x => x.key === "General Care Aide").value}</h4> : <Spinner size='sm' color="primary" />}
                        </Media>
                        <div className="text-primary">
                            <i className={"ri-group-2-line font-size-24"}></i>
                        </div>
                    </Media>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default GeneralCareAideCount
