import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Card, CardBody, Button, CardText } from 'reactstrap'
import { get, update } from '../../../helpers/API';
import { MDBDataTable } from 'mdbreact';
import { converPriceWithCommas, handleStatusStyle } from '../../../store/CustomFunctions'
import { TEXT_PRODUCTS } from '../../../Text';
import AlertSuccess from '../../CustomComponent/Alert/AlertSuccess';
import AlertError from '../../CustomComponent/Alert/AlertError';
import { BookingSettingContext } from '../Store/BookingSetingContext';

const initialDataTable = {
    columns: [
        {
            label: "#",
            field: "index",
            width: '50',
        },
        {
            label: "Product name",
            field: "product",
        },
        {
            label: "Quantity",
            field: "quantity",
            width: "150"
        },
        {
            label: "Unit price (RM)",
            field: "unitPrice",
            width: "150"
        },
        {
            label: "Total (RM)",
            field: "total",
            width: "150"
        },
    ],
    rows: []
}

const ActionButton = ({ status, handleReject, handleApprove }) => {
    return (
        <div className="pt-3 pb-2 mt-3 text-right">
            {status === 'pending' &&
                <Row>
                    <Col>
                        <Button color="danger" onClick={handleReject}>Reject</Button>
                        <Button className='ml-2' color="success" onClick={handleApprove}>Approve</Button>

                    </Col>
                </Row>}
        </div>
    )
}
const NursingInvoiceDetails = (props) => {
    const [dataContext] = useContext(BookingSettingContext)
    const [dataSource, setDataSource] = useState(null);
    const [dataTable, setDataTable] = useState(initialDataTable)
    const [requiredService, setRequiredService] = useState([])
    const [additionalTreatment, setAdditionalTreatment] = useState([])
    const [sweetTimmer, setSweetTimmer] = useState(false)
    const [customSchedule, setCustomSchedule] = useState(null)
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ''
    })

    useEffect(() => {
        if (props.invoiceData.id) {
            get(`/invoices/${props.invoiceData.id}`).then(response => {
                setDataSource(response)
                if (response.booking_form) {
                    if (response.booking_form.schedule_mode === 'custom') {

                        if (response.booking_form.custom_schedule_custom_rate) {
                            setCustomSchedule({
                                ...response.booking_form.custom_schedule_custom_rate,
                                hours: `${response.booking_form.custom_schedule_custom_rate.hours} hours per day`
                            })
                        } else {
                            const scheduleType = response.booking_form.custom_schedule_rate;
                            const selected = dataContext.schedule_rate_price.find(x => x.value === scheduleType)
                            setCustomSchedule({
                                days: response.booking_form.custom_schedule_days ? `${response.booking_form.custom_schedule_days}` : 0,
                                hours: response.booking_form.custom_schedule_rate ? `${selected ? selected.totalHours : 0} hours per day` : `0 hours per day`,
                            })
                        }
                    } else {
                        // const scheduleType = response.booking_form.package_name
                        // const selected = dataContext.schedule_rate_price.find(x => x.value === scheduleType)
                    }
                }


                const dataRequiredService = response.line_items.find(item => item.title === 'Required Services')
                const dataAdditionalTreatment = response.line_items.find(item => item.title === 'Additional Treatments')

                if (dataRequiredService && dataRequiredService.items.length > 0) {
                    setRequiredService(dataRequiredService.items)
                }
                if (dataAdditionalTreatment && dataAdditionalTreatment.items.length > 0) {
                    setAdditionalTreatment(dataAdditionalTreatment.items)
                }

                const dataItems = response.line_items.find(item => item.title === 'Medical Supplies')
                if (dataItems && dataItems.items.length > 0) {
                    const mapping = dataItems.items.map((item, index) => {
                        return {
                            index: index + 1,
                            product: item.name,
                            quantity: item.quantity,
                            unitPrice: converPriceWithCommas(item.unit_price),
                            total: converPriceWithCommas(item.total)
                        }
                    })

                    setDataTable(prev => {
                        return {
                            ...prev,
                            rows: mapping
                        }
                    })
                }
            })
        }
    }, [props, dataContext])

    const handleBackButton = () => {
        return props.handleBack()
    }

    const handleApprove = () => {
        if (dataSource) {
            let payload = {
                status: 'completed'
            }
            update(`/invoices/${dataSource.id}`, payload).then(response => {
                setDataSource(response)
                setSweetTimmer(!sweetTimmer)
            }).catch(err => {
                setErrMsg({
                    visible: false,
                    msg: ''
                })
            })
        }
    }

    const handleReject = () => {
        if (dataSource) {
            let payload = {
                status: 'cancelled'
            }
            update(`/invoices/${dataSource.id}`, payload).then(response => {
                setSweetTimmer(!sweetTimmer)
                setDataSource(response)
            }).catch(err => {
                setErrMsg({
                    visible: false,
                    msg: ''
                })
            })
        }
    }
    const calculateSchedulePrice = (data) => {
        let countTotal = 0
        if (data && data.schedule_rate_price) {
            if (dataSource.booking_form.custom_schedule_custom_rate !== null) {
                const totalDays = dataSource.booking_form.custom_schedule_custom_rate.days
                const totalPrice = dataSource.booking_form.custom_schedule_custom_rate.price_rate
                const totalHours = dataSource.booking_form.custom_schedule_custom_rate.hours
                countTotal = totalPrice * totalHours * totalDays
            } else {
                const scheduleType = dataSource.booking_form.custom_schedule_rate
                const totalDays = dataSource.booking_form.custom_schedule_days
                const selected = data.schedule_rate_price.find(x => x.value === scheduleType)

                if (selected !== undefined) {
                    countTotal = selected.price * totalDays
                }
            }
        }
        return converPriceWithCommas(countTotal)
    }

    // const remapService = (array, type) => {
    //     return array.filter(item => item.is_tick).map(item => {
    //         return {
    //             ...item,
    //             totalPrice: item.custom_price ? item.custom_price : item[type].price
    //         }
    //     })
    // }

    // const remapMedical = (array) => {
    //     return array.map(item => {
    //         let price = 0
    //         if (item.item && item.item.variants && item.item.variants.length > 0) {
    //             price = item.item.variants[0].price
    //         }
    //         return {
    //             ...item,
    //             totalPrice: item.quantity * price
    //         }
    //     })
    // }

    // const updates = () => {
    //     // type_of_services
    //     const type_of_services = remapService(dataSource.booking_form.type_of_services, 'service')
    //     const result_type_of_service = type_of_services.map(item => { return item.totalPrice }).reduce((a, b) => a + b, 0)
    //     // additional_treatments
    //     const additional_treatments = remapService(dataSource.booking_form.additional_treatments, 'treatment')
    //     const result_additional_treatments = additional_treatments.map(item => { return item.totalPrice }).reduce((a, b) => a + b, 0)
    //     // medical_supplies
    //     const medical_supplies = remapMedical(dataSource.booking_form.medical_supplies)
    //     const result_medical_supplies = medical_supplies.map(item => { return item.totalPrice }).reduce((a, b) => a + b, 0)

    //     // service price
    //     let service_of_price = 0
    //     if (dataContext && dataContext.schedule_rate_price) {
    //         if (dataSource.booking_form.custom_schedule_custom_rate) {
    //             const totalDays = dataSource.booking_form.custom_schedule_custom_rate.days
    //             const totalPrice = dataSource.booking_form.custom_schedule_custom_rate.price_rate
    //             const totalHours = dataSource.booking_form.custom_schedule_custom_rate.hours
    //             service_of_price = totalPrice * totalHours * totalDays
    //         } else {
    //             const scheduleType = dataSource.booking_form.custom_schedule_rate
    //             const totalDays = dataSource.booking_form.custom_schedule_days
    //             const selected = dataContext.schedule_rate_price.find(x => x.value === scheduleType)

    //             if (selected !== undefined) {
    //                 service_of_price = selected.price * selected.totalHours * totalDays
    //             }
    //         }
    //     }

    //     const subtotal = result_type_of_service + result_additional_treatments + result_medical_supplies
    // }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {props.invoiceData && props.invoiceData.title ?
                        <div>
                            <Button color="link" className="pl-0" style={{ textDecoration: "none" }} onClick={handleBackButton}>
                                <h4><i className="ri-arrow-left-s-line mr-1 align-middle" />{props.invoiceData.title}</h4>
                            </Button>
                            {/* <Button onClick={updates}>Update</Button> */}
                        </div> : <h4>{TEXT_PRODUCTS}</h4>}

                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    {customSchedule && dataContext && <div className='pb-4 border-bottom'>
                                        <h5>General Care Service (non-nursing)</h5>

                                        <MDBDataTable
                                            paging={false}
                                            scrollX
                                            searching={false}
                                            displayEntries={false}
                                            hover
                                            data={{
                                                columns: [
                                                    {
                                                        label: '#',
                                                        field: 'index',
                                                        width: 50
                                                    },
                                                    {
                                                        label: 'Name',
                                                        field: 'name',
                                                        width: '45%'
                                                    },
                                                    {
                                                        label: 'Total (RM)',
                                                        field: 'total',
                                                        width: 200
                                                    }
                                                ],
                                                rows: [{
                                                    index: '1',
                                                    name: `Booking for ${customSchedule.days} days, ${customSchedule.hours}`,
                                                    total: calculateSchedulePrice(dataContext)
                                                }]
                                            }}
                                        />

                                    </div>}

                                    {requiredService.length > 0 && <div className='py-4 border-bottom'>
                                        <h5>Required Services</h5>

                                        <MDBDataTable
                                            paging={false}
                                            scrollX
                                            searching={false}
                                            displayEntries={false}
                                            hover
                                            data={{
                                                columns: [
                                                    {
                                                        label: '#',
                                                        field: 'index',
                                                        width: 50
                                                    },
                                                    {
                                                        label: 'Name',
                                                        field: 'name',
                                                        width: '45%'
                                                    },
                                                    {
                                                        label: 'Total (RM)',
                                                        field: 'total',
                                                        width: 200
                                                    }
                                                ],
                                                rows: requiredService.map((item, index) => {
                                                    return {
                                                        index: index + 1,
                                                        name: item.name,
                                                        total: converPriceWithCommas(item.total)
                                                    }
                                                })
                                            }}
                                        />

                                    </div>}

                                    {additionalTreatment.length > 0 && <div className='py-4 border-bottom'>
                                        <h5>Additional Treatment</h5>

                                        <MDBDataTable
                                            paging={false}
                                            scrollX
                                            searching={false}
                                            displayEntries={false}
                                            hover
                                            data={{
                                                columns: [
                                                    {
                                                        label: '#',
                                                        field: 'index',
                                                        width: 50
                                                    },
                                                    {
                                                        label: 'Name',
                                                        field: 'name',
                                                        width: '45%'
                                                    },
                                                    {
                                                        label: 'Total (RM)',
                                                        field: 'total',
                                                        width: 200
                                                    }
                                                ],
                                                rows: additionalTreatment.map((item, index) => {
                                                    return {
                                                        index: index + 1,
                                                        name: item.name,
                                                        total: converPriceWithCommas(item.total)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>}

                                    {dataTable.rows.length > 0 &&
                                        <div className='py-4'>
                                            <h5 className='mb-2'>Medical Supplies</h5>

                                            <MDBDataTable
                                                paging={false}
                                                scrollX
                                                searching={false}
                                                displayEntries={false}
                                                hover
                                                data={dataTable}
                                            />
                                        </div>}
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <div className="border-bottom border-top pt-3 pb-2 mb-3">
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <h5 className="font-size-14">Your Order</h5>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="d-flex flex-row-reverse">{dataSource && <div className={handleStatusStyle(dataSource.status).badgeClass}>{handleStatusStyle(dataSource.status).text}</div>}</div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <CardText>Invoice No.</CardText>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <CardText className="text-muted text-right">{dataSource && dataSource.invoice_number}</CardText>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="border-bottom border-top pt-3 pb-2 mt-3 mb-3">
                                        <Row>
                                            <Col><h5 className="font-size-14">Order</h5></Col>
                                        </Row>
                                    </div>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <CardText>SubTotal (RM)</CardText>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <CardText className="text-muted text-right"> {dataSource && converPriceWithCommas(dataSource.subtotal)}</CardText>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <CardText>Deposit (RM)</CardText>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <CardText className="text-muted text-right"> {dataSource && converPriceWithCommas(dataSource.deposit_fee)}</CardText>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col sm={12} md={6}>
                                            <CardText>Transportation Fee (RM)</CardText>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <CardText className="text-muted text-right"> {dataSource && converPriceWithCommas(dataSource.transportation_fee)}</CardText>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <CardText>Processing fee (RM)</CardText>
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <CardText className="text-muted text-right"> {dataSource && converPriceWithCommas(dataSource.processing_fee)}</CardText>
                                        </Col>
                                    </Row>

                                    <div className="border-bottom border-top pt-3 pb-2 mt-3 mb-3">
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <h5 className="font-size-14">TOTAL (RM)</h5>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <h5 className="text-right">{dataSource && converPriceWithCommas(dataSource.subtotal + dataSource.deposit_fee + dataSource.processing_fee + dataSource.transportation_fee)}</h5>
                                            </Col>
                                        </Row>
                                    </div>

                                    <ActionButton status={dataSource && dataSource.status} handleApprove={handleApprove} handleReject={handleReject} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <AlertSuccess
                        title={'Success'}
                        sweetTimmer={sweetTimmer}
                        setSweetTimmer={setSweetTimmer}
                    />
                    <AlertError
                        title={'ERROR'}
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NursingInvoiceDetails