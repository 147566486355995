import React from 'react'
import { Col, Row } from 'reactstrap'
import ApplicationsCount from '../ApplicationsCount'
import ChartApplicationStatus from './ChartApplicationStatus'
import ChartProvider from '../ChartProvider'
import GeneralCareAideCount from '../GeneralCareAideCount'
import MedicalAssistant from '../MedicalAssistant'
import NurseCount from '../NurseCount'
import PhiseotheraphyCount from '../PhiseotheraphyCount'


const initialData = {
    labels: [
        "New",
        "Approved",
        "KIV",
        "Rejected"
    ],
    datasets: [
        {
            data: [0, 0, 0, 0],
            backgroundColor: [
                "rgba(235, 239, 242, 1)",
                "rgba(25, 171, 79, 1)",
                'RGBA(74,163,255,1)',
                'rgba(255, 61, 96, 1)',
            ],
            hoverBackgroundColor: [
                "rgba(235, 239, 242, 0.5)",
                "rgba(25, 171, 79, 0.5)",
                'RGBA(74,163,255,0.5)',
                'rgba(255, 61, 96, 1)',
            ],
            hoverBorderColor: "rgba(255, 255, 255, 1)"
        }]
};


const ProviderContent = ({
    providers
}) => {
    return (
        <div>
            <h4 className='mb-4'>{`Dashboard - Provider`}</h4>
            <Row>
                <Col md={4}>
                    <ApplicationsCount
                        icon="ri-folder-4-line"
                        title="Total Applications"
                        count={providers.totalApplications.total}
                        loading={providers.totalApplications.loading}
                    />

                    <ChartApplicationStatus
                        initialData={initialData}
                        loading={providers.applicationStatus.loading}
                        countProcessing={providers.applicationStatus.processing}
                        countApproved={providers.applicationStatus.approved}
                        countRejected={providers.applicationStatus.rejected}
                        countKiv={providers.applicationStatus.kiv}
                    />
                </Col>
                <Col md={4}>
                    <ChartProvider
                        title='Type of providers'
                        loading={providers.typeOfProvider.loading}
                        typeOfProvider={providers.typeOfProvider.analytics}
                    />
                </Col>
                <Col md={4}>
                    <Row>
                        <Col md={6}>
                            <NurseCount loading={providers.typeOfProvider.loading} typeOfProvider={providers.typeOfProvider.analytics} />
                        </Col>
                        <Col md={6}>
                            <MedicalAssistant loading={providers.typeOfProvider.loading} typeOfProvider={providers.typeOfProvider.analytics} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <PhiseotheraphyCount loading={providers.typeOfProvider.loading} typeOfProvider={providers.typeOfProvider.analytics} />
                        </Col>
                        <Col md={6}>
                            <GeneralCareAideCount loading={providers.typeOfProvider.loading} typeOfProvider={providers.typeOfProvider.analytics} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default ProviderContent
