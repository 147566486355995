import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, CardImg, Col, Row } from 'reactstrap'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import img1 from '../../../../assets/images/selcare-big.png'
import { capitalizeFirstLetter } from '../../../../store/CustomFunctions'
import moment from 'moment'
import TablePrescriptionItems from './TablePrescriptionItems'
import { getAPIroot } from '../../../../helpers/API'
import FooterActions from '../Form/FooterActions'
import ModalUpdatePrescription from '../Form/Modal/ModalUpdatePrescription'
import { getCorporateOrganizationById, putPrescription } from '../../../../store/prescriptions/_api'
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType'
import AlertSuccess from '../../../CustomComponent/Alert/AlertSuccess'

const PrescriptionDoc = ({ goToPage }) => {
    const { globalData } = useContext(GlobalContext)
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)
    const [breadcrumbItems, setBreadcrumbItems] = useState([])
    const [showWaterMark, setShowWaterMark] = useState(false)
    const [temporary, setTemporary] = useState(null)
    const [signature, setSignature] = useState(null)
    const [clinicInfo, setClinicInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [visibleModalUpdatePrescription, setVisibleModalUpdatePrescription] = useState(false)
    const [sweetTimmer, setSweetTimmer] = useState(false)

    useEffect(() => {
        let isMounted = true
        async function fecthing() {
            const response = await getCorporateOrganizationById(prescriptionDataLayer.prescription.doctor.corporate_organisation)
            if (isMounted) {
                setClinicInfo(response)
            }

        }
        if (prescriptionDataLayer.prescription && isMounted) {
            setTemporary(prescriptionDataLayer.prescription)
            for (let i = 0; i < prescriptionDataLayer.prescription.prescription_items.length; i++) {
                if (prescriptionDataLayer.prescription.prescription_items[i].amount === prescriptionDataLayer.prescription.prescription_items[i].supply_amount) {
                    setShowWaterMark(true)
                }
            }

            if (prescriptionDataLayer.prescription.doctor) {
                if (prescriptionDataLayer.prescription.doctor.signature && prescriptionDataLayer.prescription.doctor.signature.url) {
                    setSignature(prescriptionDataLayer.prescription.doctor.signature.url)
                }

                if (prescriptionDataLayer.prescription.doctor.corporate_organisation) {
                    fecthing()
                }
            }
        }
        return () => isMounted = false
    }, [prescriptionDataLayer.prescription])

    useEffect(() => {
        let array = [];

        if (prescriptionDataLayer.content_type === 'prescription') {
            if (prescriptionDataLayer.prescription) {
                array = [
                    { title: "Home", link: { pathname: '/e-prescription/waiting-approval', state: { title: 'Home', filter: '' } } },
                    { title: 'List', link: { pathname: `/e-prescription/waiting-approval`, state: { title: 'Details', filter: '' } } },
                    { title: prescriptionDataLayer.prescription.prescription_name, link: { pathname: `/e-prescription/waiting-approval`, state: { title: 'Details', filter: '' } } },
                ]

                setBreadcrumbItems(array)
            }
        }
    }, [prescriptionDataLayer.content_type, prescriptionDataLayer.prescription])

    return (
        <React.Fragment>
            <Breadcrumbs title={prescriptionDataLayer.action_type.title} breadcrumbItems={breadcrumbItems} />

            <Card className='overflow-hidden position-relative w-100'>
                <CardBody className='overflow-hidden'>
                    {showWaterMark && <div className='parent-water-mark'>
                        <div className='water-mark'>
                            <p>SUPPLIED</p>
                        </div>
                    </div>}

                    <div className='p-4 mb-4'>
                        <Row className='mb-4'>
                            <Col>
                                <CardImg top className="img-fluid pl-0 mb-4" src={img1} alt="img-selcare" style={{ width: 170 }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='d-flex'>
                                    <p className='text-secandary m-0'>Prescription ID</p>
                                    <p className='ml-2'>: {prescriptionDataLayer.prescription && prescriptionDataLayer.prescription.prescription_name}</p>
                                </div>
                            </Col>
                        </Row>

                        <div className='my-3'>
                            <div>
                                <h5>Patient Personal Info</h5>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <div className='w-100'>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Name</p>
                                        </Col>
                                        <Col >
                                            <p className='text-secandary'>: {capitalizeFirstLetter(prescriptionDataLayer.patient && prescriptionDataLayer.patient.user.username)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>NRIC</p>
                                        </Col>
                                        <Col >
                                            <p className='text-secandary'>: {prescriptionDataLayer.patient && prescriptionDataLayer.patient.nric}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Gender</p>
                                        </Col>
                                        <Col >
                                            <p className='text-secandary'>: {prescriptionDataLayer.patient && capitalizeFirstLetter(prescriptionDataLayer.patient.gender)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>D.O.B</p>
                                        </Col>
                                        <Col >
                                            <p className='text-secandary'>: {prescriptionDataLayer.patient && moment(prescriptionDataLayer.patient.date_of_birth).format('DD MMMM YYYY')}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='w-100'>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Phone No.:</p>
                                        </Col>
                                        <Col>
                                            <p className='text-secandary'>: {prescriptionDataLayer.patient && prescriptionDataLayer.patient.default_phone}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Address</p>
                                        </Col>
                                        <Col>
                                            <p className='text-secandary ml-2'>{'-'}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='w-100'>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Allergies</p>
                                        </Col>
                                        <Col>
                                            <p className='text-secandary ml-2'>: {prescriptionDataLayer.patient && prescriptionDataLayer.patient.patient_biodata && prescriptionDataLayer.patient.patient_biodata.allergy}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Weight</p>
                                        </Col>
                                        <Col>
                                            <p className='text-secandary ml-2'>: {prescriptionDataLayer.patient && prescriptionDataLayer.patient.patient_biodata && prescriptionDataLayer.patient.patient_biodata.weight}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>Height</p>
                                        </Col>
                                        <Col>
                                            <p className='text-secandary ml-2'>: {prescriptionDataLayer.patient && prescriptionDataLayer.patient.patient_biodata && prescriptionDataLayer.patient.patient_biodata.height}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3}>
                                            <p className='m-0'>BMI</p>
                                        </Col>
                                        <Col>
                                            <p className='text-secandary ml-2'>: {prescriptionDataLayer.patient && prescriptionDataLayer.patient.patient_biodata && prescriptionDataLayer.patient.patient_biodata.bmi}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {prescriptionDataLayer.prescription && prescriptionDataLayer.prescription.diagnosis.length > 0 && <Row className='my-3'>
                            <Col lg={12}>
                                <h5 className='mb-3'>Diagnosis</h5>

                                {prescriptionDataLayer.prescription.diagnosis.map((item, index) => {
                                    return (
                                        <div className={index + 1 === prescriptionDataLayer.prescription.diagnosis.length ? 'd-flex mb-2 justify-content-between' : 'd-flex mb-2 justify-content-between border-bottom'
                                        } key={index} >
                                            <div>
                                                <p className='mb-1'>{item.icd}</p>
                                                <p className='text-secandary'>{item.remarks}</p>
                                            </div>
                                        </div >
                                    )
                                })}
                            </Col>
                        </Row>}

                        <Row className='my-3'>
                            <Col lg={12}>
                                <h5 className='mb-3'>Medication</h5>

                                <TablePrescriptionItems
                                    dataSource={temporary}
                                    hideBtnSupply={showWaterMark}
                                    role={globalData.global.role}

                                    handleUpdateSupplyAmount={(value, data) => {
                                        const newData = [...temporary.prescription_items];
                                        const index = newData.findIndex(item => item.id === data.id)
                                        const items = newData[index]

                                        let newObj = {
                                            ...items,
                                            supply_amount: value
                                        }

                                        newData.splice(index, 1, { ...items, ...newObj })

                                        setTemporary(prev => {
                                            return {
                                                ...prev,
                                                prescription_items: newData
                                            }
                                        })
                                    }}
                                    handleIncrement={(value) => {
                                        const newData = [...temporary.prescription_items];
                                        const index = newData.findIndex(item => item.id === value.id)
                                        const items = newData[index]

                                        let newObj = {
                                            ...items,
                                            supply_amount: items.supply_amount + 1
                                        }

                                        newData.splice(index, 1, { ...items, ...newObj })

                                        setTemporary(prev => {
                                            return {
                                                ...prev,
                                                prescription_items: newData
                                            }
                                        })
                                    }}
                                    handleDecrement={(value) => {
                                        const newData = [...temporary.prescription_items];
                                        const index = newData.findIndex(item => item.id === value.id)
                                        const items = newData[index]

                                        let newObj = {
                                            ...items,
                                            supply_amount: items.supply_amount > 0 ? items.supply_amount - 1 : 0
                                        }

                                        newData.splice(index, 1, { ...items, ...newObj })

                                        setTemporary(prev => {
                                            return {
                                                ...prev,
                                                prescription_items: newData
                                            }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col lg={12}>
                                <p className='m-0'>Prescribed electronically by:</p>
                                <h6>{temporary && temporary.doctor && (temporary.doctor.name)}</h6>
                                <div className='p-4 border-dashed d-inline-block'>
                                    {signature && <CardImg className="avatar-md" src={getAPIroot() + signature} />}
                                </div>

                                {clinicInfo && <div className='mb-4'>
                                    <p className='text-bold m-0'>{clinicInfo.org_name}</p>
                                    <p className='text-secandary m-0'>{clinicInfo.address && clinicInfo.address.address1 + ', '}{clinicInfo.address && clinicInfo.address.address2 + ', '}</p>
                                    <p className='text-secandary m-0'>{clinicInfo.address && clinicInfo.address.city + ', '}{clinicInfo.address && clinicInfo.address.province + ', '}{clinicInfo.address && clinicInfo.address.zipcode + ', '}</p>
                                    <p className='text-secandary m-0'>{clinicInfo.address && clinicInfo.address.country}</p>
                                </div>}
                            </Col>
                            <Col lg={12}>
                                <div className='mt-3'>
                                    <p className='text-secandary m-0'>This e-Prescription is not valid for self dispensary unless specifically supplied by licensed pharmacists.</p>
                                    <p className='text-secandary m-0'>This copy of e-Prescription is electronically generated.</p>
                                    <p className='text-secandary m-0'>This of e-Prescription is Digitally Signed in the system in compliance to Digital Signature Act 1997 in Malaysia.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='footer-container px-4'>
                        <FooterActions
                            goToPage={goToPage}
                            openModalUpdateConsultation={() => setVisibleModalUpdatePrescription(true)}
                        />
                    </div>
                </CardBody>
            </Card>

            <ModalUpdatePrescription
                visible={visibleModalUpdatePrescription}
                loading={loading}
                isClosed={() => setVisibleModalUpdatePrescription(false)}
                onSubmit={async () => {
                    setLoading(true)
                    let payload = {
                        prescription_items: temporary.prescription_items.map(x => {
                            return {
                                id: x.id,
                                supply_amount: x.supply_amount
                            }
                        })
                    }
                    const prescriptionData = await putPrescription(payload, prescriptionDataLayer.prescription.id);
                    if (prescriptionData) {
                        dispatch({
                            type: ACTION_TYPE.SET_PRESCRIPTION,
                            payload: {
                                data: prescriptionData,
                                role: globalData.global.role
                            }
                        })
                        setLoading(false)
                        setVisibleModalUpdatePrescription(false)
                        setSweetTimmer(true)
                    }

                }}
            />
            <AlertSuccess setSweetTimmer={setSweetTimmer} sweetTimmer={sweetTimmer} title="Saved!" />
        </React.Fragment>
    )
}

export default PrescriptionDoc