import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Spinner } from 'reactstrap';
import ServerSidePaginations from '../../components/Table/ServerSidePaginations';
import { get } from '../../helpers/API';
import classNames from 'classnames';

const TabInvoice = [
    {
        key: 'All',
        name: 'All'
    }
]


const obj = {
    path: '/doctors',
    title: "Doctors Management",
    filter: "Doctors Management"
}

let initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: '',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

const initialDataTable = {
    columns: [
        {
            label: "#",
            field: "index",
            sort: "asc",
            width: 50
        },
        {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 300
        },
        {
            label: "Clinics",
            field: "clinics",
            sort: "asc",
        },
        {
            label: "Availability",
            field: "availability",
            sort: "asc",
            width: 200
        },
        {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 140
        },
    ]
}

const DoctorPage = () => {
    const [activeTab, setActiveTab] = useState('All')
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [loading, setLoading] = useState(() => false)
    const [initialParams, setInitialParams] = useState(initialValue);


    const getDoctors = useCallback((
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords
    ) => {
        setLoading(true);

        setDataSource(prev => {
            return {
                ...prev,
                rows: [{
                    index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                }]
            }
        })

        let params = ""
        let url = new URLSearchParams(params)

        url.set('_start', page === 0 ? page : (page * 10) - 10);
        url.set('_sort', sortBy);

        get(`/doctors/count?${url.toString()}`).then(count => {
            url.set('_limit', count);
            get(`/doctors?${url.toString()}`).then(response => {
                // console.log(response);
                const mapping = response.map((item, index) => {
                    let clinics = ''
                    if (item.corporate_organisation) {
                        clinics = item.corporate_organisation.org_name
                    }

                    let availability = ''
                    if (item.is_online) {
                        availability = <div className={item.is_online ? 'badge badge-pill badge-soft-success font-size-12' : 'badge badge-pill badge-soft-danger font-size-12'}>{item.is_online ? 'Online' : 'Offline'}</div>
                    }

                    const action = <div>
                        <Button className='mr-2' color='light'><i className="ri-pencil-line"></i></Button>
                        <Button color='light'><i className="ri-delete-bin-line"></i></Button>
                    </div>
                    return {
                        index: index + 1,
                        name: item.name,
                        clinics,
                        availability,
                        action,
                    }
                })

                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    total: mapping.length,
                    page,
                    category,
                    keywords
                })

                setDataSource(prev => {
                    return {
                        ...prev,
                        rows: mapping
                    }
                });
                setLoading(false)
            })
        })
    }, [])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getDoctors(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                initialValue.keywords
            )
        }

        return () => {
            mount = true
        }
    }, [getDoctors])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <h4 className='mb-4'>{`Doctors Management`}</h4>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0">
                                    <Nav tabs className="nav-tabs-custom mb-4">
                                        {TabInvoice.map((item, index) => {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink onClick={() => { setActiveTab(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3")}>{item.name}</NavLink>
                                                </NavItem>
                                            )
                                        })}
                                    </Nav>

                                    <ServerSidePaginations
                                        loading={loading}
                                        dataSource={dataSource}
                                        getItems={(params) => {
                                            getDoctors(
                                                params.sortBy,
                                                params.orderBy,
                                                params.limit,
                                                params.page,
                                                params.total,
                                                params.category,
                                                params.keywords
                                            )
                                        }}
                                        emptyMessage="There are no provider yet"
                                        obj={obj}
                                        initialParams={initialParams}
                                        setLoading={setLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    )
}

export default DoctorPage
