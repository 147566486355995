import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardText, Col, Collapse, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { getAPIroot } from '../../../helpers/API';
import { converPriceWithCommas } from '../../../store/CustomFunctions';
import imgEmpty from '../../../assets/images/no_img.png'
import { PaginationButton } from '../../CustomComponent/Pagination/PaginationButton';
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext';
import { Link } from 'react-router-dom';
import ListLoading from '../../CustomComponent/Loading/ListLoading';
import LoadingCard from '../../CustomComponent/Loading/CardLoading';

const reducer = (state, { type, payload }) => {
    const newData = [...state.cart];
    let index;
    let selected;
    if (payload) {
        index = newData.findIndex(x => x.id === payload.id)
        selected = newData.find(x => x.id === payload.id)
    }
    switch (type) {
        case 'ADD_ITEM':
            const variants = payload.variants && payload.variants.length > 0 && payload.variants[0];
            const variant = variants && variants;
            return {
                ...state,
                cart: [...state.cart, { ...payload, quantity: 1, variants: [{...variant, quantity_supplied: 0}] }]
            }
        case 'INCREMENT_ITEM':
            let incItem = {
                ...selected,
                quantity: selected.quantity + 1
            }

            newData.splice(index, 1, { ...selected, ...incItem })
            return {
                ...state,
                cart: newData
            }
        case 'DECREMENT_ITEM':
            if (selected.quantity === 1) return {
                ...state,
                cart: newData
            }

            let decItem = {
                ...selected,
                quantity: selected.quantity - 1
            }

            newData.splice(index, 1, { ...selected, ...decItem })
            return {
                ...state,
                cart: newData
            }
        case 'UPDATE_ITEM':
            newData.splice(index, 1, { ...selected, ...payload })
            return {
                ...state,
                cart: newData
            }
        case 'REMOVE_ITEM':
            const newItems = newData.filter(x => x.id !== payload.id);
            return {
                ...state,
                cart: newItems
            }
        case 'ADD_CURRENT_ITEM':
            return {
                ...state,
                cart: payload
            }
        case 'RESET_CART':
            return {
                cart: []
            }
        default:
            return
    }

}
const initialState = {
    cart: []
}

const StoreProductModal = ({ visibleModal, toggleModal, products, onSubmit, currentItem, onSearch, getItems, initialParams, categories, category }) => {
    const { globalData } = useContext(GlobalContext)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [loading, setLoading] = useState(true)
    const [loadingProduct, setLoadingProduct] = useState(true)
    const [normalPrice, setNormalPrice] = useState(true)
    const [selectedCategory, setSelected] = useState(null)
    const [menu, setMenu] = useState(null)
    const [timer, setTimer] = useState(null);
    const listOfCategories = useMemo(() => {
        return categories
    }, [categories])


    useEffect(() => {
        if (!visibleModal) {
            setSelected(null)
            setMenu(null)
            setTimer(null)
            setSelected(null)
        }
    }, [visibleModal])

    useEffect(() => {
        if (listOfCategories.length > 0) {
            setLoadingProduct(false);
            setLoading(false);
        }
       
    }, [listOfCategories])


    useEffect(() => {
        let isMounted = false;
        if (!isMounted && products.length > 0) {
            setLoadingProduct(false)
        }
        return () => {
            isMounted = true
        }
    }, [products])

    const handleSelectMenu = (val) => {
        setLoadingProduct(true)
        setMenu(val)
        getItems({
            sortBy: initialParams.sortBy,
            orderBy: initialParams.orderBy,
            limit: initialParams.limit,
            page: initialParams.page,
            total: initialParams.total,
            category: val,
            keywords: initialParams.keywords
        })
    }

    useEffect(() => {
        const _userType = globalData.global.role;
        if (_userType && !_userType.includes('corporate')) {
            setNormalPrice(false)
        }
    }, [globalData.global.role])

    const addItem = (product) => {
        dispatch({
            type: 'ADD_ITEM',
            payload: product
        })
    }
    const increment = (product) => {
        dispatch({
            type: 'INCREMENT_ITEM',
            payload: product
        })
    }
    const decrement = (product) => {
        dispatch({
            type: 'DECREMENT_ITEM',
            payload: product
        })
    }

    const removeItem = (product) => {
        dispatch({
            type: 'REMOVE_ITEM',
            payload: product
        })
    }
    const manualyKeyIn = (product, value) => {
        dispatch({
            type: 'UPDATE_ITEM',
            payload: {
                ...product,
                quantity: parseInt(value)
            }
        })
    }

    const handleCancel = () => {
        toggleModal()
        dispatch({
            type: 'RESET_CART',
        })
    }

    const handleSubmit = () => {
        toggleModal()
        onSubmit(state.cart)
        dispatch({
            type: 'RESET_CART',
        })
    }

    useEffect(() => {
        dispatch({
            type: 'ADD_CURRENT_ITEM',
            payload: currentItem
        })
    }, [currentItem, visibleModal])

    const handleSearchProduct = (value) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(setTimeout(() => {
            onSearch(value)
        }, 500));
    }

    const handleSelectCategory = (index, data) => {
        const values = data.children && data.children.length && data.children[0].id;
        setMenu(null)
        if (selectedCategory !== data.key) {
            setSelected(index)

            if (values) {
                setMenu(values)
                setLoadingProduct(true)
                getItems({
                    sortBy: initialParams.sortBy,
                    orderBy: initialParams.orderBy,
                    limit: initialParams.limit,
                    page: initialParams.page,
                    total: initialParams.total,
                    category: values,
                    keywords: initialParams.keywords
                })
            }

        } else {
            setSelected(null)
            setMenu(null)
        }
    }

    const clearFilters = () => {
        getItems({
            sortBy: initialParams.sortBy,
            orderBy: initialParams.orderBy,
            limit: initialParams.limit,
            page: initialParams.page,
            total: initialParams.total,
            category: null,
            keywords: initialParams.keywords
        })
        setSelected(null)
        setMenu(null)
    }
    return (
        <Modal
            isOpen={visibleModal}
            toggle={toggleModal}
            size="xl"
            fullscreen="true"
            scrollable={true}
        >
            <ModalHeader>
                Add Product From Store
            </ModalHeader>
            <ModalBody>
                <Row className='overflow-hidden'>
                    <Col className='position-relative'>
                        <Card className="position-sticky shadow-none border m-0">
                            <CardHeader className="bg-transparent border-bottom">
                                <h5 className="mb-0">Filters</h5>
                            </CardHeader>
                            <CardBody className="pb-3 d-flex justify-content-between align-items-center">
                                <h5 className="font-size-14 mb-0 w-100">Categories</h5>
                                {selectedCategory && menu ? <Button className='border-0' color='danger' outline size='sm' onClick={() => clearFilters()}>Clear</Button> : null}
                            </CardBody>
                            <CardBody>
                                <div>
                                    {loading ? <ListLoading row={8} /> : categories && categories.length > 0 && categories.map((x, i) => {
                                        return (
                                            <div key={i + 1} className="custom-accordion categories-accordion mb-3">
                                                {x.children && x.children.length > 0 ?
                                                    <div className="categories-group-card">
                                                        <Link to="#" onClick={() => handleSelectCategory(i + 1, x)} className={selectedCategory === x.key ? "categories-group-list accordian-bg-products rounded" : "categories-group-list"}>
                                                            {`${x.title} ${!x.products ? `` : `(${x.products.length})`}`}
                                                            <i className={selectedCategory === x.key ? "mdi mdi-minus float-right accor-minus-icon" : "mdi mdi-plus float-right accor-plus-icon"}></i>
                                                        </Link>
                                                        <Collapse isOpen={selectedCategory === x.key ? true : false} id={`collapseOne`}>
                                                            <div>
                                                                <ul className="list-unstyled categories-list mb-0">
                                                                    {x.children.map((item) => {
                                                                        return (
                                                                            <li key={item.id} onClick={() => handleSelectMenu(item.id)}>
                                                                                <Link to="#" className={menu === item.id ? "categories-group-list accordian-bg-products bg-primary text-white rounded" : "categories-group-list"}><i className="mdi mdi-circle-medium mr-1"></i> {`${item.title} (${item.products_count})`}</Link>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </Collapse>
                                                    </div> :
                                                    <div className="categories-group-card">
                                                        <Link to="#" onClick={() => {
                                                            setSelected((i + 1))
                                                            handleSelectMenu(x.id)
                                                        }} className={selectedCategory === x.key ? "categories-group-list accordian-bg-products bg-primary text-white rounded" : "categories-group-list"}>
                                                            {`${x.title} (${x.products.length})`}
                                                        </Link>
                                                    </div>}
                                            </div>
                                        )
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={9}>
                        <div className='mb-3'>
                            <h5>Medication</h5>
                            <Input placeholder='Search...' className='border-light' onChange={(e) => handleSearchProduct(e.target.value)} />
                        </div>
                        <div className='position-relative d-flex flex-wrap'>
                            {loadingProduct ? <LoadingCard row={8} /> : products.length > 0 ? products.map((product, index) => {
                                return (
                                    <Card key={index} className="m-0  rounded-0 shadow-none border" style={{ width: 'calc(100% / 4)' }}>
                                        <CardBody className='text-center' style={{ paddingTop: 12 }}>
                                            {product.variants.length > 0 ? !product.variants[0].image || product.variants[0].image.length === 0 ? <CardImg src={imgEmpty} alt="img" /> : <CardImg className="avatar-lg mb-2" src={getAPIroot() + product.variants[0].image[0].url} alt="img" /> : <CardImg src={imgEmpty} alt="img" />}
                                            <CardText>
                                                {product.title}
                                            </CardText>
                                            <CardText>
                                                {product.prod_description}
                                            </CardText>
                                        </CardBody>
                                        <CardFooter className='bg-white'>
                                            <p className='small mb-0 text-muted text-center'>Customer Price</p>
                                            {product.variants.length > 0 ? <h5 className={`mb-3 text-center ${normalPrice ? 'text-muted' : 'text-primary'}`} style={{ textDecoration: normalPrice ? 'line-through' : 'none' }}>
                                                RM {converPriceWithCommas(product.variants[0].price)}
                                            </h5> : 'RM 0.00'}
                                            <p className='small mb-0 text-muted text-center'>Corporate Price</p>
                                            {product.variants.length > 0 ? <h5 className='text-primary mb-3 text-center'>
                                                RM {converPriceWithCommas(product.variants[0].corporate_price)}
                                            </h5> : 'RM 0.00'}
                                            <div className='d-flex justify-content-between align-items-center'>
                                                {state.cart.length > 0 && state.cart.find(x => x.id === product.id) ?
                                                    <div>
                                                        <div className="product-cart-touchspin w-100">
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend" className="input-group-btn">
                                                                    <Button color="primary" className="bootstrap-touchspin-down" onClick={() => decrement(product)}><i className="ri-subtract-line"></i></Button>
                                                                </InputGroupAddon>
                                                                <Input className='custom-number-input' type="number" min="0" value={state.cart.find(x => x.id === product.id).quantity} onChange={(e) => {
                                                                    manualyKeyIn(product, e.target.value)
                                                                }} />
                                                                <InputGroupAddon addonType="append" className="input-group-btn">
                                                                    <Button color="primary" className="bootstrap-touchspin-down" onClick={() => increment(product)}><i className="ri-add-line"></i></Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                        <Button color='danger' className='w-100 mt-2' onClick={() => removeItem(product)}><i className="mdi mdi-trash-can mr-1"></i>Remove</Button>
                                                    </div> :
                                                    <Button color='primary' outline className='w-100' onClick={() => addItem(product)}>Add</Button>}
                                            </div>
                                        </CardFooter>
                                    </Card>
                                )
                            }) : null}
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div className='d-flex align-items-center w-100'>
                    <div className='flex-grow-1 mr-4'>
                        <PaginationButton
                            getItems={getItems}
                            initialParams={initialParams}
                            setLoading={setLoadingProduct}
                            loading={loadingProduct}
                        />
                    </div>
                    <div>
                        <Button color='light' onClick={() => handleCancel()} className='mr-1'>Cancel</Button>
                        <Button color='primary' onClick={() => handleSubmit()}>Add Product</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default StoreProductModal