import React, { useEffect, useMemo, useState } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

export const PaginationButton = (props) => {
    const [pagination, setPagination] = useState({
        totalRecords: props.initialParams.total,
        pageLimit: null,
        pageNeighbours: 1,
        currentPage: props.initialParams.page + 1
    })

    const limit = useMemo(() => {
        return {
            pageLimit: Math.ceil(props.initialParams.total / props.initialParams.limit),
            currentPage: !props.initialParams.page ? 1 : props.initialParams.page
        }
    }, [props.initialParams.total, props.initialParams.limit, props.initialParams.page])

    useEffect(() => {
        setPagination(prev => {
            return {
                ...prev,
                pageLimit: limit.pageLimit,
                currentPage: limit.currentPage
            }
        })
    }, [limit])

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    }

    const fetchPageNumbers = () => {
        const totalPages = pagination.pageLimit;
        const currentPage = pagination.currentPage;
        const pageNeighbours = pagination.pageNeighbours;

        const totalNumbers = (pagination.pageNeighbours * 2);
        const totalBlocks = totalNumbers;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);



            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }

    const pages = fetchPageNumbers();

    const getPageStart = (pageSize, pageNr) => {
        return pageSize * pageNr;
    };

    const getPageLabel = (total, pageSize, pageNr) => {
        const start = Math.max(
            getPageStart(pageSize, pageNr),
            0
        );
        const end = Math.min(
            getPageStart(pageSize, pageNr + 1),
            total
        );

        return `${start + 1} to ${end}`;
    }

    const renderTotalTable = () => {
        let result = '';

        const itemsToShow = Array.from({ length: props.initialParams.total }, (_, i) => `Item ${i + 1}`);
        const size = props.initialParams.limit;
        const pages = Array.from(
            { length: Math.ceil(itemsToShow.length / size) },
            (_, i) => getPageLabel(itemsToShow.length, size, i)
        )

        if (pages && pages.length > 0) {
            result = `Showing ${pages[pagination.currentPage - 1]} of ${props.initialParams.total} entries`
        }

        return <p className='m-0'>{result}</p>
    }

    return props.initialParams.keywords === "" &&
        <React.Fragment>
            <div style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10
            }}>
                {renderTotalTable()}

                <Pagination className="pagination-rounded mb-0" style={{ margin: 0 }}>
                    {pages.map((x, i) => {
                        if (x === LEFT_PAGE) return (
                            <PaginationItem key={i} disabled={props.loading}>
                                <PaginationLink href="#" onClick={() => {
                                    props.setLoading(true);

                                    let obj = {
                                        ...props.initialParams,
                                        page: pagination.currentPage - 1,
                                    }
                                    props.getItems(obj)

                                    setPagination(prev => {
                                        return {
                                            ...prev,
                                            currentPage: pagination.currentPage - 1
                                        }
                                    })
                                }}><i className="ri-arrow-left-s-line"></i></PaginationLink>
                            </PaginationItem>
                        )
                        if (x === RIGHT_PAGE) return (
                            <PaginationItem key={i} disabled={props.loading}>
                                <PaginationLink href="#" onClick={() => {
                                    props.setLoading(true)

                                    let obj = {
                                        ...props.initialParams,
                                        page: pagination.currentPage + 1,
                                    }
                                    props.getItems(obj)

                                    setPagination(prev => {
                                        return {
                                            ...prev,
                                            currentPage: pagination.currentPage + 1
                                        }
                                    })
                                }}><i className="ri-arrow-right-s-line"></i></PaginationLink>
                            </PaginationItem>
                        )
                        return pages.length > 1 && <PaginationItem disabled={props.loading} active={pagination.currentPage === (x)} key={i}>
                            <PaginationLink href="#" onClick={() => {
                                if (pagination.currentPage !== x) {
                                    props.setLoading(true);

                                    let obj = {
                                        ...props.initialParams,
                                        page: x === 1 ? 0 : x
                                    }
                                    props.getItems(obj)

                                    setPagination(prev => {
                                        return {
                                            ...prev,
                                            currentPage: x
                                        }
                                    })
                                }
                            }}>{x}</PaginationLink>
                        </PaginationItem>
                    })}
                </Pagination>
            </div>
        </React.Fragment>
}
