import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Spinner } from 'reactstrap'
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import PatientInfo from '../Details/PatientInfo'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import PrescriptionInfo from '../Details/PrescriptionDetails'
import Diagnosis from './Diagnosis';
import Medication from './Medication';
import ModalDiagnosis from './Modal/ModalDiagnosis';
import ModalMedication from './Modal/ModalMedication';
import FooterActions from './FooterActions';
import ModalApprove from './Modal/ModalApprove'
import ModalConfirmation from './Modal/ModalConfirmation'
import ModalPatientBiodata from './Modal/ModalPatientBiodata'
import moment from 'moment'
import { getConsultationById, getPatientById, postMedicalCertificate, postPrescription, postReferralLetter, putChats, putConsultation, putMedicalCertificate, putPatient, putPrescription, putReferralLetter } from '../../../../store/prescriptions/_api'
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType'
import AlertSuccess from '../../../CustomComponent/Alert/AlertSuccess'
import ModalPatient from './Modal/ModalPatient'
import ModalReject from './Modal/ModalReject'
import Remark from './Remark'
import ModalUpdateConsultation from './Modal/ModalUpdateConsultation'
import { getAgora, getAPIroot, getAPIrootTrustGate, post } from '../../../../helpers/API'
import AgoraRTC from "agora-rtc-sdk-ng";
import ModalVideoCall from './Modal/ModalVideoCall'
import MedicalCertificate from './MedicalCertificate'
import ReferralLetter from './ReferralLetter'
import ModalMedicalCertificate from './Modal/ModalMedicalCertificate'
import ModalReferralLetter from './Modal/ModalReferralLetter'
import AlertConfirmation from '../../../CustomComponent/Alert/AlertConfirmation'
import { get } from '../../../../helpers/API'
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import wall1 from "../../../../assets/images/wallpaper-1.jpg"
import wall2 from "../../../../assets/images/wallpaper-2.jpg"
import wall3 from "../../../../assets/images/wallpaper-3.jpg"
import wall4 from "../../../../assets/images/wallpaper-4.jpg"
import wall5 from "../../../../assets/images/wallpaper-5.jpg"
import wall6 from "../../../../assets/images/wallpaper-6.jpg"
import wall7 from "../../../../assets/images/videocallwallpaper.png"

import { pdfTelemedicine } from '../../../../helpers/pdfGenerator/pdfGenerator'
import Axios from 'axios'
import ModalPin from './Modal/ModalPin'
import AlertError from '../../../CustomComponent/Alert/AlertError'
import ModalExtandVideoCall from './Modal/ModalExtandVideoCall'


// Create a VirtualBackgroundExtension instance
const extension = new VirtualBackgroundExtension();
// Register the extension
AgoraRTC.registerExtensions([extension]);

let processor = null;

const PrescriptionFormContent = ({ goToPage }) => {

    const { globalData, setGlobalData } = useContext(GlobalContext)
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)
    const [breadCrumbItems, setBreadCrumbItems] = useState([])
    const [diagnosisData, setDiagnosisData] = useState([])
    const [selected, setSelected] = useState({
        medication: null,
        diagnosis: null
    })
    // const [selectedFiles, setselectedFiles] = useState(null)
    const [loadingPDF, setLoadingPDF] = useState(false)
    const [loading, setLoading] = useState(false)
    const [medicationData, setMedicationData] = useState([])
    const [medicalCertificate, setMedicalCertificate] = useState([])
    const [referralLetter, setReferralLetter] = useState([])
    const [visibleRejectModal, setVisibleRejectModal] = useState(false)
    const [visibleDiagnosisModal, setVisibleDiagnosisModal] = useState(false)
    const [visibleMedicationModal, setVisibleMedicationModal] = useState(false)
    const [visibleApproveModal, setVisibleApproveModal] = useState(false)
    const [visibleConfirmationModal, setVisibleConfirmationModal] = useState(false)
    const [visiblePatientBiodataModal, setVisiblePatientBiodataModal] = useState(false)
    const [sweetTimmer, setSweetTimmer] = useState(false)
    const [visiblePatientModal, setVisiblePatientModal] = useState(false)
    const [visibleRemark, setVisibleRemark] = useState(false)
    const [visibleUpdateConsultation, setVisibleUpdateConsultation] = useState(false)
    const [visibleModalMedicalCertificate, setVisibleModalMedicalCertificate] = useState(false)
    const [visibleModalReferralLetter, setVisibleReferralLetter] = useState(false)
    const [visibleModalEndConsultation, setVisibleModalEndConsultation] = useState(false)
    const [visibleAcceptConsultation, setModalAcceptConsultation] = useState(false)
    // const [setMedicalCertPayload] = useState(null)
    // const [setReferralLetterPayload] = useState(null)
    const [showModalPin, setShowModalPin] = useState({
        selected: '',
        visible: false,
    })
    const [btnEndChat, setBtnEndChat] = useState(true)
    const [btnEndConsult, setBtnEndConsult] = useState(false)
    const [effectVideoCall, setEffectVideoCall] = useState({
        blur: false,
        color: false,
        wallpaper: false
    })
    const [signBase64, setSignBase64] = useState(null)
    const [userVideoTrack, setremoteVideoTrack] = useState(null)
    // const [visibleModalUploadStamp, setVisibleModalUploadStamp] = useState(false)
    const [videoCallConfig, setVideoCallConfig] = useState({
        isMinimised: false,
        showOther: false,
        visible: false,
        rtc: null,
        token: null,
        customer_data: null,
        options: {
            appId: '37a8846c22a847138a9bd8547a30c2b1',
            channel: '',
            token: null,
            uid: 166453,
        },
    })
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ''
    })

    const memorizedChat = useMemo(() => { return prescriptionDataLayer.chat }, [prescriptionDataLayer.chat])
    const memorizedConsultation = useCallback(prescriptionDataLayer.consultation?.id, [])


    // Start handle message alert every 10min video call
    // handleIntervalAlert
    const [showMessage, setShowMessage] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [myInterval, setMyInterval] = useState(null);
    const [intervalReminder, setIntervalReminder] = useState(null)

    useEffect(() => {
        if (showMessage) {
            clearInterval(myInterval)
        }
    }, [showMessage, myInterval])

    useEffect(() => {
        if (showMessage) {
            clearInterval(intervalReminder)
        }
    }, [showMessage, intervalReminder])

    const handleExtandCall = () => {
        setShowMessage(false)
        setMyInterval(setInterval(() => {
            setSeconds(prev => prev + 1)
        }, 1000))
        setIntervalReminder(setInterval(() => {
            alert('pause')
            setShowMessage(true)
        }, 600000));
    }

    const formatMin = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
        return minutes.toString()
    }

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
        const seconds = timeInSeconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    // End handle message alert every 10min video call

    const handleUpdate = async (data, property) => {
        setLoading(true);

        let payload = {};

        if (property === 'diagnosis') {
            payload = {
                diagnosis: data.map((item) => {
                    delete item.index
                    return {
                        ...item
                    }
                })
            }
        } else if (property === 'medication') {
            payload = {
                prescription_items: data.map((item) => {
                    delete item.index
                    return {
                        ...item,
                        product: !item.product ? null : item.product.value,
                        medicine: !item.medicine ? null : item.medicine.value
                    }
                })
            }
        }
        const prescriptionData = await putPrescription(payload, prescriptionDataLayer.prescription.id);
        if (prescriptionData) {
            const patientData = await getPatientById(prescriptionDataLayer.patient.id);
            dispatch({
                type: ACTION_TYPE.SET_PRESCRIPTION,
                payload: {
                    data: prescriptionData,
                    role: globalData.global.role
                }
            })
            dispatch({
                type: ACTION_TYPE.SET_PATIENT,
                payload: {
                    data: patientData,
                    role: globalData.global.role
                }
            })

            setVisibleUpdateConsultation(false)
            setLoading(false)
            setSweetTimmer(true)
        }
    }

    // useEffect(() => {
    //     if (globalData.global.user) {
    //         if (globalData.global.user.doctor && globalData.global.user.doctor.signature) {
    //             getBase64Image(getAPIroot() + globalData.global.user.doctor.signature.url).then(function (base64image) {
    //                 setSignBase64(base64image)
    //             }, function (reason) {
    //                 // console.log(reason); // Error!
    //             });
    //         }
    //     }
    // }, [globalData.global.user])

    useEffect(() => {
        get('/upload/files/64252bc67a92b419e608fd82').then(response => {
            getBase64Image(getAPIroot() + response.url).then(function (base64image) {
                setSignBase64(base64image)
            }, function (reason) {
                // console.log(reason); // Error!
            });
        })
    }, [])

    const handleChatBox = useCallback(() => {
        // if (memorizedChat && memorizedChat && memorizedChat.messages && memorizedChat.messages.length > 0) {
        //     const filterMsg = memorizedChat.messages.filter(x => x !== null && x !== undefined).filter(x => x.text !== undefined).map(x => x.text)
        //     const findText = filterMsg.find(x => x.includes("Thank you for choosing us. We are always there to care!"))
        //     if (findText !== undefined && findText !== null) {
        //         setBtnEndChat(false)
        //         setBtnEndConsult(true)
        //     }
        // }
        if (!memorizedChat || !memorizedChat.messages.length) {
            return;
        }

        const filterMsg = memorizedChat.messages.filter(x => typeof x !== undefined || x !== null)

        if (!filterMsg || !filterMsg.length) {
            return;
        }

        const findText = filterMsg.find(x => x && x.text && x.text.includes("Thank you for choosing us. We are always there to care!"))

        if (!findText) {
            return;
        }

        setBtnEndChat(false)
        setBtnEndConsult(true)
    }, [memorizedChat])


    useEffect(() => {
        handleChatBox()
    }, [handleChatBox])

    useEffect(() => {
        async function getData() {
            if (!videoCallConfig.options.token && memorizedConsultation) {
                const response = await getAgora(memorizedConsultation, videoCallConfig.options.uid);
                if (response) {
                    setVideoCallConfig(prev => {
                        return {
                            ...prev,
                            rtc: {
                                localAudioTrack: null,
                                localVideoTrack: null,
                                client: AgoraRTC.createClient({
                                    mode: "rtc",
                                    codec: "vp8",
                                }),
                            },
                            options: {
                                ...prev.options,
                                channel: memorizedConsultation,
                                token: response.rtcToken
                            }
                        }
                    })
                }
            }
        }

        getData()
    }, [videoCallConfig.options, memorizedConsultation])

    const getProcessorInstance = async () => {
        if (!processor && videoCallConfig.rtc.localVideoTrack) {
            // Create a VirtualBackgroundProcessor instance
            processor = extension.createProcessor();

            try {
                // Initialize the extension and pass in the URL of the Wasm file
                await processor.init("../../../../../node_modules/agora-extension-virtual-background/wasms");
            } catch (e) {
                alert("Fail to load WASM resource!"); return null;
            }
            // Inject the extension into the video processing pipeline in the SDK
            videoCallConfig.rtc.localVideoTrack.pipe(processor).pipe(videoCallConfig.rtc.localVideoTrack.processorDestination);
        }
        return processor;
    }

    const createBlur = async () => {
        if (videoCallConfig.rtc.localVideoTrack) {
            document.getElementById("loading").style.display = "block";

            let processor = await getProcessorInstance();

            try {
                processor.setOptions({ type: 'blur', blurDegree: 1 });
                await processor.enable();
            } finally {
                document.getElementById("loading").style.display = "none";
            }
        }
    }

    const createWallpaper = async (val) => {
        if (videoCallConfig.rtc.localVideoTrack) {
            const imgElement = document.createElement('img');

            imgElement.onload = async () => {
                document.getElementById("loading").style.display = "block";
                let processor = await getProcessorInstance();
                try {
                    processor.setOptions({ type: 'img', source: imgElement });
                    await processor.enable();
                } finally {
                    document.getElementById("loading").style.display = "none";
                }
            }

            switch (val) {
                case "1":
                    imgElement.src = wall7;
                    break;
                case "2":
                    imgElement.src = wall2;
                    break;
                case "3":
                    imgElement.src = wall3;
                    break;
                case "4":
                    imgElement.src = wall4;
                    break;
                case "5":
                    imgElement.src = wall5;
                    break;
                case "6":
                    imgElement.src = wall6;
                    break;
                case "7":
                    imgElement.src = wall1;
                    break;
                default:

                    break;
            }
        }
    }

    const disabledEffect = async () => {
        if (videoCallConfig.rtc.localVideoTrack) {
            document.getElementById("loading").style.display = "block";

            let processor = await getProcessorInstance();

            try {
                await processor.disable();
            } finally {
                document.getElementById("loading").style.display = "none";
            }
        }
    }

    const functionVideoCAll = async () => {
        setVideoCallConfig(prev => {
            return {
                ...prev,
                visible: true
            }
        })
        const el = document.getElementById('chat-popup')
        el.classList.add('d-none')

        const rtcs = videoCallConfig.rtc;
        const rtc = rtcs && rtcs;
        const client = rtc && rtc.client;
        const options = videoCallConfig.options;
        const appId = options && options.appId;
        const channel = options && options.channel;
        const token = options && options.token;
        const uid = options && options.uid;

        if (rtc && client && appId && channel && token && uid) {
            await client.join(
                appId,
                channel,
                token,
                uid
            );
            rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
            rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
            await client.publish([
                rtc.localAudioTrack,
                rtc.localVideoTrack,
            ]);

            const video = rtc.localVideoTrack;
            const element = document.getElementById("player-left");

            video.play(element);

            client.on("user-published", async (user, mediaType) => {
                await client.subscribe(user, mediaType);

                if (mediaType === "video") {
                    const remoteVideoTrack = user.videoTrack;
                    if (remoteVideoTrack) {
                        remoteVideoTrack.play(document.getElementById("player-other"));
                        setremoteVideoTrack(remoteVideoTrack);
                    }
                }

                if (mediaType === "audio") {
                    const remoteAudioTrack = user.audioTrack;
                    remoteAudioTrack.play();
                }
            });

            createWallpaper('1')
            setMyInterval(setInterval(() => {
                setSeconds(prev => prev + 1)
            }, 1000))

            setIntervalReminder(setInterval(() => {
                setShowMessage(true)
            }, 30 * 60 * 1000));
        }
    }

    const rtc = useCallback(videoCallConfig.rtc, [videoCallConfig.rtc])

    const modalVideo = useMemo(() => {
        return videoCallConfig.visible
    }, [videoCallConfig.visible])

    useEffect(() => {
        if (modalVideo && rtc && rtc.localAudioTrack) {
            setTimeout(() => {

            }, 2000);
        }
    }, [modalVideo, rtc]);

    const getMedicationData = useCallback((isMounted) => {
        setMedicationData([]);
        if (prescriptionDataLayer.prescription) {
            let medicationItems = []

            if (prescriptionDataLayer.prescription.prescription_items.length > 0) {
                medicationItems = prescriptionDataLayer.prescription.prescription_items.map((x, i) => {
                    return {
                        ...x,
                        index: i + 1,
                        product: !x.product ? null : {
                            label: x.product.title,
                            value: x.product.id,
                            availability: true
                        },
                        medicine: !x.medicine ? null : {
                            label: x.medicine.description,
                            value: x.medicine.id,
                            availability: false
                        },
                    }
                })
            }

            if (isMounted) {
                setMedicationData(medicationItems);
            }

        }
    }, [prescriptionDataLayer.prescription])

    useEffect(() => {
        let isMounted = true;
        getMedicationData(isMounted)

        return () => {
            isMounted = false
            getMedicationData(isMounted)
        }
    }, [getMedicationData])

    const getMedicalCertificateData = useCallback((isMounted) => {
        if (prescriptionDataLayer.consultation) {

            const medical = prescriptionDataLayer.consultation?.medical_certificates;
            const referral = prescriptionDataLayer.consultation?.referral_letters

            if (medical && isMounted) {
                let newData = medical.map((item, index) => {
                    return {
                        index: index + 1,
                        ...item
                    }
                })
                setMedicalCertificate(newData);
            }
            if (referral && isMounted) {
                let newData = referral.map((item, index) => {
                    return {
                        index: index + 1,
                        ...item
                    }
                })
                setReferralLetter(newData);
            }
        }
    }, [prescriptionDataLayer.consultation])

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            getMedicalCertificateData(isMounted)
        }

        return () => isMounted = false
    }, [getMedicalCertificateData])

    const getDiagnosisData = useCallback((isMounted) => {
        setDiagnosisData([])
        if (prescriptionDataLayer.prescription) {
            let diagnosisItems = []
            if (prescriptionDataLayer.prescription.diagnosis.length > 0) {
                diagnosisItems = prescriptionDataLayer.prescription.diagnosis.map((x, i) => {
                    return {
                        index: i + 1,
                        ...x,
                    }
                })
            }

            if (isMounted) {
                setDiagnosisData(diagnosisItems)
            }

        }
    }, [prescriptionDataLayer.prescription])

    useEffect(() => {
        let isMounted = true;
        getDiagnosisData(isMounted)

        return () => {
            isMounted = false
            getDiagnosisData(isMounted)
        }
    }, [getDiagnosisData])

    const breadCrumb = useCallback((isMounted) => {
        let patientArray = []
        let prescriptionArray = []
        let consultationArray = []
        if (prescriptionDataLayer.content_type === 'prescription') {

            if (!prescriptionDataLayer.prescription && prescriptionDataLayer.patient) {
                patientArray = [
                    { title: "Home", link: { pathname: '/e-prescription/patient', state: { title: 'Home', filter: '' } } },
                    { title: 'Patient Details', link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}`, state: { title: 'Details', filter: '' } } },
                    { title: prescriptionDataLayer.patient.nric, link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}`, state: { title: 'Details', filter: '' } } }
                ]
                if (isMounted) {
                    setBreadCrumbItems(patientArray)
                }
            }

            if (prescriptionDataLayer.prescription && prescriptionDataLayer.patient) {
                if (prescriptionDataLayer.from === 'waiting-approval') {
                    prescriptionArray = [
                        { title: "Home", link: { pathname: '/e-prescription/waiting-approval', state: { title: 'Home', filter: '' } } },
                        { title: 'List', link: { pathname: `/e-prescription/waiting-approval`, state: { title: 'Details', filter: '' } } },
                        { title: prescriptionDataLayer.prescription.prescription_name, link: { pathname: `/e-prescription/waiting-approval/${prescriptionDataLayer.patient.id}/prescription/${prescriptionDataLayer.prescription.id}`, state: { title: 'Details', filter: '' } } }
                    ]
                } else {
                    prescriptionArray = [
                        { title: "Home", link: { pathname: '/e-prescription/patient', state: { title: 'Home', filter: '' } } },
                        { title: 'Patient Details', link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}`, state: { title: 'Details', filter: '' } } },
                        { title: prescriptionDataLayer.patient.nric, link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}`, state: { title: 'Details', filter: '' } } },
                        { title: 'Prescription', link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}/prescription/${prescriptionDataLayer.prescription.id}`, state: { title: 'Details', filter: '' } } },
                        { title: prescriptionDataLayer.prescription.prescription_name, link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}/prescription/${prescriptionDataLayer.prescription.id}`, state: { title: 'Details', filter: '' } } }
                    ]
                }


                if (isMounted) {
                    setBreadCrumbItems(prescriptionArray)
                }
            }
        } else if (prescriptionDataLayer.consultation) {
            consultationArray = [
                { title: "Home", link: { pathname: '/tele-medicine/consultation', state: { title: 'Home', filter: '' } } },
                { title: prescriptionDataLayer.consultation.consultation_name, link: { pathname: `/tele-medicine/consultation/${prescriptionDataLayer.consultation.id}`, state: { title: 'Details', filter: '' } } }
            ]
            setBreadCrumbItems(consultationArray)
        }
    }, [prescriptionDataLayer.patient, prescriptionDataLayer.prescription, prescriptionDataLayer.from, prescriptionDataLayer.consultation, prescriptionDataLayer.content_type])

    useEffect(() => {
        let isMounted = true;
        breadCrumb(isMounted)
        return () => {
            isMounted = false
            breadCrumb(isMounted)
        }
    }, [breadCrumb])

    useEffect(() => {
        if (prescriptionDataLayer.prescription && prescriptionDataLayer.prescription.activity_statuses.length > 0) {
            const findRemark = prescriptionDataLayer.prescription.activity_statuses.filter(x => x.status.includes('remark'));
            if (findRemark.length > 0) setVisibleRemark(true)
        }
    }, [prescriptionDataLayer.prescription])

    const updateStatusConsultation = async (string, string2) => {
        setLoading(true)
        let payload = {
            status: !string ? string2 : string
        }
        if (prescriptionDataLayer.consultation) {
            if (prescriptionDataLayer.prescription) {
                let payload2 = {
                    activity_statuses: [
                        ...prescriptionDataLayer.prescription.activity_statuses,
                        { status: `prescription_status-approved`, datetime: moment().toISOString() },
                    ],
                }
                const prescriptionData = await putPrescription(payload2, prescriptionDataLayer.prescription.id);
                dispatch({
                    type: ACTION_TYPE.SET_PRESCRIPTION,
                    payload: {
                        data: prescriptionData,
                        role: globalData.global.role
                    }
                })
            }


            const consultationData = await putConsultation(payload, prescriptionDataLayer.consultation.id);
            setLoading(false)
            setSweetTimmer(true)

            delete consultationData.prescriptions
            dispatch({
                type: ACTION_TYPE.SET_CONSULTATION,
                payload: {
                    data: {
                        ...consultationData,
                        customer: prescriptionDataLayer.patient,
                        prescription: prescriptionDataLayer.prescription
                    },
                    role: globalData.global.role
                }
            })
            dispatch({
                type: ACTION_TYPE.UPDATE_ACTION_TYPE,
                payload: {
                    action: consultationData.status,
                    role: globalData.global.role
                }
            })

            setTimeout(() => {
                if (!string2) {
                    if (string === 'completed') {
                        handleStopChatting(consultationData)
                    } else if (string === 'accepted') {
                        handleStartChatting(consultationData)
                    }
                } else {
                    handleStopChatting(consultationData)
                }

            }, 1000);
        }
    }

    const handleStartChatting = async (consultationData) => {
        let customerData = ''
        if (consultationData.customer && consultationData.customer.user) {
            const response = await get(`/users/${consultationData.customer.user}`)
            customerData = response
        }
        let text1 = `Greetings, ${!customerData.username ? "" : customerData.username}!`
        let text2 = `We're glad to have you join us at Selcare.`
        let text3 = `I'm ${!globalData.global.user ? 'Doctor' : globalData.global.user.username}, and I'm here to help. Is there anything specific that you would like assistance with today?`
        let text4 = `Please note all communication and video will be recorded for security purpose.`
        if (consultationData &&
            consultationData.chat
        ) {
            let payload = {
                messages: [
                    {
                        user: globalData.global.user.id,
                        text: text1,
                        sent_datetime: moment().toISOString(),
                        file: null
                    },
                    {
                        user: globalData.global.user.id,
                        text: text2,
                        sent_datetime: moment().toISOString(),
                        file: null
                    },
                    {
                        user: globalData.global.user.id,
                        text: text3,
                        sent_datetime: moment().toISOString(),
                        file: null
                    },
                    {
                        user: globalData.global.user.id,
                        text: text4,
                        sent_datetime: moment().toISOString(),
                        file: null
                    }
                ]
            }
            const response = await putChats(payload, consultationData.chat.id);
            setTimeout(async () => {
                dispatch({
                    type: ACTION_TYPE.UPDATE_CHAT,
                    payload: response
                })
            }, 800);
        }
    }

    const handleStopChatting = async (consultationData) => {
        let text1 = 'Now you can go to the Prescription tab.  Please make your payment and get your prescription, medical certificate and referral letter, if any.'
        let text2 = 'Thank you for choosing us. We are always there to care!'

        if (consultationData &&
            consultationData.chat
        ) {
            let payload = {
                messages: [
                    ...consultationData.chat.messages,
                    {
                        user: globalData.global.user.id,
                        text: text1,
                        sent_datetime: moment().toISOString(),
                        file: null
                    },
                    {
                        user: globalData.global.user.id,
                        text: text2,
                        sent_datetime: moment().toISOString(),
                        file: null
                    }
                ]
            }
            const response = await putChats(payload, consultationData.chat.id);
            setTimeout(async () => {
                dispatch({
                    type: ACTION_TYPE.UPDATE_CHAT,
                    payload: response
                })
            }, 800);
        }
    }

    const handleGeneratePDF = (obj) => {
        handleSignPDF(obj)
    }

    const getBase64Image = (imgUrl) => {
        return new Promise(
            function (resolve, reject) {

                var img = new Image();
                img.src = imgUrl;
                img.setAttribute('crossOrigin', 'anonymous');

                img.onload = function () {
                    var canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);
                    var dataURL = canvas.toDataURL("image/png");
                    resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
                }
                img.onerror = function () {
                    reject("The image could not be loaded.");
                }

            });
    }

    const handleSignPDF = async (obj) => {
        let payload = {
            data: {
                UserID: obj.nric,
                FullName: obj.FullName,
                AuthFactor: obj.pin,
                SignatureInfo: {
                    visibility: true,
                    x1: 30,
                    y1: 60,
                    x2: 195,
                    y2: 100,
                    pageNo: obj.pdf.pageNo,
                    pdfInBase64: obj.pdf.data,
                    sigImageInBase64: signBase64
                }
            }
        }
        setLoadingPDF(true)
        const response = await Axios.post(getAPIrootTrustGate() + '/sign-pdf', payload);
        if (response.data.return.statusCode === '000') {
            setLoadingPDF(false)
            checkForMIMEType(response.data.return.signedPdfInBase64);

            if (!obj.upload) return

            setShowModalPin({
                selected: '',
                visible: !showModalPin.visible
            })
            if (obj.name === 'medicalCert') {
                setVisibleModalMedicalCertificate(false)
                // await postMedicalCertificate(medicalCertPayload).then(async responseMC => {
                await getConsultationById(prescriptionDataLayer.consultation.id).then(responseConsult => {
                    const consultationData = responseConsult
                    delete consultationData.prescriptions
                    dispatch({
                        type: ACTION_TYPE.SET_CONSULTATION,
                        payload: {
                            data: {
                                ...consultationData,
                                customer: prescriptionDataLayer.patient,
                                prescription: prescriptionDataLayer.prescription
                            },
                            role: globalData.global.role
                        }
                    })

                    setSweetTimmer(true)
                    uploadFile({ name: obj.name + ".pdf", file: response.data.return.signedPdfInBase64, itemId: prescriptionDataLayer.consultation?.medical_certificates?.[0].id })
                })
                // })
            } else if (obj.name === 'referralLetter') {
                setVisibleReferralLetter(false)
                // await postReferralLetter(referralLetterPayload).then(async responseRL => {
                await getConsultationById(prescriptionDataLayer.consultation.id).then(responseConsult => {
                    const consultationData = responseConsult
                    delete consultationData.prescriptions
                    dispatch({
                        type: ACTION_TYPE.SET_CONSULTATION,
                        payload: {
                            data: {
                                ...consultationData,
                                customer: prescriptionDataLayer.patient,
                                prescription: prescriptionDataLayer.prescription
                            },
                            role: globalData.global.role
                        }
                    })

                    setSweetTimmer(true)
                    uploadFile({ name: obj.name + ".pdf", file: response.data.return.signedPdfInBase64, itemId: prescriptionDataLayer.consultation?.referral_letters?.[0].id })
                })
                // })
            } else if (obj.name === 'prescription') {
                setSweetTimmer(true)
                uploadFile({ name: obj.name + ".pdf", file: response.data.return.signedPdfInBase64, itemId: prescriptionDataLayer.prescription.id })
            }
        } else {
            setLoadingPDF(false)
            setErrMsg({
                visible: true,
                msg: response.data.return.statusMsg
            })
        }
    }

    const uploadFile = async (res) => {
        let blob;
        blob = converBase64toBlob(res.file, 'application/pdf');

        let formdata = new FormData()
        formdata.append('files', blob, res.name)

        await post('/upload', formdata).then(async response => {
            if (res.name === 'prescription.pdf') {
                await putPrescription({ prescription_file: response[0].id }, res.itemId)
                await updateStatusConsultation('completed')
            } else if (res.name === 'referralLetter.pdf') {
                await putReferralLetter({ referral_letter_file: response[0].id }, res.itemId)
            } else if (res.name === 'medicalCert.pdf') {
                await putMedicalCertificate({ medical_certificate_file: response[0].id }, res.itemId)
            }
        })
    }

    const checkForMIMEType = (response, bol) => {
        let blob;
        blob = converBase64toBlob(response, 'application/pdf');
        let blobURL = URL.createObjectURL(blob);
        // if (!bol) window.open(blobURL);
        return blobURL
    }

    const converBase64toBlob = (content, contentType) => {
        contentType = contentType || '';
        var sliceSize = 512;
        var byteCharacters = window.atob(content);
        var byteArrays = [
        ];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {
            type: contentType
        });
        return blob;
    }

    const handleEndVideoCall = async () => {
        if (videoCallConfig.rtc.localAudioTrack && videoCallConfig.rtc.localVideoTrack) {

            await videoCallConfig.rtc.localVideoTrack.stop(document.getElementById("player-left"));
            await videoCallConfig.rtc.localVideoTrack.setEnabled(false)
            await videoCallConfig.rtc.localAudioTrack.setMuted(true);
            await videoCallConfig.rtc.localVideoTrack.close();
            await videoCallConfig.rtc.localAudioTrack.close();
            await videoCallConfig.rtc.client.leave();

            setVideoCallConfig({
                showOther: false,
                visible: false,
                rtc: null,
                token: null,
                customer_data: null,
                options: {
                    appId: '37a8846c22a847138a9bd8547a30c2b1',
                    channel: prescriptionDataLayer.consultation && prescriptionDataLayer.consultation.id,
                    token: null,
                    uid: 166453,
                },
            })

            const el = document.getElementById('chat-popup')
            el.classList.remove('d-none')

            processor = null;
            setShowMessage(false)
            setSeconds(null)
            clearInterval(intervalReminder)
            clearInterval(myInterval)
        }
    }

    const handleEndChat = async () => {
        setBtnEndConsult(true)
        setBtnEndChat(false)

        const url = `/chats/${!prescriptionDataLayer.chat ? null : prescriptionDataLayer.chat.id}`
        // console.log("chat", url)

        const resChat = await get(url)
        // console.log(resChat)
        let text1 = 'Now you can go to the Prescription tab.  Please make your payment and get your prescription, medical certificate and referral letter, if any.'
        let text2 = 'Thank you for choosing us. We are always there to care!'



        let payload = {
            messages: [
                ...resChat.messages,
                {
                    user: globalData.global.user.id,
                    text: text1,
                    sent_datetime: moment().toISOString(),
                    file: null
                },
                {
                    user: globalData.global.user.id,
                    text: text2,
                    sent_datetime: moment().toISOString(),
                    file: null
                }
            ]
        }

        const response = await putChats(payload, resChat.id)

        if (response) {

            await post("/custom/push-chat-messages", {
                chatID: response.id,
                currentMessage: response.messages[response.messages.length - 2],
            });

            await post("/custom/push-chat-messages", {
                chatID: response.id,
                currentMessage: response.messages[response.messages.length - 1],
            });
            setTimeout(async () => {
                dispatch({
                    type: ACTION_TYPE.UPDATE_CHAT,
                    payload: response
                })
            }, 800);
        }
    }
    return (
        <React.Fragment>
            {/* Prescription Content */}
            <div>
                {/* Prescription Details */}
                <div className={(prescriptionDataLayer.action_type.type === 'pending_consultation' || prescriptionDataLayer.action_type.type === 'accepted_consultation') ? 'd-flex justify-content-between align-items-center' : 'd-flex justify-content-between align-items-center'}>
                    <Breadcrumbs
                        title={prescriptionDataLayer.action_type.title}
                        breadcrumbItems={breadCrumbItems}
                    />

                    <div className='d-flex justify-content-center align-items-center'>
                        {(prescriptionDataLayer.action_type.type === 'accepted_consultation' || prescriptionDataLayer.action_type.type === 'pending_consultation') && (
                            <div>
                                {btnEndChat && (prescriptionDataLayer.action_type.type === 'accepted_consultation') ? (
                                    <Button
                                        outline
                                        className='mr-2'
                                        color='light'
                                        onClick={handleEndChat}
                                    >End Chat
                                    </Button>
                                ) : null}
                                {btnEndConsult && prescriptionDataLayer.action_type.type === 'accepted_consultation' ? (
                                    <Button
                                        className='mr-2'
                                        color='light'
                                        onClick={() => {
                                            setVisibleModalEndConsultation(!visibleModalEndConsultation)
                                        }}
                                    >End Consultation
                                    </Button>
                                ) : null}
                                {prescriptionDataLayer.action_type.type === 'pending_consultation' ? (
                                    <Button
                                        color='primary'
                                        onClick={() => {
                                            setModalAcceptConsultation(!visibleAcceptConsultation);
                                        }}>
                                        {loading && <Spinner color='light' size={"sm"} className="align-middle mr-2" />}Accept Consultation
                                    </Button>
                                ) : null}
                            </div>
                        )}

                        {globalData.global.role === 'doctor' && prescriptionDataLayer.action_type.type === 'accepted_consultation' && videoCallConfig.visible ?
                            <Button color='danger'>End Video Call</Button> : null}

                        {globalData.global.role === 'doctor' && prescriptionDataLayer.action_type.type === 'accepted_consultation' && !videoCallConfig.visible ?
                            <Button
                                color='light'
                                onClick={() => {
                                    functionVideoCAll()
                                }}>Video call</Button> : null}
                        {/* {prescriptionDataLayer.action_button.crud_tele_medicine && prescriptionDataLayer.action_button.crud_tele_medicine.update ? <Button className='ml-2 mr-2' color='primary' onClick={() => setVisibleUpdateConsultation(true)}>Save<i className="ri-save-line align-middle ml-2"></i></Button> : null} */}
                    </div>
                </div>
                {(prescriptionDataLayer.action_type.type === 'update_prescription' || prescriptionDataLayer.action_type.type === 'view_prescription') && <Card>
                    <CardBody>
                        <PrescriptionInfo
                            role={globalData.global.role}
                            title="Prescription Details"
                        />
                    </CardBody>
                </Card>}
                {/* Patient Info */}
                <Card>
                    <CardBody>
                        <PatientInfo
                            role={globalData.global.role}
                            title="Patient Personal Info"
                            handleEditPatient={() => {
                                setVisiblePatientModal(true)
                            }}
                            editMode={prescriptionDataLayer.edit_mode}
                        />
                    </CardBody>
                </Card>
                {/* diagnosis */}
                <Card>
                    <CardBody>
                        <Diagnosis
                            content_type={prescriptionDataLayer.content_type}
                            action_type={prescriptionDataLayer.action_type.type}
                            editMode={prescriptionDataLayer.edit_mode}
                            role={globalData.global.role}
                            title="Diagnoses"
                            openModal={() => {
                                setVisibleDiagnosisModal(true)
                            }}
                            dataSource={diagnosisData}
                            setSelected={setSelected}
                            removeDiagnosis={(value) => {
                                const newData = diagnosisData.filter((item) => item.index !== value.index)
                                setDiagnosisData(newData);

                                handleUpdate(newData, 'diagnosis')
                            }}
                        />
                    </CardBody>
                </Card>
                {/* Medication */}
                <Card>
                    <CardBody>
                        <Medication
                            content_type={prescriptionDataLayer.content_type}
                            action_type={prescriptionDataLayer.action_type.type}
                            editMode={prescriptionDataLayer.edit_mode}
                            role={globalData.global.role}
                            title="Medication"
                            openModal={() => {
                                setVisibleMedicationModal(true)
                            }}
                            dataSource={medicationData}
                            handleRemove={(value) => {
                                const newData = medicationData.filter(x => x.index !== value.index)
                                const data = newData.map((x, i) => {
                                    return {
                                        ...x,
                                        index: i + 1
                                    }
                                })
                                setMedicationData(data)

                                handleUpdate(data, 'medication')
                            }}
                            handleUpdate={(value) => {
                                setVisibleMedicationModal(true)
                                setSelected(prev => {
                                    return {
                                        ...prev,
                                        medication: {
                                            ...value,
                                            medicine: value.medicine,
                                        }
                                    }
                                })
                            }}
                        />
                    </CardBody>
                </Card>
                {/* Medical Certificate */}
                {globalData.global.role === 'doctor' && <Card>
                    <CardBody>
                        {/* <div className=' shadow rounded h-100 w-100 position-absolute d-flex justify-content-center align-items-center bg-info-coming-soon'><b className='m-0 text-white'><i className="ri-tools-fill mr-2 align-middle"></i>Coming Soon!</b></div> */}
                        <MedicalCertificate
                            editMode={prescriptionDataLayer.edit_mode}
                            dataSource={medicalCertificate}
                            showModal={() => {
                                setVisibleModalMedicalCertificate(true)
                                const el = document.getElementById('chat-popup')
                                el.classList.add('d-none')
                            }}
                            printPDF={() => {
                                setShowModalPin({
                                    selected: 'medicalCert',
                                    visible: !showModalPin.visible
                                })
                            }}
                            status={prescriptionDataLayer.consultation.status}
                        />
                    </CardBody>
                </Card>}
                {/* Referral Letter */}
                {globalData.global.role === 'doctor' && <Card>
                    <CardBody>
                        {/* <div className=' shadow rounded h-100 w-100 position-absolute d-flex justify-content-center align-items-center bg-info-coming-soon'><b className='m-0 text-white'><i className="ri-tools-fill mr-2 align-middle"></i>Coming Soon!</b></div> */}
                        <ReferralLetter
                            editMode={prescriptionDataLayer.edit_mode}
                            dataSource={referralLetter}
                            showModal={() => {
                                setVisibleReferralLetter(true)
                                const el = document.getElementById('chat-popup')
                                el.classList.add('d-none')
                            }}
                            printPDF={() => {
                                setShowModalPin({
                                    selected: 'referralLetter',
                                    visible: !showModalPin.visible
                                })
                            }}
                            status={prescriptionDataLayer.consultation.status}
                        />
                    </CardBody>
                </Card>}
                {/* Remark */}
                {visibleRemark && <Card>
                    <CardBody>
                        <Remark
                            title='Remarks'
                        />
                    </CardBody>
                </Card>}
            </div>
            {/* End Prescription Content */}
            {/* Footer Action */}
            <FooterActions
                goToPage={goToPage}
                openModalPatientBiodata={() => {
                    setVisiblePatientBiodataModal(true)
                }}
                openModalApprove={() => {
                    setVisibleApproveModal(true)
                }}
                openModalConfirmation={() => {
                    setVisibleConfirmationModal(true)
                }}
                openModalReject={() => {
                    setVisibleRejectModal(true)
                }}
            // openModalUpdateConsultation={() => {
            //     setVisibleUpdateConsultation(true)
            // }}
            />
            {/* End Footer Action */}

            {/* Modal Referral Letter */}
            <ModalReferralLetter
                visible={visibleModalReferralLetter}
                isClosed={() => {
                    setVisibleReferralLetter(false)
                    const el = document.getElementById('chat-popup')
                    el.classList.remove('d-none')
                }}
                onSubmit={async (payload, stamp) => {
                    let data = {
                        ...payload,
                        referral_for: payload.referral_for.value
                    }
                    // setReferralLetterPayload(data)

                    // setShowModalPin({
                    //     selected: 'referralLetter',
                    //     visible: !showModalPin.visible
                    // })

                    const referal = prescriptionDataLayer.consultation?.referral_letters?.[0];
                    setVisibleReferralLetter(false)
                    if (!referal) {
                        await postReferralLetter(data).then(async responseRL => {
                            await getConsultationById(responseRL.consultation.id).then(responseConsult => {
                                const consultationData = responseConsult
                                delete consultationData.prescriptions
                                dispatch({
                                    type: ACTION_TYPE.SET_CONSULTATION,
                                    payload: {
                                        data: {
                                            ...consultationData,
                                            customer: prescriptionDataLayer.patient,
                                            prescription: prescriptionDataLayer.prescription
                                        },
                                        role: globalData.global.role
                                    }
                                })
                                setSweetTimmer(true)
                            })
                        })
                    } else {
                        await putReferralLetter(data, referal.id).then(async responseRL => {
                            await getConsultationById(responseRL.consultation.id).then(responseConsult => {
                                const consultationData = responseConsult
                                delete consultationData.prescriptions
                                dispatch({
                                    type: ACTION_TYPE.SET_CONSULTATION,
                                    payload: {
                                        data: {
                                            ...consultationData,
                                            customer: prescriptionDataLayer.patient,
                                            prescription: prescriptionDataLayer.prescription
                                        },
                                        role: globalData.global.role
                                    }
                                })
                                setSweetTimmer(true)
                            })
                        })
                    }


                    const el = document.getElementById('chat-popup')
                    el.classList.remove('d-none')
                }}
            />
            {/* End Modal Referral Letter */}

            {/* Modal Medication Certificate */}
            <ModalMedicalCertificate
                visible={visibleModalMedicalCertificate}
                isClosed={() => {
                    setVisibleModalMedicalCertificate(false)
                    const el = document.getElementById('chat-popup')
                    el.classList.remove('d-none')
                }}
                onSubmit={async (payload) => {
                    // setShowModalPin({
                    //     selected: 'medicalCert',
                    //     visible: !showModalPin.visible
                    // })
                    // setMedicalCertPayload(payload)
                    const medical = prescriptionDataLayer.consultation?.medical_certificates?.[0];
                    setVisibleModalMedicalCertificate(false)
                    if (!medical) {
                        await postMedicalCertificate(payload).then(async responseMC => {
                            await getConsultationById(responseMC.consultation.id).then(responseConsult => {
                                const consultationData = responseConsult
                                delete consultationData.prescriptions
                                dispatch({
                                    type: ACTION_TYPE.SET_CONSULTATION,
                                    payload: {
                                        data: {
                                            ...consultationData,
                                            customer: prescriptionDataLayer.patient,
                                            prescription: prescriptionDataLayer.prescription
                                        },
                                        role: globalData.global.role
                                    }
                                })

                                setSweetTimmer(true)
                            })
                        })
                    } else {
                        await putMedicalCertificate(payload, medical.id).then(async responseMC => {
                            await getConsultationById(responseMC.consultation.id).then(responseConsult => {
                                const consultationData = responseConsult
                                delete consultationData.prescriptions
                                dispatch({
                                    type: ACTION_TYPE.SET_CONSULTATION,
                                    payload: {
                                        data: {
                                            ...consultationData,
                                            customer: prescriptionDataLayer.patient,
                                            prescription: prescriptionDataLayer.prescription
                                        },
                                        role: globalData.global.role
                                    }
                                })

                                setSweetTimmer(true)
                            })
                        })
                    }
                    const el = document.getElementById('chat-popup')
                    el.classList.remove('d-none')
                }}
            />
            {/* End Modal Medication Certificate */}

            {/* Modal Video Call */}
            <ModalVideoCall
                countDownClose={formatMin(seconds)}
                handleExtandCall={handleExtandCall}
                timers={formatTime(seconds)}
                visible={videoCallConfig.visible}
                toggleCamera={async (value) => {
                    if (videoCallConfig.rtc.localVideoTrack.isPlaying) {
                        videoCallConfig.rtc.localVideoTrack.stop(document.getElementById("player-left"));
                        videoCallConfig.rtc.localVideoTrack.setEnabled(false)
                    } else {
                        videoCallConfig.rtc.localVideoTrack.play(document.getElementById("player-left"))
                        videoCallConfig.rtc.localVideoTrack.setEnabled(true)
                    }
                }}
                toggleSpeaker={async (value) => {
                    if (value === 'off') {
                        videoCallConfig.rtc.localAudioTrack.setMuted(true);
                    } else {
                        videoCallConfig.rtc.localAudioTrack.setMuted(false);
                    }
                }}
                effectVideoCall={effectVideoCall}
                clearEffect={() => {
                    setEffectVideoCall(prev => {
                        return {
                            ...prev,
                            blur: !effectVideoCall.blur
                        }
                    })
                    disabledEffect()
                }}
                setBlur={() => {
                    setEffectVideoCall({
                        color: false,
                        blur: !effectVideoCall.blur,
                        wallpaper: false
                    })
                    createBlur()
                }}
                setWallpaper={(val) => {
                    setEffectVideoCall({
                        color: false,
                        blur: false,
                        wallpaper: !effectVideoCall.wallpaper
                    })

                    createWallpaper(val)
                }}
                handleMinised={async () => {
                    setVideoCallConfig(prev => {
                        return {
                            ...prev,
                            visible: false,
                            isMinimised: true,
                        }
                    })

                    const el2 = document.getElementById('minimised-cam');
                    el2.style.opacity = '1';

                    const el = document.getElementById('chat-popup')
                    el.classList.add('d-block')

                    if (userVideoTrack) {
                        userVideoTrack.play(document.getElementById("doctor-cam"));
                        setVideoCallConfig(prev => {
                            return {
                                ...prev,
                                showOther: true,
                            }
                        })
                    }
                }}
                isClosed={async () => {
                    if (videoCallConfig.rtc.localAudioTrack && videoCallConfig.rtc.localVideoTrack) {
                        await videoCallConfig.rtc.localVideoTrack.stop(document.getElementById("player-left"));
                        await videoCallConfig.rtc.localVideoTrack.setEnabled(false)
                        await videoCallConfig.rtc.localAudioTrack.setMuted(true);
                        await videoCallConfig.rtc.localVideoTrack.close();
                        await videoCallConfig.rtc.localAudioTrack.close();

                        await videoCallConfig.rtc.client.leave();
                        setVideoCallConfig({
                            showOther: false,
                            visible: false,
                            rtc: null,
                            token: null,
                            customer_data: null,
                            options: {
                                appId: '37a8846c22a847138a9bd8547a30c2b1',
                                channel: prescriptionDataLayer.consultation && prescriptionDataLayer.consultation.id,
                                token: null,
                                uid: 166453,
                            },
                        })
                        const el = document.getElementById('chat-popup')
                        el.classList.remove('d-none')
                    }
                }}
                handleEndCall={handleEndVideoCall}
            />
            {/* End Modal Video Call */}

            {/* Modal Update Consultation */}
            <ModalUpdateConsultation
                visible={visibleUpdateConsultation}
                loading={loading}
                isClosed={() => { setVisibleUpdateConsultation(false) }}
                onSubmit={async () => {
                    setLoading(true)
                    let payload = {
                        diagnosis: diagnosisData.map((item) => {
                            delete item.index
                            return {
                                ...item
                            }
                        }),
                        prescription_items: medicationData.map((item) => {
                            delete item.index
                            return {
                                ...item,
                                product: !item.product ? null : item.product.value,
                                medicine: !item.medicine ? null : item.medicine.value
                            }
                        })
                    }
                    const prescriptionData = await putPrescription(payload, prescriptionDataLayer.prescription.id);
                    if (prescriptionData) {
                        const patientData = await getPatientById(prescriptionDataLayer.patient.id);
                        dispatch({
                            type: ACTION_TYPE.SET_PRESCRIPTION,
                            payload: {
                                data: prescriptionData,
                                role: globalData.global.role
                            }
                        })
                        dispatch({
                            type: ACTION_TYPE.SET_PATIENT,
                            payload: {
                                data: patientData,
                                role: globalData.global.role
                            }
                        })

                        setVisibleUpdateConsultation(false)
                        setLoading(false)
                        setSweetTimmer(true)
                    }
                }}
            />
            {/* Modal Reject prescription */}
            <ModalReject
                visible={visibleRejectModal}
                isClosed={() => setVisibleRejectModal(false)}
                loading={loading}
                onSubmit={async (result) => {
                    setLoading(true)
                    let newData = prescriptionDataLayer.prescription;
                    if (newData) {
                        let payload = {
                            activity_statuses: [
                                ...newData.activity_statuses,
                                { status: `prescription_status-rejected`, datetime: moment().toISOString() },
                                { status: `remark-rejected`, datetime: moment().toISOString(), remarks: result.remarks }
                            ],
                        }

                        if (!newData.doctor) {
                            payload = {
                                ...payload,
                                doctor: globalData && globalData.global.user.doctor
                            }
                        }
                        // let url = `/e-prescription/patient/${state.customer_id}/prescription/${state.prescription_id}/document`
                        const prescriptionData = await putPrescription(payload, newData.id)
                        if (prescriptionData) {
                            const patientData = await getPatientById(prescriptionDataLayer.patient.id);
                            dispatch({
                                type: ACTION_TYPE.SET_PRESCRIPTION,
                                payload: {
                                    data: prescriptionData,
                                    role: globalData.global.role
                                }
                            })
                            dispatch({
                                type: ACTION_TYPE.SET_PATIENT,
                                payload: {
                                    data: patientData,
                                    role: globalData.global.role
                                }
                            })

                            setVisibleRejectModal(false)
                            setLoading(false)
                            setSweetTimmer(true)
                        }
                    }
                }}
            />
            {/* Modal approve prescription */}
            <ModalApprove
                visible={visibleApproveModal}
                isClosed={() => setVisibleApproveModal(false)}
                loading={loading}
                onSubmit={async (result) => {
                    // update user
                    // update prescription status approved
                    // prescriptionDataLayer.consultation.chat.messages

                    setLoading(true)
                    let newData = prescriptionDataLayer.prescription;
                    if (newData) {
                        let payload = {
                            activity_statuses: [
                                ...newData.activity_statuses,
                                { status: `prescription_status-approved`, datetime: moment().toISOString() },
                            ],
                        }

                        if (!newData.doctor) {
                            payload = {
                                ...payload,
                                doctor: globalData && globalData.global.user.doctor
                            }
                        }
                        // let url = `/e-prescription/patient/${state.customer_id}/prescription/${state.prescription_id}/document`
                        const prescriptionData = await putPrescription(payload, newData.id)
                        if (prescriptionData) {
                            const patientData = await getPatientById(prescriptionDataLayer.patient.id);
                            dispatch({
                                type: ACTION_TYPE.SET_PRESCRIPTION,
                                payload: {
                                    data: prescriptionData,
                                    role: globalData.global.role
                                }
                            })
                            dispatch({
                                type: ACTION_TYPE.SET_PATIENT,
                                payload: {
                                    data: patientData,
                                    role: globalData.global.role
                                }
                            })


                            setVisibleApproveModal(false)
                            setLoading(false)
                            setSweetTimmer(true)
                        }
                    }
                }}
            />
            {/* Modal update patient biodata */}
            <ModalPatientBiodata
                visible={visiblePatientBiodataModal}
                isClosed={() => setVisiblePatientBiodataModal(false)}
                onSubmit={async (form) => {
                    const response = await putPatient({ patient_biodata: form }, prescriptionDataLayer.patient.id);
                    if (response) {
                        dispatch({
                            type: ACTION_TYPE.SET_PATIENT,
                            payload: {
                                data: response,
                                role: globalData.global.role
                            }
                        })

                        setSweetTimmer(true)
                        setVisiblePatientBiodataModal(false)
                    }
                }}
            />
            {/* Modal CREATE AND UPDATE prescription */}
            <ModalConfirmation
                type={prescriptionDataLayer.action_type.type}
                visible={visibleConfirmationModal}
                isClosed={() => setVisibleConfirmationModal(false)}
                loading={loading}
                onSubmit={async (form) => {
                    let payload = {
                        prescription_name: '#SCE-' + moment().format('YYMMDDHHmmss'),
                        is_active: true,
                        issued_on: moment().toISOString(),
                        expired_on: moment().add(30, 'days').toISOString(),
                        issued_by: globalData.global.user.username,
                        activity_statuses: [
                            {
                                status: "prescription_status-waiting for approval",
                                datetime: moment().toISOString(),
                                pic_name: form.name,
                                pic_nric: form.nric,
                                pic_registration_number: form.registeration_no,

                            },
                            {
                                status: "medical_status-unsupplied",
                                datetime: moment().toISOString(),
                                pic_name: form.name,
                                pic_nric: form.nric,
                                pic_registration_number: form.registeration_no,
                            }
                        ],
                        diagnosis: diagnosisData,
                        prescription_items: medicationData.map((item) => {
                            delete item.index
                            return {
                                ...item,
                                product: !item.product ? null : item.product.value,
                                medicine: !item.medicine ? null : item.medicine.value
                            }
                        }),
                        customer: prescriptionDataLayer.patient.id
                    }
                    const prescriptionData = await postPrescription(payload);
                    if (prescriptionData) {
                        dispatch({
                            type: ACTION_TYPE.SET_PRESCRIPTION,
                            payload: {
                                data: prescriptionData,
                                role: globalData.global.role
                            }
                        })
                        const patientData = await getPatientById(prescriptionDataLayer.patient.id);
                        dispatch({
                            type: ACTION_TYPE.SET_PATIENT,
                            payload: {
                                data: patientData,
                                role: globalData.global.role
                            }
                        })

                        setVisibleConfirmationModal(false)
                        setLoading(false)
                        setSweetTimmer(true)
                    }
                }}
                onUpdate={async (form) => {
                    setLoading(true)
                    let data = {
                        activity_statuses: [
                            ...prescriptionDataLayer.prescription.activity_statuses,
                            {
                                status: 'prescription_status-waiting for approval',
                                datetime: moment().toISOString(),
                                pic_name: !form ? '' : form.name,
                                pic_nric: !form ? '' : form.nric,
                                pic_registration_number: !form ? '' : form.registeration_no,
                            }
                        ],
                        diagnosis: diagnosisData.map((item) => {
                            delete item.index
                            return {
                                ...item
                            }
                        }),
                        prescription_items: medicationData.map((item) => {
                            delete item.index
                            return {
                                ...item,
                                product: !item.product ? null : item.product.value,
                                medicine: !item.medicine ? null : item.medicine.value
                            }
                        })
                    }
                    const prescriptionData = await putPrescription(data, prescriptionDataLayer.prescription.id);
                    if (prescriptionData) {
                        const patientData = await getPatientById(prescriptionDataLayer.patient.id);
                        dispatch({
                            type: ACTION_TYPE.SET_PRESCRIPTION,
                            payload: {
                                data: prescriptionData,
                                role: globalData.global.role
                            }
                        })
                        dispatch({
                            type: ACTION_TYPE.SET_PATIENT,
                            payload: {
                                data: patientData,
                                role: globalData.global.role
                            }
                        })

                        setVisibleConfirmationModal(false)
                        setLoading(false)
                        setSweetTimmer(true)
                    }
                }}
            />
            {/* Modal add / remove diagnosis items */}
            <ModalDiagnosis
                visible={visibleDiagnosisModal}
                isClosed={() => {
                    setVisibleDiagnosisModal(false)
                    setSelected({
                        diagnosis: null,
                        medication: null
                    })
                }}
                selectedItem={selected.diagnosis}
                onSubmitDiagnosis={(value) => {
                    const data = [{
                        ...value
                    }]
                    const newArray = diagnosisData.concat(data)
                    const result = newArray.map((x, i) => {
                        return {
                            ...x,
                            index: i + 1
                        }
                    })
                    setDiagnosisData(result)

                    handleUpdate(result, 'diagnosis')
                }}
                onSubmitUpdateDiagnosis={(value) => {
                    let newData = diagnosisData;
                    let index = newData.findIndex(item => item.index === value.index);
                    let items = newData[index];
                    newData.splice(index, 1, { ...items, ...value })
                    setDiagnosisData(newData)
                    setSelected({
                        diagnosis: null,
                        medication: null
                    })
                    setVisibleDiagnosisModal(false)

                    handleUpdate(newData, 'diagnosis')
                }}
            />
            {/* Modal add / remove drug items */}
            <ModalMedication
                visible={visibleMedicationModal}
                isClosed={() => {
                    setVisibleMedicationModal(false)
                    setSelected({
                        diagnosis: null,
                        medication: null
                    })
                }}
                selectedItem={selected.medication}
                onUpdateMedications={(value) => {
                    const newItem = [...medicationData]
                    const idx = newItem.findIndex(item => item.index === value.index)
                    const item = newItem[idx]

                    newItem.splice(idx, 1, { ...item, ...value })
                    setMedicationData(newItem)

                    handleUpdate(newItem, 'medication')
                }}
                onSubmitMedications={(value) => {
                    const array1 = [{ ...value }];
                    const newArray = array1.concat(medicationData)
                    const result = newArray.map((x, i) => {
                        return {
                            ...x,
                            index: i + 1
                        }
                    })
                    setMedicationData(result)

                    handleUpdate(result, 'medication')
                }}
            />
            {/* Modal update patient details */}
            <ModalPatient
                visible={visiblePatientModal}
                isClosed={() => setVisiblePatientModal(false)}
            />

            <AlertConfirmation
                title={'Are you sure?'}
                // desc={'Are you sure want to end this consultation?'}
                action={'end_consultation'}
                setAlertConfirm={setVisibleModalEndConsultation}
                alertConfirm={visibleModalEndConsultation}
                handleEndButton={() => setShowModalPin({
                    selected: 'prescription',
                    visible: !showModalPin.visible
                })}
            />

            <ModalPin
                visibility={showModalPin.visible}
                loading={loadingPDF}
                handleSubmit={async (obj) => {

                    const medical = prescriptionDataLayer.consultation?.medical_certificates?.[0];
                    const referral = prescriptionDataLayer.consultation?.referral_letters?.[0];

                    let newArray = [
                        {
                            ...obj,
                            name: 'prescription',
                            upload: true,
                            pdf: pdfTelemedicine.pdfPrescription(prescriptionDataLayer)
                        }
                    ]

                    if (medical) {
                        newArray.push({
                            ...obj,
                            name: 'medicalCert',
                            upload: true,
                            pdf: pdfTelemedicine.pdfMedicalCert({ prescription: prescriptionDataLayer, medical })
                        })
                    }

                    if (referral) {
                        newArray.push({
                            ...obj,
                            name: 'referralLetter',
                            upload: true,
                            pdf: pdfTelemedicine.pdfReferralLetter({ prescription: prescriptionDataLayer, referral })
                        })
                    }

                    if (prescriptionDataLayer.consultation.status !== 'completed') {
                        for (let i = 0; i < newArray.length; i++) {
                            await handleGeneratePDF(newArray[i])
                        }
                    }

                    // if (showModalPin.selected === 'prescription') {
                    //     if (prescriptionDataLayer.consultation.status !== 'completed') {
                    //         await handleGeneratePDF({
                    //             ...obj,
                    //             name: 'prescription',
                    //             upload: true,
                    //             pdf: pdfTelemedicine.pdfPrescription(prescriptionDataLayer)
                    //         })
                    //     }
                    //     const el = document.getElementById('chat-popup')
                    //     el.classList.remove('d-none')
                    // } else if (showModalPin.selected === 'medicalCert') {
                    //     let payload = medicalCertPayload;

                    //     handleGeneratePDF({
                    //         ...obj,
                    //         name: 'medicalCert',
                    //         upload: true,
                    //         pdf: pdfTelemedicine.pdfMedicalCert({ prescription: prescriptionDataLayer, medical: payload })
                    //     })

                    //     const el = document.getElementById('chat-popup')
                    //     el.classList.remove('d-none')
                    // } else if (showModalPin.selected === 'referralLetter') {
                    //     let payload = referralLetterPayload;

                    //     handleGeneratePDF({
                    //         ...obj,
                    //         name: 'referralLetter',
                    //         upload: true,
                    //         pdf: pdfTelemedicine.pdfReferralLetter({ prescription: prescriptionDataLayer, referral: payload })
                    //     })

                    //     const el = document.getElementById('chat-popup')
                    //     el.classList.remove('d-none')

                    // }
                }}
                isClosed={() => {
                    setShowModalPin({
                        selected: '',
                        visible: !showModalPin.visible
                    })
                }}
            />

            <AlertConfirmation
                title={'Are you sure?'}
                // desc={'Are you sure want to accept this consultation?'}
                action={'accept_consultation'}
                setAlertConfirm={setModalAcceptConsultation}
                alertConfirm={visibleAcceptConsultation}
                handleAcceptButton={() => {
                    updateStatusConsultation('accepted')

                    const data = globalData.global.tele_medicine && globalData.global.tele_medicine.count;
                    const value = !data ? 0 : data
                    setGlobalData({
                        type: ACTION_TYPE.GET_CONSULTATION_COUNT,
                        payload: value > 0 ? value - 1 : 0
                    })
                }
                }
            />
            <AlertSuccess setSweetTimmer={setSweetTimmer} sweetTimmer={sweetTimmer} title="Saved!" />

            <AlertError
                title={"ERROR"}
                setErrMsg={setErrMsg}
                errMsg={errMsg}
            />

            <ModalExtandVideoCall
                title={"Reminder"}
                visible={showMessage}
                onConfirm={handleExtandCall}
                onCancel={() => {
                    handleExtandCall()
                }}
                onEndCall={() => {
                    handleEndVideoCall();
                }}
                timer={parseFloat(formatMin(seconds))}
            />

            <div id='minimised-cam' className='border border-light rounded shadow mini-cam overflow-hidden'>
                <div className='d-flex justify-content-center align-items-center position-absolute bg-light w-100' style={{ height: 150 }}><i className="ri-user-line ri-3x"></i></div>
                <Button size='sm' color='light' className='m-1 position-absolute' style={{ top: 5, left: 5, zIndex: 10 }} onClick={() => {
                    setVideoCallConfig(prev => {
                        return {
                            ...prev,
                            visible: true,
                            isMinimised: false,
                        }
                    })

                    const el2 = document.getElementById('minimised-cam');
                    el2.style.opacity = '0';

                    const el = document.getElementById('chat-popup')
                    el.classList.remove('d-block')
                    el.classList.add('d-none')

                    setTimeout(() => {
                        const rtc = videoCallConfig.rtc;
                        const video = rtc && rtc.localVideoTrack;
                        if (video) {
                            const element = document.getElementById("player-left");
                            video.play(element);
                        }

                        const videoUser = userVideoTrack;

                        if (videoUser) {
                            const elementUser = document.getElementById("player-other");
                            videoUser.play(elementUser);
                        }


                    }, 1000);

                }}><i className="ri-fullscreen-line align-middle"></i></Button>
                <div id='doctor-cam' className='bg-light' style={{ height: 150, width: 300, zIndex: 1 }}></div>
            </div>
        </React.Fragment>
    )
}

export default PrescriptionFormContent