export const initialTypeOfService = [
    {
        key: 1,
        key_name: 'nursing_care',
        title: 'Nursing Care',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 2,
        key_name: 'physiotherapy',
        title: 'Physiotherapy',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 3,
        key_name: 'general_care_assistant',
        title: 'General Care Assistant',
        subtitle: '(Non-Nursing)',
        name: '',
        value: false
    },
    {
        key: 4,
        key_name: 'home_to_hospital_assistance',
        title: 'Home to Hospital Assistance',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 5,
        key_name: 'post_surgical_wound_care',
        title: 'Post Surgical & Wound Care',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 6,
        key_name: 'orthopaedic_care',
        title: 'Orthopaedic Care',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 7,
        key_name: 'post_natal_care',
        title: 'Post Natal Care',
        subtitle: '(Midwifery/after birth)',
        name: '',
        value: false
    },
    {
        key: 8,
        key_name: 'neonatal_care',
        title: 'Neonatal Care',
        subtitle: '(Newborn baby)',
        name: '',
        value: false
    },
    {
        key: 9,
        key_name: 'past_neurology_care',
        title: 'Past Neurology Care',
        subtitle: '(Stroke, Paralysis, etc)',
        name: '',
        value: false
    },
    {
        key: 10,
        key_name: 'paediatric_care',
        title: 'Paediatric Care',
        subtitle: '(Children)',
        name: '',
        value: false
    },
    {
        key: 11,
        key_name: 'hospice_terminal_care',
        title: 'Hospice/Terminal Care',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 12,
        key_name: 'respite_care',
        title: 'Respite Care',
        subtitle: '(Temporary care of a sick, elderly, or disabled person, providing relief or their usual carer)',
        name: '',
        value: false
    },
]

export const AdditionalTreatmentRequired = [
    {
        key: 1,
        key_name: 'change_dressing',
        title: 'Change dressing',
        subtitle: 'Sterile pad or bandage or compress applied to a wound to promote healing and protect the wound from further harm.',
        name: '',
        value: false
    },
    {
        key: 2,
        key_name: 'perform_enema',
        title: 'Perform enema (liquid treatment to enable pass motion)',
        subtitle: 'Technique used to stimulate stool evacuation. Most commonly used to relieve severe constipation.',
        name: '',
        value: false
    },
    {
        key: 3,
        key_name: 'change_urinary_catheter',
        title: 'Change urinary catheter',
        subtitle: 'Tube placed in the abdomen to drain and collect urine from the bladder.',
        name: '',
        value: false
    },
    {
        key: 4,
        key_name: 'change_tube_feeding',
        title: 'Change tube feeding',
        subtitle: 'Tube used to provide nutrition to people who cannot obtain nutrition by mouth, are unable to swallow safely, or need nutritional supplementation.',
        name: '',
        value: false
    },
    {
        key: 5,
        key_name: 'oral_respiratory',
        title: 'Oral-respiratory suctioning',
        subtitle: 'Suctioning to remove mucous secretions and foreign material (vomit or gastric secretions) from the mouth and throat.',
        name: '',
        value: false
    },
]

export const assessmentTab = [
    {
        key: '1',
        title: 'Type of Services'
    },
    {
        key: '2',
        title: 'Additional Treatment Required'
    },
    {
        key: '3',
        title: 'Medical supplies/Medication required'
    },
    {
        key: '4',
        title: 'Assessment'
    },
]

export const assessmentTab2 = [
    {
        key: '1',
        title: 'Confinement Services'
    },
    {
        key: '2',
        title: 'Confinement Add-ons'
    }
]

export const intialAssessment = [
    {
        key: 1,
        key_name: 'alret',
        title: 'Alert',
        subtitle: "(unconscious / semi-conscious)",
        name: '',
        value: false
    },
    {
        key: 2,
        key_name: 'ambulate',
        title: 'Ambulate',
        subtitle: "Ability to walk from place to place independently with or without assistive device",
        name: '',
        value: false
    },
    {
        key: 3,
        key_name: 'get_up_from_seated_position',
        title: 'Get up from seated position',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 4,
        key_name: 'get_up_from_bed',
        title: 'Get up from bed',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 5,
        key_name: 'bath',
        title: 'Bath / Toileting on their own',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 6,
        key_name: 'feed_on_their_own',
        title: 'Feed on their own',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 7,
        key_name: 'patient_problem',
        title: 'Does the patient have problems with speech (slurred speech), hearing (loss of hearing), sight (reduce or loss vision) or touch (numbness over hands and feet) ?',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 8,
        key_name: 'move_upper',
        title: 'Does the patient have problems to move upper (arms, hands) and lower limbs (legs)?',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 9,
        key_name: 'memory_loss',
        title: 'Does the patient have problems with memory loss / anxiety / depression / fainting episode or others?',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 10,
        key_name: 'breathing',
        title: 'Does the patient have shortness of breath, chest tightness, pain or whistling sound while breathing?',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 11,
        key_name: 'disease',
        title: 'Does the patient have a previous history of respiratory disease such as asthma, COAD, pneumonia?',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 10,
        key_name: 'swelling',
        title: 'Does the patient have chest pain while resting or doing activities, feeling of heart racing or pounding, hard time catching breath, or swelling over both legs and feet?',
        subtitle: "",
        name: '',
        value: false
    },
    {
        key: 10,
        key_name: 'medications',
        title: 'The patient unable to take medications on their own?',
        subtitle: "",
        name: '',
        value: false
    },
]


export const siteVisitContent = {
    title: "Set Site Visit?",
    body: "This action will notify the user and the bill for Site Visit will be included in the summary.",
    btnSubmit: "Okay"
}

export const setSiteVisitContent = {
    title: "Do you want to set site visit?",
    body: "This will turn this booking into site visit booking.",
    btnSubmit: "Set Site Visit"
}

export const proceedPaymentContent = {
    title: "Proceed to Payment?",
    body: "This action will notify the user and they can proceed with the payment as stated in the invoice",
    btnSubmit: "Yes & Notify"
}

export const completeSiteVisitContent = {
    title: "Site Visit Completed?",
    body: "Complete site visit and notify the patient.",
    btnSubmit: 'Complete Site Visit',
}

export const providerCOntent = [
    {
        path: '/booking/nurse',
        title: "Nurse",
        backUrl: "/booking/nurse",
        filter: "nurse"
    },
    {
        path: '/booking/physio',
        title: "Physio",
        backUrl: "/booking/physio",
        filter: "physio"
    },
    {
        path: '/booking/medical-assistant',
        title: "Medical Assistant",
        backUrl: "/booking/medical-assistant",
        filter: "medical_assistant"
    },
    {
        path: '/booking/care-aide',
        title: "Care Aide",
        backUrl: "/booking/care-aide",
        filter: "care_aide"
    },
    {
        path: '/booking/confinement',
        title: "Confinement",
        backUrl: "/booking/confinement",
        filter: "confinement"
    }
]