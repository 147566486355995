import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, Row, Spinner, Input, ModalHeader } from 'reactstrap'
import classNames from 'classnames';
import RegisterBy from './Form/RegisterBy';
import { optionFee, optionRate, TabArr, TabArr2 } from '../Store/Option'
import { TEXT_CLOSE, TEXT_SUBMIT } from '../../../../../Text';
import Notes from './Form/Notes';
import Patient from './Form/Patient';
import { ACTION_TYPE } from '../Store/ActionType';
import Assessment from './Form/Assessment/AssessmentsContent'
// import { NurseContext } from '../Store/NurseContext';
import { get, getAPIroot, post, update } from '../../../../../helpers/API';
import Schdule from "./Form/Schdule";
import Provider from './Form/Provider';
import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext';
import moment from 'moment'
import AlertSuccess from '../../../../CustomComponent/Alert/AlertSuccess';
import AlertError from '../../../../CustomComponent/Alert/AlertError';
import Billing from './Form/Billing';
import EditButton from './EditButton';
import Tablist from './Tablist';
import Feedback from './Form/Feedback';
import Rating from "react-rating";

//Import Calendar
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';


// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/bootstrap/main.css";
import { pdfGeneratorQuotationBooking, pdfGeneratorInvoiceBooking } from '../../../../../helpers/pdfGenerator/pdfGenerator';
import { handleGenerateInvoice } from '../Store/generateQuotation';

const containerSpinner = {
    position: "absolute", height: "100%", width: "100%", margin: "auto", display: "flex", justifyContent: "center", alignItems: "center"
}

const calenderBody = { position: "relative", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }


const BodyNurse = ({
    dataSource,
    dispatch,
    handleShowNoti,
    handleUpdateBooking,
    timeSlot,
    setTimeSlot,
    countValue,
    handleRemoveFile
}) => {
    const { globalData } = useContext(GlobalContext);
    // const [dataSource, dispatch] = useContext(NurseContext);
    const [modalFeedback, setModalFeedback] = useState(false)
    const [bookingSettingID, setBookingSettingID] = useState([])
    const [dataFeedback, setDataFeedback] = useState({
        rate: [],
        customer_comment: ""
    })
    const [activeChildTab, setActiveChildTab] = useState('1')
    const [activeTab, setActiveTab] = useState('')
    const [formValues, setFormValues] = useState(dataSource.formData)
    const [visibleModal, setVisibleModal] = useState(false)
    const [isEditMode, setEditMode] = useState(null)
    const [
        filterTab,
        setTab
    ] = useState(TabArr)
    const [imgURL, setImgURL] = useState('');
    const [visible, setVisible] = useState(false);
    const [newNote, setNewNote] = useState('');
    const [newTitleNote, setNewTitleNote] = useState('');
    const [isloadingchat, setisloadingchat] = useState(false);
    const [calendarEvents, setcalendarEvents] = useState([]);
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ""
    });
    const [sweetTimmer, setSweetTimmer] = useState(false);
    const [dataSourceFeedBack, setDataSourceFeedBack] = useState(!formValues.booking_feedback ? [] : formValues.booking_feedback)
    const [loadingCalender, setLoadingCalender] = useState(true)
    const [disableDate, setDisableDate] = useState([])
    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [errorMsgFormSchedule, setErrorMsgFormSchedule] = useState('')
    const [download, setDownload] = useState(false)
    const [downloadInvoice, setDownloadInvoice] = useState(false)
    const [customScheduleRate, setCustomScheduleRate] = useState({
        totalDays: !formValues.custom_schedule_days ? null : parseInt(formValues.custom_schedule_days),
        totalBills: !formValues.custom_schedule_rate && !formValues.custom_schedule_rate ? 0 : optionRate.find(x => x.label === formValues.custom_schedule_rate).price,
        totalHours: !formValues.custom_schedule_rate ? null : optionRate.find(x => x.label === formValues.custom_schedule_rate),
        totalPerDay: formValues.custom_schedule_custom_rate === null ? 0 : formValues.custom_schedule_custom_rate.hours,
        totalPerHours: formValues.custom_schedule_custom_rate === null ? 0 : formValues.custom_schedule_custom_rate.price_rate,
        locum: formValues.custom_schedule_custom_rate === null ? 0 : formValues.custom_schedule_custom_rate.locum,
    });
    const [customScheduleSetting, setCustomScheduleSetting] = useState({
        type: !formValues.custom_schedule_fee_setting ? optionFee[0] : optionFee.find(x => x.value === formValues.custom_schedule_fee_setting.type),
        processing_fee: !formValues.custom_schedule_fee_setting ? 0 : formValues.custom_schedule_fee_setting.processing_fee,
        deposit_fee: !formValues.custom_schedule_fee_setting ? 0 : formValues.custom_schedule_fee_setting.deposit_fee,
        transportation_fee: !formValues.custom_schedule_fee_setting ? 0 : formValues.custom_schedule_fee_setting.transportation_fee,
    });
    const [totalSelectedDays, setTotalSelectedDays] = useState(0)
    const [selectedQuotation, setSelectedQuotation] = useState(null)
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [confinementServices, setConfinementServices] = useState([])
    const [confinementAddons, setConfinementAddons] = useState([])

    const downloadStatus = useMemo(() => {
        return download
    }, [download])

    const downloadInvoiceStatus = useMemo(() => {
        return downloadInvoice
    }, [downloadInvoice])

    const getConfinementService = useCallback(() => {
        get(`/confinement-services`).then(res => {
            const newData = res.map(item => {
                return {
                    ...item,
                    is_tick: false
                }
            })
            setConfinementServices(newData)
        })
    }, [])

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            getConfinementService()
        }

        return () => isMounted = false
    }, [getConfinementService])

    const getConfinementAddons = useCallback(() => {
        get(`/confinement-addons`).then(res => {
            const newData = res.map(item => {
                return {
                    ...item,
                    is_tick: false
                }
            })
            setConfinementAddons(newData)
        })
    }, [])

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            getConfinementAddons()
        }

        return () => isMounted = false
    }, [getConfinementAddons])

    useEffect(() => {
        if (downloadStatus && dataSource.formData.invoices && dataSource.formData.invoices.length > 0) {
            let data = handleGenerateInvoice(dataSource.formData, customScheduleRate, customScheduleSetting, selectedQuotation, globalData.global.user)
            pdfGeneratorQuotationBooking.generatorInvoiceBooking(data);

            setDownload(!download)
        }
    }, [downloadStatus, dataSource, customScheduleRate, customScheduleSetting, setDownload, download, selectedQuotation, globalData.global.user])

    useEffect(() => {
        if (downloadInvoiceStatus && dataSource.formData && dataSource.formData.invoices && dataSource.formData.invoices.length > 0) {
            // console.log(dataSource.formData);
            let invoice_data = dataSource.formData.invoices.find(x => x.id === selectedInvoice);
            let data = handleGenerateInvoice(dataSource.formData, customScheduleRate, customScheduleSetting, selectedInvoice, globalData.global.user)
            pdfGeneratorInvoiceBooking.generatorInvoiceBooking({ ...data, invoice_data: invoice_data });
            setDownloadInvoice(!downloadInvoice)
        }
    }, [downloadInvoiceStatus, dataSource, setDownloadInvoice, customScheduleRate, customScheduleSetting, downloadInvoice, selectedInvoice, globalData.global.user])

    const renderTablist = useMemo(() => {
        let tabRegisteredBySomeOne = TabArr.filter(x => {
            if (dataSource.formData.schedule_mode === 'custom') {
                return x.key !== "RegisterBy" && x.key !== "Provider"
            } else {
                return x.key !== "RegisterBy"
            }
        });

        return ({
            tabList: dataSource.mode_disabled ? TabArr2 : !dataSource.formData.is_someone_else ? tabRegisteredBySomeOne : TabArr,
            parentKey: dataSource.mode_disabled ? 'Patient' : !dataSource.formData.is_someone_else ? 'Patient' : 'RegisterBy',
        })
    }, [dataSource.mode_disabled, dataSource.formData.is_someone_else, dataSource.formData.schedule_mode]);

    useEffect(() => {
        get(`/booking-setting`).then(res => {
            setBookingSettingID(res.booking_feedbacks)
            let item = res.booking_feedbacks.map(x => {
                return {
                    name: x.name,
                    rate: 0,
                    feedback_id: x.id
                }
            })

            setDataFeedback({
                customer_comment: "",
                rate: item
            })
        }).catch({})
    }, [])

    // useEffect(() => {
    //     if (formValues.is_feedback_submitted && formValues.booking_feedback !== undefined && formValues.booking_feedback !== null) {
    //         get(`/booking-feedbacks/${formValues.booking_feedback.id}`).then(res => {
    //             setDataSourceFeedBack(res);
    //         }).catch({})
    //     }
    // }, [formValues.is_feedback_submitted, formValues.booking_feedback])


    useEffect(() => {
        getProviderJobs(formValues, calendarEvents.length)
    }, [formValues, calendarEvents.length]);

    const getProviderJobs = (bData, cLength) => {
        setLoadingCalender(true)
        get(`/provider-booking-jobs?_limit=-1`).then(res => {
            setLoadingCalender(false)
            const filterProviderJob = res.filter(x => {
                let obj = null;
                if (x.type_of_job === 'assigned' && x.booking_form !== null) {

                    if (x.booking_form.booking_number === bData.booking_number) {
                        obj = x;
                    }
                }
                return obj
            });


            let newArrDisable = []
            let totalDay = []

            const dataSource = filterProviderJob.map((x, i) => {
                let startD = null;
                let endD = null;

                if (moment(x.schedule_start_datetime).format("YYYY-MM-DD") !== moment(x.schedule_end_datetime).format("YYYY-MM-DD")) {
                    newArrDisable.push({
                        after: new Date(moment(x.schedule_start_datetime).format("YYYY"), moment(x.schedule_start_datetime).format("MM") - 1, moment(x.schedule_start_datetime).format("DD")),
                        before: new Date(moment(x.schedule_end_datetime).format("YYYY"), moment(x.schedule_end_datetime).format("MM") - 1, moment(x.schedule_end_datetime).format("DD")),
                    })

                    let diffTime = moment(moment(x.schedule_end_datetime).format('YYYY-MM-DD')).diff(moment(x.schedule_start_datetime).format('YYYY-MM-DD'), 'days');
                    totalDay.push(diffTime + 1)

                } else {
                    newArrDisable.push(new Date(moment(x.schedule_start_datetime).format("YYYY"), moment(x.schedule_start_datetime).format("MM") - 1, moment(x.schedule_start_datetime).format("DD")))
                    if ((x.schedule_start_datetime === null) && (x.schedule_end_datetime === null) && i) {
                        totalDay.push(1)
                    }
                }

                let sum = totalDay.reduce((a, b) => a + b, 0)
                setTotalSelectedDays(sum)

                setDisableDate(newArrDisable);

                if (x.schedule_start_datetime) {
                    startD = moment(x.schedule_start_datetime)._d
                }

                if (x.schedule_end_datetime) {
                    endD = moment(x.schedule_end_datetime)._d
                }

                return {
                    id: cLength + 1,
                    title: `Assign to ${x.provider.fullname}`,
                    start: startD,
                    end: endD,
                    className: 'bg-primary',
                    allDay: false,
                }
            })


            setcalendarEvents(dataSource)
        }).catch(() => { })
    }

    useEffect(() => {
        setTab(renderTablist.tabList)
        setActiveTab(renderTablist.parentKey)
    }, [renderTablist])

    const handleParentKey = (key) => {
        setActiveChildTab('1')
        setActiveTab(key)
        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                childeTab: null,
                mode: false
            }
        })
    }

    const handleChildkey = (key) => {
        setActiveChildTab(key)
        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                childeTab: null,
                mode: false
            }
        })
    }

    const handleModal = (data) => {
        setVisible(true)
        if (data && data.url) return setImgURL(getAPIroot() + data.url)
    }

    const handleSubmitNote = () => {
        let data = {
            "booking_form": formValues.booking_note.booking_form,
            "chat_note": [
                ...formValues.booking_note.chat_note,
                {
                    "datetime": moment().toISOString(),
                    "user": globalData.global.user ? globalData.global.user.id : null,
                    "title": newTitleNote,
                    "notes": newNote
                }
            ],
        }
        setisloadingchat(true)
        update(`/booking-notes/${formValues.booking_note.id}`, data).then(res => {
            setNewNote('')
            setNewTitleNote('')
            setFormValues(prev => {
                return {
                    ...prev,
                    booking_note: {
                        ...prev.booking_note,
                        chat_note: res.chat_note
                    }
                }
            });
            setisloadingchat(false)
        }).catch(err => setisloadingchat(false))
    }

    const handleSubmitNotes = (e) => {
        e.preventDefault();
    }

    const handleSubmitEdit = (value) => {
        setNewNote(value)
    }

    const handleClickTag = (e) => {
        // console.log(e);
    }

    const handleDateClick = (arg) => {
        let date = arg.dateStr;
        let availabledays = 0;
        let tday = dataSource.formData.custom_schedule_days === "" ? 0 : parseInt(dataSource.formData.custom_schedule_days);

        if (tday !== 0) {
            availabledays = parseInt(tday) - parseInt(totalSelectedDays)
        }

        // const invoiceStatus = dataSource.formData.invoices.filter(x => {
        //     return x.invoice_number.includes('SNSB') && x
        // })

        if (!loadingCalender && (dataSource.formData.financial_status === 'completed') && (availabledays > 0)) {
            if (date) {
                setSelectedStartDate(date)
                setDisableDate([...disableDate])
            }
            setVisibleModal(!visibleModal)
            setErrorMsgFormSchedule('')
        }
    }

    const handleDownloadQuataion = (invoice_no) => {
        setSelectedQuotation(invoice_no)
        setDownload(!download)
    }

    const handleDownloadInvoice = (_id) => {
        setSelectedInvoice(_id)
        setDownloadInvoice(!downloadInvoice)
    }

    const handleFeedback = (e, data) => {
        let checking = dataFeedback.rate.find(x => x.feedback_id === data.id)
        let value = {
            ...dataFeedback,
            rate: [...dataFeedback.rate, { feedback_id: data.id, rate: e, name: data.name }]
        }
        if (checking) {
            let newItem = [...dataFeedback.rate]
            let index = newItem.findIndex(x => x.feedback_id === data.id);
            let item = newItem[index]

            let newObj = {
                ...item,
                rate: e,
            }

            newItem.splice(index, 1, { ...item, ...newObj })
            value = {
                ...dataFeedback,
                rate: newItem
            }
        }
        setDataFeedback(value)
    }

    const handleSubmitFeedback = (e) => {
        e.preventDefault();

        let totalAverage = dataFeedback.rate.length;
        let sumOfStar = dataFeedback.rate.map((item, index) => { return item.rate }).reduce((a, b) => a + b, 0)

        let data = {
            overall_booking_rate: sumOfStar / totalAverage,
            feedback_rates: dataFeedback.rate,
            customer_comment: dataFeedback.customer_comment,
            booking_form: formValues.id
        }

        post(`/booking-feedbacks`, data).then(res => {
            setModalFeedback(!modalFeedback)
            handleUpdateBooking(null, ACTION_TYPE.ACTION_UPDATE_FORM)
            setDataSourceFeedBack(res)
        })
    }

    return (
        <Row>
            <Col md={7}>
                <Card>
                    <CardBody className="pt-0">

                        <Tablist
                            filterTab={filterTab}
                            handleShowNoti={handleShowNoti}
                            activeTab={activeTab}
                            handleParentKey={handleParentKey}
                        />


                        {activeTab === 'RegisterBy' &&
                            <RegisterBy
                                isEditMode={dataSource.editMode}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                handleModal={handleModal}
                                setVisible={setVisible}
                                visible={visible}
                                setImgURL={setImgURL}
                                imgURL={imgURL}
                                dispatch={dispatch}
                                dataSource={dataSource}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                setErrMsg={setErrMsg}
                                errMsg={errMsg}
                            />}

                        {activeTab === 'Patient' &&
                            <Patient
                                isEditMode={dataSource.editMode}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                handleModal={handleModal}
                                setVisible={setVisible}
                                visible={visible}
                                setImgURL={setImgURL}
                                imgURL={imgURL}
                                dispatch={dispatch}
                                dataSource={dataSource}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                setErrMsg={setErrMsg}
                                errMsg={errMsg}
                            />
                        }

                        {activeTab === 'Assessment' &&
                            <Assessment
                                isEditMode={dataSource.editMode}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                dispatch={dispatch}
                                dataSource={dataSource}
                                setActiveChildTab={handleChildkey}
                                activeChildTab={activeChildTab}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                setErrMsg={setErrMsg}
                                errMsg={errMsg}
                                setCustomScheduleSetting={setCustomScheduleSetting}
                                setCustomScheduleRate={setCustomScheduleRate}
                                confinementServices={confinementServices}
                                confinementAddons={confinementAddons}
                            />
                        }

                        {activeTab === 'Schedule' &&
                            <Schdule
                                setCustomScheduleSetting={setCustomScheduleSetting}
                                customScheduleSetting={customScheduleSetting}
                                setCustomScheduleRate={setCustomScheduleRate}
                                customScheduleRate={customScheduleRate}
                                handleUpdateBooking={handleUpdateBooking}
                                isEditMode={dataSource.editMode}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                dispatch={dispatch}
                                dataSource={dataSource}
                                setActiveChildTab={handleChildkey}
                                activeChildTab={activeChildTab}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                setErrMsg={setErrMsg}
                                errMsg={errMsg}
                                timeSlot={timeSlot}
                                setTimeSlot={setTimeSlot}
                                setcalendarEvents={setcalendarEvents}
                                visibleModal={visibleModal}
                                setVisibleModal={setVisibleModal}
                                calendarEvents={calendarEvents}
                                setLoadingCalender={setLoadingCalender}
                                disableDate={disableDate}
                                getProviderJobs={getProviderJobs}
                                selectedStartDate={selectedStartDate}
                                errorMsgFormSchedule={errorMsgFormSchedule}
                                setErrorMsgFormSchedule={setErrorMsgFormSchedule}
                                countValue={countValue}
                                totalSelectedDays={totalSelectedDays}
                            />
                        }

                        {activeTab === 'Provider' &&
                            <Provider
                                isEditMode={dataSource.editMode}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                dispatch={dispatch}
                                dataSource={dataSource}
                                setActiveChildTab={handleChildkey}
                                activeChildTab={activeChildTab}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                setErrMsg={setErrMsg}
                                errMsg={errMsg}
                            />
                        }

                        {activeTab === 'Billing' &&
                            <Billing
                                handleDownloadQuataion={handleDownloadQuataion}
                                handleUpdateBooking={handleUpdateBooking}
                                setErrMsg={setErrMsg}
                                errMsg={errMsg}
                                isEditMode={dataSource.editMode}
                                setFormValues={setFormValues}
                                dispatch={dispatch}
                                dataSource={dataSource}
                                setActiveChildTab={handleChildkey}
                                activeChildTab={activeChildTab}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                handleRemoveFile={handleRemoveFile}
                                handleDownloadInvoice={handleDownloadInvoice}
                            />
                        }

                        {activeTab === 'Feedback' &&
                            <Feedback
                                dataSourceFeedBack={dataSourceFeedBack}
                                setModalFeedback={setModalFeedback}
                                modalFeedback={modalFeedback}
                            />
                        }

                        <EditButton
                            dataSource={dataSource}
                            activeTab={activeTab}
                            isEditMode={isEditMode}
                            setEditMode={setEditMode}
                            dispatch={dispatch}
                            activeChildTab={activeChildTab}
                            handleDownloadQuataion={handleDownloadQuataion}
                            customScheduleSetting={customScheduleSetting}
                            customScheduleRate={customScheduleRate}
                        />

                        {((activeTab === 'Schedule') && (formValues.schedule_mode === "custom") && (dataSource.formData.financial_status === 'completed')) &&
                            <div className="border-top pb-4">
                                <div style={calenderBody}>
                                    {loadingCalender ?
                                        <div style={containerSpinner}>
                                            <Spinner className="mr-2" style={{ zIndex: 12 }} color="primary" />
                                        </div>
                                        : <FullCalendar
                                            style={{ zIndex: 0 }}
                                            defaultView="dayGridMonth"
                                            plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                                            handleWindowResize={true}
                                            themeSystem="bootstrap"
                                            header={{
                                                left: "prev,next today",
                                                center: "title",
                                                right: "dayGridMonth,dayGridWeek,dayGridDay"
                                            }}
                                            dateClick={handleDateClick}
                                            editable={false}
                                            droppable={false}
                                            eventLimit={true}
                                            selectable={true}
                                            events={calendarEvents}
                                            eventClick={(e) => {
                                                handleClickTag(e)
                                            }}
                                        />}

                                </div>
                            </div>
                        }

                    </CardBody>
                </Card>
            </Col>
            <Col md={5}>
                <Card>
                    <CardBody className="pt-0">
                        <Nav tabs className="nav-tabs-custom mb-4">
                            <NavItem>
                                <NavLink className={classNames({ active: true }, "font-weight-bold p-3")}>Note</NavLink>
                            </NavItem>
                        </Nav>
                        <Form onSubmit={handleSubmitNotes}>
                            <Notes
                                formValues={formValues}
                                setFormValues={setFormValues}
                                handleSubmitNote={handleSubmitNote}
                            />
                        </Form>


                        <Input
                            className="form-control mb-2"
                            placeholder="Title"
                            value={newTitleNote}
                            onChange={e => setNewTitleNote(e.target.value)}
                        />
                        <textarea
                            className="form-control"
                            type="text"
                            value={newNote}
                            placeholder="Log your activities"
                            onChange={e => handleSubmitEdit(e.target.value)}
                            rows={3}
                        />

                        <CardBody className="border-top pr-0">
                            <FormGroup className="mb-2">
                                <Button className="float-right" color="primary" onClick={() => handleSubmitNote()}>
                                    {isloadingchat ?
                                        <span><Spinner className="mr-2" color="light" style={{ height: '1.3rem', width: '1.3rem' }} />{TEXT_SUBMIT}</span> : TEXT_SUBMIT}
                                    <i className="ri-send-plane-2-fill align-middle ml-2" />
                                </Button>
                            </FormGroup>
                        </CardBody>
                    </CardBody>
                </Card>
            </Col>


            <Modal
                backdrop="static"
                isOpen={visible}
                toggle={handleModal}
            >
                <ModalBody className="p-0">
                    <img src={imgURL} alt="" />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setVisible(false)
                        setImgURL('')
                    }}>
                        {TEXT_CLOSE}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                backdrop="static"
                isOpen={modalFeedback}
                toggle={() => setModalFeedback(!modalFeedback)}
            >
                <ModalHeader>Satisfaction Survey</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmitFeedback}>
                        <div className="text-center">
                            {bookingSettingID.map((item, i) => {
                                return <div key={i}>
                                    <div className="p-4 text-center">
                                        <h5 className="font-16 m-b-15">{item.name}</h5>
                                        <Rating
                                            placeholderRating={dataFeedback.rate.find(x => x.feedback_id === item.id) !== undefined ? dataFeedback.rate.find(x => x.feedback_id === item.id).rate : 0}
                                            emptySymbol="ri-star-line fa-2x"
                                            fullSymbol="ri-star-fill fa-2x"
                                            placeholderSymbol={<i style={{ color: "red" }} className="ri-star-fill fa-2x" />}
                                            fractions={2}
                                            onClick={e => handleFeedback(e, item)}
                                        />

                                    </div>

                                </div>
                            })}
                            <label style={{ fontSize: 18 }}>Any other comment?</label>
                            <Input type="textarea" value={!dataFeedback.customer_comment ? "" : dataFeedback.customer_comment} onChange={e => setDataFeedback({ ...dataFeedback, customer_comment: e.target.value })} />
                        </div>
                        <div className="mt-3">
                            <Button color="primary" className="mr-2">Submit</Button>
                            <Button color="light" onClick={() => setModalFeedback(!modalFeedback)}>Cancel</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <AlertSuccess
                title="Saved!"
                setSweetTimmer={setSweetTimmer}
                sweetTimmer={sweetTimmer}
            />

            <AlertError
                title="Error!"
                setErrMsg={setErrMsg}
                errMsg={errMsg}
            />
        </Row>
    )
}

export default BodyNurse

