import React, { useContext, useState } from 'react'
import { Button, Col, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap'
import Select from 'react-select'
import { convertBase64, formatBytes } from '../../store/CustomFunctions';
import axios from 'axios';
import { GlobalContext } from '../../store/PageWrapper/GlobalContext';
import { getAPIrootTrustGate, postMyTrustGate } from '../../helpers/API';
import imgLoading from '../../assets/images/loadingsimg.png'
import imgSuccess from '../../assets/images/success.png'
import useLocalStorage from '../../helpers/useLocalStorage';
import AlertError from '../CustomComponent/Alert/AlertError';
import imgVerification from "../../assets/images/verification.png"
import imgError from "../../assets/images/error.png"
import { ACTION_TYPE } from '../../store/PageWrapper/ActionType';
// import { initialState } from '../../store/PageWrapper/Store';

const initialPayload = {
    UserID: "",
    IDType: null,
    FullName: "",
    Nationality: null,
    EmailAddress: "",
    MobileNo: "",
    CertValidity: null,
    Designation: null,
    MembershipNo: "",
    DegreeCert: "",
    PassportImage: "",
    NRICFront: "",
    NRICBack: "",
    OrganisationInfo: {
        orgName: "",
        orgUserDesignation: "",
        orgAddress: "",
        orgAddressCity: "",
        orgAddressState: "",
        orgAddressPostcode: "",
        orgAddressCountry: "",
        orgRegistrationNo: "",
        orgPhoneNo: "",
        orgFaxNo: "",
    }
}

const optionsIDtype = [
    {
        label: 'Malaysian NRIC',
        value: 'N'
    },
    {
        label: 'Passport',
        value: 'P'
    }
]

const optionsNationality = [
    {
        label: 'Malaysia',
        value: 'MY'
    }
]

const optionsCertValidity = [
    {
        label: 'Long Term',
        value: 'L'
    }, {
        label: 'Short Term',
        value: 'S'
    }
]

const optionsDesignation = [
    {
        label: 'Doctor',
        value: 'Doctor'
    },
]


const ModalRegisterCert = ({ visible, isClosed }) => {
    const { globalData, setGlobalData, fetchingUserData } = useContext(GlobalContext)
    const [forms, setForms] = useState(initialPayload);
    const [uploadImg1, setUploadImg1] = useState(null)
    const [uploadImg2, setUploadImg2] = useState(null)
    const [uploadImg3, setUploadImg3] = useState(null)
    const [uploadImg4, setUploadImg4] = useState(null)
    // const [showCancel, setShowCancel] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [myinterval, setmyinterval] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [activationLink, setActivationLink] = useState('')
    const [certData, setCertData] = useState(null)
    const [showErr, setShowErr] = useState({
        visible: false,
        msg: ''
    })


    const handleGetCertInfo = async () => {
        let payload = {
            data: {
                UserID: forms.UserID
            }
        }

        const interval = setInterval(async () => {
            const response = await postMyTrustGate('/get-cert-info', payload);
            let statusCode = response.return.statusCode;
            if (statusCode === '000') {
                if (response.return.certStatus === 'Valid') {
                    setCertData(response.return)
                    setActivationLink('')
                    setErrMsg('')
                    setLoading(false)
                    setShowSuccess(true)
                }
            }
        }, 1500);
        setmyinterval(interval)
    }
    const handleSubmit = async () => {
        let payload = {
            data: {
                ...forms,
                PassportImage: !forms.PassportImage ? null : forms.PassportImage.split(',')[1],
                DegreeCert: !forms.DegreeCert ? null : forms.DegreeCert.split(',')[1],
                NRICFront: !forms.NRICFront ? null : forms.NRICFront.split(',')[1],
                NRICBack: !forms.NRICBack ? null : forms.NRICBack.split(',')[1],
                IDType: !forms.IDType ? '' : forms.IDType.value,
                Nationality: !forms.Nationality ? '' : forms.Nationality.value,
                CertValidity: !forms.CertValidity ? '' : forms.CertValidity.value,
                Designation: !forms.Designation ? '' : forms.Designation.value,
            }
        }
        setLoading(true)
        await axios.post(getAPIrootTrustGate() + '/request-certificate-for-healthcare', payload).then(async response => {
            let statusCode = response.data.return.statusCode;
            switch (statusCode) {
                case '000':
                    setLoading(false)
                    await getRequestStatus(response.data.return.certRequestID);
                    break;
                case 'CR101':
                    setLoading(false)
                    await getRequestStatus(response.data.return.certRequestID);
                    break;
                case 'CR100':
                    setLoading(false)
                    await getRequestStatus(response.data.return.certRequestID);
                    break;
                case 'WS118':
                    setLoading(false)
                    setErrMsg(response.data.return.statusMsg)
                    break;
                case 'WS104':
                    setLoading(false)
                    setErrMsg('Required field missing. Please fill in all the mandatory fields marked with an asterisk (*) to continue.')
                    break;
                default:
                    setLoading(false)
                    setErrMsg(response.data.return.statusMsg)
                    break;

            }
        }).catch(err => {
            setErrMsg('500 Internal Server Error, Please contact customer support for assistance or try again.')
            setLoading(false)
        })
    }
    const getRequestStatus = async (_requestID) => {
        let payload = {
            data: {
                UserRequestList: {
                    userID: forms.UserID,
                    requestID: _requestID
                }
            }
        }

        const response = await axios.post(getAPIrootTrustGate() + '/get-request-status', payload);
        if (response.data.return.statusCode === '000') {
            await requestActivation(response.data.return.dataList[0].requestID);
        } else {
            // setShowCancel(true)
            setErrMsg(response.data.return.statusMsg)
        }
    }
    const requestActivation = async (_requestID) => {
        let payload = {
            data: {
                UserID: forms.UserID,
                RequestID: _requestID
            }
        }

        const response = await axios.post(getAPIrootTrustGate() + '/get-activation', payload);
        let statusCode = response.data.return.statusCode
        switch (statusCode) {
            case '000':
                handleGetCertInfo();
                setActivationLink(response.data.return.activationLink)
                window.open(response.data.return.activationLink)
                break;
            case 'CR100':
                // setShowCancel(true)
                setErrMsg(response.data.return.statusMsg)
                break;
            default:
                // setShowCancel(true)
                setErrMsg(response.data.return.statusMsg)
                break
        }
    }
    const handleAcceptedFiles1 = async (event) => {
        setShowErr({
            visible: false,
            msg: ''
        })
        const file = event.target.files[0];
        const fileSize = file && formatBytes(file.size);
        const fileFormat = file.type

        const allowSize = fileSize.includes('KB') || fileSize.includes('Bytes') || fileSize.includes('1 MB')
        const allowFormat = fileFormat === 'application/pdf';

        if (allowSize && allowFormat) {
            const base64 = await convertBase64(file);
            setUploadImg1({
                name: file.name,
                size: fileSize
            });
            setForms(prev => {
                return {
                    ...prev,
                    DegreeCert: base64
                }
            })
        } else {
            setUploadImg1(null);
            setForms(prev => {
                return {
                    ...prev,
                    DegreeCert: null
                }
            })
            setShowErr({
                visible: true,
                msg: !allowFormat ? 'Incorrect document type, please re-upload in the correct format of PDF.' : 'Your file size exceeds the limit, please re-upload a smaller document with a maximum size of 1 MB.'
            })
        }

    }
    const handleAcceptedFiles2 = async (event) => {
        setShowErr({
            visible: false,
            msg: ''
        })
        const files = event.target.files;
        const file = files[0]
        const fileSize = file && formatBytes(file.size);
        const fileFormat = file.type;

        const allowSize = fileSize.includes('KB') || fileSize.includes('Bytes') || fileSize.includes('1 MB')
        const allowFormat = fileFormat === 'image/png' || fileFormat === 'image/jpeg' || fileFormat === 'image/jpg';

        if (allowSize && allowFormat) {
            const base64 = await convertBase64(file);
            setUploadImg2({
                img: URL.createObjectURL(file),
                name: file.name,
                size: fileSize
            });
            setForms(prev => {
                return {
                    ...prev,
                    PassportImage: base64
                }
            })
        } else {
            setUploadImg2(null);
            setForms(prev => {
                return {
                    ...prev,
                    PassportImage: null
                }
            })
            setShowErr({
                visible: true,
                msg: !allowFormat ? 'Incorrect document type, please re-upload in the correct format of JPG, JPEG or PNG' : 'Your file size exceeds the limit, please re-upload a smaller document with a maximum size of 1 MB.'
            })
        }
    }
    const handleAcceptedFiles3 = async (event) => {
        setShowErr({
            visible: false,
            msg: ''
        })
        const files = event.target.files;
        const file = files[0]
        const fileSize = file && formatBytes(file.size);
        const fileFormat = file.type;

        const allowSize = fileSize.includes('KB') || fileSize.includes('Bytes') || fileSize.includes('1 MB')
        const allowFormat = fileFormat === 'image/png' || fileFormat === 'image/jpeg' || fileFormat === 'image/jpg';

        if (allowSize && allowFormat) {
            const base64 = await convertBase64(file);
            setUploadImg3({
                img: URL.createObjectURL(file),
                name: file.name,
                size: fileSize
            });
            setForms(prev => {
                return {
                    ...prev,
                    NRICFront: base64
                }
            })
        } else {
            setUploadImg3(null);
            setForms(prev => {
                return {
                    ...prev,
                    NRICFront: null
                }
            })
            setShowErr({
                visible: true,
                msg: !allowFormat ? 'Incorrect document type, please re-upload in the correct format of JPG, JPEG or PNG' : 'Your file size exceeds the limit, please re-upload a smaller document with a maximum size of 1 MB.'
            })
        }
    }
    const handleAcceptedFiles4 = async (event) => {
        setShowErr({
            visible: false,
            msg: ''
        })
        const files = event.target.files;
        const file = files[0]
        const fileSize = file && formatBytes(file.size);
        const fileFormat = file.type;

        const allowSize = fileSize.includes('KB') || fileSize.includes('Bytes') || fileSize.includes('1 MB')
        const allowFormat = fileFormat === 'image/png' || fileFormat === 'image/jpeg' || fileFormat === 'image/jpg';

        if (allowSize && allowFormat) {
            const base64 = await convertBase64(file);
            setUploadImg4({
                img: URL.createObjectURL(file),
                name: file.name,
                size: fileSize
            });
            setForms(prev => {
                return {
                    ...prev,
                    NRICBack: base64
                }
            })
        } else {
            setUploadImg4(null);
            setForms(prev => {
                return {
                    ...prev,
                    NRICBack: null
                }
            })
            setShowErr({
                visible: true,
                msg: !allowFormat ? 'Incorrect document type, please re-upload in the correct format of JPG, JPEG or PNG' : 'Your file size exceeds the limit, please re-upload a smaller document with a maximum size of 1 MB.'
            })
        }
    }
    const changeState = (label, value) => {
        setForms(prev => {
            return {
                ...prev,
                [label]: value
            }
        })
    }
    const changeStateOrg = (label, value) => {
        setForms(prev => {
            return {
                ...prev,
                OrganisationInfo: {
                    ...prev.OrganisationInfo,
                    [label]: value
                }
            }
        })
    }
    return (
        <Modal size={!loading && !errMsg && !activationLink && !showSuccess ? 'lg' : 'md'} isOpen={visible}>
            <ModalBody>
                {!loading && !errMsg && !activationLink && !showSuccess ? <Form>
                    <h4 className='mb-3'>Personal Information</h4>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>No. of Identity card</Label>
                                    <Input placeholder='Exp: 9010101029120' value={forms.UserID} disabled={loading} onChange={(e) => changeState('UserID', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>ID Type</Label>
                                    <Select options={optionsIDtype} value={forms.IDType} disabled={loading} onChange={(value) => changeState('IDType', value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>FullName (base on NRIC)</Label>
                                    <Input placeholder='Exp: Muhammad Hassan Bin Abdullah' value={forms.FullName} disabled={loading} onChange={(e) => changeState('FullName', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Nationality</Label>
                                    <Select options={optionsNationality} value={forms.Nationality} disabled={loading} onChange={(value) => changeState('Nationality', value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Email</Label>
                                    <Input type='email' placeholder='Exp: muhammadhassan@email.com' disabled={loading} value={forms.EmailAddress} onChange={(e) => changeState('EmailAddress', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Mobile No.</Label>
                                    <Input type='number' placeholder='Exp: 60133257202' disabled={loading} value={forms.MobileNo} onChange={(e) => changeState('MobileNo', e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Certificate Validity</Label>
                                    <Select options={optionsCertValidity} disabled={loading} value={forms.CertValidity} onChange={(value) => changeState('CertValidity', value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Designation</Label>
                                    <Select options={optionsDesignation} disabled={loading} value={forms.Designation} onChange={(value) => changeState('Designation', value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Malaysian Medical Counsel Certificate (MMC) No.</Label>
                                    <Input type='text' placeholder='Exp: A-123123' disabled={loading} value={forms.MembershipNo} onChange={(e) => changeState('MembershipNo', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                    <div className='mb-4'>
                        <h4 className='mb-3'>Organisation Info</h4>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Name</Label>
                                    <Input placeholder='Exp: OGG SDN BHD' disabled={loading} value={forms.OrganisationInfo.orgName} onChange={(e) => changeStateOrg('orgName', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Designation</Label>
                                    <Input placeholder='Exp: Mr.' disabled={loading} value={forms.OrganisationInfo.orgUserDesignation} onChange={(e) => changeStateOrg('orgUserDesignation', e.target.value)} />
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Address</Label>
                                    <Input placeholder='Exp: 4-12 Jalan Pembangunan' disabled={loading} value={forms.OrganisationInfo.orgAddress} onChange={(e) => changeStateOrg('orgAddress', e.target.value)} />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>City</Label>
                                    <Input placeholder='Exp: Shah Alam' disabled={loading} value={forms.OrganisationInfo.orgAddressCity} onChange={(e) => changeStateOrg('orgAddressCity', e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>State</Label>
                                    <Input placeholder='Exp: Selangor' disabled={loading} value={forms.OrganisationInfo.orgAddressState} onChange={(e) => changeStateOrg('orgAddressState', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Postcode</Label>
                                    <Input type='number' placeholder='Exp: 409120' disabled={loading} value={forms.OrganisationInfo.orgAddressPostcode} onChange={(e) => changeStateOrg('orgAddressPostcode', e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Country</Label>
                                    <Input placeholder='Exp: Malaysia' disabled={loading} value={forms.OrganisationInfo.orgAddressCountry} onChange={(e) => changeStateOrg('orgAddressCountry', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Registration No.</Label>
                                    <Input placeholder='Exp: A-1239213' disabled={loading} value={forms.OrganisationInfo.orgRegistrationNo} onChange={(e) => changeStateOrg('orgRegistrationNo', e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Phone No.</Label>
                                    <Input type='number' placeholder='Exp: 033320123129' disabled={loading} value={forms.OrganisationInfo.orgPhoneNo} onChange={(e) => changeStateOrg('orgPhoneNo', e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label><span className='text-danger mr-1'>*</span>Fax No.</Label>
                                    <Input type='number' placeholder='Exp: 033320123129' disabled={loading} value={forms.OrganisationInfo.orgFaxNo} onChange={(e) => changeStateOrg('orgFaxNo', e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <h4 className='mb-3'>Upload Documents</h4>
                        <Row className='mb-3'>
                            <Col lg={6}>
                                <FormGroup>
                                    <Label className='mb-0'><span className='text-danger mr-1'>*</span>Malaysian Medical Counsel Certificate (MMC)</Label>
                                    <FormText className='mb-3 mt-0'>Please upload a file in the PDF format with a maximum size of 1Mb.</FormText>
                                    <br />
                                    {!uploadImg1 ?
                                        <div>
                                            <Input type='file' id='file-degree' onChange={handleAcceptedFiles1} className="d-none" />
                                            <Button color='light' onClick={() => {
                                                const el = document.getElementById('file-degree');
                                                el.click()
                                            }}><i className="ri-file-upload-line aling-middle mr-2"></i>Upload</Button>
                                        </div> :
                                        <div className='position-relative' style={{ width: 369 }}>
                                            <div className='bg-light border rounded py-1 px-2 d-flex justify-content-between align-items-center'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><i className="ri-file-pdf-line mr-2 ri-2x align-middle"></i></div>
                                                    <div className='w-100'><p className='mb-0 text-truncate'>{uploadImg1.name}</p>
                                                        <p className='mb-0 small text-muted'>{uploadImg1.size}</p></div>
                                                </div>
                                                {!forms.DegreeCert ? null : <Button color='white' size='sm' disabled={loading} className='' onClick={() => {
                                                    setUploadImg1(null);
                                                    setForms(prev => {
                                                        return {
                                                            ...prev,
                                                            DegreeCert: null
                                                        }
                                                    })
                                                }}>Replace</Button>}
                                            </div>
                                        </div>}
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <Label className='mb-0'>{forms.IDType && forms.IDType.value === 'P' ? <span className='text-danger mr-1'>*</span> : null}Passport</Label>
                                <FormText className='mb-3 mt-0'>Please upload a file in JPG, JPEG, or PNG format with a maximum size of 1 MB.</FormText>
                                {!uploadImg2 ?
                                    <div>
                                        <Input type='file' id='file-passport' onChange={handleAcceptedFiles2} className="d-none" />
                                        <Button color='light' onClick={() => {
                                            const el = document.getElementById('file-passport');
                                            el.click()
                                        }}><i className="ri-file-upload-line aling-middle mr-2"></i>Upload</Button>
                                    </div> : <div className='border rounded overflow-hidden'>
                                        <div className='position-relative' style={{ width: 369 }}>
                                            <img src={uploadImg2.img} alt="passport" className='w-100' />
                                        </div>
                                        <div className='bg-light border-top py-1 px-2 d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center w-75 pr-2'>
                                                <div><i className="ri-file-pdf-line mr-2 ri-2x align-middle"></i></div>
                                                <div className='w-100'><p className='mb-0 text-truncate'>{uploadImg2.name}</p>
                                                    <p className='mb-0 small text-muted'>{uploadImg2.size}</p></div>
                                            </div>
                                            {!forms.PassportImage ? null : <Button color='white' size='sm' disabled={loading} className='' onClick={() => {
                                                setUploadImg2(null);
                                                setForms(prev => {
                                                    return {
                                                        ...prev,
                                                        PassportImage: null
                                                    }
                                                })
                                            }}>Replace</Button>}
                                        </div>
                                    </div>}
                            </Col>
                        </Row>
                        <Row className='mb-5'>
                            <Col>
                                <Label className='mb-0'>{forms.IDType && forms.IDType.value !== 'N' ? null : <span className='text-danger mr-1'>*</span>}NRIC Front</Label>
                                <FormText className='mb-3 mt-0'>Please upload a file in JPG, JPEG, or PNG format with a maximum size of 1 MB.</FormText>
                                {!uploadImg3 ?
                                    <div>
                                        <Input type='file' id='file-nric-front' onChange={handleAcceptedFiles3} className="d-none" />
                                        <Button color='light' onClick={() => {
                                            const el = document.getElementById('file-nric-front');
                                            el.click()
                                        }}><i className="ri-file-upload-line aling-middle mr-2"></i>Upload</Button>
                                    </div> : <div className='border rounded overflow-hidden'>
                                        <div className='position-relative' style={{ width: 369 }}>
                                            <img src={uploadImg3.img} alt="passport" className='w-100' />
                                        </div>
                                        <div className='bg-light border-top py-1 px-2 d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center w-75 pr-2'>
                                                <div><i className="ri-file-pdf-line mr-2 ri-2x align-middle"></i></div>
                                                <div className='w-100'><p className='mb-0 text-truncate'>{uploadImg3.name}</p>
                                                    <p className='mb-0 small text-muted'>{uploadImg3.size}</p></div>
                                            </div>
                                            {!forms.NRICFront ? null : <Button color='white' size='sm' disabled={loading} className='' onClick={() => {
                                                setUploadImg3(null);
                                                setForms(prev => {
                                                    return {
                                                        ...prev,
                                                        NRICFront: null
                                                    }
                                                })
                                            }}>Replace</Button>}
                                        </div>
                                    </div>}
                            </Col>
                            <Col>
                                <Label className='mb-0'>{forms.IDType && forms.IDType.value !== 'N' ? null : <span className='text-danger mr-1'>*</span>}NRIC Back</Label>
                                <FormText className='mb-3 mt-0'>Please upload a file in JPG, JPEG, or PNG format with a maximum size of 1 MB.</FormText>
                                {!uploadImg4 ?
                                    <div>
                                        <Input type='file' id='file-nric-front' onChange={handleAcceptedFiles4} className="d-none" />
                                        <Button color='light' onClick={() => {
                                            const el = document.getElementById('file-nric-front');
                                            el.click()
                                        }}><i className="ri-file-upload-line aling-middle mr-2"></i>Upload</Button>
                                    </div> : <div className='border rounded overflow-hidden'>
                                        <div className='position-relative' style={{ width: 369 }}>
                                            <img src={uploadImg4.img} alt="passport" className='w-100' />
                                        </div>
                                        <div className='bg-light border-top py-1 px-2 d-flex justify-content-between align-items-center'>
                                            <div className='d-flex justify-content-between align-items-center w-75 pr-2'>
                                                <div><i className="ri-file-pdf-line mr-2 ri-2x align-middle"></i></div>
                                                <div className='w-100'><p className='mb-0 text-truncate'>{uploadImg4.name}</p>
                                                    <p className='mb-0 small text-muted'>{uploadImg4.size}</p></div>
                                            </div>
                                            {!forms.NRICBack ? null : <Button color='white' size='sm' disabled={loading} className='' onClick={() => {
                                                setUploadImg4(null);
                                                setForms(prev => {
                                                    return {
                                                        ...prev,
                                                        NRICBack: null
                                                    }
                                                })
                                            }}>Replace</Button>}
                                        </div>
                                    </div>}
                            </Col>
                        </Row>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Button color='light' className='mr-2 w-100' disabled={loading} onClick={() => {
                            isClosed()
                            // setShowCancel(false)
                            setErrMsg('')
                            setForms(initialPayload)
                            setUploadImg1(null)
                            setUploadImg2(null)
                            setUploadImg3(null)
                            setUploadImg4(null)
                        }}>Cancel</Button>
                        <Button color='primary' className='w-100' onClick={() => handleSubmit()}>{loading ? 'Loading...' : 'Submit'}</Button>
                    </div>
                </Form> : <div className='position-relative' >
                    {!showSuccess && !errMsg && loading ? <div>
                        <div style={{ width: 200 }} className="m-auto"><img src={imgLoading} alt="img" className="m-auto w-100" /></div>
                        <div className='py-3'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Spinner color='primary' className='mr-2' />
                                <h3 className='mb-0'>Loading...</h3>
                            </div>
                            <div className='text-center mt-3'><p>Please wait while we verify your request. <br /> This process may take a few moments, and we appreciate your patience. If you have any questions or concerns, feel free to reach out to our support team.</p></div>
                        </div>
                    </div>
                        : null}
                    {activationLink && !errMsg && !loading && !showSuccess ? <div>
                        <div style={{ width: 200 }} className="m-auto"><img src={imgVerification} alt="img" className="m-auto w-100" /></div>
                        <div className='py-3'>
                            <h3 className='text-center text-weight-bold'>Verification Request</h3>
                            <p className='text-center mb-0'>Verification for new client digital ID for Selgate Healthcare Sdn Bhd</p>
                            <p className='text-center'>Please click the button below to verify your certificate</p>
                            <Button color='light' className='mt-3 w-100' onClick={() => { window.open(activationLink) }}>Verification Link</Button>
                        </div>
                    </div> : null}
                    {showSuccess && !errMsg && !loading && !activationLink ? <div>
                        <div style={{ width: 200 }} className="m-auto"><img src={imgSuccess} alt="img" className="m-auto w-100" /></div>
                        <div className='py-3'>
                            <div className=''>
                                <h3 className='mb-0 text-center text-weight-bold'>Congratulations!</h3>
                                <p className='text-center'>Your registration is now complete. You have successfully obtained your certificate</p>
                                <Button color='light' className='mt-3 w-100' onClick={() => {
                                    clearInterval(myinterval);
                                    isClosed()
                                    useLocalStorage.setItem('certDetails', certData)
                                    useLocalStorage.setItem('certInfo', true)
                                    setGlobalData({
                                        type: ACTION_TYPE.GET_USER_DATA,
                                        payload: {
                                            user: globalData.global.user,
                                            token: globalData.token,
                                            role: globalData.global.role,
                                            image: globalData.global.image,
                                            availability_doctor: globalData.global.availability_doctor,
                                            certInfo: certData,
                                            signature: null
                                        }
                                    })
                                    fetchingUserData()
                                }}>Close</Button>
                            </div>
                        </div>
                    </div>
                        : null}

                    {errMsg ? <div>
                        <div style={{ width: 200 }} className="m-auto"><img src={imgError} alt="img" className="m-auto w-100" /></div>
                        <div>
                            <h3 className='text-center text-weight-bold text-danger'>Error!</h3>
                            <p className='text-center'>{errMsg}</p>
                            <Button color='light' className='w-100' onClick={() => {
                                setErrMsg('');
                            }}>Close</Button>
                        </div>
                    </div> : null}
                </div>
                }
            </ModalBody>

            <AlertError setErrMsg={setShowErr} errMsg={showErr} title="Error!" />
        </Modal>
    )
}

export default ModalRegisterCert