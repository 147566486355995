import React from 'react'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'

const ModalUpdatePrescription = ({ loading, visible, isClosed, onSubmit }) => {
    return (
        <Modal centered isOpen={visible} toggle={() => isClosed()}>
            <ModalBody>
                <div className='text-center'>
                    <i className='ri-question-line ri-3x' />
                    <h4>Are you sure?</h4>

                    <p>Do you want to save the changes?</p>

                    <Button className='mr-2' color='light' onClick={isClosed}>Cancel</Button>
                    <Button color='primary' onClick={onSubmit}>{loading && <Spinner color='light' size={"sm"} className="align-middle mr-2" />}Confirm</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalUpdatePrescription