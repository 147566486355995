import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { RESET_USER } from './actionTypes';
import { resetUserSuccessful, userResetPasswordError } from './actions';

// AUTH related methods
import { postResetPwd } from '../../../helpers/API';

function* resetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postResetPwd, '/auth/reset-password', { ...user });
    if (response) {

      yield put(
        resetUserSuccessful(
          "Your password was changed"
        )
      );
      history.push('/login');
    }
  } catch (error) {
    if (error.response.status === 400) {
      if (error.response.data.message) {
        var message = error.response.data.message[0].messages[0].message
        if (message) {
          yield put(userResetPasswordError(message));
        }
      }
    }
  }
}

export function* watchUserReset() {
  yield takeEvery(RESET_USER, resetUser)
}

function* forgetSaga() {
  yield all([fork(watchUserReset)]);
}

export default forgetSaga;