import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { Button, CardBody, Col, FormGroup, Input, Label } from 'reactstrap'
import { TEXT_ALLERGIES, TEXT_ALTERNATE_PHONE_NO, TEXT_BACK, TEXT_CANCEL, TEXT_CITY, TEXT_EMAIL, TEXT_FRONT, TEXT_GENDER, TEXT_HEIGHT, TEXT_NAME, TEXT_NRIC, TEXT_PASSPORT, TEXT_PAST_MEDICAL_HISTORY, TEXT_PAST_SURGICAL_HISTORY, TEXT_PATIENT_DIAGNOSIS, TEXT_PHONE_NO, TEXT_POSTCODE, TEXT_RACE, TEXT_RELIGION, TEXT_SAVE, TEXT_STATE, TEXT_STREET_ONE, TEXT_STREET_TWO, TEXT_WEIGHT } from '../../../../../../Text'
import { optionGender, optionRace, optionReligion, OptionHonorific } from '../../Store/Option'
import { capitalizeFirstLetter } from '../../../../../../store/CustomFunctions'
import { ACTION_TYPE } from '../../Store/ActionType';
import { update } from '../../../../../../helpers/API';

const Patient = ({ formValues, handleModal, isEditMode, setFormValues, dataSource, dispatch, setSweetTimmer, sweetTimmer, setErrMsg, errMsg }) => {
    const [toggleSwithcNric, setToggleSwitchNric] = useState(true)
    const [toggleSwitchAllergies, setToggleSwitchAllergies] = useState(true)
    const [toggleSwitchPSH, setToggleSwitchPSH] = useState(true)
    const [toggleSwitchPMH, setToggleSwitchPMH] = useState(true)
    const [temporary, setTemporary] = useState(formValues)

    useEffect(() => {
        if (temporary && temporary.patient_info) {
            setToggleSwitchNric(temporary.patient_info.id_card_type === 'nric' ? true : false)
            setToggleSwitchAllergies(temporary.patient_info.has_allergies ? true : false)
            setToggleSwitchPSH(temporary.patient_info.has_past_surgical_history ? true : false)
            setToggleSwitchPMH(temporary.patient_info.has_past_medical_history ? true : false)
        }
    }, [temporary, temporary.patient_info])

    const handleUpdateForm = (propertyName, childPropertyName, values) => {
        setTemporary(prev => {
            let data = {}
            if (childPropertyName) {
                data = {
                    ...prev,
                    patient_info: {
                        ...prev.patient_info,
                        [propertyName]: {
                            ...prev.patient_info[propertyName],
                            [childPropertyName]: values,
                        }
                    }
                }
            } else {
                data = {
                    ...prev,
                    patient_info: {
                        ...prev.patient_info,
                        [propertyName]: values,
                    }
                }
            }
            return data
        })
    }

    const handleSubmit = () => {
        let data = {
            booking_status: temporary.patient_info.patient_diagnosis ? 'pending' : temporary.booking_status,
            patient_info: {
                ...temporary.patient_info,
                allergies: toggleSwitchAllergies ? temporary.patient_info.allergies : ""
            }
        }
        update(`/booking-forms/${formValues.id}`, data).then(res => {
            setTemporary(res)
            setFormValues(res)
            dispatch({
                type: ACTION_TYPE.ACTION_EDIT_MODE,
                payload: {
                    tab: null,
                    mode: !dataSource.editMode.mode
                }
            })
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: res,
                    loading: false
                }
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(err => {
            setErrMsg({
                visible: !errMsg.visible,
                msg: err
            })
        })
    }

    return (
        <div>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">Title</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.honorific ? "-" : capitalizeFirstLetter(temporary.patient_info.honorific)}</p> :
                        <Select
                            options={OptionHonorific}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Mr'
                            value={!temporary.patient_info.honorific ? "" : { label: capitalizeFirstLetter(temporary.patient_info.honorific), value: temporary.patient_info.honorific }}
                            onChange={e => handleUpdateForm('honorific', '', e.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_NAME}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.name ? "-" : capitalizeFirstLetter(temporary.patient_info.name)}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="e.g: John"
                            value={!temporary.patient_info ? "" : temporary.patient_info.name}
                            onChange={e => handleUpdateForm('name', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">Identification No.</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.id_card_number ? "-" : temporary.patient_info.id_card_number}</p> :
                        <div className="d-flex flex-row align-items-center">
                            <div className="custom-control custom-switch mr-4" dir="ltr">
                                <Input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked={toggleSwithcNric} onChange={(e) => handleUpdateForm('id_card_type', '', e.target.checked ? 'nric' : 'passport')} />
                                <Label className="custom-control-label" htmlFor="customSwitch1">{toggleSwithcNric ? TEXT_NRIC : TEXT_PASSPORT}</Label>
                            </div>

                            <div className="mr-2">
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="e.g: 891212103919"
                                    value={!temporary.patient_info.id_card_number ? "" : temporary.patient_info.id_card_number}
                                    onChange={e => handleUpdateForm('id_card_number', '', e.target.value, '')}
                                />
                            </div>
                            <div>
                                {!temporary.patient_info.id_card_front_file ? null : <Button color="light" className='mr-2' disabled={!temporary.patient_info.id_card_front_file} onClick={() => handleModal(temporary.patient_info.id_card_front_file)}><i className="ri-eye-fill mr-2 align-middle" />{TEXT_FRONT}</Button>}
                            </div>
                            <div>
                                {!temporary.patient_info.id_card_back_file ? null : <Button color="light" disabled={!temporary.patient_info.id_card_back_file} onClick={() => handleModal(temporary.patient_info.id_card_back_file)}><i className="ri-eye-fill mr-2 align-middle" />{TEXT_BACK}</Button>}
                            </div>
                            {/* <div>
                            <Button className="mr-2" color="light">
                                <input type="file" onChange={(e) => handleUploadImg(e.target.files, TEXT_FRONT)} className="input-hide" />
                                <i className=" ri-attachment-2 mr-2 align-middle" />
                                {TEXT_FRONT}
                            </Button>
                            <Button color="light">
                                <input type="file" onChange={(e) => handleUploadImg(e.target.files, TEXT_BACK)} className="input-hide" />
                                <i className=" ri-attachment-2 mr-2 align-middle" />
                                {TEXT_BACK}
                            </Button>
                        </div> */}
                        </div>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_GENDER}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.gender ? "-" : capitalizeFirstLetter(temporary.patient_info.gender)}</p> :
                        <Select
                            options={optionGender}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Male'
                            value={!temporary.patient_info.gender ? "" : { label: capitalizeFirstLetter(temporary.patient_info.gender), value: temporary.patient_info.gender }}
                            onChange={e => handleUpdateForm('gender', '', e.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_RACE}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.race ? "-" : capitalizeFirstLetter(temporary.patient_info.race)}</p> :
                        <Select
                            options={optionRace}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Malay'
                            value={!temporary.patient_info.race ? "" : { label: capitalizeFirstLetter(temporary.patient_info.race), value: temporary.patient_info.race }}
                            onChange={e => handleUpdateForm('race', '', e.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_RELIGION}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.religion ? "-" : capitalizeFirstLetter(temporary.patient_info.religion)}</p> :
                        <Select
                            options={optionReligion}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Muslim'
                            value={!temporary.patient_info.religion ? "" : { label: capitalizeFirstLetter(temporary.patient_info.religion), value: temporary.patient_info.religion }}
                            onChange={e => handleUpdateForm('religion', '', e.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_PHONE_NO}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.phone ? "-" : temporary.patient_info.phone}</p> :
                        <Input
                            className="form-control"
                            type="tel"
                            placeholder="e.g: 0133101212"
                            value={!temporary.patient_info.phone ? null : temporary.patient_info.phone}
                            onChange={e => handleUpdateForm('phone', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_ALTERNATE_PHONE_NO}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.alt_phone ? "-" : temporary.patient_info.alt_phone}</p> :
                        <Input
                            className="form-control"
                            type="tel"
                            placeholder="e.g: 0133101212"
                            value={!temporary.patient_info.alt_phone ? null : temporary.patient_info.alt_phone}
                            onChange={e => handleUpdateForm('alt_phone', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_EMAIL}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.email ? "-" : temporary.patient_info.email}</p> :
                        <Input
                            className="form-control"
                            type="email"
                            placeholder="e.g: john@example.com"
                            value={!temporary.patient_info.email ? "" : temporary.patient_info.email}
                            onChange={e => handleUpdateForm('email', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_STREET_ONE}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.address.address1 ? "-" : temporary.patient_info.address.address1}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Street name 1"
                            value={!temporary.patient_info.address.address1 ? "" : temporary.patient_info.address.address1}
                            onChange={e => handleUpdateForm('address', 'address1', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_STREET_TWO}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.address.address2 ? "-" : temporary.patient_info.address.address2}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Street name 2"
                            value={!temporary.patient_info.address.address2 ? "" : temporary.patient_info.address.address2}
                            onChange={e => handleUpdateForm('address', 'address2', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_STATE}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.address.province ? "-" : temporary.patient_info.address.province}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="State"
                            value={!temporary.patient_info.address.province ? "" : temporary.patient_info.address.province}
                            onChange={e => handleUpdateForm('address', 'province', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_CITY}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.address.city ? "-" : temporary.patient_info.address.city}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="City"
                            value={!temporary.patient_info.address.city ? "" : temporary.patient_info.address.city}
                            onChange={e => handleUpdateForm('address', 'city', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_POSTCODE}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.address.zipcode ? "-" : temporary.patient_info.address.zipcode}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Postcode"
                            value={!temporary.patient_info.address.zipcode ? "" : temporary.patient_info.address.zipcode}
                            onChange={e => handleUpdateForm('address', 'zipcode', e.target.value)}
                        />}
                </Col>
            </FormGroup>

            <div className="border-top mb-3"></div>

            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_PATIENT_DIAGNOSIS}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.patient_diagnosis ? "-" : temporary.patient_info.patient_diagnosis}</p> :
                        <textarea
                            className="form-control"
                            type="text"
                            placeholder="Describe your condition"
                            value={!temporary.patient_info.patient_diagnosis ? "" : temporary.patient_info.patient_diagnosis}
                            onChange={e => handleUpdateForm('patient_diagnosis', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_HEIGHT}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.height ? "-" : temporary.patient_info.height + "cm"}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="in centimeter"
                            value={!temporary.patient_info.height ? "" : temporary.patient_info.height}
                            onChange={e => handleUpdateForm('height', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="validateWeight" className="col-md-3 col-form-label">{TEXT_WEIGHT}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'Patient' ?
                        <p className="col-form-label text-mute">{!temporary.patient_info.weight ? "-" : temporary.patient_info.weight + "kg"}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="in kilogram"
                            value={!temporary.patient_info.weight ? "" : temporary.patient_info.weight}
                            onChange={e => handleUpdateForm('weight', '', e.target.value)}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_ALLERGIES}</Label>
                <Col lg={9}>
                    <div className="custom-control custom-switch mb-2" dir="ltr">
                        <Input type="checkbox" className="custom-control-input" disabled={!isEditMode.mode} id="customSwitch2" checked={toggleSwitchAllergies} onChange={(e) => { handleUpdateForm('has_allergies', '', !e.target.checked) }} />
                        <Label className="custom-control-label" disabled={!isEditMode.mode} htmlFor="customSwitch2" onClick={(e) => { if (isEditMode.mode) setToggleSwitchAllergies(!toggleSwitchAllergies) }}>{toggleSwitchAllergies ? "Yes" : "No"}</Label>
                    </div>
                    {isEditMode.tab !== 'Patient' ?
                        temporary.patient_info ? <p className="col-form-label text-mute">{temporary.patient_info.allergies}</p> : null :
                        toggleSwitchAllergies && <div>
                            <textarea
                                className="form-control"
                                type="text"
                                placeholder="Describe your condition"
                                value={temporary.patient_info ? temporary.patient_info.allergies : ""}
                                onChange={e => handleUpdateForm('allergies', '', e.target.value)}
                            />
                        </div>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_PAST_MEDICAL_HISTORY}</Label>
                <Col lg={9}>
                    <div className="custom-control custom-switch mb-2" dir="ltr">
                        <Input type="checkbox" disabled={!isEditMode.mode} className="custom-control-input" checked={toggleSwitchPMH} onChange={(e) => { handleUpdateForm('has_past_surgical_history', '', !e.target.checked) }} />
                        <Label className="custom-control-label" disabled={!isEditMode.mode} onClick={(e) => { if (isEditMode.mode) setToggleSwitchPMH(!toggleSwitchPMH) }}>{toggleSwitchPMH ? "Yes" : "No"}</Label>
                    </div>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_PAST_SURGICAL_HISTORY}</Label>
                <Col lg={9}>
                    <div className="custom-control custom-switch mb-2" dir="ltr">
                        <Input type="checkbox" disabled={!isEditMode.mode} className="custom-control-input" checked={toggleSwitchPSH} onChange={(e) => { handleUpdateForm('has_past_medical_history', '', !e.target.checked) }} />
                        <Label className="custom-control-label" disabled={!isEditMode.mode} onClick={(e) => { if (isEditMode.mode) setToggleSwitchPSH(!toggleSwitchPSH) }}>{toggleSwitchPSH ? "Yes" : "No"}</Label>
                    </div>
                </Col>
            </FormGroup>


            {isEditMode.tab === 'Patient' &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" onClick={handleSubmit} color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            setTemporary(formValues)
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>}
        </div>
    )
}

export default Patient
