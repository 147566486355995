import React, { useEffect, useMemo, useState } from 'react'
import { Button, CardBody } from 'reactstrap'
import { TEXT_EDIT } from '../../../../../Text'
import { ACTION_TYPE } from '../Store/ActionType'
// import { converPriceWithCommas } from '../../../../../store/CustomFunctions';
// import { handleGenerateInvoice } from '../Store/generateQuotation';

const ButtonActionSchedule = ({ activeTab, dataSource, handleDownloadQuataion, customScheduleSetting, customScheduleRate }) => {
    // const [total, setTotal] = useState(0)
    const [invoiceNumber, setInvoiceNumber] = useState(null)
    useEffect(() => {
        if (customScheduleSetting && customScheduleRate && dataSource.formData) {
            let invoice = null
            if (dataSource.formData.invoices.length > 0) {
                invoice = dataSource.formData.invoices[dataSource.formData.invoices.length - 1].invoice_number
                setInvoiceNumber(invoice)
            }

            // let data = handleGenerateInvoice(dataSource.formData, customScheduleRate, customScheduleSetting, invoice);
            // if (data.total > 0) setTotal(data.total);
        }
    }, [customScheduleRate, customScheduleSetting, dataSource.formData])
    return activeTab === 'Schedule' && <div className='d-flex align-items-center justify-content-between w-100'>
        {/* <div className='d-flex align-items-center justify-content-between'><h5 className='text-primary fw-light m-0'>TOTAL AMOUNT:</h5><h5 className='text-primary m-0 ml-2' style={{ fontWeight: 'bold' }}>RM {converPriceWithCommas(total)}</h5></div> */}
        <Button disabled={dataSource.formData.invoices.length === 0} className="align-middle mr-2" color="light" onClick={() => handleDownloadQuataion(invoiceNumber)}><i className="ri-download-line align-middle mr-1" />Download Quotation</Button>
    </div>
}

const EditButton = ({ dataSource, activeTab, isEditMode, setEditMode, dispatch, activeChildTab, handleDownloadQuataion, customScheduleSetting, customScheduleRate }) => {
    const [showButton, setShowButton] = useState(false);

    const handleShowButton = useMemo(() => {
        let arr = []
        dataSource.formData.invoices.filter(x => {
            if (x.invoice_number.includes('SNSB')) {
                arr.push(x);
            }
            return x
        })

        let visibleButton = false;
        if (dataSource.category === 'confinement') {
            visibleButton = false
        } else {
            if (dataSource.formData.schedule_mode === 'default') {
                if ((dataSource.formData.booking_status === 'validation_error') && (dataSource.formData.financial_status === 'pending') && (activeTab !== 'Provider') && (activeTab !== 'Billing') && (activeTab !== 'Feedback') && (activeTab !== "Schedule")) {
                    visibleButton = true
                }

                if ((dataSource.formData.booking_status === 'pending') && (dataSource.formData.financial_status === 'pending') && (dataSource.formData.invoices && dataSource.formData.invoices.length === 0) && (activeTab !== 'Provider') && (activeTab !== 'Billing') && (activeTab !== 'Feedback') && (activeTab !== "Schedule")) {
                    visibleButton = true
                }

                if ((dataSource.formData.booking_status === 'pending') && (dataSource.formData.financial_status === 'completed') && (activeTab !== 'Billing') && (activeTab !== 'Feedback') && (activeTab !== "Assessment") && (activeTab !== "Schedule") && (activeTab !== "Schedule")) {
                    visibleButton = true
                }

                if ((dataSource.formData.booking_status === 'completed') && (dataSource.formData.financial_status === 'completed') && (activeTab !== 'Provider') && (activeTab !== 'Billing') && (activeTab !== 'Feedback') && (activeTab !== "Assessment") && (activeTab !== "Schedule") && (activeTab !== 'Feedback') && (activeTab !== "Schedule")) {
                    visibleButton = true
                }
            } else {
                if ((dataSource.formData.booking_status === 'validation_error') && (dataSource.formData.financial_status === 'pending') && (activeTab !== 'Provider') && (activeTab !== 'Billing') && (activeTab !== 'Feedback')) {
                    visibleButton = true
                }

                if ((dataSource.formData.booking_status === 'pending') && (dataSource.formData.financial_status === 'pending') && (dataSource.formData.invoices && arr.length === 0) && (activeTab !== 'Provider') && (activeTab !== 'Billing') && (activeTab !== 'Feedback')) {
                    visibleButton = true
                }

                if ((dataSource.formData.booking_status === 'pending') && (dataSource.formData.financial_status === 'completed') && (activeTab !== 'Billing') && (activeTab !== 'Feedback') && (activeTab !== "Assessment") && (activeTab !== "Schedule")) {
                    visibleButton = true
                }

                if ((dataSource.formData.booking_status === 'completed') && (dataSource.formData.financial_status === 'completed') && (activeTab !== 'Provider') && (activeTab !== 'Billing') && (activeTab !== 'Feedback') && (activeTab !== "Assessment") && (activeTab !== "Schedule") && (activeTab !== 'Feedback')) {
                    visibleButton = true
                }
            }
        }
        return { disabled: visibleButton }
    }, [dataSource.formData, activeTab, dataSource.category])

    useEffect(() => {
        setShowButton((handleShowButton.disabled) ? true : false)
    }, [handleShowButton])


    return dataSource.editMode.mode === false && showButton &&
        <CardBody className={activeTab === 'Schedule' ? 'border-bottom pr-0 d-flex position-relative justify-content-end align-items-center w-100' : 'border-top pr-0 d-flex position-relative justify-content-end align-items-center w-100'}>
            <ButtonActionSchedule activeTab={activeTab} dataSource={dataSource} handleDownloadQuataion={handleDownloadQuataion} customScheduleSetting={customScheduleSetting} customScheduleRate={customScheduleRate} />
            <Button
                disabled={(activeTab === 'Provider' && dataSource.formData.booking_status === 'validation_error')}
                color='light'
                onClick={() => {
                    setEditMode(!isEditMode)
                    dispatch({
                        type: ACTION_TYPE.ACTION_EDIT_MODE,
                        payload: {
                            tab: activeTab,
                            childeTab: activeChildTab,
                            mode: !dataSource.editMode.mode
                        }
                    })
                }}>{activeTab !== 'Provider' ? TEXT_EDIT : dataSource.formData.provider_booking_jobs.filter(x => x.type_of_job === 'assigned').length === 0 ? 'Start Assigning' : 'Assigned'}</Button>
        </CardBody>
}



export default EditButton
