import React, { useEffect, useState } from 'react'
import { Card, CardBody, Media } from 'reactstrap'
import moment from "moment"
import { capitalizeFirstLetter } from '../../../../../../store/CustomFunctions'
import '../../../../../Templete/Tables/Styles/customStyle.scss'

const scrollstyle = {
    maxHeight: "calc(100vh - 566px)",
    overflow: "auto"
}
const Notes = (props) => {
    const [arr, setArr] = useState([])
    useEffect(() => {
        const copy = props.formValues.booking_note.chat_note;
        copy.sort((a, b) => {
            var dateA = new Date(a.datetime).getTime();
            var dateB = new Date(b.datetime).getTime();
            return dateA < dateB ? 1 : -1;
        });
        setArr(copy);
    }, [props.formValues.booking_note.chat_note])
    return (
        arr && arr.length === 0 ? <div>
            <div className='empty-note'>
                <p> Note is empty</p>
            </div>
        </div> :
            <div style={scrollstyle}>
                {arr && arr.map((item, index) => {
                    return (
                        <div key={index}>
                            <Media className="mb-4">
                                <div className='custom-card-body rounded-circle mr-3' style={{ height: 40, width: 40 }}>{item.user === null || item.user === undefined ? "-" : capitalizeFirstLetter(item.user.username[0])}</div>
                                <Media body>
                                    <h5 className="mt-0 font-size-14">{item.user === null || item.user === undefined ? "-" : capitalizeFirstLetter(item.user.username)}</h5>
                                    <Card className="mb-0" style={{ backgroundColor: "#eff2f7", color: "#74788d" }}>
                                        <CardBody style={{ padding: "12px 16px", opacity: (arr.length - 1) === index ? arr.length > 1 ? 0.6 : 1 : 1 }}>
                                            <h5 className="mt-0 font-size-14">{!item.title ? "-" : item.title}</h5>{!item.notes ? "-" : item.notes}
                                        </CardBody>
                                    </Card>
                                    <p className='text-muted mt-2 font-size-11 text-right'><i className="ri-time-line mr-1 align-middle" />{moment(item.datetime).format('LLLL')}</p>
                                </Media>
                            </Media>
                        </div>
                    )
                })}
            </div>
    )
}

export default Notes
