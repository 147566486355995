import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import saveAs from "file-saver";
import { converPriceWithCommas } from '../../../store/CustomFunctions';


export const exportTableToExcel = (array, nameFile) => {

    let newData = array;
    let data = {};
    let groupOfData = []
    let ExcelJSWorkbook = new ExcelJS.Workbook();
    let worksheet = ExcelJSWorkbook.addWorksheet("ExcelJS sheet");
    let columns = ["Order No", "Name", "Email", "Phone Number", "Date", "Item", "Quantity", "Price", "Total Price (Excluding Tax)", "Tax", "Category", "Status", "Payment Status"];

    worksheet.mergeCells("A2:I2");

    let headerRow = worksheet.addRow();
    worksheet.getRow(4).font = { bold: true };

    for (let i = 0; i < columns.length; i++) {
        let currentColumnWidth = 100
        worksheet.getColumn(i + 1).width = currentColumnWidth / 6;
        let cell = headerRow.getCell(i + 1);
        cell.value = columns[i];
    }

    for (let i = 0; i < newData.length; i++) {
        let orderNumber = newData[i].orderNumber;
        let orderName = newData[i].orderName;
        let orderPhoneNumber = newData[i].orderPhoneNumber;
        let orderDateTime = newData[i].orderDateTime;
        let orderPaymentStatus = newData[i].orderPaymentStatus;
        let orderEmail = newData[i].orderEmail;
        let orderTax = newData[i].orderTax;
        let orderStatus = newData[i].orderStatus;

        if (newData[i].orderItems && newData[i].orderItems.length > 0) {
            for (let j = 0; j < newData[i].orderItems.length; j++) {
                let orderItemName = !newData[i].orderItems[j].title ? "-" : newData[i].orderItems[j].title;
                let orderQuantity = !newData[i].orderItems[j].quantity ? 0 : newData[i].orderItems[j].quantity;
                let orderPrice = !newData[i].orderItems[j].price ? 0 : newData[i].orderItems[j].price;
                let orderTotalPrice = parseFloat(converPriceWithCommas((!newData[i].orderItems[j].price ? 0 : newData[i].orderItems[j].price) * (!newData[i].orderItems[j].quantity ? 0 : newData[i].orderItems[j].quantity)));
                let categories = newData[i].orderItems[j].categories;

                data = {
                    rowType: j > 0 ? "same" : "new",
                    values: [j > 0 ? "" : orderNumber, j > 0 ? "" : orderName, j > 0 ? "" : orderEmail, j > 0 ? "" : orderPhoneNumber, j > 0 ? "" : orderDateTime, orderItemName, orderQuantity, orderPrice, orderTotalPrice, orderTax, categories, orderStatus, orderPaymentStatus]
                }
                groupOfData.push(data)
            }
        }
    }

    for (let i = 0; i < groupOfData.length; i++) {
        let dataRow = worksheet.addRow();
        if (groupOfData[i].rowType === "new" && i > 0) {
            dataRow.outlineLevel = 1;
        }
        for (let j = 0; j < groupOfData[i].values.length; j++) {
            let cell = dataRow.getCell(j + 1);
            cell.value = groupOfData[i].values[j];
        }
    }

    ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${nameFile}.xlsx`
        );
    });

}