import React, { useState } from 'react'
import { Col, FormGroup, Label, Input, CardBody, Button } from 'reactstrap'
import { ACTION_TYPE } from '../../../Store/ActionType';
import { TEXT_CANCEL, TEXT_SAVE } from '../../../../../../../Text';
import { update } from '../../../../../../../helpers/API';

const AssessmentTab = ({ data, isEditMode, dispatch, dataSource, setFormValues, activeTab, sweetTimmer, setSweetTimmer }) => {
    const [temporary, setTemporary] = useState(data)


    const handleUpdateForm = (value, id, property) => {
        const copyData = [...temporary.assesments]
        const index = copyData.findIndex(item => item.id === id)
        const item = copyData[index]

        let newData = {
            ...item,
            [property]: value,
            explaination: item.is_tick ? value : ""
        }

        copyData.splice(index, 1, { ...item, ...newData })
        setTemporary({
            ...data,
            assesments: copyData
        })
    }

    const handleSubmit = () => {
        update(`/booking-forms/${data.id}`,
            {
                assesments: temporary.assesments
            }).then(res => {
                setFormValues(res)
                dispatch({
                    type: ACTION_TYPE.ACTION_UPDATE_FORM,
                    payload: {
                        data: res,
                        loading: false
                    }
                })
                dispatch({
                    type: ACTION_TYPE.ACTION_EDIT_MODE,
                    payload: {
                        tab: null,
                        childeTab: null,
                        mode: !dataSource.editMode.mode
                    }
                })
            }).catch(err => { })

        setSweetTimmer(!sweetTimmer)
    }
    return (<div>

        {!temporary.assesments ? null : temporary.assesments.map((item, index) => {
            return (
                <FormGroup row key={index}>
                    <Col md={12} className="ml-auto">
                        <div className="mt-4 mt-lg-0 w-100" style={{ minWidth: 44 }}>
                            <div className="custom-control custom-checkbox custom-control-right w-100">
                                <Input type="checkbox" className="custom-control-input" disabled={isEditMode.childeTab === activeTab ? false : true} checked={!item.is_tick ? false : item.is_tick} onChange={() => false} />
                                <Label className="custom-control-label w-100" disabled={isEditMode.childeTab === activeTab ? false : true}
                                    onClick={(event) => {
                                        if (isEditMode.childeTab === activeTab) {
                                            handleUpdateForm(!item.is_tick, item.id, 'is_tick')
                                        }
                                    }}>{item.assessment.question}</Label>
                                <Label className="w-100 font-size-11 text-muted">{item.assessment.description}</Label>
                            </div>
                            {/* Does the patient have problems with speech */}
                            {!isEditMode.mode ?
                                !item.explaination ? null : <Label className="w-100 font-size-11 text-muted">Note: {item.explaination}</Label> :
                                item.assessment.question && item.assessment.question === 'Does the patient have problems with speech' ?
                                    !item.is_tick ? null :
                                        <textarea
                                            className="form-control mt-2 mb-2"
                                            type="text"
                                            rows={3}
                                            disabled={isEditMode.childeTab === activeTab ? false : true}
                                            value={item.explaination}
                                            onChange={e => handleUpdateForm(e.target.value, item.id, 'explaination')}
                                            placeholder={`Note...`} /> :
                                    null
                            }
                        </div>
                    </Col>
                </FormGroup>
            )
        })}

        {isEditMode.tab === 'Assessment' && isEditMode.childeTab === activeTab &&
            <CardBody className="border-top pr-0">
                <FormGroup className="mb-2">
                    <Button className="float-right" type="submit" onClick={handleSubmit} color='primary'>{TEXT_SAVE}</Button>
                    <Button className="float-right mr-2" color='light' onClick={() => {
                        setTemporary(data)
                        dispatch({
                            type: ACTION_TYPE.ACTION_EDIT_MODE,
                            payload: {
                                tab: null,
                                childeTab: null,
                                mode: !dataSource.editMode.mode
                            }
                        })
                    }}>{TEXT_CANCEL}</Button>
                </FormGroup>
            </CardBody>}
    </div>)
}

export default AssessmentTab
