import moment from 'moment'

export const handleGenerateInvoice = (source, scheduleObj, settingObj, invoice_number, userData) => {
    const copy_data = { ...source }
    let serviceData = {
        "title": "Required Services",
        "type": "default",
        "items": [],
        "total": 0
    }

    let medicalData = {
        "title": "Medical Supplies",
        "type": "unit_quantity",
        "items": [],
        "total": 0
    }

    let additionalData = {
        "title": "Additional Treatments",
        "type": "default",
        "items": [],
        "total": 0
    }

    const additionalMap = copy_data.additional_treatments.filter(x => x.is_tick === true).map((item, index) => {
        return {
            "name": item.treatment.treatment,
            "quantity": "",
            "unit_price": item.custom_price ? item.custom_price : item.treatment.price,
            "total": item.custom_price ? item.custom_price : item.treatment.price
        }
    })

    const serviceMap = copy_data.type_of_services.filter(x => x.is_tick === true).map((item, index) => {
        if (item.service !== undefined && item.service !== null) {
            return {
                "name": item.service.service,
                "quantity": "",
                "unit_price": item.custom_price ? item.custom_price : item.service.price,
                "total": item.custom_price ? item.custom_price : item.service.price
            }
        }
        return item
    })

    const medicalMap = copy_data.medical_supplies.map((item, index) => {
        return {
            "name": item.item.title,
            "quantity": item.quantity,
            "unit_price": item.item.variants[0].price,
            "total": item.item.variants[0].price * item.quantity
        }
    })

    let serviceTotal = 0;
    let how_many_days = {
        title: 'Total day',
        total: 0
    }
    let hours_per_day = {
        title: 'Total hours per day',
        total: 0
    }
    let rate_per_hour = {
        title: 'Total rate per day',
        total: 0
    }
    if (scheduleObj.totalHours !== null && scheduleObj.totalHours.label !== 'Custom') {
        serviceTotal = parseInt(scheduleObj.totalBills * scheduleObj.totalHours.totalHours * scheduleObj.totalDays);
        how_many_days = {
            title: 'Total day',
            total: scheduleObj.totalDays
        }

        hours_per_day = {
            title: 'Total hours per day',
            total: scheduleObj.totalHours.totalHours
        }

        rate_per_hour = {
            title: 'Total rate per hour',
            total: scheduleObj.totalHours.price
        }
    } else {
        serviceTotal = parseInt(scheduleObj.totalPerDay * scheduleObj.totalPerHours * scheduleObj.totalDays);
        how_many_days = {
            title: 'Total day',
            total: scheduleObj.totalDays
        }

        hours_per_day = {
            title: 'Total hours per day',
            total: scheduleObj.totalPerDay
        }

        rate_per_hour = {
            title: 'Total rate per hour',
            total: scheduleObj.totalPerHours
        }
    }

    const servicTotalCost = serviceMap.map(x => x.total).reduce((a, b) => a + b, 0);

    serviceData = {
        ...serviceData,
        items: serviceMap,
        total: !servicTotalCost ? serviceTotal : servicTotalCost,
        is_hide_item_total: true,
    }

    additionalData = {
        ...additionalData,
        items: additionalMap,
        total: additionalMap.map(x => x.total).reduce((a, b) => a + b, 0)
    }

    medicalData = {
        ...medicalData,
        items: medicalMap,
        total: medicalMap.map(x => x.total).reduce((a, b) => a + b, 0)
    }

    let itemsData = []
    if (serviceData.items && serviceData.items.length > 0) {
        itemsData = [
            ...itemsData,
            serviceData,
        ]
    }

    if (additionalData.items && additionalData.items.length > 0) {
        itemsData = [
            ...itemsData,
            additionalData,
        ]
    }

    if (medicalData.items && medicalData.items.length > 0) {
        itemsData = [
            ...itemsData,
            medicalData,
        ]
    }

    const countSubTotal = itemsData.map((item) => {
        if (!isNaN(item.total)) {
            return item.total
        }
        return 0
    }).reduce((a, b) => a + b, 0)

    let total_rate_fee = !scheduleObj.totalHours ? "0" : scheduleObj.totalHours.label !== 'Custom' ?
        (scheduleObj.totalBills * scheduleObj.totalHours.totalHours * scheduleObj.totalDays) :
        (scheduleObj.totalPerDay * scheduleObj.totalPerHours * scheduleObj.totalDays)

    let countPs;
    let deposit;
    let currentValue = countSubTotal !== undefined ? 0 : parseInt(countSubTotal)
    if (settingObj && settingObj.type && settingObj.type.value === 'custom') {
        countPs = settingObj.processing_fee
        deposit = settingObj.deposit_fee
    } else {
        countPs = 0.1 * countSubTotal;
        deposit = 50;
        switch (true) {
            case currentValue < 201:
                deposit = 50
                break;
            case currentValue >= 201 && currentValue < 501:
                deposit = 100
                break;
            case currentValue >= 501 && currentValue < 1001:
                deposit = 150
                break;
            case currentValue >= 1001:
                deposit = 250
                break;
            default:
                deposit = 50
                break;
        }
    }

    const currentDate = moment().format('YYYYMMDD')

    let total = countSubTotal + total_rate_fee + deposit + countPs + settingObj.transportation_fee;

    let findInvoiceObj = source.invoices.find(x => x.id === invoice_number)

    let data = {
        days: scheduleObj.totalHours !== null && scheduleObj.totalHours.label !== 'Custom' ? `Booking for ${scheduleObj.totalDays} days, ${scheduleObj.totalHours.totalHours} hours per day` : `Booking for ${scheduleObj.totalDays} days, ${scheduleObj.totalPerDay} hours per day`,
        file_name: invoice_number ? invoice_number : `Quotation${currentDate}`,
        invoice_number: invoice_number ? invoice_number : `Quotation${currentDate}`,
        booking_form: copy_data.id,
        booking_number: copy_data.booking_number,
        booking_start_datetime: copy_data.schedule_start_datetime,
        booking_end_datetime: copy_data.schedule_end_datetime,
        address_from: {
            firstname: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.name : 'SELCARE NURSING SDN BHD (202001042792)',
            address1: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.address1 : 'No. 1-02 & 1-03, Level 1',
            address2: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.address2 : 'Jalan Pembangunan 14/6, Section 14',
            city: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.city : 'Shah Alam',
            zipcode: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.zipcode : '40000',
            province: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.province : 'Selangor',
            country: copy_data.booker_info && copy_data.booker_info.address && copy_data.booker_info.address ? copy_data.booker_info.address.country : "Malaysia",
            email: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.email : null,
            phone: copy_data.booker_info && copy_data.booker_info.address ? copy_data.booker_info.address.phone : null,
        },
        address_to: {
            firstname: copy_data.patient_info.name,
            address1: copy_data.patient_info.address.address1,
            address2: copy_data.patient_info.address.address2,
            city: copy_data.patient_info.address.city,
            zipcode: copy_data.patient_info.address.zipcode,
            province: copy_data.patient_info.address.province,
            email: copy_data.patient_info.address.email,
            phone: copy_data.patient_info.address.phone,
            country: copy_data.patient_info.address.country
        },
        line_items: source.category !== 'confinement' ? itemsData : findInvoiceObj.line_items,
        subtotal: source.category !== 'confinement' ? (countSubTotal + total_rate_fee) : findInvoiceObj.subtotal,
        management_fee: 0,
        deposit_fee: source.category !== 'confinement' ? deposit : findInvoiceObj.deposit_fee,
        processing_fee: source.category !== 'confinement' ? countPs : findInvoiceObj.processing_fee,
        transportation_fee: source.category !== 'confinement' ? settingObj.transportation_fee : findInvoiceObj.transportation_fee,
        total: source.category !== 'confinement' ? total : findInvoiceObj.total,
        status: "pending",
        issued_by: !userData ? null : userData.id,
        total_rate_fee,
        how_many_days,
        hours_per_day,
        rate_per_hour,
        date: copy_data.createdAt,
        category_type: source.category
    }
    return data
}