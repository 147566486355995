import React, { useState } from 'react'
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'

const ModalPin = ({ visibility, handleSubmit, isClosed, loading }) => {
    const [form, setForm] = useState({
        nric: '',
        FullName: '',
        pin: ''
        // nric: '940603115617',
        // FullName: 'WAN MUHAMMAD FAKHRUR RAZI BIN WAN ABDUL HALIM',
        // pin: '12345678'
    })

    const resetForm = () => {
        setForm({
            nric: '',
            FullName: '',
            pin: ''
        })
    }

    const changeState = (label, value) => {
        setForm(prev => {
            return {
                ...prev,
                [label]: value
            }
        })
    }
    return (
        <Modal isOpen={visibility}>
            <ModalHeader>
                Verification by doctor
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <label>NRIC Number</label>
                        <Input placeholder='Exp: 920110108212' type='number' disabled={loading} value={form.nric} onChange={(e) => { changeState('nric', e.target.value) }} />
                    </FormGroup>
                    <FormGroup>
                        <label>Fullname</label>
                        <Input placeholder='Exp: Muhammad Hassan Bin Abdullah' disabled={loading} value={form.FullName} onChange={(e) => { changeState('FullName', e.target.value) }} />
                    </FormGroup>
                    <FormGroup>
                        <label>Pin Number</label>
                        <Input placeholder="Exp: 12345678" type='number' disabled={loading} value={form.pin} onChange={(e) => { changeState('pin', e.target.value) }} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color='danger' disabled={loading} onClick={() => {
                    resetForm()
                    isClosed()
                }}>Cancel</Button>
                <Button color='primary' disabled={loading} onClick={() => {
                    resetForm()
                    handleSubmit(form)
                }}>{loading ? <span><Spinner color='light' size={"sm"} className="align-middle mr-2" />Loading...</span> : 'Confirm'}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalPin