import React, { useContext } from 'react'
import { Button } from 'reactstrap'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'

const FooterActions = ({ goToPage, openModalApprove, openModalConfirmation, openModalPatientBiodata, openModalReject, openModalUpdateConsultation }) => {
    const { prescriptionDataLayer } = useContext(PrescriptionContext);

    const handleBackButton = () => {
        // console.log(prescriptionDataLayer.from);
        if (prescriptionDataLayer.patient) {
            if (prescriptionDataLayer.from === 'waiting-approval') {
                goToPage(`/e-prescription/${prescriptionDataLayer.from}`)
            } else if (prescriptionDataLayer.from === 'patient') {
                goToPage(`/e-prescription/${prescriptionDataLayer.from}/${prescriptionDataLayer.patient.id}`)
            } else if (prescriptionDataLayer.from === 'tele_medicine') {
                goToPage(`/tele-medicine`)
            }
        }
    }

    const handleRejectPrescription = () => {
        openModalReject()
    }

    const handleApprovePrescription = () => {
        openModalApprove()
    }

    const handleSubmitPrescription = () => {
        openModalConfirmation()
    }

    const handleQuestionaire = () => {
        openModalPatientBiodata()
    }

    const handleUpdatePrescription = () => {
        openModalConfirmation()
    }

    // const handleUpdateConsultation = () => {
    //     openModalUpdateConsultation()
    // }

    const handleSupplyPrescription = () => {
        openModalUpdateConsultation()
    }

    const handlePrint = () => {
        window.print()
    }
    return (
        <React.Fragment>
            <div className='d-flex justify-content-between align-items-center hide-from-print'>
                <div>
                    {prescriptionDataLayer.action_button && prescriptionDataLayer.action_button.questionaireForm && <Button className='mr-2' color='light' onClick={handleQuestionaire}>Screening questionnaire<i className="ri-information-line align-middle ml-2 ri-lg"></i></Button>}
                    {prescriptionDataLayer.action_button && prescriptionDataLayer.action_button.questionaireView && <Button className='mr-2' color='light' onClick={handleQuestionaire}>View Screening<i className="ri-information-line align-middle ml-2 ri-lg"></i></Button>}
                </div>

                <div>
                    {prescriptionDataLayer.action_button && prescriptionDataLayer.action_button.back && <Button className='mr-2' color='light' onClick={handleBackButton}>Back</Button>}
                    {prescriptionDataLayer.action_button && prescriptionDataLayer.action_button.cancel && <Button className='mr-2' color='light' onClick={handleBackButton}>Cancel</Button>}
                    {prescriptionDataLayer.action_button && prescriptionDataLayer.action_button.print && <Button color='light' className='mr-2' onClick={handlePrint}>Print<i className="ri-printer-line align-middle ml-2 ri-lg"></i></Button>}
                    {/* prescription */}
                    {prescriptionDataLayer.action_button.crud_prescription && prescriptionDataLayer.action_button.crud_prescription.reject && <Button className='mr-2' color='danger' onClick={handleRejectPrescription}>Reject</Button>}
                    {prescriptionDataLayer.action_button.crud_prescription && prescriptionDataLayer.action_button.crud_prescription.approve && <Button className='mr-2' color='primary' onClick={handleApprovePrescription}>Approve</Button>}
                    {prescriptionDataLayer.action_button.crud_prescription && prescriptionDataLayer.action_button.crud_prescription.submit && <Button color='primary' onClick={handleSubmitPrescription}>Send for approval<i className="ri-send-plane-line align-middle ml-2 ri-lg"></i></Button>}
                    {prescriptionDataLayer.action_button.crud_prescription && prescriptionDataLayer.action_button.crud_prescription.update && <Button color='primary' onClick={handleUpdatePrescription}>Update Prescription</Button>}
                    {prescriptionDataLayer.action_button.crud_prescription && prescriptionDataLayer.action_button.crud_prescription.supply && <Button color='primary' onClick={handleSupplyPrescription}>Supply</Button>}
                    {/* tele_medicine */}
                   
                </div>

            </div>
        </React.Fragment>
    )
}

export default FooterActions