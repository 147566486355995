import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { capitalizeEveryLetter } from '../../../../../store/CustomFunctions'
import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext'
import { PrescriptionContext } from '../../../../../store/prescriptions/PrescriptionData'
// import img from '../../../../../assets/images/selcare.png'

const ModalMedicationCertificate = ({ visible, isClosed, onSubmit }) => {
    const { prescriptionDataLayer } = useContext(PrescriptionContext)
    const { globalData } = useContext(GlobalContext)

    const memorizedPatient = prescriptionDataLayer.patient
    const memorizedMedical = prescriptionDataLayer.consultation?.medical_certificates?.[0]

    const [patientData, setPatientData] = useState({
        patient_name: '',
        patient_nric: '',
        patient_company: '',
        datetime: moment(),
        day_leave_count: 1,
        day_leave_from: moment(),
        day_leave_to: moment().format('YYYY-MM-DD'),
        doctor_name: '',
        reason: ''
    })

    const updateFormData = (field, value) => {
        setPatientData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const handler = useCallback(() => {
        if (memorizedPatient) {
            setPatientData(prev => {
                return {
                    ...prev,
                    patient_name: capitalizeEveryLetter(memorizedPatient.user.firstname),
                    patient_nric: memorizedPatient.nric,
                    day_leave_count: !memorizedMedical ? 1 : memorizedMedical.day_leave_count,
                    // day_leave_from: !memorizedMedical ? moment().format('YYYY-MM-DD') : moment(memorizedMedical.day_leave_from).format('YYYY-MM-DD'),
                    // day_leave_to: !memorizedMedical ? moment().format('YYYY-MM-DD') : moment(memorizedMedical.day_leave_to).format('YYYY-MM-DD'),
                    reason: !memorizedMedical ? "" : memorizedMedical.reason,
                    patient_company: !memorizedMedical ? "" : memorizedMedical.patient_company,
                }
            })
        }
    }, [memorizedPatient, memorizedMedical])

    useEffect(() => {
        handler()
    }, [handler])
    return (
        <Modal
            centered
            isOpen={visible}
            toggle={() => isClosed()}
            size="md"
        >
            <ModalBody>
                <div>
                    <h4><i className="ri-file-list-3-line align-middle ri-2x mr-2"></i>Medical Certificate</h4>
                </div>
                <hr />
                <div>
                    <FormGroup>
                        <Label>Patient Name</Label>
                        <Input type='text' maxLength={50} value={patientData.patient_name} disabled />
                    </FormGroup>
                    <FormGroup>
                        <Label>Patient NRIC</Label>
                        <Input type='number' placeholder='E.g: 921010101231' value={patientData.patient_nric} disabled />
                    </FormGroup>
                    <FormGroup>
                        <Label>Patient Company</Label>
                        <Input type='text' maxLength={37} value={patientData.patient_company} onChange={(e) => updateFormData('patient_company', e.target.value)} />
                        <p className='small text-muted mt-1'><i>Maximum 37 characters</i></p>
                    </FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Day leave</Label>
                                <Input
                                    type='number'
                                    min={1}
                                    value={patientData.day_leave_count}
                                    onChange={(event) => {
                                        updateFormData('day_leave_count', event.target.value)
                                    }}
                                />
                                {/* <p className='small text-muted mt-1'><i>Maximum 10 days</i></p> */}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>From</Label>
                                <Input
                                    disabled
                                    type='date'
                                    value={moment(patientData.day_leave_from).format('YYYY-MM-DD')}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>To</Label>
                                <Input
                                    disabled
                                    type='date'
                                    value={patientData.day_leave_count === 1 ? moment(patientData.day_leave_to).format('YYYY-MM-DD') : moment(patientData.day_leave_to).add(patientData.day_leave_count - 1, 'days').format('YYYY-MM-DD')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Reason</Label>
                        <Input
                            type='textarea'
                            maxLength={37}
                            placeholder='Type something...'
                            value={patientData.reason}
                            onChange={(event) => {
                                updateFormData('reason', event.target.value)
                            }} />
                        <p className='small text-muted mt-1'><i>Maximum 37 characters</i></p>
                    </FormGroup>
                </div>
                <div className='position-relative d-flex justify-content-between align-items-center w-100'>
                    <Button color='light' onClick={() => isClosed()} className='w-50 mr-2'>Cancel</Button>
                    <Button
                        disabled={!patientData.patient_name || !patientData.patient_nric || !patientData.patient_company || !patientData.day_leave_count || !patientData.day_leave_from || !patientData.day_leave_to || !patientData.reason}
                        color='primary'
                        className='w-50'
                        onClick={() => {
                            let payload = {
                                ...patientData,
                                day_leave_from: moment(patientData.day_leave_from),
                                day_leave_to: patientData.day_leave_count === 1 ? moment(patientData.day_leave_to).format('YYYY-MM-DD') : moment(patientData.day_leave_to).add(patientData.day_leave_count - 1, 'days').format('YYYY-MM-DD'),
                                doctor_name: globalData.global.user.username,
                                consultation: prescriptionDataLayer.consultation.id
                            }
                            onSubmit(payload)
                        }}>
                        Save
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalMedicationCertificate