import React, { useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import Select from 'react-select'
import { Button } from 'reactstrap'
import { ACTION_TYPE } from '../../Nursing/Booking/Nurse/Store/ActionType'

const AlertConfirmation = ({
    title,
    desc,
    alertConfirm,
    setAlertConfirm,
    assignedJob,
    createNewJob,
    unassignedJob,
    action,
    formValues,
    setTemporary,
    dispatch,
    dataSource,
    completeBooking,
    removeBooking,
    completePayment,
    listOfDropDown,
    invoiceType,
    removeFile,
    removeProduct,
    removePromo,
    removePromoItem,
    handleSubmitFlag,
    handleSubmitRefund,
    handleEndButton,
    handleAcceptButton
}) => {
    const [selected, setSelected] = useState(null);
    const [error, setError] = useState(true);
    return (
        alertConfirm &&
        <SweetAlert
            style={{ overflow: "unset" }}
            title={title}
            warning
            showCancel={false}
            showConfirm={false}
            onConfirm={() => { }}
        >
            <p className="text-break m-0">You won't be able to revert this! </p>
            <p className='text-break'>{desc}</p>

            {(((action === 'complete_booking') || (action === 'complete_payment')) && (invoiceType === "quotation")) &&
                <div>
                    <Select
                        className="pt-3 pb-3"
                        placeholder="Please Select Quotation"
                        styles={{ zIndex: 2 }}
                        options={listOfDropDown}
                        onChange={val => {
                            setError(true)
                            setSelected(val)
                        }}
                    />
                    <p className="text-danger">{!error && "Quotation is required!"}</p>
                </div>}
            <div className="mt-4">
                <Button
                    className="btn-lg mr-2"
                    color="danger"
                    onClick={() => {
                        if (action === 'complete_booking' || action === 'remove_booking' || action === 'complete_payment') {
                            setAlertConfirm(!alertConfirm)
                        } else if (action === 'assigned_jobs' || action === 'unassigned_job') {
                            let assigned = formValues.provider_booking_jobs.filter(item => item.type_of_job === 'assigned' || action === 'create_provider_jobs')
                            let backup = formValues.provider_booking_jobs.filter(item => item.type_of_job === 'backup')
                            setTemporary({
                                assignedData: assigned,
                                backupData: backup
                            })
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        } else {
                            setAlertConfirm(!alertConfirm)
                            setSelected(null)
                            setError(true)
                        }
                    }}
                >Discard</Button>
                <Button
                    className="btn-lg"
                    color='success'
                    onClick={(e) => {
                        if (action === 'assigned_jobs') {
                            setAlertConfirm(!alertConfirm)
                            assignedJob()
                        } else if (action === 'unassigned_job') {
                            setAlertConfirm(!alertConfirm)
                            unassignedJob()
                        } else if (action === 'create_provider_jobs') {
                            setAlertConfirm(!alertConfirm)
                            createNewJob()
                        } else if (action === 'complete_booking') {
                            if (selected === null && invoiceType === "quotation") {
                                setError(false);
                            } else {
                                setAlertConfirm(!alertConfirm)
                                completeBooking(e, invoiceType === "quotation" ? selected : listOfDropDown[0])
                                setSelected(null)
                                setError(true)
                            }
                        } else if (action === 'remove_booking') {
                            setAlertConfirm(!alertConfirm)
                            removeBooking()
                        } else if (action === 'complete_payment') {
                            if (selected === null && invoiceType === "quotation") {
                                setError(false);
                            } else {
                                setAlertConfirm(!alertConfirm)
                                completePayment(e, invoiceType === "quotation" ? selected : listOfDropDown[0])
                                setSelected(null)
                                setError(true)
                            }
                        } else if (action === 'remove_file') {
                            setAlertConfirm(!alertConfirm)
                            removeFile()
                        } else if (action === 'remove_products') {
                            setAlertConfirm(!alertConfirm)
                            removeProduct()
                        } else if (action === 'remove_promo') {
                            setAlertConfirm(!alertConfirm)
                            removePromo()
                        } else if (action === 'remove_promo_item') {
                            setAlertConfirm(!alertConfirm)
                            removePromoItem()
                        } else if (action === 'remove_flag') {
                            setAlertConfirm(!alertConfirm)
                            handleSubmitFlag(e)
                        } else if (action === 'remove_refund') {
                            setAlertConfirm(!alertConfirm)
                            handleSubmitRefund(e)
                        } else if (action === 'end_consultation') {
                            handleEndButton()
                            setAlertConfirm(!alertConfirm)
                        } else if (action === 'accept_consultation') {
                            handleAcceptButton()
                            setAlertConfirm(!alertConfirm)
                        } else {
                            setAlertConfirm(!alertConfirm)
                        }
                    }}
                >Confirm</Button>
            </div>
        </SweetAlert>
    )
}

export default AlertConfirmation
