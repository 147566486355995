import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { getAPIroot, post } from '../../../../../helpers/API';
import { capitalizeFirstLetter } from '../../../../../store/CustomFunctions';
import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext';
import { ACTION_TYPE } from '../../../../../store/prescriptions/ActionType';
import { PrescriptionContext } from '../../../../../store/prescriptions/PrescriptionData';
import { postUploadImage, putChats } from '../../../../../store/prescriptions/_api';
import EffectBoxContainer from '../../../../../components/VideoCall/EffectBoxContainer';
import Header from '../ComponentChat/HeaderChat';

const ModalVideoCall = ({ visible, handleMinised, handleEndCall, toggleCamera, toggleSpeaker, setBlur, clearEffect, setWallpaper, timers, countDownClose }) => {
    let styles = {
        container: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            borderRadius: 4,
            background: '#f0f2f6'
        },
        btn: {
            bottom: 5,
            right: 5,
        }
    }

    const [selectedFile] = useState('')
    const [messages, setMessages] = useState([])
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext);
    const { globalData } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [showInputText, setShowInputText] = useState(false);
    const [text, setText] = useState('');
    const [changeIcon, setChangeIcon] = useState({
        video: false,
        audio: false
    })
    const [modalImg, setModalImg] = useState({
        visible: false,
        url: null,
        name: null
    });
    const [showEffect, setShowEffect] = useState(false);

    const [mytimer, setMytimer] = useState(0)

    const showWarning = useMemo(() => {
        const num = parseFloat(countDownClose);
        return num
    }, [countDownClose])

    useEffect(() => {
        if (showWarning === 10 || showWarning === 20) {
            setMytimer(5)
        }
    }, [showWarning])

    useEffect(() => {
        let interval = 0
        if (mytimer === 5) {
            interval = setInterval(() => {
                setMytimer(prev => prev - 1)
            }, 1000);
        } else if (mytimer === 0) {
            setMytimer(0)
            clearInterval(interval)
        }
    }, [mytimer])

    const getMessagesUpdate = useMemo(() => {
        const chats = prescriptionDataLayer.chat && prescriptionDataLayer.chat;
        const chat = chats && chats.messages && chats.messages.length > 0;
        const data = chat && chats.messages
        return {
            chats: data,
            modal: visible
        }
    }, [prescriptionDataLayer.chat, visible])


    useEffect(() => {
        if (getMessagesUpdate.chats) {
            setLoading(false)
            setMessages(getMessagesUpdate.chats.map(item => {
                return {
                    ...item,
                    send: false
                }
            }))
        }

        if (getMessagesUpdate.modal) {
            setTimeout(() => {
                let element = document.getElementById("chating_box");
                if (element) element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
            }, 1000);
        }
    }, [getMessagesUpdate])

    useEffect(() => {
        if (prescriptionDataLayer.consultation) {
            setShowInputText(prescriptionDataLayer.consultation.status === 'completed' ? false : true)
        }
    }, [prescriptionDataLayer.consultation])

    const avatar = (name, bg, image) => {
        if (image) {
            return <img src={`${getAPIroot()}${image.url}`} className="rounded-circle img-thumbnail avatar-sm mr-3" alt="thumbnail" />
        } else {
            if (name) {
                return <div className={`avatar-user avatar-sm mr-2 ${bg}`}>{capitalizeFirstLetter(name[0])}</div>
            } else {
                return <div className={`avatar-user avatar-sm mr-2 ${bg}`}>U</div>
            }
        }
    }
    const scrollbottom = () => {
        let el = document.getElementById("chating_box");
        if (el) el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
    }

    const sizeFormating = (fileSizeInBytes) => {
        // console.log(fileSizeInBytes);
        var i = -1;
        var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes /= 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

    const handleDownload = () => {
        document.getElementById('file-link').click();
    }

    const handleIcon = (iconFormat) => {
        let icon = null
        if (iconFormat.includes('pdf') || iconFormat.includes('doc') || iconFormat.includes('docx') || iconFormat.includes('ppt') || iconFormat.includes('xlsx') || iconFormat.includes('csv') || iconFormat.includes('txt') || iconFormat.includes('json')) {
            switch (iconFormat) {
                case '.pdf':
                    icon = 'ri-file-pdf-line'
                    break;
                case '.json':
                    icon = 'ri-file-code-line'
                    break;
                case '.xlsx':
                    icon = 'ri-file-excel-2-line'
                    break;
                case '.ppt':
                    icon = 'ri-file-ppt-2-line'
                    break;
                case '.doc':
                    icon = 'ri-file-word-2-line'
                    break;
                case '.docx':
                    icon = 'ri-file-word-2-line'
                    break;
                default:
                    icon = 'ri-file-line'
                    break;
            }
            return <div className='is-cursor cursor-img position-relative'><div className='px-3 text-center bg-white border-top mt-2 text-primary '><i className={`${icon} ri-3x`}></i></div><Button className='btn-view m-auto position-absolute btn-circle h-100 w-100 rounded-0 text-primary' style={{ top: 0, opacity: 0.8 }} color='light' outline><i className="ri-download-2-line align-middle mr-1" onClick={handleDownload}></i>Download</Button></div>
        }


    }

    const toggle = (url, name) => {
        setModalImg(prev => {
            return {
                visible: !prev.visible,
                url,
                name
            }
        })
    };

    const uploadFile = async (value) => {
        setMessages(prev => {
            return [
                ...prev,
                {
                    user: {
                        id: globalData.global.user.id,
                        username: globalData.global.user.username
                    },
                    text,
                    sent_datetime: moment().toISOString(),
                    file: null,
                    send: true
                }
            ]
        })

        setTimeout(() => {
            setText('')
            if (visible) scrollbottom()
        }, 300);

        if (value) {
            let formImg = new FormData();
            formImg.append(`files`, value);

            const responseImg = await postUploadImage(formImg);

            if (responseImg) {
                let payload = {
                    messages: [
                        ...messages,
                        {
                            user: globalData.global.user.id,
                            text: '',
                            sent_datetime: moment().toISOString(),
                            file: responseImg[0].id
                        }
                    ]
                }

                payload.messages.map(x => {
                    delete x.send
                    return x
                })

                const response = await putChats(payload, prescriptionDataLayer.chat.id)
                if (response) {
                    await post("/custom/push-chat-messages", {
                        chatID: response.id,
                        currentMessage: response.messages[response.messages.length - 1],
                    });
                    setTimeout(async () => {
                        dispatch({
                            type: ACTION_TYPE.UPDATE_CHAT,
                            payload: response
                        })
                        if (messages.length > 0) scrollbottom()
                    }, 800);
                }
            }
        }
    }

    const handleSubmit = async () => {
        setMessages(prev => {
            return [
                ...prev,
                {
                    user: {
                        id: globalData.global.user.id,
                        username: globalData.global.user.username
                    },
                    text,
                    sent_datetime: moment().toISOString(),
                    file: null,
                    send: true
                }
            ]
        })

        setTimeout(() => {
            setText('')
            if (visible) scrollbottom()
        }, 300);

        if (prescriptionDataLayer.chat && prescriptionDataLayer.chat.id) {
            let payload = {
                messages: [
                    ...messages,
                    {
                        user: globalData.global.user.id,
                        text,
                        sent_datetime: moment().toISOString(),
                        file: null
                    }
                ]
            }

            payload.messages.map(x => {
                delete x.send
                return x
            })
            const response = await putChats(payload, prescriptionDataLayer.chat.id)
            if (response) {
                await post("/custom/push-chat-messages", {
                    chatID: response.id,
                    currentMessage: response.messages[response.messages.length - 1],
                });
                setTimeout(async () => {
                    dispatch({
                        type: ACTION_TYPE.UPDATE_CHAT,
                        payload: response
                    })
                    if (visible) scrollbottom()
                }, 800);
            }
        }
    }

    const handleUpload = () => {
        document.getElementById('uploadFile2').click();
    }
    return (
        <div>
            <Modal
                centered
                isOpen={visible}
                // toggle={() => isClosed()}
                size='xl'
            >
                <ModalBody>

                    <div className='position-relative overflow-hidden'>
                        <div className='position-relative d-flex justify-content-between'>
                            <EffectBoxContainer showEffect={showEffect} clearEffect={clearEffect} setBlur={setBlur} setWallpaper={setWallpaper} />
                            <div className='position-relative h-100 w-100 ef'>
                                <div className='bg-light text-dark rounded p-2 d-flex align-items-center'>
                                    <h4 className='text-dark mb-0 w-50'>Video call<i className="ri-vidicon-line ml-2 align-middle ri-sm"></i></h4>
                                    <div className='d-flex justify-content-end w-100 text-right'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className="circle"></div>
                                            <div className='w-75'>
                                                <p className='mb-0 mr-1'><span>{timers}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {mytimer > 0 ? <div className='bg-warning mt-2 text-dark rounded p-2 d-flex align-items-center'>
                                    <i className="ri-error-warning-line align-middle mr-2"></i>Your video call has been ongoing for 10 minutes.<span className='float-right ml-3'>{mytimer}</span>
                                </div> : null}

                                <div className='mt-2 rounded overflow-hidden'>
                                    <div className='d-flex justify-content-center align-items-center position-absolute bg-light w-100' style={{ height: 500 }}><i className="ri-user-line ri-3x"></i></div>
                                    <div className='bg-light' style={{ height: 500, minWidth: 500 }} id='player-other'></div>
                                </div>
                                <div className='position-absolute bg-light shadow-sm rounded shadow-lg border-white border overflow-hidden' style={{ bottom: 65, right: 10, }}>

                                    <div className='bg-light d-flex justify-content-between align-items-center' style={{ height: 130, width: 130 }}>
                                        <div className='d-flex justify-content-center align-items-center position-absolute bg-light w-100 h-100'><i className="ri-user-line ri-3x"></i></div>
                                        <div id='loading' className='bg-light'></div>
                                        <div id='player-left' className='bg-light' style={{ height: 130, width: 130 }}></div>
                                    </div>
                                </div>
                                <div className='position-absolute mt-2 rounded p-2 text-center d-flex justify-content-center align-items-center' style={{ bottom: 10, width: 'calc(100% - 20px)', left: 10 }}>
                                    <div className='d-flex'>
                                        <div className='px-2'>
                                            <Button color='light' size='sm' className='rounded-circle border border-primary' onClick={() => setShowEffect(!showEffect)}><i className="ri-settings-fill align-middle text-primary"></i></Button>
                                            {/* <p className='mb-0 mt-1 small text-white'>Effects</p> */}
                                        </div>
                                        {/* {effectVideoCall.blur ? <Button color='warning' size='sm' className='rounded-circle mr-2' onClick={clearEffect}><i className="ri-blur-off-fill align-middle"></i></Button> : <Button color='warning' size='sm' className='rounded-circle mr-2' onClick={setBlur}><i className="ri-drop-fill align-middle"></i></Button>} */}

                                        <div className='px-2'>
                                            <Button color='danger' size='sm' className='rounded-pill border border-white' onClick={handleEndCall}><i className="ri-close-fill align-middle"></i></Button>
                                            {/* <p className='mb-0 mt-1 small text-white'>Leave</p> */}
                                        </div>

                                        <div className='px-2'>
                                            <Button color={!changeIcon.video ? 'light' : 'dark'} size='sm' className={!changeIcon.video ? 'rounded-circle border border-primary' : 'rounded-circle border'} onClick={() => {
                                                setChangeIcon(prev => {
                                                    return {
                                                        ...prev,
                                                        video: !prev.video
                                                    }
                                                });
                                                toggleCamera(changeIcon.video ? 'on' : 'off')

                                            }}><i className={!changeIcon.video ? "ri-camera-fill align-middle text-primary" : "ri-camera-off-fill align-middle text-white"}></i></Button>
                                            {/* <p className='mb-0 mt-1 small text-white'>Cam</p> */}
                                        </div>

                                        <div className='px-2'>
                                            <Button color={!changeIcon.audio ? 'light' : 'dark'} size='sm' className={!changeIcon.audio ? 'rounded-circle border border-primary' : 'rounded-circle border'} onClick={() => {
                                                setChangeIcon(prev => {
                                                    return {
                                                        ...prev,
                                                        audio: !prev.audio
                                                    }
                                                });
                                                toggleSpeaker(changeIcon.audio ? 'on' : 'off')

                                            }}><i className={!changeIcon.audio ? "ri-mic-2-fill align-middle text-primary" : "ri-mic-off-fill align-middle text-white"}></i></Button>
                                            {/* <p className='mb-0 mt-1 small text-white'>Mic</p> */}
                                        </div>

                                        <div className='px-2'>
                                            <Button color='light' size='sm' className='rounded-circle border border-primary' onClick={() => handleMinised()}>
                                                <i className="ri-fullscreen-exit-line align-middle text-primary"></i>
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='h-100 position-relative ml-2 rounded overflow-hidden' style={{ width: 580 }}>
                                <div className='bg-light position-absolute p-2 w-100 shadow-sm' style={{ zIndex: 10 }}>{prescriptionDataLayer.patient && prescriptionDataLayer.patient.user &&
                                    <Header data={prescriptionDataLayer.patient} />
                                    // <i className="ri-chat-1-line mx-2 align-middle"></i><b>Chat with {capitalizeFirstLetter(prescriptionDataLayer.patient.user?.firstname)}</b>
                                }</div>
                                <div id='chating_box' className='bg-light overflow-auto rounded p-2 w-100' style={{ height: 550 }}>
                                    <div style={{ paddingTop: '4rem', paddingBottom: '3rem' }}>
                                        <div className='border-top px-3 py-1'>
                                            {loading ? (<div className='d-flex justify-content-center align-items-center text-muted italic p-4'><em>Loading...</em></div>) : (
                                                <div>{messages.length > 0 && (globalData.global.user && globalData.global.user.id) ? (
                                                    messages.filter(x => x.user && x.user.id).map((message, index) => {
                                                        return (
                                                            message.user.id === globalData.global.user.id ? <div key={index} className='justify-content-end d-flex w-100 overflow-hidden mb-3'>
                                                                {!message.file ? <div className='d-block positio-relative'>
                                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100 opacity-1'>
                                                                        <div>{avatar(message.user.username, 'bg-primary text-white font-weight-bold', message.user.image)}</div>
                                                                        <div className='ml-2 w-100 position-relative d-block border border-primary bg-primary shadow-sm rounded py-2 px-3' style={{ minWidth: 50, opacity: message.send ? '.5' : '1' }}>
                                                                            <div className='w-100 position-relative d-block'>
                                                                                <div className='w-100 d-flex justify-content-between' style={{ fontSize: 10 }}>
                                                                                    <p className='font-weight-light m-0 text-light mr-3'>{message.send ? 'Sending...' : capitalizeFirstLetter('you')}</p></div>
                                                                            </div>
                                                                            <div className='font-weight-bold rounded w-100 py-2' style={{ fontSize: 16 }}><p className='text-white m-0'>{message.text}</p></div>
                                                                            <div><p className='font-weight-light m-0 text-light text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                                        </div>

                                                                    </div>
                                                                </div> : <div className='d-block positio-relative w-100 h-100'>
                                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100'>
                                                                        <div>{avatar(message.user.username, 'bg-white text-muted font-weight-bold', message.user.image)}</div>

                                                                        <div className='ml-2 w-100 position-relative d-block border bg-primary rounded shadow-sm' style={{ minWidth: 200 }}>
                                                                            <div className='w-100 position-relative d-block pt-2 px-3'>
                                                                                <div className='w-100 d-flex justify-content-between'>
                                                                                    <p className='font-weight-light m-0 text-light mr-3'>{message.send ? 'Sending...' : capitalizeFirstLetter('you')}</p>
                                                                                </div>
                                                                            </div>
                                                                            {message.file && message.file.url && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className='mt-2 is-cursor cursor-img position-relative'><img src={getAPIroot() + message.file.url} alt="" style={{ width: '100%' }} /><Button className='btn-view m-auto position-absolute btn-circle h-100 w-100 rounded-0' style={{ top: 0, opacity: 0.8 }} color='primary' outline onClick={() => toggle(getAPIroot() + message.file.url, message.file.name)}><i className="ri-eye-line align-middle mr-1"></i>View</Button></div> : null}
                                                                            {message.file && message.file.ext ? handleIcon(message.file.ext) : null}
                                                                            {message.file && message.file.name && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className='d-flex justify-content-between' style={{ backgroundColor: '#3d4ab3' }}><p className='py-2 px-3 text-light small m-0 is-cursor d-inline-block text-truncate' style={{ width: '70%' }}>{message.file.name}</p><p className='py-2 px-3 text-light small m-0 is-cursor'>{sizeFormating(message.file.size)}</p></div> : null}
                                                                            {message.file && message.file.name && (message.file.ext.includes('pdf') || message.file.ext.includes('docx') || message.file.ext.includes('xlsx') || message.file.ext.includes('json') || message.file.ext.includes('doc') || message.file.ext.includes('txt') || message.file.ext.includes('ppt') || message.file.ext.includes('csv')) ? <div className='bg-light'><a href={getAPIroot() + message.file.url} className='py-2 px-3 text-primary small m-0 is-cursor'>{message.file.name}</a></div> : null}
                                                                            {message.text ? <div className='font-weight-bold rounded w-100 px-3 pt-2' style={{ fontSize: 16 }}><p className='text-white m-0 is-cursor'>{message.text}</p></div> : null}
                                                                            <div className='py-2 px-3'><p className='font-weight-light m-0 text-light text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div> : <div key={index} className='justify-content-start d-flex w-100 overflow-hidden mb-3'>
                                                                {!message.file ? <div className='d-block positio-relative'>
                                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100'>
                                                                        <div>{avatar(message.user.username, 'bg-white text-muted font-weight-bold', message.user.image)}</div>
                                                                        <div className='ml-2 w-100 position-relative d-block border bg-white rounded shadow-sm py-2 px-3' style={{ minWidth: 50 }}>
                                                                            <div className='w-100 position-relative d-block'>
                                                                                <div className='w-100 d-flex justify-content-between'>
                                                                                    <p className='font-weight-light m-0 text-primary mr-3' style={{ fontSize: 10 }}>{capitalizeFirstLetter(message.user.username)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='font-weight-bold rounded w-100 py-2' style={{ fontSize: 16 }}><p className='text-dark m-0'>{message.text}</p></div>
                                                                            <div><p className='font-weight-light m-0 text-muted text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <div className='d-block positio-relative w-100 h-100'>
                                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100'>
                                                                        <div>{avatar(message.user.username, 'bg-white text-muted font-weight-bold', message.user.image)}</div>

                                                                        <div className='ml-2 w-100 position-relative d-block border bg-white rounded shadow-sm' style={{ minWidth: 200 }}>
                                                                            <div className='w-100 position-relative d-block pt-2 px-3'>
                                                                                <div className='w-100 d-flex justify-content-between'>
                                                                                    <p className='font-weight-light m-0 text-primary mr-3' style={{ fontSize: 10 }}>{capitalizeFirstLetter(message.user.username)}</p>
                                                                                </div>
                                                                            </div>
                                                                            {message.file && message.file.url && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className="mt-2 is-cursor cursor-img position-relative"><img src={getAPIroot() + message.file.url} alt="" style={{ width: '100%' }} /><Button className='btn-view m-auto position-absolute btn-circle h-100 w-100 rounded-0' style={{ top: 0, opacity: 0.8 }} color='light' outline onClick={() => toggle(getAPIroot() + message.file.url, message.file.name)}><i className="ri-eye-line align-middle mr-1"></i>View</Button></div> : null}
                                                                            {message.file && message.file.ext ? handleIcon(message.file.ext) : null}
                                                                            {message.file && message.file.name && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className='bg-light d-flex justify-content-between'><p className='py-2 px-3 text-muted small m-0 is-cursor d-inline-block text-truncate' style={{ width: '70%' }}>{message.file.name}</p><p className='py-2 px-3 text-muted small m-0 is-cursor'>{sizeFormating(message.file.size)}</p></div> : null}
                                                                            {message.file && message.file.name && (message.file.ext.includes('pdf') || message.file.ext.includes('docx') || message.file.ext.includes('xlsx') || message.file.ext.includes('json') || message.file.ext.includes('doc') || message.file.ext.includes('txt') || message.file.ext.includes('ppt') || message.file.ext.includes('csv')) ? <div className='bg-light  d-flex justify-content-between'><a id='file-link' href={getAPIroot() + message.file.url} className='py-2 px-3 text-primary small m-0 is-cursor d-inline-block text-truncate' style={{ width: '70%' }}>{message.file.name}</a><p className='py-2 px-3 text-muted small m-0 is-cursor'>{sizeFormating(message.file.size)}</p></div> : null}
                                                                            {message.text ? <div className='font-weight-bold rounded w-100 px-3 pt-2' style={{ fontSize: 16 }}><p className='text-dark m-0 is-cursor'>{message.text}</p></div> : null}
                                                                            <div className='py-2 px-3'><p className='font-weight-light m-0 text-muted text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        )
                                                    })) : <div className='d-flex justify-content-center align-items-center text-center text-light p-4'>
                                                    <div><i className="ri-inbox-line align-middle ri-2x"></i>
                                                        <p><em>Empty</em></p>
                                                    </div>
                                                </div>
                                                }</div>
                                            )}
                                        </div>

                                        {showInputText &&
                                            <div className='p-1' style={styles.container}>
                                                <div className='rounded border p-2 position-relative d-flex justify-content-between align-items-center w-100' >
                                                    <Input
                                                        placeholder='Type something...'
                                                        type='textarea' value={text}
                                                        onChange={e => setText(e.target.value)}
                                                        bsSize='sm'
                                                        className='rounded bg-none border-0'
                                                        onKeyDown={(event) => {
                                                            if (!event.shiftKey && event.keyCode === 13) {
                                                                handleSubmit(event);
                                                            }

                                                            if (event.shiftKey && event.keyCode === 13) {
                                                                event.target.value += "\n";
                                                                console.log("Shift + Enter pressed");
                                                            }
                                                        }}
                                                    />
                                                    <div className='d-flex'>
                                                        <Button size='sm' onClick={handleUpload} color='light' className='rounded-circle text-center mr-2' ><i className="ri-attachment-line align-middle ri-1x"></i></Button>
                                                        <Input
                                                            id="uploadFile2"
                                                            className='d-none'
                                                            name="file"
                                                            type="file"
                                                            value={selectedFile}
                                                            onChange={(e) => uploadFile(e.target.files[0])}
                                                        />
                                                        <div style={styles.btn}><Button size='sm' disabled={!text} onClick={handleSubmit} color='primary' className='rounded-circle text-center' ><i className="ri-send-plane-fill align-middle ri-1x"></i></Button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalImg.visible} size="xl" fade={true} toggle={() => { setModalImg({ visible: false, url: null, name: null }) }}>
                <ModalHeader toggle={() => { setModalImg({ visible: false, url: null, name: null }) }}>{modalImg.name}</ModalHeader>
                <ModalBody className='p-1' style={{ height: 590 }}>
                    <div className='position-relative h-100 d-flex justify-content-center w-100 overflow-hidden rounded'  >
                        <img src={modalImg.url} alt="" height="100%" width="auto" />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ModalVideoCall