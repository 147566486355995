//  import React, { useState } from 'react'
//  import { Button, Modal, ModalBody, Spinner, FormGroup, Input } from 'reactstrap'

//  const ModalConfirmation = ({ visible, loading, isClose, onSubmit }) => {
//      const [senderDetails, setSenderDetails] = useState({
//          name: '',
//          nric: '',
//          registeration_no: ''
//      })

//      const updateForm = (field, value) => {
//          setSenderDetails(prev => {
//              return {
//                  ...prev,
//                  [field]: value
//              }
//          })
//      }
//      return (
//          <Modal
//              isOpen={visible}
//              backdrop="static"
//              centered
//              size="md"
//          >
//              <ModalBody>
//                  {!loading ?
//                      <div>
//                          <h4 className='mb-4'>Send for approval?</h4>
//                          <FormGroup>
//                              <label>Name</label>
//                              <Input value={senderDetails.name} type="text" onChange={(e) => updateForm('name', e.target.value)} />
//                          </FormGroup>
//                          <FormGroup>
//                              <label>NRIC</label>
//                              <Input value={senderDetails.nric} type="text" onChange={(e) => updateForm('nric', e.target.value)} />
//                          </FormGroup>
//                          <FormGroup>
//                              <label>Registration No.</label>
//                              <Input value={senderDetails.registeration_no} type="text" onChange={(e) => updateForm('registeration_no', e.target.value)} />
//                          </FormGroup>
//                          <div className='d-flex justify-content-center align-items-center'>
//                              <Button color='light' className='mr-2 w-100' onClick={isClose}>Cancel</Button>
//                              <Button color='primary' className='w-100' onClick={() => onSubmit(senderDetails)}>Submit</Button>
//                          </div>
//                      </div> :
//                      <div className='d-flex justify-content-center align-items-center'>
//                          <div className='text-center'>
//                              <Spinner color='primary' size={"lg"} />
//                              <div>
//                                  <p className='text-secondary mt-2 mb-0'>Loading...</p>
//                              </div>
//                          </div>
//                      </div>}
//              </ModalBody>
//          </Modal>
//      )
//  }

//  export default ModalConfirmation

import React, { useState } from 'react'
import { Button, FormGroup, Input, Modal, ModalBody, Spinner } from 'reactstrap'

const ModalConfirmation = ({ visible, isClosed, onSubmit, loading, type, onUpdate }) => {
    const [senderDetails, setSenderDetails] = useState({
        name: '',
        nric: '',
        registeration_no: ''
    })

    const updateForm = (field, value) => {
        setSenderDetails(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    return (
        <Modal centered isOpen={visible}>
            <ModalBody>
                {!loading ? (
                    <div>
                        <h4 className='mb-4'>{type !== 'update_prescription' ? 'Send for approval?' : 'Resubmit for approval'}</h4>
                        <FormGroup>
                            <label>Name</label>
                            <Input value={senderDetails.name} type="text" onChange={(e) => updateForm('name', e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <label>NRIC</label>
                            <Input value={senderDetails.nric} type="text" onChange={(e) => updateForm('nric', e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <label>Registration No.</label>
                            <Input value={senderDetails.registeration_no} type="text" onChange={(e) => updateForm('registeration_no', e.target.value)} />
                        </FormGroup>
                        <div className='d-flex justify-content-center align-items-center'>
                            <Button color='light' className='mr-2 w-100' onClick={isClosed}>Cancel</Button>
                            <Button color='primary' className='w-100' onClick={() => type !== 'update_prescription' ? onSubmit(senderDetails) : onUpdate(senderDetails)}>{type !== 'update_prescription' ? 'Submit' : 'Resubmit'}</Button>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                            <Spinner color='primary' size={"lg"} />
                            <div>
                                <p className='text-secondary mt-2 mb-0'>Loading...</p>
                            </div>
                        </div>
                    </div>
                )}
            </ModalBody>
        </Modal>
    )
}

export default ModalConfirmation