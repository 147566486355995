import { PRODUCT_ACTION_TYPE } from "./ActionType";

export const reducerProduct = (state, action) => {
    let copy_array = [...state, action.payload]
    switch (action.type) {
        case PRODUCT_ACTION_TYPE.ADD_PRODUCTS:
            let selected = action.payload.map(item => {
                return {
                    ...item.item,
                    quantity: item.quantity,
                    disabled: true,
                }
            })
            return selected
        case PRODUCT_ACTION_TYPE.ADD_CARD:
            let new_array = copy_array.map(item => {
                return {
                    ...item,
                    disabled: true,
                    quantity: !item.quantity ? 1 : item.quantity
                }
            })
            return new_array
        case PRODUCT_ACTION_TYPE.INCREASE_ITEM:
            let increase = state.map(x => {
                if (x.id === action.payload.id) {
                    return {
                        ...x,
                        quantity: x.quantity + 1
                    }
                }
                return x
            })
            return increase
        case PRODUCT_ACTION_TYPE.INSERT_ITEM:
            let insert = state.map(x => {
                if (x.id === action.payload.id) {
                    return {
                        ...x,
                        quantity: action.payload.quantity
                    }
                }
                return x
            })
            return insert
        case PRODUCT_ACTION_TYPE.DECREASE_ITEM:
            let decrease = state.map(x => {
                if (x.id === action.payload.id) {
                    return {
                        ...x,
                        quantity: x.quantity - 1,
                    }
                }
                return x
            })
            return decrease
        case PRODUCT_ACTION_TYPE.REMOVE_ITEM:
            let remove = state.filter(x => x.id !== action.payload.id)
            return remove
        case PRODUCT_ACTION_TYPE.RESET_ITEM:
            return action.payload
        default:
    }
}