import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Nav, NavItem, NavLink, Row, Spinner, Button } from 'reactstrap';
import classNames from 'classnames';
import { TEXT_ALL_INVOICE } from '../../../Text';
import { get } from '../../../helpers/API';
import { handleStatusStyle, converPriceWithCommas } from '../../../store/CustomFunctions';
import moment from 'moment';
import ServerSidePaginations from '../../../components/Table/ServerSidePaginations';
// import useLocalStorage from '../../../helpers/useLocalStorage';
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext';
// import useLocalStorage from '../../../helpers/useLocalStorage';
import ModalFilters from './ModalFilters';
import AlertError from "../../CustomComponent/Alert/AlertError"
import qs from 'qs'

const TabInvoice = [
    {
        key: 'All',
        name: 'All'
    }
]

let initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: '',
    limit: 30,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

const obj = {
    path: '/all-invoice',
    title: "Invoice",
    filter: "Invoice"
}

const initialDataTable = {
    columns: [
        {
            label: "Invoice Info",
            field: "order_name",
            sort: "asc",
        },
        {
            label: "Total (RM)",
            field: "total",
            sort: "asc",
            width: 150
        },
        {
            label: "Checked By",
            field: "checkedBy",
            sort: "asc",
            width: 200
        },
        {
            label: "Approved By",
            field: "approvedBy",
            sort: "asc",
            width: 200
        },
        {
            label: "Rejected By",
            field: "rejectedBy",
            sort: "asc",
            width: 200
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150
        },]
}

const AllInvoice = (props) => {
    const { globalData } = useContext(GlobalContext)
    const [visibleModal, setVisibleModal] = useState(false)
    const [activeTab, setActiveTab] = useState('All')
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [loading, setLoading] = useState(() => false)
    const [initialParams, setInitialParams] = useState(initialValue);
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [userRole, setUserRole] = useState(false)
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ''
    })

    const memorizedRole = globalData.global.role;

    const handleUserRole = useCallback(() => {

        if (!memorizedRole) {
            return;
        }

        switch (memorizedRole) {
            case 'corporate_admin':
                setUserRole(true)
                break;
            case 'corporate_admin_n_pharmacist':
                setUserRole(true)
                break;
            case 'corporate_hq':
                setUserRole(true)
                break;
            case 'administrator':
                setUserRole(true)
                break;
            default:
                setUserRole(false)
        }

    }, [memorizedRole])

    useEffect(() => {
        const controller = new AbortController()
        handleUserRole()
        return () => {
            controller.abort()
        }
    }, [handleUserRole])

    const getStatus = (_userType, order) => {
        var statusOrder = ""
        switch (_userType) {
            case 'corporate_admin':
                statusOrder = order.status
                break;
            case 'corporate_clinic':
                if (order.status === 'approved' || order.status === 'rejected') {
                    statusOrder = order.status
                } else {
                    if (order.clinic_status !== "submitted") {
                        statusOrder = 'draft'
                    } else {
                        if (order.hq_status === 'approved') {
                            statusOrder = 'waiting_for_approval'
                        } else if (order.hq_status === 'rejected') {
                            statusOrder = 'rejected'
                        } else {
                            statusOrder = 'submitted'
                        }
                    }
                }
                break;
            case 'corporate_hq':
                if (order.status === 'approved' || order.status === 'rejected') {
                    statusOrder = order.status
                } else {
                    if (order.clinic_status === "submitted") {
                        if (order.hq_status === 'approved') {
                            statusOrder = order.hq_status
                        } else if (order.hq_status === 'rejected') {
                            statusOrder = order.hq_status
                        } else {
                            statusOrder = 'waiting_for_approval'
                        }
                    }
                }
                break;
            case 'corporate_pharmacist':
                if (order.status === 'approved' || order.status === 'rejected') {
                    statusOrder = order.status
                } else {
                    if (order.clinic_status === 'submitted' && order.hq_status === 'pending') {
                        statusOrder = 'waiting_for_approval'
                    } else if (order.clinic_status === 'submitted') {
                        statusOrder = 'waiting_for_approval'
                    }
                }
                break;
            case 'corporate_dental':
                if (order.status === 'approved' || order.status === 'rejected') {
                    statusOrder = order.status
                } else {
                    if (order.clinic_status !== "submitted") {
                        statusOrder = 'draft'
                    } else {
                        if (order.hq_status === 'approved') {
                            statusOrder = 'waiting_for_approval'
                        } else if (order.hq_status === 'rejected') {
                            statusOrder = 'rejected'
                        } else {
                            statusOrder = 'submitted'
                        }
                    }
                }
                break;
            case 'corporate_admin_n_pharmacist':
                if (order.status !== "pending") {
                    statusOrder = order.status
                } else {
                    if (!order.pharmacy_status && order.clinic_status) {
                        if (order.clinic_status === "draft") {
                            statusOrder = order.clinic_status
                        } else {
                            if (order.clinic_status === 'submitted' && (order.hq_status && order.hq_status === 'pending')) {
                                statusOrder = 'waiting_for_approval_from_hq'
                            } else if (order.clinic_status === 'submitted' && (order.hq_status && order.hq_status === 'rejected')) {
                                statusOrder = 'rejected'
                            } else if (order.clinic_status === 'submitted' && (order.hq_status && order.hq_status === 'approved')) {
                                statusOrder = 'waiting_for_approval'
                            }
                        }
                    } else {
                        if (order.pharmacy_status === "draft") {
                            statusOrder = order.pharmacy_status
                        } else {
                            if (order.pharmacy_status === 'pending') {
                                statusOrder = 'waiting_for_approval'
                            }
                        }
                    }
                }
                break;
            default:
                statusOrder = order.status
                break;
        }
        return statusOrder
    }

    const getInvoice = useCallback(async (
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords,
    ) => {
        try {
            if (globalData.global.user && globalData.global.role) {
                setLoading(true);
                setErrMsg({
                    visible: false,
                    msg: ''
                })

                setDataSource(prev => {
                    return {
                        ...prev,
                        rows: [{
                            order_name: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                        }]
                    }
                })

                const userData = globalData.global.user;
                const user_corporate_organisation = userData && userData.corporate_organisation;
                const userRole = globalData.global.role;

                const filterTableByAccClinic = (userRole === "corporate_clinic" || userRole === "corporate_dental" || userRole === "corporate_pharmacist");

                let arrayQeury = [];

                if (keywords) {
                    arrayQeury.push({ order_name_contains: keywords })
                }

                if (dateFrom) {
                    const date = moment(dateFrom).toISOString()
                    arrayQeury.push({ createdAt_gt: date })
                }

                if (dateTo) {
                    const date = moment(dateTo).toISOString()
                    arrayQeury.push({ createdAt_lt: date })
                }

                if (filterTableByAccClinic && user_corporate_organisation) {
                    arrayQeury.push({ corporate_customer: user_corporate_organisation.id })
                }

                if (!filterTableByAccClinic && selectedBranch) {
                    arrayQeury.push({ corporate_customer: selectedBranch.value })
                }

                if (!filterTableByAccClinic && !selectedBranch) {
                    arrayQeury.push({
                        corporate_customer_null: "false"
                    })
                }

                if (userRole === "corporate_hq") {
                    arrayQeury.push({ clinic_status: 'submitted' }, { hq_status_ne: ['draft', 'pending'] })
                } else if (userRole === "corporate_pharmacist" || userRole === "corporate_admin_n_pharmacist" || userRole === "administrator" || userRole === "corporate_admin") {
                    arrayQeury.push({ clinic_status: 'submitted' }, { hq_status_ne: ['draft', 'pending'] })
                } else if (userRole === "corporate_dental") {
                    // arrayQeury.push({ clinic_status: "submitted" })
                } else if (userRole === "corporate_clinic") {
                    // arrayQeury.push({ clinic_status: "submitted" })
                }
                const query = qs.stringify({
                    _sort: sortBy,
                    _start: page === 0 ? page : (page * limit) - limit,
                    _where: arrayQeury
                });
                const count = await get(`/clinic-orders/count?${query}`);
                const query2 = qs.stringify({
                    _sort: sortBy,
                    _limit: limit,
                    _start: page === 0 ? page : (page * limit) - limit,
                    _where: arrayQeury
                });
                const response = await get(`/clinic-orders?${query2}`);

                if (count === 0) return setDataSource(prev => {
                    return {
                        ...prev,
                        rows: []
                    }
                });

                const mapping = response.map(order => {

                    const order_name = <div className=''>
                        <p className='mb-0 text-muted small'>{!order.order_name ? "" : order.order_name}</p>
                        <h6 className='mb-0'>{!order.corporate_customer ? "-" : order.corporate_customer.org_name}</h6>
                        <p className='mb-0 text-muted small'>Submitted on: {!order.createdAt ? '-' : moment(order.createdAt).format('DD/MM/YYYY')}</p>
                    </div>

                    const total = <div>
                        <p className='mb-0'>{!order.total_price ? "-" : converPriceWithCommas(order.total_price)}</p>
                    </div>

                    const status = order.status && <div className={handleStatusStyle(getStatus(userRole, order)).badgeClass}>{handleStatusStyle(getStatus(userRole, order)).text}</div>

                    const clinicInfo = order.record_status.find(x => x.role === 'hq');
                    const checkData = !clinicInfo ? order.checked_by : order.checked_by;

                    const checkedBy = checkData && <div>
                        <h6 className='mb-0'>{!checkData ? "-" : `${checkData.username}`}</h6>
                        <p className='mb-0 text-muted small'>Approved on: {!checkData ? "-" : `${moment(checkData.datetime).format('DD/MM/YYYY')}`}</p>
                    </div>

                    const hqInfo = order.record_status && order.record_status.length > 0 && order.record_status.find(x => x.role === 'hq');
                    const pharmacyInfo = order.record_status && order.record_status.length > 0 && order.record_status.find(x => x.role === 'pharmacy');

                    const approvedBy = pharmacyInfo && pharmacyInfo.status === "approved" && <div>
                        <h6 className='mb-0'>{!pharmacyInfo.user ? "-" : `${pharmacyInfo.user.username}`}</h6>
                        <p className='mb-0 text-muted small'>Approved on: {!pharmacyInfo ? "-" : `${moment(pharmacyInfo.datetime).format('DD/MM/YYYY')}`}</p>
                    </div>

                    let rejectedBy = '-';
                    if (hqInfo && hqInfo.status === 'rejected') {
                        rejectedBy = hqInfo && hqInfo.status === "rejected" && <div>
                            <h6 className='mb-0'>{!hqInfo.user ? "-" : `${hqInfo.user.username}`}</h6>
                            <p className='mb-0 text-muted small'>Rejected on: {!hqInfo ? "-" : `${moment(hqInfo.datetime).format('DD/MM/YYYY')}`}</p>
                        </div>
                    } else {
                        rejectedBy = pharmacyInfo && pharmacyInfo.status === "rejected" && <div>
                            <h6 className='mb-0'>{!pharmacyInfo.user ? "-" : `${pharmacyInfo.user.username}`}</h6>
                            <p className='mb-0 text-muted small'>Rejected on: {!pharmacyInfo ? "-" : `${moment(pharmacyInfo.datetime).format('DD/MM/YYYY')}`}</p>
                        </div>
                    }
                    return {
                        order_name,
                        total,
                        checkedBy,
                        approvedBy,
                        rejectedBy,
                        status,
                        clickEvent: () => {
                            if (props.history !== undefined || props.history !== null) {
                                return props.history.push({ pathname: `/all-invoice/${order.id}`, state: { title: order.order_name } })
                            }
                        }
                    }
                })

                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    total: count,
                    page,
                    category,
                    keywords
                })

                setLoading(false)
                setDataSource(prev => {
                    return {
                        ...prev,
                        rows: mapping
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            setErrMsg({
                visible: false,
                msg: error,
            })
        }


    }, [props.history, globalData.global.user, globalData.global.role, selectedBranch, dateFrom, dateTo])

    useEffect(() => {
        const controller = new AbortController()
        let mount = false;

        if (!mount) {
            getInvoice(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                initialValue.keywords
            )
        }

        return () => {
            mount = true
            controller.abort()
        }
    }, [getInvoice])

    let timeout = 0
    const handleSearching = (value) => {
        if (timeout) clearTimeout(timeout)
        let keyword = value;

        timeout = setTimeout(() => {
            getInvoice(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                keyword
            )
        }, 1000);
    }

    return (
        <React.Fragment>
            {!globalData.global.user ? <div className="page-content">Loading...</div> :
                <div className="page-content">
                    <Container fluid>

                        <h4>{TEXT_ALL_INVOICE}</h4>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="pt-0">

                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            {TabInvoice.map((item, index) => {
                                                return (
                                                    <NavItem key={index}>
                                                        <NavLink onClick={() => { setActiveTab(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3")}>{item.name}</NavLink>
                                                    </NavItem>
                                                )
                                            })}

                                            <div className='constainer-box-actions'>
                                                <div className="custom-searching-box">
                                                    <Input
                                                        style={{ width: 300 }}
                                                        type="text"
                                                        placeholder="Invoice No. : e.g. 000000001"
                                                        onChange={e => handleSearching(e.target.value)}
                                                    />
                                                    <i className="ri-search-line"></i>
                                                </div>

                                            </div>
                                        </Nav>

                                        {userRole || selectedBranch || dateFrom || dateTo ? <div className='d-flex align-items-center justify-content-start mb-4'>
                                            {userRole ? (<Button color='light' onClick={() => { setVisibleModal(!visibleModal) }} className="mr-2"><i className="fas fa-filter mr-2"></i>Filters</Button>) : null}
                                            {selectedBranch ?
                                                <div style={{ background: '#e0e3ff' }} className='px-3 py-2 text-primary shadow d-flex justify-content-between rounded mr-2 is-cursor border border-primary'>
                                                    <p className='mb-0'>Branch: <b>{selectedBranch.label}</b></p>
                                                    <p className='mb-0' onClick={() => { setSelectedBranch(null) }}> <i className='ml-2 fas fa-times' /></p>
                                                </div> :
                                                null}
                                            {dateFrom ?
                                                <div style={{ background: '#e0e3ff' }} className='px-3 py-2 text-primary shadow d-flex justify-content-between rounded mr-2 is-cursor border border-primary'>
                                                    <p className='mb-0'>From: <b>{moment(dateFrom).format('DD/MM/YYYY')}</b></p>
                                                    <p className='mb-0' onClick={() => { setDateFrom('') }}> <i className='ml-2 fas fa-times' /></p>
                                                </div> :
                                                null}
                                            {dateTo ?
                                                <div style={{ background: '#e0e3ff' }} className='px-3 py-2 text-primary shadow d-flex justify-content-between rounded mr-2 is-cursor border border-primary'>
                                                    <p className='mb-0'>To: <b>{moment(dateTo).format('DD/MM/YYYY')}</b></p>
                                                    <p className='mb-0' onClick={() => { setDateTo('') }}> <i className='ml-2 fas fa-times' /></p>
                                                </div> :
                                                null}
                                        </div> : null}

                                        <ServerSidePaginations
                                            loading={loading}
                                            dataSource={dataSource}
                                            getItems={(params) => {
                                                getInvoice(
                                                    params.sortBy,
                                                    params.orderBy,
                                                    params.limit,
                                                    params.page,
                                                    params.total,
                                                    params.category,
                                                    params.keywords
                                                )
                                            }}
                                            emptyMessage="There are no provider yet"
                                            obj={obj}
                                            initialParams={initialParams}
                                            setLoading={setLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                    <AlertError
                        title="Error!"
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                    />
                    <ModalFilters
                        dataSource={dataSource}
                        visibleModal={visibleModal}
                        selectedBranch={selectedBranch}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        toggleModal={() => setVisibleModal(!visibleModal)}
                        handleSubmit={(form) => {
                            setDateFrom(form.from)
                            setDateTo(form.to)
                            setSelectedBranch(form.branch)
                        }}
                        clear={() => {
                            setSelectedBranch(null)
                            setDateFrom('')
                            setDateTo('')
                        }}
                    />
                </div>}
        </React.Fragment>
    )
}

export default AllInvoice
