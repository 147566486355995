import React from 'react'
import { TEXT_DEGREE, TEXT_DIPLOMA, TEXT_OTHERS, TEXT_POST_BASIC, TEXT_QUALIFICATION } from '../../Text'

const Qualification = ({ dataSource, handleViewFile, selectedFile, checkingFileName, handleSubmission, changeHandler }) => {
    return (
        <>
            <dt className="col-sm-3">{TEXT_QUALIFICATION}</dt>
            <dd className="col-sm-9">
                {!dataSource.qualification ? "-" :
                    <>
                        <div className="pb-2" >
                            <div className="wrapper-text">
                                <span className="title">{TEXT_DIPLOMA}:</span>
                                <div className="box-1">
                                    <span className={!dataSource.qualification.diploma_cert_file ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_diploma_cert.loading ? "Uploading..." : checkingFileName(dataSource.qualification.diploma_cert_file, selectedFile.is_diploma_cert)}</span>
                                    {dataSource.qualification.diploma_cert_file &&
                                        <button
                                            disabled={!dataSource.qualification.diploma_cert_file}
                                            className="btn btn-sm btn-light ml-2"
                                            onClick={() => handleViewFile(dataSource.qualification.diploma_cert_file.url, TEXT_DIPLOMA)}>
                                            <i className="ri-eye-line"></i>
                                        </button>}
                                </div>
                                <div className="wrapper">
                                    <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                        <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'qualification', 'is_diploma_cert', 'diploma_cert_file', TEXT_QUALIFICATION)} className="input-hide" />
                                        <i className=" ri-attachment-2" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="pb-2" >
                            <div className="wrapper-text">
                                <span className="title">{TEXT_DEGREE}:</span>
                                <div className="box-1">
                                    <span className={!dataSource.qualification.degree_cert_file ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_degree_cert.loading ? "Uploading..." : checkingFileName(dataSource.qualification.degree_cert_file, selectedFile.is_degree_cert)}</span>
                                    {dataSource.qualification.degree_cert_file &&
                                        <button
                                            disabled={!dataSource.qualification.degree_cert_file}
                                            className="btn btn-sm btn-light ml-2"
                                            onClick={() => handleViewFile(dataSource.qualification.degree_cert_file.url, TEXT_DEGREE)}>
                                            <i className="ri-eye-line"></i>
                                        </button>}
                                </div>
                                <div className="wrapper">
                                    <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                        <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'qualification', 'is_degree_cert', 'degree_cert_file', TEXT_QUALIFICATION)} className="input-hide" />
                                        <i className=" ri-attachment-2" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="pb-2" >
                            <div className="wrapper-text">
                                <span className="title">{TEXT_POST_BASIC}:</span>
                                <div className="box-1">
                                    <span className={!dataSource.qualification.post_basic_cert_file ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_post_basic_cert.loading ? "Uploading..." : checkingFileName(dataSource.qualification.post_basic_cert_file, selectedFile.is_post_basic_cert)}</span>
                                    {dataSource.qualification.post_basic_cert_file &&
                                        <button
                                            disabled={!dataSource.qualification.post_basic_cert_file}
                                            className="btn btn-sm btn-light ml-2"
                                            onClick={() => handleViewFile(dataSource.qualification.post_basic_cert_file.url, TEXT_POST_BASIC)}>
                                            <i className="ri-eye-line"></i>
                                        </button>}
                                </div>
                                <div className="wrapper">
                                    <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                        <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'qualification', 'is_post_basic_cert', 'post_basic_cert_file', TEXT_QUALIFICATION)} className="input-hide" />
                                        <i className=" ri-attachment-2" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="pb-2" >
                            <div className="wrapper-text">
                                <span className="title">{TEXT_OTHERS}:</span>
                                <div className="box-1">
                                    <span className={!dataSource.qualification.others_file ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_others_cert.loading ? "Uploading..." : checkingFileName(dataSource.qualification.others_file, selectedFile.is_others_cert)}</span>
                                    {dataSource.qualification.others_file &&
                                        <button
                                            disabled={!dataSource.qualification.others_file}
                                            className="btn btn-sm btn-light ml-2"
                                            onClick={() => handleViewFile(dataSource.qualification.others_file.url, TEXT_OTHERS)}>
                                            <i className="ri-eye-line"></i>
                                        </button>}
                                </div>
                                <div className="wrapper">
                                    <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                        <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'qualification', 'is_others_cert', 'others_file', TEXT_QUALIFICATION)} className="input-hide" />
                                        <i className=" ri-attachment-2" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>}
            </dd>
        </>
    )
}

export default Qualification
