import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Input } from 'reactstrap'
import Select from 'react-select'
import { get } from '../../../helpers/API'
import moment from 'moment'

const ModalFilters = ({ visibleModal, toggleModal, handleSubmit, clear, dataSource, dateFrom, dateTo, corporate }) => {
    const [list, setList] = useState([])
    const [typeFrom, setTypeFrom] = useState('text')
    const [typeTo, setTypeTo] = useState('text')
    const [isLoading, setLoading] = useState(true)
    const [form, setForm] = useState({
        branch: null,
        from: '',
        to: ''
    })

    useEffect(() => {
        const controller = new AbortController()
        if (dateFrom || dateTo || corporate) {
            // console.log('run');
            setTypeFrom(dateFrom === '' ? 'text' : 'date')
            setTypeTo(dateTo === '' ? 'text' : 'date')
            setForm(prev => {
                return {
                    from: dateFrom === '' ? '' : prev.from,
                    to: dateTo === '' ? '' : prev.to,
                    branch: corporate === null ? null : prev.branch,
                }
            })
        }
        return () => {
            controller.abort()
        }
    }, [dateFrom, dateTo, corporate])

    useEffect(() => {
        function fetch() {
            get('/corporate-organisations').then(res => {
                setLoading(false)
                setList(res.filter(x => x.org_name).filter(x => x.org_name.includes('Selcare')).map(x => {
                    return {
                        label: x.org_name,
                        value: x.id
                    }
                }));
            })
        }

        if (dataSource && dataSource.rows && dataSource.rows.length > 1 && list.length === 0) {
            fetch()
        }
    }, [dataSource, list])

    const substractDate = (date) => {
        return moment(date).subtract(1, 'day').format('YYYY-MM-DD')
    }

    const addDate = (date) => {
        return moment(date).add(1, 'day').format('YYYY-MM-DD')
    }

    return (
        <Modal
            className="submit-modal"
            isOpen={visibleModal}
            centered={true}
        >
            <ModalHeader>Filters</ModalHeader>
            <ModalBody>
                <div className='mb-3'>
                    <p className='mb-0'>Branch Name</p>
                    <Select
                        disabled={isLoading}
                        value={form.branch}
                        isLoading={isLoading}
                        options={list}
                        placeholder="Select ranch Name..."
                        onChange={(value) => {
                            setForm({
                                ...form,
                                branch: value
                            })
                        }}
                    />
                </div>
                <div className='mb-3'>
                    <p className='mb-0'>From</p>
                    <Input
                        type={typeFrom}
                        value={form.from}
                        className={isLoading ? 'disabled' : ''}
                        placeholder={`Select Date: e.g ${moment().format('DD//MM/YYYY')}`}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                from: e.target.value
                            })
                        }}
                        onFocus={() => setTypeFrom('date')}
                        max={!form.to ? null : substractDate(form.to)}
                    />
                </div>
                <div className='mb-3'>
                    <p className='mb-0'>To</p>
                    <Input
                        type={typeTo}
                        value={form.to}
                        className={isLoading ? 'disabled' : ''}
                        placeholder={`Select Date: e.g ${moment().format('DD//MM/YYYY')}`}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                to: e.target.value
                            })
                        }}
                        onFocus={() => setTypeTo('date')}
                        min={!form.from ? null : addDate(form.from)}
                    />
                </div>

                <div className='d-flex justify-content-between'>
                    <Button color='danger' onClick={() => {
                        clear();
                        setForm({
                            branch: null,
                            from: '',
                            to: ''
                        })
                    }}>Clear</Button>
                    <div>
                        <Button color='light' className='mr-2' onClick={() => {
                            toggleModal();
                        }}>Close</Button>
                        <Button color='primary' onClick={() => {
                            toggleModal();
                            handleSubmit(form)
                        }}>Add Filter</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalFilters