import moment from "moment";
import { TEXT_APPROVED, TEXT_REJECT, TEXT_REJECTED } from "../Text";

export const converPriceWithCommas = (price) => {
    let number = "0.00"
    if (price !== undefined && price !== null) {
        return price.toLocaleString(undefined, { minimumFractionDigits: 2 })
    } else {
        return number
    }
}


export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const handleStatusStyle = (status) => {
    let obj = {}
    switch (status) {
        case 'processing':
            obj = {
                status: 'processing',
                color: 'rgba(235, 239, 242, 1)',
                class: 'btn btn-soft-dark',
                badgeClass: 'badge badge-pill badge-soft-dark font-size-12',
                text: 'New',
                text2: 'New'
            }
            break;
        case 'approved':
            obj = {
                status: 'approved',
                color: 'rgba(25, 171, 79, 1)',
                class: 'btn btn-success',
                badgeClass: 'badge badge-pill badge-soft-success font-size-12',
                text: TEXT_APPROVED,
                text2: TEXT_APPROVED
            }
            break;
        case 'fulfilled':
            obj = {
                status: 'fulfilled',
                color: 'rgba(25, 171, 79, 1)',
                class: 'btn btn-success',
                badgeClass: 'badge badge-pill badge-soft-success font-size-12',
                text: 'Delivered',
                text2: 'Delivered'
            }
            break;
        case 'paid':
            obj = {
                status: 'paid',
                color: 'rgba(25, 171, 79, 1)',
                class: 'btn btn-success',
                badgeClass: 'badge badge-pill badge-soft-success font-size-12',
                text: 'Paid',
                text2: 'Paid'
            }
            break;
        case 'unfulfilled':
            obj = {
                status: 'unfulfilled',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-soft-warning font-size-12',
                text: 'Processing',
                text2: 'Processing'
            }
            break;
        case 'not_paid':
            obj = {
                status: 'not_paid',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-danger',
                badgeClass: 'badge badge-pill badge-soft-danger font-size-12',
                text: 'Unpaid',
                text2: 'Unpaid'
            }
            break;
        case 'rejected':
            obj = {
                status: 'rejected',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-danger',
                badgeClass: 'badge badge-pill badge-soft-danger font-size-12',
                text: TEXT_REJECT,
                text2: TEXT_REJECTED
            }
            break;
        case 'kiv':
            obj = {
                status: 'kiv',
                color: 'RGBA(74,163,255,1)',
                class: 'btn btn-info',
                badgeClass: 'badge badge-pill badge-soft-info font-size-12',
                text: 'KIV',
                text2: 'Kiv'
            }
            break;
        case 'completed':
            obj = {
                status: 'completed',
                color: 'rgba(25, 171, 79, 1)',
                class: 'btn btn-success',
                badgeClass: 'badge badge-pill badge-soft-success font-size-12',
                text: 'Completed',
                text2: 'Completed'
            }
            break;
        case 'pending':
            obj = {
                status: 'pending',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-soft-warning font-size-12',
                text: 'Pending',
                text2: 'Pending'
            }
            break;
        case 'waiting':
            obj = {
                status: 'waiting',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-warning font-size-12',
                text: 'Waiting',
                text2: 'Waiting'
            }
            break;
        case 'validation_error':
            obj = {
                status: 'validation_error',
                // color: 'rgba(235, 239, 242, 1)',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-danger',
                badgeClass: 'badge badge-pill badge-danger font-size-12',
                text: 'Need Attention',
                text2: 'Need Attention'
            }
            break;
        case 'in_delivery':
            obj = {
                status: 'in_delivery',
                // color: 'rgba(235, 239, 242, 1)',
                color: 'RGBA(74,163,255,1)',
                class: 'btn btn-info',
                badgeClass: 'badge badge-pill badge-soft-info font-size-12',
                text: 'In Delivery',
            }
            break;
        case 'draft':
            obj = {
                status: 'draft',
                // color: 'rgba(235, 239, 242, 1)',
                color: '#74788d',
                class: 'btn btn-secondary',
                badgeClass: 'badge badge-pill badge-soft-secondary font-size-12',
                text: 'Draft',
            }
            break;
        case 'submitted':
            obj = {
                status: 'submitted',
                color: 'RGBA(74,163,255,1)',
                class: 'btn btn-info',
                badgeClass: 'badge badge-pill badge-soft-info font-size-12',
                text: 'Submitted',
                text2: 'Submitted'
            }
            break;
        case 'waiting_for_approval':
            obj = {
                status: 'waiting_for_approval',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-warning font-size-12',
                text: 'Waiting for approval',
                text2: 'Waiting for approval'
            }
            break;
        case 'waiting_for_approval_from_hq':
            obj = {
                status: 'waiting_for_approval_from_hq',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-warning font-size-12',
                text: 'Waiting for approval from HQ',
                text2: 'Waiting for approval from HQ'
            }
            break;
        case 'approved_by_hq':
            obj = {
                status: 'approved_by_hq',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-success',
                badgeClass: 'badge badge-pill badge-success font-size-12',
                text: 'Approved by HQ',
                text2: 'Approved by HQ'
            }
            break;
        case 'rejected_by_hq':
            obj = {
                status: 'rejected_by_hq',
                color: 'rgba(252, 185, 44, 1)',
                class: 'btn btn-danger',
                badgeClass: 'badge badge-pill badge-danger font-size-12',
                text: 'Rejected by HQ',
                text2: 'Rejected by HQ'
            }
            break;
        case 'cancelled':
            obj = {
                status: 'cancelled',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-danger',
                badgeClass: 'badge badge-pill badge-soft-danger font-size-12',
                text: TEXT_REJECT,
                text2: TEXT_REJECTED
            }
            break;
        case 'partially_completed':
            obj = {
                status: 'partially_completed',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-soft-warning font-size-12',
                text: 'Partially Completed',
                text2: 'Partially Completed'
            }
            break;
        case 'accepted':
            obj = {
                status: 'accepted',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-warning',
                badgeClass: 'badge badge-pill badge-soft-warning font-size-12',
                text: 'On going',
                text2: 'On going'
            }
            break;
        default:
            obj = {
                status: 'new',
                // color: 'rgba(235, 239, 242, 1)',
                color: 'rgba(255, 61, 96, 1)',
                class: 'btn btn-danger',
                badgeClass: 'badge badge-pill badge-danger font-size-12',
                text: 'New',
                text2: 'New'
            }
            break;
    }
    return obj
}

export const capitalizeEveryLetter = string => {
    if (!string) return string;

    return string.toLowerCase().split(" ")
        .map(x => capitalizeFirstLetter(x.toLowerCase()))
        .join(" ");
}

export const capitalizeFirstLetter = string => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTemplateInvoiceOrder = (invoiceCode, num, clinicName) => {
    const year = moment().format("YYYY")
    const separator = clinicName.includes('Selcare Dental Hq') ? '/' : '-';
    const result = `${!invoiceCode ? '' : `${invoiceCode}${separator}${year}`}`
    return `${result}${!invoiceCode ? '' : separator}${num}`
}