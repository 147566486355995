export const initialState = {
    token: null,
    global: {
        notification: {
            visibility: false,
            list: null
        },
        certInfo: null,
        signature: null,
        nursing_management: {
            applications: {
                provider: {
                    count: 0
                }
            },
            booking: {
                nurse: {
                    count: 0
                },
                physio: {
                    count: 0
                },
                medical_assistant: {
                    count: 0
                },
                care_aide: {
                    count: 0
                },
                confinement: {
                    count: 0
                }
            }
        },
        tele_medicine: {
            count: 0
        },
        image: null,
        user: null,
        role: null,
        availability_doctor: false,
        visibility_menu_sidebar: {
            appointment: false,
            dashboard: false,
            manage: false,
            ecommerce: false,
            invoice: false,
            store: false,
            tele_medicine: false,
            prescription: {
                title: false,
                patient: false,
                createNew: false,
                waitingForApproval: false
            },
            setting: {
                store: false,
                account: true
            }
        }
    }
}