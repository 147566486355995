import React, { useEffect, useMemo, useReducer, useState } from 'react'
import Select from 'react-select';
import { Button, CardBody, CardImg, FormGroup, Input, InputGroup, InputGroupAddon, Label, Table, UncontrolledTooltip } from 'reactstrap'
import { get, getAPIroot, post, update } from '../../../../../../../helpers/API';
import { capitalizeFirstLetter } from '../../../../../../../store/CustomFunctions';
import { TEXT_CANCEL, TEXT_GENDER, TEXT_LANGUAGE, TEXT_SAVE } from '../../../../../../../Text';
import { ACTION_TYPE } from '../../../Store/ActionType';
import { optionGender, optionLanguage } from '../../../Store/Option';
import ModalProduct from './ModalProduct';
import { reducerProduct } from './store/ReducerProduct';
import { PRODUCT_ACTION_TYPE } from './store/ActionType'
import { Link } from 'react-router-dom';

const Medical = ({ data, setSweetTimmer, sweetTimmer, isEditMode, dispatch, setFormValues, dataSource, activeTab }) => {
    const [temporary, setTemporary] = useState({
        ...data,
    })
    const [visibleModal, setVisibleModal] = useState(false)
    const [selectedProduct, dispatchProduct] = useReducer(reducerProduct, [])
    const [loading, setLoading] = useState(false)
    const [loadingC, setLoadingC] = useState(false)
    const [listProduct, setListProduct] = useState([])
    const [listCategory, setCategory] = useState([])
    const [listOfTable, setListOfTable] = useState([])
    const [visiblePagination, setVisiblePagination] = useState(true)
    const [initialParams, setInitialParams] = useState({
        limit: 8,
        total: 0,
        page: 1,
        category: '',
        keywords: ''
    })


    const handleModal = () => {
        setVisibleModal(!visibleModal)
    }

    const isRequiredMedical = useMemo(() => {
        return {
            isRequired: temporary.language_requirement ? true : temporary.gender_requirement ? true : false
        }
    }, [temporary.language_requirement, temporary.gender_requirement])

    useEffect(() => {
        setTemporary(prev => { return { ...prev, required: isRequiredMedical.isRequired } })
    }, [isRequiredMedical])

    useEffect(() => {
        getCategory()
    }, [])

    const getCategory = () => {
        setLoadingC(true)
        get(`/categories`).then(res => {
            setCategory(res)
            setLoadingC(false)
        }).catch(err => { })
    }

    useEffect(() => {
        getProducts(1, 8, '', '')
    }, [])

    useEffect(() => {
        dispatchProduct({
            type: PRODUCT_ACTION_TYPE.ADD_PRODUCTS,
            payload: data.medical_supplies
        })


        let selected_array = data.medical_supplies.map(item => {
            return {
                ...item.item,
                quantity: item.quantity,
                disabled: true,
            }
        })

        setListOfTable(selected_array)
    }, [data.medical_supplies])


    const getProducts = (page, limit, category, keywords) => {
        setLoading(true)
        let params = ''
        let url = new URLSearchParams(params)
        url.set('_limit', limit)
        url.set('_start', page)
        url.set('_sort', 'createdAt:DESC')
        if (category) {
            url.set('category', category)
        }

        if (keywords) {
            url.set('keywords', keywords)
        }

        get(`/products?${url.toString()}`).then(res => {
            setListProduct(res.products)
            setLoading(false)
            setInitialParams(prev => {
                return {
                    total: res.count,
                    limit,
                    page,
                    category,
                    keywords
                }
            })
        }).catch(err => console.log(err))
    }

    const handleAddToCart = (item) => {
        const copy_array = [...listProduct]

        let new_array = copy_array.map(x => {
            if (x.id === item.id) {
                return {
                    ...x,
                    disabled: true
                }
            }
            return x
        })
        setListProduct(new_array)
        dispatchProduct({
            type: PRODUCT_ACTION_TYPE.ADD_CARD,
            payload: item
        })
    }

    const decreaseQuantity = (item) => {
        if (item.quantity < 2) {
            let remove = listOfTable.filter(x => x.id !== item.id)
            setListOfTable(remove)
            dispatchProduct({
                type: PRODUCT_ACTION_TYPE.REMOVE_ITEM,
                payload: item
            })
            getProducts(1, 6, initialParams.category, '')
        } else {
            let decrease = listOfTable.map(x => {
                if (x.id === item.id) {
                    return {
                        ...x,
                        quantity: x.quantity - 1
                    }
                }
                return x
            })
            setListOfTable(decrease)

            dispatchProduct({
                type: PRODUCT_ACTION_TYPE.DECREASE_ITEM,
                payload: item
            })
        }
    }

    const increaseQuantity = (item) => {
        let increase = listOfTable.map(x => {
            if (x.id === item.id) {
                return {
                    ...x,
                    quantity: x.quantity + 1
                }
            }
            return x
        })
        setListOfTable(increase)
        dispatchProduct({
            type: PRODUCT_ACTION_TYPE.INCREASE_ITEM,
            payload: item
        })
    }

    const handleSubmit = () => {
        let copy_new_medical_item = listOfTable
        let new_medical_item = copy_new_medical_item.map(item => {
            return {
                item: item.id,
                quantity: item.quantity
            }
        })

        let newData = {
            medical_supplies: new_medical_item,
            gender_requirement: temporary.gender_requirement,
            language_requirement: temporary.language_requirement
        }

        update(`/booking-forms/${data.id}`, newData).then(res => {
            setFormValues(res)
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: res,
                    loading: false
                }
            })
            dispatch({
                type: ACTION_TYPE.ACTION_EDIT_MODE,
                payload: {
                    tab: null,
                    childeTab: null,
                    mode: !dataSource.editMode.mode
                }
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(err => { })
    }

    const countTotal = (val, q) => {
        let price = val
        let quantity = q;

        let sum = price * quantity;

        return sum.toFixed(2)
    }

    const handleCollection = () => {
        // console.log(selectedProduct);
        setListOfTable(selectedProduct)
    }
    let timeout = 0
    const handleSearhing = (value) => {
        if (timeout) clearTimeout(timeout)
        let keyword = value;
        setLoading(true)
        setTimeout(() => {
            if (keyword === "") {
                getProducts(initialParams.page, initialParams.limit, initialParams.category, keyword)
                setVisiblePagination(true)
            } else {
                setVisiblePagination(false)
                post('/products/search', { keyword: value }).then(response => {
                    setListProduct(response.products)
                    setLoading(false)
                }).catch(() => { })
            }
        }, 1000);
    }


    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button color="light" disabled={isEditMode.childeTab !== activeTab} className="waves-effect float-right mb-2" onClick={handleModal}><i className="ri-add-line mr-2 align-middle" />Add Item From Store</Button>
            </div>

            {listOfTable && listOfTable.length > 0 &&
                <Table responsive className="table-centered mb-0 table-nowrap">
                    <thead>
                        <tr>
                            <th className="border-top-0 pl-0" style={{ width: "110px" }} scope="col">Product</th>
                            <th className="border-top-0" scope="col">Product Desc</th>
                            <th className="border-top-0" scope="col">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listOfTable.map((item, index) => {
                            return (
                                item.disabled &&
                                <tr key={index}>
                                    <th scope="row" className="pl-0">{!item.variants ? item.title[0] : item.variants[0].image && item.variants[0].image.length !== 0 && <CardImg className="avatar-md pl-0" src={getAPIroot() + item.variants[0].image[0].url} />}</th>
                                    <td>
                                        <h4 className="card-title" style={{ whiteSpace: "pre-line" }}> {item.title}</h4>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            <div style={{ width: "120px" }} className="product-cart-touchspin">
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend" className="input-group-btn">
                                                        <Button disabled={isEditMode.childeTab !== activeTab} onClick={() => decreaseQuantity(item)} color="primary" className="bootstrap-touchspin-down" >-</Button>
                                                    </InputGroupAddon>

                                                    <Input type="text" value={!item.quantity ? '-' : item.quantity} readOnly />
                                                    <InputGroupAddon addonType="append" className="input-group-btn">
                                                        <Button disabled={isEditMode.childeTab !== activeTab} onClick={() => increaseQuantity(item)} color="primary" className="bootstrap-touchspin-down">+</Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </div>
                                            <Link to="#" className={`${isEditMode.childeTab !== activeTab ? 'text-light' : 'text-danger'} ml-2 disable`} id={`delete${index}`} onClick={() => {
                                                if (isEditMode.childeTab === activeTab) {
                                                    dispatchProduct({
                                                        type: PRODUCT_ACTION_TYPE.REMOVE_ITEM,
                                                        payload: item
                                                    })

                                                    let remove = listOfTable.filter(x => x.id !== item.id)
                                                    setListOfTable(remove)

                                                    getProducts(initialParams.page, initialParams.limit, initialParams.category, '')
                                                }
                                            }}><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                            <UncontrolledTooltip placement="right" target={`delete${index}`}>Delete</UncontrolledTooltip >
                                        </div>
                                    </td>
                                    <td>${!item.variants ? "-" : countTotal(item.variants[0].price, item.quantity)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>}


            <div className="mt-4">
                <h4 className="card-title">Any other requirement?</h4>
                <div className="custom-control custom-switch mb-2" dir="ltr">
                    <Input type="checkbox" className="custom-control-input" disabled={isEditMode.childeTab === activeTab ? false : true} defaultChecked={temporary.required} />
                    <Label className="custom-control-label" disabled={isEditMode.childeTab === activeTab ? false : true} onClick={(e) => {
                        if (isEditMode.childeTab === activeTab) {
                            setTemporary({ ...temporary, required: !temporary.required })
                        }
                    }}>{temporary.required ? 'Yes' : 'No'}</Label>
                </div>

                {temporary.required &&
                    <div>
                        <div>
                            <Label className="col-form-label">{TEXT_GENDER}</Label>
                            {isEditMode.childeTab !== activeTab ?
                                <p className="col-form-label text-mute">{!temporary.gender_requirement ? "-" : capitalizeFirstLetter(temporary.gender_requirement)}</p> :
                                <Select
                                    disabled={!isEditMode}
                                    options={optionGender}
                                    classNamePrefix="select2-selection"
                                    placeholder='e.g: Malay'
                                    value={!temporary.gender_requirement ? "" : { label: capitalizeFirstLetter(temporary.gender_requirement), value: temporary.gender_requirement }}
                                    onChange={e => setTemporary({ ...temporary, gender_requirement: e.value })}
                                />}
                        </div>

                        <div>
                            <Label className="col-form-label">{TEXT_LANGUAGE}</Label>
                            {isEditMode.childeTab !== activeTab ?
                                <p className="col-form-label text-mute">{!temporary.language_requirement ? "-" : capitalizeFirstLetter(temporary.language_requirement)}</p> :
                                <Select
                                    disabled={!isEditMode}
                                    options={optionLanguage}
                                    classNamePrefix="select2-selection"
                                    placeholder='e.g: English'
                                    value={!temporary.language_requirement ? "" : { label: capitalizeFirstLetter(temporary.language_requirement), value: temporary.language_requirement }}
                                    onChange={e => setTemporary({ ...temporary, language_requirement: e.value })}
                                />}
                        </div>
                    </div>}
            </div>

            <ModalProduct
                setVisibleModal={setVisibleModal}
                visibleModal={visibleModal}
                handleModal={handleModal}
                products={listProduct}
                loading={loading}
                handleAddToCart={handleAddToCart}
                selectedProduct={selectedProduct}
                initialParams={initialParams}
                getProducts={getProducts}
                listCategory={listCategory}
                loadingC={loadingC}
                dispatchProduct={dispatchProduct}
                increaseQuantity={increaseQuantity}
                decreaseQuantity={decreaseQuantity}
                handleCollection={handleCollection}
                data={data}
                setListOfTable={setListOfTable}
                listOfTable={listOfTable}
                handleSearhing={handleSearhing}
                visiblePagination={visiblePagination}
            />



            {isEditMode.tab === 'Assessment' && isEditMode.childeTab === activeTab &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" onClick={handleSubmit} color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            setTemporary(data)
                            getProducts(1, 6, initialParams.category)
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    childeTab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                            dispatchProduct({
                                type: PRODUCT_ACTION_TYPE.ADD_PRODUCTS,
                                payload: data.medical_supplies
                            })

                            let selected_array = data.medical_supplies.map(item => {
                                return {
                                    ...item.item,
                                    quantity: item.quantity,
                                    disabled: true,
                                }
                            })

                            setListOfTable(selected_array)
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>}

        </div>
    )
}

export default Medical
