import React, { useEffect, useState } from 'react'
import { Badge, Col, FormGroup, Input, Label } from 'reactstrap'

const ConfinementService = ({ confinementServices, data }) => {
    const [temporary, setTemporary] = useState(data);

    useEffect(() => {
        setTemporary(confinementServices)
        let selectedArray = [];
        if (data && data.confinement_services.length > 0) {
            const newData = data.confinement_services.filter(element => element.confinement_service).map(item => { return { ...item.confinement_service, quantity: item.quantity } })
            if (newData.length > 0) {
                confinementServices.forEach(elementA => {
                    newData.forEach(elementB => {
                        selectedArray.push({
                            ...elementA,
                            is_tick: elementA.id === elementB.id ? true : false,
                            quantity: elementA.id === elementB.id ? elementB.quantity : 0
                        })
                    })
                })
                setTemporary(selectedArray)
            }

        }
    }, [data, confinementServices])

    const handleBadge = (type) => {
        return type === 'stay_in' ? 'Stay In' : 'Daily Visit'
    }

    return temporary.length > 0 && temporary.map((confinement, index) => {
        return <FormGroup row key={index}>
            <Col md={12} className="ml-auto">
                <div className="mt-4 mt-lg-0 w-100" style={{ minHeight: 44 }}>
                    <div className="custom-control custom-checkbox custom-control-right w-100">
                        <Input type="checkbox" className="custom-control-input" checked={confinement.is_tick} onChange={() => false} />
                        <Label className="custom-control-label w-100 d-flex align-items-center">{!confinement.package_name ? "" : confinement.package_name} <Badge pill className='ml-2' color='info'>{handleBadge(confinement.package_type)}</Badge></Label>
                        {confinement.quantity > 0 && <p className='small text-muted mb-2'>Quantity: {confinement.quantity}</p>}
                        <div className='list-li'>
                            <div dangerouslySetInnerHTML={{ __html: !confinement.package_detail ? '' : confinement.package_detail }} />
                        </div>

                    </div>
                </div>
            </Col>
        </FormGroup>
    })
}

export default ConfinementService