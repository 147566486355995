export const ACTION_TYPE = {
    ACTION_GET_NURSE: "get_nurse",
    ACTION_UPDATE_FORM: 'update_form',
    ACTION_SITE_VISIT: "site_visit",
    ACTION_SET_SITE_VISIT: "set_site_visit",
    ACTION_COMPLETE_SITE_VISIT: "complete_site_visit",
    ACTION_PROCEED_PAYMENT: "proceed_payment",
    ACTION_CANCEL_SITE_VISIT: 'cancel_site_visit',
    ACTION_EDIT_MODE: "edit_form",
    SAVE_PROPS_STATE: "save_props_state"
}