import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Button, CardImg, Input, Spinner, Table, UncontrolledTooltip } from 'reactstrap';
import {
    getAPIroot,
    post,
    remove,
    update
} from '../../helpers/API';
import imgEmpty from '../../assets/images/imgEmpty.png'
import { PaginationButton } from '../../pages/CustomComponent/Pagination/PaginationButton';
import { converPriceWithCommas, formatBytes } from '../../store/CustomFunctions'
import Dropzone from 'react-dropzone';
import AlertConfirmation from '../../pages/CustomComponent/Alert/AlertConfirmation';
import AlertSuccess from '../../pages/CustomComponent/Alert/AlertSuccess';
import AlertError from '../../pages/CustomComponent/Alert/AlertError';

const styles = {
    root: {
        minHeight: 72,
        maxHeight: 72,
        width: 72,
        overflow: "hidden",
        borderColor: "none",
    },
    uploadBox: {
        padding: 0,
        position: 'relative',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        height: 68,
        margin: 0,
        borderRadius: 4,
        overflow: 'hidden'
    },
    desc: {
        textAlign: "center"
    }
}

export const ServerSideEditablePaginations = ({
    dataSource,
    emptyMessage,
    loading,
    initialParams,
    setLoading,
    setDataSource,
    setVisibleModalAddProduct,
    visibleModalAddProduct,
    setSelectedItem,
    setListOfSelectedProduct,
    listOfSelectedProduct,
    getItems,
    visiblePagination
}) => {
    const [selectedRow, setSelectedRow] = useState(null)
    const [temporaryData, setTemporaryData] = useState({})
    const [uploadImgPreview, setUploadImg] = useState(null)
    const [promptItem, setPromptItem] = useState({
        title: '',
        desc: '',
        action: '',
    })
    const [errMsg, setErrMsg] = useState({
        visible: '',
        msg: '',
    })
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [sweetTimmer, setSweetTimmer] = useState(false);

    const checkCurrentPage = useMemo(() => {
        return initialParams.page
    }, [initialParams.page]);

    useEffect(() => {
        if (checkCurrentPage) {
            setSelectedRow(null)
            setUploadImg(null)
        }
    }, [checkCurrentPage])

    useEffect(() => {
        if (dataSource && dataSource.rows) {
            setTemporaryData(dataSource)
        }
        return () => {

        }
    }, [dataSource])

    const renderTable = () => {

        if (loading) {
            return <tr><td><div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div></td></tr>
        } else {
            if (temporaryData.rows && temporaryData.rows.length !== 0) {
                return (
                    temporaryData.rows.map((row, index) => {
                        return (
                            <tr key={row.id} className="hover-custom-table" onClick={(e) => {
                                e.stopPropagation()
                                if (selectedRow === null) {
                                    setVisibleModalAddProduct(!visibleModalAddProduct)
                                    setSelectedItem(row)
                                }
                            }}>
                                <td>{selectedRow !== null && selectedRow.id === row.id ?
                                    <FormUpload uploadImgPreview={uploadImgPreview} row={row} handleAcceptedFiles={handleAcceptedFiles} setUploadImg={setUploadImg} /> :
                                    row.variants && row.variants.length === 0 ? "-" : renderImge(row.variants[0].image)}
                                </td>

                                <td>{selectedRow !== null && selectedRow.id === row.id ? <Input value={row.title} onChange={e => handleChangeSelectedRow(row.id, e.target.value, 'title')} /> : !row.title ? "-" : row.title}</td>

                                <td style={{ display: (selectedRow !== null && selectedRow.id) && 'none' }}>{selectedRow !== null && selectedRow.id === row.id ?
                                    <Input value={row.category} onChange={e => handleChangeSelectedRow(row.id, e.target.value, 'category')} /> :
                                    row.categories && row.categories.length > 0 ? row.categories.map((item, i) => {
                                        return <div key={i}><Badge color='light' className="ml-1 mr-1">{item.title}</Badge></div>
                                    }) : "-"}
                                </td>

                                <td>{selectedRow !== null && selectedRow.id === row.id ?
                                    <Input
                                        min={0}
                                        type="number"
                                        step="1"
                                        value={row.variants && row.variants.length === 0 ? 0 : row.variants[0].price}
                                        onChange={e => handleChangeSelectedRow(row.id, e.target.value, 'price')}
                                    /> :
                                    row.variants && row.variants.length === 0 ? "-" :
                                        converPriceWithCommas(row.variants[0].price)}
                                </td>

                                <td style={{ display: (selectedRow !== null && selectedRow && selectedRow.id) && 'none' }}>{"-"}</td>

                                <td>{selectedRow !== null && selectedRow.id === row.id ?
                                    <>
                                        <Button color="primary" className="mr-2" onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickEditRow(row, 'save')
                                        }}><i className="ri-check-line" /></Button>
                                        <Button color="light" className="mr-1" onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickEditRow(row, 'remove')
                                        }}><i className="ri-delete-bin-line" /></Button>
                                    </> :
                                    <>
                                        <UncontrolledTooltip placement="top" target={`TooltipTop${index}`}>{listOfSelectedProduct.find(x => x.id === row.id) ? "Remove from group" : "Add to list of promotion"}</UncontrolledTooltip>
                                        <UncontrolledTooltip placement="top" target={`edit${index}`}>Edit</UncontrolledTooltip>

                                        <Button
                                            id={`edit${index}`}
                                            color="light"
                                            className="mr-2"
                                            disabled={selectedRow !== null && selectedRow.id ? true : false}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleClickEditRow(row, 'edit')
                                            }}
                                        >
                                            <i className="ri-pencil-line" />
                                        </Button>


                                        <Button
                                            id={`TooltipTop${index}`}
                                            color="light"
                                            className="mr-2"
                                            style={{ opacity: ((selectedRow !== null && selectedRow.id) || (listOfSelectedProduct.find(x => x.id === row.id))) && ".3" }}
                                            disabled={(selectedRow !== null && selectedRow.id) ? true : false}
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleSelection(row)
                                            }}
                                        >
                                            {listOfSelectedProduct.find(x => x.id === row.id) ? <i className="align-middle ri-close-fill" /> : <i className="align-middle ri-send-plane-2-fill" />}
                                        </Button>

                                    </>
                                }</td>
                            </tr>
                        )
                    })
                )
            } else {
                return <tr><td>{emptyMessage}</td></tr>
            }
        }
    }

    const renderImge = (data) => {
        if (data !== null && data !== undefined && data.length !== 0) {
            return <CardImg className="avatar-md pl-0" src={getAPIroot() + data[0].url} />
        } else {
            return <CardImg className="avatar-md pl-0" src={imgEmpty} />
        }
    }

    const handleClickEditRow = (item, action) => {
        if (action === 'cancel') {
            handleCancel()
        } else if (action === 'save') {
            const prevData = dataSource.rows.find(x => x.id === item.id)
            if (item.title === prevData.title &&
                item.variants[0].price === prevData.variants[0].price) {
                setSelectedRow(null)
            } else {
                handleSubmit()
            }
        } else if (action === "remove") {
            setAlertConfirm(!alertConfirm)
            setPromptItem({
                title: '',
                desc: 'Are you sure you want to delete this entry?',
                action: 'remove_products',
            })
        } else {
            const newData = temporaryData.rows.find(x => item.id === x.id);

            setSelectedRow(item)

            if (newData.variants[0].image && newData.variants[0].image.length > 0) {
                setUploadImg(getAPIroot() + newData.variants[0].image[0].url)
            } else {
                setUploadImg(null)
            }
        }
    }

    const handleCancel = () => {
        setSelectedRow(null)
        setTemporaryData(dataSource)
    }

    const removeProduct = () => {
        remove(`/products/${selectedRow.id}`).then(remove => {
            if (remove) {
                setSweetTimmer(!sweetTimmer)
                setSelectedRow(null)

                getItems(
                    initialParams
                )
            }
        }).catch((err) => {
            if (err) {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
                setSelectedRow(null)
            }
        })
    }

    const handleChangeSelectedRow = (id, value, property) => {
        const newData = [...temporaryData.rows];
        const index = newData.findIndex(item => id === item.id);
        const item = newData[index];

        let newObj = {}

        if (property === 'title') {
            newObj = {
                ...item,
                [property]: value,
                variants: [{
                    ...item.variants[0],
                    [property]: value
                }]
            }
        }

        if (property === 'price') {
            newObj = {
                ...item,
                variants: [{
                    ...item.variants[0],
                    price: value
                }]
            }
        }

        newData.splice(index, 1, {
            ...item,
            ...newObj,
        });

        setTemporaryData(prev => {
            return {
                ...prev,
                rows: newData
            }
        })
    }

    const handleAcceptedFiles = files => {
        const newData = [...temporaryData.rows];
        const index = newData.findIndex(item => selectedRow.id === item.id);
        const item = newData[index];

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        let obj = {
            ...item,
            variants: [
                {
                    ...item.variants[0],
                    image: files[0]
                }
            ]
        }

        setUploadImg(files[0].preview)

        newData.splice(index, 1, {
            ...item,
            ...obj,
        });

        setTemporaryData(prev => {
            return {
                ...prev,
                rows: newData
            }
        })
    }

    const handleSubmit = (data) => {
        const sID = !data ? selectedRow.id : data.id
        const newData = [...temporaryData.rows];
        const index = newData.findIndex(item => sID === item.id);
        const item = newData[index];

        if (dataSource.rows[index].variants[0].image !== undefined && dataSource.rows[index].variants[0].image !== null) {
            if (dataSource.rows[index].variants[0].image.id === item.variants[0].image.id) {
                // console.log('update only');
                submitForm(sID, item, index)
            } else {
                // console.log('update and change img');
                handleChangeImg(item, index)
            }
        } else {
            if (data !== undefined) {
                // console.log('update after upload img');
                submitForm(sID, data, index)
            } else {
                if (item.variants[0].image !== undefined && item.variants[0].image !== null) {
                    // console.log('upload img first');
                    handleUpload(item, index)
                } else {
                    submitForm(sID, item, index)
                }
            }
        }
    }

    const submitForm = (sID, data, index) => {
        const newData = [...temporaryData.rows];

        update(`/products/${sID}`, data).then((product) => {
            setSelectedRow(null)

            newData.splice(index, 1, {
                ...data,
                ...product,
            });

            setTemporaryData(prev => {
                return {
                    ...prev,
                    rows: newData
                }
            })

            setDataSource(prev => {
                return {
                    ...prev,
                    rows: newData
                }
            })
        })
    }

    const handleUpload = (item, index) => {
        if (item.variants && item.variants[0].image) {
            let data = item.variants[0].image;

            let formData = new FormData();
            formData.append(`files`, data);

            post(`/upload`, formData).then((res) => {
                if (res) {
                    let obj = {
                        ...temporaryData.rows[index],
                        variants: [{
                            ...temporaryData.rows[index].variants[0],
                            image: res[0]
                        }]
                    }
                    handleSubmit(obj)
                }
            }).catch(() => { })
        }
    }

    const handleChangeImg = (item, index) => {
        if (item.variants && item.variants[0].image) {
            let data = item.variants[0].image;

            let formData = new FormData();
            formData.append(`files`, data);

            remove(`/upload/files/${dataSource.rows[index].variants[0].image.id}`).then(remove => {
                post(`/upload`, formData).then((res) => {
                    if (res) {
                        let obj = {
                            ...temporaryData.rows[index],
                            variants: [{
                                ...temporaryData.rows[index].variants[0],
                                image: res[0]
                            }]
                        }
                        submitForm(obj.id, obj, index)
                    }
                }).catch(() => { })
            }).catch(() => { })
        }
    }

    const handleSelection = (item) => {
        const newData = [...listOfSelectedProduct];
        const filters = newData.find(x => x.id === item.id)

        if (filters === undefined) {
            setListOfSelectedProduct([...newData, item])
        } else {
            const removeItem = newData.filter(x => x.id !== item.id);
            setListOfSelectedProduct(removeItem)
        }
    }

    return (
        <div>
            <Table responsive className="table-centered mb-0 table-nowrap">
                <thead>
                    <tr>
                        <th className="border-top-0" style={{ width: 110 }} scope="col">Image</th>
                        <th className="border-top-0" scope="col">Product Name</th>
                        <th className="border-top-0" style={{ display: (selectedRow && selectedRow.id) && 'none' }} scope="col">Category</th>
                        <th className="border-top-0" scope="col">Price</th>
                        <th className="border-top-0" style={{ display: (selectedRow && selectedRow.id) && 'none' }} scope="col">In Promo</th>
                        <th className="border-top-0" style={{ width: 130 }} scope="col">Action</th>
                    </tr>

                    {renderTable()}
                </thead>
            </Table>

            {visiblePagination &&
                <PaginationButton
                    getItems={getItems}
                    initialParams={initialParams}
                    setLoading={setLoading}
                    loading={loading}
                />
            }

            <AlertConfirmation
                title={promptItem.title}
                desc={promptItem.desc}
                action={promptItem.action}
                setAlertConfirm={setAlertConfirm}
                alertConfirm={alertConfirm}
                removeProduct={removeProduct}
                setSweetTimmer={setSweetTimmer}
                sweetTimmer={sweetTimmer}
            />

            <AlertSuccess
                title={'Success!'}
                sweetTimmer={sweetTimmer}
                setSweetTimmer={setSweetTimmer}
            />

            <AlertError
                title={'Error!'}
                errMsg={errMsg}
                setErrMsg={setErrMsg}
            />
        </div>
    )
}


export const FormUpload = ({ uploadImgPreview, row, handleAcceptedFiles }) => {
    return (
        <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps, open }) => (
                <div className="dropzone" style={styles.root}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!row.variants[0].image ? (
                            <div className="dz-message needsclick" style={styles.uploadBox}>
                                <div style={styles.desc}>
                                    <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                </div>
                            </div>) : (
                            <div
                                className="dz-preview dz-processing dz-image-preview dz-success dz-complete"
                                id="file-previews"
                            >
                                {uploadImgPreview !== null ?
                                    <img className="avatar-sm rounded bg-light" alt="img" style={{ width: "100%", height: "100%" }} src={uploadImgPreview} /> :
                                    <img className="avatar-sm rounded bg-light" alt="img" style={{ width: "100%", height: "100%" }} src={getAPIroot() + row.variants[0].image[0].url} />}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Dropzone>
    )
}