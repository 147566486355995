import React, { useCallback, useContext, useEffect } from 'react'
import { Container } from 'reactstrap'
import { ACTION_TYPE } from '../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../store/prescriptions/PrescriptionData'
import NewTelemedicineContent from './NewTelemedicineContent'

const NewTelemedicineList = (props) => {
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)

    const resetState = useCallback(() => {
        if ((!prescriptionDataLayer.content_type && props.location.pathname) || (prescriptionDataLayer.content_type !== 'tele_medicine')) {
            dispatch({
                type: ACTION_TYPE.CHANGE_CONTENT_TYPE,
                payload: 'tele_medicine'
            })
        }
    }, [prescriptionDataLayer.content_type, props.location.pathname, dispatch])

    useEffect(() => {
        resetState()
    }, [resetState])

    const goToPage = (_path) => {
        return props.history.push({
            pathname: _path,
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <NewTelemedicineContent
                        goToPage={goToPage}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewTelemedicineList