import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import ServerSidePaginations from '../../../../components/Table/ServerSidePaginations'
import { BadgeStatus, FindLatestStatus } from '../../Store/CustomFunction'
import { initialDataTablePrescription, initialValue } from '../../Store/initialState'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { getConsultationById } from '../../../../store/prescriptions/_api'

const PatientPrescription = ({ title, handleCreateNewPrescription, role, handleUpdatePrescription }) => {
    const { prescriptionDataLayer } = useContext(PrescriptionContext)
    const [dataSource, setDataSource] = useState(initialDataTablePrescription)
    const [loading, setLoading] = useState(false);

    const tableContent = useCallback(async () => {
        let result = [];
        let rows = [];
        if (prescriptionDataLayer.patient) {
            const prescription_data = prescriptionDataLayer.patient.prescriptions.filter(x => !x.consultation);
            result = prescription_data
            const dataWithConsultation = prescriptionDataLayer.patient.prescriptions.filter(x => x.consultation);

            if (dataWithConsultation.length > 0) {
                for (let i = 0; i < dataWithConsultation.length; i++) {
                    const response = await getConsultationById(dataWithConsultation[i].consultation);
                    if (response.status === 'completed') {
                        result.push({
                            ...dataWithConsultation[i],
                            consultation: response
                        })
                    }
                }
            }
            // console.log(result);
            
            if (result.length > 0) {
                rows = result.map((item, index) => {
                    let statusData = 'unknown';
                    let medicationData = 'unknown';
                    if (item.activity_statuses.length > 0) {
                        statusData = FindLatestStatus('prescription_status', item.activity_statuses);
                        medicationData = FindLatestStatus('medical_status', item.activity_statuses);
                    }
                    return {
                        index: index + 1,
                        status:
                            <div>
                                {!BadgeStatus(statusData) ? '' : <div className={BadgeStatus(statusData).badge}>{BadgeStatus(statusData).text}</div>}
                                <h6 className='mt-3 mb-1'>{item.doctor && item.doctor.name}</h6>
                                <p className='text-muted'>{moment(item.updatedAt).format('DD MMMM YYYY')}</p>
                            </div>,
                        date_created: moment(item.createdAt).format('DD MMMM YYYY'),
                        medic_supply_status:
                            <div>
                                {!BadgeStatus(medicationData) ? '' : <div className={BadgeStatus(medicationData).badge}>{BadgeStatus(medicationData).text}</div>}
                            </div>,
                        clickEvent: () => {
                            handleUpdatePrescription(item, statusData, medicationData)
                        }
                    }
                })
            }

            setDataSource(prev => {
                return {
                    ...prev,
                    rows,
                }
            })
        }
    }, [prescriptionDataLayer.patient, handleUpdatePrescription])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            tableContent()
        }
        return () => {
            mount = true
        }
    }, [tableContent])
    return (
        <React.Fragment>
            <div className='d-flex justify-content-between align-middle align-items-center mb-3'>
                <h5 className='m-0'>{title}</h5>
                {role && role !== 'doctor' && <Button color='light' onClick={handleCreateNewPrescription}>Create new<i className="ri-add-line align-middle ri-lg ml-2"></i></Button>}
            </div>
            <ServerSidePaginations
                loading={loading}
                dataSource={dataSource}
                obj={{}}
                initialParams={initialValue}
                setLoading={setLoading}
            />
        </React.Fragment>
    )
}

export default PatientPrescription