import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Card, CardBody, Col, Media, Row, Table } from "reactstrap";
import Spinner from 'reactstrap/lib/Spinner';

// customCOlor
import { handleStatusStyle } from '../../../store/CustomFunctions';


let options = {
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
    }
}

let optionsPhysio = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                return " " + data.labels[tooltipItem.index] + ": " + data.datasets[0].data[tooltipItem.index] + ' bookings'
            }
        }
    },
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
    }
}

let optionsNurse = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                return ` ${data.labels[tooltipItem.index]} Days : ${data.datasets[0].data[tooltipItem.index]} Bookings`
            }
        }
    },
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
    }
}

const ChartBookingStatus = (props) => {
    const [data, setData] = useState(props.initialData)

    useEffect(() => {
        let obj = {}
        switch (props.title) {
            case 'Booking Status':
                obj = {
                    ...props.initialData,
                    datasets: [
                        {
                            ...props.initialData.datasets[0],
                            data: [props.bookingNew, props.bookingCompleted, props.bookingPending, props.bookingCancelled]
                        }
                    ]
                }
                setData(obj)
                break;
            case 'Physio - Sessions':
                obj = {
                    ...props.initialData,
                    datasets: [
                        {
                            ...props.initialData.datasets[0],
                            data: [props.physioOne, props.physioTwo, props.physioThree]
                        }
                    ]
                }
                setData(obj)
                break;
            default:
                setData(props.initialData)
                break;
        }

    }, [
        props.bookingNew,
        props.bookingCompleted,
        props.bookingPending,
        props.bookingCancelled,
        props.initialData,
        props.title,
        props.physioOne,
        props.physioTwo,
        props.physioThree
    ])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{props.title}</p>
                            {/* {((props.physioOne === 0) && (props.physioTwo === 0) && (props.physioThree === 0)) ?
                                <div style={{ textAlign: "center", width: "100%", height: "100%", margin: "50px auto" }}>
                                    <p style={{ marginBottom: 0 }}>No data for you</p>
                                </div> :
                                <Pie width={474} height={260} data={data} options={props.title === 'Physio - Sessions' ? optionsPhysio : props.title === 'Nurse - Days' ? optionsNurse : options} />} */}

                            {props.loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}><Spinner color="primary" size="sm" /></div> :
                                <Pie width={474} height={260} data={data} options={props.title === 'Physio - Sessions' ? optionsPhysio : props.title === 'Nurse - Days' ? optionsNurse : options} />}
                        </Media>
                    </Media>
                </CardBody>

                {props.title === 'Booking Status' &&
                    <CardBody className="border-top py-3">
                        <Row className="text-center mt-1 mb-2">
                            <Col xs={3}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('processing').color }}></i>New</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingNew ? 0 : props.bookingNew}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={3}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('completed').color }}></i>Completed</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingCompleted ? 0 : props.bookingCompleted}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={3}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('pending').color }}></i>Pending</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingPending ? 0 : props.bookingPending}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={3}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('rejected').color }}></i>Cancelled</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingCancelled ? 0 : props.bookingCancelled}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                        </Row>
                    </CardBody>}

                {props.title === 'Physio - Sessions' &&
                    <CardBody className="border-top py-3">
                        <Row className="text-center mt-1 mb-2">
                            <Col xs={4}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: "rgba(45, 156, 219, 1)" }}></i>45 min</p>

                                {!props.loading ? <h5 className="mb-0">{!props.physioOne ? 0 : props.physioOne}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={4}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: "RGBA(86,100,210,1)" }}></i>90 min</p>

                                {!props.loading ? <h5 className="mb-0">{!props.physioTwo ? 0 : props.physioTwo}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={4}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: "rgba(252, 185, 44, 1)" }}></i>135 min</p>

                                {!props.loading ? <h5 className="mb-0">{!props.physioThree ? 0 : props.physioThree}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                        </Row>
                    </CardBody>}

                {props.title === 'Nurse - Days' &&
                    <CardBody className="border-top py-3">
                        <Row className="text-center mt-1 mb-2">
                            <Col xs={4}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('completed').color }}></i>Completed</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingCompletedNurse ? 0 : props.bookingCompletedNurse}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={4}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('pending').color }}></i>Pending</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingPendingNurse ? 0 : props.bookingPendingNurse}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                            <Col xs={4}>
                                <p className="text-muted text-truncate font-size-11 mb-0"><i className="mdi mdi-circle font-size-10 mr-1" style={{ color: handleStatusStyle('rejected').color }}></i>Need Attention</p>

                                {!props.loading ? <h5 className="mb-0">{!props.bookingAttentionNurse ? 0 : props.bookingAttentionNurse}</h5> : <Spinner size='sm' color="primary" />}
                            </Col>
                        </Row>
                    </CardBody>}

                {props.title === 'Nurse - Days' && props.nurseListData.length > 0 &&
                    <CardBody className="border-top py-3">
                        <Row className="text-center mt-1 mb-3">
                            <Col xs={12}>
                                <h4 className="mb-0 font-size-14">Total number of booked</h4>
                            </Col>
                        </Row>
                        <Row className="text-center mt-1" style={{ position: "relative", display: "block", maxHeight: 200, overflow: "auto" }}>
                            <Table hover className="mb-0 table-centered table-nowrap">
                                <tbody>
                                    {props.nurseListData.map((x, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: "50%" }}>
                                                    <h5 className="font-size-14 mb-0">{x.key} Days</h5>
                                                </td>
                                                <td style={{ width: "50%" }}>
                                                    <p className="text-muted mb-0">{x.value} Booking</p>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Row>
                    </CardBody>}
            </Card>
        </React.Fragment>
    )
}

export default ChartBookingStatus
