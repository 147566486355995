// import React, { Component } from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// //i18n
// import { withNamespaces } from "react-i18next";

// // users
// // import avatar2 from '../../../assets/images/users/avatar-2.jpg';
// import useLocalStorage from '../../../helpers/useLocalStorage';
// import { capitalizeFirstLetter } from '../../../store/CustomFunctions';

// const styles = {
//     root: {
//         position: "relative",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//     },
//     left: {
//         position: "relative",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: 36,
//         width: 36,
//         backgroundColor: "#eff2f7",
//         borderRadius: '50%',
//         fontSize: 18,
//         marginRight: 5,
//         border: '2px solid #cdd6e6'
//     }
// }

// class ProfileMenu extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             menu: false,
//         };
//         this.toggle = this.toggle.bind(this);
//     }


//     toggle() {
//         this.setState(prevState => ({
//             menu: !prevState.menu
//         }));
//     }

//     render() {

//         let username = "Admin";
//         if (useLocalStorage.getItem("authUser")) {
//             const obj = JSON.parse(useLocalStorage.getItem("authUser")).user.username;
//             username = obj;
//             // const uNm = obj.email.split("@")[0];
//             // username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
//         }

//         return (
//             <React.Fragment>
//                 <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
//                     <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
//                         <div style={styles.root}>
//                             <div style={styles.left}>{!username ? 'U' : capitalizeFirstLetter(username[0])}</div>
//                             <div style={{ justifyContent: "flex-end" }}>
//                                 <span className="d-none d-xl-inline-block ml-1 text-transform">{username}</span>
//                                 <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
//                             </div>
//                         </div>
//                         {/* <span className="rounded-circle header-profile-user mr-1" style={{ height: 30, width: 30}}>K</span> */}

//                     </DropdownToggle>
//                     <DropdownMenu right>
//                         {/* <DropdownItem href="#"><i className="ri-user-line align-middle mr-1"></i> {this.props.t('Profile')}</DropdownItem> */}
//                         {/* <DropdownItem href="#"><i className="ri-wallet-2-line align-middle mr-1"></i> {this.props.t('My Wallet')}</DropdownItem>
//                                 <DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}</DropdownItem>
//                                 <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}</DropdownItem> */}
//                         {/* <DropdownItem divider /> */}
//                         <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle mr-1 text-danger"></i> {this.props.t('Logout')}</DropdownItem>
//                     </DropdownMenu>
//                 </Dropdown>
//             </React.Fragment>
//         );
//     }
// }

// export default withNamespaces()(ProfileMenu);

import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getAPIroot, update } from '../../../helpers/API';
import useLocalStorage from '../../../helpers/useLocalStorage';
import { capitalizeFirstLetter } from '../../../store/CustomFunctions';
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext';
import ToggleAvailbility from './ToggleAvailbility';

const styles = {
    root: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    left: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 36,
        width: 36,
        backgroundColor: "#eff2f7",
        borderRadius: '50%',
        fontSize: 18,
        marginRight: 5,
        border: '2px solid #cdd6e6'
    }
}

const ProfileMenu = ({ logout }) => {
    const { globalData } = useContext(GlobalContext)
    const [username, setUserName] = useState('')
    const [image, setImage] = useState(null)
    const [showAvatar, setShowAvatar] = useState(false)
    const [menu, setMenu] = useState(false)

    const toggle = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        const data = JSON.parse(useLocalStorage.getItem("authUser"))
        if (!data.role) {
            logout()
        }
    }, [logout])

    const handleLogout = async () => {
        try {
            const userData = globalData.global.user && globalData.global.user.doctor;
            const userRole = globalData.global.role;
            
            const doctorData = userData && userData.id
            if (doctorData && userRole === 'doctor') {
                await update(`/doctors/${doctorData}`, { is_online: false });
                window.location = "/logout"
            } else {
                window.location = "/logout"
            }
        } catch (error) {

        }
    }

    useEffect(() => {

        if (globalData.global.user) {
            setUserName(globalData.global.user.username)
        }

        if (globalData.global.image) {
            setImage(globalData.global.image)
            setShowAvatar(true)
        } else {
            setImage(null)
            setShowAvatar(false)
        }
    }, [globalData.global.user, globalData.global.image])

    const avatar = () => {

        if (showAvatar) {
            return <img src={`${getAPIroot() + image}?${new Date().getTime()}`} className="rounded-circle img-thumbnail avatar-sm mr-3" alt="thumbnail" />
        } else {
            if (username) {
                return <div className='avatar-user avatar-sm mr-2'>{capitalizeFirstLetter(username[0])}</div>
            } else {
                return <div className='avatar-user avatar-sm mr-2'>U</div>
            }
        }
    }

    return (
        <React.Fragment>
            <div className='d-flex align-items-center'>
                <ToggleAvailbility />
                <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <div style={styles.root}>
                            {avatar()}
                            <div style={{ justifyContent: "flex-end" }}>
                                <span className="d-none d-xl-inline-block ml-1 text-transform">{capitalizeFirstLetter(username)}</span>
                                <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem className="text-danger" onClick={handleLogout}><i className="ri-shut-down-line align-middle mr-1 text-danger"></i>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </React.Fragment>
    )
}

export default ProfileMenu
