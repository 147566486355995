import React, { useState } from 'react'
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, Spinner } from 'reactstrap'

const ModalReject = ({ visible, isClosed, loading, onSubmit }) => {
    const [formData, setFormData] = useState({
        remarks: '',
        check: false
    })
    const [error, setError] = useState(false)
    const updateForm = (field, value) => {
        setFormData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }
    const handleSubmit = () => {
        if (formData.check && formData.remarks) {
            onSubmit(formData)
            setError(false)
            setFormData({
                remarks: '',
                check: false
            })
        } else {
            setError(true)
        }
    }
    return (
        <Modal
            isOpen={visible}
            centered
            toggle={() => isClosed()}
        >
            <ModalBody>
                <h4>Return/Reject</h4>

                <div>
                    <FormGroup>
                        <Label>Remark</Label>
                        <Input disabled={loading} type="textarea" invalid={error && !formData.remarks} placeholder='Enter a description...' value={formData.remarks} onChange={(e) => updateForm('remarks', e.target.value)} />
                        {error && !formData.remarks && <FormFeedback>This field is required</FormFeedback>}
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            disabled={loading}
                            id="exampleCheck"
                            name="check"
                            type="checkbox"
                            checked={formData.check}
                            onChange={(e) => updateForm('check', e.target.checked)}
                        />
                        <Label className={error && !formData.check ? 'text-danger' : 'text-secondary'}>
                            Allow update medication details.
                        </Label>
                    </FormGroup>
                </div>

                <div className='d-flex justify-content-center mt-2'>
                    <Button disabled={loading} className='w-100 mr-2' color='light' onClick={() => {
                        isClosed();
                        setFormData({
                            remarks: '',
                            check: false
                        })
                        setError(false)
                    }}>Cancel</Button>
                    <Button className='w-100' color='primary' onClick={handleSubmit}>{loading && <Spinner color='light' size={"sm"} className="align-middle mr-2" />}Save</Button>
                </div>
            </ModalBody>
        </Modal >
    )
}

export default ModalReject