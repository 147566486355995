export const initialDataTable = {
    columns: [
        {
            label: "Invoice No",
            field: "invoiceNo",
            sort: "asc",
        },
        {
            label: "Date time",
            field: "dateTime",
            sort: "asc",
            width: 200
        },
        {
            label: "Total (RM)",
            field: "amount",
            sort: "asc",
            width: 200
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 200
        },]
}