import React, { useEffect, useState } from 'react'
import { Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardImg } from "reactstrap";
import { getAPIroot, post, update } from '../../helpers/API';
import { capitalizeFirstLetter, handleStatusStyle } from '../../store/CustomFunctions';
import {
    TEXT_ACTIONS,
    TEXT_APPROVED,
    TEXT_CONTACT_NO,
    TEXT_CURRENT_ADDRESS,
    TEXT_DOB,
    TEXT_EMAIL_ADDRESS,
    TEXT_EXPIRY_DATE,
    TEXT_FULLNAME,
    TEXT_GENDER,
    TEXT_IDENTIFICATIONS_AND_PASSPORT,
    TEXT_KIV,
    TEXT_NATIONALITY,
    TEXT_PERMANENT_ADDRESS,
    TEXT_PERSONEL_INFORMATION,
    TEXT_QUALIFICATION,
    TEXT_RACE,
    TEXT_REJECT,
    TEXT_REJECTED,
    TEXT_RELIGION
} from '../../Text';
import moment from "moment";
import "../Templete/Tables/Styles/importbtn.scss"
import RegistrationType from './RegistrationType';
import Qualification from './Qualification';
import RegistrationNumber from './RegistrationNumber';

const styleHeader = {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
}

const emptyImgStyle = {
    height: 96, width: 96, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 30, backgroundColor: "#eff2f7", color: "#d3d9e0", boxShadow: '0px 1px 2px rgb(190 191 204)'
}

const initialSelectedFile = {
    is_registered_nurse: {
        name: "",
        loading: false
    },
    is_medical_assistant: {
        name: "",
        loading: false
    },
    is_physiotherapist: {
        name: "",
        loading: false
    },
    is_general_care_aide: {
        name: "",
        loading: false
    },
    is_diploma_cert: {
        name: "",
        loading: false
    },
    is_degree_cert: {
        name: "",
        loading: false
    },
    is_post_basic_cert: {
        name: "",
        loading: false
    },
    is_others_cert: {
        name: "",
        loading: false
    },
    is_malaysian_nursing_board_number: {
        name: "",
        loading: false
    },
    is_malaysian_physiotherapy_association_number: {
        name: "",
        loading: false
    },
    is_medical_assistant_board_number: {
        name: "",
        loading: false
    }
}
const PersonelInformation = ({ dataSource, handleViewFile, handleApplication, setDataSource, isProviderApproved }) => {
    const [status, setStatus] = useState(TEXT_ACTIONS)
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const [selectedFile, setSelectedFile] = useState(initialSelectedFile);

    useEffect(() => {
        setStatus(dataSource.status)
    }, [dataSource.status])

    const changeHandler = (event, data, propertyType, propertyBol, propertyTypeFile, type) => {
        setSelectedFile({
            ...selectedFile,
            [propertyBol]: {
                name: data,
                loading: true
            }
        });
        if (event.target.files && event.target.files.length > 0) {
            handleUpload(event.target.files[0], propertyType, propertyBol, propertyTypeFile, type)
        }
    };

    const handleUpload = (file, propertyType, propertyBol, propertyTypeFile, type) => {
        let formData = new FormData()
        formData.append('files', file)
        post('/upload', formData).then(res => {
            if (res && res.length > 0) {
                UpdateApplicant(res[0], propertyType, propertyBol, propertyTypeFile, type)
            }
        })
    }

    const UpdateApplicant = (obj, propertyType, propertyBol, propertyTypeFile, type) => {
        setTimeout(() => {
            let data = {
                ...dataSource,
                [propertyType]: {
                    ...dataSource[propertyType],
                    [propertyBol]: true,
                    [propertyTypeFile]: {
                        ...obj
                    }
                },
            }
            if (type === TEXT_QUALIFICATION) delete data[propertyType][propertyBol]
            update(`/nurse-applications/${dataSource.id}`, data).then(res => {
                if (res) {
                    setDataSource(res)
                    setSelectedFile({
                        ...selectedFile,
                        [propertyBol]: {
                            name: data,
                            loading: false
                        }
                    });
                }
            })
        }, 500);
    }

    const checkingFileName = (value, data) => {

        if (typeof value !== 'undefined' && value !== null) {
            return value.name
        } else {
            return data.name
        }
    }

    const handleSubmission = (e) => {
        // console.log(e);
    };

    return (
        <Card>
            <CardBody>
                <div style={styleHeader}><h4 className="card-title">{TEXT_PERSONEL_INFORMATION}</h4>
                    {!isProviderApproved.includes('providers') &&
                        <div className="button-items">
                            <ButtonDropdown
                                isOpen={toggleDropdown}
                                direction="right"
                                toggle={(e) => {
                                    setToggleDropdown(!toggleDropdown)
                                }}
                            >
                                <DropdownToggle
                                    caret
                                    color="secondary"
                                    className={handleStatusStyle(status).class}
                                >
                                    {handleStatusStyle(status).text}
                                    <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-right-custom">
                                    <DropdownItem
                                        disabled={status === TEXT_APPROVED.toLowerCase()}
                                        onClick={() => {
                                            setStatus(TEXT_APPROVED.toLowerCase())
                                            handleApplication(dataSource.id, TEXT_APPROVED.toLowerCase())
                                        }}>{TEXT_APPROVED}</DropdownItem>
                                    <DropdownItem
                                        disabled={status === TEXT_REJECTED.toLowerCase()}
                                        onClick={() => {
                                            setStatus(TEXT_REJECTED.toLowerCase())
                                            handleApplication(dataSource.id, TEXT_REJECTED.toLowerCase())
                                        }}>{TEXT_REJECT}</DropdownItem>
                                    <DropdownItem
                                        disabled={status === "kiv"}
                                        onClick={() => {
                                            setStatus("kiv")
                                            handleApplication(dataSource.id, 'kiv')
                                        }}>{TEXT_KIV}</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>}
                </div>
                <dl className="row mb-0">
                    <dt className="col-sm-3 mb-3 mt-3">{!dataSource.personal_information ? "-" :
                        dataSource.personal_information.photo_file ?
                            // <div className="round-2">
                            //     <div className="img-bg-card" style={{  backgroundImage: `url(${getAPIroot() + dataSource.personal_information.photo_file.url})` }}>
                            //     </div>
                            // </div>
                            // <div style={{ width: 150 }}>
                            <CardImg className="rounded avatar-lg" src={getAPIroot() + dataSource.personal_information.photo_file.url} alt="img" />
                            // </div>
                            : <div className="rounded mr-2" style={emptyImgStyle}><i className="ri-user-line" /></div>}</dt>
                    <dd className="col-sm-9"></dd>
                    <dt className="col-sm-3">{TEXT_FULLNAME}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.fullname ? "-" : capitalizeFirstLetter(dataSource.personal_information.fullname)}</dd>
                    <dt className="col-sm-3">{TEXT_GENDER}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.gender ? "-" : capitalizeFirstLetter(dataSource.personal_information.gender)}</dd>
                    <dt className="col-sm-3">{TEXT_IDENTIFICATIONS_AND_PASSPORT}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.id_card ? "-" : dataSource.personal_information.id_card}</dd>
                    <dt className="col-sm-3">{TEXT_DOB}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.date_of_birth ? "-" : moment(dataSource.personal_information.date_of_birth).format("DD MMM YYYY")}</dd>
                    <dt className="col-sm-3">{TEXT_NATIONALITY}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.nationality ? "-" : capitalizeFirstLetter(dataSource.personal_information.nationality)}</dd>
                    <dt className="col-sm-3">{TEXT_RACE}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.race ? "-" : dataSource.personal_information.race}</dd>
                    <dt className="col-sm-3">{TEXT_RELIGION}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : !dataSource.personal_information.religion ? "-" : dataSource.personal_information.religion}</dd>
                    <dt className="col-sm-3">{TEXT_PERMANENT_ADDRESS}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" :
                        `${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.permanent_address.address_line_1} 
                                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.permanent_address.address_line_2 + ","}
                                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.permanent_address.city + ","}
                                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.permanent_address.zipcode + ","}
                                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.permanent_address.country}`
                    }</dd>
                    <dt className="col-sm-3">{TEXT_CURRENT_ADDRESS}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" :
                        `${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.current_address.address_line_1} 
                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.current_address.address_line_2 + ","}
                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.current_address.city + ","}
                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.current_address.zipcode + ","}
                    ${!dataSource.personal_information.permanent_address ? "" : dataSource.personal_information.current_address.country}`
                    }</dd>
                    <dt className="col-sm-3">{TEXT_CONTACT_NO}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : dataSource.personal_information.contact_number}</dd>
                    <dt className="col-sm-3">{TEXT_EMAIL_ADDRESS}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : dataSource.personal_information.email}</dd>




                    {/* registration type list */}
                    <RegistrationType
                        dataSource={dataSource}
                        handleViewFile={handleViewFile}
                        selectedFile={selectedFile}
                        changeHandler={changeHandler}
                        handleSubmission={handleSubmission}
                        checkingFileName={checkingFileName}
                    />
                    {/* end registration type list */}




                    {/* Registration number */}
                    <RegistrationNumber
                        dataSource={dataSource}
                        handleViewFile={handleViewFile}
                        selectedFile={selectedFile}
                        changeHandler={changeHandler}
                        handleSubmission={handleSubmission}
                        checkingFileName={checkingFileName}
                    />
                    {/* end Registration number */}



                    {/* Qualification */}
                    <Qualification
                        dataSource={dataSource}
                        handleViewFile={handleViewFile}
                        selectedFile={selectedFile}
                        changeHandler={changeHandler}
                        handleSubmission={handleSubmission}
                        checkingFileName={checkingFileName}
                    />
                    {/* end qualification */}



                    <dt className="col-sm-3">{TEXT_EXPIRY_DATE}</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? "-" : dataSource.personal_information.expiry_date ? moment(dataSource.personal_information.expiry_date).format("DD MMM YYYY") : "-"}</dd>
                    <dt className="col-sm-3">Able to stay in with patient?</dt>
                    <dd className="col-sm-9">{!dataSource.personal_information ? '-' : !dataSource.personal_information.is_stay_in ? "No" : "Yes"}</dd>
                </dl>
            </CardBody>
        </Card>
    )
}

export default PersonelInformation
