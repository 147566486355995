import axios from "axios";
import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { get, getAPIroot } from "../../helpers/API";
import useLocalStorage from "../../helpers/useLocalStorage";
import { ACTION_TYPE } from "./ActionType";
import { reducer } from "./reducer";
import { initialState } from "./Store";
import sound2 from "../../assets/sound/NewMessageNotification.wav"
import io from 'socket.io-client'
import qs from 'qs'

const GlobalContext = createContext();

const Store = ({ children }) => {
    const [globalData, setGlobalData] = useReducer(reducer, initialState);
    const [count, setCount] = useState(0)

    const memorizedUser = useMemo(() => {
        return globalData.global.user
    }, [globalData.global.user]);

    const memorizedNotification = useMemo(() => {
        return globalData.global?.notification?.visibility
    }, [globalData.global.notification.visibility]);

    const fetchingUserData = async () => {
        let token = useLocalStorage.getItem('accessToken');
        let user_id = useLocalStorage.getItem('id');
        let user_role = useLocalStorage.getItem('role');
        let _doctor = null;
        let corporate_organisation = null
        let payload = null;
        let availability_doctor = false;

        if (!user_id) {
            useLocalStorage.clear();
        }

        await get(`/users/${user_id}`).then(async response => {
            if (response.corporate_organisation) {
                const response_corporate_organisation = await get(`/corporate-organisations/${response.corporate_organisation}`);
                corporate_organisation = response_corporate_organisation
            }
            if (response.doctor) {
                const responseDoc = await get(`/doctors/${response.doctor}`);
                _doctor = responseDoc
                availability_doctor = responseDoc.is_online;

                if (_doctor.signature) {
                    useLocalStorage.setItem('signature', true)
                } else {
                    useLocalStorage.setItem('signature', false)
                }
            }

            payload = {
                user: { ...response, doctor: _doctor, corporate_organisation },
                token,
                role: user_role,
                image: !response.image ? null : response.image.url,
                availability_doctor,
                certInfo: !useLocalStorage.getItem('certDetails') ? null : useLocalStorage.getItem('certDetails'),
                signature: !useLocalStorage.getItem('signature') ? null : useLocalStorage.getItem('signature'),
            }

            useLocalStorage.setItem('authUser', JSON.stringify(payload.user))
            // console.log(payload);
            if (payload.user) {
                setGlobalData({
                    type: ACTION_TYPE.GET_USER_DATA,
                    payload,
                })
    
                if (user_role === 'doctor') {
                    // setInterval(() => {
                    //     fetchingTeleMedicineCount();
                    // }, 5000);
                }
                if (user_role === 'administrator') {
                    fetchingProviderCount();
                    fetchingNursingManagementCountProvider();
                }
            }
        }).catch(error => {
            alert(error);
            useLocalStorage.clear();
            window.location = "/login"
        })
       
    }

    const fetchingProviderCount = () => {
        get(`/nurse-applications/count?status=processing`).then(response => {
            setGlobalData({
                type: ACTION_TYPE.GET_PROVIDER_COUNT,
                payload: response
            })

        })
    }

    const fetchingNursingManagementCountProvider = () => {
        const nurse = get(`/booking-forms/count?financial_status=pending&category=nurse`)
        const physio = get(`/booking-forms/count?financial_status=pending&category=physio`)
        const medical_assistant = get(`/booking-forms/count?financial_status=pending&category=medical_assistant`)
        const care_aide = get(`/booking-forms/count?financial_status=pending&category=care_aide`)
        const confinement = get(`/booking-forms/count?financial_status=pending&financial_status=partially_completed&category=confinement`)

        axios.all([
            nurse,
            physio,
            medical_assistant,
            care_aide,
            confinement
        ]).then(
            axios.spread((...alldata) => {
                setGlobalData({
                    type: ACTION_TYPE.GET_NURSING_MANAGEMENT_COUNT_PROVIDER,
                    payload: alldata
                })
            })
        )
    }

    useEffect(() => {
        if (!memorizedUser) {
            return;
        }

        const socket = io(getAPIroot(), {
            auth: {
                'APP_KEY': 'sBtYbBgzRNKQYGRGyCGYANElweeixCuG',
                'APP_SECRET': 'WvCsrnUbSieBXFHVIvvRCyPosdbGBYIx',
            }
        })

        socket.on('connect', (err) => {
            console.log(socket.id, err)
        })

        socket.on('connect_error', (err) => {
            console.log(err.message)
        })

        socket.on('consultation:create', async (data) => {
            if (memorizedUser.id !== data.doctor.user) {
                return;
            }
            setGlobalData({
                type: ACTION_TYPE.UPDATE_NOTIFICATION,
                payload: {
                    visibility: true,
                    list: data
                }
            })
            setCount(prev => prev + 1);
            pushNotificationBrowser()
        })

        socket.on('consultation:update', async (data) => {
            if (memorizedUser.id !== data.doctor.user) {
                return;
            }
            setCount(prev => prev > 0 ? prev - 1 : 0);

            if (data.status === 'pending') {
                pushNotificationBrowser();
            } else if (data.status === 'cancelled') {
                setGlobalData({
                    type: ACTION_TYPE.UPDATE_NOTIFICATION,
                    payload: {
                        visibility: true,
                        list: data
                    }
                })
            }
        })
    }, [memorizedUser])

    useEffect(() => {
        if (!memorizedNotification) {
            return;
        }
        setTimeout(() => {
            setGlobalData({
                type: ACTION_TYPE.UPDATE_NOTIFICATION,
                payload: {
                    visibility: false,
                    list: null
                }
            })
        }, 5000);
    }, [memorizedNotification])

    const triggeredCount = useMemo(() => {
        return count
    }, [count])

    const updateCount = useCallback(() => {
        setGlobalData({
            type: ACTION_TYPE.GET_CONSULTATION_COUNT,
            payload: triggeredCount
        })
    }, [triggeredCount])

   

    const fetchhingConsult = useCallback(async () => {
        if (!memorizedUser || !memorizedUser.doctor) {
            return;
        }

        const query = qs.stringify({
            status: 'pending',
            _where: [{ doctor: memorizedUser.doctor.id }]
        })

        const response = await get(`/consultations/count?${query}`);
        setCount(response)
    }, [memorizedUser])

    const pushNotificationBrowser = () => {
        let url;
        if (window.location.hostname.includes('localhost')) {
            url = 'http://localhost:3000/tele-medicine'
        } else if (window.location.hostname.includes('admin-staging')) {
            url = 'https://admin-staging.selcare.com/tele-medicine'
        } else {
            url = 'https://admin.selcare.com/tele-medicine'
        }

        const icon = '../../assets/images/noti-icon.png'
        const body = 'Click here to visit Selcare Admin'
        const title = "New Telemedicine!"
        const soundTrack = sound2
        const audio = new Audio(soundTrack);

        audio.onerror = function () {
            audio.src = soundTrack;
            audio.play();
        };

        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            const options = {
                body,
                icon,
            }
            const notification = new Notification(title, options);
            notification.onclick = function () {
                window.open(url);
            };
            audio.play();

            notification.close()
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    const options = {
                        body,
                        icon,
                    }
                    const notification = new Notification(title, options);
                    notification.onclick = function () {
                        window.open(url);
                    };
                    audio.play();
                }
            });
        }
    }

    useEffect(() => {
        updateCount()
        fetchhingConsult()
    }, [updateCount, fetchhingConsult])

    return (
        <GlobalContext.Provider value={{ globalData, fetchingUserData, fetchingProviderCount, setGlobalData }}>
            {children}
        </GlobalContext.Provider>
    )
};

export { GlobalContext };
export default Store;
