import React, { useEffect, useState } from 'react'
import { Button, CardBody, Col, FormGroup, Row, Spinner } from 'reactstrap'
import { get, post, update } from '../../../../../../helpers/API'
import useLocalStorage from '../../../../../../helpers/useLocalStorage'
import { TEXT_CANCEL, TEXT_SAVE } from '../../../../../../Text'
import AlertConfirmation from '../../../../../CustomComponent/Alert/AlertConfirmation'
import { ACTION_TYPE } from '../../Store/ActionType'
import { AlertCompletedPaymentMessage, AlertManually, AlertPendingPaymentMessage } from './AlertMessage'
import ProviderAssignList from './ProviderAssignList'
import ProviderBookingList from './ProviderBookingList'

const Provider = ({ formValues, isEditMode, dispatch, dataSource, setFormValues, setSweetTimmer, sweetTimmer, setErrMsg, errMsg }) => {
    const [provider, setProvider] = useState([])
    const [temporary, setTemporary] = useState({
        assignedData: [],
        backupData: []
    })
    const [enableToAssign, setEnableToAssign] = useState(true)
    const [loading, setLoading] = useState(false)
    const [alertConfirm, setAlertConfirm] = useState(false)
    const [promptItem, setPromptItem] = useState({
        title: '',
        desc: '',
        action: '',
        selectedItem: {}
    })
    const [counter, setCounter] = useState({
        sec: "00",
        min: "00"
    })
    const [showAlertCoundown, setShowAlertCoundown] = useState(false)


    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }


    useEffect(() => {
        let sec = 0;
        let checkCounter;
        let checkJob;
        let newArr = []

        const checkiftoday = isToday(new Date(formValues.createdAt))

        function pad(val) {
            return val > 9 ? val : "0" + val;
        }

        let getLocalCounter = useLocalStorage.getItem('counterData')
        if (getLocalCounter !== undefined) {
            checkCounter = getLocalCounter.find(item => item.id === formValues.id);
            checkJob = formValues.provider_booking_jobs.find(x => x.type_of_job === 'assigned')
            const findId = getLocalCounter.find(x => x.id === formValues.id)

            if (!findId && checkCounter === undefined) {
                let data = [
                    ...getLocalCounter,
                    {
                        id: formValues.id,
                        counter: 0
                    }
                ]

                useLocalStorage.setItem('counterData', data)
            }
        }

        var timmer = (
            setInterval(() => {
                if ((checkCounter === undefined) || (formValues.financial_status === 'completed' && !checkJob && checkiftoday && parseInt(checkCounter.counter) === 0)) {
                    setShowAlertCoundown(true)
                    let data = {
                        sec: pad(++sec % 60),
                        min: pad(parseInt(sec / 60, 10))
                    }

                    setCounter(data)
                    if (parseInt(data.min) >= 1) {
                        setShowAlertCoundown(false)
                        newArr = getLocalCounter.map(x => {
                            if (x.id === formValues.id) {
                                return {
                                    ...x,
                                    counter: data.min
                                }
                            }
                            return x
                        })

                        useLocalStorage.setItem('counterData', newArr)
                    }
                } else {
                    setShowAlertCoundown(false)
                    setCounter({
                        sec: "00",
                        min: "01"
                    })
                }
            }, 1000)
        );

        setTimeout(() => {
            clearInterval(timmer);
        }, 60000);

        return function cleanup() {
            // console.log("cleaning up");
            clearInterval(timmer);
        };

    },
        [formValues]
    );

    useEffect(() => {
        if (temporary && temporary.length > 0) {
            let status = temporary.find(x => x.type_of_job === 'assigned')
            if (status) {
                setEnableToAssign(false)
            }
        }
    }, [temporary])

    useEffect(() => {
        setLoading(true)
        getProvider(formValues.provider_booking_jobs)
    }, [formValues.provider_booking_jobs])

    useEffect(() => {
        if (formValues.provider_booking_jobs) {
            let assigned = formValues.provider_booking_jobs.filter(item => item.type_of_job === 'assigned')
            let backup = formValues.provider_booking_jobs.filter(item => item.type_of_job === 'backup')
            setTemporary(() => {
                return {
                    assignedData: assigned,
                    backupData: backup
                }
            })
        }
    }, [formValues.provider_booking_jobs])


    const getProvider = (data) => {

        get(`/providers?_sort=createdAt:desc`).then(res => {
            const copy_data = data.filter(x => x.type_of_job === 'assigned')
            let filterArr = res.filter((array_el) => {
                return copy_data.filter((anotherOne_el) => {
                    return anotherOne_el.provider === array_el.id;
                }).length === 0
            });
            setProvider(filterArr)
            setLoading(false)
        }).catch(() => { })
    }

    const handleCheckingProvider = (id) => {
        const filterJob = formValues.provider_booking_jobs.find(x => x.provider === id);
        if (filterJob === undefined) {

            let data = {
                provider: id,
                booking_form: formValues.id,
                type_of_job: "assigned",
                status: "ongoing",
                schedule_start_datetime: formValues.schedule_start_datetime,
                schedule_end_datetime: formValues.schedule_end_datetime,
            }

            setTemporary(prev => {
                return {
                    backupData: prev.backupData,
                    assignedData: [data]
                }
            })

            const filterProviderList = provider.filter(x => x.id !== id)
            setProvider(filterProviderList)

            setPromptItem({
                title: "Are you sure?",
                desc: "Provider will be notify!",
                action: "create_provider_jobs",
                selectedItem: {}
            })
        } else {

            setTemporary(prev => {
                const findObj = formValues.provider_booking_jobs.find(x => x.id === filterJob.id);
                let result = { ...findObj, type_of_job: "assigned" }
                return {
                    backupData: prev.backupData.filter(x => x.id !== filterJob.id),
                    assignedData: [result],
                }
            })

            const filterProviderList = provider.filter(x => x.id !== id)
            setProvider(filterProviderList)

            setPromptItem({
                title: "Are you sure?",
                desc: "Provider will be notify!",
                action: "assigned_jobs",
                selectedItem: {}
            })
        }
    }

    const handleUnssigned = (id) => {
        setEnableToAssign(!enableToAssign)
        setTemporary({
            assignedData: [],
            backupData: formValues.provider_booking_jobs.filter(x => x.type_of_job === 'backup')
        })
        getProvider(formValues.provider_booking_jobs)
        setPromptItem({
            title: "Are you sure?",
            desc: "Provider will be notify!",
            action: "unassigned_job",
            selectedItem: id
        })
    }

    const handleCheckType = (type) => {
        let typeProvider = '-'
        switch (true) {
            case (type.is_general_care_aide):
                typeProvider = 'Care Aide'
                break;
            case (type.is_physiotherapist):
                typeProvider = 'Physio'
                break;
            case (type.is_registered_nurse):
                typeProvider = 'Nurse'
                break;
            case (type.is_medical_assistant):
                typeProvider = 'Medical Assistant'
                break;
            default:
                return typeProvider
        }
        return typeProvider
    }


    const handleSubmit = () => {
        setAlertConfirm(!alertConfirm)
    }

    const assignedJob = () => {
        let data = [...temporary.assignedData, ...temporary.backupData]

        let filterAssigned = formValues.provider_booking_jobs.find(x => x.type_of_job === 'assigned')
        if (filterAssigned !== undefined && filterAssigned !== null) {
            let newObj = {
                ...filterAssigned,
                type_of_job: 'expired',
                booking_form: null
            }
            update(`/provider-booking-jobs/${newObj.id}`, newObj).then(res => { }).catch(() => { })
        }

        update(`/provider-booking-jobs/${temporary.assignedData[0].id}`, temporary.assignedData[0]).then(res => {
            let newData = {
                ...formValues,
                provider_booking_jobs: data
            }
            setFormValues(newData)
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: newData,
                    loading: false
                }
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(() => { })
        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                mode: !dataSource.editMode.mode
            }
        })
    }

    const createNewJob = () => {
        let filterAssigned = formValues.provider_booking_jobs.find(x => x.type_of_job === 'assigned')
        if (filterAssigned !== undefined && filterAssigned !== null) {
            let newObj = {
                ...filterAssigned,
                type_of_job: 'expired',
                booking_form: null
            }
            update(`/provider-booking-jobs/${newObj.id}`, newObj).then(res => { }).catch(() => { })
        }

        post(`/provider-booking-jobs`, temporary.assignedData[0]).then(res => {
            let formdata = [...temporary.backupData, {
                ...res,
                provider: res.provider.id
            }]
            setFormValues({
                ...formValues,
                provider_booking_jobs: formdata
            })
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: {
                        ...formValues,
                        provider_booking_jobs: formdata
                    },
                    loading: false
                }
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(err => { })
        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                mode: !dataSource.editMode.mode
            }
        })
    }

    const unassignedJob = () => {
        const filterUnassigned = formValues.provider_booking_jobs.find(x => x.provider === promptItem.selectedItem)
        let newObj = {
            ...filterUnassigned,
            type_of_job: 'expired',
            booking_form: null
        }
        update(`/provider-booking-jobs/${filterUnassigned.id}`, newObj).then(res => {
            let formdata = [...temporary.backupData, {
                ...res,
                provider: res.provider.id
            }]
            setFormValues({
                ...formValues,
                provider_booking_jobs: formdata
            })
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: {
                        ...formValues,
                        provider_booking_jobs: formdata
                    },
                    loading: false
                }
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(() => { })
        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                mode: !dataSource.editMode.mode
            }
        })
    }
    return (
        loading ? <div style={{ textAlign: "center" }}>
            <Spinner className="mb-4" color="primary" />
        </div>
            : <div>

                <AlertPendingPaymentMessage formData={formValues} />
                <AlertCompletedPaymentMessage formData={formValues} min={counter.min} sec={counter.sec} showAlertCoundown={showAlertCoundown} />
                <AlertManually showAlertCoundown={showAlertCoundown} min={counter.min} />


                {formValues.financial_status === 'completed' && parseInt(counter.min) >= 1 && <div>
                    {temporary.assignedData && temporary.assignedData.length === 0 ? null :
                        <div>
                            <h5 className="card-title mb-3">Assigned to</h5>

                            {temporary.assignedData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <ProviderAssignList
                                            formValues={formValues}
                                            item={item}
                                            index={index}
                                            ormValues={formValues}
                                            setTemporary={setTemporary}
                                            isEditMode={isEditMode}
                                            type="assigned"
                                            enableToAssign={enableToAssign}
                                            handleUnssigned={handleUnssigned}
                                            handleCheckType={handleCheckType}
                                        />
                                    </div>
                                )
                            })}
                        </div>}
                </div>}


                {formValues.financial_status === 'completed' && formValues.booking_status !== 'completed' && parseInt(counter.min) >= 1 && <div>
                    {temporary.backupData && temporary.backupData.length === 0 ? null :
                        <div>
                            <h5 className="card-title mb-3">Other's Provider</h5>

                            {temporary.backupData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <ProviderBookingList
                                            item={item}
                                            index={index}
                                            formValues={formValues}
                                            setTemporary={setTemporary}
                                            isEditMode={isEditMode}
                                            temporary={temporary}
                                            type="backup"
                                            enableToAssign={enableToAssign}
                                            handleAssigned={handleCheckingProvider}
                                            handleCheckType={handleCheckType}
                                        />
                                    </div>
                                )
                            })}
                        </div>}
                </div>}

                {/* parseInt(counter.min) >= 5 */}
                {formValues.financial_status === 'completed' && formValues.booking_status !== 'completed' && parseInt(counter.min) >= 1 && <div>
                    {provider && provider.length === 0 ? null :
                        <div>
                            <h5 className="card-title mb-3">List Provider</h5>

                            {provider.map((item, index) => {
                                return (
                                    <CardBody key={index} className='p-0 mb-4'>
                                        <div className='custom-card'>
                                            <div className='custom-card-body size-img'>{item.fullname[0]}</div>
                                            <div className='size-content'>
                                                <Row>
                                                    <Col sm={12} md={8}>
                                                        <h6>{item.fullname}</h6>
                                                        <p className='text-muted mb-0'>{!item.nurse_application ? "" : handleCheckType(item.nurse_application.registration_type)}</p>
                                                        <p className='text-muted'>{item.contact_number}</p>
                                                    </Col>
                                                    <Col sm={12} md={4}>
                                                        {isEditMode.mode && temporary.assignedData.length === 0 && <Button className="rs-float-right" color='primary' onClick={() => handleCheckingProvider(item.id)}>Assigned</Button>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                )
                            })}
                        </div>}
                </div>}


                {isEditMode.tab === 'Provider' &&
                    <CardBody className="border-top pr-0">
                        <FormGroup className="mb-2">
                            <Button className="float-right" type="submit" onClick={() => {
                                handleSubmit()
                            }} color='primary'>{TEXT_SAVE}</Button>
                            <Button className="float-right mr-2" color='light' onClick={() => {
                                let assigned = formValues.provider_booking_jobs.filter(item => item.type_of_job === 'assigned')
                                let backup = formValues.provider_booking_jobs.filter(item => item.type_of_job === 'backup')
                                setTemporary({
                                    assignedData: assigned,
                                    backupData: backup
                                })
                                dispatch({
                                    type: ACTION_TYPE.ACTION_EDIT_MODE,
                                    payload: {
                                        tab: null,
                                        mode: !dataSource.editMode.mode
                                    }
                                })
                            }}>{TEXT_CANCEL}</Button>
                        </FormGroup>
                    </CardBody>}

                <AlertConfirmation
                    title={promptItem.title}
                    desc={promptItem.desc}
                    action={promptItem.action}
                    setAlertConfirm={setAlertConfirm}
                    alertConfirm={alertConfirm}
                    assignedJob={assignedJob}
                    setTemporary={setTemporary}
                    formValues={formValues}
                    unassignedJob={unassignedJob}
                    createNewJob={createNewJob}
                    dataSource={dataSource}
                    dispatch={dispatch}
                />
            </div>
    )
}

export default Provider
