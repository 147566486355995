export const TabOrder = [
    {
        key: '1',
        name: 'All',
        value: 'all'
    },
    {
        key: '2',
        name: 'In Delivery',
        value: 'pending'
    },
    {
        key: '3',
        name: 'Delivered',
        value: 'fulfilled'
    },
    {
        key: '4',
        name: 'Processing',
        value: 'unfulfilled'
    },
    {
        key: '5',
        name: 'Refund',
        value: 'refund'
    },
    {
        key: '6',
        name: 'Flag',
        value: 'flag'
    },
]