import React from 'react'
import { Col, Row } from 'reactstrap'
import { converPriceWithCommas } from '../../../store/CustomFunctions'
import ApplicationsCount from '../ApplicationsCount'
import BarChartOrder from './BarChartOrder'
import LineChartOrder from './LineChartOrder'

const OrderContent = ({ orders, activeTabOrder, handleChangeTab, activeBtnOrder, setActiveBtnOrder }) => {
    return (
        <div>
            <h4 className='mb-4'>{`Dashboard - Order`}</h4>
            <Row>
                <Col>
                    <div className="btn-group mb-4" role="group" aria-label="Button group with nested dropdown">
                        <button
                            type="button"
                            onClick={() => {
                                setActiveBtnOrder(false)
                                handleChangeTab(1)
                            }}
                            className={activeTabOrder === 1 ? `btn btn-primary` : 'btn btn-light'}
                            style={{ backgroundColor: activeTabOrder !== 1 && 'white' }}
                        >
                            All
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setActiveBtnOrder(false)
                                handleChangeTab(2)
                            }}
                            className={activeTabOrder === 2 ? `btn btn-primary` : 'btn btn-light'}
                            style={{ backgroundColor: activeTabOrder !== 2 && 'white' }}
                        >
                            Weekly
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                handleChangeTab(3)
                                setActiveBtnOrder(!activeBtnOrder)
                            }}
                            className={activeTabOrder === 3 ? `btn btn-primary` : 'btn btn-light'}
                            style={{ backgroundColor: activeTabOrder !== 3 && 'white' }}
                        >
                            Monthly
                        </button>
                        {/* <div className="btn-group" role="group" >
                            <button
                                id="btnGroupDrop1"
                                type="button"
                                className={activeTabOrder === 3 ? `btn btn-primary dropdown-toggle` : 'btn btn-light dropdown-toggle'}
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={() => {
                                    setActiveTabOrder(3)
                                    setActiveBtnOrder(!activeBtnOrder)
                                }}
                                style={{ backgroundColor: activeTabOrder !== 3 && 'white' }}
                            >Monthly</button>
                            <ul
                                className="dropdown-menu"
                                onMouseLeave={() => setActiveBtnOrder(false)}
                                style={{ display: activeBtnOrder && 'block', height: 200, overflow: 'auto' }}
                                aria-labelledby="btnGroupDrop1">
                                {arrMonth.map((x, i) => {
                                    return <li key={i}><p className="dropdown-item">{x}</p></li>
                                })}
                            </ul>
                        </div> */}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                    <ApplicationsCount
                        icon="ri-folder-4-line"
                        title="Total Orders"
                        count={orders.totalOrder.count}
                        loading={orders.totalOrder.loading}
                    />
                </Col>
                <Col md={2}>
                    <ApplicationsCount
                        icon="ri-folder-4-line"
                        title="Delivered"
                        count={orders.totalDelivered.count}
                        loading={orders.totalDelivered.loading}
                    />
                </Col>
                <Col md={2}>
                    <ApplicationsCount
                        icon="ri-folder-4-line"
                        title="Pending"
                        count={orders.totalOrderPending.count}
                        loading={orders.totalOrderPending.loading}
                    />
                </Col>
                <Col md={3}>
                    <ApplicationsCount
                        icon="ri-store-line"
                        title="Sales Revenue"
                        count={!orders.totalRevenue.count ? '$ 0.00' : `$ ${converPriceWithCommas(orders.totalRevenue.count)}`}
                        loading={orders.totalRevenue.loading}
                    />
                </Col>
                <Col md={3}>
                    <ApplicationsCount
                        icon="ri-briefcase-4-line"
                        title="Total Refund"
                        count={!orders.totalRefunded.count ? '$ 0.00' : `$ ${converPriceWithCommas(orders.totalRefunded.count)}`}
                        loading={orders.totalRefunded.loading}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <LineChartOrder
                        title="Orders"
                        lineData={orders.orderGraph.data}
                        loading={orders.orderGraph.loading}
                    />
                </Col>
                <Col md={6}>
                    <BarChartOrder
                        title="Sales by category"
                        barData={orders.orderSalesByCategoryGraph.data}
                        loading={orders.orderSalesByCategoryGraph.loading}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default OrderContent
