import { capitalizeFirstLetter } from "../../../store/CustomFunctions";

export const changeToActionTypeName = (string, role, category) => {
    if (category === 'tele_medicine') {
        switch (string) {
            case 'accepted':
                return {
                    code: 'accepted_consultation',
                    label: 'Telemedicine Consultation'
                }
            case 'pending':
                return {
                    code: 'pending_consultation',
                    label: 'Telemedicine Consultation'
                }
            case 'cancelled':
                return {
                    code: 'cancelled_consultation',
                    label: 'Telemedicine Consultation'
                }
            case 'completed':
                return {
                    code: 'completed_consultation',
                    label: 'Telemedicine Consultation'
                }
            default:
                return {
                    code: 'new_consultation',
                    label: 'Telemedicine Consultation'
                }
        }
    } else if (category === 'prescription') {
        switch (true) {
            case string === 'waiting for approval' && role !== 'doctor':
                return {
                    code: 'view_prescription',
                    label: 'View Prescription'
                }
            case string === 'approved' && role !== 'doctor':
                return {
                    code: 'view_pdf',
                    label: 'View Prescription'
                }
            case string === 'rejected' && role !== 'doctor':
                return {
                    code: 'update_prescription',
                    label: 'Update Prescription'
                }
            case string === 'new' && role !== 'doctor':
                return {
                    code: 'create_new_prescription',
                    label: 'Create New Prescription'
                }
            case string === 'waiting for approval' && role === 'doctor':
                return {
                    code: 'view_prescription',
                    label: 'View Prescription'
                }
            case string === 'approved' && role === 'doctor':
                return {
                    code: 'view_pdf',
                    label: 'View Prescription'
                }
            case string === 'rejected' && role === 'doctor':
                return {
                    code: 'view_prescription',
                    label: 'View Prescription'
                }
            default:
                return null
        }
    }

}

export const FindLatestStatus = (filterBy, data) => {
    let findStatusData = data
    if (filterBy) {
        findStatusData = data.filter(f => f.status.includes(filterBy))
    }

    let statusIndex = findStatusData.length - 1;
    let statusSelected = findStatusData[statusIndex].status
    let statusStr = statusSelected.split('-')
    let resultStatus = statusStr[1]

    return resultStatus
}

export const BadgeStatus = (status) => {
    switch (status) {
        case 'approved':
            return {
                badge: 'badge badge-pill badge-soft-success font-size-12',
                text: capitalizeFirstLetter(status)
            }
        case 'waiting for approval':
            return {
                badge: 'badge badge-pill badge-soft-warning font-size-12',
                text: capitalizeFirstLetter(status)
            }
        case 'rejected':
            return {
                badge: 'badge badge-pill badge-soft-danger font-size-12',
                text: capitalizeFirstLetter(status)
            }
        case 'supplied':
            return {
                badge: 'badge badge-pill badge-soft-success font-size-12',
                text: capitalizeFirstLetter(status)
            }
        case 'unsupplied':
            return {
                badge: 'badge badge-pill badge-soft-warning font-size-12',
                text: capitalizeFirstLetter(status)
            }
        default:
            return null
    }
}
