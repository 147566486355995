import React, { useState } from 'react'
import { Col, FormGroup, Label, Input, Button, CardBody } from 'reactstrap'
import { TEXT_CANCEL, TEXT_SAVE } from '../../../../../../../Text'
import { ACTION_TYPE } from '../../../Store/ActionType'
import { update } from '../../../../../../../helpers/API';
import { converPriceWithCommas } from '../../../../../../../store/CustomFunctions';
import { optionFee, optionRate } from '../../../Store/Option';

const AdditionalTreatment = ({ setCustomScheduleSetting, setCustomScheduleRate, data, setSweetTimmer, sweetTimmer, setFormValues, isEditMode, dispatch, dataSource, activeTab }) => {
    const [temporary, setTemporary] = useState(data)


    const handleUpdateForm = (value, id, property) => {
        const copyData = [...temporary.additional_treatments]
        const index = copyData.findIndex(item => item.id === id)
        const item = copyData[index]

        let newData = {
            ...item,
            [property]: value,
        }

        copyData.splice(index, 1, { ...item, ...newData })
        setTemporary({
            ...temporary,
            additional_treatments: copyData.map(x => {
                return {
                    ...x,
                    custom_price: x.is_tick ? parseFloat(x.custom_price) : 0,
                }
            })
        })
    }

    const handleSubmit = () => {
        setFormValues({
            ...data,
            additional_treatments: temporary.additional_treatments
        })

        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                childeTab: null,
                mode: !dataSource.editMode.mode
            }
        })
        update(`/booking-forms/${data.id}`,
            {
                additional_treatments: temporary.additional_treatments
            }).then(res => {
                const scheduleObj = {
                    totalDays: !res.custom_schedule_days ? null : parseInt(res.custom_schedule_days),
                    totalBills: !res.custom_schedule_rate ? 0 : optionRate.find(x => x.label === res.custom_schedule_rate).price,
                    totalHours: !res.custom_schedule_rate ? 0 : optionRate.find(x => x.label === res.custom_schedule_rate),
                    totalPerDay: res.custom_schedule_custom_rate === null ? 0 : res.custom_schedule_custom_rate.hours,
                    totalPerHours: res.custom_schedule_custom_rate === null ? 0 : res.custom_schedule_custom_rate.price_rate,
                    locum: res.custom_schedule_custom_rate === null ? 0 : res.custom_schedule_custom_rate.locum,
                }

                const settingObj = {
                    type: !res.custom_schedule_fee_setting ? null : optionFee.find(x => x.value === res.custom_schedule_fee_setting.type),
                    processing_fee: !res.custom_schedule_fee_setting ? 0 : res.custom_schedule_fee_setting.processing_fee,
                    deposit_fee: !res.custom_schedule_fee_setting ? 0 : res.custom_schedule_fee_setting.deposit_fee,
                    transportation_fee: !res.custom_schedule_fee_setting ? 0 : res.custom_schedule_fee_setting.transportation_fee
                }

                setFormValues(res)

                setTemporary(prev => {
                    return {
                        ...prev,
                        ...data
                    }
                })

                setCustomScheduleSetting(settingObj)
                setCustomScheduleRate(scheduleObj)

                dispatch({
                    type: ACTION_TYPE.ACTION_UPDATE_FORM,
                    payload: {
                        data: res,
                        loading: false
                    }
                })
            })

        setSweetTimmer(!sweetTimmer)
    }


    return (
        <div>{!temporary.additional_treatments ? null : temporary.additional_treatments.map((item, index) => {
            return (
                <FormGroup row key={index}>
                    <Col md={12} className="ml-auto">
                        <div className="mt-4 mt-lg-0 w-100" style={{ minHeight: 44 }}>
                            <div className="custom-control custom-checkbox custom-control-right w-100">
                                <Input type="checkbox" className="custom-control-input" disabled={isEditMode.childeTab === activeTab ? false : true} checked={!item.is_tick ? false : item.is_tick} onChange={() => false} />
                                <Label className="custom-control-label w-100" disabled={isEditMode.childeTab === activeTab ? false : true}
                                    onClick={(event) => {
                                        if (isEditMode.childeTab === activeTab) {
                                            handleUpdateForm(!item.is_tick, item.id, 'is_tick')
                                        }
                                    }}>{item.treatment.treatment}</Label>
                                <Label className="w-100 font-size-11 text-muted">{item.treatment.description}</Label>
                                <div className='badge badge-pill badge-soft-dark mb-2'><Label className="w-100 font-size-11 text-muted m-0">Default Price: RM {item.treatment.price ? converPriceWithCommas(item.treatment.price) : converPriceWithCommas(0)}  |  Custom Price: {item.custom_price ? converPriceWithCommas(item.custom_price) : converPriceWithCommas(0)}</Label></div>
                            </div>
                            
                            {!isEditMode.mode && isEditMode.childeTab !== activeTab ? null : !item.is_tick ? null : <div><Label className='form-label' >Amount</Label><Input type="number" value={item.custom_price ? converPriceWithCommas(item.custom_price) : converPriceWithCommas(0)} min={0} step={0.01} onChange={e => handleUpdateForm(e.target.value, item.id, 'custom_price')} /></div>}
                        </div>
                    </Col>
                </FormGroup>
            )
        })
        }

            {isEditMode.tab === 'Assessment' && isEditMode.childeTab === activeTab &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" onClick={handleSubmit} color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            setTemporary(data)
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    childeTab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>}
        </div>
    )
}

export default AdditionalTreatment
