import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { TEXT_CANCEL } from '../../../../../Text';
import Breadcrumbs from '../../../../../components/Common/Breadcrumb';
import HeaderNurse from './HeaderNurse';
import BodyNurse from './BodyNurse';
import { get, post, remove, update } from '../../../../../helpers/API';
import { ACTION_TYPE } from '../Store/ActionType';
import { NurseContext } from '../Store/NurseContext';
import { providerCOntent } from '../Store/AssessmentData';
// import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext';
import moment from 'moment'
import AlertSuccess from '../../../../CustomComponent/Alert/AlertSuccess';
import AlertError from '../../../../CustomComponent/Alert/AlertError';
import AlertConfirmation from '../../../../CustomComponent/Alert/AlertConfirmation';
import useLocalStorage from '../../../../../helpers/useLocalStorage';
import { converPriceWithCommas } from '../../../../../store/CustomFunctions';


const NurseDetailsContent = (props) => {
    // const {globalData} = useContext(GlobalContext)
    const [dataSource, dispatch] = useContext(NurseContext)
    const [visibleModal, setVisibleModal] = useState(false)
    const [contentBody, setContentBody] = useState({})
    const [breadcrumbItems, setbreadcrumbItems] = useState([])
    const [timeSlot, setTimeSlot] = useState({ label: '0-4 HRS', value: '0-4 HRS' })
    const [servicePrice, setServicePrice] = useState([])
    const [alertConfirm, setAlertConfirm] = useState(false)
    const [modalContentInfo, setModalContentInfo] = useState({
        type: null,
        disabled: false,
    })
    const [promptItem, setPromptItem] = useState({
        title: '',
        desc: '',
        action: '',
    })
    const [invoice_number, setinvoice_number] = useState('');
    const [sweetTimmer, setSweetTimmer] = useState(false);
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: "",
        pathname: ''
    })
    const [listOfDropDown, setListOfDropdown] = useState([])
    const [invoiceType, setInvoiceType] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)


    // useEffect(() => {
    //     if (globalData.global.role === null || globalData.global.user === null) {
    //         globalData.setFetching(true)
    //     }
    // }, [globalData.global.role, globalData.global.user])

    useEffect(() => {
        get(`/booking-setting`).then(res => {
            setServicePrice(res)
        }).catch(() => { })
    }, [])

    useEffect(() => {
        const path = window.location.pathname.split('/')
        const obj = providerCOntent.find(x => x.path === `/${path[1]}/${path[2]}`)
        setContentBody(obj)

        setbreadcrumbItems([
            { title: "Manage", link: "#" },
            { title: "Booking", link: "#" },
            { title: obj.title, link: { pathname: obj.backUrl, state: { title: obj.title, filter: obj.filter } } },
            { title: "Details", link: "#" },
        ])
    }, [])

    useEffect(() => {
        if (contentBody) {
            dispatch({
                type: ACTION_TYPE.SAVE_PROPS_STATE,
                payload: contentBody
            })
        }
    }, [contentBody, dispatch])


    useEffect(() => {
        let mount = false;


        if (!mount && props.props.match && props.props.match.params.id !== undefined) {
            get(`/booking-forms/${props.props.match.params.id}`).then(res => {
                dispatch({
                    type: ACTION_TYPE.ACTION_GET_NURSE,
                    payload: {
                        data: res,
                        loading: false
                    }
                })

                dispatch({
                    type: res.booking_mode !== 'site_visit' ? ACTION_TYPE.ACTION_SITE_VISIT : ACTION_TYPE.ACTION_SET_SITE_VISIT,
                    payload: {
                        mode: res.booking_mode !== 'site_visit' ? ACTION_TYPE.ACTION_SITE_VISIT : ACTION_TYPE.ACTION_SET_SITE_VISIT,
                        headerColor: res.booking_mode !== 'site_visit' ? '#252b3b' : '#DA1414',
                        disabled: res.booking_mode === 'site_visit' ? true : false
                    }
                })
            }).catch(err => {
                // handleError(err)
            })
        }
        return () => {
            mount = true
        }
    }, [props.props.match, dispatch])

    const handleViewInvoices = (data) => {
        const invoice_filter = data.invoices.find(x => x.status === 'completed')
        if (invoice_filter !== undefined && invoice_filter !== null) {
            window.open(`https://staging.selcare.com/home-nursing/booking/${invoice_filter.id}/invoice/${invoice_filter.id}`)
            // globalData.setFetching(true)
        }
    }

    const handleModal = (status) => {
        setVisibleModal(status)
    }

    const handleConfirmationBooking = (action) => {
        checkingInvoice()
        setAlertConfirm(!alertConfirm)
        setPromptItem({
            title: 'Are you sure ?',
            desc: '',
            action: action,
        })
    }

    const completePayment = (e, selectedData) => {
        e.preventDefault();
        // Update Quotation || update invoice status
        if (checkingInvoice().type === 'quotation') {
            let currentYear = moment().format('YYYY');
            get(`/invoices/count?invoice_number_contains=${currentYear}`).then(res => {
                let dataInvoice = {
                    invoice_number: `SNSB-HOMENURSING-BOOKING-${currentYear}-${res + 1}`,
                    status: 'completed'
                }
                update(`/invoices/${selectedData.value}`, dataInvoice).then(() => {
                    // Update booking status & push notification
                    let dataBooking = {
                        financial_status: 'completed'
                    }
                    handleUpdateBooking(dataBooking, ACTION_TYPE.ACTION_UPDATE_FORM)
                }).catch(() => { })

            }).catch(() => { })
        } else {
            let dataInvoice = {
                status: 'completed'
            }
            update(`/invoices/${selectedData.value}`, dataInvoice).then(() => {
                // Update booking status & push notification
                let dataBooking = {
                    financial_status: 'completed'
                }

                handleUpdateBooking(dataBooking, ACTION_TYPE.ACTION_UPDATE_FORM)
            }).catch(() => { });

        }
    }

    const completeBooking = (e, selectedData) => {
        e.preventDefault();
        // Update Quotation || update invoice status
        if (checkingInvoice().type === 'quotation') {
            let currentYear = moment().format('YYYY');
            get(`/invoices/count?invoice_number_contains=${currentYear}`).then(res => {
                let dataInvoice = {
                    invoice_number: `SNSB-HOMENURSING-BOOKING-${currentYear}-${res + 1}`,
                    status: 'completed'
                }
                update(`/invoices/${selectedData.value}`, dataInvoice).then(() => {
                    // Update booking status & push notification
                    let dataBooking = {
                        booking_mode_status: 'completed',
                        booking_status: 'completed',
                        financial_status: 'completed'
                    }
                    // props.props.history.push('/nurse')
                    handleUpdateBooking(dataBooking, ACTION_TYPE.ACTION_UPDATE_FORM)
                }).catch(() => { })
            }).catch(() => { })
        } else {
            let dataInvoice = {
                status: 'completed'
            }
            update(`/invoices/${selectedData.value}`, dataInvoice).then(() => {
                // Update booking status & push notification
                let dataBooking = {
                    booking_mode_status: 'completed',
                    booking_status: 'completed',
                    financial_status: 'completed'
                }
                // props.props.history.push('/nurse')
                handleUpdateBooking(dataBooking, ACTION_TYPE.ACTION_UPDATE_FORM)
            }).catch(() => { });
        }
    }

    const removeBooking = () => {
        const copy_data = dataSource.formData;

        remove(`/booking-forms/${copy_data.id}`).then(() => {
            setSweetTimmer(!sweetTimmer)
            setTimeout(() => {
                props.props.history.push('/nurse')
            }, 800);
        }).catch(err => {
            setErrMsg({
                visible: !errMsg.visible,
                msg: err
            })
        })
    }

    const checkingInvoice = () => {
        let listOfInvoice = []
        let lisOfQuotation = []

        dataSource.formData.invoices.filter(x => {
            if (x.invoice_number.includes('SNSB')) {
                listOfInvoice.push(x);
            }
            if (x.invoice_number.includes('Quotation')) {
                lisOfQuotation.push(x);
            }
            return x
        })

        if (listOfInvoice.length > 0) {
            const newArr = listOfInvoice.map(x => {
                return { label: `${x.invoice_number}  - ( RM ${converPriceWithCommas(x.total)} )`, value: x.id }
            })
            setListOfDropdown(newArr)
            setInvoiceType('invoice')
            return { type: "invoice", data: listOfInvoice }

        } else {
            const newArr = lisOfQuotation.map(x => {
                return { label: `${x.invoice_number}  - ( RM ${converPriceWithCommas(x.total)} )`, value: x.id }
            })
            setListOfDropdown(newArr)
            setInvoiceType('quotation')
            return { type: "quotation", data: lisOfQuotation }

        }
    }

    const changeMode = (type, disabled) => {
        setModalContentInfo({ type, disabled })
        if (type === ACTION_TYPE.ACTION_CANCEL_SITE_VISIT) {
            dispatch({
                type: type,
                payload: {
                    mode: type,
                    headerColor: dataSource.financial_status === null ? '#252b3b' : '#DA1414',
                    disabled: disabled
                }
            })
            handleModal(false)
        } else {
            dispatch({
                type: type,
                payload: {
                    mode: type === ACTION_TYPE.ACTION_SITE_VISIT ? null : dataSource.mode,
                    headerColor: type === ACTION_TYPE.ACTION_SITE_VISIT ? type === ACTION_TYPE.ACTION_SET_SITE_VISIT ? '#DA1414' : "#252b3b" : dataSource.headerColor,
                    disabled: type === ACTION_TYPE.ACTION_SITE_VISIT ? false : dataSource.mode_disabled
                }
            })
            handleModal(!visibleModal)
        }

        let currentYear = moment().format('YYYY')
        get(`/invoices/count?invoice_number_contains=${currentYear}`).then(res => {
            setinvoice_number(`SNSB-HOMENURSING-BOOKING-${currentYear}-${res + 1}`)
        })
    }

    const countValue = () => {
        const filterDataBookingSetting = servicePrice.service_pricing.find(x => x.category_type === dataSource.formData.category);
        let price = 0
        switch (timeSlot.label) {
            case '0-4 HRS':
                price = filterDataBookingSetting.dynamic_price_rate.first_hours_price
                break;
            case 'ADD HR (4-12 HRS)':
                price = filterDataBookingSetting.dynamic_price_rate.extra_12_hours_price
                break;
            case 'DAY RATE HR (0-24 HRS)':
                price = filterDataBookingSetting.dynamic_price_rate.extra_24_hours_price
                break;
            case 'WEEK RATE HR  (7 DAYS) 12Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.week_12_hours_price
                break;
            case 'WEEK RATE HR (7 DAYS) 24Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.week_24_hours_price
                break;
            case 'MONTH (5DYS/WEEK) 12Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.month_5_days_12_hours_price
                break;
            case 'MONTH (5DYS/WEEK) 24Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.month_5_days_24_hours_price
                break;
            case 'MONTH (6DYS/WEEK) 12Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.month_6_days_12_hours_price
                break;
            case 'MONTH (6DYS/WEEK) 24Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.month_6_days_24_hours_price
                break;
            case 'MONTH (7DYS/WEEK) 12Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.month_7_days_12_hours_price
                break;
            case 'MONTH (7DYS/WEEK) 24Hrs':
                price = filterDataBookingSetting.dynamic_price_rate.month_7_days_24_hours_price
                break;
            default:
                price = 0
                break;
        }

        return price
    }

    const handleGenerateInvoice = (type) => {
        const copy_data = { ...dataSource.formData }

        let serviceData = {
            "title": "Required Services",
            "type": "default",
            "items": [],
            "total": 0
        }

        let medicalData = {
            "title": "Medical Supplies",
            "type": "unit_quantity",
            "items": [],
            "total": 0
        }

        let additionalData = {
            "title": "Additional Treatments",
            "type": "default",
            "items": [],
            "total": 0
        }

        const additionalMap = copy_data.additional_treatments.filter(x => x.is_tick === true).map((item, index) => {
            return {
                "name": item.treatment.treatment,
                "quantity": "",
                "unit_price": "",
                "total": item.custom_price ? item.custom_price : item.treatment.price
            }
        })

        const serviceMap = copy_data.type_of_services.filter(x => x.is_tick === true).map((item, index) => {
            if (item.service !== undefined && item.service !== null) {
                return {
                    "name": item.service.service,
                    "quantity": "",
                    "unit_price": "",
                    "total": item.service.price
                }
            }
            return item
        })

        const medicalMap = copy_data.medical_supplies.map((item, index) => {
            return {
                "name": item.item.title,
                "quantity": item.quantity,
                "unit_price": item.item.variants[0].price,
                "total": item.item.variants[0].price * item.quantity
            }
        })

        let diffTime = moment(copy_data.schedule_start_datetime).diff(moment(copy_data.schedule_end_datetime), 'hours');
        let serviceTotal = countValue().toFixed(2) * diffTime;

        serviceData = {
            ...serviceData,
            items: serviceMap,
            total: serviceTotal,
            is_hide_item_total: true,
        }

        additionalData = {
            ...additionalData,
            items: additionalMap,
            total: additionalMap.map(x => x.total).reduce((a, b) => a + b, 0)
        }

        medicalData = {
            ...medicalData,
            items: medicalMap,
            total: medicalMap.map(x => x.total).reduce((a, b) => a + b, 0)
        }

        let itemsData = []
        if (serviceData.items && serviceData.items.length > 0) {
            itemsData = [
                ...itemsData,
                serviceData,
            ]
        }

        if (additionalData.items && additionalData.items.length > 0) {
            itemsData = [
                ...itemsData,
                additionalData,
            ]
        }

        if (medicalData.items && medicalData.items.length > 0) {
            itemsData = [
                ...itemsData,
                medicalData,
            ]
        }

        const countSubTotal = itemsData.map((item) => {
            if (!isNaN(item.total)) {
                return item.total
            }
            return 0
        }).reduce((a, b) => a + b, 0)

        let countPs = 0.1 * countSubTotal;
        let deposit = 50
        let currentValue = parseInt(countSubTotal)

        switch (true) {
            case currentValue < 201:
                deposit = 50
                break;
            case currentValue >= 201 && currentValue < 501:
                deposit = 100
                break;
            case currentValue >= 501 && currentValue < 1001:
                deposit = 150
                break;
            case currentValue >= 1001:
                deposit = 250
                break;
            default:
                deposit = 50
                break;
        }

        let data = {
            invoice_number: invoice_number,
            booking_form: copy_data.id,
            booking_start_datetime: copy_data.schedule_start_datetime,
            booking_end_datetime: copy_data.schedule_end_datetime,
            address_from: {
                firstname: 'SELCARE NURSING SDN BHD',
                address1: 'No. 1-02 & 1-03, Level 1',
                address2: 'Jalan Pembangunan 14/6, Section 14',
                city: 'Shah Alam',
                zipcode: '40000',
                province: 'Selangor'
            },
            address_to: {
                firstname: copy_data.patient_info.name,
                address1: copy_data.patient_info.address.address1,
                address2: copy_data.patient_info.address.address2,
                city: copy_data.patient_info.address.city,
                zipcode: copy_data.patient_info.address.zipcode,
                province: copy_data.patient_info.address.province,
                email: copy_data.patient_info.email,
                country: copy_data.patient_info.address.address1
            },
            line_items: itemsData,
            subtotal: countSubTotal,
            management_fee: 0,
            deposit_fee: deposit,
            processing_fee: countPs.toFixed(2),
            transportation_fee: '25.00',
            // tax: countTax.toFixed(2),
            total: (deposit + countPs + 25 + countSubTotal).toFixed(2),
            status: "pending",
            // issued_by: 
            issued_by: JSON.parse(useLocalStorage.getItem("authUser")).user.id
        }

        post('/invoices', data).then(() => {
            let payload = {
                booking_mode: type === ACTION_TYPE.ACTION_PROCEED_PAYMENT ? 'provider' : 'site_visit',
                booking_status: 'pending',
            }
            handleUpdateBooking(payload, type)
        }).catch(err => {
            setErrMsg({
                visible: !errMsg.visible,
                msg: err
            })
        })
    }

    const handleUpdateBooking = (data, type) => {
        const copy_data = dataSource.formData;

        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                mode: !dataSource.editMode.mode
            }
        })

        if (data === undefined || data === null) {
            get(`/booking-forms/${props.props.match.params.id}`).then(res => {
                dispatch({
                    type: ACTION_TYPE.ACTION_GET_NURSE,
                    payload: {
                        data: res,
                        loading: false
                    }
                })

                dispatch({
                    type: res.booking_mode !== 'site_visit' ? ACTION_TYPE.ACTION_SITE_VISIT : ACTION_TYPE.ACTION_SET_SITE_VISIT,
                    payload: {
                        mode: res.booking_mode !== 'site_visit' ? ACTION_TYPE.ACTION_SITE_VISIT : ACTION_TYPE.ACTION_SET_SITE_VISIT,
                        headerColor: res.booking_mode !== 'site_visit' ? '#252b3b' : '#DA1414',
                        disabled: res.booking_mode === 'site_visit' ? true : false
                    }
                })
            }).catch(err => {
                // handleError(err)
            })
        } else {
            update(`/booking-forms/${copy_data.id}`, data).then(res => {

                dispatch({
                    type: ACTION_TYPE.ACTION_UPDATE_FORM,
                    payload: {
                        data: res,
                        loading: false
                    }
                })


                if (type === ACTION_TYPE.ACTION_PROCEED_PAYMENT) {
                    handleViewInvoices(res)
                }

                setSweetTimmer(!sweetTimmer)
            }).catch(err => {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
            })
        }
    }

    const handleShowNoti = (item) => {
        let showNoti = false;
        let arrTitle = [];

        if (dataSource.formData !== undefined) {
            let filterService = dataSource.formData.type_of_services.filter(x => x.is_tick === true);

            if (item === "Patient" && !dataSource.formData.patient_info.patient_diagnosis && (dataSource.formData.patient_info.patient_diagnosis === "")) {
                arrTitle = 'Patient'
            }

            if (item === "Assessment" && filterService && filterService.length === 0) {
                arrTitle = 'Assessment'
            }

            if (dataSource.formData.schedule_mode === 'default') {
                if (item === "Schedule" && !dataSource.formData.schedule_end_datetime && !dataSource.formData.schedule_start_datetime) {
                    arrTitle = 'Schedule'
                }
            } else {
                if (item === "Schedule" && !dataSource.formData.custom_schedule_rate && !dataSource.formData.custom_schedule_days) {
                    arrTitle = 'Schedule'
                }
            }
        }
        return { status: showNoti, key: arrTitle }
    }

    const handleRemoveFile = (action, data) => {
        if (data !== undefined || data !== null) {
            setSelectedFile(data)
        }
        setAlertConfirm(!alertConfirm)
        setPromptItem({
            title: 'Are you sure?',
            desc: '',
            action: action,
        })
    }

    const removeFile = () => {
        if (selectedFile !== undefined || selectedFile !== null) {
            remove(`/upload/files/${selectedFile.id}`).then(res => {
                setSweetTimmer(!sweetTimmer)
                handleUpdateBooking(null, ACTION_TYPE.ACTION_UPDATE_FORM)
            }).catch((err) => {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
            })
        }
    }
    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={dataSource.propsState.title} breadcrumbItems={breadcrumbItems} />
                    {dataSource.loading ?
                        <div style={{ textAlign: "center" }}>
                            <Card><CardBody><Spinner className="mr-2" color="primary" /></CardBody></Card>
                        </div> :
                        dataSource.formData !== undefined ?
                            <>
                                <HeaderNurse
                                    dataSource={dataSource}
                                    handleModal={handleModal}
                                    changeMode={changeMode}
                                    handleShowNoti={handleShowNoti}
                                    handleConfirmationBooking={handleConfirmationBooking}
                                />
                                <BodyNurse
                                    dataSource={dataSource}
                                    dispatch={dispatch}
                                    handleShowNoti={handleShowNoti}
                                    timeSlot={timeSlot}
                                    setTimeSlot={setTimeSlot}
                                    countValue={countValue}
                                    handleUpdateBooking={handleUpdateBooking}
                                    handleRemoveFile={handleRemoveFile}
                                />
                            </> : null
                    }
                </Container>
            </div>

            <Modal
                backdrop="static"
                isOpen={visibleModal}
                toggle={handleModal}
            >
                <ModalHeader toggle={() => {
                    setVisibleModal(false)
                }}>
                    <div style={{ display: 'flex', alignItems: "center" }}><i className="ri-error-warning-fill mr-2" />
                        {dataSource.modalContent.title}</div>
                </ModalHeader>
                <ModalBody>
                    <p>{dataSource.modalContent.body}</p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setVisibleModal(false)
                    }}>{TEXT_CANCEL}</Button>
                    <Button color="primary" onClick={() => {

                        if (modalContentInfo.type === ACTION_TYPE.ACTION_SET_SITE_VISIT) {
                            handleGenerateInvoice(ACTION_TYPE.ACTION_SET_SITE_VISIT)
                        }

                        if (modalContentInfo.type === ACTION_TYPE.ACTION_COMPLETE_SITE_VISIT) {
                            let payload = {
                                booking_mode: 'provider',
                            }
                            handleUpdateBooking(payload)
                        }

                        dispatch({
                            type: modalContentInfo.type,
                            payload: {
                                mode: modalContentInfo.type,
                                headerColor: modalContentInfo.type !== ACTION_TYPE.ACTION_SITE_VISIT ? modalContentInfo.type === ACTION_TYPE.ACTION_SET_SITE_VISIT ? '#DA1414' : "#252b3b" : '#DA1414',
                                disabled: modalContentInfo.disabled
                            }
                        })

                        if (modalContentInfo.type === ACTION_TYPE.ACTION_PROCEED_PAYMENT) {
                            handleGenerateInvoice(ACTION_TYPE.ACTION_PROCEED_PAYMENT)
                        }

                        setVisibleModal(false)
                    }}>{dataSource.modalContent.btnSubmit}</Button>
                </ModalFooter>
            </Modal>

            <AlertConfirmation
                title={promptItem.title}
                desc={promptItem.desc}
                action={promptItem.action}
                setAlertConfirm={setAlertConfirm}
                alertConfirm={alertConfirm}
                completeBooking={completeBooking}
                removeBooking={removeBooking}
                completePayment={completePayment}
                setSweetTimmer={setSweetTimmer}
                sweetTimmer={sweetTimmer}
                listOfDropDown={listOfDropDown}
                invoiceType={invoiceType}
                removeFile={removeFile}
            />

            <AlertSuccess
                title="Saved!"
                setSweetTimmer={setSweetTimmer}
                sweetTimmer={sweetTimmer}
            />

            <AlertError
                props={props.props}
                title="Error!"
                setErrMsg={setErrMsg}
                errMsg={errMsg}
            />
        </React.Fragment>
    )
}

export default NurseDetailsContent
