import { changeToActionTypeName, FindLatestStatus } from "../../pages/New-E-Prescription/Store/CustomFunction";
import { ACTION_TYPE } from "./ActionType"
import { initialState } from "./initialState"

export const action_mode = (type, role, category) => {
    // console.log(type, role, category);
    const obj = {
        crud_prescription: {
            submit: false,
            update: false,
            reject: false,
            approve: false,
            supply: false
        },
        crud_tele_medicine: {
            update: false,
        },
        cancel: false,
        questionaireForm: false,
        questionaireView: false,
        print: false,
        back: false
    }

    if (category === 'prescription') {
        switch (true) {
            case type === 'waiting for approval' && role === 'doctor':
                return {
                    footer_action: {
                        ...obj,
                        crud_prescription: {
                            ...obj.crud_prescription,
                            approve: true,
                            reject: true,
                        },
                        questionaireView: true,
                        back: true,
                    },
                    edit_mode: false,
                }
            case type === 'approved' && role === 'doctor':
                return {
                    footer_action: {
                        ...obj,
                        questionaireView: true,
                        back: true,
                        print: true,
                    },
                    edit_mode: false,
                }
            case type === 'rejected' && role === 'doctor':
                return {
                    footer_action: {
                        ...obj,
                        questionaireView: true,
                        back: true,
                    },
                    edit_mode: false,
                }
            case type === 'waiting for approval' && role !== 'doctor':
                return {
                    footer_action: {
                        ...obj,
                        questionaireForm: true,
                        back: true,
                    },
                    edit_mode: false,
                }
            case type === 'approved' && role !== 'doctor':
                return {
                    footer_action: {
                        ...obj,
                        crud_prescription: {
                            ...obj.crud_prescription,
                            supply: true,
                        },
                        questionaireView: true,
                        back: true,
                        print: true,
                    },
                    edit_mode: false,
                }
            case type === 'rejected' && role !== 'doctor':
                return {
                    footer_action: {
                        ...obj,
                        crud_prescription: {
                            ...obj.crud_prescription,
                            update: true
                        },
                        questionaireForm: true,
                        back: true,
                    },
                    edit_mode: true,
                }
            case type === 'new':
                return {
                    footer_action: {
                        ...obj,
                        crud_prescription: {
                            ...obj.crud_prescription,
                            submit: true,
                        },
                        questionaireForm: true,
                        cancel: true,
                    },
                    edit_mode: true,
                }
            default:
                return {
                    footer_action: {
                        ...obj
                    },
                    edit_mode: false
                }
        }
    } else if (category === 'tele_medicine') {
        switch (type) {
            case 'completed':
                return {
                    footer_action: {
                        ...obj,
                        questionaireView: true,
                        back: true
                    },
                    edit_mode: false
                }
            case 'cancelled':
                return {
                    footer_action: {
                        ...obj,
                        questionaireView: true,
                    },
                    edit_mode: false
                }
            case 'accepted':
                return {
                    footer_action: {
                        ...obj,
                        crud_tele_medicine: {
                            update: true,
                        },
                        questionaireView: true,
                    },
                    edit_mode: true
                }
            case 'pending':
                return {
                    footer_action: {
                        ...obj,
                        questionaireView: true,
                        back: true
                    },
                    edit_mode: false
                }
            default:
                return {
                    footer_action: {
                        ...obj
                    },
                    edit_mode: true
                }
        }
    }

}

export const reducer = (state, { type, payload }) => {

    switch (type) {
        case ACTION_TYPE.SET_PATIENT:
            return {
                ...state,
                patient: payload.data,
            }
        case ACTION_TYPE.SET_PRESCRIPTION:
            let action_type1 = null;
            if (state.content_type === 'tele_medicine') {
                action_type1 = state.consultation.status
            } else if (state.content_type === 'prescription') {
                if (payload.data.activity_statuses && payload.data.activity_statuses.length > 0) {
                    action_type1 = FindLatestStatus('prescription_status', payload.data.activity_statuses);
                }
            }
            return {
                ...state,
                loading: false,
                action_type: {
                    type: changeToActionTypeName(action_type1, payload.role, state.content_type).code,
                    title: changeToActionTypeName(action_type1, payload.role, state.content_type).label
                },
                prescription: payload.data,
                // consultation: payload.data.consultation ? payload.data.consultation : null,
                action_button: action_mode(action_type1, payload.role, state.content_type).footer_action,
                edit_mode: action_mode(action_type1, payload.role, state.content_type).edit_mode
            }
        case ACTION_TYPE.SET_CONSULTATION:
            let action_type2 = null
            if (state.content_type === 'tele_medicine') {
                action_type2 = payload.data.status
            } else if (state.content_type === 'prescription') {
                if (payload.data.activity_statuses && payload.data.activity_statuses.length > 0) {
                    action_type2 = FindLatestStatus('prescription_status', payload.data.activity_statuses);
                }
            }
            return {
                ...state,
                loading: false,
                action_type: {
                    type: changeToActionTypeName(action_type2, payload.role, state.content_type).code,
                    title: changeToActionTypeName(action_type2, payload.role, state.content_type).label
                },
                consultation: !payload.data.consultation ? payload.data : payload.data.consultation,
                patient: payload.data.customer ? payload.data.customer : state.patient ? state.patient : null,
                prescription: payload.data.prescription ? payload.data.prescription : state.prescription ? state.prescription : null,
                action_button: action_mode(action_type2, payload.role, state.content_type).footer_action,
                edit_mode: action_mode(action_type2, payload.role, state.content_type).edit_mode
            }
        case ACTION_TYPE.UPDATE_CHAT:
            return {
                ...state,
                chat: payload
            }
        case ACTION_TYPE.UPDATE_ACTION_TYPE:
            let loading = true
            if (payload.action === 'new') loading = false;
            else if (payload.action === 'accepted') loading = false;
            else if (payload.action === 'completed') loading = false;
            return {
                ...state,
                loading,
                action_type: {
                    type: changeToActionTypeName(payload.action, payload.role, state.content_type).code,
                    title: changeToActionTypeName(payload.action, payload.role, state.content_type).label
                },
                prescription: payload.action === 'new' ? null : state.prescription,
                action_button: action_mode(payload.action, payload.role, state.content_type).footer_action,
                edit_mode: action_mode(payload.action, payload.role, state.content_type).edit_mode
            }
        case ACTION_TYPE.SET_FROM_PAGE:
            return {
                ...state,
                ...payload,
                patient: null,
                prescription: null,
                consultation: null
            }
        case ACTION_TYPE.CHANGE_CONTENT_TYPE:
            return {
                ...state,
                content_type: payload
            }
        case ACTION_TYPE.CHANGE_ALL:
            return payload
        case ACTION_TYPE.RESET_STATE:
            return {
                ...initialState,
                from: state.from,
                content_type: state.content_type
            }
        default:
            return null
    }
}