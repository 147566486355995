import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GlobalContext } from "../../store/PageWrapper/GlobalContext";
import PageWrapper from "../../store/PageWrapper/PageWrapper";
import { ACTION_TYPE } from "../../store/prescriptions/ActionType";
import { PrescriptionContext } from "../../store/prescriptions/PrescriptionData";
import { withRouter } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

const SideBarBody = (props) => {
  const { globalData } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();
  const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext);

  const providerMatch = useRouteMatch("/dashboard/provider");
  const bookingMatch = useRouteMatch("/dashboard/booking");
  const orderMatch = useRouteMatch("/dashboard/order");
  const clinicMatch = useRouteMatch("/dashboard/clinic");

  useEffect(() => {
    if (providerMatch) {
      setActiveTab("1");
    } else if (bookingMatch) {
      setActiveTab("2");
    } else if (orderMatch) {
      setActiveTab("3");
    } else if (clinicMatch) {
      setActiveTab("4");
    } else {
      setActiveTab(null); // Reset activeTab if none of the routes match
    }
  }, [providerMatch, bookingMatch, orderMatch, clinicMatch]);

  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleVisiblityMenu = (type) => {
    return globalData.global.visibility_menu_sidebar[type];
  };

  useEffect(() => {
    if (!prescriptionDataLayer.from) {
      let page = window.location.pathname.split("/");
      switch (page[2]) {
        case "waiting-approval":
          dispatch({
            type: ACTION_TYPE.SET_FROM_PAGE,
            payload: {
              content_type: "prescription",
              from: "waiting-approval",
            },
          });
          break;
        case "patient":
          dispatch({
            type: ACTION_TYPE.SET_FROM_PAGE,
            payload: {
              content_type: "prescription",
              from: "patient",
            },
          });
          break;
        case "consultation":
          dispatch({
            type: ACTION_TYPE.SET_FROM_PAGE,
            payload: {
              content_type: "tele_medicine",
              from: "tele_medicine",
            },
          });
          break;
        default:
          break;
      }
    }
  }, [prescriptionDataLayer.from, dispatch]);

  const badge = (type) => {
    switch (type) {
      case "provider":
        if (
          globalData.global.nursing_management.applications.provider.count !== 0
        ) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {globalData.global.nursing_management.applications.provider.count}
            </span>
          );
        } else return null;
      case "nurse":
        if (globalData.global.nursing_management.booking.nurse.count !== 0) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {globalData.global.nursing_management.booking.nurse.count}
            </span>
          );
        } else return null;
      case "physio":
        if (globalData.global.nursing_management.booking.physio.count !== 0) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {globalData.global.nursing_management.booking.physio.count}
            </span>
          );
        } else return null;
      case "medical_assistant":
        if (
          globalData.global.nursing_management.booking.medical_assistant
            .count !== 0
        ) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {
                globalData.global.nursing_management.booking.medical_assistant
                  .count
              }
            </span>
          );
        } else return null;
      case "care_aide":
        if (
          globalData.global.nursing_management.booking.care_aide.count !== 0
        ) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {globalData.global.nursing_management.booking.care_aide.count}
            </span>
          );
        } else return null;
      case "confinement":
        if (
          globalData.global.nursing_management.booking.confinement.count !== 0
        ) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {globalData.global.nursing_management.booking.confinement.count}
            </span>
          );
        } else return null;
      case "tele_medicine":
        if (globalData.global.tele_medicine.count !== 0) {
          return (
            <span className="badge badge-pill badge-danger float-right">
              {globalData.global.tele_medicine.count}
            </span>
          );
        } else return null;
      default:
        return null;
    }
  };

  return (
    <PageWrapper>
      {/* <Button onClick={() => setFetching(!fetching)}>Fetch</Button> */}
      <ul className="metismenu list-unstyled" id="side-menu">
        {/* Menu */}

        <li
          style={{
            display:
              handleVisiblityMenu("appointment") ||
              handleVisiblityMenu("dashboard") ||
              (handleVisiblityMenu("prescription") &&
                handleVisiblityMenu("prescription").patient) ||
              (handleVisiblityMenu("prescription") &&
                handleVisiblityMenu("prescription").waitingForApproval) ||
              handleVisiblityMenu("tele_medicine")
                ? ""
                : "none",
          }}
          className="menu-title pb-0"
        >
          {props.t("Menu")}
        </li>

        <li style={{ display: handleVisiblityMenu("dashboard") ? "" : "none" }}>
          <Link
            to="/#"
            className={
              activeTab
                ? "has-arrow waves-effect pt-0 active"
                : "has-arrow waves-effect pt-0"
            }
          >
            <i className="ri-dashboard-line" title="Dashboard"></i>
            <span className="ml-1">{props.t("Dashboard")}</span>
          </Link>
          <ul className="sub-menu-one">
            <li
              style={{
                display: handleVisiblityMenu("dashboard") ? "" : "none",
              }}
              className={
                location.pathname.includes("/dashboard/provider")
                  ? "active"
                  : ""
              }
            >
              <Link
                to="/dashboard/provider"
                className={
                  activeTab === "1"
                    ? "waves-effect pt-0 active"
                    : "waves-effect pt-0"
                }
                onClick={() => handleClick("1")}
              >
                <i className=" ri-user-2-line" title="Provider"/>
                <span className="ml-1">{props.t("Provider")}</span>
              </Link>
            </li>
            <li
              style={{
                display: handleVisiblityMenu("dashboard") ? "" : "none",
              }}
              className={
                location.pathname.includes("/dashboard/booking") ? "active" : ""
              }
            >
              <Link
                to="/dashboard/booking"
                className={
                  activeTab === "2"
                    ? "waves-effect pt-0 active"
                    : "waves-effect pt-0"
                }
                onClick={() => handleClick("2")}
              >
                <i className=" ri-user-2-line" title="Booking"/>
                <span className="ml-1">{props.t("Booking")}</span>
              </Link>
            </li>
            <li
              style={{
                display: handleVisiblityMenu("dashboard") ? "" : "none",
              }}
              className={
                location.pathname.includes("/dashboard/order") ? "active" : ""
              }
            >
              <Link
                to="/dashboard/order"
                className={
                  activeTab === "3"
                    ? "waves-effect pt-0 active"
                    : "waves-effect pt-0"
                }
                onClick={() => handleClick("3")}
              >
                <i className=" ri-user-2-line" title="Order"/>
                <span className="ml-1">{props.t("Order")}</span>
              </Link>
            </li>
            <li
              style={{
                display: handleVisiblityMenu("dashboard") ? "" : "none",
              }}
              className={
                location.pathname.includes("/dashboard/clinic") ? "active" : ""
              }
            >
              <Link
                to="/dashboard/clinic"
                className={
                  activeTab === "4"
                    ? "waves-effect pt-0 active"
                    : "waves-effect pt-0"
                }
                onClick={() => handleClick("4")}
              >
                <i className="ri-user-2-line" title="Clinic"/>
                <span className="ml-1">{props.t("Clinic")}</span>
              </Link>
            </li>
          </ul>
        </li>
        <li
          style={{ display: handleVisiblityMenu("appointment") ? "" : "none" }}
        >
          <Link to="/appointments" className=" waves-effect pt-0">
            <i className="ri-calendar-2-line" title="Appointments"></i>
            <span className="ml-1">{props.t("Appointments")}</span>
          </Link>
        </li>
        <li
          style={{
            display:
              handleVisiblityMenu("prescription") &&
              handleVisiblityMenu("prescription").title
                ? ""
                : "none",
          }}
        >
          <Link to="/#" className="has-arrow waves-effect pt-0">
            <i className="ri-file-text-line" title="E-Prescription"></i>
            <span className="ml-1">{props.t("E-Prescription")}</span>
          </Link>
          <ul className="sub-menu-one">
            <li
              style={{
                display:
                  handleVisiblityMenu("prescription") &&
                  handleVisiblityMenu("prescription").patient
                    ? ""
                    : "none",
              }}
              onClick={() => {
                dispatch({
                  type: ACTION_TYPE.SET_FROM_PAGE,
                  payload: {
                    content_type: "prescription",
                    from: "patient",
                  },
                });
              }}
            >
              <Link to="/e-prescription/patient" className="waves-effect pt-0">
                <i className=" ri-user-2-line" title="Patients"/>
                <span className="ml-1">{props.t("Patients")}</span>
              </Link>
            </li>
            <li
              style={{
                display:
                  handleVisiblityMenu("prescription") &&
                  handleVisiblityMenu("prescription").createNew
                    ? ""
                    : "none",
              }}
              onClick={() => {
                dispatch({
                  type: ACTION_TYPE.SET_FROM_PAGE,
                  payload: {
                    content_type: "prescription",
                    from: "new-patient",
                  },
                });
              }}
            >
              <Link
                to="/e-prescription/new-patient"
                className="waves-effect pt-0"
              >
                <i className=" ri-user-2-line" title="New Patient"/>
                <span className="ml-1">{props.t("New Patient")}</span>
              </Link>
            </li>
            <li
              style={{
                display:
                  handleVisiblityMenu("prescription") &&
                  handleVisiblityMenu("prescription").waitingForApproval
                    ? ""
                    : "none",
              }}
              onClick={() => {
                dispatch({
                  type: ACTION_TYPE.SET_FROM_PAGE,
                  payload: {
                    content_type: "prescription",
                    from: "waiting-approval",
                  },
                });
              }}
            >
              <Link
                to="/e-prescription/waiting-approval"
                className="waves-effect pt-0"
              >
                <i className=" ri-user-2-line" title="List"/>
                <span className="ml-1">{props.t("List")}</span>
              </Link>
            </li>
          </ul>
        </li>

        <li
          style={{
            display: handleVisiblityMenu("tele_medicine") ? "" : "none",
          }}
          onClick={() => {
            dispatch({
              type: ACTION_TYPE.SET_FROM_PAGE,
              payload: {
                content_type: "tele_medicine",
                from: "tele_medicine",
              },
            });
          }}
        >
          <Link to="/tele-medicine" className=" waves-effect pt-0">
            <i className="ri-video-chat-line" title="Telemedicine"></i>
            {badge("tele_medicine")}
            <span className="ml-1">{props.t("Telemedicine")}</span>
          </Link>
        </li>

        {/* <li
                    // style={{ display: handleVisiblityMenu('tele_medicine') ? '' : 'none' }}
                    onClick={() => {
                        dispatch({
                            type: ACTION_TYPE.CHANGE_CONTENT_TYPE,
                            payload: 'prescription'
                        })
                    }}>
                    <Link to="/tele-medicine" className=" waves-effect pt-0">
                        <i className="ri-video-chat-line"></i>
                        {badge('tele_medicine')}
                        <span className="ml-1">{props.t('Tele/Medicine')}</span>
                    </Link>
                </li> */}
        {/* <li style={{ display: handleVisiblityMenu('tele_medicine') ? '' : 'none' }}>
                    <Link to="/tele-medicine" className=" waves-effect pt-0">
                        <i className="ri-video-chat-line"></i>
                        {badge('tele_medicine')}
                        <span className="ml-1">{props.t('Tele/Medicine')}</span>
                    </Link>
                </li> */}
        {/* End Menu */}

        {/* Manage */}
        <li
          className="menu-title pb-0"
          style={{ display: handleVisiblityMenu("manage") ? "" : "none" }}
        >
          {props.t("Nursing Management")}
        </li>
        <li style={{ display: handleVisiblityMenu("manage") ? "" : "none" }}>
          <Link to="/#" className="has-arrow waves-effect pt-0">
            <i className="ri-menu-2-line" title="Applications"></i>
            <span className="ml-1">{props.t("Applications")}</span>
          </Link>
          <ul className="sub-menu-one">
            <li>
              <Link to="/application/provider" className="waves-effect pt-0">
                {badge("provider")}
                <i className=" ri-user-2-line" title="Provider"/>
                <span className="ml-1">{props.t("Provider")}</span>
              </Link>
            </li>
          </ul>
        </li>

        <li style={{ display: handleVisiblityMenu("manage") ? "" : "none" }}>
          <Link to="/#" className="has-arrow waves-effect pt-0">
            <i className="ri-book-read-line" title="Booking"></i>
            <span className="ml-1">{props.t("Booking")}</span>
          </Link>
          <ul className="sub-menu-one">
            <li>
              <Link
                to={{
                  pathname: "/booking/nurse",
                  state: { title: props.t("Nurse"), filter: "nurse" },
                }}
                className="waves-effect pt-0"
              >
                {badge("nurse")}
                {/* <span className="badge badge-pill badge-danger float-right">6</span> */}
                <i className=" ri-user-2-line" title="Nurse"/>
                <span className="ml-1">{props.t("Nurse")}</span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/booking/physio",
                  state: { title: props.t("Physio"), filter: "physio" },
                }}
                className="waves-effect pt-0"
              >
                {badge("physio")}
                {/* <span className="badge badge-pill badge-danger float-right">6</span> */}
                <i className=" ri-user-2-line" title="Physio"/>
                <span className="ml-1">{props.t("Physio")}</span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/booking/medical-assistant",
                  state: {
                    title: props.t("Medical Assistant"),
                    filter: "medical_assistant",
                  },
                }}
                className="waves-effect pt-0"
              >
                {badge("medical_assistant")}
                {/* <span className="badge badge-pill badge-danger float-right">6</span> */}
                <i className=" ri-user-2-line" title="Medical Assistant"/>
                <span className="ml-1">{props.t("Medical Assistant")}</span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/booking/care-aide",
                  state: { title: props.t("Care Aide"), filter: "care_aide" },
                }}
                className="waves-effect pt-0"
              >
                {badge("care_aide")}
                {/* <span className="badge badge-pill badge-danger float-right">6</span> */}
                <i className=" ri-user-2-line" title="Care Aide"/>
                <span className="ml-1">{props.t("Care Aide")}</span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/booking/confinement",
                  state: {
                    title: props.t("Confinement"),
                    filter: "confinement",
                  },
                }}
                className="waves-effect pt-0"
              >
                {badge("confinement")}
                {/* <span className="badge badge-pill badge-danger float-right">6</span> */}
                <i className=" ri-user-2-line" title="Confinement"/>
                <span className="ml-1">{props.t("Confinement")}</span>
              </Link>
            </li>
          </ul>
        </li>

        <li style={{ display: handleVisiblityMenu("manage") ? "" : "none" }}>
          <Link
            to={{
              pathname: "/nursing/all-invoice",
              state: { title: props.t("All Invoice"), filter: "all_invoice" },
            }}
            className=" waves-effect pt-0"
          >
            <i className="ri-file-list-3-line" title="All Invoice"></i>
            <span className="ml-1">{props.t("All Invoice")}</span>
          </Link>
        </li>

        {/* <li style={{ display: props.showProd() }}>
                    <Link to="/provider" className=" waves-effect" style={{ display: showMenu.manage ? '' : 'none' }}>
                        <i className=" ri-user-2-line" />
                        <span className="ml-1">{props.t('Provider')}</span>
                    </Link>
                </li> */}

        <li
          className="menu-title pb-0"
          style={{
            display:
              handleVisiblityMenu("manage") || handleVisiblityMenu("store")
                ? ""
                : "none",
          }}
        >
          {props.t("Online Store")}
        </li>
        <li>
          <Link
            to="/#"
            className="has-arrow waves-effect pt-0"
            style={{ display: handleVisiblityMenu("store") ? "" : "none" }}
          >
            <i className="ri-store-line" title="Store"></i>
            <span className="ml-1">{props.t("Store")}</span>
          </Link>
          <ul className="sub-menu-one">
            <li>
              <Link to="/store/products" className="waves-effect pt-0">
                <i className=" ri-stack-line" title="Products"/>
                <span className="ml-1">{props.t("Products")}</span>
              </Link>
            </li>
            <li>
              <Link to="/store/orders" className="waves-effect pt-0">
                <i className=" ri-shopping-cart-line" title="Orders"/>
                <span className="ml-1">{props.t("Orders")}</span>
              </Link>
            </li>
            <li>
              <Link to="/store/promotions" className="waves-effect pt-0">
                <i className="ri-price-tag-3-line" title="Promotions"/>
                <span className="ml-1">{props.t("Promotions")}</span>
              </Link>
            </li>
          </ul>
        </li>

        <li
          className="menu-title pb-0"
          style={{
            display:
              handleVisiblityMenu("invoice") || handleVisiblityMenu("ecommerce")
                ? ""
                : "none",
          }}
        >
          {props.t("Clinic / Pharmacy Order")}
        </li>

        <li>
          <Link
            to="/order-list"
            className=" waves-effect pt-0"
            style={{ display: handleVisiblityMenu("ecommerce") ? "" : "none" }}
          >
            <i className="ri-shopping-cart-line" title="Create New Order"></i>
            <span className="ml-1">{props.t("Create New Order")}</span>
          </Link>
          <Link
            to="/all-invoice"
            className=" waves-effect pt-0"
            style={{ display: handleVisiblityMenu("invoice") ? "" : "none" }}
          >
            <i className="ri-file-list-3-line" title="All Invoice"></i>
            <span className="ml-1">{props.t("All Invoice")}</span>
          </Link>
        </li>

        <li
          style={{
            display:
              handleVisiblityMenu("setting").store ||
              handleVisiblityMenu("setting").account
                ? ""
                : "none",
          }}
          className="menu-title pb-0"
        >
          {props.t("Settings")}
        </li>
        <li
          style={{
            display: handleVisiblityMenu("setting").store ? "" : "none",
          }}
        >
          <Link to="/#" className="has-arrow waves-effect pt-0">
            <i className="ri-settings-line" title="Store"></i>
            <span className="ml-1">{props.t("Store")}</span>
          </Link>
          <ul className="sub-menu-one">
            <li>
              <Link to="/setting-store" className="waves-effect pt-0">
                <i className="ri-store-line" title="Product"></i>
                {props.t("Product")}
              </Link>
            </li>
          </ul>
        </li>
        <li
          style={{
            display: handleVisiblityMenu("setting").account ? "" : "none",
          }}
        >
          <Link to="/account" className=" waves-effect pt-0">
            <i className="ri-account-circle-line" title="Account"></i>
            <span className="ml-1">{props.t("Account")}</span>
          </Link>
        </li>
      </ul>
    </PageWrapper>
  );
};

export default withRouter(SideBarBody);
