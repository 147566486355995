import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Form, FormGroup, Row, Spinner, Table, UncontrolledTooltip } from 'reactstrap'
import { TEXT_CANCEL, TEXT_SAVE } from '../../../../../../Text'
import { ACTION_TYPE } from '../../Store/ActionType'
import moment from 'moment'
import { formatBytes, handleStatusStyle } from '../../../../../../store/CustomFunctions'
import { Link } from 'react-router-dom'
import { get, getAPIroot, post, update } from '../../../../../../helpers/API'
import { converPriceWithCommas } from '../../../../../../store/CustomFunctions'
import Dropzone from "react-dropzone";
import imges from "../../../../../../assets/images/news.svg"
import './customStyleButton.scss'

const loadingContainer = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: 20,
}

const headerStyle = {
    position: "re;ative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10
}

const cardFileStyle = {
    root: {
        padding: 10,
        margin: 0,
        minHeight: 300,
        width: '100%',
    },
    border: { border: '2px dashed #ced4da', },
    cardFile: {
        padding: 0,
        overflow: 'hidden',
        textAlign: "center",
        cursor: "pointer",
    },

    listCard: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        padding: 16,
    },
    bodyContent: {
        marginTop: 10,
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical"
    }
}

export const FulfilmentAttachedFile = ({
    setErrMsg,
    errMsg,
    dataSource,
    handleUpdateBooking,
    sweetTimmer,
    setSweetTimmer,
    handleRemoveFile
}) => {
    const [selectedFiles, setselectedFiles] = useState(dataSource.formData.payment_attachments && dataSource.formData.payment_attachments.length === 0 ? [] : dataSource.formData.payment_attachments)
    const [showRemoveBtn, setShowRemoveBtn] = useState({
        show: false,
        id: null
    })

    useEffect(() => {
        setselectedFiles(dataSource.formData.payment_attachments && dataSource.formData.payment_attachments.length === 0 ? [] : dataSource.formData.payment_attachments)
    }, [dataSource.formData])

    const handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        let data = [...selectedFiles, ...files]
        if (files !== undefined || files !== null) {
            handleSubmitFile(data)
        }
    }

    const handleSubmitFile = (data) => {
        let copy_data = [...selectedFiles]
        let formData = new FormData();
        formData.append(`ref`, 'booking-form')
        formData.append(`refId`, dataSource.formData.id)
        formData.append(`field`, 'payment_attachments')

        const filterItem = data.filter(x => x.id === undefined)
        filterItem.forEach((file) => {
            if (file.id === undefined || file.id === null) {
                return formData.append(`files`, file, file.name)
            }
        })

        if (filterItem.length > 0) {
            setselectedFiles(data);
            post(`/upload`, formData).then(() => {
                handleUpdateBooking(null, ACTION_TYPE.ACTION_UPDATE_FORM)
                setSweetTimmer(!sweetTimmer)
            }).catch(err => {
                setselectedFiles(copy_data)
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
            })
        }
    }

    const handleRemovePDF = (data) => {
        handleRemoveFile('remove_file', data)
    }
    return (
        <div className="border-top pt-4 mt-3">
            <div style={headerStyle}>
                <h5 className="card-title pl-2">Proof of payment</h5>

            </div>
            <Form>
                <div>
                    <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
                        {({ getRootProps, getInputProps, open }) => (
                            <div className="dropzone" style={{ border: 0 }}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {selectedFiles && selectedFiles.length === 0 ? (
                                        <div className="dz-message needsclick mt-2 mb-3" style={{ ...cardFileStyle.border }}>
                                            <div className="mb-3">
                                                <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                            </div>
                                            <p className="font-size-18">Drop files here or click to upload.</p>
                                        </div>
                                    ) : (
                                        <div
                                            className="dz-preview dz-processing dz-image-preview dz-success dz-complete mb-3"
                                            style={{ ...cardFileStyle.root, ...cardFileStyle.border }}
                                            id="file-previews"
                                        >
                                            <Row>
                                                {selectedFiles.map((f, i) => {
                                                    return (
                                                        <Col md={4} key={i}>
                                                            <Card
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                }}
                                                                style={{ ...cardFileStyle.cardFile }}
                                                                key={i + "-file"}
                                                                onMouseOver={event => {
                                                                    event.stopPropagation()
                                                                    setShowRemoveBtn({
                                                                        show: true,
                                                                        id: i
                                                                    })
                                                                }}
                                                                onMouseLeave={event => {
                                                                    event.stopPropagation()
                                                                    setShowRemoveBtn({
                                                                        show: false,
                                                                        id: null
                                                                    })
                                                                }}

                                                            >
                                                                {!f.id && <div className='overlay-button'>
                                                                    <Spinner size="sm" color="primary" className="align-middle mr-1" />
                                                                </div>}

                                                                <div>
                                                                    <div>
                                                                        {showRemoveBtn.id !== i ?
                                                                            <div className="pt-4 pl-4 pr-4"><img src={imges} style={{ margin: "auto" }} height="50" width="50" alt="img" /></div> :
                                                                            <div
                                                                                className="close-btn pt-4 pl-4 pr-4"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleRemovePDF(f)
                                                                                }}
                                                                            >
                                                                                <i className=" ri-close-circle-line" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="pb-4 pl-4 pr-4">
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold link-hover"
                                                                            style={{ ...cardFileStyle.bodyContent }}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation()
                                                                                if (f.preview === undefined || f.preview === null) {
                                                                                    return window.open(`${getAPIroot()}${f.url}`)
                                                                                } else {
                                                                                    return window.open(`${f.preview}`)
                                                                                }
                                                                            }}
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </div>
                                    )}
                                </div>
                                <Button color="light" className="w-100" onClick={open}>
                                    <i className="align-middle ri-upload-cloud-2-line mr-1"></i>
                                    Drop files here or click to upload.
                                </Button>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </Form>
        </div>
    )
}


export const InvoiceContent = ({
    dataSource,
    isEditMode,
    dispatch,
    dataTableInvoice,
    handleDownloadInvoice,
    loading
}) => {

    return (
        <React.Fragment>
            {
                dataTableInvoice && dataTableInvoice.length > 0 &&
                <h5 className="card-title mb-4 pl-2">Invoices</h5>
            }
            <div className="table-responsive">
                {loading ? <div style={loadingContainer}><Spinner color="primary" style={{ height: '1.3rem', width: '1.3rem' }} className="mr-2" size="sm" /></div> :
                    <Table className="mb-0">
                        <tbody>

                            {dataTableInvoice && dataTableInvoice.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row"><div style={{ width: 70 }}><div style={{ fontSize: 13 }} className={handleStatusStyle(item.status).badgeClass}>{handleStatusStyle(item.status).text}</div></div></th>
                                        <td><div style={{ width: 150 }}>{item.invoice_number}</div></td>
                                        <td><div style={{ width: 130 }}>{moment(item.createdAt).format("LLLL")}</div></td>
                                        <td><div style={{ width: 90 }}>RM {converPriceWithCommas(item.subtotal + item.deposit_fee + item.processing_fee + item.transportation_fee)}</div></td>
                                        <td>
                                            <div style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", width: 60 }}>
                                                <Link to="#" className="ml-2" id={`delete${index + 1}`} onClick={() => handleDownloadInvoice(item.id)}>
                                                    <i className="ri-file-list-2-line font-size-18"></i></Link>
                                                <UncontrolledTooltip placement="left" target={`delete${index + 1}`}>View Invoice</UncontrolledTooltip >
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>}
            </div>

            {
                isEditMode.tab === 'Billing' &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>
            }
        </React.Fragment>
    )
}

export const QuotationContent = ({
    handleDownloadQuataion,
    dataSource,
    isEditMode,
    dispatch,
    handleApproveQuotation,
    dataTableQuotation,
    loading
}) => {

    return (
        <React.Fragment>
            {
                dataTableQuotation && dataTableQuotation.length > 0 &&
                <h5 className="card-title mb-4 pl-2">Quotations</h5>
            }
            <div className="table-responsive">
                {loading ? <div style={loadingContainer}><Spinner color="primary" style={{ height: '1.3rem', width: '1.3rem' }} className="mr-2" size="sm" /></div> :
                    <Table className="mb-4">
                        <tbody>
                            {dataTableQuotation && dataTableQuotation.map((item, index) => {
                                const findInvoices = dataSource.formData.invoices.filter(x => !x.invoice_number.includes('Quotation')).filter(x => x.status !== 'cancelled')
                                return (
                                    <tr key={index}>
                                        <th scope="row"><div style={{ width: 70 }}><div style={{ fontSize: 13 }} className={handleStatusStyle(item.status).badgeClass}>{handleStatusStyle(item.status).text}</div></div></th>
                                        <td><div style={{ width: 150 }}>{item.invoice_number}</div></td>
                                        <td><div style={{ width: 130 }}>{moment(item.createdAt).format("LLLL")}</div></td>
                                        <td><div style={{ width: 90 }}>RM {converPriceWithCommas(item.subtotal + item.deposit_fee + item.processing_fee + item.transportation_fee)}</div></td>
                                        <td>
                                            <div style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", width: 60 }}>
                                                {findInvoices.length === 0 &&
                                                    <Button color="link" style={{ textDecoration: 'none' }} onClick={() => handleApproveQuotation(item.id)} ><i className="ri-check-line" /></Button>
                                                }

                                                <Link to="#" className="ml-2" id={`delete${index + 1}`} onClick={() => handleDownloadQuataion(item.invoice_number)}>
                                                    <i className="ri-file-list-2-line font-size-18"></i></Link>
                                                <UncontrolledTooltip placement="left" target={`delete${index + 1}`}>View Invoice</UncontrolledTooltip >
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>}
            </div>

            {
                isEditMode.tab === 'Billing' &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>
            }
        </React.Fragment>
    )
}

const Billing = ({
    isEditMode,
    handleUpdateBooking,
    setErrMsg,
    errMsg,
    handleDownloadQuataion,
    dataSource,
    dispatch,
    setSweetTimmer,
    sweetTimmer,
    handleRemoveFile,
    handleDownloadInvoice
}) => {
    const [loading, setLoading] = useState(false)
    const [dataTableInvoice, setDataTableInvoice] = useState([])
    const [dataTableQuotation, setDataTableQuotation] = useState([])


    useEffect(() => {
        let listOfInvoice = []
        let lisOfQuotation = [];
        if (dataSource.formData !== undefined || dataSource.formData !== null) {
            if (dataSource.formData.invoices && dataSource.formData.invoices.length > 0) {

                dataSource.formData.invoices.filter(x => {
                    if (x.invoice_number.includes('SNSB')) {
                        listOfInvoice.push(x);
                    }
                    if (x.invoice_number.includes('Quotation')) {
                        lisOfQuotation.push(x);
                    }
                    return x
                })

                setDataTableQuotation(lisOfQuotation.reverse());
                setDataTableInvoice(listOfInvoice.reverse());
            }
        }
    }, [dataSource.formData])

    // const handleOnClick = (invoiceId) => {
    //     let urlRoot;
    //     if (window.location.hostname.includes('localhost') || window.location.hostname.includes('admin-staging')) {
    //         urlRoot = 'https://staging.selcare.com/'
    //     } else {
    //         urlRoot = 'https://selcare.com/'
    //     }

    //     window.open(`${urlRoot}home-nursing/booking/${dataSource.formData.id}/invoice/${invoiceId}?showonly=invoice`)
    // }

    const handleApproveQuotation = (id) => {
        setLoading(true)
        let currentYear = moment().format('YYYY')
        get(`/invoices/count?invoice_number_contains=${currentYear}`).then(res => {
            let data = {
                invoice_number: `SNSB-HOMENURSING-BOOKING-${currentYear}-${res + 1}`,
                status: 'pending'
            }
            update(`/invoices/${id}`, data).then(() => {
                let payload = {
                    booking_mode: 'provider',
                    booking_status: 'pending',
                }
                setLoading(false)
                handleUpdateBooking(payload, ACTION_TYPE.ACTION_UPDATE_FORM)
            }).catch(err => {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
            })
        })
    }
    return (
        <div>
            <QuotationContent
                loading={loading}
                dataSource={dataSource}
                isEditMode={isEditMode}
                dispatch={dispatch}
                handleApproveQuotation={handleApproveQuotation}
                dataTableQuotation={dataTableQuotation}
                dataTableInvoice={dataTableInvoice}
                handleDownloadQuataion={handleDownloadQuataion}
            />
            <InvoiceContent
                loading={loading}
                dataSource={dataSource}
                handleDownloadInvoice={handleDownloadInvoice}
                isEditMode={isEditMode}
                dispatch={dispatch}
                dataTableInvoice={dataTableInvoice}
            />

            <FulfilmentAttachedFile
                setErrMsg={setErrMsg}
                errMsg={errMsg}
                dataSource={dataSource}
                handleUpdateBooking={handleUpdateBooking}
                setSweetTimmer={setSweetTimmer}
                sweetTimmer={sweetTimmer}
                handleRemoveFile={handleRemoveFile}
            />
        </div>
    )
}

export default Billing
