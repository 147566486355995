import React, { useState } from 'react'
import { Button } from 'reactstrap'
import wall1 from "../../assets/images/wallpaper-1.jpg"
import wall2 from "../../assets/images/wallpaper-2.jpg"
import wall3 from "../../assets/images/wallpaper-3.jpg"
import wall4 from "../../assets/images/wallpaper-4.jpg"
import wall5 from "../../assets/images/wallpaper-5.jpg"
import wall6 from "../../assets/images/wallpaper-6.jpg"
import wall7 from "../../assets/images/videocallwallpaper.png"


const EffectBoxContainer = ({ showEffect, clearEffect, setBlur, setWallpaper }) => {
    const [seleted, setSelected] = useState('');

    let styles = { 
        left: showEffect ? '0' : '-200px', 
        opacity: showEffect ? 1 : 0, 
        transitionDelay: ".1s",
        transitionDuration: ".2s",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
        transition: "opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s",
        transitionProperty: "left, opacity, transform",
    }
    return (
        <div className='ef container-box-effect shadow' style={styles}>
            <h5>Effects</h5>

            <div>
                <p>No effect and blur</p>

                <div className='d-flex flex-row'>
                    <Button onClick={() => {
                        clearEffect()
                        setSelected('')
                    }} color="light" className={seleted === '' ? 'border border-primary mr-1 text-primary' : 'mr-1'} outline><i className="ri-blur-off-line align-middle ri-sm"></i></Button>
                    <Button onClick={() => {
                        setBlur()
                        setSelected('blur')
                    }} color="light" className={seleted === 'blur' ? 'border border-primary mx-1 text-primary' : 'mx-1'} outline><i className="ri-contrast-drop-2-line align-middle ri-sm"></i></Button>
                </div>

                <p className='mt-4'>Backgrounds</p>
                <div className='overflow-auto' style={{ height: 362 }}>
                <div className='h-100'>
                    <Button onClick={() => {
                        setWallpaper('1')
                        setSelected('wall7')
                    }} color="light" className={seleted === 'wall1' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall7} alt='1' style={{ height: '100%', width: '100%' }} /></Button>
                    <Button onClick={() => {
                        setWallpaper('2')
                        setSelected('wall2')
                    }} color="light" className={seleted === 'wall2' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall2} alt='2' style={{ height: '100%', width: '100%' }} /></Button>
                     <Button onClick={() => {
                        setWallpaper('3')
                        setSelected('wall3')
                    }} color="light" className={seleted === 'wall3' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall3} alt='3' style={{ height: '100%', width: '100%' }} /></Button>
                    <Button onClick={() => {
                        setWallpaper('4')
                        setSelected('wall4')
                    }} color="light" className={seleted === 'wall4' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall4} alt='4' style={{ height: '100%', width: '100%' }} /></Button>
                     <Button onClick={() => {
                        setWallpaper('5')
                        setSelected('wall5')
                    }} color="light" className={seleted === 'wall5' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall5} alt='5' style={{ height: '100%', width: '100%' }} /></Button>
                    <Button onClick={() => {
                        setWallpaper('6')
                        setSelected('wall6')
                    }} color="light" className={seleted === 'wall6' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall6} alt='6' style={{ height: '100%', width: '100%' }} /></Button>
                     <Button onClick={() => {
                        setWallpaper('7')
                        setSelected('wall1')
                    }} color="light" className={seleted === 'wall1' ? 'border border-primary p-0 overflow-hidden text-primary mb-2 ' : 'mb-2 p-0 overflow-hidden'} style={{ height: 110, width: "100%" }} outline><img src={wall1} alt='1' style={{ height: '100%', width: '100%' }} /></Button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default EffectBoxContainer