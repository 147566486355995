import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, FormFeedback, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { get, post } from '../../../helpers/API'
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext'
import { ACTION_TYPE } from '../../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../../store/prescriptions/PrescriptionData'
import AlertError from '../../CustomComponent/Alert/AlertError'

const NewPatient = (props) => {
    const { globalData} = useContext(GlobalContext)
    const { dispatch } = useContext(PrescriptionContext);
    const [formData, setFormData] = useState({
        fullName: '',
        nric: '',
        phone_number: '',
        email: '',
        check1: false,
        check2: false
    })
    const [error, setError] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [validNumber, setValidNumber] = useState(false)
    const [validNumberIc, setValidNumberIc] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorFullName, setErrorFullName] = useState(false)
    const [checker, setChecker] = useState({
        check1: false,
        check2: false
    })
    const [selectedRoleCustomer, setSelectedRoleCustomer] = useState('')
    const [errMsg, setErrMsg] = useState({
        msg: '',
        visible: ''
    })

    useEffect(() => {
        let isMounted = true
        get(`/users-permissions/roles`).then(response => {
            const data = response.roles
            if (data.length > 0) {
                let findCustomerRole = data.find(item => item.name === 'Customer')
                if (findCustomerRole !== undefined && isMounted) {
                    setSelectedRoleCustomer(findCustomerRole.id)
                }
            }
        })

        return () => isMounted = false
    }, [])

    const updateForm = (field, value) => {
        checkingEmail()
        checkNumber()
        checkNumberIc()
        setFormData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const checkingEmail = () => {
        var validRegex = /\S+@\S+\.\S+/;


        if (formData.email) {
            if (formData.email.match(validRegex)) {
                setValidEmail(false)
            } else {
                setValidEmail(true)
            }
        }
    }

    const checkNumber = () => {
        if (formData.phone_number) {
            var isANumber = isNaN(formData.phone_number) === false;
            if (!isANumber) {
                setValidNumber(true)
            } else {
                setValidNumber(false)
            }
        }
    }

    const checkNumberIc = () => {
        if (formData.nric) {
            var isANumber = isNaN(formData.nric) === false;
            if (!isANumber) {
                setValidNumberIc(true)
            } else {
                setValidNumberIc(false)
            }
        }
    }

    const checkingOddNum = (num) => {
        const length = num.length
        const lastIndex = num[length - 1]
        return parseInt(lastIndex) % 2
    }

    const createCustomer = (user_id) => {
        let d_year = formData.nric.toString().slice(0, 2)
        let d_month = formData.nric.toString().slice(2, 4)
        let d_day = formData.nric.toString().slice(4, 6)
        //   create customer 
        let payloadCustomer = {
            nric: formData.nric,
            cust_type: "standard",
            default_phone: formData.phone_number,
            date_of_birth: moment(`${checkingYear(d_year)}-${d_month}-${d_day}`).add(1, 'days').toISOString(),
            gender: checkingOddNum(formData.nric) !== 0 ? 'male' : 'female',
            billing_address: {
                lastname: formData.fullName,
                phone: formData.phone_number,
                firstname: formData.fullName,
                email: formData.email
            },
            user: user_id
        }

        if (!payloadCustomer.date_of_birth) delete payloadCustomer.date_of_birth
        post(`/customers`, payloadCustomer).then(response => {
            setLoading(false)
            // props.history.push({ pathname: `/e-prescription/patient/${response.id}` })
            goToPage(`/e-prescription/patient/${response.id}`)

            dispatch({
                type: ACTION_TYPE.SET_PATIENT,
                payload: {
                    data: response,
                    role: globalData.global.role
                }
            })
        })
    }

    const goToPage = (_path) => {
        return props.history.push({
            pathname: _path,
        })
    }

    const CreateUser = () => {
        // create user
        let payloadUser = {
            username: formData.fullName,
            email: formData.email,
            password: "123456",
            confirmed: true,
            role: selectedRoleCustomer,
            firstname: formData.fullName,
            lastname: formData.fullName,
        }
        post(`/users`, payloadUser).then(responseUser => {
            createCustomer(responseUser.id)
            setErrorFullName(false)
        }).catch(err => {
            setError(true)
            setLoading(false)
            setErrorFullName(true)
            setErrMsg({
                visible: true,
                msg: err.includes('Username') ? 'Fullname already taken!' : err
            })
        })
    }

    const checkingYear = (value) => {
        const currentYear = moment().format('YYYY')
        const lastNum = parseFloat(currentYear.slice(2, 4))
        if (lastNum > value) {
            return '20' + value
        } else {
            return '19' + value
        }
    }

    const onSubmit = async () => {
        // setLoading(true)
        setError(true)
        if (formData.fullName &&
            formData.nric &&
            formData.email &&
            formData.phone_number &&
            formData.check1 &&
            formData.check2 &&
            !validNumber &&
            !validEmail) {
            setError(false)
            setChecker({
                check1: false,
                check2: false
            })
            setLoading(true)
            await CreateUser()

        } else {
            setError(true)
            setChecker({
                check1: true,
                check2: true
            })
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <div className='d-flex justify-content-center align-items-center' style={{ height: 'calc(100vh - 158px)' }}>
                        <div className='card' style={{ width: '50%' }}>
                            <div className='card-body'>
                                <h4 className='mb-4'>{`Patient Personal Info`}</h4>

                                <div>
                                    <FormGroup className='mb-3'>
                                        <Label>Full Name (Based on IC)</Label>
                                        <Input type="text" invalid={(error && !formData.fullName) || errorFullName} value={formData.fullName} onChange={(e) => updateForm('fullName', e.target.value)} />
                                        {error && !formData.fullName && <FormFeedback>Please Insert your fullname</FormFeedback>}
                                        {errorFullName && <FormFeedback>This Fullname is not available.</FormFeedback>}
                                    </FormGroup>

                                    <FormGroup className='mb-3'>
                                        <Label>IC Number</Label>
                                        <Input type="text" invalid={(error && !formData.nric) || validNumberIc} minLength={12} maxLength={12} value={formData.nric} onChange={(e) => updateForm('nric', e.target.value)} />
                                        {error && !formData.nric && <FormFeedback>Please Insert your IC number</FormFeedback>}
                                        {validNumberIc && <FormFeedback>NRIC must be a number</FormFeedback>}
                                    </FormGroup>

                                    <FormGroup className='mb-3'>
                                        <Label >Phone Number</Label>
                                        <Input type="text" invalid={(error && !formData.phone_number) || validNumber} value={formData.phone_number} onChange={(e) => updateForm('phone_number', e.target.value)} />
                                        {validNumber && <FormFeedback>Phone Number must be a number</FormFeedback>}
                                        {error && !formData.phone_number && <FormFeedback>Please Insert your phone number</FormFeedback>}
                                    </FormGroup>

                                    <FormGroup className='mb-3'>
                                        <Label>Email</Label>
                                        <Input type="email" invalid={(error && !formData.email) || validEmail} value={formData.email} onChange={(e) => updateForm('email', e.target.value)} />
                                        {validEmail && <FormFeedback>You have entered an invalid email address!</FormFeedback>}
                                        {error && !formData.email && <FormFeedback>Please Insert your email</FormFeedback>}
                                    </FormGroup>

                                    <FormGroup check>
                                        <Input
                                            id="exampleCheck"
                                            name="check"
                                            type="checkbox"
                                            checked={formData.check1}
                                            onChange={(e) => updateForm('check1', e.target.checked)}
                                        />
                                        <Label className={!error || !checker.check1 || formData.check1 ? 'text-secondary' : 'text-danger'}>
                                            By signing up you agree to our Terms & conditions
                                        </Label>
                                    </FormGroup>

                                    <FormGroup check>
                                        <Input
                                            id="exampleCheck"
                                            name="check"
                                            type="checkbox"
                                            checked={formData.check2}
                                            onChange={(e) => updateForm('check2', e.target.checked)}
                                        />
                                        <Label className={!error || !checker.check2 || formData.check2 ? 'text-secondary' : 'text-danger'}>
                                            All the data provided verified and correct
                                        </Label>
                                    </FormGroup>

                                    <div className='text-right'>
                                        <Button color='primary' disabled={loading} onClick={onSubmit}>{loading && <Spinner className="align-middle mr-2" size={"sm"} />}Next</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AlertError
                        title="Error!"
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                </Container>
            </div>
        </React.Fragment>

    )
}

export default NewPatient