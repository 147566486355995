import React from 'react';
import Dropzone from 'react-dropzone';
import { getAPIroot } from '../../helpers/API';

const styles = {
    root: {
        minHeight: 72,
        maxHeight: 72,
        width: 72,
        overflow: "hidden",
        borderColor: "none",
    },
    uploadBox: {
        padding: 0,
        position: 'relative',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        height: 68,
        margin: 0,
        borderRadius: 4,
        overflow: 'hidden'
    },
    desc: {
        textAlign: "center"
    }
}

const FormUpload = ({ uploadImgPreview, dataImage, handleAcceptedFiles }) => {
    return (
        <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps, open }) => (
                <div className="dropzone m-0" style={styles.root}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {!dataImage ? (
                            <div className="dz-message needsclick" style={styles.uploadBox}>
                                <div style={styles.desc}>
                                    <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                </div>
                            </div>) : (
                            <div
                                className="dz-preview dz-processing dz-image-preview dz-success dz-complete"
                                id="file-previews"
                            >
                                {uploadImgPreview !== null ?
                                    <img className="avatar-sm rounded bg-light" alt="img" style={{ width: "100%", height: "100%" }} src={uploadImgPreview} /> :
                                    <img className="avatar-sm rounded bg-light" alt="img" style={{ width: "100%", height: "100%" }} src={getAPIroot() + dataImage.url} />}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Dropzone>
    )
}

export default FormUpload