import React from 'react'
import { Alert } from 'reactstrap'

export const AlertPendingPaymentMessage = ({ formData }) => {
    return formData.financial_status === 'pending' && <Alert color="warning"><i className="mdi mdi-alert-outline mr-2 align-middle" />Provider assignment is not available until patient complete the payment.</Alert>
}

export const AlertCompletedPaymentMessage = ({ formData, min, sec, showAlertCoundown }) => {
    return formData.financial_status === 'completed' && showAlertCoundown && <Alert color="warning"><i className="mdi mdi-alert-outline mr-2 align-middle" />The system right now in the process assigning a task to provider. This process might take up to 15-30 minute to completed. Starts from <b>{min}:{sec}</b></Alert>
}

export const AlertManually = ({ showAlertCoundown, min }) => {
    return showAlertCoundown && parseInt(min) >= 1 && <Alert color="warning"><i className="mdi mdi-alert-outline mr-2 align-middle" />Cannot find any match. Please click <b><u>Start Assigning</u></b> to manually select from the list below.</Alert>
}