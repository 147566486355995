import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import SideBarBody from "./SideBarBody";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      var pathname = this.props.location.pathname.split("/");
      // var linkPathname = items[i].pathname.split('/')
      if (
        `/${pathname[1]}/${pathname[2]}` === items[i].pathname ||
        `/${pathname[1]}` === items[i].pathname
      ) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    // console.log(item);
    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      // console.log(parent2);
      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;
        // console.log(parent3.childNodes[0]);
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          // console.log(parent4);
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  showProd = () =>
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("admin-staging")
      ? "inherit"
      : "none";

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <SideBarBody
            type={this.props.type}
            t={this.props.t}
            showProd={this.showProd}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
