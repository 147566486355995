import { MDBDataTable } from 'mdbreact';
import React from 'react'
import { PaginationButton } from '../../pages/CustomComponent/Pagination/PaginationButton';

const ServerSidePaginations = ({ dataSource, emptyMessage, obj, loading, getItems, initialParams, setLoading }) => {
    const handleSorting = (sortBy, orderBy, filter) => {
        if (sortBy !== 'index:') {
            let str = sortBy + orderBy.toUpperCase();
            let obj = {
                ...initialParams,
                sortBy: str
            }
            getItems(obj)
        }
    }
    return (
        <div>
            <MDBDataTable
                paging={false}
                scrollX
                searching={false}
                displayEntries={false}
                hover
                data={dataSource}
                entries={initialParams.limit}
                onSort={e => {
                    if (e.column !== undefined && e.direction !== undefined) {
                        handleSorting(e.column + ":", e.direction, obj)
                    }
                }}
            />
            <PaginationButton
                getItems={getItems}
                initialParams={initialParams}
                setLoading={setLoading}
                loading={loading}
            />
        </div>
    )
}

export default ServerSidePaginations
