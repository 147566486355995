import React from 'react'
import { TEXT_MALAYSIAN_NURSING_BOARD, TEXT_MALAYSIAN_PHYSIOTHERAPHY_ASSOCIATION, TEXT_MEDICAL_ASSISTANT_BOARD, TEXT_NUMBER, TEXT_REGISTRATION } from '../../Text'

const RegistrationNumber = ({ dataSource, handleViewFile, handleSubmission, changeHandler, checkingFileName, selectedFile }) => {
    return (
        <>
            <dt className="col-sm-3">{`${TEXT_REGISTRATION} ${TEXT_NUMBER.toLowerCase()}`}</dt>
            <dd className="col-sm-9">{!dataSource.registration_number ? "-" :
                <>
                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{TEXT_MALAYSIAN_NURSING_BOARD}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_number.is_malaysian_nursing_board_number ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_malaysian_nursing_board_number.loading ? "Uploading..." : checkingFileName(dataSource.registration_number.malaysian_nursing_board_number_file, selectedFile.is_malaysian_nursing_board_number)}</span>
                                {dataSource.registration_number.malaysian_nursing_board_number_file &&
                                    <button
                                        disabled={!dataSource.registration_number.is_malaysian_nursing_board_number}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_number.malaysian_nursing_board_number_file.url, TEXT_MALAYSIAN_NURSING_BOARD)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_number', 'is_malaysian_nursing_board_number', 'malaysian_nursing_board_number_file', `${TEXT_REGISTRATION} ${TEXT_NUMBER.toLowerCase()}`)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{TEXT_MALAYSIAN_PHYSIOTHERAPHY_ASSOCIATION}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_number.is_malaysian_physiotherapy_association_number ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_malaysian_physiotherapy_association_number.loading ? "Uploading..." : checkingFileName(dataSource.registration_number.malaysian_physiotherapy_association_number_file, selectedFile.is_malaysian_physiotherapy_association_number)}</span>
                                {dataSource.registration_number.malaysian_physiotherapy_association_number_file &&
                                    <button
                                        disabled={!dataSource.registration_number.is_malaysian_physiotherapy_association_number}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_number.malaysian_physiotherapy_association_number_file.url, TEXT_MALAYSIAN_PHYSIOTHERAPHY_ASSOCIATION)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_number', 'is_malaysian_physiotherapy_association_number', 'malaysian_physiotherapy_association_number_file', `${TEXT_REGISTRATION} ${TEXT_NUMBER.toLowerCase()}`)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{TEXT_MEDICAL_ASSISTANT_BOARD}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_number.is_medical_assistant_board_number ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_medical_assistant_board_number.loading ? "Uploading..." : checkingFileName(dataSource.registration_number.medical_assistant_board_number_file, selectedFile.is_medical_assistant_board_number)}</span>
                                {dataSource.registration_number.medical_assistant_board_number_file &&
                                    <button
                                        disabled={!dataSource.registration_number.is_medical_assistant_board_number}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_number.medical_assistant_board_number_file.url, TEXT_MEDICAL_ASSISTANT_BOARD)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_number', 'is_medical_assistant_board_number', 'medical_assistant_board_number_file', `${TEXT_REGISTRATION} ${TEXT_NUMBER.toLowerCase()}`)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
            </dd>
        </>
    )
}

export default RegistrationNumber
