import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { BadgeStatus } from '../../Store/CustomFunction'

const Remark = ({ title }) => {
    const [dataList, setDataList] = useState([])
    const { prescriptionDataLayer } = useContext(PrescriptionContext)

    useEffect(() => {
        if (prescriptionDataLayer.prescription) {
            const newData = prescriptionDataLayer.prescription.activity_statuses
            const findRemark = newData.filter(x => x.status.includes('remark'))
            let mapping = findRemark.map(x => {
                const msg = x.status.split('-')
                return {
                    ...x,
                    status: msg[1]
                }
            })
            setDataList(mapping)
        }
    }, [prescriptionDataLayer.prescription])
    return (
        <React.Fragment>
            <div>
                <div className='d-flex justify-content-between align-middle align-items-center mb-3'>
                    <h5 className='m-0'>{title}</h5>
                </div>

                <div>
                    {dataList.map((diagnosis, index) => {
                        return (
                            <div key={index}>
                                <div className='p-3 border-top'>
                                    <div className='d-flex justify-content-between w-100'>
                                        <div><p className={`${BadgeStatus(diagnosis.status).badge} m-0 mr-2`}>{BadgeStatus(diagnosis.status).text}</p></div>
                                        <div className='w-100'>
                                            <h6 className='m-0 '>{diagnosis.remarks}</h6>
                                            <p className='text-muted m-0 small'>{moment(diagnosis.dateTime).format('DD MMMM YYYY h:mm A')}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Remark