import Pusher from 'pusher-js'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Container, Spinner } from 'reactstrap'
import { getPushKey } from '../../../../helpers/API'
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext'
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { getChat, getConsultationById, getPatientById, getPrescriptionById } from '../../../../store/prescriptions/_api'
import PrescriptionDoc from '../Details/PrescriptionDoc'
import PopupChat from './PopupChat'
import PrescriptionFormContent from './PrescriptionFormContent'

const PrescriptionForm = (props) => {
    const { globalData } = useContext(GlobalContext)
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)
    const [showChat, setShowChat] = useState(false);
    const [data, setData] = useState(null);
    // const memoizedDispatch = useCallback(dispatch, []);
    // const memoizedRole = useCallback(globalData.global.role, []);
    // const memorizedChatId = useCallback(prescriptionDataLayer.chat, [])

    const memorizedChatId = prescriptionDataLayer.chat; // state variable
    const memoizedRole = globalData.global.role;
    const memoizedDispatch = dispatch; // dispatch function

    const handler = useCallback(async (_data) => {
        if (memorizedChatId) {
            const chats = memorizedChatId;
            const chat_id = chats && chats.id
            if (chat_id) {
                const response = await getChat(chat_id);
                memoizedDispatch({
                    type: ACTION_TYPE.UPDATE_CHAT,
                    payload: response
                })
            }
        }
    }, [memorizedChatId, memoizedDispatch]);

    useEffect(() => {
        const pusher = new Pusher(getPushKey(), {
            cluster: "ap1",
        });

        const channel = pusher.subscribe("chat-channel");
        channel.bind("my-event", handler);

        return () => {
            channel.unbind("my-event", handler);
            pusher.unsubscribe("chat-channel");
            pusher.disconnect();
        };
    }, [handler]);

    const goToPage = (_path) => {
        let data = props.propsData ? props.propsData : props
        return data.history.push({
            pathname: _path,
        })
    }

    const consultId = useMemo(() => {
        return props.match.params.id
    }, [props.match.params.id])

    useEffect(() => {
        let isMounted = true;

        async function getConsult() {
            const response = await getConsultationById(consultId);
            const payload = { ...response };
            if (payload.customer) {
                payload.customer = await getPatientById(payload.customer.id);
            }
            if (payload.prescriptions && payload.prescriptions.length > 0) {
                payload.prescription = await getPrescriptionById(payload.prescriptions[0].id);
            }
            if (isMounted) {
                setData(payload);
            }
        }

        if (memoizedRole && memoizedRole === 'doctor') {
            getConsult();
        }


        return () => {
            isMounted = false;
        };
    }, [consultId, memoizedRole]);

    const handleSetConsult = useCallback(() => {
        if (data && memoizedRole && memoizedRole === 'doctor') {
            memoizedDispatch({
                type: ACTION_TYPE.SET_CONSULTATION,
                payload: {
                    data: {
                        ...data,
                        customer: data.customer,
                        prescription: data.prescriptions[0]
                    },
                    role: memoizedRole
                }
            })

            memoizedDispatch({
                type: ACTION_TYPE.UPDATE_CHAT,
                payload: data.chat
            })
        }
    }, [data, memoizedRole, memoizedDispatch])

    useEffect(() => {
        handleSetConsult()
    }, [handleSetConsult])

    // useEffect(() => {
    //     if (data && memoizedRole && memoizedRole === 'doctor') {
    //         memoizedDispatch({
    //             type: ACTION_TYPE.SET_CONSULTATION,
    //             payload: {
    //                 data: {
    //                     ...data,
    //                     customer: data.customer,
    //                     prescription: data.prescriptions[0]
    //                 },
    //                 role: memoizedRole
    //             }
    //         })

    //         memoizedDispatch({
    //             type: ACTION_TYPE.UPDATE_CHAT,
    //             payload: data.chat
    //         })
    //     }
    // }, [data, memoizedRole, memoizedDispatch])



    // useEffect(() => {
    //     // Pusher.logToConsole =
    //     // process.env.VUE_APP_PUSHER_DEBUG === "true" ? true : false;
    //     const pusher = new Pusher(getPushKey(), {
    //         cluster: "ap1",
    //     })

    //     const channel = pusher.subscribe("chat-channel");
    //     let context = { title: "Pusher" };

    //     async function handler(_data) {
    //         if (memorizedChatId) {
    //             const chats = memorizedChatId;
    //             const chat_id = chats && chats.id
    //             if (chat_id) {
    //                 const response = await getChat(chat_id);
    //                 memoizedDispatch({
    //                     type: ACTION_TYPE.UPDATE_CHAT,
    //                     payload: response
    //                 })
    //             }
    //         }
    //     }
    //     channel.bind("my-event", handler, context);


    // }, [memorizedChatId, memoizedDispatch])


    useEffect(() => {
        if (props.location.pathname.includes('create-new-prescription')) {
            memoizedDispatch({
                type: ACTION_TYPE.UPDATE_ACTION_TYPE,
                payload: {
                    action: 'new',
                    role: globalData.global.role
                }
            })
        }
    }, [props.location.pathname, globalData.global.role, memoizedDispatch])

    const getPatientData = useCallback(async (_id) => {
        if (!prescriptionDataLayer.patient && _id && globalData.global.role) {
            const data = await getPatientById(_id);
            memoizedDispatch({
                type: ACTION_TYPE.SET_PATIENT,
                payload: {
                    data: data,
                    role: globalData.global.role
                }
            })
        }
    }, [prescriptionDataLayer.patient, globalData.global.role, memoizedDispatch])

    const getPrescriptionData = useCallback(async (_id) => {
        if (!prescriptionDataLayer.prescription && _id && globalData.global.role) {
            const data = await getPrescriptionById(_id);
            memoizedDispatch({
                type: ACTION_TYPE.SET_PRESCRIPTION,
                payload: {
                    data: data,
                    role: globalData.global.role
                }
            })
        }
    }, [prescriptionDataLayer.prescription, memoizedDispatch, globalData.global.role])

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            let data = props.propsData ? props.propsData : props;
            if (prescriptionDataLayer.content_type === 'prescription') {
                const arrayOfId = data.location.pathname.split('/')
                if (arrayOfId.length > 0) {
                    getPatientData(arrayOfId[3])
                }
            }
        }


        return () => isMounted = false
    }, [getPatientData, props, prescriptionDataLayer.content_type])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            let data = props.propsData ? props.propsData : props;
            const arrayOfId = data.location.pathname.split('/')
            if (prescriptionDataLayer.content_type === 'prescription') {
                if (arrayOfId.length > 0) {
                    getPrescriptionData(arrayOfId[5])
                }
            }
        }

        return () => isMounted = false
    }, [getPrescriptionData, props, prescriptionDataLayer.content_type])

    // useEffect(() => {
    //     let isMounted = true;
    //     if (isMounted && !props.location.pathname.includes('create-new-prescription')) {
    //         let data = props.propsData ? props.propsData : props;
    //         if (prescriptionDataLayer.content_type === 'tele_medicine') {
    //             const arrayOfId = data.location.pathname.split('/');
    //             getConsultationData(arrayOfId[3])
    //         }
    //     }
    //     return () => isMounted = false
    // }, [getConsultationData, props, prescriptionDataLayer.content_type]);

    useEffect(() => {
        if (prescriptionDataLayer.action_type.type) {
            if ((prescriptionDataLayer.action_type.type === 'completed_consultation') || (prescriptionDataLayer.action_type.type === 'accepted_consultation')) {
                setShowChat(true)
            }
        }
    }, [prescriptionDataLayer.action_type.type])

    const scrollbottom = () => {
        let element = document.getElementById("bottom-container");

        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
    }
    return (
        <React.Fragment>
            <div className='page-content overflow-auto' style={{ height: '100vh' }}>
                <Container fluid>
                    {!prescriptionDataLayer.loading ? (<div>
                        {prescriptionDataLayer.action_type.type === 'view_pdf' ?
                            <PrescriptionDoc goToPage={goToPage} />
                            : <PrescriptionFormContent goToPage={goToPage} scrollbottom={scrollbottom} />
                        }
                        {showChat ? <PopupChat
                            scrollbottom={scrollbottom}
                        /> : null}
                    </div>) : (<div className='d-flex justify-content-center align-items-center w-100' style={{ height: '80vh' }}>
                        <Spinner color='muted' size='sm' />
                        <p className='ml-2 mb-0 text-muted'>Loading...</p>
                    </div>)}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PrescriptionForm