import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { logoutUser } from '../../store/actions';

const Logout = (props) => {
    useEffect(() => {
        let isMounted = true
        async function changeDocStatus() {
            if (isMounted) props.logoutUser(props.history);
        }

        changeDocStatus()
        return () => isMounted = false
    }, [props])
    return (
        <React.Fragment>
            <h1>&nbsp;</h1>
        </React.Fragment>
    )
}

export default withRouter(connect(null, { logoutUser })(Logout));

