import { get, post, update } from "../../helpers/API"


// GET API
export const getPatientById = async (_id) => {
    return await get(`/customers/${_id}`)
}
export const countPrescription = async (url) => {
    return await get(`/prescriptions/count?${url}`)
}

export const getPrescription = async (url) => {
    return await get(`/prescriptions?${url}`)
}

export const getConsultations = async (url) => {
    return await get(`/consultations?${url}`)
}

export const getConsultationById = async (_id) => {
    return await get(`/consultations/${_id}`)
}

export const getChat = async (_id) => {
    return await get(`/chats/${_id}`)
}

export const getCorporateOrganizationById = async (_id) => {
    return await get(`/corporate-organisations/${_id}`)
}

export const getPrescriptionById = async (_id) => {
    return await get(`/prescriptions/${_id}`)
}

export const getCorporateOrganisationById = async (_id) => {
    return await get(`/corporate-organisations/${_id}`)
}
// END GET API
// PUT API
export const putConsultation = async (data, _id) => {
    return await update(`/consultations/${_id}`, data)
}
export const putPrescription = async (data, _id) => {
    return await update(`/prescriptions/${_id}`, data)
}

export const putPatient = async (data, _id) => {
    return await update(`/customers/${_id}`, data)
}

export const putChats = async (data, _id) => {
    return await update(`/chats/${_id}`, data)
}

export const putUser = async (data, _id) => {
    return await update(`/users/${_id}`, data)
}

export const putCorporateOrganisation = async (data, _id) => {
    return await update(`/corporate-organisations/${_id}`, data)
}

export const putMedicalCertificate = async (data, _id) => {
    return await update(`/medical-certificates/${_id}`, data)
}

export const putReferralLetter = async (data, _id) => {
    return await update(`/referral-letters/${_id}`, data)
}
// END PUT API

// POST
export const postUploadImage = async (data) => {
    return await post(`/upload`, data)
}
export const postPrescription = async (data) => {
    return await post(`/prescriptions`, data)
}
export const postMedicalCertificate = async (data) => {
    return await post(`/medical-certificates`, data)
}
export const postReferralLetter = async (data) => {
    return await post(`/referral-letters`, data)
}
// END POST API




