export const initialState = {
    loading: true,
    content_type: null,  // tele_medicine / prescription,
    action_type: {
        type: null,
        title: null
    }, // view_prescription / update_prescription / create_new_prescription / view_pdf
    action_button: {
        approve: false,
        reject: false,
        submit: false,
        save: false,
        cancel: false,
        questionaireForm: false,
        questionaireView: false,
        print: false,
        back: false,
        supply: false
    },
    chat: null,
    prescription: null,
    consultation: null,
    patient: null,
    from: null,
    edit_mode: false
} 
