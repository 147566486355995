export const initialDataTable = {
    columns: [
        // {
        //     label: '#',
        //     field: "index",
        //     sort: "asc",
        //     width: 30
        // },
        {
            label: "Image",
            field: "image",
            sort: "disabled",
            width: 150
        },
        {
            label: "Product Name",
            field: "title",
            // sort: "asc",
            width: 250
        },
        {
            label: "Category",
            field: "category",
            sort: "disabled",
            width: 150
        },
        {
            label: "Price",
            field: "price",
            sort: "disabled",
            width: 150
        },
        {
            label: "Inventory",
            field: "inventory",
            sort: "disabled",
            width: 150
        },
        {
            label: "In Promo",
            field: "inPromo",
            sort: "disabled",
            width: 150
        },
    ],
}