import React from 'react'
import { Button } from 'reactstrap'

const Diagnosis = ({ title, role, openModal, dataSource, setSelected, removeDiagnosis, editMode, action_type, content_type }) => {

  const handleUpdate = (data) => {
    openModal();
    setSelected(prev => {
      return {
        ...prev,
        diagnosis: data
      }
    })
  }

  const handleRemove = (data) => {
    removeDiagnosis(data)
  }
  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-middle align-items-center mb-3'>
        <h5 className='m-0'>{title}</h5>
        {((role && role !== 'doctor') || (role && role === 'doctor' && action_type === 'accepted_consultation' && content_type === 'tele_medicine'))  && editMode && <Button color='light' onClick={() => { openModal() }}>Add new diagnoses<i className="ri-add-line align-middle ri-lg ml-2"></i></Button>}
      </div>
      <div>
        {dataSource.length > 0 && dataSource.map((diagnosis, index) => {
          return (
            <div
              className='d-flex justify-content-between align-items-center border-top m-0 p-0' key={index + 1}>
              <div className='p-3'>
                <p className='m-0'>{diagnosis.icd}</p>
                <p className='text-muted small m-0'>{diagnosis.remarks}</p>
              </div>
              {editMode && <div className='p-3'>
                <Button color='light' onClick={() => handleUpdate({ ...diagnosis })} className='mr-2' outline><i className="ri-pencil-line align-middle"></i></Button>
                <Button color='light' onClick={() => handleRemove({ ...diagnosis })} outline><i className="ri-delete-bin-7-line align-middle"></i></Button>
              </div>}
            </div>
          )
        })}
      </div>
    </React.Fragment >
  )
}

export default Diagnosis