import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonDropdown, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Spinner, UncontrolledAlert, UncontrolledTooltip } from 'reactstrap'
import { getAPIroot } from '../../../../../../../helpers/API'
import { PRODUCT_ACTION_TYPE } from './store/ActionType'
import imgEmpty from '../../../../../../../assets/images/imgEmpty.png'

let imgEmptyStyle = {
    height: "226.5px",
    marginBottom: " 1.5rem",
    alignItems: "center",
    display: "flex",
    background: "#6b6c7e",
    borderRadius: "4px"
}

const ModalProduct = ({
    setVisibleModal,
    visibleModal,
    handleModal,
    products,
    loading,
    handleAddToCart,
    selectedProduct,
    initialParams,
    getProducts,
    listCategory,
    loadingC,
    dispatchProduct,
    decreaseQuantity,
    increaseQuantity,
    handleCollection,
    data,
    setListOfTable,
    handleSearhing,
    listOfTable,
    visiblePagination
}) => {
    const [list, setList] = useState([])
    const [btnCategory, setBtnCategory] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState('')

    useEffect(() => {
        if (selectedProduct.length > 0) {
            let new_array = [...products]
            for (let i = 0; i < new_array.length; i++) {
                for (let x = 0; x < selectedProduct.length; x++) {
                    if (new_array[i].id === selectedProduct[x].id) {
                        new_array[i].disabled = true
                        new_array[i].quantity = selectedProduct[x].quantity
                    }
                }
            }
            setList(new_array)
        } else {
            setList(products)
        }
    }, [products, selectedProduct])

    return (
        <Modal
            size='xl'
            center={true}
            isOpen={visibleModal}
            toggle={handleModal}
        >
            <ModalHeader
                toggle={() => {
                    setVisibleModal(!visibleModal)
                    dispatchProduct({
                        type: PRODUCT_ACTION_TYPE.ADD_PRODUCTS,
                        payload: data.medical_supplies
                    })
                    getProducts(1, 8, '', '')
                    setSelectedCategory('')
                    setListOfTable(listOfTable)
                }}
            >
                Medical supplies/Medication required
            </ModalHeader>
            <ModalBody>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 16 }}>
                    <div style={{ width: 'calc(100% - 224px)' }}>
                        <Input placeholder="Search" className="w-100" onChange={e => handleSearhing(e.target.value)} />
                    </div>
                    <div style={{ width: 214 }}>
                        <ButtonGroup>
                            <ButtonDropdown
                                isOpen={btnCategory}
                                toggle={() =>
                                    setBtnCategory(!btnCategory)
                                }
                            >
                                <Button id="caret" className="waves-effect" color="light"><i className=" ri-filter-2-fill mr-2 align-center" />Category</Button>
                                <DropdownToggle caret color="light" className="mr-2">
                                    <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {loadingC ? <div style={{ margin: '10px auto', textAlign: "center" }}><Spinner className="mr-2" color="primary" style={{ height: '1.5rem', width: '1.5rem' }} /></div> : listCategory.map((item, index) => {
                                        return (
                                            <DropdownItem key={index} onClick={() => {
                                                setSelectedCategory(item)
                                                getProducts(initialParams.page, initialParams.limit, item.id, initialParams.keywords)
                                            }}>{item.title} ({item.products_count})</DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                        <Button color="light" className="waves-effect"><i className="ri-shopping-cart-fill align-middle mr-2" />{selectedProduct.length}</Button>
                    </div>
                </div>

                {selectedCategory && <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: 16 }}>
                    <p className='mr-3'>Filter by:</p> <UncontrolledAlert color="secondary" className="alert-dismissible fade show" role="alert" onClick={() => {
                        setSelectedCategory('')
                        getProducts(initialParams.page, initialParams.limit, '', initialParams.keywords)
                    }}>
                        {selectedCategory.title}
                    </UncontrolledAlert>
                </div>}
                <div style={{ height: 'calc(100vh - 320px)', overflow: 'auto', marginBottom: '1rem' }}>
                    <Row className="no-gutters">
                        {loading ? <div style={{ margin: 'auto' }}>
                            <Spinner className="mr-2" color="primary" />
                        </div>
                            : list.map((item, index) => {
                                return (
                                    <Col xl={3} sm={6} key={index}>
                                        <div className="product-box" style={{ minHeight: 370 }}>
                                            <div className="product-img">
                                                {!item.variants || item.variants[0].image === null ?
                                                    <div style={imgEmptyStyle}><img className="img-fluid mx-auto d-block mb-0" src={imgEmpty} alt="" height="80" /></div> :
                                                    item.variants[0].image && item.variants[0].image.length !== 0 && <img className="img-fluid mx-auto d-block mb-4" style={{ boxShadow: '0px 1px 2px rgb(190 191 204)' }} alt="" height="80" src={getAPIroot() + item.variants[0].image[0].url} />}
                                            </div>

                                            <div className="text-center" style={{ minHeight: 80 }}>
                                                <h5 className="font-size-15">{!item.title ? "-" : item.title}</h5>

                                                <h5 className="mt-3 mb-0">{!item.variants ? "-" : item.variants[0].price.toFixed(2)}</h5>
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 16 }}>
                                                {item.disabled ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <div style={{ width: "120px" }} className="product-cart-touchspin">
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend" className="input-group-btn">
                                                                <Button onClick={() => decreaseQuantity(item)} color="primary" className="bootstrap-touchspin-down" >-</Button>
                                                            </InputGroupAddon>

                                                            <Input type="text" value={!item.quantity ? '-' : item.quantity} readOnly />
                                                            <InputGroupAddon addonType="append" className="input-group-btn">
                                                                <Button onClick={() => increaseQuantity(item)} color="primary" className="bootstrap-touchspin-down">+</Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </div>
                                                    <Link to="#" className="text-danger ml-2" id="delete2" onClick={() => {
                                                        dispatchProduct({
                                                            type: PRODUCT_ACTION_TYPE.REMOVE_ITEM,
                                                            payload: item
                                                        })
                                                        getProducts(initialParams.page, initialParams.limit, initialParams.category, initialParams.keywords)
                                                    }}><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                    <UncontrolledTooltip placement="right" target="delete2">Delete</UncontrolledTooltip >
                                                    {/* <Button className="waves-effect ml-2" color="link" onClick={() => {
                                                    dispatchProduct({
                                                        type: PRODUCT_ACTION_TYPE.REMOVE_ITEM,
                                                        payload: item
                                                    })
                                                    getProducts(1, 6, initialParams.category)
                                                }}>
                                                    <i className="ri-delete-bin-6-line align-middle" /></Button> */}
                                                </div> :
                                                    <Button className="waves-effect" color="light" disabled={!item.disabled ? false : true} onClick={() => handleAddToCart(item)}><i className="ri-add-line mr-2 align-middle" />Add to Cart</Button>}
                                            </div>

                                        </div>
                                    </Col>
                                )
                            })

                        }
                    </Row>
                </div>

                {visiblePagination &&
                    <Row className="mt-4">
                        <Col sm={6}>
                            <div>
                                <p className="mb-sm-0 mt-2">Page <span className="font-weight-bold">{initialParams.page}</span> Of <span className="font-weight-bold">{initialParams.total}</span></p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="float-sm-right">
                                <Pagination className="pagination-rounded mb-sm-0">
                                    <PaginationItem disabled={initialParams.page < 2}>
                                        <PaginationLink href="#" onClick={() => getProducts(initialParams.page - 1, initialParams.limit, initialParams.category, initialParams.keywords)}>Previous</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem active>
                                        <PaginationLink href="#">{initialParams.page}</PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem disabled={initialParams.page > initialParams.total}>
                                        <PaginationLink href="#" onClick={() => getProducts(initialParams.page + 1, initialParams.limit, initialParams.category, initialParams.keywords)}>Next</PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </Col>
                    </Row>
                }


                <div className="float-right">
                    <Button className="waves-effect mr-2" color="light" onClick={() => {
                        setVisibleModal(!visibleModal)
                        dispatchProduct({
                            type: PRODUCT_ACTION_TYPE.ADD_PRODUCTS,
                            payload: data.medical_supplies
                        })
                        getProducts(1, 8, '', '')
                        setSelectedCategory('')
                        setListOfTable(listOfTable)
                    }}>Cancel</Button>
                    <Button className="waves-effect" color="primary" onClick={() => {
                        handleCollection()
                        setVisibleModal(!visibleModal)
                    }}><i className="ri-shopping-cart-fill align-middle mr-2" />Add to Cart</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalProduct
