export const OptionCourier = [
    {
        label: 'Poslaju',
        value: 'poslaju'
    },
    {
        label: 'Jnt',
        value: 'jnt'
    },
    {
        label: 'Skynet',
        value: 'skynet'
    },
    {
        label: 'DHL',
        value: 'dhl'
    },
    {
        label: 'Fedex',
        value: 'fedex'
    },
    {
        label: 'Ups',
        value: 'ups'
    },
    {
        label: 'Ninja',
        value: 'ninja'
    },
    {
        label: 'City-Link',
        value: 'citylink'
    },
]

export const OptionExportStatus = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Paid',
        value: 'paid'
    },
]

export const OptionMonth = [
    {
        label: 'January',
        value: '01'
    },
    {
        label: 'February',
        value: '02'
    },
    {
        label: 'March',
        value: '03'
    },
    {
        label: 'April',
        value: '04'
    },
    {
        label: 'May',
        value: '05'
    },
    {
        label: 'June',
        value: '06'
    },
    {
        label: 'July',
        value: '07'
    },
    {
        label: 'August',
        value: '08'
    },
    {
        label: 'September',
        value: '09'
    },
    {
        label: 'October',
        value: '10'
    },
    {
        label: 'November',
        value: '11'
    },
    {
        label: 'December',
        value: '12'
    },
]