import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const Style = {
    root: {
        position: "relative",
        display: "block",
        width: "100%",
        paddingBottom: 15
    }
}

const ListLoading = (data) => {
    const [arr, setArr] = useState([])

    useEffect(() => {
        let array = []
        for (let i = 0; i < data.row; i++) {
            array.push(i)
        }

        setArr(array)
    }, [data.row])

    return arr.map((elements) => {
        return (
            <div style={Style.root} key={elements}>
                <Skeleton />
                <Skeleton width={'50%'} />
            </div>
        )
    })
}

export default ListLoading