import React from 'react'
import { Spinner } from 'reactstrap'

export const patientTabList = [
    {
        key: 'all',
        name: 'All'
    }
]

export const waitingApprovalNav = [
    {
        key: 'waiting_for_approval',
        name: 'Waiting for Approval'
    },
    {
        key: 'approved',
        name: 'Approved'
    },
    {
        key: 'rejected',
        name: 'Further Investigation'
    }
]

export const obj = {
    path: '/e-prescription/waiting-approval',
    title: "Approval",
    filter: "Approval"
}

export const initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: '',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

export const initialDataTableApproval = {
    columns: [
        {
            label: "#",
            field: "index",
            sort: "asc",
            width: 50
        },
        {
            label: "Patient Name",
            field: "patient_name",
            sort: "disabled",
        },
        {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 200
        },
        {
            label: "Date Updated",
            field: "date_updated",
            sort: "asc",
            width: 200
        },
        {
            label: "Medication Supply Status",
            field: "supply_status",
            sort: "disabled",
            width: 300
        },
    ]
}

export const initialDataTableApproval2 = {
    columns: [
        {
            label: "#",
            field: "index",
            sort: "asc",
            width: 50
        },
        {
            label: "Patient Name",
            field: "patient_name",
            sort: "disabled",
        },
        {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 160
        },
        {
            label: "Date Updated",
            field: "date_updated",
            sort: "asc",
            width: 200
        },
        {
            label: "Medication Supply Status",
            field: "supply_status",
            sort: "disabled",
            width: 200
        },
    ]
}

export const initialDataTablePatient = {
    columns: [
        {
            label: "Name",
            field: "name",
            sort: "disabled",
        },
        {
            label: "Gender",
            field: "gender",
            sort: "disabled",
            width: 100,
        },
        {
            label: "IC Number",
            field: "ic_number",
            sort: "disabled",
            width: 150,
        },
        {
            label: "DOB",
            field: "dob",
            sort: "disabled",
            width: 150,
        },
        {
            label: "Phone Number",
            field: "phone_number",
            sort: "disabled",
            width: 160,
        },
        {
            label: "Remark",
            field: "remark",
            sort: "disabled",
            width: 200,
        }
    ],
    rows: [{
        index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
    }]
}

export const initialDataTablePrescription = {
    columns: [
        {
            label: "#",
            field: "index",
            width: 50,
            sort: "disabled",
        },
        {
            label: "Status",
            field: "status",
            sort: "disabled",
        },
        {
            label: "Date created",
            field: "date_created",
            sort: "disabled",
            width: 300
        },
        {
            label: "Medication supply status",
            field: "medic_supply_status",
            sort: "disabled",
            width: 300
        },
    ],
    rows: [{
        index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
    }]
}

export const initialDataTableMedication = {
    columns: [
        {
            label: "#",
            field: "index",
            width: 50,
            sort: "disabled",
        },
        {
            label: "Medication",
            field: "medicine",
            sort: "disabled",
            width: 140
        },
        {
            label: "Dosage",
            field: "dosage",
            sort: "disabled",
            width: 100
        },
        {
            label: "Route",
            field: "route",
            sort: "disabled",
            width: 100
        },
        {
            label: "Frequency",
            field: "frequency",
            sort: "disabled",
            width: 100
        },
        {
            label: "Duration",
            field: "amount",
            sort: "disabled",
            width: 150
        },
        {
            label: "Remarks",
            field: "remarks",
            sort: "disabled",
           
        }
    ],
    rows: [{
        index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
    }]
}

export const addColAction = {
    label: "Actions",
    field: "actions",
    sort: "disabled",
    width: 150
}

export const initialDataTableDocMedication = {
    columns: [
        {
            label: "#",
            field: "index",
            width: 50,
            sort: "disabled",
        },
        {
            label: "Medication",
            field: "medicine",
            sort: "disabled",
        },
        {
            label: "Dosage",
            field: "dosage",
            sort: "disabled",
            width: 100
        },
        {
            label: "Route",
            field: "route",
            sort: "disabled",
            width: 100
        },
        {
            label: "Frequency",
            field: "frequency",
            sort: "disabled",
            width: 100
        },
        {
            label: "Request amount",
            field: "request_amount",
            sort: "disabled",
            width: 150
        },
        {
            label: "Supply amount",
            field: "supply_amount",
            sort: "disabled",
            width: 150
        },
        {
            label: "Supply status",
            field: "supply_status",
            sort: "disabled",
            width: 150
        },
        {
            label: "Remarks",
            field: "remarks",
            sort: "disabled",
            width: 100
        }
    ],
    rows: [{
        index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
    }]
}

export const initialDataTableMainPatient = {
    columns: [
        {
            label: "#",
            field: "index",
            width: 50,
            sort: "disabled",
        },
        {
            label: "Name",
            field: "name",
            sort: "asc",
        },
        {
            label: "ERX",
            field: "erx",
            sort: "asc",
            width: 200
        },
        {
            label: "Date registered",
            field: "date_registered",
            sort: "asc",
            width: 200
        },
    ]
}
