import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import ServerSidePaginations from '../../../../components/Table/ServerSidePaginations'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData';
import { BadgeStatus, FindLatestStatus } from '../../Store/CustomFunction';
import { initialDataTablePrescription, initialValue } from '../../Store/initialState';

const PrescriptionDetails = ({ title, role }) => {
  const [loading, setLoading] = useState(false);
  const { prescriptionDataLayer } = useContext(PrescriptionContext)
  const [dataSource, setDataSource] = useState(initialDataTablePrescription);

  useEffect(() => {
    if (prescriptionDataLayer.prescription) {
      const data = [prescriptionDataLayer.prescription]
      let rows = data.map((item, index) => {

        const statusData = FindLatestStatus('prescription_status', item.activity_statuses);
        const medicationData = FindLatestStatus('medical_status', item.activity_statuses)

        return {
          index: index + 1,
          status:
            <div>
              <div className={BadgeStatus(statusData).badge}>{BadgeStatus(statusData).text}</div>
              <h6 className='mt-2 mb-0'>{item.doctor && item.doctor.name}</h6>
              <p className='text-muted small'>{moment(item.updatedAt).format('DD MMMM YYYY')}</p>
            </div>,
          date_created: moment(item.createdAt).format('DD MMMM YYYY'),
          medic_supply_status:
            <div>
              <div className={BadgeStatus(medicationData).badge}>{BadgeStatus(medicationData).text}</div>
            </div>,
        }
      })

      setDataSource(prev => {
        return {
          ...prev,
          rows
        }
      })
    }
  }, [prescriptionDataLayer.prescription])
  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-middle align-items-center mb-3'>
        <h5 className='m-0'>{title}</h5>
      </div>
      <ServerSidePaginations
        loading={loading}
        dataSource={dataSource}
        obj={{}}
        initialParams={initialValue}
        setLoading={setLoading}
      />
    </React.Fragment>
  )
}

export default PrescriptionDetails