export const initialDataTable = {
    columns: [
        // {
        //     label: "#",
        //     field: "index",
        //     sort: "asc",
        //     width: 30
        // },
        {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 200
        },
        {
            label: "Type",
            field: "type",
            sort: "disabled",
            width: 150
        },
        {
            label: "Applicant",
            field: "id_card",
            sort: "disabled",
            width: 150
        },
        {
            label: "Contact Number",
            field: "contact_number",
            sort: "disabled",
            width: 150
        },
        {
            label: "Date Submitted",
            field: "createdAt",
            sort: "asc",
            width: 150
        },
        {
            label: "Checked By",
            field: "checked_by",
            sort: "asc",
            width: 150
        },
        {
            label: "Status",
            field: "status",
            sort: "disabled",
            width: 150
        },
    ],
}