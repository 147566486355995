import React, { useCallback, useContext, useEffect } from 'react'
import { Container } from 'reactstrap'
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext'
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { getPatientById } from '../../../../store/prescriptions/_api'
import PatientDetailsContent from './PatientDetailsContent'

const PatientDetails = (props) => {
    const { globalData } = useContext(GlobalContext)
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)

    const goToNextPage = (_path) => {
        return props.history.push({
            pathname: _path,
        })
    }

    const getPatientData = useCallback(async () => {
        if (!prescriptionDataLayer.patient && props.match.params.id && globalData.global.role) {
            if (prescriptionDataLayer.content_type === 'prescription') {
                const patients = await getPatientById(props.match.params.id);
                dispatch({
                    type: ACTION_TYPE.SET_PATIENT,
                    payload: {
                        data: patients,
                        role: globalData.global.role
                    }
                })
            }
        }
    }, [prescriptionDataLayer.content_type, prescriptionDataLayer.patient, globalData.global.role, props.match.params.id, dispatch])

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            getPatientData()
        }

        return () => false
    }, [getPatientData])
    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>

                    <PatientDetailsContent goToNextPage={goToNextPage} />

                </Container>
            </div>
        </React.Fragment>
    )
}

export default PatientDetails