import React, { useCallback, useContext, useEffect } from 'react'
import { Container } from 'reactstrap'
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import PatientContent from './PatientContent'


const PatientList = (props) => {
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext);

    const resetState = useCallback(() => {
        if (!prescriptionDataLayer.content_type && props.location.pathname) {
            dispatch({
                type: ACTION_TYPE.CHANGE_CONTENT_TYPE,
                payload: 'prescription'
            })
        }
    }, [prescriptionDataLayer.content_type, props.location.pathname, dispatch])
   
    useEffect(() => {
        let isMounted = false;

        if (isMounted) {
            resetState();
        }

        return () => false
    }, [resetState])

    const goToPage = (_path) => {
        return props.history.push({
            pathname: _path,
        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PatientContent
                        goToPage={goToPage}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PatientList