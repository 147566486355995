import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody } from 'reactstrap';

const style = {
    root: {
        width: "calc(100% / 4)",
        height: 300,
    }
}

const LoadingCard = (data) => {
    const [arr, setArr] = useState([])

    useEffect(() => {
        let array = []
        for (let i = 0; i < data.row; i++) {
            array.push(i)
        }

        setArr(array)
    }, [data.row])

    return (
        <div className='position-relative d-flex flex-wrap w-100'>
            {arr.map((elements) => {
                return (
                    <Card style={style.root} className="rounded-0 mb-0" key={elements}>
                        <CardBody>
                            <Skeleton height={100} />
                        </CardBody>
                        <CardBody>
                            <div className="mt-1"><Skeleton width={'70%'} /></div>
                            <div className="mt-1"><Skeleton width={'100%'} /></div>
                            <div className="mt-1"><Skeleton width={'100%'} /></div>
                            <div className="mt-1"><Skeleton width={'100%'} /></div>
                        </CardBody>
                    </Card>
                )
            })}
        </div>
    )
}

export default LoadingCard