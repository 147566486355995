import '../../assets/fonts/FiraSans-Regular-normal.js';
import '../../assets/fonts/FiraSans-Bold-bold.js';
import '../../assets/fonts/FiraSans-Italic-italic.js';
import '../../assets/fonts/FiraSans-BoldItalic-bolditalic.js';
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import writtenNumber from 'written-number'
import { capitalizeFirstLetter, converPriceWithCommas } from '../../store/CustomFunctions';
import moment from 'moment'
import imgSelcare from '../../assets/images/selcareLogo.png'
import imgSelcare2 from '../../assets/images/selcare.png'
import imgSelcateTransparent from '../../assets/images/selcareBT.png'


export const pdfTelemedicine = {
    pdfPrescription: function ({ prescription }) {
        const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: 'a4'
        });

        pdf.setLineWidth(0.2);
        const pageWidth = pdf.internal.pageSize.width
        const pageHeight = pdf.internal.pageSize.height
        const pageMaxHeight = pageHeight - 20;
        let botPosition = pageHeight - 10;
        const d_fs = 9;
        let topPosition = 10;
        const leftPostion = 20;
        const rightPostion = 190;
        pdf.setFont("FiraSans-Regular", "normal");

        var img = new Image();
        img.src = imgSelcare2;

        // bg-overlary
        var bg_img = new Image();
        bg_img.src = imgSelcateTransparent;
        var imgWidth = 160;
        var imgHeight = 75;
        var x = (pageWidth - imgWidth) / 2;
        var y = (pageHeight - imgHeight) / 2;
        pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);

        // Section Hearder
        pdf.addImage(img, 'png', leftPostion, topPosition, 18, 8)
        // ADDRESS
        topPosition = topPosition + 3;
        pdf.setFontSize(d_fs);
        pdf.text('No. 47, Ground Floor, Jalan Snuker 13/28,', leftPostion + 22, topPosition, null, null, "left")
        topPosition = topPosition + 4;
        pdf.text('Section 13, 40100 Shah Alam, Selangor', leftPostion + 22, topPosition, null, null, "left")
        pdf.text("HOTLINE: 1800 22 6600", rightPostion, topPosition, null, null, "right");
        topPosition = topPosition + 4;


        pdf.line(leftPostion, topPosition, rightPostion, topPosition);
        topPosition = topPosition + 6;

        // PRESCRIPTION INFO
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('PRESCRIPTION ID:', leftPostion, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text('#SCE-221229171052', leftPostion + 30, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.text('ID Priskripsi:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 6;

        pdf.setFontSize(d_fs);
        pdf.setTextColor(39, 39, 49);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('ISSUED ON:', leftPostion, topPosition, null, null, "left");
        // pdf.text('EXPIRY ON:', rightPostion - 37, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(moment(prescription.issued_on).format('DD/MM/YYYY'), leftPostion + 20, topPosition, null, null, "left");
        // pdf.text(moment(prescription.expired_on).format('DD/MM/YYYY'), rightPostion - 17, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.text('Dikeluarkan Pada:', leftPostion, topPosition, null, null, "left");
        pdf.text('Tarikh luput:', rightPostion - 37, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setLineWidth(0.1);
        pdf.setDrawColor(180);
        pdf.line(leftPostion, topPosition, rightPostion, topPosition);
        topPosition = topPosition + 6

        // Diagnosis
        pdf.setFontSize(d_fs);
        pdf.setTextColor(39, 39, 49);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('LIST OF DIAGNOSIS', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setFontSize(d_fs - 2)
        pdf.setTextColor(109, 109, 132);
        pdf.text('Senarai Diagnosis', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 7

        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.setFontSize(d_fs)
        pdf.setTextColor(39, 39, 49);

        pdf.setFillColor(230, 232, 239)
        pdf.rect(20, topPosition - 4, 170, 11, 'F');

        pdf.text('No.', leftPostion + 3, topPosition, 'left')
        pdf.text('ICD', leftPostion + 10, topPosition, 'left')
        pdf.text('Remark', leftPostion + 100, topPosition, 'left')

        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2)
        topPosition = topPosition + 5

        pdf.text('No.', leftPostion + 3, topPosition, 'left')
        pdf.text('ICD', leftPostion + 10, topPosition, 'left')
        pdf.text('Catatan', leftPostion + 100, topPosition, 'left')

        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs)
        pdf.setTextColor(39, 39, 49);
        topPosition = topPosition + 8

        prescription.diagnosis.forEach((element, index) => {
            var remarks = pdf.splitTextToSize(!element.remarks ? '-' : element.remarks, 65);
            var icd = pdf.splitTextToSize(!element.icd ? '-' : element.icd, 65);

            const baseHeight = topPosition;
            var icdHeight = baseHeight;
            var remarkHeight = baseHeight;

            for (var i = 0; i < icd.length; i++) {
                if (icdHeight > pageMaxHeight) {
                    icdHeight = 15;
                    pdf.addPage();
                }

                if (i === 0) pdf.text((index + 1).toString(), leftPostion + 3, icdHeight, 'left');
                pdf.text(icd[i], leftPostion + 10, icdHeight, null, null, "left");
                icdHeight = icdHeight + 4;
            }

            for (var f = 0; f < remarks.length; f++) {
                if (remarkHeight > pageMaxHeight) {
                    remarkHeight = 15;
                    pdf.addPage();
                }
                pdf.text(remarks[f], leftPostion + 100, remarkHeight, null, null, "left");
                remarkHeight = remarkHeight + 4;
            }

            topPosition = remarks.length > icd.length ? remarkHeight + 4 : icdHeight + 4;

            return element
        })

        topPosition = topPosition + 10;

        // Medicine
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('LIST OF MEDICATION', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setFontSize(d_fs - 2)
        pdf.setTextColor(109, 109, 132);
        pdf.text('Senarai ubat-ubatan', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 6

        pdf.setFillColor(230, 232, 239)
        pdf.rect(20, topPosition - 4, 170, 11, 'F');

        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.setFontSize(d_fs)
        pdf.setTextColor(39, 39, 49);

        pdf.text('No.', leftPostion + 3, topPosition, 'left')
        pdf.text('Medications', leftPostion + 10, topPosition, 'left')
        pdf.text('Dosage', leftPostion + 50, topPosition, 'left')
        pdf.text('Frequency', leftPostion + 68, topPosition, 'left')
        pdf.text('Duration', leftPostion + 90, topPosition, 'left')
        pdf.text('Remarks', leftPostion + 110, topPosition, 'left')

        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2)
        topPosition = topPosition + 5

        pdf.text('No.', leftPostion + 3, topPosition, 'left')
        pdf.text('Ubatan', leftPostion + 10, topPosition, 'left')
        pdf.text('Dos', leftPostion + 50, topPosition, 'left')
        pdf.text('Kekerapan', leftPostion + 68, topPosition, 'left')
        pdf.text('Jangka masa', leftPostion + 90, topPosition, 'left')
        pdf.text('Catatan', leftPostion + 110, topPosition, 'left')

        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs)
        pdf.setTextColor(39, 39, 49);
        topPosition = topPosition + 8;

        prescription.prescription_items.forEach((element, idx) => {
            var medicines = "-";
            if (!element.product) medicines = pdf.splitTextToSize(!element.medicine.description ? '' : element.medicine.description, 37);
            else medicines = pdf.splitTextToSize(!element.product.title ? '' : element.product.title, 37);
            var dosage = pdf.splitTextToSize(!element.dosage ? '' : element.dosage, 13);
            var frequency = pdf.splitTextToSize(!element.frequency ? '' : element.frequency, 19);
            var amount = pdf.splitTextToSize(!element.amount ? '' : element.amount.toString(), 19);
            var remarks = pdf.splitTextToSize(!element.remarks ? '' : element.remarks, 55);


            var baseHeight = topPosition;
            var medicinesHeight = baseHeight;
            var dosageHeight = baseHeight;
            var frequencyHeight = baseHeight;
            var amountHeight = baseHeight;
            var remarkHeight = baseHeight;

            for (var i = 0; i < medicines.length; i++) {
                if (medicinesHeight > pageMaxHeight) {
                    medicinesHeight = 15;
                    pdf.addPage();
                }

                if (i === 0) pdf.text((idx + 1).toString(), leftPostion + 3, medicinesHeight, 'left');
                pdf.text(medicines[i], leftPostion + 10, medicinesHeight, null, null, "left");
                medicinesHeight = medicinesHeight + 4;
            }

            for (var f = 0; f < dosage.length; f++) {
                if (dosageHeight > pageMaxHeight) {
                    dosageHeight = 15;
                    pdf.addPage();
                }
                pdf.text(dosage[f], leftPostion + 50, dosageHeight, null, null, "left");
                dosageHeight = dosageHeight + 4;
            }

            for (var g = 0; g < frequency.length; g++) {
                if (frequencyHeight > pageMaxHeight) {
                    frequencyHeight = 15;
                    pdf.addPage();
                }
                pdf.text(frequency[g], leftPostion + 68, frequencyHeight, null, null, "left");
                frequencyHeight = frequencyHeight + 4;
            }

            for (var q = 0; q < amount.length; q++) {
                if (amountHeight > pageMaxHeight) {
                    amountHeight = 15;
                    pdf.addPage();
                }
                pdf.text(amount[q], leftPostion + 90, amountHeight, null, null, "left");
                amountHeight = amountHeight + 4;
            }

            for (var x = 0; x < remarks.length; x++) {
                if (remarkHeight > pageMaxHeight) {
                    remarkHeight = 15;
                    pdf.addPage();
                }
                pdf.text(remarks[x], leftPostion + 110, remarkHeight, null, null, "left");
                remarkHeight = remarkHeight + 4;
            }

            topPosition = remarks.length > amount.length ? remarkHeight + 4 : medicines.length > remarks.length ? medicinesHeight : amountHeight + 4;

            return element
        })

        // Footer
        if (topPosition > 244) {
            pdf.addPage();
        }

        topPosition = topPosition + 175;
        pdf.setFont("FiraSans-Regular", "normal");
        topPosition = topPosition + 4;
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setFontSize(d_fs - 2);
        pdf.setTextColor(109, 109, 132);
        botPosition = botPosition - 36
        pdf.line(leftPostion, botPosition, rightPostion, botPosition);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.setTextColor(39, 39, 49);
        botPosition = botPosition + 6
        pdf.text('ISSUED BY:', leftPostion, botPosition, null, null, "left");
        botPosition = botPosition + 4
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.text('Dikeluarkan Oleh:', leftPostion, botPosition, null, null, "left");
        addFooterPageNumber(pdf)
        botPosition = botPosition + 4
        topPosition = 52;

        pdf.setFontSize(d_fs - 4);
        var footnote = pdf.splitTextToSize("Digital signature is verified by MSC Trustgate, A licensed Certification Authority (CA) in Malaysia since 2000 by Malaysian Communications and Multimedia Commssion (MCMC).", pdf.internal.pageSize.width - 40);
        var footnoteMalay = pdf.splitTextToSize("Tandatangan digital disahkan oleh MSC Trustgate, sebuah Autoriti Pensijilan (CA) berlesen di Malaysia sejak tahun 2000 oleh Suruhanjaya Komunikasi dan Multimedia Malaysia (MCMC).", pdf.internal.pageSize.width - 40)
        botPosition = pdf.internal.pageSize.height - 18
        for (var d = 0; d < footnote.length; d++) {
            pdf.text(footnote[d], leftPostion, botPosition, null, null, "left");
            botPosition = botPosition + 2.5;
        }
        for (var dM = 0; dM < footnoteMalay.length; dM++) {
            pdf.text(footnoteMalay[dM], leftPostion, botPosition, null, null, "left");
            botPosition = botPosition + 2.5;
        }

        // result
        // pdf.output('dataurlnewwindow');
        // pdf.save('prescription.pdf')
        let out = pdf.output('datauristring');
        let totalPages = pdf.internal.getNumberOfPages();
        return {
            data: out.split(',')[1],
            pageNo: totalPages
        }
    },
    pdfMedicalCert: function (data) {
        // console.log(data.prescription.prescription.doctor, data.prescription.prescription.doctor.specialist);
        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: ['148', '210']
        });


        const d_fs = 9;
        let topPosition = 10;
        let botPosition = pdf.internal.pageSize.height - 10;
        const leftPostion = 20;
        const rightPostion = pdf.internal.pageSize.width - 20;
        const alignTextCenter = (pdf.internal.pageSize.width / 2);
        pdf.setLineWidth(0.2);
        var img = new Image();
        img.src = imgSelcare2;
        pdf.setFont("FiraSans-Regular", "normal");

        // bg-overlary
        var bg_img = new Image();
        bg_img.src = imgSelcateTransparent;
        var imgWidth = 160;
        var imgHeight = 75;
        var pageWidth = pdf.internal.pageSize.width;
        var pageHeight = pdf.internal.pageSize.height;
        var x = (pageWidth - imgWidth) / 2;
        var y = (pageHeight - imgHeight) / 2;
        pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);

        // Section Hearder
        pdf.addImage(img, 'png', leftPostion, topPosition, 18, 8)
        // ADDRESS
        topPosition = topPosition + 3;
        pdf.setFontSize(d_fs);
        pdf.text('No. 47, Ground Floor, Jalan Snuker 13/28,', leftPostion + 22, topPosition, null, null, "left")
        topPosition = topPosition + 4;
        pdf.text('Section 13, 40100 Shah Alam, Selangor', leftPostion + 22, topPosition, null, null, "left")
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text("HOTLINE: 1800 22 6600", rightPostion, topPosition, null, null, "right");
        topPosition = topPosition + 4;


        pdf.line(leftPostion, topPosition, rightPostion, topPosition);
        topPosition = topPosition + 10;

        pdf.setFontSize(16)
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('MEDICAL CERTIFICATE', alignTextCenter, topPosition, null, null, 'center')
        topPosition = topPosition + 4;
        pdf.setFontSize(d_fs)
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setFontSize(d_fs - 2);
        pdf.setTextColor(109, 109, 132);
        pdf.text('Sijil Cuti Sakit', alignTextCenter, topPosition, null, null, 'center')
        pdf.setTextColor(39, 39, 49);
        topPosition = topPosition + 10;
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", "bold");
        pdf.text('Date:', rightPostion - 18, topPosition, null, null, 'right')
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(!data.medical ? '' : moment(data.medical.datetime).format('DD/MM/YYYY'), rightPostion, topPosition, null, null, 'right')
        topPosition = topPosition + 4;
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.text('Tarikh:', rightPostion - 18, topPosition, null, null, 'right')
        topPosition = topPosition + 10;
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs);
        pdf.setTextColor(39, 39, 49);
        pdf.text('This is to certify that', leftPostion, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-BoldItalic", "bolditalic");
        pdf.setDrawColor(180)
        pdf.text(`${!data.medical ? '' : data.medical.patient_name} ${!data.medical ? '' : '(I/C ' + data.medical.patient_nric + ' )'}`, leftPostion + 98, topPosition, null, null, 'center')
        pdf.line(leftPostion + 31, topPosition + 1, rightPostion, topPosition + 1);
        pdf.setFont("FiraSans-Regular", "normal")
        topPosition = topPosition + 6
        pdf.text(`of`, leftPostion, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-BoldItalic", "bolditalic");
        pdf.text(`${!data.medical ? '' : data.medical.patient_company}`, leftPostion + 37, topPosition, null, null, 'center')
        pdf.line(leftPostion + 7, topPosition + 1, leftPostion + 67, topPosition + 1);
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text('will be absent due to', leftPostion + 70, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-BoldItalic", "bolditalic");
        pdf.text(`${data.medical.reason ? data.medical.reason : '-'}`, leftPostion + 137, topPosition, null, null, 'center')
        pdf.line(leftPostion + 105, topPosition + 1, rightPostion, topPosition + 1);
        topPosition = topPosition + 6;
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(`for`, leftPostion, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-BoldItalic", "bolditalic");
        pdf.text(`${!data.medical.day_leave_count ? '1' : data.medical.day_leave_count} ${data.medical.day_leave_count > 1 ? 'days' : 'day'}`, leftPostion + 50.5, topPosition, null, null, 'center')
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.line(leftPostion + 8, topPosition + 1, leftPostion + 108, topPosition + 1);
        pdf.setFont("FiraSans-Regular", "normal")
        pdf.text('from', leftPostion + 111, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-BoldItalic", "bolditalic");
        pdf.text(`${!data.medical ? '' : moment(data.medical.day_leave_from).format('DD/MM/YYYY')} `, leftPostion + 130, topPosition, null, null, 'center')
        pdf.line(leftPostion + 120, topPosition + 1, rightPostion - 30, topPosition + 1);
        pdf.setFont("FiraSans-Regular", "normal")
        pdf.text('to', leftPostion + 143, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-BoldItalic", "bolditalic");
        pdf.text(`${!data.medical ? '' : moment(data.medical.day_leave_to).format('DD/MM/YYYY')} `, leftPostion + 160, topPosition, null, null, 'center')
        pdf.line(leftPostion + 148, topPosition + 1, rightPostion, topPosition + 1);

        pdf.setFontSize(d_fs - 1)
        topPosition = topPosition + 10
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.text('Dengan ini disahkan', leftPostion, topPosition, null, null, 'left')
        pdf.setDrawColor(180)
        pdf.text(`${!data.medical ? '' : data.medical.patient_name} ${!data.medical ? '' : '(I/C ' + data.medical.patient_nric + ' )'}`, leftPostion + 95, topPosition, null, null, 'center')
        pdf.line(leftPostion + 28, topPosition + 1, rightPostion, topPosition + 1);
        topPosition = topPosition + 6
        pdf.text(`dari`, leftPostion, topPosition, null, null, 'left')
        pdf.text(`${!data.medical ? '' : data.medical.patient_company}`, leftPostion + 37, topPosition, null, null, 'center')
        pdf.line(leftPostion + 7, topPosition + 1, leftPostion + 67, topPosition + 1);
        pdf.text('tidak dapat hadir kerana', leftPostion + 70, topPosition, null, null, 'left')
        pdf.text(`${data.medical.reason ? data.medical.reason : '-'}`, leftPostion + 140, topPosition, null, null, 'center')
        pdf.line(leftPostion + 105, topPosition + 1, rightPostion, topPosition + 1);
        topPosition = topPosition + 6;
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(`untuk`, leftPostion, topPosition, null, null, 'left')
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.text(`${!data.medical ? '' : data.medical.day_leave_count} hari`, leftPostion + 50.5, topPosition, null, null, 'center')
        pdf.line(leftPostion + 7, topPosition + 1, leftPostion + 113, topPosition + 1);
        pdf.text('dari', leftPostion + 114, topPosition, null, null, 'left')
        pdf.text(`${!data.medical ? '' : moment(data.medical.day_leave_from).format('DD/MM/YYYY')} `, leftPostion + 130, topPosition, null, null, 'center')
        pdf.line(leftPostion + 120, topPosition + 1, rightPostion - 30, topPosition + 1);
        pdf.text('ke', leftPostion + 143, topPosition, null, null, 'left')
        pdf.text(`${!data.medical ? '' : moment(data.medical.day_leave_to).format('DD/MM/YYYY')} `, leftPostion + 160, topPosition, null, null, 'center')
        pdf.line(leftPostion + 148, topPosition + 1, rightPostion, topPosition + 1);

        pdf.setFont("FiraSans-Regular", "normal");
        topPosition = topPosition + 4;
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setFontSize(d_fs - 2);
        pdf.setTextColor(109, 109, 132);

        botPosition = botPosition - 36
        pdf.line(leftPostion, botPosition, rightPostion, botPosition);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", "bold");
        pdf.setTextColor(39, 39, 49);
        botPosition = botPosition + 6
        pdf.text('ISSUED BY:', leftPostion, botPosition, null, null, "left");
        botPosition = botPosition + 4
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", "italic");
        pdf.setTextColor(109, 109, 132);
        pdf.text('Dikeluarkan Oleh:', leftPostion, botPosition, null, null, "left");

        pdf.setFontSize(d_fs - 4);
        var footnote = pdf.splitTextToSize("Digital signature is verified by MSC Trustgate, A licensed Certification Authority (CA) in Malaysia since 2000 by Malaysian Communications and Multimedia Commssion (MCMC).", pdf.internal.pageSize.width - 40);
        var footnoteMalay = pdf.splitTextToSize("Tandatangan digital disahkan oleh MSC Trustgate, sebuah Autoriti Pensijilan (CA) berlesen di Malaysia sejak tahun 2000 oleh Suruhanjaya Komunikasi dan Multimedia Malaysia (MCMC).", pdf.internal.pageSize.width - 40)
        botPosition = pdf.internal.pageSize.height - 18
        for (var d = 0; d < footnote.length; d++) {
            pdf.text(footnote[d], leftPostion, botPosition, null, null, "left");
            botPosition = botPosition + 2.5;
        }
        for (var dM = 0; dM < footnoteMalay.length; dM++) {
            pdf.text(footnoteMalay[dM], leftPostion, botPosition, null, null, "left");
            botPosition = botPosition + 2.5;
        }
        // pdf.text(`x2: ${leftPostion + 50} y2: ${topPosition}`, leftPostion + 50, topPosition)
        // pdf.rect(leftPostion, topPosition, 50, 25);
        // pdf.text(`x1: ${leftPostion} y1: ${topPosition + 25}`, leftPostion, topPosition + 25)
        // topPosition = topPosition + 32;

        // pdf.setFont("FiraSans-Regular", "normal");
        // pdf.setTextColor(39,39,49);
        // pdf.setFontSize(d_fs);
        // pdf.text(!data.prescription || !data.prescription.prescription.doctor ? '-' : data.prescription.prescription.doctor.name, leftPostion, topPosition, null, null, "left");
        // topPosition = topPosition + 4;
        // pdf.text(`(${!data.prescription || !data.prescription.prescription.doctor ? '-' : data.prescription.prescription.doctor.specialist})`, leftPostion, topPosition, null, null, "left");


        // result
        // pdf.save("MC.pdf");
        // pdf.output('dataurlnewwindow');
        let out = pdf.output('datauristring');
        let totalPages = pdf.internal.getNumberOfPages();
        return {
            data: out.split(',')[1],
            pageNo: totalPages
        }
    },
    pdfReferralLetter: function (data) {
        const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: 'a4'
        });
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setLineWidth(0.2);
        const d_fs = 9;

        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const pageMaxHeight = pageHeight - 20;
        let topPosition = 10;
        let botPosition = pageHeight - 10;
        const leftPostion = 20;
        const rightPostion = 190;
        const alignTextCenter = (pdf.internal.pageSize.width / 2);
        var img = new Image();
        img.src = imgSelcare2;

        // bg-overlary
        var bg_img = new Image();
        bg_img.src = imgSelcateTransparent;
        var imgWidth = 160;
        var imgHeight = 75;
        var x = (pageWidth - imgWidth) / 2;
        var y = (pageHeight - imgHeight) / 2;
        pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);

        addHeader(pdf, img, leftPostion, rightPostion, topPosition, d_fs);
        topPosition = topPosition + 11

        pdf.line(leftPostion, topPosition, rightPostion, topPosition);
        topPosition = topPosition + 10;

        pdf.setFontSize(16)
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('REFERRAL LETTER', alignTextCenter, topPosition, null, null, 'center')
        topPosition = topPosition + 4;
        pdf.setFontSize(d_fs)
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.setFontSize(d_fs - 2);
        pdf.setTextColor(109, 109, 132);
        pdf.text('Surat Rujukan', alignTextCenter, topPosition, null, null, 'center')
        pdf.setTextColor(39, 39, 49);
        topPosition = topPosition + 10;
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setFontSize(d_fs);

        // Body
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Referral To:', leftPostion, topPosition, null, null, "left");

        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Date of Referral:', alignTextCenter, topPosition, null, null, "left");

        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(!data.referral ? '-' : moment(data.referral.createdAt).format('DD/MM/YYYY'), alignTextCenter + 26, topPosition, null, null, "left");

        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.text('Rujukan Kepada', leftPostion, topPosition + 4, null, null, "left");
        pdf.text('Tarikh Rujukan', alignTextCenter, topPosition + 4, null, null, "left");

        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);

        var referralTo;
        if (data.referral && data.referral.referral_to) {
            referralTo = pdf.splitTextToSize(data.referral.referral_to, 60)

            for (var index = 0; index < referralTo.length; index++) {
                if (topPosition > pageMaxHeight) {
                    pdf.addPage();
                    pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
                }
                pdf.text(referralTo[index], leftPostion + 20, topPosition, null, null, "left");
                topPosition = topPosition + 4;
            }
        } else {
            pdf.text('-', leftPostion + 20, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }



        topPosition = topPosition + 6;
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.setTextColor(0)
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Referral For:', leftPostion, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(!data.referral ? '-' : capitalizeFirstLetter(data.referral.referral_for), leftPostion + 21, topPosition, null, null, "left");

        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Rujukan Untuk:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setDrawColor(180);
        pdf.setLineWidth(0.1);
        pdf.line(leftPostion, topPosition, rightPostion, topPosition);
        topPosition = topPosition + 6;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs + 1);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Patient Information', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setFontSize(d_fs - 2);
        pdf.setTextColor(109, 109, 132);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Maklumat Pesakit', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 6;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Patient Name:', leftPostion, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");


        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Patient NRIC:', alignTextCenter, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(!data.referral ? '-' : !data.referral.patient_nric ? '-' : data.referral.patient_nric, alignTextCenter + 22, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Name Pesakit:', leftPostion, topPosition, null, null, "left");
        pdf.text('No K/P Pesakit:', alignTextCenter, topPosition, null, null, "left");

        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);

        pdf.setFont("FiraSans-Regular", "normal");
        if (data.referral && data.referral.patient_name) {
            const patientName = pdf.splitTextToSize(data.referral.patient_name, 60);

            for (var indexs = 0; indexs < patientName.length; indexs++) {
                if (topPosition > pageMaxHeight) {
                    pdf.addPage();
                    pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
                }
                pdf.text(patientName[indexs], leftPostion + 23, topPosition - 4, null, null, "left");
                topPosition = topPosition + 4;
            }

        } else {
            pdf.text('-', leftPostion + 23, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }

        // pdf.text(!data.referral ? '-' : !data.referral.patient_name ? "-" : data.referral.patient_name, leftPostion + 23, topPosition, null, null, "left");

        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Patient Age:', leftPostion, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(!data.referral ? '-' : !data.referral.patient_age ? '-' : data.referral.patient_age, leftPostion + 20, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Patient Gender:', alignTextCenter, topPosition, null, null, "left");
        pdf.setFont("FiraSans-Regular", "normal");
        pdf.text(!data.referral ? '-' : !data.referral.patient_gender ? '-' : data.referral.patient_gender, alignTextCenter + 25, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Umur Pesakit:', leftPostion, topPosition, null, null, "left");
        pdf.text('Jantina Pesakit:', alignTextCenter, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.line(leftPostion, topPosition, rightPostion, topPosition);
        topPosition = topPosition + 6;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Dear Doctor,', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 8;



        var treatmenstText = pdf.splitTextToSize(data.referral.treatment, 180);
        var historyText = pdf.splitTextToSize(data.referral.patient_history, 180);
        var clinicalFindingText = pdf.splitTextToSize(data.referral.relevant_clinical_finding, 180);
        var provisionalText = pdf.splitTextToSize(data.referral.provisional_diagnosis, 180);
        var reasonForReferralText = pdf.splitTextToSize(data.referral.reason_for_referral, 180);


        // History
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('History:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Sejarah:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;

        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");

        for (var i = 0; i < historyText.length; i++) {
            if (topPosition > pageMaxHeight) {
                pdf.addPage();

                topPosition = 15;
                addHeader(pdf, img, leftPostion, rightPostion, topPosition, d_fs);
                topPosition = topPosition + 11

                pdf.setLineWidth(0.2);
                pdf.setDrawColor(0);
                pdf.line(leftPostion, topPosition, rightPostion, topPosition);
                topPosition = topPosition + 10;
                pdf.setDrawColor(180);
                pdf.setLineWidth(0.1);

                pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
            }
            pdf.text(historyText[i], leftPostion, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }

        topPosition = topPosition + 4;


        // Clinical Findings
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Clinical Findings:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Penemuan Klinikal:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");

        for (var j = 0; j < clinicalFindingText.length; j++) {
            if (topPosition > pageMaxHeight) {
                pdf.addPage();

                topPosition = 15;
                addHeader(pdf, img, leftPostion, rightPostion, topPosition, d_fs);
                topPosition = topPosition + 11

                pdf.setLineWidth(0.2);
                pdf.setDrawColor(0);
                pdf.line(leftPostion, topPosition, rightPostion, topPosition);
                topPosition = topPosition + 10;
                pdf.setDrawColor(180);
                pdf.setLineWidth(0.1);

                pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
            }
            pdf.text(clinicalFindingText[j], leftPostion, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }

        topPosition = topPosition + 4;

        // Provisional Diagnosis
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Provisional Diagnosis:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Diagnosis Sementara:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");

        for (var t = 0; t < provisionalText.length; t++) {
            if (topPosition > pageMaxHeight) {
                pdf.addPage();

                topPosition = 15;
                addHeader(pdf, img, leftPostion, rightPostion, topPosition, d_fs);
                topPosition = topPosition + 11

                pdf.setLineWidth(0.2);
                pdf.setDrawColor(0);
                pdf.line(leftPostion, topPosition, rightPostion, topPosition);
                topPosition = topPosition + 10;
                pdf.setDrawColor(180);
                pdf.setLineWidth(0.1);

                pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
            }
            pdf.text(provisionalText[t], leftPostion, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }
        topPosition = topPosition + 4;

        // Treatment
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Treatment:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Rawatan:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");


        for (var d = 0; d < treatmenstText.length; d++) {
            if (topPosition > pageMaxHeight) {
                pdf.addPage();

                topPosition = 15;
                addHeader(pdf, img, leftPostion, rightPostion, topPosition, d_fs);
                topPosition = topPosition + 11

                pdf.setLineWidth(0.2);
                pdf.setDrawColor(0);
                pdf.line(leftPostion, topPosition, rightPostion, topPosition);
                topPosition = topPosition + 10;
                pdf.setDrawColor(180);
                pdf.setLineWidth(0.1);

                pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
            }
            pdf.text(treatmenstText[d], leftPostion, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }

        topPosition = topPosition + 4;

        // Reason for Referral
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Reason for Referral:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Tujuan Rujukan:', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Regular", "normal");

        for (var r = 0; r < reasonForReferralText.length; r++) {
            if (topPosition > 244) {
                pdf.addPage();

                topPosition = 15;
                addHeader(pdf, img, leftPostion, rightPostion, topPosition, d_fs);
                topPosition = topPosition + 11

                pdf.setLineWidth(0.2);
                pdf.setDrawColor(0);
                pdf.line(leftPostion, topPosition, rightPostion, topPosition);
                topPosition = topPosition + 10;
                pdf.setDrawColor(180);
                pdf.setLineWidth(0.1);

                pdf.addImage(bg_img, 'png', x, y, imgWidth, imgHeight);
            }
            pdf.text(reasonForReferralText[r], leftPostion, topPosition, null, null, "left");
            topPosition = topPosition + 4;
        }

        topPosition = topPosition + 4;

        pdf.setTextColor(39, 39, 49);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.text('Thank you.', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;
        pdf.setTextColor(109, 109, 132);
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.text('Terima Kasih.', leftPostion, topPosition, null, null, "left");
        topPosition = topPosition + 4;

        // Footer
        botPosition = botPosition - 36
        pdf.setLineWidth(0.2)
        pdf.setDrawColor(0)
        pdf.line(leftPostion, botPosition, rightPostion, botPosition);
        pdf.setFontSize(d_fs);
        pdf.setFont("FiraSans-Bold", 'bold');
        pdf.setTextColor(39, 39, 49);
        botPosition = botPosition + 6
        pdf.text('ISSUED BY:', leftPostion, botPosition, null, null, "left");
        botPosition = botPosition + 4
        pdf.setFontSize(d_fs - 2);
        pdf.setFont("FiraSans-Italic", 'italic');
        pdf.setTextColor(109, 109, 132);
        pdf.text('Dikeluarkan Oleh:', leftPostion, botPosition, null, null, "left");


        pdf.setFontSize(d_fs - 4);
        var footnote = pdf.splitTextToSize("Digital signature is verified by MSC Trustgate, A licensed Certification Authority (CA) in Malaysia since 2000 by Malaysian Communications and Multimedia Commssion (MCMC).", pdf.internal.pageSize.width - 40);
        var footnoteMalay = pdf.splitTextToSize("Tandatangan digital disahkan oleh MSC Trustgate, sebuah Autoriti Pensijilan (CA) berlesen di Malaysia sejak tahun 2000 oleh Suruhanjaya Komunikasi dan Multimedia Malaysia (MCMC).", pdf.internal.pageSize.width - 40)
        botPosition = pdf.internal.pageSize.height - 18
        for (var ds = 0; ds < footnote.length; ds++) {
            pdf.text(footnote[ds], leftPostion, botPosition, null, null, "left");
            botPosition = botPosition + 2.5;
        }
        for (var dM = 0; dM < footnoteMalay.length; dM++) {
            pdf.text(footnoteMalay[dM], leftPostion, botPosition, null, null, "left");
            botPosition = botPosition + 2.5;
        }

        addFooterPageNumber(pdf)

        botPosition = botPosition + 4;


        // result
        // pdf.output('dataurlnewwindow');
        // pdf.save('refferal.pdf')
        let out = pdf.output('datauristring');
        let totalPages = pdf.internal.getNumberOfPages();
        return {
            data: out.split(',')[1],
            pageNo: totalPages
        }
    }
}

export const addHeader = (pdf, img, leftPostion, rightPostion, topPosition, d_fs) => {
    // Section Hearder
    pdf.addImage(img, 'png', leftPostion, topPosition, 18, 8)
    // ADDRESS
    topPosition = topPosition + 3;
    pdf.setFontSize(d_fs);
    pdf.text('No. 47, Ground Floor, Jalan Snuker 13/28,', leftPostion + 22, topPosition, null, null, "left")
    topPosition = topPosition + 4;
    pdf.text('Section 13, 40100 Shah Alam, Selangor', leftPostion + 22, topPosition, null, null, "left")
    pdf.setFont("FiraSans-Bold", 'bold');
    pdf.text("HOTLINE: 1800 22 6600", rightPostion, topPosition, null, null, "right");
    topPosition = topPosition + 4;

    pdf.setFont("FiraSans-Regular", "normal");
    pdf.setTextColor(0)
    pdf.setFontSize(d_fs);
}

export const addFooterPageNumber = doc => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont("FiraSans-Italic", 'italic');
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, null, null, 'right')
    }
}


export const pdfInvoice = {
    generateInvoice: async function (product, totalOrder, selectedOrder, pharmacyAddress, billingAddress, deliveryAddress) {
        const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: 'a4'
        });
        const alignTextCenter = (pdf.internal.pageSize.width / 2);

        var lineSpaceText = function (x, line) {
            return (x + line) + 2
        }
        const intPart = parseInt(totalOrder);
        const decimalPart = parseInt((totalOrder - intPart) * 100);

        // Invoice Header
        var address1 = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.address1.toUpperCase()}, \n`
        var address2 = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.address2.toUpperCase()}, \n`
        var zipcode = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.zipcode.toUpperCase()}, `
        var city = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.city.toUpperCase()}, `
        var province = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.province.toUpperCase()}, \n`
        var country = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.country.toUpperCase()}, \n`
        var phoneNum = `TEL: ${pharmacyAddress.org_phone_num === undefined ? "" : pharmacyAddress.org_phone_num}     FAX: ${!pharmacyAddress.org_phone_num ? "" : pharmacyAddress.org_phone_num} `


        var address = `${address1}${address2}${zipcode}${city}${province}${country}${phoneNum}`
        var companyName = `${pharmacyAddress.address.company.toUpperCase()}`
        var gstId = '-'

        pdf.setFontSize(12);
        pdf.text(companyName, alignTextCenter, lineSpaceText(15, 0), null, null, 'center');
        pdf.setFontSize(9);
        pdf.text(`GST ID: ${gstId}`, alignTextCenter, lineSpaceText(15, 4), null, null, 'center')
        pdf.text(address, alignTextCenter, lineSpaceText(15, 8), null, null, 'center')

        // inovice info
        pdf.setFontSize(9);

        // Right side
        // Label
        pdf.setFontSize(7);
        pdf.setTextColor(62, 71, 87);
        pdf.text('DELIVERY ADDRESS:', 14, 46.5)
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(9);
        pdf.text(deliveryAddress.company, 14, 50.5)
        pdf.text(deliveryAddress.address1, 14, 54.5)
        pdf.text(deliveryAddress.address2, 14, 58.5)
        pdf.text((deliveryAddress.zipcode ? `${deliveryAddress.zipcode}, ` : '') + deliveryAddress.city, 14, 62.5)
        pdf.text((deliveryAddress.province ? `${deliveryAddress.province}, ` : '') + deliveryAddress.country, 14, 66.5)
        pdf.text('Attention', 14, 70.5)
        // pdf.text('Transport', 14, 79.5)
        // Value
        pdf.text(`: ${selectedOrder.checked_by && selectedOrder.checked_by.username ? selectedOrder.checked_by.username : ''}`, 30, 70.5)
        // pdf.text(': -', 30, 79.5)

        // Middle side
        // Label
        pdf.setFontSize(7);
        pdf.setTextColor(62, 71, 87);
        pdf.text('BILLING ADDRESS:', 80, 46.5)
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(9);
        pdf.text(billingAddress.name, 80, 50.5)
        pdf.text(billingAddress.address1, 80, 54.5)
        pdf.text(billingAddress.address2, 80, 58.5)
        pdf.text(billingAddress.address3, 80, 62.5)
        pdf.text(billingAddress.address4, 80, 66.5)
        pdf.text('Attention', 80, 70.5)
        pdf.text(`: ${billingAddress.attention}`, 95, 70.5)

        // Left side 
        // Label
        pdf.text('Invoice No', 145, 50.5)
        pdf.text('Submitted On', 145, 54.5)
        pdf.text('Approved On', 145, 58.5)
        pdf.text('Terms', 145, 62.5)
        pdf.text('Page No', 145, 66.5)
        // pdf.text('Remark:', 135, 70.5)
        // pdf.text('S/O No:', 135, 74.5)
        // pdf.text('Consignment No /\nAirway Bill No', 135, 79.5)

        // Value
        const pharmacyInfo = selectedOrder.record_status.find(x => x.role === 'pharmacy')

        pdf.text(`: ${selectedOrder.order_name}`, 165, 50.5)
        pdf.text(`: ${moment(selectedOrder.createdAt).format('DD MMM YYYY')}`, 165, 54.5)
        pdf.text(`: ${!pharmacyInfo ? "-" : moment(pharmacyInfo.datetime).format('DD MMM YYYY')}`, 165, 58.5)
        pdf.text(': 60 Days', 165, 62.5)
        pdf.text(`: ${pdf.internal.pages.length}`, 165, 66.5)
        // pdf.text(': -', 165, 70.5)
        // pdf.text(': -', 165, 74.5)
        // pdf.text(': -', 165, 79.5)


        var head = [['No', 'Description \nExpiry Date & Batch No', 'Qty', 'UOM', 'U.Price', 'Disc', 'Sub Total', 'Sub Total Excel \n GST (RM)', 'GST Amt \n @ 6% (RM)', 'Total Incl \n GST (RM)', 'Tax Code']]
        var body = [];


        if (product.length > 0) {
            product.map((x, i) => {
                const price = converPriceWithCommas(x.variants[0].price)
                const totalPrice = converPriceWithCommas(x.variants[0].price * x.quantity)

                body.push([i + 1, x.title, x.quantity, x.variants[0].uom, price, "0.00", '0.00', totalPrice, totalPrice, '0.00', totalPrice])
                return x
            })
        }

        pdf.autoTable({
            head: head,
            body: body,
            startY: 85,
            showHead: 'firstPage',
            styles: {
                autoSize: true,
                printHeaders: false,
                columnWidths: 80,
                fontSize: 9
            },
            headStyles: {
                fontStyle: 'bold',
                fillColor: [228, 231, 237],
                textColor: [0, 0, 0],
                fontSize: 9,
            },
            bodyStyles: {
                fontStyle: 'normal',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 9,
            },
        })

        var head2 = [['GST Summary', 'Tax (RM)', 'Amount (RM)']]
        var body2 = [
            ['0%', '0.00', '' + converPriceWithCommas(selectedOrder.total_price)],
        ];
        var calcHeight = 0
        if (parseInt(pdf.lastAutoTable.finalY) < 180) {
            calcHeight = pdf.lastAutoTable.finalY + 10
        } else {
            pdf.addPage()
            calcHeight = 30
        }

        pdf.setFontSize(9)
        pdf.autoTable({
            head: head2,
            body: body2,
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.2,
            startY: calcHeight,
            showHead: 'firstPage',
            styles: {
                autoSize: true,
                printHeaders: false,
                columnWidths: 80,
                fontSize: 9,
                overflow: 'hidden'
            },
            headStyles: {
                fontStyle: 'bold',
                fontSize: 9,
            },
            bodyStyles: {
                fontStyle: 'normal',
                fontSize: 9,
            },
            theme: 'plain'
        })

        var head3 = [['', 'Sub Total', 'Sub Total Excel \n GST (RM)', 'GST Amt \n @ 6% (RM)', 'Total Incl \n GST (RM)']]
        var body3 = [
            ['Sub Total', '', '', '', converPriceWithCommas(selectedOrder.total_price)],
            ['Rounding ADI', '', '', '', ''],
            ['Total', converPriceWithCommas(selectedOrder.total_price), converPriceWithCommas(selectedOrder.total_price), '0.00', converPriceWithCommas(selectedOrder.total_price)]
        ];

        pdf.setFontSize(9)
        pdf.autoTable({
            head: head3,
            body: body3,
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.2,
            startY: calcHeight + 20,
            showHead: 'firstPage',
            styles: {
                autoSize: true,
                printHeaders: false,
                columnWidths: 80,
                fontSize: 9,
                overflow: 'hidden'
            },
            headStyles: {
                fontStyle: 'bold',
                fontSize: 9,
            },
            bodyStyles: {
                fontStyle: 'normal',
                fontSize: 9,
            },
            theme: 'plain',
            allSectionHooks: true,
            didParseCell: function (data) {
                if (data.column.dataKey === 0) {
                    data.cell.styles.fontStyle = 'bold'
                }
            }
        })

        var totalWords = writtenNumber(intPart).toUpperCase()
        if (decimalPart > 1) {
            totalWords += ' AND ' + writtenNumber(decimalPart).toUpperCase() + ' CENT'
        }
        pdf.text('RINGGIT: ' + totalWords, 14, pdf.lastAutoTable.finalY + 10)

        var calcHeightFooter = 0
        if ((parseInt(pdf.lastAutoTable.finalY) >= 82) && (parseInt(pdf.lastAutoTable.finalY) <= 184)) {
            calcHeightFooter = pdf.lastAutoTable.finalY
        } else {
            pdf.addPage()
            calcHeightFooter = 0

        }

        // footer
        // Left side
        // Label
        pdf.text('Name', 14, calcHeightFooter + 20)
        pdf.text('Bank Account', 14, calcHeightFooter + 24)
        pdf.text('Location', 14, calcHeightFooter + 28)
        pdf.text('Authorized Signature', 14, calcHeightFooter + 52)

        // Value
        pdf.text(`: ${companyName}`, 25, calcHeightFooter + 20)
        pdf.text(': 5621-0670-5587 (MAYBANK)', 35, calcHeightFooter + 24)
        pdf.text(`: ${companyName}\n  for ${companyName}`, 28, calcHeightFooter + 28)

        pdf.line(14, calcHeightFooter + 48, 60, calcHeightFooter + 48);

        // Right side
        // Label
        pdf.text('Received the following goods \nin good order and condition', pdf.internal.pageSize.width - 60, calcHeightFooter + 28)
        pdf.text('Authorized Signature', pdf.internal.pageSize.width - 60, calcHeightFooter + 52)

        pdf.line(pdf.internal.pageSize.width - 60, calcHeightFooter + 48, pdf.internal.pageSize.width - 14, calcHeightFooter + 48);

        pdf.save("invoice.pdf");
    }
}

// export default pdfGenerator;

export const pdfPoisonForm = {
    generatorClinic: async function (product, totalOrder, selectedOrder, pharmacyAddress, billingAddress, deliveryAddress) {
        const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: 'a4'
        });
        const alignTextCenter = (pdf.internal.pageSize.width / 2);

        var lineSpaceText = function (x, line) {
            return (x + line) + 2
        }

        // Invoice Header
        var address1 = ` , \n`
        var address2 = ` , \n`
        var zipcode = ` , `
        var city = ` , `
        var province = ` , \n`
        var country = ` , \n`
        var phoneNum = `TEL:     FAX: -`
        var companyName = ` `
        var address = ` `

        if (pharmacyAddress.address !== undefined && pharmacyAddress.address !== null) {
            address1 = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.address1.toUpperCase()}, \n`
            address2 = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.address2.toUpperCase()}, \n`
            zipcode = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.zipcode.toUpperCase()}, `
            city = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.city.toUpperCase()}, `
            province = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.province.toUpperCase()}, \n`
            country = `${pharmacyAddress.address === undefined ? "" : pharmacyAddress.address.country.toUpperCase()}, \n`
            phoneNum = `TEL:${pharmacyAddress.org_phone_num === undefined ? "" : pharmacyAddress.org_phone_num}     FAX: -`
            companyName = `${!pharmacyAddress.address.company ? '' : pharmacyAddress.address.company.toUpperCase()}`
            address = `${address1}${address2}${zipcode}${city}${province}${country}${phoneNum}`
        }

        var gstId = '-'
        pdf.setFontSize(12);
        pdf.text(companyName, alignTextCenter, lineSpaceText(15, 0), null, null, 'center');
        pdf.setFontSize(9);
        pdf.text(`GST ID: ${gstId}`, alignTextCenter, lineSpaceText(15, 4), null, null, 'center')
        pdf.text(address, alignTextCenter, lineSpaceText(15, 8), null, null, 'center')

        // inovice info
        pdf.setFontSize(9);

        // Right side
        // Label
        pdf.setFontSize(7);
        pdf.setTextColor(62, 71, 87);
        pdf.text('DELIVERY ADDRESS:', 14, 46.5)
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(9);
        pdf.text(deliveryAddress.company, 14, 50.5)
        pdf.text(deliveryAddress.address1, 14, 54.5)
        pdf.text(deliveryAddress.address2, 14, 58.5)
        pdf.text((deliveryAddress.zipcode ? `${deliveryAddress.zipcode}, ` : '') + deliveryAddress.city, 14, 62.5)
        pdf.text((deliveryAddress.province ? `${deliveryAddress.province}, ` : '') + deliveryAddress.country, 14, 66.5)
        pdf.text('Attention', 14, 70.5)
        // pdf.text('Transport', 14, 79.5)

        // Value
        pdf.text(`: ${selectedOrder.checked_by && selectedOrder.checked_by.username ? selectedOrder.checked_by.username : ''}`, 30, 70.5)
        // pdf.text(': -', 30, 79.5)

        // Middle side

        // Left side 
        // Label
        pdf.text('Poison Order No', 140, 50.5)
        pdf.text('Submitted On', 140, 54.5)
        pdf.text('Approved On', 140, 58.5)
        pdf.text('Terms', 140, 62.5)
        pdf.text('Page No', 140, 66.5)

        // Value
        var strRemove = selectedOrder.order_name;

        const pharmacyInfo = selectedOrder.record_status.find(x => x.role === 'pharmacy')

        pdf.text(`: ${strRemove}`, 165, 50.5)
        pdf.text(`: ${moment(selectedOrder.createdAt).format('DD MMM YYYY')}`, 165, 54.5)
        pdf.text(`: ${!pharmacyInfo ? "-" : moment(pharmacyInfo.datetime).format('DD MMM YYYY')}`, 165, 58.5)
        pdf.text(': 60 Days', 165, 62.5)
        pdf.text(`: ${pdf.internal.pages.length}`, 165, 66.5)


        // var head = [['No', 'Description \nExpiry Date & Batch No', 'Qty', 'UOM', 'U.Price', 'Disc', 'Sub Total', 'Sub Total Excel \n GST (RM)', 'GST Amt \n @ 6% (RM)', 'Total Incl \n GST (RM)', 'Tax Code']]
        var head = [['No', 'Description \nExpiry Date & Batch No', 'Qty', 'Qty Supplied', 'UOM', 'U.Price (RM)', 'Amount (RM)']]
        var body = [];


        if (product.length > 0) {
            product.map((x, i) => {
                const price = converPriceWithCommas(x.variants[0].price)
                const totalPrice = converPriceWithCommas(x.variants[0].price * x.quantity)

                body.push([i + 1, x.title, x.quantity, x.variants[0].quantity_supplied, x.variants[0].uom, price.toString(), totalPrice.toString()])
                return x
            })
        }

        body.push(['', '', '', '', '', ''])
        body.push(['', 'Sub Total', '', '', '', converPriceWithCommas(selectedOrder.subtotal_price)])
        body.push(['', 'Transportation Fee', '', '', '', '25.00'])
        body.push(['', 'Total', '', '', '', converPriceWithCommas(selectedOrder.total_price)])

        pdf.autoTable({
            head: head,
            body: body,
            startY: 85,
            showHead: 'firstPage',
            didParseCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = [228, 231, 237];
                }

                if (data.row.index === rows.length - 2) {
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = [228, 231, 237];
                }

                if (data.row.index === rows.length - 3) {
                    data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = [228, 231, 237];
                }

            },
            styles: {
                autoSize: true,
                printHeaders: false,
                columnWidths: 80,
                fontSize: 9
            },
            headStyles: {
                fontStyle: 'bold',
                fillColor: [228, 231, 237],
                textColor: [0, 0, 0],
                fontSize: 9,
            },
            bodyStyles: {
                fontStyle: 'normal',
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontSize: 9,
                halign: "center"
            },
            columnStyles: {
                0: { halign: "left" },
                1: { halign: "left" }
            },

            theme: 'plain'
        })

        var calcHeightFooter = 0
        if ((parseInt(pdf.lastAutoTable.finalY) >= 82) && (parseInt(pdf.lastAutoTable.finalY) <= 184)) {
            calcHeightFooter = pdf.lastAutoTable.finalY
        } else {
            pdf.addPage()
            calcHeightFooter = 0

        }
        // footer
        // Left side
        // Label
        pdf.text('Name', 14, calcHeightFooter + 20)
        pdf.text('Bank Account', 14, calcHeightFooter + 24)
        pdf.text('Location', 14, calcHeightFooter + 28)
        pdf.text('Authorized Signature', 14, calcHeightFooter + 52)

        // Value
        pdf.text(`: ${companyName}`, 25, calcHeightFooter + 20)
        pdf.text(': 5621-0670-5587 (MAYBANK)', 35, calcHeightFooter + 24)
        pdf.text(`: ${companyName}\n  for ${companyName}`, 28, calcHeightFooter + 28)

        pdf.line(14, calcHeightFooter + 48, 60, calcHeightFooter + 48);

        // Right side
        // Label
        pdf.text('Received the following goods \nin good order and condition', pdf.internal.pageSize.width - 60, calcHeightFooter + 28)
        pdf.text('Authorized Signature', pdf.internal.pageSize.width - 60, calcHeightFooter + 52)

        pdf.line(pdf.internal.pageSize.width - 60, calcHeightFooter + 48, pdf.internal.pageSize.width - 14, calcHeightFooter + 48);
        pdf.save("poison_form.pdf");
        // pdf.output('dataurlnewwindow');
    }
}

export const pdfGeneratorQuotationBooking = {
    generatorInvoiceBooking: async function (data) {
        const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: 'a4'
        });

        const alignTextCenter = (pdf.internal.pageSize.width / 2);

        var lineSpaceText = function (x, line) {
            return (x + line) + 2
        }

        var img = new Image()

        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 0);
        pdf.setDrawColor(235, 238, 242);
        pdf.setTextColor(0, 0, 0);
        pdf.setFillColor(235, 87, 87);

        img.src = imgSelcare;

        // Section Hearder

        pdf.addImage(img, 'png', 15, 15, 58, 13)
        pdf.setFontSize(8);
        pdf.setFont("helvetica", "bold");
        if (data.address_from) {

            let name = data.address_from && data.address_from.firstname ? data.address_from.firstname : '-'
            let zipcode = data.address_from.zipcode
            let province = data.address_from.province
            let country = data.address_from.country
            pdf.setTextColor(34, 34, 34);
            pdf.text(name, pdf.internal.pageSize.width - 15, lineSpaceText(17, 0), null, null, 'right');
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");
            pdf.text(`${data.address_from.address1}, ${data.address_from.address2}, ${zipcode},`, pdf.internal.pageSize.width - 15, lineSpaceText(20, 0), null, null, 'right')
            pdf.text(`${province}, ${country},`, pdf.internal.pageSize.width - 15, lineSpaceText(23, 0), null, null, 'right')
        }
        pdf.setFillColor(169, 26, 24);
        pdf.roundedRect(15, 32, pdf.internal.pageSize.width - 30, .1, 3, 3, "F");


        // Section Quotation
        pdf.roundedRect(pdf.internal.pageSize.width - 55, 36, 40, 7, 3.3, 3.3, "FD")

        // Title
        pdf.setFontSize(8);
        pdf.setTextColor(255, 255, 255);
        pdf.setFont("helvetica", "bold");
        pdf.text('QUOTATION', pdf.internal.pageSize.width - 19, lineSpaceText(38.5, 0), null, null, 'right')

        // LEFT
        let clientName = data.address_to && data.address_to.firstname ? data.address_to.firstname : '-'
        let clientPhone = data.address_to && data.address_to.phone ? data.address_to.phone : '-'

        pdf.setFontSize(7);

        pdf.setTextColor(37, 43, 59);
        pdf.setFont("helvetica", "normal");
        pdf.text('Client Name', 17, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${clientName}`, 35, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Address', 17, lineSpaceText(52, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);

        if (data.address_to) {
            let zipcode = data.address_to.zipcode
            let province = data.address_to.province
            let country = data.address_to.country
            pdf.text(`:  ${data.address_to.address1},`, 35, lineSpaceText(52, 0), null, null, 'left')
            pdf.text(`   ${data.address_to.address2},`, 35, lineSpaceText(56, 0), null, null, 'left')
            pdf.text(`   ${zipcode}, ${province}, ${country}`, 35, lineSpaceText(60, 0), null, null, 'left')
        }
        pdf.setTextColor(37, 43, 59);
        pdf.text('Phone No.', 17, lineSpaceText(65, 0), null, null, 'left')

        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${clientPhone}`, 35, lineSpaceText(65, 0), null, null, 'left')

        // RIGHT

        pdf.setTextColor(37, 43, 59);
        pdf.text('Date', pdf.internal.pageSize.width / 2 + 20, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${moment(data.date).format('DD/MM/YYYY')}`, pdf.internal.pageSize.width - 55, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Quatation No.', pdf.internal.pageSize.width / 2 + 20, lineSpaceText(52, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${data.invoice_number}`, pdf.internal.pageSize.width - 55, lineSpaceText(52, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Booking No.', pdf.internal.pageSize.width / 2 + 20, lineSpaceText(56, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${data.booking_number}`, pdf.internal.pageSize.width - 55, lineSpaceText(56, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Validity', pdf.internal.pageSize.width / 2 + 20, lineSpaceText(60, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(':  30 DAYS', pdf.internal.pageSize.width - 55, lineSpaceText(60, 0), null, null, 'left')

        let service = 0
        let addtional = 0
        let medical = 0
        let confinementService = 0
        let confinementAddons = 0

        if (data.line_items && data.line_items.length > 0) {
            const addRequiredService = data.line_items.find(x => x.title === 'Required Services')
            const addAdditionalTreatment = data.line_items.find(x => x.title === 'Additional Treatments')
            const addMedicalTreatment = data.line_items.find(x => x.title === 'Medical Supplies')
            const confinementServices = data.line_items.find(x => x.title === 'Confinement Services')
            const confinementAddOns = data.line_items.find(x => x.title === 'Confinement Addons')
            if (addRequiredService !== undefined) {
                let value = addRequiredService.items.length * 4
                service = value + 12
            }
            if (addAdditionalTreatment !== undefined) {
                let value = addAdditionalTreatment.items.length * 4;
                addtional = value + 12
            }
            if (addMedicalTreatment !== undefined) {
                let value = addMedicalTreatment.items.length * 4;
                medical = value + 12
            }
            if (confinementServices !== undefined) {
                let value = confinementServices.items.length * 4;
                confinementService = value + 12
            }
            if (confinementAddOns !== undefined) {
                let value = confinementAddOns.items.length * 4;
                confinementAddons = value + 12
            }
        }

        let resultHeight = data.category_type !== 'confinement' ? (service) + (addtional) + (medical) + (confinementAddons) + (confinementService) + 12 : (service) + (addtional) + (medical) + (confinementAddons) + (confinementService);

        let height = 78;
        // Section Descriptions
        // Title
        pdf.setFontSize(8);
        pdf.setTextColor(255, 255, 255);
        pdf.setFont("helvetica", "bold");
        pdf.setFillColor(169, 26, 24);
        pdf.roundedRect(15, 78, pdf.internal.pageSize.width - 30, 7, 3.3, 3.3, "FD")
        pdf.text('DESCRIPTION', 19, lineSpaceText(80.6, 0), null, null, 'left')
        pdf.text('AMOUNT (RM)', pdf.internal.pageSize.width - 19, lineSpaceText(80.6, 0), null, null, 'right')
        height = height + 2.6
        pdf.setFillColor(255, 255, 255);
        pdf.roundedRect(15, height + 7.4, pdf.internal.pageSize.width - 30, resultHeight, 3, 3, "FD")
        height = height + 4.4

        // rate fee
        if (data.category_type !== 'confinement') {
            pdf.setFont("helvetica", "bold");
            pdf.setTextColor(34, 34, 34);
            pdf.setFontSize(7);
            pdf.text('General care service (non-nursing)', 19, lineSpaceText(height + 7, 0), null, null, 'left')
            height = height + 7
            pdf.setFont("helvetica", "normal");
            pdf.setTextColor(37, 43, 59);
            pdf.text(`${converPriceWithCommas(data.total_rate_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
            pdf.text(data.days, 21, lineSpaceText(height + 4, 0), null, null, 'left');
        }

        // Confinement Services
        const confinementServices = data.line_items.find(x => x.title === 'Confinement Services')
        if (confinementServices !== undefined) {
            height = height + 4
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Confinement Services', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (data.line_items && data.line_items.length > 0) {
                const requiredServices = data.line_items.filter(x => x.title === 'Confinement Services')
                if (requiredServices.length > 0) {
                    for (let i = 0; i < requiredServices[0].items.length; i++) {
                        height = height + 4
                        pdf.text(requiredServices[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(requiredServices[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Confinement Addons
        const confinementAddOns = data.line_items.find(x => x.title === 'Confinement Addons')
        if (confinementAddOns !== undefined) {
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Confinement Addons', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (data.line_items && data.line_items.length > 0) {
                const confinementAddOns = data.line_items.filter(x => x.title === 'Confinement Addons')
                if (confinementAddOns.length > 0) {

                    for (let i = 0; i < confinementAddOns[0].items.length; i++) {
                        height = height + 4
                        pdf.text(confinementAddOns[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(confinementAddOns[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Required Services
        const requiredService = data.line_items.find(x => x.title === 'Required Services')
        if (requiredService !== undefined) {
            height = height + 4
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Required Services', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (data.line_items && data.line_items.length > 0) {
                const addTreatment = data.line_items.filter(x => x.title === 'Required Services')
                if (addTreatment.length > 0) {

                    for (let i = 0; i < addTreatment[0].items.length; i++) {
                        height = height + 4
                        pdf.text(addTreatment[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(addTreatment[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Additional Treatments
        const additionalTreatment = data.line_items.find(x => x.title === 'Additional Treatments')
        if (additionalTreatment !== undefined) {
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Additional Treatments', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (data.line_items && data.line_items.length > 0) {
                const addTreatment = data.line_items.filter(x => x.title === 'Additional Treatments')
                if (addTreatment.length > 0) {

                    for (let i = 0; i < addTreatment[0].items.length; i++) {
                        height = height + 4
                        pdf.text(addTreatment[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(addTreatment[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Medical Supplies
        const medicalTreatment = data.line_items.find(x => x.title === 'Medical Supplies')
        if (medicalTreatment !== undefined) {
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setTextColor(34, 34, 34);
            pdf.setFontSize(7);
            pdf.text('Medical Supplies', 19, lineSpaceText(height + 4, 0), null, null, 'left');
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");
            if (data.line_items && data.line_items.length > 0) {
                const addMedicalTreatment = data.line_items.filter(x => x.title === 'Medical Supplies')
                if (addMedicalTreatment.length > 0) {

                    for (let i = 0; i < addMedicalTreatment[0].items.length; i++) {
                        height = height + 4
                        pdf.text(addMedicalTreatment[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(addMedicalTreatment[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // total
        height = height + 12
        pdf.setFillColor('#F3F3F3');
        pdf.roundedRect(15, height, pdf.internal.pageSize.width - 30, 29, 3, 3, "FD");
        pdf.setTextColor(34, 34, 34);
        pdf.text('Subtotal', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.subtotal)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('Processing Fee (non-refundable)', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.processing_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('Deposit (non-refundable)', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.deposit_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('Transportation Fee (Fixed Fee)', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.transportation_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4

        pdf.setTextColor(34, 34, 34);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text('TOTAL AMOUNT', 19, lineSpaceText(height + 6.5, 0), null, null, 'left')
        pdf.text(`${converPriceWithCommas(data.total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 6.5, 0), null, null, 'right')
        height = height + 6.5

        if (height >= 244) {
            pdf.addPage();
            height = 15
        } else {
            height = pdf.internal.pageSize.height - 40
        }
        // Footer
        pdf.setTextColor(34, 34, 34);
        pdf.setFontSize(7);
        pdf.setFont("helvetica", "bold");
        pdf.text('Booking will only be confirm after full payment has been made.', 15, lineSpaceText(height, 0), null, null, 'left')
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(37, 43, 59);
        pdf.text('* This is computer generate invoice, no signature required', 15, lineSpaceText(height + 4, 0), null, null, 'left')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('If you have any questions about this invoice, kindly contact us at +6019 666 1767', 15, lineSpaceText(height + 4, 0), null, null, 'left')
        height = height + 4
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(9);
        pdf.text('Thank You For Your Business', alignTextCenter, lineSpaceText(height + 10, 0), null, null, 'center')

        pdf.save(`${data.file_name}.pdf`);
        // window.open(pdf.output('bloburl'))
    }
}

export const pdfGeneratorInvoiceBooking = {
    generatorInvoiceBooking: async function (data) {
        const booking_data = data;
        const invoice_data = data.invoice_data;

        const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: 'a4'
        });

        const alignTextCenter = (pdf.internal.pageSize.width / 2);

        var lineSpaceText = function (x, line) {
            return (x + line) + 2
        }

        var img = new Image()

        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 0);
        pdf.setDrawColor(235, 238, 242);
        pdf.setTextColor(0, 0, 0);
        pdf.setFillColor(235, 87, 87);

        img.src = imgSelcare;

        // Section Hearder
        pdf.addImage(img, 'png', 15, 15, 58, 13)
        pdf.setFontSize(8);
        pdf.setFont("helvetica", "bold");

        if (invoice_data.address_from) {
            let name = invoice_data.address_from && invoice_data.address_from.firstname ? invoice_data.address_from.firstname : '-'
            let zipcode = !invoice_data.address_from.zipcode ? '' : invoice_data.address_from.zipcode
            let province = !invoice_data.address_from.province ? '' : invoice_data.address_from.province
            let country = !invoice_data.address_from.country ? '' : invoice_data.address_from.country
            pdf.setTextColor(34, 34, 34);
            pdf.text(name, pdf.internal.pageSize.width - 15, lineSpaceText(17, 0), null, null, 'right');
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");
            pdf.text(`${invoice_data.address_from.address1 + ','} ${invoice_data.address_from.address2 + ','} ${zipcode + ','}`, pdf.internal.pageSize.width - 15, lineSpaceText(20, 0), null, null, 'right')
            pdf.text(`${province} ${',' + country}`, pdf.internal.pageSize.width - 15, lineSpaceText(23, 0), null, null, 'right')
        }
        pdf.setFillColor(169, 26, 24);
        pdf.roundedRect(15, 32, pdf.internal.pageSize.width - 30, .1, 3, 3, "F");


        // Section Quotation
        pdf.roundedRect(pdf.internal.pageSize.width - 55, 36, 40, 7, 3.3, 3.3, "FD")

        // Title
        pdf.setFontSize(8);
        pdf.setTextColor(255, 255, 255);
        pdf.setFont("helvetica", "bold");
        pdf.text('INVOICE', pdf.internal.pageSize.width - 19, lineSpaceText(38.5, 0), null, null, 'right')

        // LEFT
        let clientName = invoice_data.address_to && invoice_data.address_to.firstname ? invoice_data.address_to.firstname : '-'
        let clientPhone = invoice_data.address_to && invoice_data.address_to.phone ? invoice_data.address_to.phone : '-'

        pdf.setFontSize(7);

        pdf.setTextColor(37, 43, 59);
        pdf.setFont("helvetica", "normal");
        pdf.text('Client Name', 17, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${clientName}`, 35, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Address', 17, lineSpaceText(52, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);

        if (invoice_data.address_to) {
            let zipcode = !invoice_data.address_to.zipcode ? '' : invoice_data.address_to.zipcode
            let province = !invoice_data.address_to.province ? '' : invoice_data.address_to.province
            let country = !invoice_data.address_to.country ? '' : invoice_data.address_to.country
            pdf.text(`:  ${invoice_data.address_to.address1},`, 35, lineSpaceText(52, 0), null, null, 'left')
            pdf.text(`   ${invoice_data.address_to.address2},`, 35, lineSpaceText(56, 0), null, null, 'left')
            pdf.text(`   ${zipcode}, ${province}, ${country}`, 35, lineSpaceText(60, 0), null, null, 'left')
        }
        pdf.setTextColor(37, 43, 59);
        pdf.text('Phone No.', 17, lineSpaceText(65, 0), null, null, 'left')

        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${clientPhone}`, 35, lineSpaceText(65, 0), null, null, 'left')
        // RIGHT
        pdf.setTextColor(37, 43, 59);
        pdf.text('Date', pdf.internal.pageSize.width / 2 + 5, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${moment(booking_data.date).format('DD/MM/YYYY')}`, pdf.internal.pageSize.width - 70, lineSpaceText(48, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Invoice No.', pdf.internal.pageSize.width / 2 + 5, lineSpaceText(52, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${invoice_data.invoice_number}`, pdf.internal.pageSize.width - 70, lineSpaceText(52, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Booking No.', pdf.internal.pageSize.width / 2 + 5, lineSpaceText(56, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(`:  ${booking_data.booking_number}`, pdf.internal.pageSize.width - 70, lineSpaceText(56, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text('Validity', pdf.internal.pageSize.width / 2 + 5, lineSpaceText(60, 0), null, null, 'left')
        pdf.setTextColor(34, 34, 34);
        pdf.text(':  30 DAYS', pdf.internal.pageSize.width - 70, lineSpaceText(60, 0), null, null, 'left')

        let service = 0
        let addtional = 0
        let medical = 0
        let confinementService = 0
        let confinementAddons = 0

        if (invoice_data.line_items && invoice_data.line_items.length > 0) {
            const addRequiredService = invoice_data.line_items.find(x => x.title === 'Required Services')
            const addAdditionalTreatment = invoice_data.line_items.find(x => x.title === 'Additional Treatments')
            const addMedicalTreatment = invoice_data.line_items.find(x => x.title === 'Medical Supplies')
            const confinementServices = invoice_data.line_items.find(x => x.title === 'Confinement Services')
            const confinementAddOns = invoice_data.line_items.find(x => x.title === 'Confinement Addons')
            if (addRequiredService !== undefined) {
                let value = addRequiredService.items.length * 4
                service = value + 12
            }
            if (addAdditionalTreatment !== undefined) {
                let value = addAdditionalTreatment.items.length * 4;
                addtional = value + 12
            }
            if (addMedicalTreatment !== undefined) {
                let value = addMedicalTreatment.items.length * 4;
                medical = value + 12
            }
            if (confinementServices !== undefined) {
                let value = confinementServices.items.length * 4;
                confinementService = value + 12
            }
            if (confinementAddOns !== undefined) {
                let value = confinementAddOns.items.length * 4;
                confinementAddons = value + 12
            }
        }

        let resultHeight = booking_data.category_type !== 'confinement' ? (service) + (addtional) + (medical) + (confinementAddons) + (confinementService) + 12 : (service) + (addtional) + (medical) + (confinementAddons) + (confinementService);

        let height = 78;
        // Section Descriptions
        // Title
        pdf.setFontSize(8);
        pdf.setTextColor(255, 255, 255);
        pdf.setFont("helvetica", "bold");
        pdf.setFillColor(169, 26, 24);
        pdf.roundedRect(15, 78, pdf.internal.pageSize.width - 30, 7, 3.3, 3.3, "FD")
        pdf.text('DESCRIPTION', 19, lineSpaceText(80.6, 0), null, null, 'left')
        pdf.text('AMOUNT (RM)', pdf.internal.pageSize.width - 19, lineSpaceText(80.6, 0), null, null, 'right')
        height = height + 2.6
        pdf.setFillColor(255, 255, 255);
        pdf.roundedRect(15, height + 7.4, pdf.internal.pageSize.width - 30, resultHeight, 3, 3, "FD")
        height = height + 4.4

        // rate fee
        if (booking_data.category_type !== 'confinement') {
            pdf.setFont("helvetica", "bold");
            pdf.setTextColor(34, 34, 34);
            pdf.setFontSize(7);
            pdf.text('General care service (non-nursing)', 19, lineSpaceText(height + 7, 0), null, null, 'left')
            height = height + 7
            pdf.setFont("helvetica", "normal");
            pdf.setTextColor(37, 43, 59);
            pdf.text(`${converPriceWithCommas(booking_data.total_rate_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
            pdf.text(booking_data.days, 21, lineSpaceText(height + 4, 0), null, null, 'left');
        }

        // Confinement Services
        const confinementServices = invoice_data.line_items.find(x => x.title === 'Confinement Services')
        if (confinementServices !== undefined) {
            height = height + 4
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Confinement Services', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (invoice_data.line_items && invoice_data.line_items.length > 0) {
                const requiredServices = invoice_data.line_items.filter(x => x.title === 'Confinement Services')
                if (requiredServices.length > 0) {
                    for (let i = 0; i < requiredServices[0].items.length; i++) {
                        height = height + 4
                        pdf.text(requiredServices[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(requiredServices[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Confinement Addons
        const confinementAddOns = invoice_data.line_items.find(x => x.title === 'Confinement Addons')
        if (confinementAddOns !== undefined) {
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Confinement Addons', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (invoice_data.line_items && invoice_data.line_items.length > 0) {
                const confinementAddOns = invoice_data.line_items.filter(x => x.title === 'Confinement Addons')
                if (confinementAddOns.length > 0) {

                    for (let i = 0; i < confinementAddOns[0].items.length; i++) {
                        height = height + 4
                        pdf.text(confinementAddOns[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(confinementAddOns[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Required Services
        const requiredService = invoice_data.line_items.find(x => x.title === 'Required Services')
        if (requiredService !== undefined) {
            height = height + 4
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Required Services', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (invoice_data.line_items && invoice_data.line_items.length > 0) {
                const requiredServices = invoice_data.line_items.filter(x => x.title === 'Required Services')
                if (requiredServices.length > 0) {
                    for (let i = 0; i < requiredServices[0].items.length; i++) {
                        height = height + 4
                        pdf.text(requiredServices[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(requiredServices[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Additional Treatments
        const additionalTreatment = invoice_data.line_items.find(x => x.title === 'Additional Treatments')
        if (additionalTreatment !== undefined) {
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(7);
            pdf.setTextColor(34, 34, 34);
            pdf.text('Additional Treatments', 19, lineSpaceText(height + 4, 0), null, null, 'left')
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");

            if (invoice_data.line_items && invoice_data.line_items.length > 0) {
                const addTreatment = invoice_data.line_items.filter(x => x.title === 'Additional Treatments')
                if (addTreatment.length > 0) {

                    for (let i = 0; i < addTreatment[0].items.length; i++) {
                        height = height + 4
                        pdf.text(addTreatment[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(addTreatment[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // Medical Supplies
        const medicalTreatment = invoice_data.line_items.find(x => x.title === 'Medical Supplies')
        if (medicalTreatment !== undefined) {
            pdf.line(19, height + 6, pdf.internal.pageSize.width - 19, height + 6)
            height = height + 6
            pdf.setFont("helvetica", "bold");
            pdf.setTextColor(34, 34, 34);
            pdf.setFontSize(7);
            pdf.text('Medical Supplies', 19, lineSpaceText(height + 4, 0), null, null, 'left');
            height = height + 4
            pdf.setTextColor(37, 43, 59);
            pdf.setFontSize(7);
            pdf.setFont("helvetica", "normal");
            if (invoice_data.line_items && invoice_data.line_items.length > 0) {
                const addMedicalTreatment = invoice_data.line_items.filter(x => x.title === 'Medical Supplies')
                if (addMedicalTreatment.length > 0) {

                    for (let i = 0; i < addMedicalTreatment[0].items.length; i++) {
                        height = height + 4
                        pdf.text(addMedicalTreatment[0].items[i].name, 21, lineSpaceText(height, 0), null, null, 'left')
                        pdf.text(`${converPriceWithCommas(addMedicalTreatment[0].items[i].total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height, 0), null, null, 'right')
                    }
                }
            }
        }

        // total
        height = height + 12
        pdf.setFillColor('#F3F3F3');
        pdf.roundedRect(15, height, pdf.internal.pageSize.width - 30, 29, 3, 3, "FD");
        pdf.setTextColor(34, 34, 34);
        pdf.text('Subtotal', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.subtotal)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('Processing Fee (non-refundable)', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.processing_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('Deposit (non-refundable)', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.deposit_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4
        pdf.setTextColor(34, 34, 34);
        pdf.text('Transportation Fee (Fixed Fee)', 19, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setTextColor(37, 43, 59);
        pdf.text(`${converPriceWithCommas(data.transportation_fee)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 4, 0), null, null, 'right')
        height = height + 4

        pdf.setTextColor(34, 34, 34);
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.text('TOTAL AMOUNT', 19, lineSpaceText(height + 6.5, 0), null, null, 'left')
        pdf.text(`${converPriceWithCommas(data.total)}`, pdf.internal.pageSize.width - 19, lineSpaceText(height + 6.5, 0), null, null, 'right')
        height = height + 6.5

        if (height >= 244) {
            pdf.addPage();
            height = 15
        } else {
            height = pdf.internal.pageSize.height - 54
        }
        // Footer
        pdf.setTextColor(34, 34, 34);
        pdf.setFontSize(7);
        pdf.setFont("helvetica", "bold");
        pdf.text('Booking will only be confirm after full payment has been made.', 15, lineSpaceText(height, 0), null, null, 'left')
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(37, 43, 59);
        pdf.text('Payment can be transfer to', 15, lineSpaceText(height + 6, 0), null, null, 'left')
        height = height + 6
        pdf.setFont("helvetica", "italic");
        pdf.setTextColor(37, 43, 59);
        pdf.text('SELCARE NURSING SDN BHD', 15, lineSpaceText(height + 4, 0), null, null, 'left')
        height = height + 4
        pdf.setFont("helvetica", "bold");
        pdf.setTextColor(34, 34, 34);
        pdf.text('CIMB BANK 8604635595', 15, lineSpaceText(height + 4, 0), null, null, 'left')
        pdf.setFont("helvetica", "normal");
        height = height + 4
        pdf.text('* This is computer generate invoice, no signature required', 15, lineSpaceText(height + 6, 0), null, null, 'left')
        height = height + 6
        pdf.setTextColor(34, 34, 34);
        pdf.text('If you have any questions about this invoice, kindly contact us at +6019 666 1767', 15, lineSpaceText(height + 4, 0), null, null, 'left')
        height = height + 4
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(9);
        pdf.text('Thank You For Your Business', alignTextCenter, lineSpaceText(height + 10, 0), null, null, 'center')

        pdf.save(`${invoice_data.invoice_number}.pdf`);
    }
}