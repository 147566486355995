
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, Button, FormGroup, Input, Label, Spinner } from 'reactstrap'
import { get } from '../../../../../helpers/API'

const OptionsDropdown = ({ option, selected, handleSelect, show }) => {
    return show && <div className="position-absolute w-100 bg-white shadow mt-2 custom-dropItem">
        <ul className="list-group shadow-0">
            {option.map((x, i) => {
                return x.value !== undefined && <li
                    onClick={() => handleSelect(x)}
                    className={selected.medicine === x.label ? "list-group-item cursor-p active text-white" : "list-group-item cursor-p "}
                    key={i}>
                    <div className='d-flex justify-content-between align-items-center'><div>{x.label}</div><div className='badge badge-success'>{x.availability ? 'Available on Selcare online' : ''}</div></div>
                </li>
            })}
        </ul>
    </div>
}

const ModalMedication = ({ visible, selectedItem, isClosed, onUpdateMedications, onSubmitMedications }) => {
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState({
        product: "",
        medicine: "",
        route: "",
        dosage: "",
        frequency: "",
        amount: 1,
        remarks: ""
    })
    const [show, setShow] = useState(false)
    const [searching, setSearching] = useState('')
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let isMounted = true;

        if (selectedItem) {
            if (isMounted) {
                setSearching(!selectedItem.medicine ? selectedItem.product.label : selectedItem.medicine.label)
                setSelected({
                    product: selectedItem.product,
                    medicine: selectedItem.medicine,
                    route: selectedItem.route,
                    dosage: selectedItem.dosage,
                    frequency: selectedItem.frequency,
                    amount: selectedItem.amount,
                    remarks: selectedItem.remarks
                })
            }
        }
        return () => {
            isMounted = false;
        }
    }, [selectedItem])

    useEffect(() => {
        let isMounted = true;

        async function fetchingControlledDrug() {
            setLoading(true)
            try {
                const countControlledDrug = await get(`/controlled-Medicines/count`)
                const controlledDrug = await get(`/controlled-Medicines?_limit=${countControlledDrug}`)

                const newDataControlledDrug = controlledDrug.map(item => {
                    return {
                        id: item.id,
                        title: item.description,
                        availability: false
                    }
                })

                if (isMounted) {
                    setLoading(false)
                    setDataSource(prev => [...prev, ...newDataControlledDrug]);
                }

            } catch (error) {
                setLoading(false)
                setDataSource([])
            }
        }

        async function fetchingProducts() {
            setLoading(true)
            try {
                const countDrug = await get(`/products/count`)
                const drug = await get(`/products?_limit=${countDrug}`)
                const newDataDrug = drug.products.map(item => {
                    return {
                        id: item.id,
                        title: item.title,
                        availability: true
                    }
                })
                if (isMounted) {
                    setLoading(false)
                    setDataSource(prev => [...prev, ...newDataDrug]);
                }
            } catch (error) {
                setLoading(false)
                setDataSource([])
            }
        }
        fetchingControlledDrug()
        fetchingProducts()


        return () => {
            // console.log('clean up');
            isMounted = false;
        }
    }, [])

    useEffect(() => {
        let isMounted = true

        if (dataSource.length > 0) {
            const arr = dataSource.map(item => {
                return {
                    label: item.title,
                    value: item.id,
                    availability: item.availability
                }
            })
            if (isMounted) {
                setOptions(arr)
            }
        }

        return () => {
            isMounted = false
        }
    }, [dataSource])


    const handleCancel = () => {
        resetForm()
        isClosed()
    }
    const resetForm = () => {
        setSearching('')
        setSelected({
            product: "",
            medicine: "",
            route: "",
            dosage: "",
            frequency: "",
            amount: "",
            remarks: ""
        })
    }
    const handleUpdate = () => {
        if (selected.medicine || selected.product) {
            onUpdateMedications({ ...selected, index: selectedItem.index })
            resetForm()
            isClosed()
        }
    }
    const handleSubmit = () => {
        if (selected.medicine || selected.product) {
            onSubmitMedications(selected)
            resetForm()
            isClosed()
        }
    }
    const updateForm = (field, value) => {
        setSelected(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const handleSearching = (value) => {
        setSearching(value)
        if (value.length > 0) {
            setShow(true)
            const data = dataSource;
            const filter = data.filter(x => x.title.toLowerCase().includes(value.toLowerCase()));
            const mapping = filter.map(item => {
                return {
                    label: item.title,
                    value: item.id,
                    availability: item.availability
                }
            })
            setOptions(mapping)
        } else {
            setShow(false)
        }
    }
    return (
        <React.Fragment>
            <Modal
                isOpen={visible}
            >
                <ModalBody>
                    <h4>{selectedItem ? 'Update Drug' : 'Add Drug'}</h4>

                    <div>
                        <FormGroup>
                            <Label>Medication</Label>
                            <div className='position-relative d-block'>
                                <div className='position-relative d-flex align-items-center'>
                                    <Input
                                        onChange={e => {
                                            handleSearching(e.target.value)
                                            setSearching(e.target.value)

                                            if (e.target.value.length === 0) {
                                                setSelected(prev => {
                                                    return {
                                                        ...prev,
                                                        product: "",
                                                        medicine: ""
                                                    }
                                                })
                                            }

                                        }} value={searching} />
                                    {loading && <div className='text-right pr-2 position-absolute w-100'><Spinner size='sm' color='light' /></div>}
                                </div>
                                {selected.product && searching.length > 0 && <div className='position-absolute d-block badge badge-success float-right' style={{ top: 11, right: 11 }}>Available on Selcare online</div>}
                                <OptionsDropdown
                                    option={options}
                                    show={show}
                                    selected={selected}
                                    handleSelect={(value) => {
                                        setShow(false)
                                        setSearching(value.label)
                                        updateForm(value.availability ? 'product' : 'medicine', value)
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Route</Label>
                            <Input value={selected.route} type="text" onChange={(e) => updateForm('route', e.target.value)} />
                        </FormGroup>

                        <div className='d-flex justify-content-between'>
                            <FormGroup className='mr-2'>
                                <Label>Dosage</Label>
                                <Input value={selected.dosage} type="text" onChange={(e) => updateForm('dosage', e.target.value)} />
                            </FormGroup>
                            <FormGroup className='mr-2'>
                                <Label>Frequency</Label>
                                <Input value={selected.frequency} type="text" onChange={(e) => updateForm('frequency', e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Duration</Label>
                                <Input value={selected.amount} type="text" onChange={(e) => updateForm('amount', e.target.value)} />
                            </FormGroup>
                        </div>

                        <FormGroup>
                            <label>Remarks</label>
                            <Input value={selected.remarks} type="textarea" onChange={(e) => updateForm('remarks', e.target.value)} />
                        </FormGroup>
                    </div>


                    <div className='d-flex justify-content-between'>
                        <Button className='w-100 mr-2' color='light' onClick={() => {
                            handleCancel()
                            setShow(false)
                        }}>Cancel</Button>
                        <Button
                            className='w-100'
                            disabled={selected.product || selected.medicine ? false : true}
                            color='primary'
                            onClick={() => {
                                if (!selectedItem) {
                                    handleSubmit()
                                } else {
                                    handleUpdate()
                                }
                                setShow(false)
                            }}>{selectedItem ? 'Save' : 'Add'}</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ModalMedication