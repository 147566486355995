export const TabNurse = [
    {
        key: '1',
        name: 'All'
    },
    // {
    //     key: '2',
    //     name: 'Pending'
    // },
    // {
    //     key: '3',
    //     name: 'Successfull'
    // },
    // {
    //     key: '4',
    //     name: 'Failed'
    // },
    // {
    //     key: '5',
    //     name: 'Completed'
    // }
]