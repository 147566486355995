import React from 'react'
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { assessmentTab, assessmentTab2 } from '../../../Store/AssessmentData'
import classNames from 'classnames';
import TypeOfService from './TypeOfService';
import AdditionalTreatment from './AdditionalTreatment';
import Assessments from './Assessments';
import Medical from './Medical'
import ConfinementService from './ConfinementService';
import ConfinementAddons from './ConfinementAddons';

const Assessment = ({ confinementAddons, confinementServices, setCustomScheduleSetting, setCustomScheduleRate, setActiveChildTab, activeChildTab, isEditMode, formValues, setFormValues, dispatch, dataSource, setSweetTimmer, sweetTimmer, setErrMsg, errMsg }) => {
    let array = assessmentTab;
    if (dataSource.category && dataSource.category === 'confinement') {
        array = assessmentTab2;
    }
    return (
        <Row>
            <Col sm={4} md={4}>
                <Nav pills className="flex-column mb-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    {array.map((item, index) => {
                        return (
                            <NavItem key={index}>
                                <NavLink onClick={() => { setActiveChildTab(item.key) }} className={classNames({ active: activeChildTab === item.key }, "font-weight-bold p-3")}>{item.title}</NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
            </Col>
            <Col sm={8} md={8}>
                {activeChildTab === '1' && dataSource.category === 'confinement' &&
                    <ConfinementService
                        confinementServices={confinementServices}
                        data={formValues}
                    />
                }

                {activeChildTab === '2' && dataSource.category === 'confinement' &&
                    <ConfinementAddons
                        confinementAddons={confinementAddons}
                        data={formValues}
                    />
                }

                {activeChildTab === '1' && dataSource.category !== 'confinement' &&
                    <TypeOfService
                        isEditMode={isEditMode}
                        data={formValues}
                        setFormValues={setFormValues}
                        dispatch={dispatch}
                        dataSource={dataSource}
                        activeTab={activeChildTab}
                        setSweetTimmer={setSweetTimmer}
                        sweetTimmer={sweetTimmer}
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                    />
                }

                {activeChildTab === '2' && dataSource.category !== 'confinement' &&
                    <AdditionalTreatment
                        isEditMode={isEditMode}
                        data={formValues}
                        setFormValues={setFormValues}
                        dispatch={dispatch}
                        dataSource={dataSource}
                        activeTab={activeChildTab}
                        setSweetTimmer={setSweetTimmer}
                        sweetTimmer={sweetTimmer}
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                        setCustomScheduleSetting={setCustomScheduleSetting}
                        setCustomScheduleRate={setCustomScheduleRate}
                    />
                }


                {activeChildTab === '4' && dataSource.category !== 'confinement' &&
                    <Assessments
                        isEditMode={isEditMode}
                        data={formValues}
                        setFormValues={setFormValues}
                        dispatch={dispatch}
                        dataSource={dataSource}
                        activeTab={activeChildTab}
                        setSweetTimmer={setSweetTimmer}
                        sweetTimmer={sweetTimmer}
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                    />
                }

                {activeChildTab === '3' && dataSource.category !== 'confinement' &&
                    <Medical
                        isEditMode={isEditMode}
                        data={formValues}
                        setFormValues={setFormValues}
                        dispatch={dispatch}
                        dataSource={dataSource}
                        activeTab={activeChildTab}
                        setSweetTimmer={setSweetTimmer}
                        sweetTimmer={sweetTimmer}
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                    />
                }

                {/* {activeTab === '3' &&
                    dataSource.map((item, index) => {
                        return (
                            <FormGroup row key={index}>
                                <Col md={12} className="ml-auto">
                                    <div className="mt-4 mt-lg-0 w-100">
                                        <div className="custom-control custom-checkbox custom-control-right w-100">
                                            <Input type="checkbox" className="custom-control-input" id={item.key} />
                                            <Label className="custom-control-label w-100" htmlFor={item.key} onClick={(event) => handleCheckBox(event, item)}>{item.title}</Label>
                                            <Label className="w-100 font-size-11 text-muted">{item.subtitle}</Label>
                                        </div>
                                        {item.value ? <div>
                                            <Input className="mb-3" onChange={e => handleOnchange(e.target.value, item.key)} placeholder={`${item.title} Name`} />
                                        </div> : null}
                                    </div>
                                </Col>
                            </FormGroup>
                        )
                    })}

                {activeTab === '4' &&
                    dataSource.map((item, index) => {
                        return (
                            <FormGroup row key={index}>
                                <Col md={12} className="ml-auto">
                                    <div className="mt-4 mt-lg-0 w-100">
                                        <div className="custom-control custom-checkbox custom-control-right w-100">
                                            <Input type="checkbox" className="custom-control-input" id={item.key} />
                                            <Label className="custom-control-label w-100" htmlFor={item.key} onClick={(event) => handleCheckBox(event, item)}>{item.title}</Label>
                                            <Label className="w-100 font-size-11 text-muted">{item.subtitle}</Label>
                                        </div>
                                        {item.value ? <div>
                                            <Input className="mb-3" onChange={e => handleOnchange(e.target.value, item.key)} placeholder={`${item.title} Name`} />
                                        </div> : null}
                                    </div>
                                </Col>
                            </FormGroup>
                        )
                    })} */}


            </Col>
        </Row>
    )
}

export default Assessment
