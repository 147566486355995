import axios from 'axios';
import useLocalStorage from './useLocalStorage';

// Error modal
const showErrorModal = (message) => {
    const modal = document.createElement('div');
    modal.innerHTML = `
      <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Error</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>${message}</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    `;
    const modalContainer = document.body;
    modalContainer.appendChild(modal);

    const dismissModal = () => {
        if (modal && modalContainer.contains(modal)) {
            modalContainer.removeChild(modal);
        }
        if (document.querySelector('.modal-backdrop')) {
            modalContainer.removeChild(document.querySelector('.modal-backdrop'));
        }

        localStorage.clear();
        window.location.href = "/logout";
    }

    modal.querySelector('.btn-primary').addEventListener('click', () => {
        dismissModal();
    });

    modal.querySelector('.close').addEventListener('click', () => {
        dismissModal();
    });

    modal.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            dismissModal();
        }
    });
}

// API ROOT
export const getAPIroot = () => {
    let API_ROOT;
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('admin-staging')) {
        API_ROOT = 'https://selcare-staging-api.inference.asia'
        // API_ROOT = 'https://selcare-v2-api.inference.asia'
    } else {
        API_ROOT = 'https://selcare-v2-api.inference.asia'
    }
    return API_ROOT
}

export const getAPIrootTrustGate = () => {
    let API_ROOT_TRUSTGATE;
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('admin-staging')) {
        API_ROOT_TRUSTGATE = 'https://selcare-trustgate-api.inference.asia/api/default/staging'
        // API_ROOT_TRUSTGATE = 'https://selcare-trustgate-api.inference.asia/api/default/production'
    } else {
        API_ROOT_TRUSTGATE = 'https://selcare-trustgate-api.inference.asia/api/default/production'
    }
    return API_ROOT_TRUSTGATE
}

export const getPushKey = () => {
    let PUSHER_KEY;
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('admin-staging')) {
        PUSHER_KEY = !process.env.REACT_APP_PUSHER_APP_KEY_STAGING ? "a7703660fc029be803bd" : process.env.REACT_APP_PUSHER_APP_KEY_STAGING
    } else {
        PUSHER_KEY = !process.env.REACT_APP_PUSHER_APP_KEY_PRODUCTION ? "a7703660fc029be803bd" : process.env.REACT_APP_PUSHER_APP_KEY_PRODUCTION
    }
    return PUSHER_KEY
}

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = useLocalStorage.getItem('authUser');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 401) {
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });

}

// Login Method
const postLogin = (url, data) => {
    return axios.post(getAPIroot() + url, data).then(response => {
        if (response.statusCode === 200)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err;
    });
}

// postForgetPwd 
const postForgetPwd = (url, data) => {
    return axios.post(getAPIroot() + url, data).then(response => {
        return response.data;
    }).catch(err => {
        throw err;
    });
}

// postResetPwd
const postResetPwd = (url, data) => {
    return axios.post(getAPIroot() + url, data).then(response => {
        return response.data;
    }).catch(err => {
        throw err;
    });
}


// GET Method
const get = (url) => {
    return axios.get(getAPIroot() + url, {
        headers: {
            Authorization: `Bearer ` + useLocalStorage.getItem('accessToken')
        }
    }).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 403: message = "Sorry! forbidden"; break;
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 403 || err.response.status === 401) {
            // if (err.response.status === 500) {
                // message = "Invalid credentials"
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });
}

// Get AGORA response
const getAgora = (channel, uid) => {
    return axios.get(
        `https://agora-node-token-server-u7ed8.ondigitalocean.app/rtc/${channel}/audience/uid/${uid}`
    ).then(response => {
        return response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 403: message = "Sorry! forbidden"; break;
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 403 || err.response.status === 401) {
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });
}

// UPDATE Method
const update = (url, data) => {
    return axios.put(getAPIroot() + url, data, {
        headers: {
            Authorization: `Bearer ` + useLocalStorage.getItem('accessToken')
        },

    }).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 400: message = err.response.data && err.response.data.message.length > 0 ? err.response.data.message[0].messages[0].message : 'Sorry! forbidden'; break;
                case 403: message = "Sorry! forbidden"; break;
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 403 || err.response.status === 401) {
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });
}

// POST Method
const post = (url, data) => {
    return axios.post(getAPIroot() + url, data, {
        headers: {
            Authorization: `Bearer ` + useLocalStorage.getItem('accessToken')
        },

    }).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 400: message = err.response.data && err.response.data.message.length > 0 ? err.response.data.message[0].messages[0].message : 'Sorry! forbidden'; break;
                case 403: message = "Sorry! forbidden"; break;
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 403 || err.response.status === 401) {
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });
}

// REMOVE Method
const remove = (url, data) => {
    return axios.delete(getAPIroot() + url, {
        headers: {
            Authorization: `Bearer ` + useLocalStorage.getItem('accessToken')
        },

    }).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 403: message = "Sorry! forbidden"; break;
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 403 || err.response.status === 401) {
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });
}

// MyTrustGate

const postMyTrustGate = (url, data) => {
    return axios.post(getAPIrootTrustGate() + url, data).then(response => {
        if (response.status >= 200 || response.status <= 299) {        
            // console.log(response.data);
            return response.data;
        }
        // console.log(response.data);
        throw response.data;
    }).catch(err => {
        // console.log(err);
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 403: message = "Sorry! forbidden"; break;
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }

            if (err.response.status === 403 || err.response.status === 401) {
                message += ". The current session will be terminated."
                showErrorModal(message);
            }
        }
        throw message;
    });
}

const getAccessToken = () => {
    return useLocalStorage.getItem('accessToken')
}

export { getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd, get, update, post, remove, postResetPwd, getAccessToken, getAgora, postMyTrustGate }