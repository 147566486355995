import React from 'react'
import { Table, Card, CardBody } from "reactstrap";
import { TEXT_COMPANY, TEXT_CONTACT_NO, TEXT_DESIGNATION, TEXT_NAME, TEXT_REFERENCE } from '../../Text';

const Reference = ({ dataSource }) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title">{TEXT_REFERENCE}</h4>
                    <Table className="mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{TEXT_NAME}</th>
                                <th>{TEXT_DESIGNATION}</th>
                                <th>{TEXT_COMPANY}</th>
                                <th>{TEXT_CONTACT_NO}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource.references && dataSource.references.length > 0 && dataSource.references.map((items, i) => {
                                return (
                                    <tr key={i}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{!items.name ? "-" : items.name}</td>
                                        <td>{!items.designation ? "-" : `${items.designation}`}</td>
                                        <td>{!items.company ? "-" : items.company}</td>
                                        <td>{!items.contact_number ? "-" : `${items.contact_number}`}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default Reference
