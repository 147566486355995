export const TEXT_PERSONEL_INFORMATION = 'Personal information'
export const TEXT_FULLNAME = "Full name"
export const TEXT_GENDER = "Gender"
export const TEXT_PHOTO = "Photo"
export const TEXT_IDENTIFICATIONS_AND_PASSPORT = "NRIC / Passport no"
export const TEXT_DOB = "Date of birth"
export const TEXT_NATIONALITY = "Nationality"
export const TEXT_RACE = "Race"
export const TEXT_RELIGION = "Religion"
export const TEXT_PERMANENT_ADDRESS = "Permanent address"
export const TEXT_CURRENT_ADDRESS = "Current address"
export const TEXT_CONTACT_NO = "Contact no"
export const TEXT_EMAIL_ADDRESS = "E-mail address"
export const TEXT_REGISTRATION_TYPES = "Registration types"
export const TEXT_EXPIRY_DATE = "Expiry date"
export const TEXT_QUALIFICATION = "Qualification"
export const TEXT_DIPLOMA = "Diploma cert"
export const TEXT_DEGREE = "Degree cert"
export const TEXT_POST_BASIC = "Post basic cert"
export const TEXT_OTHERS = "Others"
export const TEXT_CURRENT_EMPLOYMENT = "Current employment"
export const TEXT_NAME_OF_EMPLOYER = "Name of employer"
export const TEXT_ADDRESS = "Address"
export const TEXT_DATE_OF_EMPLOYMENT = "Date of employment"
export const TEXT_POSITION = "Position"
export const TEXT_CONTRACT_VALIDITY_DATE = "Contract validity date"
export const TEXT_CONTRACT = "Contract"
export const TEXT_PHONE_NO = "Phone number"
export const TEXT_PREVIOUS = "Previous"
export const TEXT_EMPLOYMENT = "Employment"
export const TEXT_NAME = "Name"
export const TEXT_WORK = "Work"
export const TEXT_EXPERIECE = "Experience"
export const TEXT_ORGANIZARION = "Organization"
export const TEXT_YEARS = "Years"
export const TEXT_YEARS_OF_EXPERIENCE = "Years of experience"
export const TEXT_REFERENCE = "Reference"
export const TEXT_REGISTRATION = "Registration"
export const TEXT_NURSE = "Nurse"
export const TEXT_MEDICAL_ASSISTANT = "Medical Assistant (MA)"
export const TEXT_PHYSIOTHERAPIST = "Physiotherapist"
export const TEXT_GENERAL_CARE_AIDE = "General Care Aide"
export const TEXT_NUMBER = "Number"
export const TEXT_MALAYSIAN_NURSING_BOARD = "Malaysian Nursing Board"
export const TEXT_MALAYSIAN_PHYSIOTHERAPHY_ASSOCIATION = "Malaysian Physiotherapy Association"
export const TEXT_MEDICAL_ASSISTANT_BOARD = "Medical Assistant Board"
export const TEXT_DESIGNATION = "Designation"
export const TEXT_COMPANY = "Company"
export const TEXT_REJECT = "Reject"
export const TEXT_REJECTED = "Rejected"
export const TEXT_APPROVED = "Approved"
export const TEXT_APPROVE = "Approve"
export const TEXT_ACTIONS = "Actions"
export const TEXT_PROVIDER = "Provider"
export const TEXT_NEW = "New"
export const TEXT_KIV = "KIV"
export const TEXT_PRODUCT = "Product"
export const TEXT_PRODUCTS = "Products"
export const TEXT_MEDICATION_DRUGS = "Medication/Drugs"
export const TEXT_ORDER = "Order"
export const TEXT_PROMOTION = "Promotion"
export const TEXT_PAYMENT = "Payment"
export const TEXT_NRIC = "NRIC"
export const TEXT_FRONT = "Front"
export const TEXT_BACK = "Back"
export const TEXT_MALE = "Male"
export const TEXT_FEMALE = "Female"
export const TEXT_ISLAM = "Islam"
export const TEXT_BUDHA = "Budha"
export const TEXT_HINDU = "Hindu"
export const TEXT_CHRISTIAN = "Christian"
export const TEXT_MALAY = "Malay"
export const TEXT_INDIAN = "Indian"
export const TEXT_CHINESE = "Chinese"
export const TEXT_ALTERNATE_PHONE_NO = `Alternate ${TEXT_PHONE_NO}`
export const TEXT_EMAIL = "Email"
export const TEXT_WEIGHT = "Weight"
export const TEXT_HEIGHT = "Height"
export const TEXT_PATIENT_DIAGNOSIS = "Patient Diagnosis"
export const TEXT_EDIT = "Edit"
export const TEXT_SAVE = "Save"
export const TEXT_SUBMIT = "Submit"
export const TEXT_STREET_ONE = "Street name 1"
export const TEXT_STREET_TWO = "Street name 2"
export const TEXT_STATE = "State"
export const TEXT_CITY = "City"
export const TEXT_POSTCODE = "Postcode"
export const TEXT_ALLERGIES = "Allergies"
export const TEXT_PAST_SURGICAL_HISTORY = "Past Surgical History"
export const TEXT_PAST_MEDICAL_HISTORY = "Past Medical History"
export const TEXT_LANGUAGE = "Language"
export const TEXT_MANDARIN = "Mandarin"
export const TEXT_TAMIL = "Tamil"
export const TEXT_ENGLISH = "English"
export const TEXT_CANCEL = "Cancel"
export const TEXT_PASSPORT = "Passport"
export const TEXT_CLOSE = 'Close'
export const TEXT_INVOICE = 'Invoice'
export const TEXT_ALL_INVOICE = 'All Invoice'
export const TEXT_BOOKING = 'Booking'
export const TEXT_YOUR_ACCOUNT = 'Your Account'
export const TEXT_ACCOUNT_SETTING = 'Account Setting'
export const TEXT_ADDRESS_SETTING = 'Address Setting'
export const TEXT_IMAGE_SETTING = 'Profile Image Setting'
