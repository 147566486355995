import React, { useContext, useEffect, useState } from 'react'
import ToggleButton from 'react-toggle-button'
import { update } from '../../../helpers/API'
import useLocalStorage from '../../../helpers/useLocalStorage'
import { ACTION_TYPE } from '../../../store/PageWrapper/ActionType'
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext'

const ToggleAvailbility = () => {
    const { globalData, setGlobalData } = useContext(GlobalContext)
    const [availability, setAvailability] = useState(false)
    const [show, setShow] = useState(false)

    useEffect(() => {
        let isMounted = true;
        if (isMounted && globalData.global.availability_doctor) {
            setAvailability(globalData.global.availability_doctor)
        }
        return () => isMounted = false
    }, [globalData.global.availability_doctor]);

    useEffect(() => {
        let isMounted = true;
        let signature = useLocalStorage.getItem('signature')
        let certInfo = useLocalStorage.getItem('certInfo')
        
        if (isMounted && globalData.global.role && globalData.global.role === 'doctor' && signature && certInfo) {
            setShow(prev => {
                return !prev
            })
        }
        return () => isMounted = false
    }, [globalData.global.role])


    const updateDoctorAvailability = async (val) => {

        if (globalData.global.user.doctor) {
            const res = await update(`/doctors/${globalData.global.user.doctor.id}`, { is_online: !val });
            const element = document.getElementById('liveToast')
            // console.log(element);
            element.classList.remove('hide')
            element.classList.add('show')
            setTimeout(() => {
                element.classList.remove('show')
                element.classList.add('hide')
            }, 2000);
            setAvailability(res.is_online)
            setGlobalData({
                type: ACTION_TYPE.UPDATE_DOCTOR_STATUS,
                payload: {
                    status: res.is_online
                }
            })
        }
    }

    return (
        <>
            {show &&
                <div className='mr-2 d-flex align-items-center'>
                    <p className='mr-2 mb-0 text-muted'>Availability</p>
                    <div
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                    >
                        <ToggleButton
                            value={availability}
                            animateThumbStyleHover={(n) => {
                                return {
                                    boxShadow: `0 0 ${2 + 4 * n}px rgba(0,0,0,.16),0 ${2 + 3 * n}px ${4 + 8 * n}px rgba(0,0,0,.32)`,
                                }
                            }}
                            onToggle={(val) => {
                                updateDoctorAvailability(val)
                            }} />
                    </div>
                </div>}
        </>
    )
}

export default ToggleAvailbility