import React, { useEffect, useState } from 'react'
import { Card, CardBody, Media } from 'reactstrap'

// Chartjs
import { Bar } from 'react-chartjs-2';
import Spinner from 'reactstrap/lib/Spinner';

import moment from 'moment';

const initialData = {
    labels: [],
    datasets: [
        {
            label: 'Pending',
            data: [],
            backgroundColor: '#f6c23e',
            stack: 'status',
        },
        {
            label: 'Approved',
            data: [],
            backgroundColor: '#1cc88a',
            stack: 'status',
        },
        {
            label: 'Rejected',
            data: [],
            backgroundColor: '#e74a3b',
            stack: 'status',
        },
    ],
};

const ClinicChart = (props) => {
    const [data, setData] = useState(initialData);
    const [maxValue, setMaxValue] = useState(50); // default max value

    useEffect(() => {
        if (props.typeOfProvider !== undefined && props.typeOfProvider !== null) {
            let sortedData = [...props.typeOfProvider];

            // Sort by full month name if the key is in MMMM format
            const hasFullMonthName = sortedData.every((item) =>
                moment(item.key, "MMMM", true).isValid()
            );
            if (hasFullMonthName) {
                sortedData = sortedData.sort(
                    (a, b) => moment(a.key, "MMMM").diff(moment(b.key, "MMMM"))
                );
            } else {
                sortedData = sortedData.sort((a, b) => moment(a.key).diff(moment(b.key)));
            }

            const labels = sortedData.map((item) => item.key);
            const values = sortedData.map((item) => item.value);
            const statuses = sortedData.map((item) => item.status);

            let obj = {
                ...initialData,
                labels: labels,
                datasets: [
                    {
                        ...initialData.datasets[0],
                        data: statuses.map((status) =>
                            status.filter((s) => s === 'pending').length
                        ),
                    },
                    {
                        ...initialData.datasets[1],
                        data: statuses.map((status) =>
                            status.filter((s) => s === 'approved').length
                        ),
                    },
                    {
                        ...initialData.datasets[2],
                        data: statuses.map((status) =>
                            status.filter((s) => s === 'rejected').length
                        ),
                    },
                ],
            };
            setData(obj);
            setMaxValue(Math.max(...values)); // set the maximum value based on the chart data
        }
    }, [props.typeOfProvider]);


    const option = {
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontSize: 10,
                    },
                },
            ],
            yAxes: [
                {
                    beginAtZero: true,
                    ticks: {
                        max: Math.ceil((maxValue + 1) / 2) * 2, // Set the max tick value based on the chart data
                        min: 0,
                        stepSize: Math.ceil((maxValue + 1) / 5),
                    },
                },
            ],
        },
        legend: {
            position: "bottom",
            fullWidth: true,
        },
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{props.title}</p>

                            {props.loading ? (
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: 150 }}
                                >
                                    <Spinner color="primary" size="sm" />
                                </div>
                            ) : (
                                <Bar width={474} height={300} data={data} options={option} />
                            )}
                        </Media>
                    </Media>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ClinicChart;
