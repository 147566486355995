import React, { useCallback, useEffect, useState } from 'react'
import { get } from '../../helpers/API';
import { handleStatusStyle } from '../../store/CustomFunctions';
import { initialDataTable } from '../Provider/tableColumn';
import moment from 'moment'
import { TEXT_PROVIDER } from '../../Text';
import { Card, CardBody, Col, Container, Nav, NavItem, Row, NavLink, Badge, Spinner } from 'reactstrap';
import ServerSidePaginations from '../../components/Table/ServerSidePaginations';
import { TabProvider } from '../Provider/TabProvider'
import classnames from 'classnames';
import { Link } from 'react-router-dom';

let initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: 'approved',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

const obj = {
    path: '/providers',
    title: "Providers",
    filter: "Providers"
}

const ProviderApproved = (props) => {
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [activeTab, setActiveTab] = useState('1');
    const [initialParams, setInitialParams] = useState(initialValue)
    const [loading, setLoading] = useState(() => false);

    const getProviders = useCallback((
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords
    ) => {
        setLoading(true);
        let columns = initialDataTable.columns.filter(x => x.field !== 'checked_by');

        setDataSource({
            columns: columns,
            rows: [{
                name: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
            }]
        })

        let params = ""
        let url = new URLSearchParams(params)

        url.set('_limit', limit)
        url.set('_start', page === 0 ? page : (page * 10) - 10)
        url.set('_sort', sortBy)

        get(`/providers/count?${url.toString()}`).then(count => {
            get(`/providers?${url.toString()}`).then(res => {
                const mapping = res.map((item, index) => {
                    return {
                        // index: page === 1 ? (index + 1) : (index + (10 * page) - 9),
                        name: <Link to={{ pathname: `/provider/${item.nurse_application.id}`, state: { from: "providers" } }} className="text-dark font-weight-bold">{!item.fullname ? '-' : item.fullname}</Link>,
                        type: <>{item.nurse_application.registration_type.is_registered_nurse && <Badge className='mr-1' color="primary">Nurse</Badge>}
                            {item.nurse_application.registration_type.is_general_care_aide && <Badge className='mr-1' color="primary">Care Aide</Badge>}
                            {item.nurse_application.registration_type.is_medical_assistant && <Badge className='mr-1' color="primary">Medical Assistant</Badge>}
                            {item.nurse_application.registration_type.is_physiotherapist && <Badge className='mr-1' color="primary">Physio</Badge>}</>,
                        id_card: item.nurse_application.personal_information && item.nurse_application.personal_information.id_card ? item.nurse_application.personal_information.id_card : "-",
                        createdAt: item.nurse_application.personal_information && item.nurse_application.personal_information.createdAt ? moment(item.nurse_application.personal_information.createdAt).format("DD MMM YYYY") : "-",
                        // checked_by: !item.nurse_application.checked_by ? "-" : item.nurse_application.checked_by.username,
                        contact_number: item.nurse_application.personal_information && item.nurse_application.personal_information.contact_number ? item.nurse_application.personal_information.contact_number : "-",
                        status: <span className={handleStatusStyle(item.nurse_application.status).badgeClass}>{handleStatusStyle(item.nurse_application.status).text2}</span>,
                        clickEvent: () => {
                            if (props.history !== undefined || props.history !== null) {
                                return props.history.push({ pathname: `/provider/${item.nurse_application.id}`, state: { from: "providers" } })
                            }
                        }
                    }
                })

                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    total: count,
                    page,
                    category,
                    keywords
                })
                setDataSource({
                    columns: columns,
                    rows: mapping
                })
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }).catch(() => {
            setLoading(false);
        })
    }, [props.history])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getProviders(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                initialValue.keywords
            )
        }

        return () => {
            mount = true
        }
    }, [getProviders])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h4>{TEXT_PROVIDER}</h4>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0">

                                    <Nav tabs className="nav-tabs-custom mb-4">
                                        {TabProvider.map((item, index) => {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink onClick={() => { setActiveTab(item.key); }} className={classnames({ active: activeTab === item.key }, "font-weight-bold p-3")}>{item.name}</NavLink>
                                                </NavItem>
                                            )
                                        })}
                                    </Nav>


                                    <ServerSidePaginations
                                        loading={loading}
                                        dataSource={dataSource}
                                        getItems={(params) => {
                                            getProviders(
                                                params.sortBy,
                                                params.orderBy,
                                                params.limit,
                                                params.page,
                                                params.total,
                                                params.category,
                                                params.keywords
                                            )
                                        }}
                                        emptyMessage="There are no provider yet"
                                        obj={obj}
                                        initialParams={initialParams}
                                        setLoading={setLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProviderApproved
