import React, { useCallback, useContext, useEffect } from 'react'
import { Container } from 'reactstrap'
import { ACTION_TYPE } from '../../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../../store/prescriptions/PrescriptionData'
import ApprovalContent from './ApprovalContent'

const ApprovalList = (props) => {
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)

    const resetState = useCallback(() => {
        if ((!prescriptionDataLayer.content_type && props.location.pathname) || (prescriptionDataLayer.content_type !== 'prescription')) {
            dispatch({
                type: ACTION_TYPE.CHANGE_CONTENT_TYPE,
                payload: 'prescription'
            })
        }
    }, [prescriptionDataLayer.content_type, props.location.pathname, dispatch])

    useEffect(() => {
        resetState()
    }, [resetState])
    
    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <ApprovalContent goToPage={props.history} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ApprovalList