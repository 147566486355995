import React, { useEffect, useState } from 'react'
import { Badge, Button, CardImg, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap'
import { get, getAPIroot, update } from '../../../helpers/API'
import imgEmpty from '../../../assets/images/imgEmpty.png'
import { converPriceWithCommas } from '../../../store/CustomFunctions'
import Select from 'react-select'

export const ProductSendToPromo = ({ visibleModalSelection, setvisibleModalSelection, listOfSelectedProduct, setListOfSelectedProduct, setSweetTimmer }) => {
    const [promoOption, setPromoOption] = useState([

    ])
    const [formValues, setFormValues] = useState({
        promo_type: null
    })
    const [promoData, setPromoData] = useState([])
    const [validate, setValidate] = useState(false)

    const renderImge = (data) => {
        if (data !== null && data !== undefined && data.length > 0) {
            return <CardImg className="avatar-md pl-0" src={getAPIroot() + data[0].url} />
        } else {
            return <CardImg className="avatar-md pl-0" src={imgEmpty} />
        }
    }

    useEffect(() => {
       if (visibleModalSelection) {
        get(`/promotions`).then(response => {
            const mapping = response.map(item => {
                return {
                    label: item.title,
                    value: item.id
                }
            })
            setPromoData(response)
            setPromoOption([{ label: "Select Promo", value: null }, ...mapping])
        })
       }
    }, [visibleModalSelection])

    const handleSubmit = (e) => {
        e.preventDefault();
        setValidate(true)
        if (formValues.promo_type !== undefined && formValues.promo_type !== null) {
            setValidate(false)
            const findPromo = promoData.find(x => x.id === formValues.promo_type.value)
            const promoItem = listOfSelectedProduct.map(x => {
                return {
                    product: x,
                    promo_price: 0
                }
            })
            let data = {
                ...findPromo,
                product_promos: [
                    ...findPromo.product_promos,
                    ...promoItem
                ]
            }
            update(`/promotions/${formValues.promo_type.value}`, data).then(response => {
                setSweetTimmer({
                    title: "Promotion has been set into this item.",
                    status: true
                })
                setListOfSelectedProduct([])
                setFormValues({
                    promo_type: null
                })
                setvisibleModalSelection(!visibleModalSelection)
            }).catch((err) => { })
        }
    }


    return (
        <Modal
            size='lg'
            isOpen={visibleModalSelection}
            toggle={() => setvisibleModalSelection(!visibleModalSelection)}
            centered={true}
        >
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    Products
            </ModalHeader>
                <ModalBody className="p-0">
                    <Table responsive className="table-centered mb-0 table-nowrap">
                        <thead>
                            <tr>
                                <th className="border-top-0" style={{ width: 110 }} scope="col">Image</th>
                                <th className="border-top-0" scope="col">Product Name</th>
                                <th className="border-top-0" scope="col">Category</th>
                                <th className="border-top-0" scope="col">Price</th>
                                <th className="border-top-0" scope="col">In Promo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listOfSelectedProduct && listOfSelectedProduct.map((row, index) => {
                                return <tr key={index}>
                                    <td>{renderImge(row.variants[0].image)}</td>
                                    <td>{row.title}</td>
                                    <td>{row.categories.map((item, i) => {
                                        return <div key={i}><Badge color='light' className="ml-1 mr-1">{item.title}</Badge></div>
                                    })}</td>
                                    <td>{converPriceWithCommas(row.variants[0].price)}</td>
                                    <td></td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <div className="pt-4 pl-4 pr-4 pb-3">
                        <Label style={{ color: validate && "#ff3d60" }}>Send to Promo: </Label>
                        <Select
                            className={validate && "custom-validate"}
                            isClearable={true}
                            value={formValues.promo_type}
                            options={promoOption}
                            onChange={(val) => {

                                if (val === null || val.value === null) {
                                    setFormValues({
                                        promo_type: null
                                    })
                                    setValidate(true)
                                } else {
                                    setFormValues({
                                        promo_type: val
                                    })
                                    if (validate) {
                                        setValidate(false)
                                    }
                                }

                            }}
                        />
                        {validate && <p className="mt-2 font-size-11" style={{ color: "#ff3d60" }}>Send to Promo is required!</p>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="mb-3 w-100">
                        <div className="float-left">
                            <Button
                                disabled={listOfSelectedProduct && listOfSelectedProduct.length === 0 ? true : false}
                                type="button"
                                style={{ textDecoration: "none" }}
                                className="waves-effect waves-light"
                                color="link"
                                onClick={e => {
                                    setvisibleModalSelection(!visibleModalSelection)
                                    setListOfSelectedProduct([])
                                    setValidate(false)
                                }}
                            >Clear Selection</Button>
                        </div>
                        <div className="float-right">
                            <Button
                                disabled={listOfSelectedProduct && listOfSelectedProduct.length === 0 ? true : false}
                                type="button"
                                style={{ textDecoration: "none" }}
                                className="waves-effect waves-light mr-4"
                                color="link"
                                onClick={e => {
                                    setValidate(false)
                                    setvisibleModalSelection(!visibleModalSelection);
                                }}
                            >Cancel</Button>
                            <Button
                                disabled={listOfSelectedProduct && listOfSelectedProduct.length === 0 ? true : false}
                                type="submit"
                                className="waves-effect waves-light mr-2"
                                color="primary"
                            >Submit</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
