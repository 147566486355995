import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';
import { Card, CardBody, Media, Spinner } from 'reactstrap';

// const arrMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const option = {
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                // max: 100,
                min: 0,
                stepSize: 10,
            }
        }]
    }
}

const LineChartOrder = ({ lineData, loading, title }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    })
    useEffect(() => {
        // const date = new Date()
        // const year = date.getFullYear()
        // if (lineData.length > 0) {

        //     const remapData = (arr) => {
        //         let newArr = []
        //         const newData = [...arrMonth]
        //         newData.forEach((element) => {
        //             const findData = arr.find(x => x.month === element)
        //             if (findData) {
        //                 newArr.push(findData.value)
        //             } else {
        //                 newArr.push(0)
        //             }
        //         })
        //         return newArr
        //     }

        //     const filterByYearCurrent = lineData.filter(x => x.year === year)
        //     const filterByYearPast = lineData.filter(x => x.year === (year - 1))


        //     // const labels = filterByYearCurrent.map(x => { return x.month })
        //     const data = remapData(filterByYearCurrent)


        //     // const labelsPast = filterByYearPast.map(x => { return x.month })
        //     const dataPast = remapData(filterByYearPast)


        setChartData({
            labels: lineData.data_a,
            datasets: [
                {
                    // label: year,
                    fill: true,
                    lineTension: 0.5,
                    backgroundColor: "rgba(85, 110, 230, 0.2)",
                    borderColor: "#556ee6",
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: "#556ee6",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "#556ee6",
                    pointHoverBorderColor: "#fff",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: lineData.data_b
                },
                //         {
                //             label: year - 1,
                //             fill: true,
                //             lineTension: 0.5,
                //             backgroundColor: "rgba(235, 239, 242, 0.2)",
                //             borderColor: "rgba(235, 239, 242, 1)",
                //             borderCapStyle: 'butt',
                //             borderDash: [],
                //             borderDashOffset: 0.0,
                //             borderJoinStyle: 'miter',
                //             pointBorderColor: "rgba(235, 239, 242, 1)",
                //             pointBackgroundColor: "#fff",
                //             pointBorderWidth: 1,
                //             pointHoverRadius: 5,
                //             pointHoverBackgroundColor: "rgba(235, 239, 242, 1)",
                //             pointHoverBorderColor: "#fff",
                //             pointHoverBorderWidth: 2,
                //             pointRadius: 1,
                //             pointHitRadius: 10,
                //             data: dataPast
                //         }
            ]
        })
        // }
    }, [lineData])
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{title}</p>
                            {loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}><Spinner color="primary" size="md" /></div> :
                                <Line width={474} height={300} data={chartData} options={option} />}
                        </Media>
                    </Media>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
export default LineChartOrder