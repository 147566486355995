import { ACTION_TYPE } from "./ActionType"

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE.GET_BOOKING_SERVICE_PRICING_NURSE:
            const nurseData = action.payload.find(item => item.category_type === 'nurse')
            const rate_price = nurseData.dynamic_price_rate;
            return {
                schedule_rate_price: [
                    {
                        label: '0-4 HRS',
                        value: '0-4 HRS',
                        price: rate_price.first_hours_price,
                        totalHours: 4
                    },
                    {
                        label: 'ADD HR (4-12 HRS)',
                        value: 'ADD HR (4-12 HRS)',
                        price: rate_price.extra_12_hours_price,
                        totalHours: 12
                    },
                    {
                        label: 'DAY RATE HR (0-24 HRS)',
                        value: 'DAY RATE HR (0-24 HRS)',
                        price: rate_price.day_price,
                        totalHours: 24
                    },
                    {
                        label: 'WEEK RATE HR  (7 DAYS) 12Hrs',
                        value: 'WEEK RATE HR  (7 DAYS) 12Hrs',
                        price: rate_price.week_12_hours_price,
                        totalHours: 12
                    },
                    {
                        label: 'WEEK RATE HR (7 DAYS) 24Hrs',
                        value: 'WEEK RATE HR (7 DAYS) 24Hrs',
                        price: rate_price.week_24_hours_price,
                        totalHours: 24
                    },
                    {
                        label: 'MONTH (5DYS/WEEK) 12Hrs',
                        value: 'MONTH (5DYS/WEEK) 12Hrs',
                        price: rate_price.month_5_days_12_hours_price,
                        totalHours: 12
                    },
                    {
                        label: 'MONTH (6DYS/WEEK) 12Hrs',
                        value: 'MONTH (6DYS/WEEK) 12Hrs',
                        price: rate_price.month_6_days_12_hours_price,
                        totalHours: 12
                    },
                    {
                        label: 'MONTH (7DYS/WEEK) 12Hrs',
                        value: 'MONTH (7DYS/WEEK) 12Hrs',
                        price: rate_price.month_7_days_12_hours_price,
                        totalHours: 12
                    },
                    {
                        label: 'MONTH (5DYS/WEEK) 24Hrs',
                        value: 'MONTH (5DYS/WEEK) 24Hrs',
                        price: rate_price.month_5_days_24_hours_price,
                        totalHours: 24
                    },
                    {
                        label: 'MONTH (6DYS/WEEK) 24Hrs',
                        value: 'MONTH (6DYS/WEEK) 24Hrs',
                        price: rate_price.month_6_days_24_hours_price,
                        totalHours: 24
                    },
                    {
                        label: 'MONTH (7DYS/WEEK) 24Hrs',
                        value: 'MONTH (7DYS/WEEK) 24Hrs',
                        price: rate_price.month_7_days_24_hours_price,
                        totalHours: 24
                    },
                    {
                        label: 'Custom',
                        value: 'Custom',
                        price: 0,
                        totalHours: 0
                    }
                ]
            }
        default:
            return null
    }
}

export const data = {
    schedule_rate_price: []
}