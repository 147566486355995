const useLocalStorage = {
    getItem: function(key) {
      const currentData = JSON.parse(localStorage.getItem('admin')) || {};
      return currentData[key];
    },
    setItem: function(key, val) {
      const currentData = JSON.parse(localStorage.getItem('admin')) || {};
      currentData[key] = val;
      localStorage.setItem('admin', JSON.stringify(currentData))
    },
    clear: function() {
      // localStorage.setItem('admin', null)
      localStorage.clear()
    }
  }
  
  export default useLocalStorage;
  