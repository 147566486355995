import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Card, CardBody, CardTitle, Col, Container, Row, Input, Form, FormGroup, Label, Button, CardText, Table, CardImg, Spinner } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Dropzone from 'react-dropzone'
import dateFnsFormat from 'date-fns/format';
import { converPriceWithCommas, formatBytes } from '../../../store/CustomFunctions';
import { get, getAPIroot, post, remove, update } from '../../../helpers/API';
import AlertSuccess from '../../CustomComponent/Alert/AlertSuccess';
import AlertConfirmation from '../../CustomComponent/Alert/AlertConfirmation';
import imgEmpty from '../../../assets/images/imgEmpty.png'

// ColorPicker
// import ColorPicker from "@vtaits/react-color-picker";
// import "@vtaits/react-color-picker/dist/index.css";

let timeout = null

let breadcrumbItems = [
    { title: "Promotions", link: "/store/promotions" },
    { title: "Details", link: "#" },
]

const FORMAT = 'dd-MM-yyyy'

export const formatDate = (date, format, locale) => {
    return dateFnsFormat(date, format, { locale });
}

export const LeftSideContent = ({
    props,
    selectedData,
    loading,
    setSelectedData,
    sweetTimmer,
    setSweetTimmer,
    alertConfirm,
    setAlertConfirm,
    setPrompItem,
    listOfPromoItem,
    setListOfPromoItem,
    setVisibleEdit,
    visibleEdit,
    setSelectedRemoveItem
}) => {

    const renderImge = (item) => {
        if (item !== null && item !== undefined) {
            return <CardImg className="avatar-md pl-0" src={getAPIroot() + item.variants[0].image[0].url} />
        } else {
            return <CardImg className="avatar-md pl-0" src={imgEmpty} />
        }
    }

    const handleRemove = (id) => {
        setAlertConfirm(!alertConfirm)
        setSelectedRemoveItem(id)
        setPrompItem(prev => {
            return {
                ...prev,
                title: `Are you sure?`,
                action: "remove_promo_item"
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!visibleEdit) {
            let data = {
                ...selectedData,
                product_promos: listOfPromoItem
            }

            update(`/promotions/${data.id}`, data).then(response => {
                setSelectedData(response);
                setSweetTimmer(!sweetTimmer)
            }).catch(() => { })
        }
    }

    return (
        <Card>
            <CardBody>
                <Form onSubmit={handleSubmit}>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="d-flex align-items-center"><i className="ri-file-list-line mr-2 align-middle" /><h5 className="mb-0 font-size-16">Product List</h5></div>
                        <div className="d-flex align-items-center">
                            <div className="custom-searching-box">
                                <Input
                                    type="text"
                                    placeholder="Search..."
                                    onChange={e => {
                                        let keyword = e.target.value.toLowerCase()
                                        clearTimeout(timeout)
                                        timeout = setTimeout(() => {
                                            const newData = [...listOfPromoItem];
                                            const searchItem = newData.filter(x => {
                                                let sCharItemName = x.product.title.toLowerCase()
                                                return sCharItemName.includes(keyword)
                                            })
                                            if (searchItem.length === 0) {
                                                setListOfPromoItem(selectedData.product_promos)
                                            } else {
                                                setListOfPromoItem(searchItem)
                                            }

                                            if (keyword.length === 0) {
                                                setListOfPromoItem(selectedData.product_promos)
                                            }
                                        }, 1000);
                                    }}
                                />
                                <i className="ri-search-line"></i>
                            </div>
                            {listOfPromoItem &&
                                listOfPromoItem.length > 0 &&
                                <Button
                                    color="light"
                                    className="ml-2"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        props.history.push(`/store/products`)
                                    }}
                                >
                                    <i className=" ri-add-line" />
                                </Button>}
                            <Button
                                color={visibleEdit ? "primary" : "light"}
                                className="ml-2"
                                type="submit"
                                disabled={listOfPromoItem && listOfPromoItem.length === 0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setVisibleEdit(!visibleEdit)

                                }}
                            >
                                {visibleEdit ? "Save" : "Edit"}
                            </Button>
                        </div>
                    </div>
                    {!loading &&
                        <div>
                            {listOfPromoItem && listOfPromoItem.length === 0 ? (
                                <div className="border-top pt-4">
                                    <div style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center"
                                    }}>
                                        <div>
                                            <CardTitle>No products</CardTitle>
                                            <CardText>Please press the button below to add products in the promotion</CardText>
                                            <Button
                                                color="light"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    props.history.push(`/store/products`)
                                                }}
                                            >go to Products</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Table responsive className="table-centered mb-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th className="border-top-0" scope="col">Image</th>
                                            <th className="border-top-0" scope="col" >Title</th>
                                            <th className="border-top-0" scope="col" style={{ width: 100 }}>Price</th>
                                            <th className="border-top-0" scope="col" style={{ width: visibleEdit ? 150 : 100 }}>Promo Price</th>
                                            <th className="border-top-0" scope="col" style={{ width: 30 }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listOfPromoItem && listOfPromoItem.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    {loading && <td><div><Spinner /></div></td>}
                                                    <td>{renderImge(row.product)}</td>
                                                    <td><div style={{ maxWidth: 300, whiteSpace: "break-spaces" }}>{!row.product ? "-" : row.product.title}</div></td>
                                                    <td>{!row.product ? "-" : converPriceWithCommas(row.product.variants[0].price)}</td>
                                                    <td>
                                                        {!visibleEdit ? (converPriceWithCommas(row.promo_price)) : (
                                                            <Input
                                                                size="md"
                                                                type="text"
                                                                value={row.promo_price}
                                                                onChange={e => {
                                                                    const newData = [...listOfPromoItem]
                                                                    const findIndex = newData.findIndex(x => x.id === row.id)
                                                                    const item = newData[findIndex];

                                                                    let obj = {
                                                                        ...item,
                                                                        promo_price: e.target.value
                                                                    }

                                                                    newData.splice(index, 1, { ...item, ...obj })

                                                                    setListOfPromoItem(newData)
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            color="link"
                                                            style={{ textDecoration: "none", color: "#ff1741", fontSize: "1.2rem" }}
                                                            onClick={() => handleRemove(row.id)}>
                                                            <i className=" ri-delete-bin-line align-middle" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </Table>
                            )}
                        </div>}
                </Form>
            </CardBody>
        </Card>
    )
}

export const RightSideContent = ({ props, selectedData, loading, sweetTimmer, setSweetTimmer, setAlertConfirm, alertConfirm, setPrompItem }) => {
    const [formValues, setFormValues] = useState({
        title: '',
        notes: '',
        is_active: false,
        start_datetime: null,
        end_datetime: null,
        banner_desktop: null,
        banner_mobile: null,
        badge: null,
        banner_bg_code_color: ""
    })
    const [listOfImage, setListOfImage] = useState([])
    // const [visibleColorPicker, setVisibleColorPicker] = useState(false)

    useEffect(() => {
        if (selectedData !== undefined && selectedData !== null) {
            setFormValues(prev => {
                return {
                    title: !selectedData.title ? prev.title : selectedData.title,
                    notes: !selectedData.notes ? prev.notes : selectedData.notes,
                    is_active: !selectedData.is_active ? prev.is_active : selectedData.is_active,
                    start_datetime: !selectedData.start_datetime ? prev.start_datetime : selectedData.start_datetime,
                    end_datetime: !selectedData.end_datetime ? prev.end_datetime : selectedData.end_datetime,
                    banner_desktop: !selectedData.banner_desktop ? prev.banner_desktop : selectedData.banner_desktop,
                    banner_mobile: !selectedData.banner_mobile ? prev.banner_mobile : selectedData.banner_mobile,
                    badge: !selectedData.badge ? prev.badge : selectedData.badge,
                    banner_bg_code_color: !selectedData.banner_bg_code_color ? prev.banner_bg_code_color : selectedData.banner_bg_code_color,
                }
            })
        }
    }, [selectedData])

    const CustomOverlay = ({ classNames, selectedDay, children, ...props }) => {
        return (
            <div
                className={classNames.overlayWrapper}
                style={{ marginLeft: -180, position: "absolute", zIndex: 1000 }}
                {...props}
            >
                <div className={classNames.overlay}>
                    {children}
                </div>
            </div>
        );
    }

    const handleDateStartOn = (day, modifiers = {}) => {
        if (day !== undefined) {
            setFormValues(prev => {
                return {
                    ...prev,
                    start_datetime: moment(day).toISOString()
                }
            })
        }
    }

    const handleDateExpiredOn = (day, modifiers = {}) => {
        if (day !== undefined) {
            setFormValues(prev => {
                return {
                    ...prev,
                    end_datetime: moment(day).toISOString()
                }
            })
        }
    }

    const handleAcceptedFiles = (property, files) => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        let formData = new FormData();
        formData.append('files', files[0])

        post(`/upload`, formData).then(response => {
            let array = [...listOfImage, ...response]
            setListOfImage(array)
            setFormValues(prev => {
                return {
                    ...prev,
                    [property]: response[0]
                }
            })
        })
    }

    const handleForm = (property, value) => {
        setFormValues(prev => {
            return {
                ...prev,
                [property]: value
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedData && selectedData.id) {
            update(`/promotions/${selectedData.id}`, formValues).then(response => {

                setFormValues(prev => {
                    return {
                        title: !response.title ? prev.title : response.title,
                        notes: !response.notes ? prev.notes : response.notes,
                        is_active: !selectedData.is_active ? prev.is_active : selectedData.is_active,
                        start_datetime: !response.start_datetime ? prev.start_datetime : response.start_datetime,
                        end_datetime: !response.end_datetime ? prev.end_datetime : response.end_datetime,
                        banner_desktop: !response.banner_desktop ? prev.banner_desktop : response.banner_desktop,
                        banner_mobile: !response.banner_mobile ? prev.banner_mobile : response.banner_mobile,
                        badge: !response.badge ? prev.badge : response.badge,
                        banner_bg_code_color: !response.banner_bg_code_color ? prev.banner_bg_code_color : response.banner_bg_code_color,
                    }
                })
                setSweetTimmer(!sweetTimmer)
                setTimeout(() => {
                    props.history.push('/store/promotions')
                }, 1000);
            }).catch(err => { })
        }
    }

    const renderImage = (url) => {

        if (url.id === undefined) {
            return <img alt="" style={{ width: '100%' }} src={url.preview} />
        } else {
            return <img alt="" style={{ width: '100%' }} src={getAPIroot() + url.url} />
        }

    }

    const handleRemoveImg = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            remove(`/upload/files/${arr[i].id}`).then(res => {
                props.history.push('/store/promotions')
            }).catch(err => {
                props.history.push('/store/promotions')
            })
        }
    }

    const handleCancel = () => {
        if (listOfImage.length === 0) {
            props.history.push('/store/promotions')
        } else {
            handleRemoveImg(listOfImage)
        }
    }
    return (
        <Card>
            <CardBody>
                <div className="d-flex mb-3 align-items-center"><i className="ri-truck-line mr-2 align-middle" /><h5 className="align-middle mb-0 font-size-16">Settings</h5></div>
                <div className="border-top pt-4">
                    <Form onSubmit={e => handleSubmit(e)}>

                        <div className="mb-4">
                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Title</Label>
                                <Col sm={8}>
                                    <Input
                                        disabled={loading}
                                        type="text"
                                        name="title"
                                        placeholder="Title"
                                        value={!formValues.title ? "" : formValues.title}
                                        onChange={e => handleForm('title', e.target.value)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Start On</Label>
                                <Col sm={8}>
                                    <DayPickerInput
                                        format={FORMAT}
                                        formatDate={formatDate}
                                        overlayComponent={CustomOverlay}
                                        value={formValues.start_datetime === null ? null : dateFnsFormat(new Date(formValues.start_datetime), FORMAT)}
                                        onDayChange={handleDateStartOn}
                                        placeholder={`e.g: ${dateFnsFormat(new Date(), FORMAT)}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Expired On</Label>
                                <Col sm={8}>
                                    <DayPickerInput
                                        format={FORMAT}
                                        formatDate={formatDate}
                                        overlayComponent={CustomOverlay}
                                        value={formValues.end_datetime === null ? null : dateFnsFormat(new Date(formValues.end_datetime), FORMAT)}
                                        onDayChange={handleDateExpiredOn}
                                        placeholder={`e.g: ${dateFnsFormat(new Date(moment().add(1, 'months')), FORMAT)}`}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Status</Label>
                                <Col sm={8}>
                                    <div className="custom-control custom-switch mt-2" dir="ltr">
                                        <Input type="checkbox" className="custom-control-input" id="customSwitch1" checked={formValues.is_active} onChange={(e) => { handleForm('is_active', !formValues.is_active) }} />
                                        <Label className="custom-control-label" htmlFor="customSwitch1">{formValues.is_active === true ? "Active" : "Inactive"}</Label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </div>

                        <div className="mt-2 mb-4 border-top">
                            <CardTitle className="pt-3 pb-3">Banner Setting</CardTitle>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Banner Desktop</Label>
                                <Col sm={8}>
                                    <Dropzone disabled={loading} onDrop={acceptedFiles => { handleAcceptedFiles('banner_desktop', acceptedFiles) }} >
                                        {({ getRootProps, getInputProps, open }) => (
                                            <div className="single-drop" style={{ maxWidth: formValues.banner_mobile !== null && 250 }}>
                                                <div {...getRootProps()}>
                                                    <input  {...getInputProps()} />

                                                    {formValues.banner_desktop === null ?
                                                        <div className="dz-message needsclick px-0 py-3" style={{ fontSize: 14 }}>
                                                            <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                                        </div> :
                                                        renderImage(formValues.banner_desktop)
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <p className="m-0 pt-2">Size 1000x590</p>
                                </Col>
                            </FormGroup>

                            <FormGroup row className="mt-5">
                                <Label className="text-muted" for="exampleEmail" sm={4}>Banner Mobile</Label>
                                <Col sm={8}>
                                    <Dropzone disabled={loading} onDrop={acceptedFiles => { handleAcceptedFiles('banner_mobile', acceptedFiles) }} >
                                        {({ getRootProps, getInputProps, open }) => (
                                            <div className="single-drop" style={{ maxWidth: formValues.banner_mobile !== null && 250 }}>
                                                <div {...getRootProps()}>
                                                    <input  {...getInputProps()} />

                                                    {formValues.banner_mobile === null ?
                                                        <div className="dz-message needsclick px-0 py-3" style={{ fontSize: 14 }}>
                                                            <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                                        </div> :
                                                        renderImage(formValues.banner_mobile)
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Backgroup Color</Label>
                                <Col sm={8}>
                                    {/* <InputGroup className="colorpicker-default" title="Using format option">
                                        <Input readOnly type="text" className="form-control input-lg" />
                                        <InputGroupAddon addonType="append">
                                            <span
                                                className="input-group-text colorpicker-input-addon p-0"
                                                onClick={() => setVisibleColorPicker(!visibleColorPicker)}
                                            >
                                                <i style={{
                                                    height: 39,
                                                    width: 39,
                                                    borderTopRightRadius: ".25rem",
                                                    borderBottomRightRadius: ".25rem",
                                                    background: !formValues.banner_bg_code_color ? "none" : formValues.banner_bg_code_color
                                                }}></i>
                                            </span>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {visibleColorPicker ?
                                       <Card>
                                           <CardBody>
                                           <ColorPicker
                                            saturationHeight={100}
                                            saturationWidth={100}
                                            value={!formValues.banner_bg_code_color ? "" : formValues.banner_bg_code_color}
                                            onDrag={(color) => {
                                                console.log(color);
                                                handleForm('banner_bg_code_color', color)
                                            }}
                                        />
                                           </CardBody>
                                       </Card>
                                        : null} */}
                                    <Input
                                        disabled={loading}
                                        className="p-1"
                                        type="color"
                                        name="banner_bg_code_color"
                                        placeholder=""
                                        value={!formValues.banner_bg_code_color ? "" : formValues.banner_bg_code_color}
                                        onChange={e => {
                                            handleForm('banner_bg_code_color', e.target.value)
                                        }} />
                                </Col>
                            </FormGroup>
                        </div>

                        <div className="mt-2 border-top">
                            <CardTitle className="pt-3 pb-3">Product Setting</CardTitle>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Badge</Label>
                                <Col sm={8}>
                                    <Dropzone disabled={loading} onDrop={acceptedFiles => { handleAcceptedFiles('badge', acceptedFiles) }} >
                                        {({ getRootProps, getInputProps, open }) => (
                                            <div
                                                className="single-drop"
                                                style={{
                                                    minHeight: formValues.badge !== null && 120,
                                                    minWidth: formValues.badge !== null && 120,
                                                    width: "auto",
                                                    height: formValues.badge !== null && 200,
                                                }}
                                            >
                                                <div {...getRootProps()} style={{ height: "100%" }}>
                                                    <input  {...getInputProps()} />

                                                    {formValues.badge === null ?
                                                        <div className="dz-message needsclick px-0 py-3" style={{ fontSize: 12 }}>
                                                            <i className="display-4 text-muted ri-upload-line font-size-22 mt-4"></i>
                                                            <p className="m-0 pt-2">Size 300 x 70</p>
                                                        </div> :
                                                        <div style={{ height: 50, width: 50 }}>
                                                            {renderImage(formValues.badge)}

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <p className="m-0 pt-2">Size 300 x 70</p>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label className="text-muted" for="exampleEmail" sm={4}>Note</Label>
                                <Col sm={8}>
                                    <Input
                                        disabled={loading}
                                        type="textarea"
                                        name="notes"
                                        placeholder="Note"
                                        value={!formValues.notes ? "" : formValues.notes}
                                        onChange={e => {
                                            handleForm('notes', e.target.value)
                                        }}
                                    />
                                </Col>
                            </FormGroup>

                            <div className="d-flex justify-content-between mt-4 pt-3">
                                <Button color="danger" onClick={(e) => {
                                    e.preventDefault();
                                    setAlertConfirm(!alertConfirm)
                                    setPrompItem({
                                        title: "Are you sure?",
                                        desc: "",
                                        action: "remove_promo"
                                    })
                                }} ><i className="ri-delete-bin-line" /></Button>
                                <div>
                                    <Button className="mr-2" color="light" onClick={handleCancel}>Cancel</Button>
                                    <Button color="primary" type='submit'>Save</Button>
                                </div>
                            </div>
                        </div>

                    </Form>
                </div>
            </CardBody>
        </Card>
    )
}

export const PromotionDetails = (props) => {
    const [selectedData, setSelectedData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [sweetTimmer, setSweetTimmer] = useState(false)
    const [promptItem, setPrompItem] = useState({
        title: "",
        desc: "",
        action: ""
    })
    const [alertConfirm, setAlertConfirm] = useState(false)
    const [listOfPromoItem, setListOfPromoItem] = useState([]);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [selectedRemoveItem, setSelectedRemoveItem] = useState(null)

    const getPromoById = useMemo(() => {
        return props.match.params.id ? props.match.params.id : null
    }, [props.match.params.id]);

    useEffect(() => {
        let mount = true;
        setLoading(true)
        if (mount) {
            getPromos(getPromoById)
            setLoading(false)
        }

        return () => {
            mount = false
            setLoading(false)
        }
    }, [getPromoById, loading])

    const getPromos = (id) => {
        get(`/promotions/${id}`).then(response => {
            setSelectedData(response)
            setListOfPromoItem(response.product_promos)
        }).catch(err => { })
    }

    const removePromo = () => {
        if (selectedData && selectedData.id) {
            remove(`/promotions/${selectedData.id}`).then(response => {
                setSweetTimmer(!sweetTimmer);

                setTimeout(() => {
                    props.history.push('/store/promotions')
                }, 1000);
            }).catch(err => { })
        }
    }

    const removePromoItem = () => {
        if (selectedRemoveItem !== null) {
            const newData = [...listOfPromoItem]
            const filters = newData.filter(x => x.id !== selectedRemoveItem);

            let data = {
                ...selectedData,
                product_promos: filters
            }

            update(`/promotions/${data.id}`, data).then(response => {
                setSelectedData(response);
                setListOfPromoItem(response.product_promos)
                setSweetTimmer(!sweetTimmer)
            }).catch(() => { })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={"Promotion"} breadcrumbItems={breadcrumbItems} />

                    <Row>
                        <Col md={8}>
                            <LeftSideContent
                                props={props}
                                selectedData={selectedData}
                                loading={loading}
                                setSelectedData={setSelectedData}
                                sweetTimmer={sweetTimmer}
                                setSweetTimmer={setSweetTimmer}
                                setAlertConfirm={setAlertConfirm}
                                alertConfirm={alertConfirm}
                                setPrompItem={setPrompItem}
                                removePromoItem={removePromoItem}
                                setListOfPromoItem={setListOfPromoItem}
                                listOfPromoItem={listOfPromoItem}
                                visibleEdit={visibleEdit}
                                setVisibleEdit={setVisibleEdit}
                                setSelectedRemoveItem={setSelectedRemoveItem}
                            />
                        </Col>
                        <Col md={4}>
                            <RightSideContent
                                props={props}
                                selectedData={selectedData}
                                loading={loading}
                                setSweetTimmer={setSweetTimmer}
                                sweetTimmer={sweetTimmer}
                                setAlertConfirm={setAlertConfirm}
                                alertConfirm={alertConfirm}
                                setPrompItem={setPrompItem}
                            />
                        </Col>
                    </Row>

                    <AlertSuccess
                        title="Success!"
                        sweetTimmer={sweetTimmer}
                        setSweetTimmer={setSweetTimmer}
                    />

                    <AlertConfirmation
                        title={promptItem.title}
                        desc={promptItem.desc}
                        action={promptItem.action}
                        removePromo={removePromo}
                        setAlertConfirm={setAlertConfirm}
                        alertConfirm={alertConfirm}
                        removePromoItem={removePromoItem}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}
