export const ACTION_TYPE = {
    UPDATE_STATE: 'update_state',
    UPDATE_PATIENT: 'update_patient_details',
    SET_FORM: 'set_form',
    RESET_STATE: 'reset_state',
    UPDATE_PRESCRIPTION: 'update_prescription_details',
    UPDATE_VISIBILITY_FOOTER: 'update_visibility_footer',
    // new
    SET_PATIENT: 'set_patient',
    SET_PRESCRIPTION: 'set_prescription',
    UPDATE_ACTION_TYPE: 'update_action_type',
    SET_FROM_PAGE: 'set_from_page',
    SET_CONSULTATION: 'set consultation',
    CHANGE_CONTENT_TYPE: 'change_content_type',
    CHANGE_ALL: 'change_all',
    UPDATE_CHAT: 'update chatting'
}