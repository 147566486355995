import React, { createContext, useReducer } from 'react'
import { initialState } from './initialState'
import { reducer } from './reducer'

export const PrescriptionContext = createContext();

const PrescriptionProvider = ({ children }) => {
  const [prescriptionDataLayer, dispatch] = useReducer(reducer, initialState);
  // console.log(prescriptionDataLayer);
  return (
    <PrescriptionContext.Provider value={{ prescriptionDataLayer, dispatch }}>
      {children}
    </PrescriptionContext.Provider>
  )
}

export default PrescriptionProvider