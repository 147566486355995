import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Spinner } from 'reactstrap';
import classNames from 'classnames';
import { capitalizeFirstLetter, handleStatusStyle } from '../../../../store/CustomFunctions';
import { get } from '../../../../helpers/API';
import { initialDataTable } from './tableColumn';
import { Link } from 'react-router-dom';
import NurseProvider from './Store/NurseContext';
import { TabNurse } from './TabNurse'
import ServerSidePaginations from '../../../../components/Table/ServerSidePaginations'
import { providerCOntent } from './Store/AssessmentData'
import useLocalStorage from '../../../../helpers/useLocalStorage';

let defaultRate = <div className="d-inline-flex" style={{ width: 150 }}>
    <div className="text-muted mr-1">
        <span className=" ri-star-s-line mr-1"></span>
        <span className=" ri-star-s-line mr-1"></span>
        <span className=" ri-star-s-line mr-1"></span>
        <span className=" ri-star-s-line mr-1"></span>
        <span className=" ri-star-s-line mr-1"></span>
    </div>
    <div className="text-muted">( 0 )</div>
</div>

let initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: '',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}



export const ratingUI = (value) => {
    let rating = defaultRate;

    if (value > 0) {
        const rateValue = value.toString();
        const splitValue = rateValue.split('.');
        let newEmptyObj = [];
        let newObj = [];
        const arr1 = parseInt(splitValue[0]);

        for (let i = 0; i < arr1; i++) {
            newObj.push({ key: i, component: <span key={i} className=" ri-star-s-fill text-warning mr-1"></span> })
        };

        if (rateValue.includes('.')) {
            let noValue = parseInt(splitValue[1]) > 0 ? 5 - (arr1 + 1) : arr1;
            for (let i = 0; i < noValue; i++) {
                newEmptyObj.push({ key: i, component: <span key={i} className=" ri-star-s-line mr-1"></span> })
            }
        } else {
            let noValue = 5 - arr1;
            for (let i = 0; i < noValue; i++) {
                newEmptyObj.push({ key: i, component: <span key={i} className=" ri-star-s-line mr-1"></span> })
            }
        };

        rating = <div className="d-inline-flex" style={{ width: 180 }}>
            <div className="text-muted mr-1">
                {newObj.map(x => {
                    return x.component
                })}
                {splitValue[1] > 0 && <span className=" ri-star-half-s-line text-warning mr-1"></span>}

                {newEmptyObj.map(x => {
                    return x.component
                })}
            </div>
            <div className="text-muted">( {value} )</div>
        </div>
    }

    return rating
}



const NursePage = (props) => {
    const [initialParams, setInitialParams] = useState(initialValue)
    const [activeTab, setActiveTab] = useState('1')
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [loading, setLoading] = useState(() => false)

    const getBooking = useCallback((
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords,
    ) => {
        setLoading(true)
        setDataSource(prev => {
            return {
                ...prev,
                rows: [{
                    client: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                }]
            }
        })

        let params = ""
        let url = new URLSearchParams(params)

        url.set('_limit', limit);
        url.set('_start', page === 0 ? page : (page * 10) - 10)
        url.set('_sort', sortBy);

        if (category) {
            url.set('category', category)
        }

        get(`/booking-forms/count?${url.toString()}`).then(count => {
            get(`/booking-forms?${url.toString()}`).then(async res => {
                let i = 0;
                let arrayData = [];

                if (res && res.length > 0) {
                    arrayData = [...res]
                }

                let timearr = useLocalStorage.getItem('counterData');
                if (timearr === undefined) {
                    let counterData = arrayData.map(x => {
                        return {
                            id: x.id,
                            counter: x.provider_booking_jobs.find(x => x.type_of_job === 'assigned') ? '05' : 0,
                        }
                    })
                    useLocalStorage.setItem('counterData', counterData)
                } else {
                    let counterData = timearr.map(x => {
                        return {
                            id: x.id,
                            counter: x.counter,
                        }
                    })
                    useLocalStorage.setItem('counterData', counterData)
                }

                for (; i < arrayData.length; i++) {
                    const selectedItem = arrayData[i].provider_booking_jobs.find(x => x.type_of_job === 'assigned')
                    let selectedName = ''
                    if (selectedItem !== undefined && selectedItem !== null) {
                        if (selectedItem.provider !== undefined && selectedItem.provider !== null) {
                            const responseProvider = await get(`/providers/${selectedItem.provider}`)
                            if (responseProvider) {
                                selectedName = responseProvider.fullname
                            }
                        }
                    }
                    arrayData[i].assignedName = selectedName
                }

                let obj = providerCOntent.find(x => x.path === window.location.pathname)

                const mapping = arrayData.map((item, index) => {
                    return {
                        // index: page === 1 ? (index + 1) : (index + (10 * page) - 9),
                        client: <Link to={{ pathname: `${obj.path}/${item.id}`, state: { item, title: obj.title, backUrl: obj.path, filter: obj.filter } }} className="text-dark font-weight-bold">{!item.patient_info.name ? "-" : item.patient_info.name}</Link>,
                        refId: item.id,
                        booking_mode: !item.booking_mode ? "-" : item.booking_mode === 'site_visit' ? 'Site Visit' : capitalizeFirstLetter(item.booking_mode),
                        financial_status: !item.financial_status ? '-' : <div className={handleStatusStyle(item.financial_status).badgeClass}>{handleStatusStyle(item.financial_status).text}</div>,
                        assignedTo: item.provider_booking_jobs && item.provider_booking_jobs.length === 0 ? '-' : !item.assignedName ? "-" : item.assignedName,
                        rating: !item.booking_feedback ? ratingUI(0) : ratingUI(item.booking_feedback.overall_booking_rate),
                        booking_status: <div className={handleStatusStyle(!item.booking_status ? 'new' : item.booking_status).badgeClass}>{handleStatusStyle(item.booking_status).text}</div>,
                        clickEvent: () => {
                            if (props.history !== undefined || props.history !== null) {
                                return props.history.push(`${obj.path}/${item.id}`)
                            }
                        }
                    }
                })
                setDataSource(prev => {
                    return {
                        ...prev,
                        rows: mapping
                    }
                })
                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    page,
                    total: count,
                    category,
                    keywords,
                })

                setLoading(false)
            }).catch(() => { setLoading(false) })
        }).catch(() => { setLoading(false) })
    }, [props.history])

    useEffect(() => {
        let mount = false;

        const cat = providerCOntent.find(x => x.path === window.location.pathname).filter
        if (!mount) {
            getBooking(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                cat,
                initialValue.keywords,
            )
        }

        return () => {
            mount = true
        }
    }, [getBooking])

    return (
        <React.Fragment>
            <NurseProvider>
                <div className="page-content">
                    <Container fluid>

                        <h4>{providerCOntent.find(x => x.path === window.location.pathname).title}</h4>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="pt-0">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            {TabNurse.map((item, index) => {
                                                return (
                                                    <NavItem key={index}>
                                                        <NavLink onClick={() => { setActiveTab(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3")}>{item.name}</NavLink>
                                                    </NavItem>
                                                )
                                            })}
                                        </Nav>

                                        <ServerSidePaginations
                                            loading={loading}
                                            dataSource={dataSource}
                                            getItems={(params) => {
                                                getBooking(
                                                    params.sortBy,
                                                    params.orderBy,
                                                    params.limit,
                                                    params.page,
                                                    params.total,
                                                    params.category,
                                                    params.keywords,
                                                )
                                            }}
                                            emptyMessage={`There are no ${!providerCOntent.find(x => x.path === window.location.pathname).title ? "" : providerCOntent.find(x => x.path === window.location.pathname).title.toLowerCase()} yet`}
                                            obj={providerCOntent.find(x => x.path === window.location.pathname)}
                                            initialParams={initialParams}
                                            setLoading={setLoading}
                                        />

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </NurseProvider>
        </React.Fragment>
    )
}

export default NursePage
