export const initialDataTable = {
    columns: [
        {
            label: "Order No.",
            field: "order_no",
            sort: "asc",
            width: 150
        },
        {
            label: "Name",
            field: "name",
            sort: "disabled",
            width: 150
        },
        {
            label: "Email",
            field: "customer",
            sort: "asc",
            width: 250
        },
        {
            label: "Phone Number",
            field: "phoneNumber",
            sort: "disabled",
            width: 150
        },
        {
            label: "Date",
            field: "createdAt",
            sort: "asc",
            width: 150
        },
        {
            label: "Total Spending",
            field: "total_price",
            sort: "asc",
            width: 150
        },
        {
            label: "Status",
            field: "fulfillment_status",
            sort: "disabled",
            width: 150
        },
        {
            label: "Payment Status",
            field: "financial_status",
            sort: "disabled",
            width: 150
        },
    ],
}