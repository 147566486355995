import React, { useContext, useEffect, useState } from 'react'
import { Button, FormGroup, Label, Modal, ModalBody, Spinner } from 'reactstrap'
import { post, update } from '../../../../../helpers/API'
import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext'
import { formatBytes } from '../../../../../store/CustomFunctions'
import FormUpload from '../../../../../components/Common/FormUpload'
import { ACTION_TYPE } from '../../../../../store/PageWrapper/ActionType'

const ModalApprove = ({ visible, isClosed, loading, onSubmit }) => {
    const { globalData, setGlobalData } = useContext(GlobalContext)
    const [signature, setSignature] = useState(null)
    const [doctorName, setDoctorName] = useState('')
    const [formData, setFormData] = useState({
        check: false,
    })
    const [error, setError] = useState(false)
    const [uploadImgPreview, setUploadImg] = useState(null)
    const updateForm = (field, value) => {
        if (field === 'check' && value) {
            setError(false)
        }
        setFormData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }
    const handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        if (files.length > 0) setError(false)
        setUploadImg(files[0].preview)
        setSignature(files[0])
    }
    const resetForm = () => {
        setError(false)
        setDoctorName('')
        setFormData({
            check: false
        })
        setUploadImg(null)
        setSignature(null)
    }
    const handleUpload = () => {
        let data = signature;
        let formImg = new FormData();
        formImg.append(`files`, data);

        if (uploadImgPreview && formData.check) {
            setError(false)
            post(`/upload`, formImg).then((img) => {
                if (globalData.global.user && globalData.global.user.doctor) {
                    update(`/doctors/${globalData.global.user.doctor.id}`, { signature: img }).then(response => {
                        if (formData.check && response.signature) {
                            setGlobalData({
                                type: ACTION_TYPE.GET_USER_DATA,
                                payload: {
                                    ...globalData.global,
                                    user: { ...globalData.global.user, doctor: response },
                                    availability_doctor: response.is_online
                                }
                            })
                            onSubmit(formData)
                            resetForm()
                        }
                    })
                }
            }).catch(() => { })
        } else if (globalData.global.user.doctor && globalData.global.user.doctor.signature && formData.check) {
            setError(false)
            onSubmit(formData)
        } else {
            setError(true)
        }
    }
    useEffect(() => {
        if (globalData.global.user && globalData.global.user.username) {
            setDoctorName(globalData.global.user.username)
        }
        if (globalData.global.user && globalData.global.user.doctor && globalData.global.user.doctor.signature) {
            setSignature(globalData.global.user.doctor.signature)
        }
    }, [globalData])
    return (
        <Modal centered isOpen={visible} toggle={() => isClosed()}>

            <ModalBody>
                <h4>Approve this prescription?</h4>

                <div className='my-4'>
                    <Label>{!signature ? "Upload your signature" : "Signature"}</Label>
                    <FormUpload uploadImgPreview={uploadImgPreview} dataImage={signature} handleAcceptedFiles={handleAcceptedFiles} />
                    {/* {!signature ? <FormUpload uploadImgPreview={uploadImgPreview} dataImage={signature} handleAcceptedFiles={handleAcceptedFiles} /> : <div className='border rounded d-flex justify-content-center align-items-center'>
                            {!uploadImgPreview ? <CardImg className="avatar-md pl-0 m-0" src={getAPIroot() + signature.url} /> : <CardImg className="avatar-md pl-0 m-0" src={uploadImgPreview} />}
                        </div>} */}
                    <FormGroup className='mt-4'>
                        <div
                            className='d-flex justify-content-between w-100'
                            onClick={(e) => {
                                if (!loading) updateForm('check', !formData.check)
                            }}>
                            <div className='d-flex justify-content-between align-items-center border rounded mr-2 p-2 position-relative d-block' style={{ width: 33, height: 33 }}>
                                {formData.check && (<i className='fas fa-check align-middle text-primary' />)}
                            </div>
                            <Label className={error ? 'text-danger w-100' : 'text-secondary w-100'}>
                                I , {doctorName} confirm that I have read and approve this prescription.
                            </Label>
                        </div>
                    </FormGroup>
                </div>
                <div className='d-flex justify-content-center'>
                    <Button disabled={loading} className='w-100 mr-2' color='light' onClick={() => {
                        isClosed()
                        resetForm()
                    }}>Cancel</Button>
                    <Button className='w-100' color='primary' onClick={() => {
                        handleUpload()
                    }}>{loading && <Spinner color='light' size={"sm"} className="align-middle mr-2" />}Save</Button>
                </div>

            </ModalBody >
        </Modal >
    )
}

export default ModalApprove