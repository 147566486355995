import React, { useContext, useState } from 'react'
import { Button, Card, CardBody, Input, Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import { patientTabList, obj, initialValue, initialDataTableMainPatient } from '../../Store/initialState'
import classNames from 'classnames';
import { capitalizeFirstLetter } from '../../../../store/CustomFunctions'
import ServerSidePaginations from '../../../../components/Table/ServerSidePaginations'
import { get } from '../../../../helpers/API'
import moment from 'moment'
import AlertError from '../../../CustomComponent/Alert/AlertError'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData';
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType';
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext';

const PatientContent = ({ goToPage }) => {
  const { globalData } = useContext(GlobalContext)
  const { dispatch } = useContext(PrescriptionContext)
  const [activeTab, setActiveTab] = useState('all')
  const [dataSource, setDataSource] = useState(initialDataTableMainPatient)
  const [loading, setLoading] = useState(false)
  const [initialParams, setInitialParams] = useState(initialValue)
  const [keyword, setKeyword] = useState('')
  const [errMsg, setErrMsg] = useState({
    title: '',
    msg: '',
    visible: false
  })

  
  const getPatientById = async (
    sortBy,
    orderBy,
    limit,
    page,
    total,
    filter
  ) => {
    setLoading(true);

    setDataSource(prev => {
      return {
        ...prev,
        rows: [{
          index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
        }]
      }
    })

    let params = ""
    let url = new URLSearchParams(params)

    url.set('_start', page === 0 ? page : (page * 10) - 10);
    url.set('_sort', sortBy);

    if (filter) {
      url.set('nric', filter);
    }

    try {
      const _count = await get(`/customers/count?${url.toString()}`)
      url.set('_limit', _count);
      const _customers = await get(`/customers?${url.toString()}`)
      let new_data = []

      _customers.forEach((item) => {
        let name = ''
        let erx = ''

        if (item.user && item.user.username) {
          name = item.user.username
        }

        erx = item.prescriptions.length;

        new_data.push({
          index: new_data.length + 1,
          name,
          erx,
          date_registered: moment(item.createdAt).format("MMM DD, YYYY h:mm A"),
          clickEvent: () => {
            goToPage(`/e-prescription/patient/${item.id}`)

            dispatch({
              type: ACTION_TYPE.SET_PATIENT,
              payload: {
                data: item,
                role: globalData.global.role
            }
            })
          }
        })
      })
      setInitialParams({
        sortBy,
        orderBy,
        limit,
        total: new_data.length,
        page,
        filter
      })

      setDataSource(prev => {
        return {
          ...prev,
          rows: new_data
        }
      });
    } catch (error) {
      setErrMsg({
        title: "Error",
        msg: error,
        visible: true
      })
      setDataSource(initialDataTableMainPatient)
    }
  }

  const handleOnSearch = async () => {
    setLoading(true)

    setDataSource(prev => {
      return {
        ...prev,
        rows: [{
          index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
        }]
      }
    })

    getPatientById(
      initialParams.sortBy,
      initialParams.orderBy,
      initialParams.limit,
      initialParams.page,
      initialParams.total,
      keyword,
    )
  }

  const handleClear = () => {
    setKeyword('')
    setInitialParams(initialValue)
    setDataSource(initialDataTableMainPatient)
  }


  return (
    <React.Fragment>
      <h4 className='mb-4'>{`Patient list`}</h4>

      <Card className='card'>
        <CardBody className="pt-0">

          <Nav tabs className="nav-tabs-custom mb-4">
            {patientTabList.map((item, index) => {
              return (
                <NavItem key={index}>
                  <NavLink onClick={() => { setActiveTab(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3 align-middle")}>
                    {capitalizeFirstLetter(item.name)}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>


          <div className='d-flex '>
            <Input className='mb-3' style={{ width: 300 }} value={keyword} onChange={e => setKeyword(e.target.value)} />
            <div>
              <Button disabled={keyword.length === 0} className='waves-effect ml-2' color='primary' onClick={handleOnSearch} >Search</Button>
              {keyword.length > 0 && <Button className='waves-effect ml-2' color='danger' onClick={handleClear} >Clear</Button>}
            </div>
          </div>

          <ServerSidePaginations
            loading={loading}
            dataSource={dataSource}
            getItems={(params) => {
              // getPatientById(
              //     params.sortBy,
              //     params.orderBy,
              //     params.limit,
              //     params.page,
              //     params.total,
              //     params.filter,
              // )
            }}
            emptyMessage="There are no provider yet"
            obj={obj}
            initialParams={initialParams}
            setLoading={setLoading}
          />

        </CardBody>
      </Card>
      <AlertError
        title={errMsg.title}
        setErrMsg={setErrMsg}
        errMsg={errMsg}
      />
    </React.Fragment>
  )
}

export default PatientContent
