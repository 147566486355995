import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import ServerSidePaginations from '../../../../components/Table/ServerSidePaginations'
import { addColAction, initialDataTableMedication, initialValue } from '../../Store/initialState'

const Medication = ({ title, role, openModal, dataSource, handleUpdate, editMode, handleRemove, content_type, action_type }) => {
    const [dataTable, setDataTable] = useState({ columns: [...initialDataTableMedication.columns, addColAction], rows: [] })

    useEffect(() => {
        if (dataSource.length > 0) {
            const rows = dataSource.map((item, index) => {
                return {
                    ...item,
                    medicine: !item.product ? item.medicine.label : item.product.label,
                    actions:  editMode && (
                        <div className='d-flex justify-content-evenly' key={index}>
                            <Button color='light' outline className='mr-2' onClick={() => handleUpdate(item)}> <i className="ri-pencil-line align-middle"></i></Button>
                            <Button color='light' outline onClick={() => {
                                handleRemove(item)
                                setDataTable(prev => {
                                    const newData = prev.rows.filter(x => x.index !== item.index)
                                    return {
                                        ...prev,
                                        rows: newData
                                    }
                                })
                            }}> <i className="ri-delete-bin-7-line align-middle"></i></Button>
                        </div>
                    )
                }
            })
            setDataTable(prev => {
                return {
                    ...prev,
                    rows
                }
            })
        }
    }, [dataSource, handleUpdate, editMode, handleRemove])
    return (
        <React.Fragment>
            <div className='d-flex justify-content-between align-middle align-items-center mb-3'>
                <h5 className='m-0'>{title}</h5>
                {((role && role !== 'doctor') || (role && role === 'doctor' && action_type === 'accepted_consultation' && content_type === 'tele_medicine')) && editMode && <Button color='light' onClick={openModal}>Create New <i className="ri-add-line align-middle ri-lg ml-2"></i></Button>}
            </div>

            <ServerSidePaginations
                dataSource={dataTable}
                obj={null}
                loading={false}
                setLoading={() => { }}
                initialParams={initialValue}
            />
        </React.Fragment>
    )
}

export default Medication