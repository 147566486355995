import React, { useEffect, useState } from 'react'
import {
    Card, CardBody, Col, Row,
    Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Alert
} from 'reactstrap'
import { handleStatusStyle } from '../../../../../store/CustomFunctions'
import { TEXT_CANCEL, TEXT_PAYMENT, TEXT_PROVIDER } from '../../../../../Text'
import moment from 'moment'
import { ACTION_TYPE } from '../Store/ActionType'
import { get } from '../../../../../helpers/API'
import '../../../../Templete/Tables/Styles/customStyle.scss'

const containerBox = {
    position: "relative",
    display: "flex",
    alignItems: "center"
}

const HeaderNurse = ({ dataSource, changeMode, handleConfirmationBooking }) => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [assignedName, setAssignedName] = useState('')

    const [quotation, setQuotation] = useState([])

    useEffect(() => {
        let arr = []
        dataSource.formData.invoices.filter(x => {
            if (x.invoice_number.includes('SNSB')) {
                arr.push(x);
            }
            return x
        })
        setQuotation(arr.reverse());
    }, [dataSource])

    useEffect(() => {
        const selectedItem = dataSource.formData.provider_booking_jobs.find(x => x.type_of_job === 'assigned')
        if (selectedItem !== undefined && selectedItem !== null) {
            get(`/providers/${selectedItem.provider}`).then(providerRes => {
                setAssignedName(providerRes.fullname);
            })
        } else {
            setAssignedName('')
        }
    }, [dataSource.formData.provider_booking_jobs]);


    const paymentButton = () => {
        let disabledStatus = true;
        if (dataSource.formData.schedule_mode === "default") {
            if (!dataSource.formData.schedule_end_datetime && !dataSource.formData.schedule_start_datetime) {
                disabledStatus = false
            }
        } else {
            if (dataSource.formData.custom_schedule_rate && dataSource.formData.custom_schedule_days) {
                disabledStatus = false
            }
        }
        return dataSource.category === 'confinement' ?
            <Button
                color="primary"
                outline={disabledStatus}
                className="waves-effect"
                disabled={true}
                onClick={() => {
                    // changeMode(ACTION_TYPE.ACTION_PROCEED_PAYMENT, false)
                }}>{'Push E-mail'}<i className="ri-mail-send-fill align-middle ml-2" /></Button> :
                <Button
                    color="primary"
                    className="waves-effect"
                    outline={disabledStatus}
                    disabled={disabledStatus}
                    onClick={() => {
                        changeMode(ACTION_TYPE.ACTION_PROCEED_PAYMENT, false)
                    }}>{'Proceed to Payment'}<i className="ri-notification-3-fill align-middle ml-2" />
                </Button>
    }

                return (
                <Card style={{ backgroundColor: dataSource.headerColor }}>
                    <CardBody className="pt-sm-3 pb-sm-3 pt-md-3 pb-md-3 pt-lg-0 pb-lg-0">
                        <div className="container-box split-2">
                            <div className="left-content">
                                <Row>
                                    <Col md={3}>
                                        <div className="mb-3 mb-md-0 mt-md-0 mb-lg-3 mt-lg-3">
                                            <div>
                                                <h4 className="card-title text-light custom-header-title mb-0">{!dataSource.formData.patient_info ? "-" : dataSource.formData.patient_info.name}</h4>
                                                <div style={containerBox}>
                                                    <div className={`${handleStatusStyle(!dataSource.formData.booking_status ? 'pending' : dataSource.formData.booking_status).badgeClass} ml-2`}>{handleStatusStyle(!dataSource.formData.booking_status === 'validation_error' ? 'new' : dataSource.formData.booking_status).text}</div>
                                                </div>
                                            </div>
                                            <p className="m-0 text-light-muted">{!dataSource.formData.patient_info ? "-" : dataSource.formData.patient_info.phone}</p>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        {!dataSource.mode_disabled &&
                                            <div className="container-box split-2">
                                                <div className="left-content w-100">
                                                    <Row>
                                                        <Col md={3}>
                                                            <div className="mb-3 mb-md-0 mt-md-0 mb-lg-3 mt-lg-3">
                                                                <p className="text-light-muted mb-0" style={{ lineHeight: 1.4, fontSize: 13 }}>Submited on</p>
                                                                <div style={containerBox}>
                                                                    <p className="m-0 text-light" style={{ fontSize: 13 }}>{!dataSource.formData.createdAt ? "-" : moment(dataSource.formData.createdAt).format('DD MMM YYYY')}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3 mb-md-0 mt-md-0 mb-lg-3 mt-lg-3">
                                                                <p className="text-light-muted mb-0" style={{ lineHeight: 1.4, fontSize: 13 }}>{TEXT_PAYMENT}</p>
                                                                <div style={containerBox}>
                                                                    {!dataSource.formData.financial_status ? "-" :
                                                                        <div style={{ fontSize: 13 }} className={handleStatusStyle(dataSource.formData.financial_status).badgeClass}>{handleStatusStyle(dataSource.formData.financial_status).text}</div>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-md-0 mt-md-0 mb-lg-3 mt-lg-3">
                                                                <p className="text-light-muted mb-0" style={{ lineHeight: 1.4, fontSize: 13 }}>{TEXT_PROVIDER}</p>
                                                                <div style={containerBox}>
                                                                    {!assignedName ? "-" :
                                                                        <p className="m-0 text-light" style={{ fontSize: 13 }}>{assignedName}</p>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>}
                                    </Col>
                                </Row>
                            </div>

                            <div className="right-content">
                                {quotation.length !== 0 && dataSource.formData.financial_status === 'pending' && dataSource.formData.booking_mode !== 'site_visit' &&
                                    <Alert color="warning" className="font-size-11" style={{ margin: 'auto' }}><i className="mdi mdi-alert-outline mr-2 align-middle" />Edit function are locked until patient proceed with the payment</Alert>}

                                {(dataSource.mode === ACTION_TYPE.ACTION_SITE_VISIT && dataSource.mode_disabled) &&
                                    <Button
                                        color="link"
                                        className="waves-effect"
                                        style={{ color: "#fff", textDecoration: "none" }}
                                        onClick={() => {
                                            changeMode(ACTION_TYPE.ACTION_CANCEL_SITE_VISIT, false)
                                        }}>{TEXT_CANCEL}
                                    </Button>
                                }

                                {(dataSource.mode === ACTION_TYPE.ACTION_SET_SITE_VISIT && dataSource.mode_disabled) &&
                                    <Button
                                        color="primary"
                                        className="waves-effect"
                                        onClick={() => {
                                            changeMode(ACTION_TYPE.ACTION_COMPLETE_SITE_VISIT, false,)
                                        }}>Complete Site Visit<i className="ri-notification-3-fill align-middle ml-2" />
                                    </Button>
                                }


                                {(dataSource.mode === ACTION_TYPE.ACTION_SITE_VISIT && dataSource.mode_disabled) &&
                                    <Button
                                        color="primary"
                                        className="waves-effect"
                                        onClick={() => {
                                            changeMode(ACTION_TYPE.ACTION_SET_SITE_VISIT, true)
                                        }}>Set Site Visit<i className="ri-notification-3-fill align-middle ml-2" />
                                    </Button>
                                }
                                {(dataSource.formData.schedule_mode !== "custom") &&
                                    <>
                                        {!dataSource.mode_disabled && dataSource.formData.booking_mode === 'provider' && quotation.length === 0 &&
                                            paymentButton()
                                        }
                                    </>
                                }


                                {!dataSource.mode_disabled &&
                                    <Dropdown
                                        className={dataSource.formData.financial_status === 'completed' && dataSource.formData.booking_status === "completed" ? 'float-right ml-2' : 'float-right ml-2 text-white'}
                                        style={{ opacity: dataSource.mode_disabled && 0.5 }}
                                        disabled={dataSource.formData.financial_status === 'completed' && dataSource.formData.booking_status === "completed"}
                                        isOpen={openDropdown}
                                        toggle={() => setOpenDropdown(!openDropdown)}>
                                        <DropdownToggle tag="i" className="darrow-none card-drop" aria-expanded="false">
                                            <i className="mdi align-middle mdi-dots-vertical"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {/* {((dataSource.formData.financial_status === 'completed') || (dataSource.formData.invoices.length !== 0)) && */}
                                            <DropdownItem
                                                href=""
                                                // disabled={(dataSource.formData.financial_status === 'completed') || (dataSource.formData.invoices.length === 0)}
                                                onClick={() => handleConfirmationBooking('complete_payment')}
                                            >
                                                Complete payment
                                            </DropdownItem>
                                            {/* } */}
                                            <DropdownItem href="" onClick={() => changeMode(ACTION_TYPE.ACTION_SITE_VISIT, true)}>Site visit</DropdownItem>
                                            {/* {((dataSource.formData.financial_status === 'completed') || (dataSource.formData.invoices.length !== 0)) && */}
                                            <DropdownItem
                                                href=""
                                                // disabled={(dataSource.formData.financial_status === 'completed') || (dataSource.formData.invoices.length === 0)}
                                                onClick={() => handleConfirmationBooking('complete_booking')}
                                            >
                                                Complete job
                                            </DropdownItem>
                                            {/* } */}
                                            <DropdownItem href="" onClick={() => handleConfirmationBooking('remove_booking')}>Cancel job</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
                )
}

                export default HeaderNurse
