import React, { useEffect, useMemo, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Button } from 'reactstrap'

const ModalExtandVideoCall = ({ visible, title, timer, onConfirm, onCancel, onEndCall }) => {
    const [myTimer, setMyTimer] = useState(60)

    const memorizedVisible = useMemo(() => { return visible }, [visible])

    const format = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
        const seconds = timeInSeconds % 60;

        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        let interval = null
        if (memorizedVisible) {
            interval = setInterval(() => {
                setMyTimer(prev => prev - 1)
            }, 1000)
        } else {
            clearInterval(interval)
            setMyTimer(60)
        }

        return () => clearInterval(interval)
    }, [memorizedVisible])


    useEffect(() => {
        let interval = null
        if (memorizedVisible && timer >= 30) {
            interval = setInterval(() => {
                onEndCall()
            }, 60000)
        } else {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [memorizedVisible, onEndCall, timer])
    return (visible && timer >= 30 ?
        <SweetAlert
            style={{ overflow: "unset" }}
            title={title}
            warning
            showCancel={false}
            showConfirm={false}
            onConfirm={() => { }}
        >
            {timer >= 30 ? <p className='small text-muted'>Kindly note that the call will be terminated in {format(myTimer)} minute if there is no response. To continue the call, please extend it.</p> : null}
            <div className='mt-4'>
                {timer >= 30 ? null : <Button color='light' className='mr-2' onClick={onCancel}>Close</Button>}
                {timer >= 30 ? <Button color='danger' className='mr-2' onClick={onEndCall}>End Call</Button> : null}
                {timer >= 30 ? <Button color='primary' onClick={onConfirm}>Extend</Button> : null}
            </div>
        </SweetAlert>
        : null)
}

export default ModalExtandVideoCall