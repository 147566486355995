import React from 'react'
import NurseProvider from '../Store/NurseContext'
import NurseDetailsContent from './NurseDetailsContent'

const NurseDetails = (props) => {
    return (
        <React.Fragment>
            <NurseProvider>
                <NurseDetailsContent props={props} />
            </NurseProvider>
        </React.Fragment>
    )
}

export default NurseDetails
