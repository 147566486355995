import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { Badge, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row, Spinner, Table } from 'reactstrap'
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { get, getAPIroot, update } from '../../../helpers/API';
import { capitalizeFirstLetter, converPriceWithCommas, handleStatusStyle } from '../../../store/CustomFunctions';
import imgEmpty from '../../../assets/images/imgEmpty.png'
import Select from "react-select";
// DatePicker
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// Timepicker
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment-timezone';
import { OptionCourier } from './store/Option';
import AlertSuccess from '../../CustomComponent/Alert/AlertSuccess'
import AlertError from '../../CustomComponent/Alert/AlertError'
import Switch from "react-switch";
import AlertConfirmation from "../../CustomComponent/Alert/AlertConfirmation"

const styles = {
    columns: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
    },
    pragraph: {
        overflow: "hidden",
        textOverflow: 'ellipsis',
        whiteSpace: "pre-line",
        wordBreak: "break-all",
    },
}

export const ErrorMsg = ({ visible, msg }) => {
    return visible && <p style={{ color: "red" }}>{msg}</p>
}

export const OrderHeader = ({
    temporary,
    loading,
    handleVisibleModalImg
}) => {

    const renderImage = () => {
        let typeFile;
        if (temporary.prescription_file && temporary.prescription_file.url) {
            let file = temporary.prescription_file.url.split('.')
            typeFile = file[1];
        }

        if (temporary.prescription_file !== null && temporary.prescription_file !== undefined) {
            if (typeFile === 'pdf') {
                return <Button className="align-middle" color="link" onClick={() => {
                    window.open(getAPIroot() + temporary.prescription_file.url)
                }}><i className="ri-file-pdf-line" /> File</Button>
            } else {
                return <div style={{ cursor: "pointer" }}>
                    <CardImg
                        className="img-fluid"
                        src={getAPIroot() + temporary.prescription_file.url} alt="img"
                    />
                    <div className='viewBtn'><Button color="light" style={{ zIndex: 200 }} onClick={(e) => handleVisibleModalImg(e)}>View</Button></div>
                </div>
            }
        } else {
            return <CardImg className="img-fluid" src={imgEmpty} alt="img" />
        }
    }
    return (
        <Card>
            <CardBody>
                <div className="d-flex align-items-center mb-3"><i className="ri-user-line mr-2 align-middle" /><h5 className="font-size-15 mb-0">Customer Info</h5></div>

                {loading ?
                    <div>
                        <Skeleton className="mb-4" width={'50%'} />
                        <Row>
                            <Col md={4}>
                                <Skeleton width={'60%'} />
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                            </Col>
                            <Col md={4}>
                                <Skeleton width={'60%'} />
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                                <Skeleton width={'100%'} />
                            </Col>
                            <Col md={4}>
                                <Skeleton height={'100%'} />
                            </Col>
                        </Row>
                    </div> :
                    <div className="border-top pt-4">
                        <CardTitle>{!temporary.delivery_address ? "-" : capitalizeFirstLetter(temporary.delivery_address.firstname)} {!temporary.delivery_address ? "-" : capitalizeFirstLetter(temporary.delivery_address.lastname)}</CardTitle>
                        <div style={styles.columns}>
                            <div style={{ width: "30%" }}>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-3 col-form-label pr-1">Email</Label>
                                    <Col md={8} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.email ? "-" : temporary.email}</CardText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-3 col-form-label pr-1">Phone</Label>
                                    <Col md={8} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.delivery_address ? "-" : temporary.delivery_address.phone}</CardText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-3 col-form-label pr-1">NRIC</Label>
                                    <Col md={8} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.customer_nric ? "-" : temporary.customer_nric}</CardText>
                                    </Col>
                                </FormGroup>
                            </div>
                            <div style={{ width: "40%" }}>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-4 col-form-label pr-1">Address 1</Label>
                                    <Col md={7} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.delivery_address ? "-" : temporary.delivery_address.address1}</CardText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-4 col-form-label pr-1">Address 2</Label>
                                    <Col md={7} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.delivery_address ? "-" : temporary.delivery_address.address2}</CardText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-4 col-form-label pr-1">Postcode</Label>
                                    <Col md={7} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.delivery_address ? "-" : temporary.delivery_address.zipcode}</CardText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-4 col-form-label pr-1">City</Label>
                                    <Col md={7} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.delivery_address ? "-" : capitalizeFirstLetter(temporary.delivery_address.city)}</CardText>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-4 col-form-label pr-1">Country</Label>
                                    <Col md={7} className="pl-1">
                                        <CardText className="mb-0 mt-2" style={styles.pragraph}>: {!temporary.delivery_address ? "-" : capitalizeFirstLetter(temporary.delivery_address.country)}</CardText>
                                    </Col>
                                </FormGroup>
                            </div>
                            <div style={{ width: "30%" }}>
                                <FormGroup row className="mb-0">
                                    <Label className="col-md-5 col-form-label pr-1">Prescription :</Label>
                                    <Col md={7} className="pl-1">
                                        <div className='viewContainer'>
                                            {!temporary.has_prescription ?
                                                <CardText className="mb-0 mt-2">No prescription</CardText> :
                                                <div>
                                                    {renderImage()}
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                        </div>
                    </div>}
            </CardBody>
        </Card>
    )
}

export const OrderBody = ({
    temporary,
    loading
}) => {
    const displayImage = (item, temporary) => {
        let title = '';
        let showPrescription = '';
        if (item.title !== undefined || item.title !== null) {
            title = item.title
        }

        if (temporary.has_prescription !== undefined || temporary.has_prescription !== null) {
            showPrescription = <Badge color="danger"><i className="ri-information-line mr-2 align-middle" />Prescription</Badge>
        }

        if (item.img_url === undefined || item.img_url === null) {
            return <div style={styles.columns}><CardImg className="img-fluid avatar-sm" src={imgEmpty} alt="Skote" /> <div style={{ width: "calc(100% - 80px)" }}><CardText className="mb-0">{title}</CardText>{showPrescription}</div></div>
        } else if (item.img_url.includes('.pdf')) {
            return <div style={styles.columns}><CardImg className="img-fluid avatar-sm" src={imgEmpty} alt="Skote" /><div style={{ width: "calc(100% - 80px)" }}><CardText className="mb-0">{title}</CardText>{showPrescription}</div></div>
        } else {
            return <div style={styles.columns}><CardImg className="img-fluid avatar-sm" src={item.img_url && getAPIroot() + item.img_url} alt="Skote" /><div style={{ width: "calc(100% - 80px)" }}><CardText className="mb-0">{title}</CardText>{showPrescription}</div></div>
        }
    }
    return (
        <Card>
            <CardBody>
                <div className="d-flex flex-row justify-content-between mb-3">
                    <div className="d-flex flex-row align-items-center pr-3">
                        <i className="ri-file-list-line mr-2 align-middle" />
                        <h5 className="font-size-15 mb-0">Order List</h5>
                        <div>{loading ? <Skeleton className="ml-3" /> :
                            <Badge className={`${handleStatusStyle(temporary.financial_status).badgeClass} ml-3`}>{handleStatusStyle(temporary.financial_status).text}</Badge>}</div>
                    </div>
                    <div>
                        {loading ? <Skeleton width={'200px'} className="ml-3" /> :
                            <div className="d-flex" style={{ textAlign: "right" }}>
                                <CardText className="mr-3 pl-3 m-0" style={{ borderLeft: "1.8px solid #eff2f7" }}>Date: {!temporary.createdAt ? '-' : moment(temporary.createdAt).format("DD MMM YYYY")}</CardText>
                                <CardText className="mr-3 pl-3 m-0" style={{ borderLeft: "1.8px solid #eff2f7" }}>Total Tax: <b>{!temporary.total_price ? "-" : `RM ${converPriceWithCommas(temporary.total_tax)}`}</b></CardText>
                                <CardText className="m-0 pl-3" style={{ borderLeft: "1.8px solid #eff2f7" }}>Total Spending: <b>{!temporary.total_price ? "-" : `RM ${converPriceWithCommas(temporary.total_price)}`}</b></CardText>
                            </div>
                        }
                    </div>
                </div>

                <div className="border-top">
                    <Table>
                        <tbody>
                            <tr>
                                <th>Item</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total Price</th>
                            </tr>

                            {loading ?
                                <>
                                    <tr className="border-top">
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                    </tr>
                                    <tr className="border-top">
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                    </tr>
                                    <tr className="border-top">
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                        <td><Skeleton width={'100%'} /></td>
                                    </tr>
                                </> :
                                temporary.line_item && temporary.line_item.length > 0 && temporary.line_item.map((item, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                {displayImage(item, temporary)}
                                            </td>
                                            <td>{!item.price ? 'RM 0' : converPriceWithCommas(item.price)}</td>
                                            <td>{!item.quantity ? 'RM 0' : item.quantity}</td>
                                            <td>{!item.price ? 'RM 0' : 'RM' + converPriceWithCommas(item.price * item.quantity)}</td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    )
}

export const OrderFulfillment = ({
    temporary,
    loading,
    handleVisibleModal,
    setForm,
    form,
    validate,
    setValidate,
    handleCompleteOrder,
    handleSubmitFlag,
    setFlagDesc,
    flagDesc,
    setRefundDesc,
    refundDesc,
    handleSubmitRefund,
    handleSwithingFlag,
    handleSwithingRefund
}) => {
    const [visibility, setVisibility] = useState(false)

    const handleSetForm = (properties, value) => {
        setForm(prev => {
            return {
                ...prev,
                [properties]: value
            }
        })
    }

    const handleDate = (day, modifiers = {}) => {
        handleSetForm('processed_at_date', day)
        if (day !== undefined) {
            setValidate(prev => {
                return {
                    ...prev,
                    processed_at_date: false
                }
            })
        } else {
            setValidate(prev => {
                return {
                    ...prev,
                    processed_at_date: true
                }
            })
        }
    }

    const handleReset = () => {
        setForm({
            courier_type: '',
            tracking_number: '',
            processed_at_date: '',
            processed_at_time: '',
            note: ''
        })
        setValidate({
            courier_type: false,
            tracking_number: false,
            processed_at_date: false,
            processed_at_time: false,
        })
    }

    const CustomOverlay = ({ classNames, selectedDay, children, ...props }) => {
        return (
            <div
                className={classNames.overlayWrapper}
                style={{ marginLeft: -180 }}
                {...props}
            >
                <div className={classNames.overlay}>
                    {children}
                </div>
            </div>
        );
    }

    const Offsymbol = (text) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                {text}
            </div>
        );
    };

    const OnSymbol = (text) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                    paddingLeft: 2
                }}
            >
                {" "}
                {text}
            </div>
        );
    };


    const deliverytype = useMemo(() => {
        if (temporary.delivery_type !== undefined && temporary.delivery_type !== null) {
            if (temporary.fulfillment_status === "unfulfilled") {
                return true
            } else {
                return temporary.delivery_type.type === 'pickup' ? false : temporary.delivery_type.type === 'ship' ? false : temporary.delivery_type.type === 'cod' ? false : true
            }
        } else return false
    }, [temporary.delivery_type, temporary.fulfillment_status])

    useEffect(() => {
        setVisibility(deliverytype)
    }, [deliverytype])

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row">
                            <i className="ri-flag-line mr-2 align-middle" />
                            <h5 className="mb-0 font-size-15">Flag</h5>
                        </div>
                        <div>
                            <Switch
                                uncheckedIcon={Offsymbol("No")}
                                checkedIcon={OnSymbol("Yes")}
                                onColor="#02a499"
                                offColor="#ec4561"
                                onChange={checked => handleSwithingFlag(checked)}
                                checked={flagDesc.is_flag}
                                className="mr-1 mt-1"
                            />
                            <Button className="ml-2" color="primary" onClick={handleSubmitFlag}>Save</Button>
                        </div>
                    </div>
                    <div style={{ height: flagDesc.is_flag ? '105px' : '0px', overflow: "hidden", transition: 'ease 500ms' }} >
                        <textarea
                            onChange={e => setFlagDesc({ ...flagDesc, flag_comment: e.target.value })}
                            className="form-control mt-4"
                            type="text"
                            rows={3}
                            value={flagDesc.flag_comment}
                            placeholder="note..."
                        />
                        {/* <div className="float-right">
                            <Button className="mt-3" color="primary" onClick={handleSubmitFlag}>Save</Button>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>

                    <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                        <div className="d-flex flex-row">
                            <i className="ri-truck-line mr-2 align-middle" />
                            <h5 className="mb-0 font-size-15">Fulfillment</h5>
                        </div>
                        <div>
                            {visibility &&
                                loading ? <Skeleton width={'20%'} /> :
                                <Badge className={`${handleStatusStyle(temporary.fulfillment_status === 'pending' ? 'in_delivery' : temporary.fulfillment_status).badgeClass}`}>{temporary.fulfillment_status === 'pending' ? 'In Delivery' : handleStatusStyle(temporary.fulfillment_status).text}</Badge>
                            }
                        </div>
                    </div>

                    <div className="border-top pt-4">
                        <FormGroup row className="mb-3">
                            <Label className="col-md-4 col-form-label pr-1">Delivery Type</Label>

                            {loading ?
                                <Col md={8} className="pl-1">
                                    <Skeleton width={'100%'} />
                                    <Skeleton width={'100%'} />
                                    <Skeleton width={'70%'} />
                                </Col> :
                                <Col md={8} className="pl-1">
                                    <CardText className="mb-0 mt-2 text-muted">{temporary.delivery_type !== null && temporary.delivery_type !== undefined ? !temporary.delivery_type.name ? '-' : temporary.delivery_type.name : '-'}</CardText>
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "inline-flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 8
                                        }}
                                    >
                                        <Badge className="badge badge-soft-dark font-size-15">{temporary.delivery_type !== null && temporary.delivery_type !== undefined ? !temporary.delivery_type.type ? '-' : capitalizeFirstLetter(temporary.delivery_type.type) : '-'}</Badge>
                                        <CardText className="mb-0 ml-3 text-muted">{temporary.delivery_type !== null && temporary.delivery_type !== undefined ? `RM ${converPriceWithCommas(temporary.delivery_type.fee_charge)}` : '-'}</CardText>
                                    </div>
                                </Col>
                            }
                        </FormGroup>

                        {!visibility &&
                            <FormGroup row className="mb-3">
                                <Label className="col-md-4 col-form-label pr-1"><i className="ri-map-pin-4-line mr-2" />Pickup</Label>
                                {loading ?
                                    <Col md={8} className="pl-1">
                                        <div className="mb-0 mt-2">
                                            <Skeleton width={'70%'} />
                                        </div>
                                    </Col> :
                                    <Col md={8} className="pl-1">
                                        <CardText className="mb-0 mt-2 text-muted">{!temporary.delivery_address ? "-" : !temporary.delivery_address.location_name ? "-" : temporary.delivery_address.location_name}</CardText>
                                    </Col>}
                            </FormGroup>}

                        {visibility &&
                            <FormGroup row className="mb-3">
                                <Label className="col-md-4 col-form-label pr-1">Courier</Label>
                                <Col md={8} className="pl-1">
                                    {temporary.fulfillment_status !== 'fulfilled' ?
                                        <div className={validate.courier_type ? "select-red-select" : ""}>
                                            <Select
                                                options={OptionCourier}
                                                value={!form.courier_type ? null : form.courier_type}
                                                onChange={val => handleSetForm('courier_type', val)}
                                                onBlur={() => {
                                                    if (form.courier_type) {
                                                        setValidate(prev => {
                                                            return {
                                                                ...prev,
                                                                courier_type: false
                                                            }
                                                        })
                                                    }
                                                }}
                                            />
                                            <ErrorMsg visible={validate.courier_type} msg='Courier Type is required' />
                                        </div> : <div><CardText className="mb-0 mt-2 text-muted">{!form.courier_type ? '-' : form.courier_type}</CardText></div>}
                                </Col>
                            </FormGroup>}

                        {visibility &&
                            <FormGroup row className="mb-3">
                                <Label className="col-md-4 col-form-label pr-1">Tracking Number</Label>
                                <Col md={8} className="pl-1">
                                    {temporary.fulfillment_status !== 'fulfilled' ?
                                        <div className={validate.tracking_number ? "select-red" : ''}>
                                            <Input
                                                placeholder="e.g: 81929128301212"
                                                value={!form.tracking_number ? "" : form.tracking_number}
                                                onChange={event => {
                                                    handleSetForm('tracking_number', event.target.value)
                                                    if (event.target.value === "") {
                                                        setValidate(prev => {
                                                            return {
                                                                ...prev,
                                                                tracking_number: true
                                                            }
                                                        })
                                                    }
                                                }}
                                                onBlur={() => {
                                                    if (form.tracking_number) {
                                                        setValidate(prev => {
                                                            return {
                                                                ...prev,
                                                                tracking_number: false
                                                            }
                                                        })
                                                    }
                                                }}
                                            />
                                            <ErrorMsg visible={validate.tracking_number} msg='Tracking Number type is required' />
                                        </div> : <div><CardText className="mb-0 mt-2 text-muted">{!form.tracking_number ? "-" : form.tracking_number}</CardText></div>}
                                </Col>
                            </FormGroup>}

                        {visibility &&
                            <FormGroup row className="mb-3">
                                <Label className="col-md-4 col-form-label pr-1">Processed on</Label>
                                <Col md={4} className="pl-1">
                                    {temporary.fulfillment_status !== 'fulfilled' ?
                                        <div className={validate.processed_at_date ? "select-red" : ""}>
                                            <DayPickerInput
                                                overlayComponent={CustomOverlay}
                                                value={!form.processed_at_date ? '' : moment(form.processed_at_date).format('DD-MM-YYYY')}
                                                onDayChange={handleDate}
                                                placeholder={`e.g: ${moment().format('DD-MM-YYYY')}`}
                                            />
                                            <ErrorMsg visible={validate.processed_at_date} msg='Date is required' />
                                        </div> : <div><CardText className="mb-0 mt-2 text-muted">{!form.processed_at_date ? "-" : moment(form.processed_at_date).format('DD MMM YYYY')}</CardText></div>}
                                </Col>
                                <Col md={4} className="pl-1">
                                    {temporary.fulfillment_status !== 'fulfilled' ?
                                        <div>
                                            <TimePicker
                                                className={validate.processed_at_time ? 'custom-timepicker select-red' : 'custom-timepicker'}
                                                showSecond={false}
                                                use12Hours={true}
                                                value={!form.processed_at_time ? null : form.processed_at_time}
                                                onChange={value => {
                                                    handleSetForm('processed_at_time', value)
                                                    if (value !== null) {
                                                        setValidate(prev => {
                                                            return {
                                                                ...prev,
                                                                processed_at_time: false
                                                            }
                                                        })
                                                    } else {
                                                        setValidate(prev => {
                                                            return {
                                                                ...prev,
                                                                processed_at_time: true
                                                            }
                                                        })
                                                    }
                                                }}
                                                placeholder={`e.g: ${moment().format('HH:mm')}`}
                                            />
                                            <ErrorMsg visible={validate.processed_at_time} msg='Time is required' />
                                        </div> : <div><CardText className="mb-0 mt-2 text-muted">{!form.processed_at_time ? "-" : moment(form.processed_at_time).format('H:mm A')}</CardText></div>}
                                </Col>
                            </FormGroup>}

                        {visibility &&
                            <FormGroup row className="mb-3">
                                <Label className="col-md-4 col-form-label pr-1">Note</Label>
                                <Col md={8} className="pl-1">
                                    {temporary.fulfillment_status !== 'fulfilled' ?
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            value={!form.note ? "" : form.note}
                                            onChange={event => handleSetForm('note', event.target.value)}
                                            rows={3}
                                        />
                                        : <div><CardText className="mb-0 mt-2 text-muted">{!form.note ? "-" : form.note}</CardText></div>}
                                </Col>
                            </FormGroup>}

                        {visibility &&
                            temporary.fulfillment_status === 'unfulfilled' && <div className="float-right">
                                <Button color="light" disabled={loading} className="mr-2" onClick={handleReset}>Reset</Button>
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    onClick={handleVisibleModal}>
                                    {loading && <Spinner className="mr-4" size="sm" color="light" />}Submit<i className="ml-2 ri-check-line align-middle" /></Button>
                            </div>}
                    </div>

                    {visibility &&
                        temporary.fulfillment_status === 'pending' && <div className="border-top pt-4 align-middle" style={styles.columns}>
                            <CardText>Order delivered ?</CardText>
                            <Button color="success" disabled={loading} onClick={handleCompleteOrder}>Complete</Button>
                        </div>}
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row">
                            <i className="ri-refund-line mr-2 align-middle" />
                            <h5 className="mb-0 font-size-15">Refund</h5>
                        </div>
                        <div>
                            <Switch
                                uncheckedIcon={Offsymbol("No")}
                                checkedIcon={OnSymbol("Yes")}
                                onColor="#02a499"
                                offColor="#ec4561"
                                onChange={(checked) => handleSwithingRefund(checked)}
                                checked={refundDesc.is_refunded}
                                className="mr-1 mt-1"
                            />
                            <Button className="ml-2" color="primary" onClick={handleSubmitRefund}>Save</Button>
                        </div>
                    </div>
                    <div style={{ height: refundDesc.is_refunded ? '105px' : '0px', overflow: "hidden", transition: 'ease 500ms' }} >
                        <textarea
                            onChange={e => setRefundDesc({ ...refundDesc, refund_comment: e.target.value })}
                            className="form-control mt-4"
                            type="text"
                            rows={3}
                            placeholder="note..."
                            value={refundDesc.refund_comment}
                        />
                        {/* <div className="float-right">
                            <Button className="mt-3" color="primary" onClick={handleSubmitRefund}>Submit</Button>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </div >
    )
}

export const OrderDetails = (props) => {
    const [breadcrumbItems] = useState([
        { title: "All order", link: { pathname: '/store/orders', state: { title: 'Orders', filter: 'order' } } },
        { title: 'Order Detail', link: "#" },
    ])
    const [flagDesc, setFlagDesc] = useState({
        is_flag: false,
        is_comment: ''
    })
    const [refundDesc, setRefundDesc] = useState({
        is_refunded: false,
        refund_comment: ''
    })
    const [temporary, setTemporary] = useState([])
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        courier_type: '',
        tracking_number: '',
        processed_at_date: null,
        processed_at_time: null,
        note: ''
    })
    const [validate, setValidate] = useState({
        courier_type: false,
        tracking_number: false,
        processed_at_date: false,
        processed_at_time: false,
    })
    const [sweetTimmer, setSweetTimmer] = useState(false)
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ''
    })
    const [visibleModal, setVisibelModal] = useState(false)
    const [visibleModalImg, setVisibleModalImg] = useState(false)
    const [promptItem, setPrompItem] = useState({
        title: "",
        desc: "",
        action: ""
    })
    const [alertConfirm, setAlertConfirm] = useState(false)

    const orderId = useMemo(() => {
        if (props.match.params.id) return props.match.params.id
    }, [props.match.params])

    useEffect(() => {
        let mount = false

        if (!mount) {
            setLoading(true)
            setTimeout(() => {
                get(`/orders/${orderId}`).then(response => {
                    setLoading(false)
                    setTemporary(response)
                    setFlagDesc({
                        is_flag: response.is_flag,
                        flag_comment: response.flag_comment
                    })
                    setRefundDesc({
                        is_refunded: response.is_refunded,
                        refund_comment: response.refund_comment
                    })
                    setForm({
                        courier_type: response.courier_type === undefined && response.courier_type === null ? "" : OptionCourier.find(x => x.value === response.courier_type),
                        tracking_number: response.tracking_number === undefined && response.tracking_number === null ? "" : response.tracking_number,
                        processed_at_date: response.processed_at === undefined || response.processed_at === null ? null : moment(response.processed_at),
                        processed_at_time: response.processed_at === undefined || response.processed_at === null ? null : moment(response.processed_at),
                        note: response.note === undefined && response.note === null ? '' : response.note
                    })
                }).catch(() => {
                    setLoading(false)
                })
            }, 500);
        }
        return () => {
            mount = true
        }
    }, [orderId])

    const handleCompleteOrder = () => {
        let data = {
            fulfillment_status: 'fulfilled',
        }

        update(`/orders/${temporary.id}`, data).then(response => {
            setSweetTimmer(!sweetTimmer)
            setVisibelModal(!visibleModal)
            setTemporary(response)
            setForm({
                courier_type: response.courier_type === undefined || response.courier_type === null ? "" : response.courier_type,
                tracking_number: response.tracking_number === undefined || response.tracking_number === null ? "" : response.tracking_number,
                processed_at_date: response.processed_at === undefined || response.processed_at === null ? null : moment(response.processed_at),
                processed_at_time: response.processed_at === undefined || response.processed_at === null ? null : moment(response.processed_at),
                note: response.note === undefined || response.note === null ? '' : response.note
            })
        }).catch(err => {
            if (err) {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
                setLoading(false)
            }
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let dateTime;
        if (form.processed_at_date && form.processed_at_time) {
            let date = moment(form.processed_at_date).format('YYYY-MM-DD');
            let time = moment(form.processed_at_time).format('HH:mm')
            let momentObj = moment(date + time, 'YYYY-MM-DDLT');
            dateTime = moment(momentObj._d)
        }

        let data = {
            fulfillment_status: 'pending',
            tracking_number: form.tracking_number,
            note: form.note
        }

        if (form.courier_type.value !== undefined && form.courier_type.value !== null) {
            const obj = {
                ...data,
                courier_type: form.courier_type.value,
            }
            data = obj
        }

        if (dateTime !== undefined && dateTime !== null) {
            const obj = {
                ...data,
                processed_at: dateTime,
            }

            data = obj
        }

        update(`/orders/${temporary.id}`, data).then(response => {
            // console.log(response);
            setSweetTimmer(!sweetTimmer)
            setVisibelModal(!visibleModal)
            setTemporary(response)
            setForm({
                courier_type: response.courier_type === undefined || response.courier_type === null ? "" : response.courier_type,
                tracking_number: response.tracking_number === undefined || response.tracking_number === null ? "" : response.tracking_number,
                processed_at_date: response.processed_at === undefined || response.processed_at === null ? '' : moment(response.processed_at),
                processed_at_time: response.processed_at === undefined || response.processed_at === null ? '' : moment(response.processed_at),
                note: response.note === undefined || response.note === null ? '' : response.note
            })
        }).catch(err => {
            if (err) {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
                setLoading(false)
            }
        })
    }

    const handleVisibleModal = () => {
        if (form.courier_type && form.processed_at_date && form.processed_at_time && form.tracking_number) {
            setVisibelModal(!visibleModal)
        } else {
            setValidate({
                courier_type: form.courier_type ? false : true,
                tracking_number: form.tracking_number ? false : true,
                processed_at_date: form.processed_at_date ? false : true,
                processed_at_time: form.processed_at_time ? false : true,
            })
        }
    }

    const handleVisibleModalImg = (e) => {
        e.preventDefault()
        setVisibleModalImg(!visibleModalImg)
    }

    const handleSwithingRefund = (checked) => {
        if (checked) {
            setRefundDesc(prev => { return { ...prev, is_refunded: checked } })
        } else {
            setRefundDesc(prev => { return { ...prev, is_refunded: checked } })
            setPrompItem(prev => {
                return {
                    ...prev,
                    title: `Are you sure?`,
                    action: "remove_refund"
                }
            })
        }
    }

    const handleSwithingFlag = (checked) => {
        if (checked) {
            setFlagDesc(prev => { return { ...prev, is_flag: checked } })
        } else {
            setFlagDesc(prev => { return { ...prev, is_flag: checked } })
            setPrompItem(prev => {
                return {
                    ...prev,
                    title: `Are you sure?`,
                    action: "remove_flag"
                }
            })
        }
    }

    const handleSubmitFlag = async () => {

        let payload = {
            is_flag: flagDesc.is_flag,
            flag_comment: flagDesc.is_flag ? flagDesc.flag_comment : ""
        }
        await update(`/orders/${temporary.id}`, payload).then(response => {
            setSweetTimmer(!sweetTimmer)
            setFlagDesc({
                is_flag: response.is_flag,
                flag_comment: response.flag_comment
            })
        }).catch(err => {
            if (err) {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
                setLoading(false)
            }
        })
    }

    const handleSubmitRefund = async () => {

        let payload = {
            is_refunded: refundDesc.is_refunded,
            refund_comment: refundDesc.is_refunded ? refundDesc.refund_comment : ""
        }

        await update(`/orders/${temporary.id}`, payload).then(response => {
            setSweetTimmer(!sweetTimmer)
            setRefundDesc({
                is_refunded: response.is_refunded,
                refund_comment: response.refund_comment
            })
        }).catch(err => {
            if (err) {
                setErrMsg({
                    visible: !errMsg.visible,
                    msg: err
                })
                setLoading(false)
            }
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Orders'} breadcrumbItems={breadcrumbItems} />


                    <Row>
                        <Col md={8}>
                            <OrderHeader
                                temporary={temporary}
                                loading={loading}
                                handleVisibleModalImg={handleVisibleModalImg}
                            />
                            <OrderBody
                                temporary={temporary}
                                loading={loading}
                            />
                        </Col>
                        <Col md={4}>
                            <OrderFulfillment
                                temporary={temporary}
                                loading={loading}
                                setForm={setForm}
                                form={form}
                                validate={validate}
                                setValidate={setValidate}
                                handleVisibleModal={handleVisibleModal}
                                handleCompleteOrder={handleCompleteOrder}
                                handleSubmitFlag={handleSubmitFlag}
                                setFlagDesc={setFlagDesc}
                                flagDesc={flagDesc}
                                setRefundDesc={setRefundDesc}
                                refundDesc={refundDesc}
                                handleSubmitRefund={handleSubmitRefund}
                                handleSwithingFlag={handleSwithingFlag}
                                handleSwithingRefund={handleSwithingRefund}
                            />
                        </Col>
                    </Row>

                    <Modal
                        isOpen={visibleModal}
                        // toggle={handleVisibleModal}
                        centered={true}
                    >
                        <ModalBody>
                            <div className="text-center">
                                <h4 className="mb-4 text-center">You’ve change the status to Shipping</h4>
                                <Button color="primary" onClick={handleSubmit}>Okay</Button>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal
                        isOpen={visibleModalImg}
                        toggle={handleVisibleModalImg}
                    >
                        <ModalBody className="p-0">
                            <div style={{ ...styles.columns, alignItems: "center" }}>
                                <CardTitle className="pt-1 pl-3">{!temporary.prescription_file ? "" : temporary.prescription_file.name}</CardTitle>
                                <Button color="link" style={{ textDecoration: "none" }} onClick={handleVisibleModalImg}>Close</Button>
                            </div>

                            <Card className="m-0">
                                <CardImg className="p-0" src={temporary.prescription_file && getAPIroot() + temporary.prescription_file.url} alt="img" />
                            </Card>
                        </ModalBody>
                    </Modal>

                    <AlertSuccess
                        title='Success!'
                        setSweetTimmer={setSweetTimmer}
                        sweetTimmer={sweetTimmer}
                    />
                    <AlertConfirmation
                        title={promptItem.title}
                        desc={promptItem.desc}
                        action={promptItem.action}
                        setAlertConfirm={setAlertConfirm}
                        alertConfirm={alertConfirm}
                        handleSubmitFlag={handleSubmitFlag}
                        handleSubmitRefund={handleSubmitRefund}
                    />
                    <AlertError
                        title='Error!'
                        setErrMsg={setErrMsg}
                        errMsg={errMsg}
                    />

                </Container>
            </div>
        </React.Fragment>
    )
}
