import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { Button, CardFooter, CardHeader, CardImg, Input, InputGroup, InputGroupAddon, Label, Spinner, Table, UncontrolledTooltip } from 'reactstrap'
import imgEmpty from '../../../assets/images/no_img.png'
// import imgAvatar from '../../../assets/images/male_avatar.png'
// import imgNoData from '../../../assets/images/no_data.png'
import { getAPIroot, get, post, update } from '../../../helpers/API'
import { converPriceWithCommas, getTemplateInvoiceOrder, handleStatusStyle } from '../../../store/CustomFunctions'
import CustomProductModal from './CustomProductModal'
import StoreProductModal from './StoreProductModal'
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext'
import Select from "react-select"
import moment from 'moment'
import AlertSuccess from '../../CustomComponent/Alert/AlertSuccess'
import AlertError from '../../CustomComponent/Alert/AlertError'
import qs from 'qs'
import { pdfInvoice, pdfPoisonForm } from '../../../helpers/pdfGenerator/pdfGenerator'

const uomArrays = [
    { label: "Ampoule", value: "Ampoule" },
    { label: "Bar", value: "Bar" },
    { label: "Bottle", value: "Bottle" },
    { label: "Box", value: "Box" },
    { label: "Gallon", value: "Gallon" },
    { label: "Jar", value: "Jar" },
    { label: "Pack", value: "Pack" },
    { label: "Tab", value: "Tab" },
    { label: "Tube", value: "Tube" },
    { label: "Vial", value: "Vial" },
]

export const showButtonActions = (button, role, order_status, clinic_status, hq_status, pharmacy_status, org_name) => {
    switch (button) {
        case 'table_action_q':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status === 'pending') {
                    if (clinic_status === 'draft') {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                if (order_status === 'pending') {
                    if (!hq_status) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_pharmacist') return false
            else if (role === 'corporate_admin_n_pharmacist') return false
            break;
        case 'table_action_qs':
            if (role === 'corporate_pharmacist') {
                if (order_status === 'pending') {
                    if (hq_status === 'approved') {
                        return true
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_admin_n_pharmacist') {
                if (order_status === 'pending') {
                    if (hq_status === 'approved') {
                        return true
                    }
                } else {
                    return false
                }
            }
            break;
        case 'table_action':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status === 'pending') {
                    if (clinic_status === 'draft') {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                if (order_status === 'pending') {
                    if (!hq_status) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_pharmacist') {
                if (order_status === 'pending') {
                    if (hq_status === 'approved') {
                        return true
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_admin_n_pharmacist') {
                if (order_status === 'pending') {
                    if (hq_status === 'approved') {
                        return true
                    }
                } else {
                    return false
                }
            }
            break;
        case 'submit':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status) {
                    if (order_status === 'pending') {
                        if (clinic_status === 'draft') {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                return false
            }
            else if (role === 'corporate_pharmacist') return false
            else if (role === 'corporate_admin_n_pharmacist') return false
            break;
        case 'approve':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status === 'pending') {
                    if (clinic_status === 'draft') {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                if (order_status === 'pending') {
                    if (!hq_status) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_pharmacist' || role === 'corporate_admin_n_pharmacist') {
                if (order_status === 'pending') {
                    if (org_name && (org_name.toLowerCase().includes('dental') || org_name.toLowerCase().includes('diagnostics'))) {
                        return true
                    } else if (hq_status === 'approved' || !pharmacy_status) {
                        return true
                    }
                } else {
                    return false
                }
            }
            break;
        case 'reject':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status === 'pending') {
                    if (clinic_status === 'draft') {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                if (order_status === 'pending') {
                    if (!hq_status) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_pharmacist' || role === 'corporate_admin_n_pharmacist') {
                if (order_status === 'pending') {
                    if (org_name && (org_name.toLowerCase().includes('dental') || org_name.toLowerCase().includes('diagnostics'))) {
                        return true
                    } else if (hq_status === 'approved' || !pharmacy_status) {
                        return true
                    }
                } else {
                    return false
                }
            }
            break;
        case 'draft':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status) {
                    if (order_status === 'pending') {
                        if (clinic_status === 'draft') {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') return false
            else if (role === 'corporate_pharmacist') return false
            else if (role === 'corporate_admin_n_pharmacist') return false
            break;
        case 'download_invoice':
            if (role === 'corporate_pharmacist') {
                if (order_status === 'approved') {
                    return true
                } else {
                    return false
                }
            }
            else if (role === 'corporate_admin_n_pharmacist') {
                if (order_status === 'approved') {
                    return true
                } else {
                    return false
                }
            }
            break;
        case 'download_poison_form':
            if (role === 'corporate_pharmacist' || role === 'corporate_admin_n_pharmacist') {
                if (order_status === 'approved') {
                    return true
                } else {
                    return false
                }
            }
            else if (role === 'corporate_clinic' || role === 'corporate_dental') {
                if (order_status === 'approved') {
                    return true
                } else {
                    return false
                }
            }
            break;
        case 'add_custom_product':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status === 'pending') {
                    if (clinic_status === 'draft') {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                if (order_status === 'pending') {
                    if (clinic_status === 'submitted' && !hq_status) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_pharmacist') if (order_status === 'pending') return true
            else if (role === 'corporate_admin_n_pharmacist') if (order_status === 'pending') return true
            else if (role === 'admin') if (order_status === 'pending') return true
            break;
        case 'add_store_product':
            if (role === 'corporate_clinic' || role === 'corporate_dental' || role === 'pharmacist' || role === 'customer_n_pharmacist') {
                if (order_status === 'pending') {
                    if (clinic_status === 'draft') {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_hq') {
                if (order_status === 'pending') {
                    if (clinic_status === 'submitted' && !hq_status) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            else if (role === 'corporate_pharmacist') if (order_status === 'pending') return true
            else if (role === 'corporate_admin_n_pharmacist') if (order_status === 'pending') return true
            else if (role === 'admin') if (order_status === 'pending') return true
            break;
        default:
            return false
    }
}

export const pricing = (role, item) => {
    const variants = item.variants && item.variants.length > 0;
    const variant = variants && item.variants[0];

    if (item.from === 'custom') return variant.price
    if (role.includes('corporate')) return !variant.corporate_price ? 0 : variant.corporate_price
    else return !variant.price ? 0 : variant.price
}

const reducer = (state, { type, payload }) => {
    const currentData = [...state.listCard, ...state.listCustom]
    const currentLenght = currentData.length;
    let index;
    let selected;
    if (payload && payload.data) {
        index = currentData.findIndex(x => x.id === payload.data.id)
        selected = currentData.find(x => x.id === payload.data.id)

        // console.log("Role: " + payload.role)
        // console.log("Order Status: " + payload.data.status)
        // console.log("Clinic Status: " + payload.data.clinic_status)
    }
    switch (type) {
        case 'ADD_CUSTOM_PRODUCT':
            const totalPrice = [...state.listCard, ...state.listCustom, payload.data].map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)
            return {
                ...state,
                listCustom: [...state.listCustom, { ...payload.data, id: currentLenght + 1 }],
                total: parseInt((state.transportation_fee + totalPrice).toFixed(2)),
                subTotal: parseInt(totalPrice.toFixed(2))
            }
        case 'CHANGE_MODE':
            return {
                ...state,
                mode: payload
            }
        case 'DECREMENT_PRODUCT_QS':
            let decItemQS = {
                ...selected,
                variants: [{
                    ...selected.variants[0],
                    quantity_supplied: selected.variants[0].quantity_supplied - 1
                }]
            }
            currentData.splice(index, 1, { ...selected, ...decItemQS })
            return {
                ...state,
                listCustom: currentData.filter(x => x.from === 'custom'),
                listCard: currentData.filter(x => x.from === 'store'),
            }
        case 'INCREMENT_PRODUCT_QS':
            let incItemQS = {
                ...selected,
                variants: [{
                    ...selected.variants[0],
                    quantity_supplied: selected.variants[0].quantity_supplied + 1
                }]
            }
            currentData.splice(index, 1, { ...selected, ...incItemQS })
            return {
                ...state,
                listCustom: currentData.filter(x => x.from === 'custom'),
                listCard: currentData.filter(x => x.from === 'store'),
            }
        case 'INCREMENT_PRODUCT':
            let incItem = {
                ...selected,
                quantity: selected.quantity + 1
            }

            currentData.splice(index, 1, { ...selected, ...incItem })
            const totalPrice3 = currentData.map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)
            return {
                ...state,
                listCustom: currentData.filter(x => x.from === 'custom'),
                listCard: currentData.filter(x => x.from === 'store'),
                total: parseInt((state.transportation_fee + totalPrice3).toFixed(2)),
                subTotal: parseInt(totalPrice3.toFixed(2))
            }
        case 'DECREMENT_PRODUCT':
            if (selected.quantity === 1) return {
                ...state,
                cart: currentData
            }

            let decItem = {
                ...selected,
                quantity: selected.quantity - 1
            }

            currentData.splice(index, 1, { ...selected, ...decItem })
            const totalPrice4 = currentData.map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)
            return {
                ...state,
                listCustom: currentData.filter(x => x.from === 'custom'),
                listCard: currentData.filter(x => x.from === 'store'),
                total: parseInt((state.transportation_fee + totalPrice4).toFixed(2)),
                subTotal: parseInt(totalPrice4.toFixed(2))
            }
        case 'UPDATE_PRODUCT':
            const listCard = payload.data.line_items.map(x => {
                return {
                    id: x.product_id,
                    title: x.title,
                    quantity: !x.quantity ? 1 : x.quantity,
                    remark: !payload.data.remark ? null : payload.data.remark,
                    variants: [{
                        id: null,
                        image: [{
                            url: x.img_url,
                        }],
                        price: !x.price ? 0 : x.price,
                        uom: !x.uom ? null : x.uom,
                        quantity_supplied: !x.quantity_supplied ? 0 : x.quantity_supplied,
                        corporate_price: 0
                    }],
                    from: 'store',
                }
            });
            const listCustom = payload.data.special_line_items.map(x => {
                return {
                    id: x.id,
                    title: x.product_name,
                    quantity: !x.quantity ? 1 : x.quantity,
                    remark: !payload.data.remark ? null : payload.data.remark,
                    variants: [{
                        id: null,
                        image: null,
                        price: !x.price ? 0 : x.price,
                        uom: x.uom,
                        quantity_supplied: !x.quantity_supplied ? 0 : x.quantity_supplied,
                        corporate_price: 0
                    }],
                    from: 'custom',
                }
            });
            const totalPrice6 = [...listCustom, ...listCard].map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)

            const corporateData = payload.data && payload.data.corporate_customer
            return {
                ...state,
                mode: true,
                selectedOrder: payload.data,
                corporate_customer: corporateData ? {
                    label: payload.data.corporate_customer.org_name,
                    value: payload.data.corporate_customer.id
                } : null,
                listCard,
                listCustom,
                remark: !payload.data.remark ? '' : payload.data.remark,
                total: parseInt((state.transportation_fee + totalPrice6).toFixed(2)),
                subTotal: parseInt(totalPrice6.toFixed(2)),
                action_button: {
                    table_action_q: showButtonActions('table_action_q', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    table_action_qs: showButtonActions('table_action_qs', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    table_action: showButtonActions('table_action', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    submit: showButtonActions('submit', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    draft: showButtonActions('draft', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    approve: showButtonActions('approve', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status, payload.data.corporate_customer.org_name),
                    reject: showButtonActions('reject', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status, payload.data.corporate_customer.org_name),
                    reset: showButtonActions('reset', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    download_invoice: showButtonActions('download_invoice', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    download_poison_form: showButtonActions('download_poison_form', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    add_custom_product: showButtonActions('add_custom_product', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status),
                    add_store_product: showButtonActions('add_store_product', payload.role, payload.data.status, payload.data.clinic_status, payload.data.hq_status, payload.data.pharmacy_status)
                }
            }
        case 'UPDATE_PRODUCT_MANUALLY_QS':
            let updateQS = {
                ...payload.data,
                variants: [{
                    ...payload.data.variants[0],
                    quantity_supplied: payload.data.variants[0].quantity_supplied
                }]
            }
            currentData.splice(index, 1, { ...selected, ...updateQS });
            const totalPrice10 = currentData.map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)
            return {
                ...state,
                listCustom: currentData.filter(x => x.from === 'custom'),
                listCard: currentData.filter(x => x.from === 'store'),
                total: parseInt((state.transportation_fee + totalPrice10).toFixed(2)),
                subTotal: parseInt(totalPrice10.toFixed(2))
            }
        case 'UPDATE_PRODUCT_MANUALLY':
            currentData.splice(index, 1, { ...selected, ...payload.data });
            const totalPrice5 = currentData.map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)
            return {
                ...state,
                listCustom: currentData.filter(x => x.from === 'custom'),
                listCard: currentData.filter(x => x.from === 'store'),
                total: parseInt((state.transportation_fee + totalPrice5).toFixed(2)),
                subTotal: parseInt(totalPrice5.toFixed(2))
            }
        case 'ADD_STORE_PRODUCT':
            const totalPrice2 = [...state.listCard, ...state.listCustom, ...payload.data].map(x => {
                return pricing(payload.role, x) * x.quantity
            }).reduce((a, b) => a + b, 0)
            return {
                ...state,
                listCard: payload.data,
                total: parseInt((state.transportation_fee + totalPrice2).toFixed(2)),
                subTotal: parseInt(totalPrice2.toFixed(2))
            }
        case 'SHOW_BUTTON_ACTION':
            return {
                ...state,
                action_button: {
                    ...state.action_button,
                    submit: true,
                    draft: true,
                    table_action: true,
                    table_action_q: true,
                    add_store_product: true,
                    add_custom_product: true,
                }
            }
        case 'REMOVE_FROM_TABLE':
            const storeData = state.listCard.filter(x => x.id !== payload.data.id)
            const customData = state.listCustom.filter(x => x.id !== payload.data.id)
            if (payload.data.from === 'store') {
                const totalPrice = [...storeData, ...customData].map(x => {
                    return pricing(payload.role, x) * x.quantity
                }).reduce((a, b) => a + b, 0)
                return {
                    ...state,
                    listCard: storeData,
                    total: parseInt((state.transportation_fee + totalPrice).toFixed(2)),
                    subTotal: parseInt(totalPrice.toFixed(2))
                }
            } else {
                const totalPrice = [...storeData, ...customData].map(x => {
                    return pricing(payload.role, x) * x.quantity
                }).reduce((a, b) => a + b, 0)
                return {
                    ...state,
                    listCustom: customData,
                    total: parseInt((state.transportation_fee + totalPrice).toFixed(2)),
                    subTotal: parseInt(totalPrice.toFixed(2))
                }
            }
        case 'SET_PARAMS':
            return {
                ...state,
                currentParams: payload
            }
        case 'SET_REMARK':
            return {
                ...state,
                remark: payload
            }
        case 'SET_CORPORATE_CUSTOMER':
            return {
                ...state,
                corporate_customer: payload
            }
        default:
            return
    }
}

const initialState = {
    listCard: [],
    listCustom: [],
    remark: '',
    corporate_customer: null,
    mode: false,
    total: 25,
    subTotal: 0,
    transportation_fee: 25,
    selectedOrder: null,
    currentParams: {
        sortBy: 'createdAt:desc',
        orderBy: '',
        limit: 20,
        page: 0,
        total: 0,
        category: '',
        keywords: ''
    },
    action_button: {
        table_action: false,
        submit: false,
        draft: false,
        approve: false,
        reject: false,
        reset: false,
        download_invoice: false,
        download_poison_form: false,
        add_custom_product: false,
        add_store_product: false,
        table_action_qs: false,
        table_action_q: false
    }
}


const OrderTableLIst = (props) => {
    const { globalData } = useContext(GlobalContext)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [showModalCustom, setShowModalCustom] = useState(false)
    const [showModalStore, setShowModalStore] = useState(false)
    const [loadingProduct, setLoadingProduct] = useState(false)
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [invoiceCode, setInvoiceCode] = useState("")
    const [numberOfInvoice, setNumberOfInvoice] = useState(0)
    const [clinicName, setClinicName] = useState('')
    const [mainLoading, setMainLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [showRemarks, setShowRemarks] = useState(false)
    const [isLoadingCorp, setisLoadingCorp] = useState(false)
    const [showFooter, setShowFooter] = useState(false)
    const [alert, setAlert] = useState({
        visibility: false,
        title: '',
    })
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ''
    })
    const [corporates, setCorporates] = useState({
        loading: true,
        data: [],
        raw_data: []
    })
    const [isLoadingPoison, setIsLoadingPoison] = useState(false)
    const [isLoadingInvoice, setIsLoadingInvoice] = useState(false)

    const memorizedUser = globalData.global.user;
    const memorizedRole = globalData.global.role;
    const memorizedOrderId = props.match.params.id;

    const handleClinicOrder = useCallback(() => {
        // console.log(memorizedRole);
        if (memorizedRole === 'corporate_clinic' || memorizedRole === 'corporate_dental') {
            if (memorizedUser.corporate_organisation) {
                // console.log(memorizedUser.corporate_organisation);
                // console.log(memorizedUser.corporate_organisation.org_name);
                // console.log(memorizedUser.corporate_organisation.id);
                dispatch({
                    type: 'SET_CORPORATE_CUSTOMER',
                    payload: {
                        label: memorizedUser.corporate_organisation.org_name,
                        value: memorizedUser.corporate_organisation.id
                    }
                })
            }
        }
    }, [memorizedRole, memorizedUser])

    useEffect(() => {
        handleClinicOrder()
    }, [handleClinicOrder])

    useEffect(() => {
        const element = document.getElementById('footer')
        if (element) {
            if (showFooter) {
                element.classList.add('expand')
            } else {
                element.classList.remove('expand')
            }
        }

    }, [showFooter])

    const getLatestOrder = useCallback(() => {
        if (!memorizedOrderId) {
            dispatch({ type: "SHOW_BUTTON_ACTION", payload: { data: "default", role: memorizedRole } })
            setMainLoading(false)
        }
        if (memorizedOrderId && memorizedRole) {
            get(`/clinic-orders/${memorizedOrderId}`).then(response => {
                dispatch({
                    type: 'UPDATE_PRODUCT',
                    payload: {
                        role: memorizedRole,
                        data: response
                    }
                })
                setMainLoading(false)
            }).catch(() => { setMainLoading(false) })
        }
    }, [memorizedOrderId, memorizedRole])

    useEffect(() => {
        let isMounted = false
        function getCorporate() {
            get(`/corporate-organisations`).then(response => {
                setCorporates({
                    loading: false,
                    data: response.map(x => {
                        return {
                            label: x.org_name,
                            value: x.id
                        }
                    }),
                    raw_data: response
                });
            })
        }

        if (!isMounted) {
            getCorporate()
        }

        return () => {
            isMounted = true
        }
    }, [])

    useEffect(() => {
        getLatestOrder()
    }, [getLatestOrder])

    const getProducts = async (sortBy, orderBy, limit, page, total, category, keywords) => {
        setLoadingProduct(true)
        setProducts([]);
        let params = ''
        let url = new URLSearchParams(params);

        url.set('_limit', limit)
        url.set('_start', page === 0 ? page : (page * 10) - 10)
        url.set('_sort', sortBy)

        if (category) url.set('category', category)
        if (keywords) url.set('keywords', keywords)

        await get(`/products?${url.toString()}`).then(response => {
            setLoadingProduct(false)
            setProducts(response.products);
            dispatch({
                type: 'SET_PARAMS',
                payload: {
                    total: response.count,
                    sortBy, orderBy, limit, page, category, keywords
                }
            })
        }).catch(() => setLoadingProduct(false))
    }

    const increment = (product) => {
        dispatch({
            type: 'INCREMENT_PRODUCT',
            payload: {
                data: product,
                role: memorizedRole
            }
        })
    }

    const decrement = (product) => {
        dispatch({
            type: 'DECREMENT_PRODUCT',
            payload: {
                data: product,
                role: memorizedRole
            }
        })
    }

    const incrementQS = (product) => {
        dispatch({
            type: 'INCREMENT_PRODUCT_QS',
            payload: {
                data: product,
                role: memorizedRole
            }
        })
    }

    const decrementQS = (product) => {
        dispatch({
            type: 'DECREMENT_PRODUCT_QS',
            payload: {
                data: product,
                role: memorizedRole
            }
        })
    }

    const manualyKeyInQS = (product, value) => {
        dispatch({
            type: 'UPDATE_PRODUCT_MANUALLY_QS',
            payload: {
                data: {
                    ...product,
                    variants: [{
                        ...product.variants[0],
                        quantity_supplied: !value || value === 0 ? 1 : parseInt(value)
                    }]
                },
                role: memorizedRole
            }
        })
    }

    const manualyKeyIn = (product, value) => {
        dispatch({
            type: 'UPDATE_PRODUCT_MANUALLY',
            payload: {
                data: {
                    ...product,
                    quantity: !value || value === 0 ? 1 : parseInt(value)
                },
                role: memorizedRole
            }
        })
    }

    const changeUom = (product, value) => {
        dispatch({
            type: 'UPDATE_PRODUCT_MANUALLY',
            payload: {
                data: {
                    ...product,
                    variants: [{
                        ...product.variants[0],
                        uom: !value ? null : value.value
                    }]
                },
                role: memorizedRole
            }
        })
    }

    useEffect(() => {
        setisLoadingCorp(true)
        let isMounted = false;
        async function getNumberOfInvoice() {
            let arrayQeury = [];

            const userData = memorizedUser;
            const user_corporate_organisation = userData && userData.corporate_organisation;
            const invoice_code = user_corporate_organisation && user_corporate_organisation.invoice_code;
            const userRole = memorizedRole;

            const filterTableByAccClinic = (userRole === "corporate_clinic" || userRole === "corporate_dental" || userRole === "corporate_pharmacist");

            if (user_corporate_organisation && filterTableByAccClinic) {
                arrayQeury.push({ corporate_customer: user_corporate_organisation.id })
            }

            const query = qs.stringify({
                _where: arrayQeury,
            });

            const count = await get(`/clinic-orders/count?${query}`)

            if (userData) {
                setNumberOfInvoice(count)

                if (user_corporate_organisation && invoice_code) {
                    setInvoiceCode(invoice_code)
                }

                if (!filterTableByAccClinic && user_corporate_organisation) {
                    setClinicName(user_corporate_organisation.org_name)
                }
            }
            setisLoadingCorp(false)
        }
        if (!isMounted) {
            getNumberOfInvoice();
        }
        setisLoadingCorp(false)
        return () => isMounted = true
    }, [memorizedUser, memorizedRole])

    const padNum = (num, places) => String(num).padStart(places, '0')

    const getCategory = () => {
        const query = qs.stringify({
            filter: "online_store_hierarchy_only",
        });
        get(`/categories?${query}`).then(response => {
            const data = response.map((x, i) => {
                return {
                    ...x,
                    key: i + 1
                }
            })
            setCategories(data);
        })
    }

    useEffect(() => {
        getCategory()
    }, [])

    const generateInvoiceCode = (code, name) => {
        return getTemplateInvoiceOrder(code, padNum(numberOfInvoice + 1, 8), name)
    }

    const handleSubmit = (status) => {
        setLoading(true)
        const _user = memorizedUser;
        const _corporateId = _user.corporate_organisation && _user.corporate_organisation.id
        const _address = memorizedUser.corporate_organisation && memorizedUser.corporate_organisation.address
        const _role = memorizedRole;

        let payload = {
            subtotal_price: state.subTotal,
            total_price: state.total,
            tax_price: state.selectedOrder ? state.selectedOrder.tax_price : 0,
            discount_price: state.selectedOrder ? state.selectedOrder.discount_price : 0,
            management_fee: state.selectedOrder ? state.selectedOrder.management_fee : 0,
            delivery_fee: state.selectedOrder ? state.selectedOrder.delivery_fee : state.transportation_fee,
            line_items: state.listCard.map(x => {
                const variants = x.variants && x.variants.length && x.variants[0];
                const variant = variants && x.variants[0];
                const imgData = variants.image && variants.image.length && variants.image[0];
                const url = imgData && imgData.url;

                return {
                    title: x.title,
                    quantity: x.quantity,
                    product_id: x.id,
                    variant_id: variant && variant.id,
                    price: pricing(memorizedRole, x),
                    uom: variant && variant.uom,
                    quantity_supplied: variant && variant.quantity_supplied,
                    total_price: pricing(memorizedRole, x) * x.quantity,
                    img_url: url
                }
            }),
            special_line_items: state.listCustom.map(x => {
                const variants = x.variants && x.variants.length;
                const variant = variants && x.variants[0]
                return {
                    product_name: x.title,
                    quantity: x.quantity,
                    price: variant && variant.price,
                    uom: variant && variant.uom,
                    quantity_supplied: variant && variant.quantity_supplied,
                    total_price: parseFloat(x.variants[0].price * x.quantity)
                }
            }),
            status: state.selectedOrder ? state.selectedOrder.status : 'pending',
            deliver_address: state.selectedOrder ? state.selectedOrder.deliver_address : {
                lastname: !_address ? "" : !_address && !_address.lastname ? '-' : _address.lastname,
                city: !_address ? "" : !_address.city ? '-' : _address.city,
                zipcode: !_address ? "" : !_address.zipcode ? '-' : _address.zipcode,
                phone: !_address ? "" : !_address.org_phone_num ? '-' : _address.org_phone_num,
                province: !_address ? "" : !_address.province ? '-' : _address.province,
                country: !_address ? "" : !_address.country ? '-' : _address.country,
                firstname: !_address ? "" : !_address.firstname ? '-' : _address.firstname,
                company: !_address ? "" : !_address.org_name ? '-' : _address.org_name,
                address1: !_address ? "" : !_address.address1 ? '-' : _address.address1,
                address2: null,
                location_name: null,
                email: !_address ? "" : !_address.email ? '-' : _address.email,
            },
            billing_address: state.selectedOrder ? state.selectedOrder.billing_address : {
                lastname: !_address ? "" : !_address && !_address.lastname ? '-' : _address.lastname,
                city: !_address ? "" : !_address.city ? '-' : _address.city,
                zipcode: !_address ? "" : !_address.zipcode ? '-' : _address.zipcode,
                phone: !_address ? "" : !_address.org_phone_num ? '-' : _address.org_phone_num,
                province: !_address ? "" : !_address.province ? '-' : _address.province,
                country: !_address ? "" : !_address.country ? '-' : _address.country,
                firstname: !_address ? "" : !_address.firstname ? '-' : _address.firstname,
                company: !_address ? "" : !_address.org_name ? '-' : _address.org_name,
                address1: !_address ? "" : !_address.address1 ? '-' : _address.address1,
                address2: null,
                location_name: null,
                email: !_address ? "" : !_address.email ? '-' : _address.email,
            },
            remark: showRemarks ? state.remark : ''
        }


        if (showOnlyForAdmin()) {
            if (state.corporate_customer === null) {
                setErrMsg({
                    visible: true,
                    msg: "Error: We regret to inform you that your order could not be processed. In order to submit your order, it is necessary to select the 'corporate customer' option."
                })
                setLoading(false);
                return;
            }
            if (!state.selectedOrder) {
                const item = corporates.raw_data.find(x => x.id === state.corporate_customer.value);
                const code = !item.invoice_code ? null : item.invoice_code;
                const name = !item.org_name ? null : item.org_name

                payload = {
                    ...payload,
                    order_name: generateInvoiceCode(code, name),
                    pharmacy_status: _role !== 'corporate_hq' ? 'approved' : null,
                    hq_status: 'approved',
                    clinic_status: 'submitted',
                    status: _role !== 'corporate_hq' ? 'approved' : 'pending',
                    corporate_customer: !state.corporate_customer ? null : state.corporate_customer.value,
                    checked_by: !_user ? null : _user.id,
                    record_status: [
                        {
                            role: _role.includes('clinic') ? 'clinic' : _role.includes('hq') ? 'hq' : _role.includes('pharmacist') ? 'pharmacy' : '',
                            status: status,
                            datetime: moment(),
                            user: !_user ? null : _user.id
                        },
                        {
                            role: _role.includes('clinic') ? 'clinic' : _role.includes('hq') ? 'hq' : _role.includes('pharmacist') ? 'pharmacy' : '',
                            status: 'approved',
                            datetime: moment(),
                            user: !_user ? null : _user.id
                        }
                    ]
                }
            } else {
                if ((status !== 'approved') && (status !== 'rejected')) {
                    payload = {
                        ...payload,
                        pharmacy_status: _role === 'corporate_admin_n_pharmacist' || _role === 'corporate_pharmacist' ? status : null,
                        hq_status: _role === 'corporate_hq' ? 'draft' : null,
                    }
                } else {
                    if (_role === 'corporate_hq') {
                        payload = {
                            ...payload,
                            checked_by: _user.id,
                            hq_status: !state.selectedOrder ? 'approved' : status,
                            status: status !== 'rejected' ? payload.status : status
                        }
                    } else if (_role === 'corporate_pharmacist' || _role === 'corporate_admin_n_pharmacist') {
                        // alert(state.selectedOrder.hq_status)
                        payload = {
                            ...payload,
                            pharmacy_status: !state.selectedOrder ? 'approved' : status,
                            hq_status: !state.selectedOrder ? 'approved' : status,
                            status: status,
                            checked_by: state.selectedOrder && state.selectedOrder.checked_by ? state.selectedOrder.checked_by : !_user.id ? null : _user.id
                        }
                    }

                    payload = {
                        ...payload,
                        record_status: [
                            ...state.selectedOrder.record_status,
                            {
                                role: _role.includes('clinic') ? 'clinic' : _role.includes('hq') ? 'hq' : _role.includes('pharmacist') ? 'pharmacy' : '',
                                status: status,
                                datetime: moment(),
                                user: !_user ? null : _user.id
                            }
                        ]
                    }
                }
            }
        } else {
            if (!state.selectedOrder) {
                payload = {
                    ...payload,
                    order_name: generateInvoiceCode(invoiceCode, clinicName),
                    corporate_customer: !_corporateId ? null : _corporateId,
                    clinic_status: status,
                    pharmacy_status: _role !== 'corporate_clinic' && status === 'submitted' ? 'pending' : null,
                    hq_status: _role !== 'corporate_clinic' && status === 'submitted' ? 'pending' : null,
                    record_status: [
                        {
                            role: 'clinic',
                            status: status,
                            datetime: moment(),
                            user: !_user ? null : _user.id
                        }
                    ]
                }
            } else {
                payload = {
                    ...payload,
                    clinic_status: status,
                    pharmacy_status: _role !== 'corporate_clinic' && status === 'submitted' ? 'pending' : null,
                    hq_status: _role !== 'corporate_clinic' && status === 'submitted' ? 'pending' : null,
                    record_status: [
                        ...state.selectedOrder.record_status,
                        {
                            role: 'clinic',
                            status: status,
                            datetime: moment(),
                            user: !_user ? null : _user.id
                        }
                    ]
                }
            }
        }

        try {
            if (!state.selectedOrder) {
                post('​/clinic-orders', payload).then(response => {
                    props.history.push({ pathname: `/all-invoice/${response.id}`, state: { title: response.order_name } })
                    setLoading(false)
                    setAlert({
                        visibility: true,
                        title: 'Saved!'
                    })
                })
            } else {
                update(`​/clinic-orders/${state.selectedOrder.id}`, payload).then(response => {
                    getLatestOrder()
                    setLoading(false)
                    setAlert({
                        visibility: true,
                        title: 'Saved!'
                    })
                })
            }
        } catch (error) {
            setErrMsg({
                visible: true,
                msg: "Error: Failed to process your request. We're sorry, but we were unable to process your request at this time. Please try again later or contact customer support for assistance."
            })
        }
    }

    const getLatestRecord = () => {
        const _role = memorizedRole;

        if (!state.selectedOrder) return;

        const record_length = state.selectedOrder.record_status.length;
        if (record_length > 0) {
            return {
                ...state.selectedOrder.record_status[record_length - 1],
                status: (_role === 'corporate_pharmacist' || _role === 'corporate_admin_n_pharmacist' || _role === 'corporate_admin') ?
                    state.selectedOrder.status === 'pending' ? 'waiting_for_approval' :
                        getStatusByRole(state.selectedOrder.record_status[record_length - 1], state.selectedOrder.record_status[record_length - 1].status) :
                    getStatusByRole(state.selectedOrder.record_status[record_length - 1], state.selectedOrder.record_status[record_length - 1].status)
            }
        } else return
    }

    const getStatusByRole = (data, status) => {
        const role = memorizedRole;
        switch (role) {
            case 'corporate_clinic':
                return status === 'approved' ? data.role === 'hq' ? 'waiting_for_approval' : 'approved' : status
            case 'corporate_hq':
                return status === 'submitted' ? 'waiting_for_approval' : status
            default:
                return status
        }
    }

    // const getCreatedOrder = () => {
    //     if (!state.selectedOrder) return;

    //     const record_length = state.selectedOrder.record_status.length;
    //     if (record_length === 0) return
    //     else return state.selectedOrder.record_status[0]
    // }

    // const getKeyByValue = (object, value) => {
    //     return Object.keys(object).find(key => object[key] === value);
    // }

    const spinner = () => {
        return (
            <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
        )
    }

    const showRemark = () => {
        const role = memorizedRole;

        if ((role === 'corporate_hq' || role === 'corporate_pharmacist' || role === 'corporate_admin_n_pharmacist' || role === 'administrator' || role === 'corporate_admin') && state.selectedOrder && state.selectedOrder.status === 'pending') return true;
        else return false
    }

    const showOnlyForAdmin = () => {
        const role = memorizedRole;

        if ((role === 'corporate_hq' || role === 'corporate_pharmacist' || role === 'corporate_admin_n_pharmacist' || role === 'administrator' || role === 'corporate_admin')) return true;
        else return false
    }

    const showButtonRemark = () => {
        const role = memorizedRole;
        const orderStatus = state.selectedOrder && state.selectedOrder.status && state.selectedOrder.status === 'pending';

        if ((role === 'corporate_pharmacist' || role === 'corporate_admin_n_pharmacist' || role === 'administrator' || role === 'corporate_admin') && orderStatus) return true;
        else return false
    }

    const handleScroll = (e) => {

        const element = document.getElementById('main-content');
        const fixedHeader = document.getElementById('header-fixed');
        if (element.scrollTop > 50) {
            fixedHeader.classList.remove('d-none')
            element.style.height = "calc(100vh - 271px)";
        } else {
            fixedHeader.classList.add('d-none')
            element.style.height = "calc(100vh - 201px)";
        }
    }

    const generateInvoice = async (e) => {
        e.preventDefault();
        setIsLoadingInvoice(true)

        let pharmacyAddress = {
            org_phone_num: '+603-79319309',
            address: {
                address1: 'GALLERY 5&6, GROUND FLOOR, MENARA PKNS',
                address2: 'NO.17 JALAN YOONG SHOK LIN, SEKSYEN 7',
                city: 'SELANGOR',
                province: 'PETALING JAYA',
                country: "Malaysia",
                zipcode: '46050',
                company: 'SELCARE PHARMACY SDN BHD'
            }
        }

        let deliveryAddress = {
            address1: '',
            address2: '',
            city: '',
            province: '',
            country: "",
            zipcode: '',
            company: ''
        }
        await get(`/corporate-organisations/${state.corporate_customer.value}`).then(res => {
            deliveryAddress = {
                address1: res.address && res.address.address1 ? res.address.address1 : '',
                address2: res.address && res.address.address1 ? res.address.address1 : '',
                city: res.address && res.address.city ? res.address.city : '',
                province: res.address && res.address.province ? res.address.province : '',
                country: res.address && res.address.country ? res.address.country : '',
                zipcode: res.address && res.address.zipcode ? res.address.zipcode : '',
                company: res.org_name ? res.org_name : '',
            }
        }).catch(error => {
            // alert(error)
        })

        let checkUserSubmitBy = '';
        let billingAddress = {
            name: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            zipcode: "",
            attention: ""
        }


        await get(`/users/${state.corporate_customer.value}`).then(res => {
            checkUserSubmitBy = !res.role ? '' : res.role.name;
        }).catch(error => {
        })

        switch (checkUserSubmitBy) {
            case "corporate_clinic":
                billingAddress = {
                    name: "Selcare Clinic HQ,",
                    address1: "No. 47A, 1st Floor,",
                    address2: "Jalan Snuker 13/28,",
                    address3: "Section 13, 40100, Shah Alam,",
                    address4: "Selangor",
                    attention: "Haris"
                }

                break;
            case "corporate_dental":
                billingAddress = {
                    name: "Selgate Healthcare Sdn Bhd,",
                    address1: "NO. 01- 02,Level 1,",
                    address2: "14/6, Jalan Pembangunan,",
                    address3: "Seksyen 14, 40000, Shah Alam,",
                    address4: "Selangor",
                    attention: "Hazri"
                }
                break;
            case "corporate_pharmacist":
                billingAddress = {
                    name: "Selgate Healthcare Sdn Bhd,",
                    address1: "NO. 01- 02,Level 1,",
                    address2: "14/6, Jalan Pembangunan,",
                    address3: "Seksyen 14, 40000, Shah Alam,",
                    address4: "Selangor",
                    attention: "Nur Hazwani"
                }
                break;
            default:

                break;
        }
        pdfInvoice.generateInvoice([...state.listCard, ...state.listCustom], state.total, state.selectedOrder, pharmacyAddress, billingAddress, deliveryAddress);
        setIsLoadingInvoice(false)
    }

    const generatePoisonForm = async (e) => {
        e.preventDefault();
        setIsLoadingPoison(true)

        let pharmacyAddress = {
            org_phone_num: '+603-79319309',
            address: {
                address1: 'GALLERY 5&6, GROUND FLOOR, MENARA PKNS',
                address2: 'NO.17 JALAN YOONG SHOK LIN, SEKSYEN 7',
                city: 'SELANGOR',
                province: 'PETALING JAYA',
                country: "Malaysia",
                zipcode: '46050',
                company: 'SELCARE PHARMACY SDN BHD'
            }
        }

        let deliveryAddress = {
            address1: '',
            address2: '',
            city: '',
            province: '',
            country: "",
            zipcode: '',
            company: ''
        }
        await get(`/corporate-organisations/${state.corporate_customer.value}`).then(res => {
            deliveryAddress = {
                address1: res.address && res.address.address1 ? res.address.address1 : '',
                address2: res.address && res.address.address1 ? res.address.address1 : '',
                city: res.address && res.address.city ? res.address.city : '',
                province: res.address && res.address.province ? res.address.province : '',
                country: res.address && res.address.country ? res.address.country : '',
                zipcode: res.address && res.address.zipcode ? res.address.zipcode : '',
                company: res.org_name ? res.org_name : '',
            }
        }).catch(error => {
            // alert(error)
        })

        let checkUserSubmitBy = ''
        await get(`/users/${state.corporate_customer.value}`).then(res => {
            checkUserSubmitBy = !res.role ? '' : res.role.name;
        }).catch(error => {
        })

        let billingAddress = {
            name: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            zipcode: "",
            attention: ""
        }

        switch (checkUserSubmitBy) {
            case "corporate_clinic":
                billingAddress = {
                    name: "Selcare Clinic HQ",
                    address1: "No. 47A, 1st Floor,",
                    address2: "Jalan Snuker 13/28,",
                    address3: "Section 13, 40100, Shah Alam,",
                    address4: "Selangor",
                    attention: "Haris"
                }

                break;
            case "corporate_dental":
                billingAddress = {
                    name: "Selgate Healthcare Sdn Bhd",
                    address1: "NO. 01- 02,Level 1,",
                    address2: "14/6, Jalan Pembangunan,",
                    address3: "Seksyen 14, 40000, Shah Alam,",
                    address4: "Selangor",
                    attention: "Hazri"
                }
                break;
            case "corporate_pharmacist":
                billingAddress = {
                    name: "Selgate Healthcare Sdn Bhd",
                    address1: "NO. 01- 02,Level 1,",
                    address2: "14/6, Jalan Pembangunan,",
                    address3: "Seksyen 14, 40000, Shah Alam,",
                    address4: "Selangor",
                    attention: "Nur Hazwani"
                }
                break;
            default:
                break;
        }

        pdfPoisonForm.generatorClinic([...state.listCard, ...state.listCustom], state.total, state.selectedOrder, pharmacyAddress, billingAddress, deliveryAddress);
        setIsLoadingPoison(false)
    }

    const onClickPoison = async (event) => {
        generatePoisonForm(event)
    }
    const onClickInvoice = async (event) => {
        generateInvoice(event)
    }

    const PoisonButton = (props) => (
        <button
            color="light"
            size='sm'
            className="waves-effect mr-1 btn btn-light btn-sm"
            onClick={props.onClick}
            disabled={isLoadingPoison}
        >
            {isLoadingPoison ? <Spinner size="sm" color='dark' className='mr-2 align-middle' /> : <i className="ri-download-line align-middle mr-1" />}Poison Form
        </button>
    )

    const InvoiceButton = (props) => (
        <button
            color="light"
            size='sm'
            className="waves-effect mr-1 btn btn-light btn-sm"
            onClick={props.onClick}
            disabled={isLoadingInvoice}
        >
            {isLoadingInvoice ? <Spinner size="sm" color='dark' className='mr-2 align-middle' /> : <i className="ri-download-line align-middle mr-1" />}Download Invoice
        </button>
    )

    return (
        <React.Fragment>
            {mainLoading ? null :
                <div className="page-content position-relative" style={{ height: "100vh", paddingTop: 70, paddingLeft: 0, paddingRight: 0 }}>

                    <div className=''>
                        <div style={{ height: 55 }}>
                            <CardHeader className='bg-white d-flex justify-content-between align-items-center border-bottom'>
                                <div>List Of Items</div>
                                <div className='positio-relative d-flex justify-content-end align-items-center'>
                                    {showOnlyForAdmin() ?
                                        <div className='d-flex justify-content-between align-items-center mr-2'><p className='mb-0 mr-2'>Corporate Customer:</p>
                                            <div style={{ width: 300 }} className=" mr-2">
                                                <Select
                                                    options={corporates.data}
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={corporates.data[0]}
                                                    value={!state.corporate_customer ? '' : state.corporate_customer}
                                                    isDisabled={props.match.params && props.match.params.id}
                                                    isLoading={corporates.loading || loading}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    name="color"
                                                    onChange={(val) => {
                                                        dispatch({
                                                            type: 'SET_CORPORATE_CUSTOMER',
                                                            payload: val
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div> : null}
                                    {!state.action_button.download_invoice ? null : <InvoiceButton onClick={onClickInvoice} />}
                                    {!state.action_button.download_poison_form ? null : <PoisonButton onClick={onClickPoison} />}
                                    {!state.action_button.add_custom_product ? null : <Button color="light" disabled={loadingProduct} size='sm' className="waves-effect mr-1" onClick={async () => {
                                        await getProducts(initialState.currentParams.sortBy, initialState.currentParams.orderBy, initialState.currentParams.limit, initialState.currentParams.page, initialState.currentParams.total, initialState.currentParams.category, initialState.currentParams.keywords)
                                        setShowModalStore(!showModalStore)
                                    }}>
                                        {loadingProduct ? <Spinner size="sm" color='dark' className='mr-2 align-middle' /> : <i className="ri-add-line align-middle mr-1 align-middle" />}
                                        Add Product From Store
                                    </Button>}
                                    {!state.action_button.add_store_product ? null : <Button color="light" size='sm' className="waves-effect" onClick={() => setShowModalCustom(!showModalCustom)}><i className="ri-add-line align-middle mr-1 align-middle" />Add Custom Product</Button>}
                                </div>
                            </CardHeader>
                        </div>

                        <div className='bg-light'>
                            <Table id='header-fixed' className="table-centered m-0 shadow-none rounded d-none" size='md' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='text-center' style={{ width: "34.28px" }} scope="col">#</th>
                                        <th className='text-center' style={{ width: "110px" }} scope="col">Products</th>
                                        <th className='text-center' style={{ width: "264.86px" }} scope="col">Details</th>
                                        <th className='text-center' style={{ width: "68.02px" }} scope="col">Price</th>
                                        <th className='text-center' style={{ width: "217px" }} scope="col">Quantity</th>
                                        <th className='text-center' style={{ width: "126.38px" }} scope="col">Quantity supplied</th>
                                        <th className='text-center' style={{ width: "217px" }} scope="col">UOM</th>
                                        <th className='text-center' style={{ width: "68.25px" }} scope="col">Total</th>
                                        <th className='text-center' style={{ width: "67.06px" }} scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </Table>
                        </div>


                        <div id='main-content' className='bg-light' style={{ height: "calc(100vh - 201px)", overflowY: "scroll" }} onScroll={handleScroll}>
                            <Table className="table-centered m-0 shadow-none rounded" size='md' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='text-center' style={{ width: "34.28px" }} scope="col">#</th>
                                        <th className='text-center' style={{ width: "110px" }} scope="col">Products</th>
                                        <th className='text-center' style={{ width: "264.86px" }} scope="col">Details</th>
                                        <th className='text-center' style={{ width: "68.02px" }} scope="col">Price</th>
                                        <th className='text-center' style={{ width: "217px" }} scope="col">Quantity</th>
                                        {!state.mode ? null : state.selectedOrder.status !== 'approved' ? null : <th className='text-center' style={{ width: "126.38px" }} scope="col">Quantity supplied</th>}
                                        <th className='text-center' style={{ width: "217px" }} scope="col">UOM</th>
                                        <th className='text-center' style={{ width: "68.25px" }} scope="col">Total</th>
                                        <th className='text-center' style={{ width: "67.06px" }} scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...state.listCard, ...state.listCustom].map((item, index) => {
                                        const variants = item.variants && item.variants.length > 0;
                                        const variant = variants && item.variants[0];
                                        const imgs = variant.image && variant.image.length > 0;
                                        const img = imgs && variant.image[0];
                                        const url = img && img.url;
                                        return (
                                            <tr key={index}>
                                                <td className='text-center px-2' style={{ width: "34.28px" }}>{index + 1}</td>
                                                <td className='text-center py-3 px-2' style={{ width: "110px" }}>{!url ?
                                                    <CardImg src={imgEmpty} alt="img" /> :
                                                    <CardImg className="avatar-md" src={getAPIroot() + url} alt="img" />}</td>
                                                <td className='text-center px-2' style={{ width: "264.86px" }}>{!item.title ? '-' : item.title}</td>
                                                <td className='text-center px-2' style={{ width: "68.02px" }}>RM {item.variants && item.variants.length > 0 ? !item.variants[0].price ? '0.00' : converPriceWithCommas(pricing(memorizedRole, item)) : '0.00'}</td>
                                                {!loading && state.action_button.table_action_q ? <td className='text-center px-2' style={{ width: "217px" }}>
                                                    <div>
                                                        <div className="product-cart-touchspin w-100">
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend" className="input-group-btn">
                                                                    <Button color="primary" className="bootstrap-touchspin-down" onClick={() => decrement(item)}><i className="ri-subtract-line"></i></Button>
                                                                </InputGroupAddon>
                                                                <Input className='custom-number-input' type="number" min="0" value={!item.quantity ? '0' : item.quantity} onChange={(e) => manualyKeyIn(item, e.target.value)} />
                                                                <InputGroupAddon addonType="append" className="input-group-btn">
                                                                    <Button color="primary" className="bootstrap-touchspin-down" onClick={() => increment(item)}><i className="ri-add-line"></i></Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                    </div>
                                                </td> : <td className='text-center' style={{ width: "217px" }}>{!item.quantity ? '0' : item.quantity}</td>}
                                                {!state.mode ? null : state.selectedOrder.status !== 'approved' ? null : !loading && state.action_button.table_action_qs ?
                                                    <td className='text-center px-2' style={{ width: "217px" }}>
                                                        <div>
                                                            <div className="product-cart-touchspin w-100">
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend" className="input-group-btn">
                                                                        <Button color="primary" className="bootstrap-touchspin-down" onClick={() => decrementQS(item)}><i className="ri-subtract-line"></i></Button>
                                                                    </InputGroupAddon>
                                                                    <Input className='custom-number-input' type="number" min="0" value={item.variants && item.variants.length > 0 ? !item.variants[0].quantity_supplied ? '0' : item.variants[0].quantity_supplied : '0'} onChange={(e) => manualyKeyInQS(item, e.target.value)} />
                                                                    <InputGroupAddon addonType="append" className="input-group-btn">
                                                                        <Button color="primary" className="bootstrap-touchspin-down" onClick={() => incrementQS(item)}><i className="ri-add-line"></i></Button>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </div>
                                                        </div>
                                                    </td> :
                                                    <td className='text-center px-2' style={{ width: "126.38px" }}>
                                                        {item.variants && item.variants.length > 0 ? !item.variants[0].quantity_supplied ? '0' : item.variants[0].quantity_supplied : '0'}
                                                    </td>
                                                }
                                                {!loading && state.action_button.table_action ? <td className='text-center px-2' style={{ width: "217px" }}>
                                                    <div>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            defaultValue={uomArrays[0]}
                                                            value={item.variants && item.variants.length > 0 ? !item.variants[0].uom ? '' : { label: item.variants[0].uom, value: item.variants[0].uom } : ''}
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={uomArrays}
                                                            onChange={(val) => {
                                                                changeUom(item, !val ? null : val)
                                                            }}
                                                        />
                                                    </div>
                                                </td> : <td className='text-center' style={{ width: "217px" }}>{item.variants && item.variants.length > 0 ? !item.variants[0].uom ? '-' : item.variants[0].uom : '-'}</td>}
                                                <td className='text-center px-2' style={{ width: "68.25px" }}>RM {item.variants && item.variants.length > 0 ? !item.variants[0].price ? '0.00' : converPriceWithCommas(pricing(memorizedRole, item) * item.quantity) : '0.00'}</td>
                                                <td className='text-center px-2' style={{ width: "67.06px" }}>
                                                    <Button
                                                        disabled={!state.action_button.table_action}
                                                        className={!state.action_button.table_action ? 'text-muted disable' : 'text-danger disable'}
                                                        id={`delete${index}`}
                                                        color="link"
                                                        onClick={() => {
                                                            dispatch({
                                                                type: "REMOVE_FROM_TABLE",
                                                                payload: {
                                                                    data: item,
                                                                    role: memorizedRole
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <i className="mdi mdi-trash-can font-size-18"></i>
                                                    </Button>
                                                    <UncontrolledTooltip placement="right" target={`delete${index}`}>Delete</UncontrolledTooltip >
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                        </div>



                        <div id='footer' className=' position-absolute w-100 footer-order bg-white' >
                            <div
                                className='position-absolute bg-white rounded-top px-3 py-1 text-muted small'
                                style={{ top: "-25px", left: 0, cursor: "pointer", boxShadow: "rgb(0 0 0 / 8%) 0px 0px 4px 2px", zIndex: 1 }}
                                onClick={() => {
                                    setShowFooter(!showFooter)
                                    setShowRemarks(false)
                                    const element = document.getElementById('footer');
                                    element.style.height = !showFooter ? "400px" : "72px";
                                }}
                            >
                                {showFooter ? <p><i className="ri-arrow-down-s-line mr-2"></i>Hide</p> : <p> <i className="ri-arrow-up-s-line mr-2"></i>Expand</p>}
                            </div>
                            <div className='py-2 px-4 bg-white position-relative w-100 d-flex h-100 flex-column' style={{ zIndex: 2, bottom: 0, left: 0 }}>
                                {showFooter ?
                                    <div className='h-100 py-2'>
                                        <div className='bg-white w-100 d-flex justify-content-between align-items-center my-3'>
                                            <h4 className='font-weight-bold mr-2'>Your Order</h4>
                                        </div>
                                        <div>
                                            <div className='border-bottom border-bottom border-light'>

                                                <div className='d-flex justify-content-between mt-3 mb-3'>
                                                    <p className='mb-0' color='secondary'>Order Status</p>
                                                    <div className={handleStatusStyle(!state.mode || !getLatestRecord() ? 'new' : getLatestRecord().status).badgeClass}>{handleStatusStyle(!state.mode || !getLatestRecord() ? 'new' : getLatestRecord().status).text}</div>
                                                </div>

                                                <div className='d-flex justify-content-between'>
                                                    <p className='mb-0' color='secondary'>Invoice No.</p>
                                                    {!state.selectedOrder ? <p className='text-right'>{generateInvoiceCode(invoiceCode, clinicName)}</p> : <p className='text-right'>{state.selectedOrder.order_name}</p>}
                                                </div>
                                            </div>

                                            <div className='d-flex justify-content-between mt-3'>
                                                <p className='mb-0' color='secondary'>Subtotal</p>
                                                <p className='text-right'>RM {converPriceWithCommas(state.subTotal)}</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-0' color='secondary'>Transportation Fee (Fixed Fee)</p>
                                                <p className='text-right'>RM {converPriceWithCommas(state.transportation_fee)}</p>
                                            </div>
                                            <div className='d-flex justify-content-between w-100'>
                                                <h4 className="text-primary mb-0 font-weight-bold">Total</h4>
                                                <h4 className='text-right text-primary mb-0 font-weight-bold'>RM {converPriceWithCommas(state.total)}</h4>
                                            </div>

                                            {state.selectedOrder && state.selectedOrder.remark && state.selectedOrder.status !== 'pending' ? <div className='border-top border-bottom border-light py-3 mt-3'>
                                                <div className=''>
                                                    <p className='mb-0' color='secondary'>Remark</p>
                                                    <p className='mb-0' color='secondary'>{state.selectedOrder.remark}</p>
                                                </div>
                                            </div> : null}
                                        </div>
                                    </div> : null}

                                {showRemark() && showRemarks ? <CardFooter className='bg-white border-top px-0'>
                                    <Label className='form-label'>Remark</Label>
                                    <textarea
                                        disabled={state.selectedOrder.status !== 'pending'}
                                        className="form-control border-0 px-0"
                                        type="text"
                                        placeholder="type something ..."
                                        value={state.remark}
                                        onChange={e => {
                                            dispatch({
                                                type: 'SET_REMARK',
                                                payload: e.target.value
                                            })
                                        }}
                                    />
                                </CardFooter> : null}

                                <div className='d-flex align-items-center justify-content-between py-2'>
                                    {!showFooter ? <div className='d-flex align-items-center justify-content-between'>
                                        <div className='pr-4 border-right border-light'>
                                            <p className='mb-0 text-muted small'>Order Status</p>
                                            <h4 className='mb-0'><div className={handleStatusStyle(!state.mode || !getLatestRecord() ? 'new' : getLatestRecord().status).badgeClass}>{handleStatusStyle(!state.mode || !getLatestRecord() ? 'new' : getLatestRecord().status).text}</div></h4>
                                        </div>
                                        <div className='px-4 border-right border-light'>
                                            <p className='mb-0 text-muted small'>Invoice No.</p>
                                            {!state.selectedOrder ? <h4 className='font-weight-bold text-primary mb-0'>{generateInvoiceCode(invoiceCode, clinicName)}</h4> : <h4 className='font-weight-bold text-primary mb-0'>{state.selectedOrder.order_name}</h4>}
                                        </div>
                                        <div className='pl-4'>
                                            <p className='mb-0 text-muted small'>Total</p>
                                            <h4 className='font-weight-bold text-primary mb-0'>RM {converPriceWithCommas(state.total)}</h4>
                                        </div>
                                    </div> : null}
                                    <div className={showFooter ? 'mt-3 w-100 text-right' : 'd-flex align-items-center justify-content-between'}>
                                        {/* button debug */}
                                        {/* {console.log("listCard: "+state.listCard)}
                                        {console.log("listCustom: "+state.listCustom)}
                                        {console.log("corporate_customer: "+state.corporate_customer)}
                                        {console.log("isLoading: "+isLoadingCorp)} */}

                                        {showButtonRemark() ? <Button color='link' className='pl-0 shadow-none' onClick={() => {
                                            setShowRemarks(!showRemarks)
                                            setShowFooter(true);

                                            const element = document.getElementById('footer');
                                            element.style.height = element.style.height !== "600px" ? "600px" : "400px";

                                        }}>
                                            <i className={!showRemarks ? "ri-add-line align-middle" : "ri-minus-line align-middle"} />
                                            {!showRemarks ? 'Add Remark' : 'Remove'}</Button> :
                                            null
                                        }
                                        {state.action_button.reset ? <Button color='link' size='md' className="text-danger waves-effect mr-1">Reset</Button> : null}
                                        {loading ? spinner() :
                                            <div>
                                                {state.action_button.reject ? <Button color='danger' size='md' className="waves-effect mr-1" onClick={() => handleSubmit('rejected')}>Reject</Button> : null}
                                                {state.action_button.approve ? <Button color='success' size='md' className="waves-effect mr-1" onClick={() => handleSubmit('approved')}>Approve</Button> : null}
                                                {/* {state.action_button.draft ? <Button color='secondary' size='md' disabled={[...state.listCard, ...state.listCustom].length === 0 || isLoadingCorp || !state.corporate_customer} className="waves-effect mr-1" onClick={() => handleSubmit('draft')}>{!state.mode ? 'Draft' : 'Save'}</Button> : null}
                                                {state.action_button.submit ? <Button color='primary' size='md' disabled={[...state.listCard, ...state.listCustom].length === 0 || isLoadingCorp || !state.corporate_customer} className="waves-effect" onClick={() => handleSubmit('submitted')}>Submit</Button> : null} */}
                                                {state.action_button.draft ? <Button color='secondary' size='md' disabled={[...state.listCard, ...state.listCustom].length === 0 || isLoadingCorp} className="waves-effect mr-1" onClick={() => handleSubmit('draft')}>{!state.mode ? 'Draft' : 'Save'}</Button> : null}
                                                {state.action_button.submit ? <Button color='primary' size='md' disabled={[...state.listCard, ...state.listCustom].length === 0 || isLoadingCorp} className="waves-effect" onClick={() => handleSubmit('submitted')}>Submit</Button> : null}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <CustomProductModal
                        toggleModal={() => {
                            setShowModalCustom(!showModalCustom)
                        }}
                        visibleModal={showModalCustom}
                        currentItem={state.listCustom}
                        onSubmit={(val) => {
                            dispatch({
                                type: "ADD_CUSTOM_PRODUCT",
                                payload: {
                                    role: memorizedRole,
                                    data: {
                                        ...val,
                                        from: 'custom'
                                    }
                                }
                            })
                        }}
                    />

                    <StoreProductModal
                        getCategory={getCategory}
                        categories={categories}
                        toggleModal={() => {
                            setShowModalStore(!showModalStore)
                        }}
                        visibleModal={showModalStore}
                        currentItem={state.listCard}
                        initialParams={state.currentParams}
                        products={products}
                        getItems={(params) => {
                            getProducts(params.sortBy, params.orderBy, params.limit, params.page, params.total, params.category, params.keywords)
                        }}
                        onSearch={(keywords) => {
                            getProducts(state.currentParams.sortBy, state.currentParams.orderBy, state.currentParams.limit, state.currentParams.page, state.currentParams.total, state.currentParams.category, keywords)
                        }}
                        onSubmit={(val) => {
                            dispatch({
                                type: "ADD_STORE_PRODUCT",
                                payload: {
                                    role: memorizedRole,
                                    data: val.map(x => {
                                        return {
                                            ...x,
                                            from: 'store'
                                        }
                                    })
                                }
                            })
                        }}
                    />
                    <AlertSuccess
                        setSweetTimmer={(val) => {
                            setAlert(prev => {
                                return {
                                    visibility: val,
                                    title: !val ? '' : prev.title
                                }
                            })
                        }}
                        title={alert.title}
                        sweetTimmer={alert.visibility}
                    />
                    <AlertError
                        title={'Error!'}
                        errMsg={errMsg}
                        setErrMsg={setErrMsg}
                    />
                </div >}
        </React.Fragment >
    )
}

export default OrderTableLIst