import moment from 'moment';
import React from 'react'
import { Button } from 'reactstrap'

const ReferralLetter = ({ dataSource, showModal, editMode, status }) => {
    return (
        <React.Fragment>
            <div className='position-relative d-flex justify-content-between align-items-center'>
                <h5>Referral Letter</h5>
                {dataSource.length === 0 && editMode && <Button color='light' onClick={showModal}>Add<i className="ri-file-line ml-2 align-middle"></i></Button>}
                {dataSource.length > 0 && status === 'accepted' ? <Button color='light' onClick={showModal}>Edit<i className="ri-file-line ml-2 align-middle"></i></Button> : null}
                {/* {dataSource.length > 0 && <Button color='light' onClick={printPDF}>PDF<i className="ri-file-line ml-2 align-middle"></i></Button>} */}
            </div>
            <div>
                {dataSource.map((item, index) => {
                    return (
                        <div key={index} className='d-flex justify-content-between border mt-3 p-3'>
                            <div className='mr-2'><h6><i className="ri-file-list-3-line align-middle ri-2x mr-2"></i></h6></div>
                            <div className='w-100'>
                                <p className='text-muted m-0'>Referral to: <b className='text-dark'>{item.referral_to}</b></p>
                                <p className='text-muted m-0'>Date of Referral: <b className='text-dark'>{moment(item.referral_date).format('MMM DD,YYYY hh:mm A')}</b></p>
                                <hr />
                                <h6><b>Patient Info</b></h6>
                                <p className='text-muted m-0'>Patient name: <b className='text-dark'>{item.patient_name}</b></p>
                                <p className='text-muted m-0'>Patient NRIC: <b className='text-dark'>{item.patient_nric}</b></p>
                                <p className='text-muted m-0'>Patient age: <b className='text-dark'>{item.patient_age}</b></p>
                                <p className='text-muted m-0'>Patient gender: <b className='text-dark'>{item.patient_gender}</b></p>
                                <hr />
                                <div>
                                    <p className='text-muted m-0'>History:</p>
                                    <p><b className='text-dark'>{!item.patient_history ? '-' : item.patient_history}</b></p>
                                </div>
                                <div>
                                    <p className='text-muted m-0'>Relevant Clinical Findings:</p>
                                    <p><b className='text-dark'>{!item.relevant_clinical_finding ? '-' : item.relevant_clinical_finding}</b></p>
                                </div>
                                <div>
                                    <p className='text-muted m-0'>Provisional Diagnosis:</p>
                                    <p><b className='text-dark'>{!item.provisional_diagnosis ? '-' : item.provisional_diagnosis}</b></p>
                                </div>
                                <div>
                                    <p className='text-muted m-0'>Treatment:</p>
                                    <p><b className='text-dark'>{!item.treatment ? '-' : item.treatment}</b></p>
                                </div>
                                <div>
                                    <p className='text-muted m-0'>Reason for Referral:</p>
                                    <p><b className='text-dark'>{!item.reason_for_referral ? '-' : item.reason_for_referral}</b></p>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default ReferralLetter