import React from 'react'
import { Col, Row } from 'reactstrap'
import ApplicationsCount from '../ApplicationsCount'
import ChartProvider from '../ChartProvider'
import GeneralCareAideCount from '../GeneralCareAideCount'
import MedicalAssistant from '../MedicalAssistant'
import NurseCount from '../NurseCount'
import PhiseotheraphyCount from '../PhiseotheraphyCount'
import ChartBookingStatus from './ChartBookingStatus'

const initialBookingData = {
    labels: [
        "New",
        "Completed",
        "Pending",
        "Cancelled",
    ],
    datasets: [
        {
            data: [0, 0, 0, 0],
            backgroundColor: [
                'rgba(235, 239, 242, 1)',
                'rgba(25, 171, 79, 1)',
                'rgba(252, 185, 44, 1)',
                'rgba(255, 61, 96, 1)'
            ],
            hoverBackgroundColor: [
                "rgba(235, 239, 242, 0.8)",
                "rgba(25, 171, 79, 0.8)",
                'rgba(252, 185, 44, 0.8)',
                'rgba(255, 61, 96, 0.8)',
            ],
            hoverBorderColor: "RGBA(255, 255, 255, 1)"
        }]
};

const initialPyshioData = {
    labels: [
        "45 min",
        "90 min",
        "135 min",
    ],
    datasets: [
        {
            data: [0, 0, 0],
            backgroundColor: [
                'rgba(45, 156, 219, 1)',
                'RGBA(86,100,210,1)',
                'rgba(252, 185, 44, 1)',
            ],
            hoverBackgroundColor: [
                'rgba(45, 156, 219, 0.8)',
                'RGBA(86,100,210,0.8)',
                'rgba(252, 185, 44, 0.8)',
            ],
            hoverBorderColor: "RGBA(255, 255, 255, 1)"
        }]
};

const BookingContent = ({ bookings, initialNurseData }) => {
    return (
        <div>
            <h4 className='mb-4'>{`Dashboard - Booking`}</h4>
            <Row>
                <Col md={4}>
                    <ApplicationsCount
                        icon="ri-folder-4-line"
                        title="Total Booking"
                        count={bookings.totalBookings.total}
                        loading={bookings.totalBookings.loading}
                    />

                    <ChartBookingStatus
                        title="Booking Status"
                        initialData={initialBookingData}
                        loading={bookings.bookingStatus.loading}
                        bookingCompleted={bookings.bookingStatus.completed}
                        bookingPending={bookings.bookingStatus.pending}
                        bookingNew={bookings.bookingStatus.new}
                        bookingCancelled={bookings.bookingStatus.cancelled}
                    />
                </Col>

                <Col md={4}>
                    <ChartProvider
                        title="Type of Booking"
                        loading={bookings.typeOfBooking.loading}
                        typeOfProvider={bookings.typeOfBooking.analytics}
                    />

                    <ChartBookingStatus
                        title="Physio - Sessions"
                        loading={bookings.bookingPhysio.loading}
                        initialData={initialPyshioData}
                        physioOne={bookings.bookingPhysio.physioOne}
                        physioTwo={bookings.bookingPhysio.physioTwo}
                        physioThree={bookings.bookingPhysio.physioThree}
                    />

                </Col>

                <Col md={4}>
                    <Row>
                        <Col md={6}>
                            <NurseCount loading={bookings.typeOfBooking.loading} typeOfProvider={bookings.typeOfBooking.analytics} />
                        </Col>
                        <Col md={6}>
                            <MedicalAssistant loading={bookings.typeOfBooking.loading} typeOfProvider={bookings.typeOfBooking.analytics} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <PhiseotheraphyCount loading={bookings.typeOfBooking.loading} typeOfProvider={bookings.typeOfBooking.analytics} />
                        </Col>
                        <Col md={6}>
                            <GeneralCareAideCount loading={bookings.typeOfBooking.loading} typeOfProvider={bookings.typeOfBooking.analytics} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ChartBookingStatus
                                title="Nurse - Days"
                                loading={bookings.bookingNurse.loading}
                                initialData={initialNurseData}
                                nurseListData={bookings.bookingNurse.listOfBookingNurse}
                                bookingCompletedNurse={bookings.bookingNurse.bookingCompletedNurse}
                                bookingPendingNurse={bookings.bookingNurse.bookingPendingNurse}
                                bookingAttentionNurse={bookings.bookingNurse.bookingAttentionNurse}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default BookingContent
