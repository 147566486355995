import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap'
import logodark from "./assets/images/selcare.png";
import avatar2 from "./assets/images/male_avatar.png";
import { GlobalContext } from './store/PageWrapper/GlobalContext';
import { getAPIroot, postMyTrustGate } from './helpers/API';
import useLocalStorage from './helpers/useLocalStorage';
import { ACTION_TYPE } from './store/PageWrapper/ActionType';
import { initialState } from './store/PageWrapper/Store';

const LockScreen = () => {
    const { globalData, setGlobalData, fetchingUserData } = useContext(GlobalContext)
    const [loading, setLoading] = useState(false)

    const getUser = useCallback(() => {
        if (globalData.global.user === null) {
            fetchingUserData();
        }
    }, [globalData, fetchingUserData])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getUser();
        }

        return () => {
            mount = true
        }
    }, [getUser])

    const handleSubmit = async (event, values) => {
        try {
            let certInfo = null;
            setLoading(true)
            const responseCert = await postMyTrustGate('/get-cert-info', {
                data: {
                    UserID: values.pin
                }
            })

            if (responseCert.return.statusCode === '000') {
                if (responseCert.return.certStatus === 'Valid' || responseCert.return.certStatus === 'Expired') {
                    setLoading(false)
                    certInfo = responseCert.return;
                    useLocalStorage.setItem('certDetails', certInfo)
                    useLocalStorage.setItem('certInfo', true)
                    useLocalStorage.setItem('lock', false)

                    const signature = globalData.global.signature ? globalData.global.signature : null;

                    if (globalData.global.signature) {
                        let payload = {
                            certInfo,
                            signature,
                            visibility_menu_sidebar: {
                                ...initialState.global.visibility_menu_sidebar,
                                tele_medicine: true,
                                prescription: {
                                    ...initialState.global.visibility_menu_sidebar.prescription,
                                    title: false,
                                    waitingForApproval: false
                                },
                                setting: {
                                    account: true
                                }
                            },
                        }

                        setGlobalData({
                            type: ACTION_TYPE.GET_USER_DATA,
                            payload,
                        })

                        window.location = '/tele-medicine'
                    } else {
                        let payload = {
                            certInfo,
                            signature,
                            visibility_menu_sidebar: {
                                ...initialState.global.visibility_menu_sidebar,
                                tele_medicine: true,
                                prescription: {
                                    ...initialState.global.visibility_menu_sidebar.prescription,
                                    title: false,
                                    waitingForApproval: false
                                },
                                setting: {
                                    account: true
                                }
                            },
                        }

                        setGlobalData({
                            type: ACTION_TYPE.GET_USER_DATA,
                            payload,
                        })

                        window.location = '/account'
                    }
                }
            } else {
                setLoading(false)
                useLocalStorage.setItem('certDetails', null)
                useLocalStorage.setItem('signature', false)
                useLocalStorage.setItem('certInfo', false)
                useLocalStorage.setItem('lock', false)
                window.location = '/acount'
            }

        } catch (error) {
            setLoading(false)
        }

    }
    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" onClick={() => {
                    window.location = '/login'
                    useLocalStorage.clear()
                }}><i className="mdi mdi-home-variant h2 text-white"></i></Link>
            </div>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="lockscreen-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo"><img src={logodark} height="100" alt="logo" /></Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Verification Request</h4>
                                                    {loading ? null : <p className="text-muted">Enter your PIN to unlock the screen!</p>}
                                                </div>
                                                {!loading ? <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <div className="user-thumb text-center mb-5">
                                                            {!globalData.global.user || !globalData.global.user.doctor || !globalData.global.user.doctor.profile_img ? <img src={avatar2} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail" /> : <img src={getAPIroot() + globalData.global.user.doctor.profile_img.url} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail" />}
                                                            <h5 className="font-size-15 mt-3">{!globalData.global.user ? null : globalData.global.user.username}</h5>
                                                        </div>

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label for="userpassword">NRIC</Label>
                                                            <AvField name="pin" validate={{ required: true }} type="text" className="form-control" id="userpassword" placeholder="Enter password" />
                                                        </FormGroup>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">{loading ? <Spinner size="sm" className="mr-2" color="light" /> : null}Submit</Button>
                                                        </div>
                                                    </AvForm>
                                                </div> : <div className='justify-content-center align-items-center text-muted d-flex w-100 mt-4 small'><Spinner size="sm" className="mr-2" color="primary" /><p className='mb-0'>Verifying you with trustgate...</p></div>}
                                                <div className="mt-5 text-center">
                                                    {/* <p>Not you ? return <Link to="/login" className="font-weight-medium text-primary"> Log in </Link> </p> */}
                                                    {/* <p>© 2020 Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="lockscreen-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default LockScreen
