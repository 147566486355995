import React, { useEffect, useState, useMemo } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";
import { capitalizeFirstLetter } from "../../store/CustomFunctions";
import classNames from "classnames";
import Select from "react-select";
import { get, update } from "../../helpers/API";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

const tabMenu = [
  {
    key: "pending",
    name: "Waiting for Appointment",
  },
  {
    key: "approved",
    name: "Approved",
  },
  {
    key: "rejected",
    name: "Cancelled",
  },
];

const clinic_type = [
  {
    value: "clinic",
    label: "GP Clinic",
  },
  {
    value: "dental",
    label: "Dental Clinic",
  },
];

const AppointmentPage = () => {
  const [modalContent, setModalContent] = useState({
    title: null,
    data: null,
  });
  const [tab] = useState(tabMenu);
  const [activeTab, setActiveTab] = useState("pending");
  const [tableList, setTableList] = useState([]);
  const [clinicList, setClinicList] = useState({
    data: [],
    loading: true,
  });
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedClinicType, setSelectedClinicType] = useState(null);

  //Sort
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);

  useEffect(() => {
    let isMounted = false;
    async function fethingAppointments() {
      let params = "";
      let url = new URLSearchParams(params);

      url.set("status", activeTab);
      // url.set("_sort", "createdAt:DESC,appointment_date_time:DESC");

      if (selectedClinic)
        url.set("_where[corporate_organisation]", selectedClinic.value);

      const response = await get(`/appointments?${url.toString()}`);

      setTableList(response);
    }

    if (!isMounted) {
      fethingAppointments();
    }

    return () => (isMounted = true);
  }, [selectedClinic, activeTab]);

  useEffect(() => {
    let isMounted = false;
    async function fethingClinics() {
      let clinicData = []
      const count = await get('/corporate-organisations/count')
      const response = await get(`/corporate-organisations?_limit=${count}`);

      const dental = response.filter(x => x.org_name).filter(x => x.org_name.toLowerCase().includes('selcare dental'))
      const clinic = response.filter(x => x.org_name).filter(x => x.org_name.toLowerCase().includes('selcare clinic'))

      if (!selectedClinicType) clinicData = [...dental, ...clinic]
      else {
        setSelectedClinic(null);
        if (selectedClinicType && selectedClinicType.value === "clinic")
          clinicData = clinic;
        else if (selectedClinicType && selectedClinicType.value === "dental")
          clinicData = dental;
      }

      const newData = clinicData.map((x) => {
        return {
          value: x.id,
          label: x.org_name,
        };
      });

      setClinicList({
        data: newData,
        loading: false,
      });
    }

    if (!isMounted) {
      fethingClinics();
    }

    return () => (isMounted = true);
  }, [selectedClinicType]);

  const convertDate = (date) => {
    return moment(date).format("DD/MM/YYYY")
  }

  const convertTime = (date) => {
    return moment(date).format("hh:mm A")
  }

  const sortedList = useMemo(() => {
    try {
      let sortableItems = [...tableList];
      if (sortConfig.key !== null) {
        sortableItems.sort((a, b) => {
          let itemA = a[sortConfig.key];
          let itemB = b[sortConfig.key];

          // Normalize string values and handle undefined/null values safely
          if (typeof itemA === 'string' && itemA !== null) {
            itemA = itemA.trim().replace(/\s+/g, ' ').toLowerCase();
          } else if (itemA === null || itemA === undefined) {
            itemA = '';
          }

          if (typeof itemB === 'string' && itemB !== null) {
            itemB = itemB.trim().replace(/\s+/g, ' ').toLowerCase();
          } else if (itemB === null || itemB === undefined) {
            itemB = '';
          }

          // Handle '-' values by placing them at the end or beginning depending on sort direction
          if (itemA === '-') itemA = sortConfig.direction === 'ascending' ? '\uffff' : '';
          if (itemB === '-') itemB = sortConfig.direction === 'ascending' ? '\uffff' : '';

          // Handling Boolean values correctly
          if (typeof itemA === 'boolean') {
            itemA = itemA ? 1 : 0; // Convert true to 1, false to 0
            itemB = itemB ? 1 : 0;
          }

          // Sorting logic
          if (itemA < itemB) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (itemA > itemB) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    } catch (error) {
      console.error('Sorting error:', error);
      return []; // Return an empty array if there's an error
    }
  }, [tableList, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortDirectionArrow = (columnName) => {
    if (sortConfig && sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? <i className="ri-arrow-up-s-line align-middle ml-2 ri-lg"></i> : <i className="ri-arrow-down-s-line align-middle ml-2 ri-lg"></i>;
    }
    return '';
  };

  const lastEntryIndex = currentPage * entriesPerPage;
  const firstEntryIndex = lastEntryIndex - entriesPerPage;
  const currentEntries = sortedList.slice(firstEntryIndex, lastEntryIndex);
  const pageCount = Math.ceil(sortedList.length / entriesPerPage);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    setCurrentPage(prev => (prev < pageCount ? prev + 1 : prev));
  };

  const prevPage = () => {
    setCurrentPage(prev => (prev > 1 ? prev - 1 : prev));
  };

  const handleApprovedAppointment = async (id) => {
    const response = await update(`/appointments/${id}`, {
      status: "approved",
    });

    if (response) {
      let params = "";
      let url = new URLSearchParams(params);

      url.set("status", activeTab);
      if (selectedClinic)
        url.set("_where[corporate_organisation]", selectedClinic.value);
      setModalContent({
        title: null,
        data: null,
      });

      const response = await get(`/appointments?${url.toString()}`);
      setTableList(response);
    }
  };
  const handleCancelledAppointment = async (id) => {
    const response = await update(`/appointments/${id}`, {
      status: "rejected",
    });

    if (response) {
      let params = "";
      let url = new URLSearchParams(params);

      url.set("status", activeTab);
      if (selectedClinic)
        url.set("_where[corporate_organisation]", selectedClinic.value);
      setModalContent({
        title: null,
        data: null,
      });

      const response = await get(`/appointments?${url.toString()}`);
      setTableList(response);
    }
  };
  return (
    <div className="page-content">
      <h4>Appointments</h4>

      <Card className="card">
        <CardBody className="py-0">
          <Nav tabs className="nav-tabs-custom mb-4">
            {tab.map((item, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    onClick={() => {
                      setActiveTab(item.key);
                    }}
                    className={classNames(
                      { active: activeTab === item.key },
                      "font-weight-bold p-3 align-middle"
                    )}
                  >
                    {capitalizeFirstLetter(item.name)}
                    {
                      <Badge
                        color="light"
                        pill
                        className="align-middle ml-2"
                      ></Badge>
                    }
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </CardBody>
        <CardBody className="position-relative">
          <div className="position-relative">
            {/* <h6>Filters</h6> */}
            <Row className='align-items-end w-100 g-0 px-2'>
              <Col md={3} className='px-1'>
                <Label>Clinic Type</Label>
                <Select
                  value={selectedClinicType}
                  options={clinic_type}
                  placeholder="e.g: Select Clinic Type"
                  onChange={(value) => {
                    setSelectedClinicType(value);
                  }}
                />
              </Col>
              <Col md={3} className='px-1'>
                <Label>Clinic Name</Label>
                <Select
                  value={selectedClinic}
                  options={clinicList.data}
                  placeholder="e.g: Select Clinic"
                  isLoading={clinicList.loading}
                  onChange={(value) => {
                    setSelectedClinic(value);
                  }}
                />
              </Col>
              <Col md={3} className='px-1'>
                <Button color="white bg-light" outline onClick={() => {
                  setSelectedClinic(null)
                  setSelectedClinicType(null)
                }}>Clear</Button>
              </Col>
            </Row>
          </div>
        </CardBody>

        <CardBody style={{ minHeight: 350 }}>
          <div className="table-border-container">
            <Table>
              <thead>
                <tr>
                  <th onClick={() => requestSort('user_fullname')}>Name{getSortDirectionArrow('user_fullname')}</th>
                  <th style={{ width: "15%" }} onClick={() => requestSort('corporate_organisation')}>Clinic{getSortDirectionArrow('corporate_organisation')}</th>
                  <th style={{ width: "15%" }} onClick={() => requestSort('createdAt')}>Submission Date{getSortDirectionArrow('createdAt')}</th>
                  {/* <th style={{ width: 180 }} onClick={() => requestSort('appointment_date_time')}>Appointment Date{getSortDirectionArrow('appointment_date_time')}</th> */}
                  {activeTab === 'rejected' ? null : <th style={{ width: "15%" }}>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((appointment, index) => {
                  return (
                    <tr key={index}>
                      {appointment.is_guest ? <td className=' align-top'>
                        <div className="">
                          <div className="d-flex align-items-start">
                            <div className="d-flex align-items-center mr-2">
                              <div className="p-2 border rounded d-flex align-items-center justify-content-center bg-light"><i className="ri-user-line ri-md text-secondary align-middle"></i></div>
                            </div>
                            <div>
                              <div className="text-capitalize">
                                <p className="small m-0 text-muted">From</p>
                                <p className="mb-1 fw-bold">{!appointment.user_fullname ? '-' : `${appointment.user_fullname}`}
                                  <div className="badge badge-pill badge-custom shadow-sm ml-2">
                                    <p className="mb-0"><i className="ri-account-circle-fill mr-1 align-middle"></i>Member</p>
                                  </div></p>
                                <p className="small mb-1">{!appointment.user_phone ? '-' : appointment.user_phone}</p>
                                <p className="small" style={{ fontStyle: 'italic' }}>"{!appointment.remark ? '-' : appointment.remark}"</p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-start">
                            <div className="d-flex align-items-center mr-2">
                              <div className="p-2 border rounded d-flex align-items-center justify-content-center bg-light"><i className="ri-calendar-2-line ri-md text-secondary align-middle"></i></div>
                            </div>
                            <div>
                              <p className="small text-muted m-0">Appointment Date</p>
                              <p className="small  m-0">{!appointment.appointment_date_time ? '-' : convertDate(appointment.appointment_date_time)} {!appointment.appointment_date_time ? '-' : convertTime(appointment.appointment_date_time)}</p>
                            </div>
                          </div>
                        </div>
                      </td> :
                        <td className='align-top'>
                          <div className="">
                            <div className="d-flex align-items-start">
                              <div className="d-flex align-items-center mr-2">
                                <div className="p-2 border rounded d-flex align-items-center justify-content-center bg-light"><i className="ri-user-line ri-md text-secondary align-middle"></i></div>
                              </div>
                              <div>
                                <div className="text-capitalize">
                                  <p className="small m-0 text-muted">From</p>
                                  <p className="mb-1 fw-bold">{!appointment.user_fullname ? '-' : appointment.user_fullname}</p>
                                  <p className="small mb-1">{!appointment.user_phone ? '-' : appointment.user_phone}</p>
                                  <p className="small" style={{ fontStyle: 'italic' }}>"{!appointment.remark ? '-' : appointment.remark}"</p>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="d-flex align-items-center mr-2">
                                <div className="p-2 border rounded d-flex align-items-center justify-content-center bg-light"><i className="ri-calendar-2-line ri-md text-secondary align-middle"></i></div>
                              </div>
                              <div>
                                <p className="small text-muted m-0">Appointment Date</p>
                                <p className="small m-0">{!appointment.appointment_date_time ? '-' : convertDate(appointment.appointment_date_time)} {!appointment.appointment_date_time ? '-' : convertTime(appointment.appointment_date_time)}</p>
                              </div>
                            </div>
                          </div>
                        </td>}
                      <td className='align-top' style={{ minWidth: 150, maxWidth: 150 }}>{!appointment.corporate_organisation && !appointment.corporate_organisation.org_name ? '-' : appointment.corporate_organisation.org_name}</td>
                      {/* <td className='align-top'>
                        <div>
                          <div className='d-flex align-items-center'><i className="ri-calendar-2-line ri-md mr-2 text-secondary"></i> {!appointment.appointment_date_time ? '-' : convertDate(appointment.appointment_date_time)}</div>
                          <div className='d-flex align-items-center'><i className="ri-time-line ri-md mr-2 text-secondary"></i> {!appointment.appointment_date_time ? '-' : convertTime(appointment.appointment_date_time)}</div>
                        </div>
                      </td> */}
                      <td className='align-top'>
                        <div>
                          <div className='d-flex align-items-center'><i className="ri-calendar-2-line ri-md mr-2 text-secondary"></i> {!appointment.createdAt ? '-' : convertDate(appointment.createdAt)}</div>
                          <div className='d-flex align-items-center'><i className="ri-time-line ri-md mr-2 text-secondary"></i> {!appointment.createdAt ? '-' : convertTime(appointment.createdAt)}</div>
                        </div>
                      </td>
                      {activeTab === 'rejected' ? null : <td className='align-top'>
                        {activeTab === 'rejected' ? null : <Button
                          size="sm"
                          onClick={() => {
                            setModalContent({
                              title: 'Cancel',
                              data: appointment
                            })
                          }} color='light'
                        >Cancel</Button>}
                        {activeTab === 'pending' ? <Button
                          size="sm"
                          onClick={() => {
                            setModalContent({
                              title: 'Approve',
                              data: appointment
                            })

                          }} color='primary' disabled={activeTab !== 'pending'} className='ml-2'>Approve</Button> : null}
                      </td>}
                    </tr>);
                })}
              </tbody>
            </Table>
          </div>
          <CardBody className="p-0">
            <div className="pb-2 px-4">
              <div className="d-flex justify-content-between align-items-center">
                <ButtonGroup>
                  {currentPage === 1 ? null : <Button className="bg-dark" onClick={() => changePage(1)}>&lt;&lt;</Button>}
                  {currentPage === 1 ? null : <Button className="bg-dark" onClick={prevPage}>&lt;</Button>}
                  {currentPage === pageCount ? null : <Button className="bg-dark" onClick={nextPage}>&gt;</Button>}
                  {currentPage === pageCount ? null : <Button className="bg-dark" onClick={() => changePage(pageCount)}>&gt;&gt;</Button>}
                </ButtonGroup>
                <div> <span>Page {currentPage} of {pageCount}</span></div>
              </div>
              {/* <Row className="align-items-end w-100 d-flex g-0">
                <Col md={3} className='px-1'>
                  
                </Col>
                <Col md={6} className='px-1'>
                  <div>
                   
                  </div>
                </Col>
              </Row> */}
            </div>
          </CardBody>
        </CardBody>
      </Card>

      {modalContent.data && (
        <SweetAlert
          style={{ overflow: "unset" }}
          title={"Are you sure?"}
          warning
          showCancel={true}
          showConfirm={true}
          onCancel={() => {
            setModalContent({
              title: null,
              data: null,
            });
          }}
          onConfirm={() => {
            if (modalContent.title === "Cancel")
              handleCancelledAppointment(modalContent.data.id);
            else handleApprovedAppointment(modalContent.data.id);
          }}
        ></SweetAlert>
      )}
    </div>
  );
};

export default AppointmentPage;
