import React from 'react'
import { Card, CardBody } from "reactstrap";
import { TEXT_ADDRESS, TEXT_CONTRACT_VALIDITY_DATE, TEXT_DATE_OF_EMPLOYMENT, TEXT_EMPLOYMENT, TEXT_NAME_OF_EMPLOYER, TEXT_PHONE_NO, TEXT_POSITION, TEXT_PREVIOUS } from '../../Text';
import moment from "moment"

const PreviousEmployment = ({ dataSource }) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title">{`${TEXT_PREVIOUS} ${TEXT_EMPLOYMENT.toLowerCase()}`}</h4>
                    <dl className="row mb-0">
                        <dt className="col-sm-3">{TEXT_NAME_OF_EMPLOYER}</dt>
                        <dd className="col-sm-9">{!dataSource.previous_employment ? "-" : !dataSource.previous_employment.name_of_employer ? "-" : dataSource.previous_employment.name_of_employer}</dd>
                        <dt className="col-sm-3">{TEXT_ADDRESS}</dt>
                        <dd className="col-sm-9">{!dataSource.previous_employment ? "-" :
                            `${!dataSource.previous_employment.address ? "" : dataSource.previous_employment.address.address_line_1 + ","} 
                                    ${!dataSource.previous_employment.address ? "" : dataSource.previous_employment.address.address_line_2 + ","}
                                    ${!dataSource.previous_employment.address ? "" : dataSource.previous_employment.address.city + ","}
                                    ${!dataSource.previous_employment.address ? "" : dataSource.previous_employment.address.zipcode + ","}
                                    ${!dataSource.previous_employment.address ? "" : dataSource.previous_employment.address.country}`
                        }</dd>
                        <dt className="col-sm-3">{TEXT_DATE_OF_EMPLOYMENT}</dt>
                        <dd className="col-sm-9">{!dataSource.previous_employment ? "-" : !dataSource.previous_employment.date_of_employment ? "-" : moment(dataSource.previous_employment.date_of_employment).format('LLLL')}</dd>
                        <dt className="col-sm-3">{TEXT_POSITION}</dt>
                        <dd className="col-sm-9">{!dataSource.previous_employment ? "-" : !dataSource.previous_employment.position ? "-" : dataSource.previous_employment.position}</dd>
                        <dt className="col-sm-3">{TEXT_CONTRACT_VALIDITY_DATE}</dt>
                        <dd className="col-sm-9">{!dataSource.previous_employment ? "-" : !dataSource.previous_employment.contract_validity_date ? "-" : dataSource.previous_employment.contract_validity_date}</dd>
                        <dt className="col-sm-3">{TEXT_PHONE_NO}</dt>
                        <dd className="col-sm-9">{!dataSource.previous_employment ? "-" : !dataSource.previous_employment.phone_number ? "-" : dataSource.previous_employment.phone_number}</dd>
                    </dl>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default PreviousEmployment
