import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Modal, ModalBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext'
import { PrescriptionContext } from '../../../../../store/prescriptions/PrescriptionData'
import Select from "react-select";
import { capitalizeEveryLetter, capitalizeFirstLetter } from '../../../../../store/CustomFunctions'

const options = [
    { label: 'Outpatient', value: 'outpatient' },
    { label: 'Inpatient', value: 'inpatient' }
]

const ModalReferralLetter = ({ visible, isClosed, onSubmit }) => {
    const { globalData } = useContext(GlobalContext)
    const { prescriptionDataLayer } = useContext(PrescriptionContext)
    const [stamp, setStamp] = useState(null)
    const [error, setError] = useState(true)
    const [referralData, setReferralData] = useState({
        referral_to: "",
        referral_date: moment(),
        referral_for: { label: "Outpatient", value: "outpatient" }, //outpatient | inpatient
        patient_name: "",
        patient_nric: "",
        patient_age: "",
        patient_gender: "",
        relevant_clinical_finding: "",
        patient_history: "",
        provisional_diagnosis: "",
        treatment: "",
        reason_for_referral: "",
        physician_name: "",
        has_clinic_stamp: false,
        consultation: null,
    })

    // const resetForm = () => {
    //     // setUploadImg(null)
    //     
    //     setError(true)
    //     setReferralData({
    //         referral_to: "",
    //         referral_date: moment(),
    //         referral_for: { label: "Outpatient", value: "outpatient" }, //outpatient | inpatient
    //         patient_name: "",
    //         patient_nric: "",
    //         patient_age: "",
    //         patient_gender: "",
    //         relevant_clinical_finding: "",
    //         provisional_diagnosis: "",
    //         treatment: "",
    //         reason_for_referral: "",
    //         patient_history: "",
    //         physician_name: "",
    //         has_clinic_stamp: false,
    //         consultation: null,
    //     })
    // }

    const memorizedPatient = prescriptionDataLayer.patient
    const memorizedUser = globalData.global.user
    const memorizedconsultation = prescriptionDataLayer.consultation
    const memorizedReferral = prescriptionDataLayer.consultation?.referral_letters?.[0]

    const updateFormData = (field, value) => {
        setReferralData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const handler = useCallback(() => {
        setStamp(null)
        let age = null;
        let currentYear = moment().format('YYYY');
        if (memorizedPatient) {
            if (memorizedPatient.date_of_birth) {
                const ary = memorizedPatient.date_of_birth.split('-');
                age = ary[0]
            }
            setError(false)
            setReferralData(prev => {
                return {
                    ...prev,
                    referral_to: !memorizedReferral ? "" : memorizedReferral.referral_to,
                    referral_for: !memorizedReferral ? "" : { label: capitalizeFirstLetter(memorizedReferral.referral_for), value: memorizedReferral.referral_for },
                    patient_name: capitalizeEveryLetter(memorizedPatient.user.firstname),
                    patient_nric: memorizedPatient.nric,
                    patient_age: (parseFloat(currentYear) - parseFloat(age)).toString(),
                    patient_gender: capitalizeFirstLetter(memorizedPatient.gender),
                    consultation: !memorizedconsultation ? null : memorizedconsultation.id,
                    // physician_name: !globalData.global.user ? null : globalData.global.user.username
                    physician_name: !memorizedUser.doctor ? null : memorizedUser.doctor.name,
                    relevant_clinical_finding: !memorizedReferral ? "" : memorizedReferral.relevant_clinical_finding,
                    provisional_diagnosis: !memorizedReferral ? "" : memorizedReferral.provisional_diagnosis,
                    treatment: !memorizedReferral ? "" : memorizedReferral.treatment,
                    reason_for_referral: !memorizedReferral ? "" : memorizedReferral.reason_for_referral,
                    patient_history: !memorizedReferral ? "" : memorizedReferral.patient_history,
                }
            })
        }
    }, [memorizedPatient, memorizedconsultation, memorizedUser, memorizedReferral])

    useEffect(() => {
        handler()
    }, [handler])


    // const handleAcceptedFiles = files => {
    //     files.map(file =>
    //         Object.assign(file, {
    //             preview: URL.createObjectURL(file),
    //             formattedSize: formatBytes(file.size)
    //         })
    //     );

    //     if (files.length > 0) {
    //         setError(false)
    //         setUploadImg(files[0].preview)
    //         setStamp(files[0])
    //     }
    // }
    return (
        <Modal
            size="lg"
            isOpen={visible}
            toggle={() => {
                isClosed()
            }}
        >
            <ModalBody>
                <div>
                    <h4><i className="ri-file-list-3-line align-middle ri-2x mr-2"></i>Referral Letter</h4>
                </div>
                <hr />
                <div>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Referral To</Label>
                                <Input type='text' value={referralData.referral_to} onChange={e => updateFormData('referral_to', e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Referral For</Label>
                                <Select
                                    options={options}
                                    classNamePrefix="select2-selection"
                                    placeholder='e.g: Mr'
                                    value={referralData.referral_for}
                                    onChange={value => updateFormData('referral_for', value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <h6>Patient Information</h6>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Patient Name</Label>
                                <Input type='text' value={referralData.patient_name} disabled />
                            </FormGroup>
                            <FormGroup>
                                <Label>Patient NRIC</Label>
                                <Input type='text' value={referralData.patient_nric} disabled />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Patient Gender</Label>
                                <Input type='text' value={referralData.patient_gender} disabled />
                            </FormGroup>
                            <FormGroup>
                                <Label>Patient Age</Label>
                                <Input type='text' value={referralData.patient_age} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />

                    <FormGroup>
                        <Label>History</Label>
                        <Input type='textarea' value={referralData.patient_history} onChange={e => updateFormData('patient_history', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Relevant clinical finding</Label>
                        <Input type='textarea' value={referralData.relevant_clinical_finding} onChange={e => updateFormData('relevant_clinical_finding', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Provisional diagnosis</Label>
                        <Input type='textarea' value={referralData.provisional_diagnosis} onChange={e => updateFormData('provisional_diagnosis', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Treatment</Label>
                        <Input type='textarea' value={referralData.treatment} onChange={e => updateFormData('treatment', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Reason for referral</Label>
                        <Input type='textarea' value={referralData.reason_for_referral} onChange={e => updateFormData('reason_for_referral', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Physician name</Label>
                        <Input type='text' disabled value={referralData.physician_name} onChange={e => updateFormData('physician_name', e.target.value)} />
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>Clinic Stamp</Label>
                        <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
                            {({ getRootProps, getInputProps, open }) => (
                                <div className="dropzone m-0 dropzone-custom">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} disabled={stamp ? true : false} />
                                        {!stamp ? (
                                            <div className="dz-message needsclick">
                                                <div>
                                                    <i className="display-4 text-muted ri-upload-line font-size-22"></i>
                                                </div>
                                            </div>) : (
                                            <div
                                                className="dz-preview dz-processing dz-image-preview dz-success dz-complete"
                                                id="file-previews"
                                            >
                                                {uploadImgPreview !== null ?
                                                    <img className="avatar-sm rounded bg-light" alt="img" style={{ width: "30%", height: "30%" }} src={uploadImgPreview} /> :
                                                    <img className="avatar-sm rounded bg-light" alt="img" style={{ width: "30%", height: "30%" }} src={getAPIroot() + stamp.url} />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </FormGroup> */}
                </div>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <Button
                        color='light'
                        className='mr-2 w-50'
                        onClick={() => {
                            // resetForm()
                            isClosed()
                        }
                        }>Cancel</Button>
                    <Button
                        disabled={error || !referralData.referral_to || !referralData.referral_for || !referralData.patient_name || !referralData.patient_nric || !referralData.patient_gender|| !referralData.patient_age || !referralData.patient_history || !referralData.relevant_clinical_finding || !referralData.provisional_diagnosis || !referralData.treatment || !referralData.reason_for_referral || !referralData.physician_name}
                        color='primary'
                        className='w-50'
                        onClick={() => {
                            let payload = {
                                ...referralData,
                                consultation: prescriptionDataLayer.consultation.id
                            }
                            onSubmit(payload, stamp)
                        }}>Save</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalReferralLetter