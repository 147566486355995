import React from 'react'
import { Table, Card, CardBody } from "reactstrap";
import { TEXT_EXPERIECE, TEXT_NAME_OF_EMPLOYER, TEXT_PREVIOUS, TEXT_WORK, TEXT_YEARS_OF_EXPERIENCE } from '../../Text';

const WorkExperience = ({ dataSource }) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title">{`${TEXT_PREVIOUS} ${TEXT_WORK.toLowerCase()} ${TEXT_EXPERIECE.toLowerCase()}`}</h4>
                    <Table className="mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{TEXT_NAME_OF_EMPLOYER}</th>
                                <th>{TEXT_YEARS_OF_EXPERIENCE}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataSource.previous_work_experiences && dataSource.previous_work_experiences.length > 0 && dataSource.previous_work_experiences.map((items, i) => {
                                return (
                                    <tr key={i}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{!items.organization ? "-" : items.organization}</td>
                                        <td>{!items.organization ? "-" : `${items.years_of_experience} year`}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default WorkExperience
