import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import ServerSidePaginations from '../../../../components/Table/ServerSidePaginations'
import { initialDataTablePatient, initialValue } from '../../Store/initialState'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { capitalizeEveryLetter, capitalizeFirstLetter } from '../../../../store/CustomFunctions'
import moment from 'moment'

const PatientInfo = ({ title, handleEditPatient, role, editMode }) => {
    const { prescriptionDataLayer } = useContext(PrescriptionContext);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState(initialDataTablePatient);

    const memorizedPatient = prescriptionDataLayer.patient;
    
    useEffect(() => {
        if (memorizedPatient) {
            // console.log("memorizedPatient", memorizedPatient);
        }
    }, [memorizedPatient])

    useEffect(() => {
        if (prescriptionDataLayer.patient) {
            const data = prescriptionDataLayer.patient;
            const name = !data.user ? '-' : !data.user.firstname ? '-' : capitalizeEveryLetter(data.user.firstname)
            const gender = !data.gender ? '-' : capitalizeFirstLetter(data.gender)
            const ic_number = !data.nric ? '-' : data.nric
            const dob = !data.date_of_birth ? '-' : moment(data.date_of_birth).format('DD MMM YYYY')
            const phone_number = !data.default_phone ? '-' : data.default_phone
            setDataSource(prev => {
                return {
                    ...prev,
                    rows: [{
                        name,
                        gender,
                        ic_number,
                        dob,
                        phone_number,
                        remark: prescriptionDataLayer.patient.remark
                    }]
                }
            })
        }
    }, [prescriptionDataLayer.patient])
    return (
        <React.Fragment>
            <div className='d-flex justify-content-between align-middle align-items-center mb-3'>
                <h5 className='m-0'>{title}</h5>
                {role && role !== 'doctor' && editMode && <Button color='light' onClick={handleEditPatient}> <i className="ri-pencil-line align-middle"></i></Button>}
            </div>
            <ServerSidePaginations
                loading={loading}
                dataSource={dataSource}
                obj={{}}
                initialParams={initialValue}
                setLoading={setLoading}
            />
        </React.Fragment>
    )
}

export default PatientInfo