import React from 'react';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

const AlertError = ({ props, setErrMsg, errMsg, title }) => {
    const history = useHistory();
    return (
        errMsg.visible ? <SweetAlert
            title={title}
            error
            onConfirm={() => {
                if (errMsg.msg.includes("terminated")) {
                    // console.log('Redirecting to logout');
                    history.push("/logout");
                } else if (props !== undefined && props !== null && errMsg.pathname !== undefined) {
                    props.history.push(errMsg.pathname);
                }
                setErrMsg({ ...errMsg, visible: false });
            }}
        ><p className='text-break'>{errMsg.msg}</p></SweetAlert> : null
    );
}

export default AlertError;
