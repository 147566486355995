import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// layout UI
import Header from "./Header";
import Sidebar from "./Sidebar";
import Rightbar from "../CommonForBoth/Rightbar";

// Actions
import {
  changeLayout,
  toggleRightSidebar,
  changeSidebarTheme,
  changeLayoutWidth,
  changeTopbarTheme,
  changeSidebarType,
} from "../../store/actions";
import { capitalizeFirstLetter } from "../../store/CustomFunctions";
import { GlobalContext } from "../../store/PageWrapper/GlobalContext";

const Layout = ({
  location,
  children,
  leftSideBarType,
  changeSidebarType,
  toggleRightSidebar,
  leftSideBarTheme,
  changeSidebarTheme,
  layoutWidth,
  changeLayoutWidth,
  topbarTheme,
  changeTopbarTheme,
  showRightSidebar,
  isPreloader,
}) => {
  const { globalData } = useContext(GlobalContext);
  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const userAcc = useMemo(() => {
    return globalData.global.user;
  }, [globalData.global.user]);

  const topbarThemes = useMemo(() => {
    return topbarTheme;
  }, [topbarTheme]);
  const leftSideBarTypes = useMemo(() => {
    return leftSideBarType;
  }, [leftSideBarType]);
  const leftSideBarThemes = useMemo(() => {
    return leftSideBarTheme;
  }, [leftSideBarTheme]);
  const layoutWidths = useMemo(() => {
    return layoutWidth;
  }, [layoutWidth]);
  const showRightSidebars = useMemo(() => showRightSidebar, [showRightSidebar]);

  const pathname = useMemo(() => {
    if (!location.pathname) return;

    return location.pathname;
  }, [location]);

  useEffect(() => {
    if (!pathname) return;

    window.scrollTo(0, 0);
    let splitPathName = pathname.split("/");
    let name = splitPathName[1];
    let currentage = capitalizeFirstLetter(name);
    document.title = currentage + " | Selcare";
  }, [pathname]);

  const toggleMenuCallback = () => {
    if (leftSideBarTypes === "default") {
      changeSidebarType("condensed", isMobile());
    } else if (leftSideBarTypes === "condensed") {
      changeSidebarType("default", isMobile());
    }
  };

  const toggleRightSidebars = useCallback(() => {
    toggleRightSidebar();
  }, [toggleRightSidebar]);

  const handler = useCallback(() => {
    if (leftSideBarThemes && leftSideBarThemes) {
      changeSidebarTheme(leftSideBarThemes);
    }

    if (layoutWidths && changeLayoutWidth) {
      changeLayoutWidth(layoutWidths);
    }

    if (leftSideBarTypes && changeSidebarType) {
      changeSidebarType(leftSideBarTypes);
    }

    if (topbarThemes && changeTopbarTheme) {
      changeTopbarTheme(topbarThemes);
    }

    if (showRightSidebars && toggleRightSidebars) {
      toggleRightSidebars();
    }

    if (!userAcc) return;

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  }, [
    userAcc,
    leftSideBarThemes,
    changeSidebarTheme,
    layoutWidths,
    changeLayoutWidth,
    leftSideBarTypes,
    changeSidebarType,
    topbarThemes,
    isPreloader,
    changeTopbarTheme,
    showRightSidebars,
    toggleRightSidebars,
  ]);

  useEffect(() => {
    handler();
  }, [handler]);

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <i className="ri-loader-line spin-icon"></i>
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          toggleMenuCallback={toggleMenuCallback}
          toggleRightSidebar={toggleRightSidebars}
        />
        <Sidebar
          theme={leftSideBarThemes}
          type={leftSideBarTypes}
          isMobile={() => isMobile()}
        />
        <div className="main-content">{children}</div>
      </div>
      <Rightbar />
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  toggleRightSidebar,
  changeSidebarTheme,
  changeLayoutWidth,
  changeTopbarTheme,
  changeSidebarType,
})(withRouter(Layout));
