import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Alert, Container, Label, FormGroup } from "reactstrap";
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';

// import images
import logodark from "../../assets/images/selcare-big.png";
import useLocalStorage from '../../helpers/useLocalStorage';
import Input from 'reactstrap/lib/Input';
import { capitalizeFirstLetter } from '../../store/CustomFunctions'

// crypted string
import Cryptr from 'cryptr'

// cookie
import Cookies from 'js-cookie'

// GlobalData
import { GlobalContext } from '../../store/PageWrapper/GlobalContext'
import { getAPIroot } from '../../helpers/API';

const Login = (props) => {
    const { fetchingUserData } = useContext(GlobalContext)
    const [credential, setCredential] = useState({ username: "", password: "" })
    const [visibility, setVisibility] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)
    const [visibilityAutoLogin, setVisibilityAutoLogin] = useState(false)
    const [rememberObj, setRememberObj] = useState({
        name: null,
        id: null,
        email: null,
        role: null,
        token: null,
        image: null
    })

    const handleVisibilityPassword = (visibility) => {
        setVisibility(visibility)
    }

    const handleSubmit = async (event, values) => {
        let data = {
            rememberMe: rememberMe,
            username: values.username,
            password: values.password
        }

        try {
            props.checkLogin(data, props.history);
        } catch (error) {
            alert(JSON.parse(error))
        }
    }

    useEffect(() => {
        const cryptr = new Cryptr('myTotallySecretKey');

        const name = Cookies.get('name')
        const token = Cookies.get('token')
        const remember_me = Cookies.get('remember_me')
        const email = Cookies.get('email')
        const role = Cookies.get('role')
        const id = Cookies.get('id')
        const image = Cookies.get('image')

        if (remember_me === 'true') {
            setRememberMe(remember_me === 'false' ? false : true)
            setVisibilityAutoLogin(remember_me === 'false' ? false : true)
        }

        if (token && name && email && role) {
            const decryptedString = cryptr.decrypt(token);
            setRememberObj({
                name: name,
                id: id,
                email: email,
                role: role,
                token: decryptedString,
                image: image
            })
        }

        if (image !== "null") {
            setRememberObj(prev => {
                return {
                    ...prev,
                    image,
                }
            })
        }
    }, []);

    const handleAutoLogin = async () => {
        useLocalStorage.setItem("accessToken", rememberObj.token);
        useLocalStorage.setItem("role", rememberObj.role);
        useLocalStorage.setItem("id", rememberObj.id);
        await fetchingUserData();
        const user_data = useLocalStorage.getItem('authUser');

        if (user_data) {
            props.history.push('/');
        }
    }

    const handleRemoveCookie = (e) => {
        e.stopPropagation()
        Cookies.remove('name')
        Cookies.remove('token')
        Cookies.remove('remember_me')
        Cookies.remove('email')
        Cookies.remove('id')
        Cookies.remove('role')
        Cookies.remove('image')
        setRememberMe(false)
        // props.history.push('/login');
        setVisibilityAutoLogin(false)
        window.location.reload()
    }


    useEffect(() => {
        document.body.classList.add("auth-body-bg");
        var data = useLocalStorage.getItem('rememberMe')
        setRememberMe(false)
        if (data !== undefined && data !== null) {
            setCredential({
                username: data.username, password: data.password
            })
        }
        return () => {
            document.body.classList.remove("auth-body-bg");
        }
    }, [])

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div className='box-container'>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo"><img src={logodark} height={visibilityAutoLogin ? "40" : "100"} alt="logo" /></Link>
                                                    </div>
                                                    <h4 className="font-size-18 mt-3">Welcome Back!</h4>
                                                    <p className="text-muted">Sign in to continue to Selcare.</p>
                                                </div>

                                                {visibilityAutoLogin && (
                                                    <div className='mb-3 mt-5 text-center'>
                                                        <div>
                                                            <h6 className='font-size-15 mb-1'>Recent logins</h6>
                                                            <p className="text-muted">Click your picture to login.</p>
                                                        </div>
                                                        <div className='d-flex justify-content-center align-items-center noselect position-relative' style={{ cursor: 'pointer' }}>
                                                            <div className='card card-hover bg-white shadow-lg w-100 mx-2' onClick={() => handleAutoLogin()}>
                                                                <div className='card-body py-2 px-4 '>
                                                                    <div className="user-thumb d-flex w-100 justify-content-between align-items-center">
                                                                        {rememberObj.image === "null" ? <div className='avatar-user lg mr-2'>{capitalizeFirstLetter(rememberObj.name[0])}</div>
                                                                            : <img src={`${getAPIroot()}${rememberObj.image}`} className="rounded-circle img-thumbnail avatar-md mr-3" alt="thumbnail" />}
                                                                        <div className='text-left w-100'>
                                                                            <h6 className="font-size-15 mb-0">{capitalizeFirstLetter(rememberObj.name)}</h6>
                                                                            <p className='font-size-11 text-muted mb-0'>{rememberObj.email}</p>
                                                                        </div>
                                                                        <div className='font-size-12'>
                                                                            <div className='btn-remove-cookie' onClick={e => handleRemoveCookie(e)}>
                                                                                <i className="ri-delete-bin-7-line"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {visibilityAutoLogin && (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='border-bottom w-100 mr-2'></div><p className='m-0 text-light small'>OR</p><div className='border-bottom w-100 ml-2'></div>
                                                    </div>)}

                                                <div className="p-2 mt-4">
                                                    {props.loginError && props.loginError ? <Alert color="danger">{props.loginError}</Alert> : null}

                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <FormGroup className="auth-form-group-custom mb-4 noselect">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">Username</Label>
                                                            <AvField name="username" value={credential.username} type="text" className="form-control" id="username" validate={{ email: true, required: { value: true } }} placeholder="Enter username" />
                                                        </FormGroup>

                                                        <FormGroup className="auth-form-group-custom mb-4 noselect">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField name="password" value={credential.password} type={!visibility ? 'password' : 'text'} className="form-control" id="userpassword" validate={{ required: { value: true } }} placeholder="Enter password" />
                                                            <div className='visible-icon-password' onClick={() => handleVisibilityPassword(!visibility)}><i className={!visibility ? "ri-eye-2-line" : "ri-eye-close-line"}></i></div>
                                                        </FormGroup>

                                                        <div className="custom-control custom-checkbox mb-3">
                                                            <Input type="checkbox" className="custom-control-input" onChange={() => setRememberMe(!rememberMe)} checked={rememberMe} />
                                                            <Label className="custom-control-label" onClick={() => { setRememberMe(!rememberMe) }} >Remember me</Label>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" disabled={props.loading} className="w-md waves-effect waves-light" type="submit">{props.loading ? "Loading..." : "Log In"}</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay-container">

                                    <div className='bg-overlay'></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { loginError, loading } = state.Login;
    return { loginError, loading };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));