import React, { useEffect, useState } from 'react'
import { get } from '../../../helpers/API'
import BookingSettingProvider from '../Store/BookingSetingContext'
import NursingInvoiceDetails from './NursingInvoiceDetails'

const NursingInvoiceBody = (props) => {
  const [invoice, setInvoice] = useState(null)
  const handleBack = () => {
    props.history.push('/nursing/all-invoice')
  }

  useEffect(() => {
    get(`/invoices/${props.match.params.id}`).then(response => {
      setInvoice(response)
    })
  }, [props])
  return (
    <div>
      <BookingSettingProvider>
        <NursingInvoiceDetails invoiceData={{ id: props.match.params.id, title: invoice ? invoice.invoice_number : '' }} handleBack={handleBack} />
      </BookingSettingProvider>
    </div>
  )
}

export default NursingInvoiceBody