import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData'
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext'
import PatientInfo from './PatientInfo'
import PatientPrescription from './PatientPrescription'
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType'

const PatientDetailsContent = ({ goToNextPage }) => {
    const { globalData } = useContext(GlobalContext);
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext)
    const [breadCrumbItems, setBreadCrumbItems] = useState([])

    const breadCrumb = useCallback(() => {
        let patientBreadCrumb = []
        if (prescriptionDataLayer.patient) {
            patientBreadCrumb = [
                { title: "Home", link: { pathname: '/e-prescription/patient', state: { title: 'Home', filter: '' } } },
                { title: 'Patient Details', link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}`, state: { title: 'Details', filter: '' } } },
                { title: prescriptionDataLayer.patient.nric, link: { pathname: `/e-prescription/patient/${prescriptionDataLayer.patient.id}`, state: { title: 'Details', filter: '' } } }
            ]

            setBreadCrumbItems(patientBreadCrumb)
        }
    }, [prescriptionDataLayer.patient])

    useEffect(() => {
        breadCrumb()
    }, [breadCrumb])

    return (
        <React.Fragment>
            <Breadcrumbs title={'Patient Profile'} breadcrumbItems={breadCrumbItems} />

            <Card>
                <CardBody>
                    <PatientInfo
                        role={globalData.global.role}
                        title="Patient Personal Info"
                        handleEditPatient={() => {
                            // setVisiblePatientModal(true)
                        }}
                    />
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <PatientPrescription
                        title="Prescription List"
                        role={globalData.global.role}
                        handleCreateNewPrescription={() => {
                            dispatch({
                                type: ACTION_TYPE.RESET_STATE
                            })
                            goToNextPage(`/e-prescription/patient/${prescriptionDataLayer.patient.id}/create-new-prescription`)
                        }}
                        handleUpdatePrescription={(item, prescription_status, medication_status) => {
                            dispatch({
                                type: ACTION_TYPE.RESET_STATE
                            })
                            goToNextPage(`/e-prescription/patient/${prescriptionDataLayer.patient.id}/prescription/${item.id}`)
                        }}
                    />
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default PatientDetailsContent