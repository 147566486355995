import React from 'react'
import { Card, CardBody, Media } from 'reactstrap'
import Spinner from 'reactstrap/lib/Spinner'

const ApplicationsCount = (props) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{props.title}</p>
                            {!props.loading ? <h4 className="mb-0">{props.count}</h4> : <Spinner size='sm' color="primary" />}
                        </Media>
                        <div className="text-primary">
                            <i className={`${props.icon} font-size-24`}></i>
                        </div>
                    </Media>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default ApplicationsCount
