import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import { TEXT_ALL_INVOICE } from '../../../Text';
import classNames from 'classnames';
import { initialDataTable } from './Store/TableColumn';
import ServerSidePaginations from '../../../components/Table/ServerSidePaginations';
import { get } from '../../../helpers/API';
import moment from 'moment'
import { converPriceWithCommas, handleStatusStyle } from '../../../store/CustomFunctions';

const TabInvoice = [
  {
    key: 'All',
    name: 'All'
  }
]

let initialValue = {
  sortBy: 'createdAt:desc',
  orderBy: '',
  limit: 20,
  page: 0,
  total: 0,
  category: '',
  keywords: ''
}

const obj = {
  path: '/nursing/all-invoice',
  title: "Invoice",
  filter: "Invoice"
}

const NursingInvoice = (props) => {
  const [activeTab, setActiveTab] = useState('All')
  const [initialParams, setInitialParams] = useState(initialValue);
  const [dataSource, setDataSource] = useState(initialDataTable)
  const [loading, setLoading] = useState(() => false)
  
  const getInvoice = useCallback((
    sortBy,
    orderBy,
    limit,
    page,
    total,
    category,
    keywords) => {

    setLoading(true);

    setDataSource(prev => {
      return {
        ...prev,
        rows: [{
          invoiceNo: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
        }]
      }
    })

    let params = ""
    let url = new URLSearchParams(params)


    url.set('_start', page === 0 ? page : (page * 10) - 10);
    url.set('_sort', sortBy);
    url.set('status_null', 'false')

    get(`/invoices/count?${url.toString()}`).then(count => {
      url.set('_limit', 10);
      get(`/invoices?${url.toString()}`).then(response => {
        const mapping = response.map(item => {
          let amount = converPriceWithCommas(item.subtotal + item.deposit_fee + item.processing_fee + item.transportation_fee)
          return {
            invoiceNo: item.invoice_number,
            dateTime: moment(item.createdAt).format('LLLL'),
            amount,
            status: item.status === null ? "-" : <div className={handleStatusStyle(item.status).badgeClass}>{handleStatusStyle(item.status).text}</div>,
            clickEvent: () => {
              if (props.history !== undefined || props.history !== null) {
                return props.history.push({ pathname: `/nursing/all-invoice/${item.id}` })
              }
            }
          }
        })

        setInitialParams({
          sortBy,
          orderBy,
          limit,
          total: count,
          page,
          category,
          keywords
        })

        setDataSource(prev => {
          return {
            ...prev,
            rows: mapping
          }
        });
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    }).catch(() => {
      setLoading(false);
    })
  }, [props.history])

  useEffect(() => {
    let mount = false;

    if (!mount) {
      getInvoice(
        initialValue.sortBy,
        initialValue.orderBy,
        initialValue.limit,
        initialValue.page,
        initialValue.total,
        initialValue.category,
        initialValue.keywords
      )
    }

    return () => {
      mount = true
    }
  }, [getInvoice])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{TEXT_ALL_INVOICE}</h4>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Nav tabs className="nav-tabs-custom mb-4">
                    {TabInvoice.map((item, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink onClick={() => { setActiveTab(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3")}>{item.name}</NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>

                  <ServerSidePaginations
                    loading={loading}
                    dataSource={dataSource}
                    getItems={(params) => {
                      getInvoice(
                        params.sortBy,
                        params.orderBy,
                        params.limit,
                        params.page,
                        params.total,
                        params.category,
                        params.keywords
                      )
                    }}
                    emptyMessage="There are no provider yet"
                    obj={obj}
                    initialParams={initialParams}
                    setLoading={setLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NursingInvoice