export const initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: '',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

export const TabTelemedicine = [
    {
        key: 'waiting_for_consultation',
        name: 'Waiting for Consultation'
    },
    {
        key: 'accepted',
        name: 'On going'
    },
    {
        key: 'history',
        name: 'history'
    },
]

export const obj = {
    path: '/e-prescription/waiting-approval',
    title: "Approval",
    filter: "Approval"
}

export const initialDataTableTelemedicine = {
    columns: [
        {
            label: "#",
            field: "index",
            sort: "asc",
            width: 50
        },
        {
            label: "Consultation Name",
            field: "consultation_name",
            sort: "disabled",
        },
        {
            label: "Payment Status",
            field: "payment_status",
            sort: "disabled",
            width: 200
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 200
        },
        {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 200
        },
    ]
}