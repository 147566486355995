import React from "react";
import { Redirect } from "react-router-dom";


// helpers
import useLocalStorage from "../helpers/useLocalStorage";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPwd from "../pages/Authentication/ResetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// ProviderApproved
import ProviderApproved from '../pages/ProviderApproved/ProviderApproved'

// ProviderPage
import ProviderPage from '../pages/Provider/ProviderPage'
import ProviderDetailPage from '../pages/Provider/ProviderDetailPage'

// Online Store
import Promotion from "../pages/OnlineStore/Promotion/Promotion";
import Order from "../pages/OnlineStore/Order/Order";
import Product from "../pages/OnlineStore/Product/Product";
import { OrderDetails } from "../pages/OnlineStore/Order/OrderDetails";
import { ProductDetails } from "../pages/OnlineStore/Product/ProductDetails";
import { PromotionDetails } from "../pages/OnlineStore/Promotion/PromotionDetails";

// Booking
import NursePage from "../pages/Nursing/Booking/Nurse/NursePage";

// Dashboard
import NewDashboard from "../pages/NewDashboard/NewDashboard";

// Nursing
import NurseDetails from "../pages/Nursing/Booking/Nurse/NurseDetails/NurseDetails";
import NursingInvoice from "../pages/Nursing/Invoice/NursingInvoice";
import NursingInvoiceBody from "../pages/Nursing/Invoice/NursingInvoiceBody";

// E-commerece
// import OrderList from "../pages/Ecommerce/OrderList/OrderList";
import AllInvoice from "../pages/Ecommerce/AllInvoice/AllInvoice";

// CLinics
import ClinicPage from "../pages/Clinics/ClinicPage";

// Doctors
import DoctorPage from "../pages/Doctors/DoctorPage";

// Appointment
import AppointmentPage from "../pages/Appointments/AppointmentPage"

// E-prescription
// import PatientPage from "../pages/E-prescription/Patient/PatientPage"
// import PatientContentPage from "../pages/E-prescription/Patient/PatientContentPage"
// import PrescriptionDetailsPage from "../pages/E-prescription/Patient/PrescriptionDetailsPage/PrescriptionDetailsPage";
// import PrescriptionFormPage from "../pages/E-prescription/Patient/PatientPrescription/PrescriptionFormPage";
// import PrescriptionDetails from "../pages/E-prescription/Patient/PrescriptionDetails/PrescriptionDetails";
// import PrescriptionDocDetails from "../pages/E-prescription/Patient/PrescriptionDetails/PrescriptionDocDetails";
// import CreateNewPatient from "../pages/E-prescription/NewPatient/CreateNewPatient";
// import ApprovalPage from "../pages/E-prescription/Approval/ApprovalPage";
// import PrescriptionForm from "../pages/E-prescription/Patient/PrescriptionForm/PrescriptionForm";
// import TeleMedicineContentPage from "../pages/TeleMedicine/TeleMedicineContentPage";
// import TeleMedicineConsultationPage from "../pages/TeleMedicine/TeleMedicineConsultationPage";
// NEW PRESCRIPTIONS
import PatientList from "../pages/New-E-Prescription/PatientList/Table/PatientList"
import PatientDetails from "../pages/New-E-Prescription/PatientList/Details/PatientDetails"
import PrescriptionForm2 from "../pages/New-E-Prescription/PatientList/Form/PrescriptionForm";
import ApprovalList from "../pages/New-E-Prescription/ApprovalList/ApprovalList";
import NewPatient from "../pages/New-E-Prescription/NewPatient/New_patient";
import NewTelemedicineList from "../pages/NewTelemedicine/NewTelemedicineList";

// Account
import AccountPage from "../pages/Account/AccountPage";

//Utility
import Maintenance from "../pages/Templete/Utility/Maintenance";
import CommingSoon from "../pages/Templete/Utility/CommingSoon";
import Error404 from "../pages/Templete/Utility/Error404";
import Error500 from "../pages/Templete/Utility/Error500";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import OrderTableLIst from "../pages/Ecommerce/NewOrderList/OrderTableLIst";




const authProtectedRoutes = [
	// NEW PRESCRIPTIONS 
	{ path: "/e-prescription/patient/:id/prescription/:id", component: PrescriptionForm2 },
	{ path: "/e-prescription/patient/:id/create-new-prescription", component: PrescriptionForm2 },
	{ path: "/e-prescription/patient/:id", component: PatientDetails },
	{ path: "/e-prescription/patient", component: PatientList },
	{ path: "/e-prescription/new-patient", component: NewPatient },
	{ path: "/e-prescription/waiting-approval/:id/prescription/:id", component: PrescriptionForm2 },
	{ path: "/e-prescription/waiting-approval", component: ApprovalList },
	// Section Doctors
	{ path: "/doctors", component: DoctorPage },
	// Section Clinics
	{ path: "/clinics", component: ClinicPage },
	// Section TeleMedicine
	{ path: "/tele-medicine/consultation/:id", component: PrescriptionForm2 },
	{ path: "/tele-medicine", component: NewTelemedicineList },
	{ path: "/appointments", component: AppointmentPage },
	// Section E-prescription
	// Patient page.
	// { path: "/e-prescription/patient/:id/prescription/:id/document", component: PrescriptionDocDetails },
	// { path: "/e-prescription/patient/:id/prescription/:id/edit", component: PrescriptionForm },
	// { path: "/e-prescription/patient/:id/prescription/:id", component: PrescriptionDetails },
	// { path: "/e-prescription/patient/:id/create-new-prescription", component: PrescriptionForm },
	// { path: "/e-prescription/patient/:id", component: PatientContentPage },
	// { path: "/e-prescription/patient", component: PatientPage },
	// Creating new patient page
	// { path: "/e-prescription/new-patient", component: CreateNewPatient },
	// Approval page
	// { path: "/e-prescription/waiting-approval/:id/prescription/:id/document", component: PrescriptionDocDetails },
	// { path: "/e-prescription/waiting-approval/:id/prescription/:id", component: PrescriptionDetails },
	// { path: "/e-prescription/waiting-approval", component: ApprovalPage },
	// Section Applications 
	// Applications > ProviderDetails
	{ path: "/application/provider/:id", component: ProviderDetailPage },
	// Applications > Provider
	{ path: "/application/provider", component: ProviderPage },
	// Section Booking
	// Booking > Nurse Details
	{ path: "/booking/nurse/:id", component: NurseDetails },
	{ path: "/booking/physio/:id", component: NurseDetails },
	{ path: "/booking/medical-assistant/:id", component: NurseDetails },
	{ path: "/booking/care-aide/:id", component: NurseDetails },
	{ path: "/booking/confinement/:id", component: NurseDetails },
	{ path: "/nursing/all-invoice/:id", component: NursingInvoiceBody },
	// Booking > Nurse
	{ path: "/booking/nurse", component: NursePage },
	{ path: "/booking/physio", component: NursePage },
	{ path: "/booking/medical-assistant", component: NursePage },
	{ path: "/booking/care-aide", component: NursePage },
	{ path: "/booking/confinement", component: NursePage },
	{ path: "/nursing/all-invoice", component: NursingInvoice },
	// Section Provider
	{ path: "/provider/:id", component: ProviderDetailPage },
	{ path: "/provider", component: ProviderApproved },
	//e-commerce
	{ path: "/order-list", component: OrderTableLIst },
	//account
	{ path: "/account", component: AccountPage },
	//all invoice
	{ path: "/all-invoice/:id", component: OrderTableLIst },
	{ path: "/all-invoice", component: AllInvoice },
	// Store
	// Section order
	{ path: "/store/orders/:id", component: OrderDetails },
	{ path: "/store/orders", component: Order },
	// Section product
	{ path: "/store/products/:id", component: ProductDetails },
	{ path: "/store/products", component: Product },
	// Section promotions
	{ path: "/store/promotions/:id", component: PromotionDetails },
	{ path: "/store/promotions", component: Promotion },
	// this route should be at the end of all other routes
	{ path: "/dashboard", component: NewDashboard },
	{
		path: "/", exact: true, component: () => {
			let role = useLocalStorage.getItem('role');
			let signature = useLocalStorage.getItem('signature');
			let certInfo = useLocalStorage.getItem('certInfo');

			if (role !== undefined && role !== null) {
				if (role === 'admin' || role === 'administrator') {
					// return <Redirect to="/dashboard" />
					return <Redirect to="/all-invoice" />
				} else if (role === 'corporate_super_admin') {
					return <Redirect to="/order-list" />
				} else if (role === 'corporate_admin') {
					return <Redirect to="/all-invoice" />
				} else if (role === 'corporate_clinic') {
					return <Redirect to="/all-invoice" />
				} else if (role === 'corporate_pharmacist') {
					return <Redirect to="/all-invoice" />
				} else if (role === 'corporate_dental') {
					return <Redirect to="/all-invoice" />
				} else if (role === 'corporate_hq') {
					return <Redirect to="/all-invoice" />
				} else if (role === 'pharmacist') {
					return <Redirect to="/store/products" />
				} else if (role === 'customer_n_pharmacist') {
					return <Redirect to="/store/products" />
				} else if (role === 'corporate_admin_n_pharmacist') {
					return <Redirect to="/store/products" />
				} else if ((role === 'doctor')) {
					if (!certInfo || !signature) {
						return <Redirect to="/account" />
					} else {
						return <Redirect to="/tele-medicine" />
					}
				} else if (role === 'admin_pharmacy') {
					// return <Redirect to="/dashboard" />
					return <Redirect to="/all-invoice" />
				}
			} else return <Redirect to="/logout" />
		}
	}
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/reset-password/:id", component: ResetPwd },
	{ path: "/register", component: Register },
	{ path: "/auth-lock-screen", component: AuthLockScreen },

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },

	{ path: "/pages-maintenance", component: Maintenance },
	{ path: "/pages-comingsoon", component: CommingSoon },
	{ path: "/pages-404", component: Error404 },
	{ path: "/pages-500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
