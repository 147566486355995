import React from 'react';
import App from './App';
import Store from "./store/PageWrapper/GlobalContext";
import PrescriptionData from './store/prescriptions/PrescriptionData'

export default function Root() {
    return (
        <Store>
            <PrescriptionData>
                <App />
            </PrescriptionData>
        </Store>
    );
}