import React, { useState, useEffect } from 'react'
import { Button, Input, InputGroup, InputGroupAddon, Table } from 'reactstrap'
import { FindLatestStatus } from '../../Store/CustomFunction'
import { initialDataTableDocMedication } from '../../Store/initialState'

const TablePrescriptionItems = ({ dataSource, role, handleIncrement, handleDecrement, handleUpdateSupplyAmount, hideBtnSupply }) => {
    const [showButton, setShowButton] = useState({
        supply: false
    })

    useEffect(() => {
        let status;
        if (dataSource) {
            // console.log("dataSource", dataSource);
            if (dataSource.activity_statuses.length > 0) {
                let statusArray = dataSource.activity_statuses
                status = FindLatestStatus('prescription_status', statusArray)
            }
        }
        if (role === 'pharmacist' || role === 'pharmacy') {
            setShowButton(prev => {
                return {
                    ...prev,
                    supply: status === 'approved' ? true : false
                }
            })
        }
    }, [role, dataSource])
    return (
        <Table responsive className="table-centered mb-0">
            <thead>
                <tr>
                    {showButton.supply ? initialDataTableDocMedication.columns.map((header, index) => {
                        return <th key={index}>{header.label}</th>
                    }) : initialDataTableDocMedication.columns.filter(item => item.field !== 'supply_amount').map((header, index) => {
                        return <th key={index}>{header.label}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {dataSource && dataSource.prescription_items && dataSource.prescription_items.map((item, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{!item.product ? item.medicine.description : item.product.title}</td>
                        <td>{!item.dosage ? '-' : item.dosage}</td>
                        <td>{!item.route ? '-' : item.route}</td>
                        <td>{!item.frequency ? '-' : item.frequency}</td>
                        <td>{!item.amount ? '0' : item.amount}</td>
                        {showButton.supply && <td>{<div>
                            {hideBtnSupply && <div className={!hideBtnSupply ? 'show-only-print' : ''}>{!item.supply_amount ? '0' : item.supply_amount}</div>}

                            {!hideBtnSupply &&
                                <div style={{ width: "120px" }} className="product-cart-touchspin hide-from-print">
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend" className="input-group-btn">
                                            <Button
                                                color="primary"
                                                className="bootstrap-touchspin-down"
                                                onClick={() => {
                                                    handleDecrement(item)
                                                }}>-</Button>
                                        </InputGroupAddon>
                                        <Input
                                            className='custom-number-input'
                                            type="number"
                                            min={0}
                                            max={!item.amount ? 0 : item.amount}
                                            value={!item.supply_amount ? '0' : item.supply_amount}
                                            onChange={(e) => {
                                                if ((e.target.value < item.amount)) {
                                                    handleUpdateSupplyAmount(parseFloat(e.target.value), item)
                                                } else if (e.target.value > item.amount) {
                                                    handleUpdateSupplyAmount(!item.amount ? 0 : item.amount, item)
                                                }

                                            }}
                                        />
                                        <InputGroupAddon addonType="append" className="input-group-btn">
                                            <Button
                                                disabled={item.supply_amount === item.amount}
                                                color="primary"
                                                className="bootstrap-touchspin-down"
                                                onClick={() => {
                                                    if ((item.supply_amount < item.amount)) {
                                                        handleIncrement(item)
                                                    }
                                                }}>+</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>}
                        </div>
                        }
                        </td>}
                        <td>{item.amount > 0 && item.supply_amount === item.amount ? 'Fully Supplied' : 'Partially Supply'}</td>
                        <td>{!item.remarks ? '-' : item.remarks}</td>
                    </tr>
                })}
            </tbody>
        </Table>
    )
}

export default TablePrescriptionItems