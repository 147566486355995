import React from 'react'
import classNames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap'

const Tablist = ({ handleParentKey, filterTab, handleShowNoti, activeTab }) => {
    return (
        <Nav tabs className="nav-tabs-custom mb-4">
            {filterTab.map((item) => {
                return (
                    <NavItem className="noti-icon" key={item.key}>
                        <NavLink onClick={() => handleParentKey(item.key)} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3")}>
                            {item.title}
                            {<span className="noti-dot" style={{ top: 15, right: 10, display: handleShowNoti(item.key).key !== item.key && 'none' }}></span>}
                        </NavLink>
                    </NavItem>
                )
            })}
        </Nav>
    )
}

export default Tablist
