import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, InputGroup, CardImg } from "reactstrap";
import {
    TEXT_YOUR_ACCOUNT,
    TEXT_ADDRESS_SETTING,
    TEXT_IMAGE_SETTING,
    TEXT_ACCOUNT_SETTING,
} from '../../Text';
import useLocalStorage from '../../helpers/useLocalStorage';
import { getAPIroot, post, remove, update, get } from '../../helpers/API';
import AlertSuccess from '../CustomComponent/Alert/AlertSuccess'
import FormUpload from '../../components/Common/FormUpload';
import ImageUpload from '../../components/Common/ImageUpload';
import { formatBytes } from '../../store/CustomFunctions';
import { GlobalContext } from '../../store/PageWrapper/GlobalContext'
import { ACTION_TYPE } from '../../store/PageWrapper/ActionType';
import ModalRegisterCert from './ModalRegisterCert';
import moment from 'moment';
import { initialState } from '../../store/PageWrapper/Store';
import qs from 'qs'

const AccountPage = () => {
    const { globalData, setGlobalData } = useContext(GlobalContext)
    const [modalCert, setModalCert] = useState(false)
    const [stateUser, setStateUser] = useState({
        username: '',
        email: ''
    })
    const [statePassword, setStatePassword] = useState({
        password: "",
        rePassword: ""
    })
    const [stateAddress, setStateAddress] = useState({
        pic_phone_num: "",
        pic_name: "",
        org_phone_num: "",
        org_name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        zipcode: "",
        city: "",
        province: "",
        country: "",
        email: ""
    })
    const [corporateDataSource, setCorporateDataSource] = useState({})
    const [stateInvoiceCode, setInvoiceCode] = useState({ invoice_code: "" })
    const [visibleModal, setVisibleModal] = useState(false)
    const [sweetTimmer, setSweetTimmer] = useState(false)
    const [showErrorMsg, setShowErrorMsg] = useState({})
    const [loading, setLoading] = useState(false)
    const [signature, setSignature] = useState(null)
    const [uploadImgPreview, setUploadImg] = useState(null)
    const [image, setImage] = useState(null)
    const [accessRole, setAccessRole] = useState({
        invoice_setting: false,
        signature: false,
        address_setting: false
    })
    const [certInfo, setCertInfo] = useState(null);
    const [padNum, setPadNum] = useState("00000001")

    useEffect(() => {
        async function getData() {
            try {
                if (globalData.global.user && globalData.global.role) {
                    const userData = globalData.global.user;
                    const user_corporate_organisation = userData && userData.corporate_organisation;
                    const userRole = globalData.global.role;

                    const filterTableByAccClinic = (userRole === "corporate_clinic" || userRole === "corporate_dental" || userRole === "corporate_pharmacist");

                    let arrayQeury = [];

                    if (filterTableByAccClinic && user_corporate_organisation) {
                        arrayQeury.push({ corporate_customer: user_corporate_organisation.id })
                    }

                    if (userRole === "corporate_hq") {
                        arrayQeury.push({ clinic_status: 'submitted' }, { hq_status_ne: ['draft', 'pending'] })
                    } else if (userRole === "corporate_pharmacist" || userRole === "corporate_admin_n_pharmacist" || userRole === "administrator" || userRole === "corporate_admin") {
                        arrayQeury.push({ clinic_status: 'submitted' }, { hq_status: 'approved' })
                    } else if (userRole === "corporate_dental") {
                        // arrayQeury.push({ clinic_status: "submitted" })
                    } else if (userRole === "corporate_clinic") {
                        // arrayQeury.push({ clinic_status: "submitted" })
                    } else if (userRole !== "doctor" && userRole !== "admin_pharmacy") {
                        const query = qs.stringify({
                            _where: arrayQeury
                        });
                        const response = await get(`/clinic-orders/count?${query}`);
                        setPadNum(String(response + 1).padStart(8, '0'));
                    }

                }
            } catch (error) {

            }
        }

        getData()
    }, [globalData.global.user, globalData.global.role])

    const handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        setUploadImg(files[0].preview)
        setSignature(files[0])
    }

    const handleAcceptedImage = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );

        setUploadImg(files[0].preview)
        setImage(files[0])
    }

    useEffect(() => {
        const global_role = globalData.global.role;
        const local_role = JSON.parse(useLocalStorage.getItem('authUser'));
        let _role = !global_role ? !local_role ? null : local_role.role.type : global_role;

        if (globalData.global.certInfo) {
            setCertInfo(globalData.global.certInfo);
        }

        const roles = _role === 'administrator' ||
            _role === 'corporate_clinic' ||
            _role === 'corporate_dental' ||
            _role === 'corporate_pharmacist' ||
            _role === 'customer_n_pharmacist' ||
            _role === 'phamacist';

        if (global_role && roles) {
            setAccessRole(prev => {
                return {
                    ...prev,
                    invoice_setting: !prev.invoice_setting,
                    address_setting: !prev.address_setting
                }
            })
        } else if (global_role && _role === 'doctor') {
            setAccessRole(prev => {
                return {
                    ...prev,
                    signature: globalData.global.certInfo ? true : false
                }
            })
        }


    }, [globalData.global.role, globalData.global.certInfo])

    useEffect(() => {
        if (globalData.global.user) {
            if (globalData.global.user.doctor && globalData.global.user.doctor.signature) {
                setSignature(globalData.global.user.doctor.signature.url);
            }

            setStateUser({
                username: globalData.global.user.username,
                email: globalData.global.user.email
            })
            if (globalData.global.user.corporate_organisation) {
                const corporateData = globalData.global.user.corporate_organisation
                setInvoiceCode({ invoice_code: corporateData.invoice_code })
                setCorporateDataSource(corporateData)

                let addressData = {}
                if (corporateData.address !== null && corporateData.address !== undefined) {
                    addressData = corporateData.address
                }

                setStateAddress({
                    pic_phone_num: corporateData.pic_phone_num,
                    pic_name: corporateData.pic_name,
                    org_phone_num: corporateData.org_phone_num,
                    org_name: corporateData.org_name,
                    email: addressData.email,
                    firstname: addressData.firstname,
                    lastname: addressData.lastname,
                    address1: addressData.address1,
                    address2: addressData.address2,
                    zipcode: addressData.zipcode,
                    city: addressData.city,
                    province: addressData.province,
                    country: addressData.address1,
                })
            }

            if (globalData.global.user && globalData.global.user.image) {
                setImage(globalData.global.user.image.url);
            }
        }
    }, [globalData.global.user])

    const handleVisibleModal = () => {
        setVisibleModal(!visibleModal)
    }

    const handleSubmitResetPassword = () => {
        if (validate()) {

            let errors = {};
            errors["password"] = "";
            errors["rePassword"] = "";

            setShowErrorMsg(errors);

            let data = {
                password: statePassword.password
            }

            update(`/users/${globalData.global.user.id}`, data).then(res => {
                setStateUser({
                    username: res.username,
                    email: res.email
                })
                setVisibleModal(!visibleModal)
                setSweetTimmer(!sweetTimmer)

            }).catch(() => { })
        }
    }

    const validateAddress = () => {
        let isValid = true;

        if (!stateAddress.org_name && !stateAddress.pic_name && !stateAddress.pic_phone_num) {
            isValid = false;
        }

        return isValid
    }

    const validateInvoices = () => {
        let isValid = true;

        if (!stateInvoiceCode.invoice_code) {
            isValid = false;
        }

        return isValid
    }

    const validate = () => {
        let isValid = true;
        let errors = {}

        if (!statePassword.password) {
            isValid = false;
            errors["password"] = "Please enter password.";
        }

        if (!statePassword.rePassword) {
            isValid = false;
            errors["rePassword"] = "Please enter re-type password.";
        }

        if (statePassword.password !== undefined && statePassword.rePassword !== undefined) {
            if (statePassword.password !== statePassword.rePassword) {
                isValid = false;
                errors["rePassword"] = "Re-type password don't match.";
            }
        }

        setShowErrorMsg(errors)
        return isValid
    }

    const handleSubmitAccount = () => {
        let data = {
            username: stateUser.username,
            email: stateUser.email
        }

        update(`/users/${globalData.global.user.id}`, data).then(res => {
            let payload = {
                ...globalData.global,
                user: res,
            }
            setGlobalData({
                type: ACTION_TYPE.GET_USER_DATA,
                payload,
            })
            setStateUser({
                username: res.username,
                email: res.email
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(() => { })
    }

    const handleSubmitAccountInfo = () => {
        if (validateAddress()) {
            setLoading(true)
            if (corporateDataSource && corporateDataSource.id) {
                let data = {
                    org_name: stateAddress.org_name,
                    org_phone_num: stateAddress.org_phone_num,
                    address: {
                        firstname: stateAddress.firstname,
                        lastname: stateAddress.lastname,
                        address1: stateAddress.address1,
                        address2: stateAddress.address2,
                        zipcode: stateAddress.zipcode,
                        city: stateAddress.city,
                        province: stateAddress.province,
                        country: stateAddress.country,
                        email: stateAddress.email,
                    },
                    user: globalData.global.user.id,
                    pic_name: stateAddress.pic_name,
                    pic_phone_num: stateAddress.pic_phone_num,
                    ...corporateDataSource.invoice_code
                };
                update(`/corporate-organisations/${corporateDataSource.id}`, data).then(res => {
                    setLoading(false)
                })
            } else {
                let data = {
                    org_name: stateAddress.org_name,
                    org_phone_num: stateAddress.org_phone_num,
                    address: {
                        firstname: stateAddress.firstname,
                        lastname: stateAddress.lastname,
                        address1: stateAddress.address1,
                        address2: stateAddress.address2,
                        zipcode: stateAddress.zipcode,
                        city: stateAddress.city,
                        province: stateAddress.province,
                        country: stateAddress.country,
                        email: stateAddress.email,
                    },
                    user: globalData.global.user.id,
                    pic_name: stateAddress.pic_name,
                    pic_phone_num: stateAddress.pic_phone_num,
                    ...corporateDataSource.invoice_code
                };
                post(`/corporate-organisations`, data).then(res => {
                    setLoading(false)
                })
            }
        }
    }

    const handleSubmitInvoiceCode = () => {
        setLoading(true)
        if (corporateDataSource && corporateDataSource.id) {
            let data = {
                ...stateInvoiceCode,
                org_name: globalData.global.user.firstname,
                address: {
                    firstname: globalData.global.user.firstname,
                    lastname: globalData.global.user.lastname,
                    email: globalData.global.user.email,
                    ...corporateDataSource.address,

                },
                user: globalData.global.user.id
            };
            update(`/corporate-organisations/${corporateDataSource.id}`, data).then(res => {
                let payload = {
                    ...globalData.global,
                    user: {
                        ...globalData.global,
                        corporate_organisation: res
                    }
                }
                setGlobalData({
                    type: ACTION_TYPE.GET_USER_DATA,
                    payload,
                })
                setCorporateDataSource(res)
                setInvoiceCode({ invoice_code: res.invoice_code })
                setLoading(false)
            })
        } else {
            let data = {
                ...stateInvoiceCode,
                org_name: globalData.global.user.firstname,
                address: {
                    firstname: globalData.global.user.firstname,
                    lastname: globalData.global.user.lastname,
                    email: globalData.global.user.email,
                    ...corporateDataSource.address,

                },
                user: globalData.global.user.id
            };
            post(`/corporate-organisations`, data).then(res => {
                setCorporateDataSource(res)
                setInvoiceCode({ invoice_code: res.invoice_code })
                setLoading(false)
            })
        }
    }

    const handleRemoveSignature = () => {
        if (globalData.global.user && globalData.global.user.doctor && globalData.global.user.doctor.signature) {
            remove(`/upload/files/${globalData.global.user.doctor.signature.id}`).then(removeSign => {
                update(`/doctors/${globalData.global.user.doctor.id}`, { signature: null, is_online: false }).then(async response => {

                    setSignature(null)
                    let payload = {
                        ...globalData.global,
                        signature: null,
                        availability_doctor: false,
                        visibility_menu_sidebar: {
                            ...initialState.global.visibility_menu_sidebar,
                            tele_medicine: false,
                            prescription: {
                                ...initialState.global.visibility_menu_sidebar.prescription,
                                title: false,
                                waitingForApproval: false
                            },
                            setting: {
                                account: true
                            }
                        },
                        user: {
                            ...globalData.global.user,
                            doctor: response
                        },
                    }
                    setGlobalData({
                        type: ACTION_TYPE.GET_USER_DATA,
                        payload,
                    })
                    useLocalStorage.setItem('authUser', JSON.stringify(payload))
                    useLocalStorage.setItem('signature', false)
                    useLocalStorage.setItem('certInfo', false)
                    setSweetTimmer(!sweetTimmer)
                    setLoading(false)
                })
            }).then(err => {
                // console.log(err);
            })
        } else {
            setSignature(null)
        }
    }

    const handleSaveNewSignature = () => {
        let data = signature;
        // console.log(signature);
        let formImg = new FormData();
        // console.log(formImg);
        formImg.append(`files`, data);
        if (uploadImgPreview && data) {
            setLoading(true)
            post(`/upload`, formImg).then((img) => {
                localStorage.setItem('authUser', null)
                update(`/doctors/${globalData.global.user.doctor.id}`, { signature: img, is_online: true }).then(async response => {

                    let payload = {
                        ...globalData.global,
                        availability_doctor: true,
                        signature: response.signature,
                        visibility_menu_sidebar: {
                            ...initialState.global.visibility_menu_sidebar,
                            tele_medicine: true,
                            prescription: {
                                ...initialState.global.visibility_menu_sidebar.prescription,
                                title: true,
                                waitingForApproval: true
                            },
                            setting: {
                                account: true
                            }
                        },
                        user: {
                            ...globalData.global.user,
                            doctor: response
                        },
                    }
                    setGlobalData({
                        type: ACTION_TYPE.GET_USER_DATA,
                        payload,
                    })
                    useLocalStorage.setItem('authUser', JSON.stringify(payload))
                    useLocalStorage.setItem('signature', true)
                    useLocalStorage.setItem('certInfo', true)
                    setSweetTimmer(!sweetTimmer)
                    setLoading(false)
                })
            }).catch(() => { })
        } else {
            setLoading(true)
            update(`/doctors/${globalData.global.user.doctor.id}`, { signature: null }).then(async response => {

                let payload = {
                    ...globalData.global,
                    signature: null,
                    user: {
                        ...globalData.global.user,
                        doctor: response
                    },
                }
                setGlobalData({
                    type: ACTION_TYPE.GET_USER_DATA,
                    payload,
                })
                useLocalStorage.setItem('authUser', JSON.stringify(payload))
                setSweetTimmer(!sweetTimmer)
                setLoading(false)
            })
        }
    }

    const handleRemoveImage = () => {
        if (globalData.global.user && globalData.global.user.image) {
            remove(`/upload/files/${globalData.global.user.image.id}`).then(removeImage => {
                update(`/users/${globalData.global.user.id}`, { image: null }).then(async response => {

                    let payload = {
                        ...globalData.global,
                        image: null,
                    }
                    setGlobalData({
                        type: ACTION_TYPE.GET_USER_DATA,
                        payload,
                    })
                    useLocalStorage.setItem('authUser', JSON.stringify(payload))
                    useLocalStorage.setItem('image', false)
                    setImage(null)
                    setSweetTimmer(!sweetTimmer)
                    setLoading(false)
                    window.location.reload()
                })
            }).then(err => {
                console.log(err);
            })
        } else {
            setImage(null)
            window.location.reload()
        }
    }

    const handleSaveNewImage = () => {
        let data = image;
        // console.log(signature);
        let formImage = new FormData();
        // console.log(formImg);
        formImage.append(`files`, data);
        if (uploadImgPreview && data) {
            setLoading(true)
            post(`/upload`, formImage).then((image) => {
                localStorage.setItem('authUser', null)
                update(`/users/${globalData.global.user.id}`, { image: image, is_online: true }).then(async response => {

                    let payload = {
                        ...globalData.global,
                        image: response.image
                    }
                    setGlobalData({
                        type: ACTION_TYPE.GET_USER_DATA,
                        payload,
                    })
                    useLocalStorage.setItem('authUser', JSON.stringify(payload))
                    useLocalStorage.setItem('image', true)
                    setSweetTimmer(!sweetTimmer)
                    setLoading(false)
                    window.location.reload()
                })
            }).catch(() => { })
        } else {
            setLoading(true)
            update(`/users/${globalData.global.user.id}`, { image: null }).then(async response => {

                let payload = {
                    ...globalData.global,
                    image: null
                }
                setGlobalData({
                    type: ACTION_TYPE.GET_USER_DATA,
                    payload,
                })
                useLocalStorage.setItem('authUser', JSON.stringify(payload))
                setSweetTimmer(!sweetTimmer)
                setLoading(false)
                window.location.reload()
            })
        }
    }

    // useEffect(() => {
    //     const handleUnload = (event) => {
    //         event.preventDefault();
    //         event.returnValue = "Please Do not reload this page! ";
    //     };

    //     window.addEventListener("beforeunload", handleUnload);

    //     return () => {
    //         window.removeEventListener("beforeunload", handleUnload);
    //     };
    // }, []);
    return (
        <React.Fragment>
            <div>
                {globalData.global.role === 'doctor' && (!globalData.global.certInfo) ? <div className='page-content pb-0 text-white mb-4 border-bottom border-light' style={{ paddingTop: 70, backgroundColor: "rgb(252, 185, 44)" }}>
                    <Container fluid>
                        <div className='py-3 d-flex justify-content-between align-items-start'>
                            <i className="ri-error-warning-line text-dark ri-3x"></i>
                            <div className='w-100 ml-3 d-flex justify-content-between'>
                                <div className='w-75'><h5 className='font-weight-bold text-dark'>Complete Certification & Upload Signature to Remove Restrictions</h5>
                                    <p className='mb-0 text-dark'>You are temporarily restricted from performing other features. Please complete your certification registration process and upload a signature before proceeding with this action.</p></div>
                                <div>{globalData.global.certInfo ? null : <Button color='light' className='mt-3' size='sm' onClick={() => setModalCert(!modalCert)}>Register Certificate for Healthcare<i className="ri-arrow-right-line ml-2 align-middle"></i></Button>}</div>
                            </div>
                        </div>
                    </Container>
                </div> : globalData.global.role === 'doctor' && (!globalData.global.signature) ? <div className='page-content pb-0 text-white mb-4 border-bottom border-light' style={{ paddingTop: 70, backgroundColor: "rgb(252, 185, 44)" }}>
                    <Container fluid>
                        <div className='py-3 d-flex justify-content-between align-items-start'>
                            <i className="ri-error-warning-line text-dark ri-3x"></i>
                            <div className='w-100 ml-3 d-flex justify-content-between'>
                                <div className='w-75'><h5 className='font-weight-bold text-dark'>Upload Signature to Remove Restrictions</h5>
                                    <p className='mb-0 text-dark'>You are temporarily restricted from performing other features. Please upload a signature before proceeding with this action.</p></div>
                            </div>
                        </div>
                    </Container>
                </div> : null}

                <div className={globalData.global.role === 'doctor' && (!globalData.global.certInfo) ? "page-content pt-0" : "page-content"}>
                    <Container fluid>

                        <h4 className='mb-3'>{TEXT_ACCOUNT_SETTING}s</h4>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="mt-2 border-bottom">
                                        <form onSubmit={handleSubmitAccount}>
                                            <Row>
                                                <Col md={{ size: 2, offset: 2 }}>
                                                    <h4 className="card-title">{TEXT_YOUR_ACCOUNT}</h4>
                                                </Col>
                                            </Row>
                                            <FormGroup row>
                                                <Label htmlFor="username" md={{ size: 2, offset: 2 }}>Username</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        id="username"
                                                        value={!stateUser.username ? "" : stateUser.username}
                                                        onChange={e => {
                                                            setStateUser({
                                                                ...stateUser,
                                                                username: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="email" md={{ size: 2, offset: 2 }}>Email</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        className="form-control "
                                                        type="email"
                                                        id="email"
                                                        value={!stateUser.email ? "" : stateUser.email}
                                                        onChange={e => {
                                                            setStateUser({
                                                                ...stateUser,
                                                                email: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col md={{ size: 2, offset: 2 }}>
                                                    <Button color="link" className="p-0" onClick={handleVisibleModal}>Reset Password</Button>
                                                </Col>
                                                <Col md={{ size: 5 }}>
                                                    <Button className="float-right" color="primary" onClick={handleSubmitAccount} >Save</Button>
                                                </Col>
                                            </FormGroup>
                                        </form>
                                    </CardBody>

                                    <Modal
                                        isOpen={visibleModal}
                                        toggle={handleVisibleModal}
                                    >
                                        <ModalHeader toggle={handleVisibleModal}>
                                            <div style={{ display: 'flex', alignItems: "center" }}><i className="ri-error-warning-fill mr-2" />Reset Password</div>
                                        </ModalHeader>
                                        <ModalBody>
                                            <FormPassword
                                                handleSubmitResetPassword={handleSubmitResetPassword}
                                                setStatePassword={setStatePassword}
                                                handleVisibleModal={handleVisibleModal}
                                                statePassword={statePassword}
                                                showErrorMsg={showErrorMsg}
                                            />
                                        </ModalBody>
                                    </Modal>


                                    <AlertSuccess
                                        title="Saved!"
                                        setSweetTimmer={setSweetTimmer}
                                        sweetTimmer={sweetTimmer}
                                    />


                                    <CardBody className="mt-2 border-bottom">
                                        <Row>
                                            <Col md={{ size: 2, offset: 2 }}>
                                                <h4 className="card-title">{TEXT_IMAGE_SETTING}</h4>
                                            </Col>
                                        </Row>

                                        <FormGroup row>
                                            <Label htmlFor="image" md={{ size: 2, offset: 2 }}>Image</Label>
                                            <Col md={{ size: 1 }}>
                                                <div className='input-image'>
                                                    {!image ? <ImageUpload uploadImgPreview={uploadImgPreview} row={image} handleAcceptedImage={handleAcceptedImage} setUploadImg={setUploadImg} /> :
                                                        <div>
                                                            {!uploadImgPreview ? <CardImg className="avatar-xl" src={getAPIroot() + image} /> : <CardImg className="avatar-xl" src={uploadImgPreview} />}
                                                        </div>}
                                                </div>
                                            </Col>
                                            <Col md={{ size: 6, offset: 3 }}>
                                                {globalData.global.image ? null : !image ? null : <Button color='primary' className='float-right' onClick={handleSaveNewImage}>{!loading ? 'Save' : 'Loading...'}</Button>}
                                                {image && <Button disabled={loading} color='light' className='mr-2 float-right' onClick={handleRemoveImage}>Remove</Button>}
                                            </Col>
                                        </FormGroup>
                                    </CardBody>

                                    {accessRole.address_setting &&
                                        <CardBody className="mt-2 border-bottom">
                                            <Row>
                                                <Col md={{ size: 2, offset: 2 }}>
                                                    <h4 className="card-title">{TEXT_ADDRESS_SETTING}s</h4>
                                                </Col>
                                            </Row>
                                            <FormGroup row>
                                                <Label htmlFor="address" md={{ size: 2, offset: 2 }}>Label</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={true}
                                                        value={!stateAddress.org_name ? "" : stateAddress.org_name}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="org_name"
                                                        placeholder="Label"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                org_name: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="address" md={{ size: 2, offset: 2 }}>Firstname</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.firstname ? "" : stateAddress.firstname}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="firstname"
                                                        placeholder="Firstname"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                firstname: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="address" md={{ size: 2, offset: 2 }}>Lastname</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.lastname ? "" : stateAddress.lastname}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="lastname"
                                                        placeholder="Lastname"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                lastname: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="address" md={{ size: 2, offset: 2 }}>Email</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.email ? "" : stateAddress.email}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="email"
                                                        id="email"
                                                        placeholder="Email"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                email: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="username" md={{ size: 2, offset: 2 }}>PIC Name</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        id="pic_name"
                                                        placeholder="PIC Name"
                                                        value={!stateAddress.pic_name ? "" : stateAddress.pic_name}
                                                        onChange={e => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                pic_name: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="username" md={{ size: 2, offset: 2 }}>PIC Phone No.</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        className="form-control"
                                                        type="phone"
                                                        id="pic_phone_num"
                                                        placeholder="PIC Phone Number"
                                                        value={!stateAddress.pic_phone_num ? "" : stateAddress.pic_phone_num}
                                                        onChange={e => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                pic_phone_num: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="address" md={{ size: 2, offset: 2 }}>Phone No.</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.org_phone_num ? "" : stateAddress.org_phone_num}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="phone"
                                                        id="org_phone_num"
                                                        placeholder="Phone No."
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                org_phone_num: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="address" md={{ size: 2, offset: 2 }}>Address 1</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.address1 ? "" : stateAddress.address1}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="address1"
                                                        placeholder="Address 1"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                address1: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="address2" md={{ size: 2, offset: 2 }}>Address 2</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.address2 ? "" : stateAddress.address2}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="address2"
                                                        placeholder="Address 2"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                address2: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="zipcode" md={{ size: 2, offset: 2 }}>Postcode</Label>
                                                <Col md={{ size: 2 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.zipcode ? "" : stateAddress.zipcode}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="zipcode"
                                                        placeholder="Postcode"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                zipcode: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                                <Label htmlFor="city" md={{ size: 1 }}>City</Label>
                                                <Col md={{ size: 2 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.city ? "" : stateAddress.city}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="city"
                                                        placeholder="City"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                city: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="state" md={{ size: 2, offset: 2 }}>State</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateAddress.province ? "" : stateAddress.province}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="state"
                                                        placeholder="State"
                                                        onChange={(e) => {
                                                            setStateAddress({
                                                                ...stateAddress,
                                                                province: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col md={{ size: 6, offset: 3 }}>
                                                    <Button className="float-right" disabled={!validateAddress() || loading} color="primary" onClick={handleSubmitAccountInfo}>Save</Button>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>}

                                    {accessRole.invoice_setting &&
                                        <CardBody className="mt-2 border-bottom">
                                            <Row>
                                                <Col md={{ size: 2, offset: 2 }}>
                                                    <h4 className="card-title">Invoice Setting</h4>
                                                </Col>
                                            </Row>
                                            <FormGroup row>
                                                <Label htmlFor="state" md={{ size: 2, offset: 2 }}>Invoice Code</Label>
                                                <Col md={{ size: 5 }}>
                                                    <Input
                                                        disabled={loading ? true : false}
                                                        value={!stateInvoiceCode.invoice_code ? "" : stateInvoiceCode.invoice_code}
                                                        className={!loading ? "form-control" : "form-control input-disabled"}
                                                        type="text"
                                                        id="invoice_code"
                                                        placeholder="Invoice Code"
                                                        onChange={(e) => {
                                                            setInvoiceCode({ invoice_code: e.target.value })
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <Row>
                                                <Col md={{ size: 2, offset: 2 }}>
                                                </Col>
                                                <Col md={{ size: 5 }}>
                                                    <div className='alert alert-info'>
                                                        <p className='mb-0 small'>Example:</p>
                                                        <p className='mb-0'>{`${!stateInvoiceCode.invoice_code ? "" : `${stateInvoiceCode.invoice_code}-`}${padNum}`}</p>
                                                    </div>
                                                </Col>
                                            </Row>


                                            <FormGroup row>
                                                <Col md={{ size: 6, offset: 3 }}>
                                                    <Button className="float-right" disabled={!validateInvoices() || loading} color="primary" onClick={handleSubmitInvoiceCode}>Save</Button>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>}

                                    {/* <CardBody className='mt-2 mb-3'>
                                    <Row>
                                        <Col md={{ size: 7, offset: 2 }}>
                                            <RegisterCertForm />
                                        </Col>
                                    </Row>
                                </CardBody> */}
                                    {!certInfo ? null
                                        // <div>
                                        //     <CardBody className='mt-2 mb-3 border-bottom'>
                                        //         <Row>
                                        //             <Col md={{ size: 2, offset: 2 }}>
                                        //                 <h4 className="card-title">Register Certificate for Healthcare</h4>
                                        //             </Col>
                                        //             <Col md={{ size: 2, offset: 4 }}>
                                        //                 <Button color='primary' onClick={() => {
                                        //                     setModalCert(!modalCert)
                                        //                 }}>Register</Button>
                                        //             </Col>
                                        //         </Row>
                                        //     </CardBody>
                                        // </div> 
                                        :
                                        <div>
                                            <CardBody className='border-bottom'>
                                                <FormGroup row>
                                                    <Col md={{ size: 2, offset: 2 }}>
                                                        <h4 className="card-title">Certificate Information</h4>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col md={{ size: 7, offset: 2 }}>
                                                        <p className='mb-1'>Cert Serial No: {certInfo.certSerialNo}</p>
                                                        <p className='mb-1'>Created Date: {moment(certInfo.certValidFrom).format('DD/MM/YYYY')}</p>
                                                        <p className='mb-1'>Expiry Date: {moment(certInfo.certValidTo).format('DD/MM/YYYY')}</p>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                            {accessRole.signature ?
                                                <CardBody className='mt-2 mb-3'>
                                                    <Row>
                                                        <Col md={{ size: 7, offset: 2 }}>
                                                            {globalData.global.role === 'doctor' && (!globalData.global.signature) ? <div className='pb-0 text-white mb-3' style={{ backgroundColor: "rgba(252, 185, 44, 20%)", border: "1px solid rgba(252, 185, 44, 1)", borderRadius: "1rem" }}>
                                                                <div className='pb-3 pt-2 px-3 d-flex justify-content-between align-items-start'>
                                                                    <div className='w-100 text-dark'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <i className="ri-error-warning-line mr-2 align-middle" style={{ fontSize: "1.4rem" }}></i>
                                                                            <h5 className='font-weight-bold mb-0 w-100' style={{ lineHeight: "1.6rem" }}>Upload Signature to Access Locked Features</h5>
                                                                        </div>
                                                                        <p className='mb-0 text-justify' style={{ lineHeight: "1rem" }}>Kindly upload your signature. Once the signature has been uploaded and saved, you will be able to access locked features. If you experience any issues, please contact customer support for assistance.</p>

                                                                        {globalData.global.certInfo ? null : <Button color='light' className='mt-3' size='sm' onClick={() => setModalCert(!modalCert)}>Register Certificate for Healthcare</Button>}
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                            <h4 className="card-title">Signature</h4>
                                                            <p className='mb-0'>Please upload your signature.</p>
                                                            <p>Your certificate has been successfully activated. You may now proceed with digital signing.</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={{ size: 2, offset: 2 }}>
                                                            <div className='input-signature'>
                                                                <div className='line-signature'></div>
                                                                {!signature ? <FormUpload uploadImgPreview={uploadImgPreview} row={signature} handleAcceptedFiles={handleAcceptedFiles} setUploadImg={setUploadImg} /> :
                                                                    <div>
                                                                        {!uploadImgPreview ? <CardImg className="avatar-md pl-0 my-3" src={getAPIroot() + signature} /> : <CardImg className="avatar-md pl-0 my-3 my-3" src={uploadImgPreview} />}
                                                                    </div>}
                                                            </div>
                                                        </Col>
                                                        <Col md={{ size: 6, offset: 3 }}>
                                                            {globalData.global.signature ? null : !signature ? null : <Button color='primary' className='float-right' onClick={handleSaveNewSignature}>{!loading ? 'Save' : 'Loading...'}</Button>}
                                                            {signature && <Button disabled={loading} color='light' className='mr-2 float-right' onClick={handleRemoveSignature}>Remove</Button>}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                : null}
                                        </div>
                                    }


                                </Card>
                            </Col>
                        </Row>

                    </Container>

                    <ModalRegisterCert
                        visible={modalCert}
                        isClosed={() => {
                            setModalCert(!modalCert)
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AccountPage;

export const FormPassword = ({ handleSubmitResetPassword, setStatePassword, statePassword, handleVisibleModal, showErrorMsg }) => {
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleShowPassword2 = () => {
        setShowPassword2(!showPassword2)
    }
    return (
        <form>
            <div className="pl-3 pr-3">

                <FormGroup row>
                    <Label md={{ size: 4 }}>Password</Label>
                    <Col md={{ size: 8 }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                            <InputGroup>
                                <Input
                                    style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", borderColor: showErrorMsg.password ? "red" : "" }}
                                    className="form-control"
                                    type={showPassword ? "text" : "password"}
                                    value={statePassword.password}
                                    placeholder='Password'
                                    onChange={(e) => {
                                        setStatePassword({
                                            ...statePassword,
                                            password: e.target.value
                                        })
                                    }}
                                />
                            </InputGroup>
                            <i onClick={handleShowPassword} className={!showPassword ? "ri-eye-off-line" : "ri-eye-line"} style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 15, zIndex: 10 }} />
                        </div>
                        <p style={{ color: "red" }}>{showErrorMsg.password}</p>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label md={{ size: 4 }}>Re-type Password</Label>
                    <Col md={{ size: 8 }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                            <InputGroup>
                                <Input
                                    style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", borderColor: showErrorMsg.rePassword ? "red" : "" }}
                                    className="form-control"
                                    type={showPassword2 ? "text" : "password"}
                                    value={statePassword.rePassword}
                                    placeholder='Re-type Password'
                                    onChange={(e) => {
                                        setStatePassword({
                                            ...statePassword,
                                            rePassword: e.target.value
                                        })
                                    }}
                                />
                            </InputGroup>
                            <i onClick={handleShowPassword2} className={!showPassword2 ? "ri-eye-off-line" : "ri-eye-line"} style={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 15, zIndex: 10 }} />
                        </div>
                        <p style={{ color: "red" }}>{showErrorMsg.rePassword}</p>
                    </Col>
                </FormGroup>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="white" className="mr-1" onClick={handleVisibleModal}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmitResetPassword} >Save</Button>
                </div>
            </div>
        </form>
    )
}
