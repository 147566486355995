export const initialDataTable = {
    columns: [
        {
            label: "Discount Name",
            field: "title",
            sort: "asc",
            width: 300
        },
        {
            label: "Items in promo",
            field: "promo",
            sort: "asc",
            width: 200
        },
        {
            label: "Expired On",
            field: "expiredOn",
            sort: "asc",
            width: 200
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 80
        },
    ],
}