import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Card, CardBody, Media, Spinner } from 'reactstrap';

const option = {
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                // max: 100,
                min: 0,
                stepSize: 20,
            }
        }],
        xAxes: [{
            ticks: {
                // max: 100,
                min: 0,
                stepSize: 20,
                maxRotation: 90,
                minRotation: 90,
            }
        }]
    }
}

const BarChartOrder = ({ barData, loading, title }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    })

    useEffect(() => {
        setChartData({
            labels: barData.data_a,
            datasets: [
                {
                    backgroundColor: "rgba(85, 110, 230)",
                    maxBarThickness: 40,
                    data: barData.data_b
                },
            ]
        })
    }, [barData])
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{title}</p>
                            {loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}><Spinner color="primary" size="md" /></div> :
                                <Bar width={474} height={300} data={chartData} options={option} />}
                        </Media>
                    </Media>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default BarChartOrder
