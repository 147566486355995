import React, { useEffect, useMemo, useState } from 'react'
import { Col, FormGroup, Label, Input, Button, CardBody } from 'reactstrap'
import { TEXT_ALTERNATE_PHONE_NO, TEXT_BACK, TEXT_CANCEL, TEXT_EMAIL, TEXT_FRONT, TEXT_GENDER, TEXT_NAME, TEXT_NRIC, TEXT_PASSPORT, TEXT_PHONE_NO, TEXT_RACE, TEXT_RELIGION, TEXT_SAVE } from '../../../../../../Text'
import Select from "react-select";
import { optionGender, OptionHonorific, optionRace, optionReligion } from '../../Store/Option'
import { capitalizeFirstLetter } from "../../../../../../store/CustomFunctions"
import { ACTION_TYPE } from '../../Store/ActionType';
import { update } from '../../../../../../helpers/API';

const RegisterBy = ({ formValues, setFormValues, isEditMode, handleModal, dispatch, dataSource, setSweetTimmer, sweetTimmer, setErrMsg, errMsg }) => {
    const [toggleSwithcNric, setToggleSwitchNric] = useState(false)
    const [temporary, setTemporary] = useState(formValues)

    const toggleCard = useMemo(() => {
        return temporary.booker_info.id_card_type === 'nric' ? true : false
    }, [temporary.booker_info.id_card_type])

    useEffect(() => {
        setToggleSwitchNric(toggleCard)
    }, [toggleCard])

    const handleUpdateForm = (value, propertyName) => {
        setTemporary({
            ...temporary,
            booker_info: {
                ...temporary.booker_info,
                [propertyName]: value
            }
        })
    }

    const handleSubmit = () => {
        let data = {
            booker_info: {
                ...temporary.booker_info
            }
        };


        dispatch({
            type: ACTION_TYPE.ACTION_EDIT_MODE,
            payload: {
                tab: null,
                mode: !dataSource.editMode.mode
            }
        })

        update(`/booking-forms/${formValues.id}`, data).then(res => {
            setFormValues(res)
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: res,
                    loading: false
                }
            })
            setSweetTimmer(!sweetTimmer)
        }).catch(err => {
            setErrMsg({
                visible: !errMsg.visible,
                msg: err
            })
        })
    }

    return (
        <div>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">Title</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.honorific ? "-" : capitalizeFirstLetter(temporary.booker_info.honorific)}</p> :
                        <Select
                            options={OptionHonorific}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Mr'
                            value={!temporary.booker_info.honorific ? "" : { label: capitalizeFirstLetter(temporary.booker_info.honorific), value: temporary.booker_info.honorific }}
                            onChange={e => handleUpdateForm(e.value, 'honorific')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_NAME}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.name ? "-" : capitalizeFirstLetter(temporary.booker_info.name)}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="e.g: John"
                            value={!temporary.booker_info.name ? "" : temporary.booker_info.name}
                            onChange={e => handleUpdateForm(e.target.value, 'name')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">Identification No.</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.id_card_number ? "-" : temporary.booker_info.id_card_number}</p> :
                        <div className="d-flex flex-row align-items-center">
                            <div className="custom-control custom-switch mr-4" dir="ltr">
                                <Input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked={toggleSwithcNric} onChange={(e) => handleUpdateForm(e.target.checked ? 'nric' : 'passport', 'id_card_type')} />
                                <Label className="custom-control-label" htmlFor="customSwitch1">{toggleSwithcNric ? TEXT_NRIC : TEXT_PASSPORT}</Label>
                            </div>

                            <div className="mr-2">
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="e.g: 891212103919"
                                    value={!temporary.booker_info.id_card_number ? "" : temporary.booker_info.id_card_number}
                                    onChange={e => handleUpdateForm('id_card_number', '', e.target.value, '')}
                                />
                            </div>
                            <div>
                                {!temporary.booker_info.id_card_front_file ? null : <Button color="light" disabled={!temporary.booker_info.id_card_front_file} className='mr-2' onClick={() => handleModal(temporary.booker_info.id_card_front_file)}><i className="ri-eye-fill mr-2 align-middle" />{TEXT_FRONT}</Button>}
                            </div>
                            <div>
                                {!temporary.booker_info.id_card_back_file ? null : <Button color="light" disabled={!temporary.booker_info.id_card_back_file} onClick={() => handleModal(temporary.booker_info.id_card_back_file)}><i className="ri-eye-fill mr-2 align-middle" />{TEXT_BACK}</Button>}
                            </div>
                            {/* <div>
                            <Button className="mr-2" color="light">
                                <input type="file" onChange={(e) => handleUploadImg(e.target.files, TEXT_FRONT)} className="input-hide" />
                                <i className=" ri-attachment-2 mr-2 align-middle" />
                                {TEXT_FRONT}
                            </Button>
                            <Button color="light">
                                <input type="file" onChange={(e) => handleUploadImg(e.target.files, TEXT_BACK)} className="input-hide" />
                                <i className=" ri-attachment-2 mr-2 align-middle" />
                                {TEXT_BACK}
                            </Button>
                        </div> */}
                        </div>}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_GENDER}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.gender ? "-" : capitalizeFirstLetter(temporary.booker_info.gender)}</p> :
                        <Select
                            options={optionGender}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Male'
                            value={!temporary.booker_info.gender ? "" : { label: capitalizeFirstLetter(temporary.booker_info.gender), value: temporary.booker_info.gender }}
                            onChange={e => handleUpdateForm(e.value, 'gender')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_RACE}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.race ? "-" : capitalizeFirstLetter(temporary.booker_info.race)}</p> :
                        <Select
                            options={optionRace}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Malay'
                            value={!temporary.booker_info.race ? "" : { label: capitalizeFirstLetter(temporary.booker_info.race), value: temporary.booker_info.race }}
                            onChange={e => handleUpdateForm(e.value, 'race')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_RELIGION}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.religion ? "-" : capitalizeFirstLetter(temporary.booker_info.religion)}</p> :
                        <Select
                            options={optionReligion}
                            classNamePrefix="select2-selection"
                            placeholder='e.g: Muslim'
                            value={!temporary.booker_info.religion ? "" : { label: capitalizeFirstLetter(temporary.booker_info.religion), value: temporary.booker_info.religion }}
                            onChange={e => handleUpdateForm(e.value, 'religion')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_PHONE_NO}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info ? "-" : temporary.booker_info.phone}</p> :
                        <Input
                            className="form-control"
                            type="tel"
                            placeholder="e.g: 0133101212"
                            defaultValue={!temporary.booker_info.phone ? "" : temporary.booker_info.phone}
                            onChange={e => handleUpdateForm(e.target.value, 'phone')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_ALTERNATE_PHONE_NO}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.alt_phone ? "-" : temporary.booker_info.alt_phone}</p> :
                        <Input
                            className="form-control"
                            type="tel"
                            placeholder="e.g: 0133101212"
                            defaultValue={!temporary.booker_info.alt_phone ? null : temporary.booker_info.alt_phone}
                            onChange={e => handleUpdateForm(e.target.value, 'alt_phone')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_EMAIL}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.email ? "-" : temporary.booker_info.email}</p> :
                        <Input
                            className="form-control"
                            type="email"
                            placeholder="e.g: john@example.com"
                            value={!temporary.booker_info.email ? "" : temporary.booker_info.email}
                            onChange={e => handleUpdateForm(e.target.value, 'email')}
                        />}
                </Col>
            </FormGroup>
            {/* <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_PATIENT_DIAGNOSIS}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.patient_diagnosis ? "-" : temporary.booker_info.patient_diagnosis}</p> :
                        <textarea
                            className="form-control"
                            type="text"
                            placeholder="Describe your condition"
                            value={!temporary.booker_info.patient_diagnosis ? "" : temporary.booker_info.patient_diagnosis}
                            onChange={e => handleUpdateForm(e.target.value, 'patient_diagnosis')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label className="col-md-3 col-form-label">{TEXT_HEIGHT}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.height ? "-" : temporary.booker_info.height + 'cm'}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="in centimeter"
                            value={!temporary.booker_info.height ? "" : temporary.booker_info.height}
                            onChange={e => handleUpdateForm(e.target.value, 'height')}
                        />}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="validateWeight" className="col-md-3 col-form-label">{TEXT_WEIGHT}</Label>
                <Col md={9}>
                    {isEditMode.tab !== 'RegisterBy' ?
                        <p className="col-form-label text-mute">{!temporary.booker_info.weight ? "-" : temporary.booker_info.weight + 'kg'}</p> :
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="in kilogram"
                            value={!temporary.booker_info.weight ? "" : temporary.booker_info.weight}
                            onChange={e => handleUpdateForm(e.target.value, 'weight')}
                        />}
                </Col>
            </FormGroup> */}


            {isEditMode.tab === 'RegisterBy' &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" onClick={handleSubmit} color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            setTemporary(formValues)
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>}
        </div>
    )
}

export default RegisterBy
