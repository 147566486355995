import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Badge, Card, CardBody, Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import { capitalizeFirstLetter } from '../../../store/CustomFunctions'
import { initialDataTableApproval2, initialValue, waitingApprovalNav } from '../Store/initialState'
import classNames from 'classnames';
import { GlobalContext } from '../../../store/PageWrapper/GlobalContext';
import { PrescriptionContext } from '../../../store/prescriptions/PrescriptionData';
import { FindLatestStatus } from '../Store/CustomFunction';
import moment from 'moment';
import ServerSidePaginations from '../../../components/Table/ServerSidePaginations';
import AlertError from '../../CustomComponent/Alert/AlertError';
import { countPrescription, getPrescription } from '../../../store/prescriptions/_api';
// import { get } from '../../../helpers/API';
import { ACTION_TYPE } from '../../../store/prescriptions/ActionType';
import qs from 'qs'

const ApprovalContent = ({ goToPage }) => {
    const { globalData } = useContext(GlobalContext)
    const { dispatch } = useContext(PrescriptionContext)
    const [initialParams, setInitialParams] = useState(initialValue);
    const [activeTab, setActiveTab] = useState('waiting_for_approval')
    const [count, setCount] = useState({
        approved: 0,
        rejected: 0,
        waiting_for_approval: 0
    })
    const [dataSource, setDataSource] = useState(initialDataTableApproval2)
    const [errMsg, setErrMsg] = useState({
        title: '',
        msg: '',
        visible: false
    })

    const memorizedRole = globalData.global.role
    const memorizedUser = globalData.global.user
    const memorizedDispatch = useCallback(dispatch, [])

    const medicSupplyStatusTemplete = (data) => {
        const filterItem = data.item.filter(item => item.amount !== item.supply_amount);
        const filterItemNotYetSupply = data.item.filter(item => item.supply_amount === 0);
        const statusStyle = (value) => {
            switch (value) {
                case "partially_supplied":
                    return {
                        classStyle: "badge badge-pill badge-soft-warning font-size-12",
                        text: capitalizeFirstLetter("partially supplied")
                    }
                case "fully_supplied":
                    return {
                        classStyle: "badge badge-pill badge-soft-success font-size-12",
                        text: capitalizeFirstLetter("fully supplied")
                    }
                default:
                    return {
                        classStyle: "badge badge-pill badge-soft-danger font-size-12",
                        text: capitalizeFirstLetter("unsupplied")
                    }
            }
        }
        return <div
            className={statusStyle((filterItemNotYetSupply.length === data.item.length) || (data.item.length === 0) ?
                "unsupplied" : filterItem.length > 0 ? 'partially_supplied' : 'fully_supplied').classStyle}>
            {statusStyle((filterItemNotYetSupply.length === data.item.length) || (data.item.length === 0) ?
                "unsupplied" : filterItem.length > 0 ? 'partially_supplied' : 'fully_supplied').text}
        </div>
    }

    const statusTemplete = (data) => {
        const badgeClass = (value) => {
            if (value.includes('waiting for approval')) {
                return {
                    classStyle: "badge badge-pill badge-soft-warning font-size-12",
                    text: capitalizeFirstLetter(value)
                }
            } else if (value.includes('rejected')) {
                return {
                    classStyle: "badge badge-pill badge-soft-danger font-size-12",
                    text: capitalizeFirstLetter('Further Investigation')
                    // text: capitalizeFirstLetter(value)
                }
            } else if (value.includes('approved')) {
                return {
                    classStyle: "badge badge-pill badge-soft-success font-size-12",
                    text: capitalizeFirstLetter(value)
                }
            };
        }
        return (
            <div>
                {data.status && <div className={badgeClass(data.status).classStyle}>
                    {badgeClass(data.status).text}
                </div>}
                {data.doctor && <div><h6 className='mt-2 mb-0'>{capitalizeFirstLetter(data.doctor.name)}</h6></div>}
                {data.doctor && <div><p className='small'>{moment(data.doctor.updatedAt).format("MMM DD, YYYY h:mm A")}</p></div>}
            </div>
        )
    }

    const fetchPrescriptionData = useCallback(async (
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords,
        isMounted
    ) => {
        if (!memorizedUser && !memorizedRole) {
            setDataSource(prev => {
                return {
                    ...prev,
                    rows: []
                }
            })
            return;
        }

        let arrayQuery = [];

        setDataSource(prev => {
            return {
                ...prev,
                rows: [{
                    index: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                }]
            }
        })

        const query = qs.stringify({
            _start: page === 0 ? page : (page * 10) - 10,
            _sort: sortBy
        })

        const count = await countPrescription(query);

        if (memorizedRole === 'doctor') {
            arrayQuery.push({ consultation_null: true }, { customer_null: false }, { doctor_null: false }, { doctor: memorizedUser.doctor.id })
        } else {
            arrayQuery.push({ consultation_null: true }, { customer_null: false })
        }

        const query2 = qs.stringify({
            _start: page === 0 ? page : (page * 10) - 10,
            _sort: sortBy,
            _limit: count,
            _where: arrayQuery
        })

        const response = await getPrescription(query2);

        if (!response.length) {
            setDataSource(prev => {
                return {
                    ...prev,
                    rows: []
                }
            })
            return
        }

        const rows = response.map((element, index) => {
            return {
                index: index + 1,
                prescription_data: element,
                prescription_id: element.id,
                prescription_name: element.prescription_name,
                patient_id: element.customer.id,
                patient_name: !element.customer.full_name ? element.full_name : element.customer.full_name,
                status: FindLatestStatus('prescription_status', element.activity_statuses),
                doctor_name: element.doctor,
                date_updated: moment(element.updatedAt).format("MMM DD, YYYY h:mm A"),
                supply_status: element.prescription_items
            }
        })

        //         if (rows.length > 0) {
        let approvedData = rows.filter(item => item.status === 'approved');
        let rejectedData = rows.filter(item => item.status === 'rejected');
        let waitingForApproval = rows.filter(item => item.status === 'waiting for approval');

        setCount({
            approved: approvedData.length,
            rejected: rejectedData.length,
            waiting_for_approval: waitingForApproval.length
        })

        let filterData = activeTab === "waiting_for_approval" ? waitingForApproval : activeTab === "approved" ? approvedData : rejectedData;
        setDataSource(prev => {
            return {
                ...prev,
                rows: filterData.map((item, index) => {
                    return {
                        index: index + 1,
                        patient_name: <div>
                            <p className='small m-0 text-muted'>{item.prescription_name}</p>
                            <h6>{item.patient_name}</h6>
                        </div>,
                        status: statusTemplete({
                            status: item.status,
                            doctor: item.doctor_name
                        }),
                        date_updated: item.date_updated,
                        supply_status: medicSupplyStatusTemplete({
                            item: item.supply_status
                        }),
                        clickEvent: () => {
                            memorizedDispatch({
                                type: ACTION_TYPE.RESET_STATE
                            })
                            let url = `/e-prescription/waiting-approval/${item.patient_id}/prescription/${item.prescription_id}`
                            goToPage.push({
                                pathname: url,
                            })
                        }
                    }
                })
            }
        })

        setInitialParams({
            sortBy,
            orderBy,
            limit,
            total: filterData.length,
            page,
            category,
            keywords
        })
    }, [
        memorizedDispatch,
        memorizedRole,
        memorizedUser,
        activeTab,
        goToPage
    ])

    useEffect(() => {
        let isMounted = true;

        fetchPrescriptionData(
            initialValue.sortBy,
            initialValue.orderBy,
            initialValue.limit,
            initialValue.page,
            initialValue.total,
            initialValue.category,
            initialValue.keywords,
            isMounted
        )

        return () => {
            // console.log('clean up');
            isMounted = false
        }
    }, [fetchPrescriptionData])

    return (
        <React.Fragment>
            <h4 className='mb-4'>{`List`}</h4>

            <Card className='card'>
                <CardBody className="pt-0">
                    <Nav tabs className="nav-tabs-custom mb-4">
                        {waitingApprovalNav.map((item, index) => {
                            return (
                                <NavItem key={index}>
                                    <NavLink onClick={() => {
                                        setActiveTab(item.key);
                                    }}
                                        className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3 align-middle")}
                                    >
                                        {capitalizeFirstLetter(item.name)}
                                        {<Badge
                                            color="light"
                                            pill
                                            className='align-middle ml-2'
                                        >
                                            {count[item.key]}
                                        </Badge>}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>

                    <ServerSidePaginations
                        loading={false}
                        dataSource={dataSource}
                        getItems={(params) => { }}
                        obj={{}}
                        emptyMessage="There are no provider yet"
                        initialParams={initialParams}
                        setLoading={() => { }}
                    />
                </CardBody>
            </Card>
            <AlertError
                title={errMsg.title}
                setErrMsg={setErrMsg}
                errMsg={errMsg}
            />
        </React.Fragment>
    )
}

export default ApprovalContent