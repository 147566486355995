import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

const AlertSuccess = ({ setSweetTimmer, sweetTimmer, title }) => {
    return (
        sweetTimmer && <SweetAlert
            title={title}
            success
            timeout={800}
            showConfirm={false}
            onConfirm={() => setSweetTimmer(!sweetTimmer)}
        />
    )
}

export default AlertSuccess
