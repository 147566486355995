import React, { useEffect, useState } from 'react'
import { Card, CardBody, Media } from 'reactstrap'
import { TEXT_GENERAL_CARE_AIDE, TEXT_NURSE, TEXT_PHYSIOTHERAPIST } from '../../Text';

// Chartjs
import { Bar } from 'react-chartjs-2';
import Spinner from 'reactstrap/lib/Spinner';

const initialData = {
    labels: [TEXT_NURSE, "Medical Assistant", TEXT_PHYSIOTHERAPIST, TEXT_GENERAL_CARE_AIDE],
    datasets: [
        {
            label: "Sales Analytics",
            backgroundColor: [
                "rgba(242, 201, 76, 1)",
                "rgba(45, 156, 219, 1)",
                'rgba(187, 107, 217, 1)',
                'rgba(111, 207, 151, 1)'
            ],
            // borderColor: "rgba(52, 195, 143, 0.8)",
            borderWidth: 0.5,
            hoverBackgroundColor: [
                "rgba(242, 201, 76, 0.8)",
                "rgba(45, 156, 219, 0.8)",
                'rgba(187, 107, 217, 0.8)',
                'rgba(111, 207, 151, 0.8)'

            ],
            // hoverBorderColor: "rgba(52, 195, 143, 0.9)",
            barPercentage: 4,
            minbarThickness: 25,
            maxBarThickness: 40,
            data: [0, 0, 0, 0]
        }
    ]
};

const option = {
    scales: {
        xAxes: [{
            ticks: {
                fontSize: 10,
            }
        }],
        yAxes: [{
            beginAtZero: true,
            ticks: {
                max: 80,
                min: 0,
                stepSize: 20
            }
        }]
    },
    legend: {
        display: false,
        position: 'bottom',
        fullWidth: true,

    }
}

const ChartProvider = (props) => {
    const [data, setData] = useState(initialData)

    useEffect(() => {
        if (props.typeOfProvider !== undefined && props.typeOfProvider !== null) {
            let obj = {
                ...initialData,
                datasets: [
                    {
                        ...initialData.datasets[0],
                        data: [props.typeOfProvider.find(x => x.key === "Registered Nurse").value, props.typeOfProvider.find(x => x.key === "Medical Assistant").value, props.typeOfProvider.find(x => x.key === "Physiotherapist").value, props.typeOfProvider.find(x => x.key === "General Care Aide").value]
                    }
                ]
            }
            setData(obj)
        }
    }, [props.typeOfProvider])
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Media>
                        <Media body className="overflow-hidden">
                            <p className="text-truncate font-size-14 mb-2">{props.title}</p>

                            {props.loading ?
                                <div className="d-flex justify-content-center align-items-center" style={{ height: 150 }}><Spinner color="primary" size="sm" /></div> :
                                <Bar width={474} height={300} data={data} options={option} />}

                        </Media>
                    </Media>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default ChartProvider
