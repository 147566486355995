import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getAPIroot, post } from '../../../../helpers/API';
import { capitalizeFirstLetter } from '../../../../store/CustomFunctions';
import { GlobalContext } from '../../../../store/PageWrapper/GlobalContext';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { postUploadImage, putChats } from '../../../../store/prescriptions/_api';
import { ACTION_TYPE } from '../../../../store/prescriptions/ActionType';
import { PrescriptionContext } from '../../../../store/prescriptions/PrescriptionData';
import AlertError from '../../../CustomComponent/Alert/AlertError';
import Header from './ComponentChat/HeaderChat'

const PopupChat = ({ scrollbottom }) => {
    const [selectedFile] = useState('')
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext);
    const { globalData } = useContext(GlobalContext)
    const [showChat, setShowChat] = useState(true);
    const [loading, setLoading] = useState(true);
    const [chatEnded, setChatEnded] = useState(false)
    const [showErr, setShowErr] = useState({
        visible: false,
        msg: ''
    })
    const [modalImg, setModalImg] = useState({
        visible: false,
        url: null,
        name: null
    });
    const [showInputText, setShowInputText] = useState(false)
    const [text, setText] = useState('')
    const [messages, setMessages] = useState([])
    const memorizedUserID = useCallback(globalData.global.user, [])

    const getMessagesUpdate = useMemo(() => {
        const chats = prescriptionDataLayer.chat && prescriptionDataLayer.chat;
        const chat = chats && chats.messages && chats.messages.length > 0;
        const data = chat && chats.messages
        return data
    }, [prescriptionDataLayer.chat])

    useEffect(() => {
        if (getMessagesUpdate.length > 0) {
            setMessages(getMessagesUpdate.map(x => {
                return {
                    ...x,
                    send: false
                }
            }))
            let element = document.getElementById("bottom-container");

            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: "smooth" });
                }, 1000);
            }
        }
    }, [getMessagesUpdate])

    const status = useMemo(() => {
        const chats = prescriptionDataLayer.consultation && prescriptionDataLayer.consultation.status;
        const chat = chats && chats
        return chat
    }, [prescriptionDataLayer.consultation])

    useEffect(() => {
        setShowInputText(status === 'completed' ? false : true)
    }, [status])

    const chat_id = useMemo(() => {
        const chats = prescriptionDataLayer.consultation && prescriptionDataLayer.consultation.chat;
        const chat = chats && chats.id
        return chat
    }, [prescriptionDataLayer.consultation])

    useEffect(() => {
        if (chat_id) return setLoading(false)
    }, [chat_id])

    const handleShowChat = () => {
        setShowChat(!showChat)
        scrollbottom()
    }

    const avatar = (name, bg, image) => {
        if (image) {
            return <img src={`${getAPIroot()}${image.url}`} className="rounded-circle img-thumbnail avatar-sm mr-3" alt="thumbnail" />
        } else {
            if (name) {
                return <div className={`avatar-user avatar-sm mr-2 ${bg}`}>{capitalizeFirstLetter(name[0])}</div>
            } else {
                return <div className={`avatar-user avatar-sm mr-2 ${bg}`}>U</div>
            }
        }
    }

    const handleIcon = (iconFormat, url, name) => {
        let icon = null
        if (iconFormat === ".pdf" || iconFormat.includes('doc') || iconFormat.includes('docx') || iconFormat.includes('ppt') || iconFormat.includes('xlsx') || iconFormat.includes('csv') || iconFormat.includes('txt') || iconFormat.includes('json')) {
            switch (iconFormat) {
                case '.pdf':
                    icon = 'ri-file-pdf-line'
                    break;
                case '.json':
                    icon = 'ri-file-code-line'
                    break;
                case '.xlsx':
                    icon = 'ri-file-excel-2-line'
                    break;
                case '.ppt':
                    icon = 'ri-file-ppt-2-line'
                    break;
                case '.doc':
                    icon = 'ri-file-word-2-line'
                    break;
                case '.docx':
                    icon = 'ri-file-word-2-line'
                    break;
                default:
                    icon = 'ri-file-line'
                    break;
            }
            return <div className='is-cursor cursor-img position-relative'><div className='px-3 text-center bg-white border-top mt-2 text-primary '><i className={`${icon} ri-3x`}></i></div><Button onClick={() => handleDownload(url, name)} className='btn-view m-auto position-absolute btn-circle h-100 w-100 rounded-0 text-primary' style={{ top: 0, opacity: 0.8 }} color='light' outline><i className="ri-download-2-line align-middle mr-1" ></i>Download</Button></div>
        }
    }

    const toggle = (url, name) => {
        setModalImg(prev => {
            return {
                visible: !prev.visible,
                url,
                name
            }
        })
    };

    const sizeFormating = (fileSizeInBytes) => {
        // console.log(fileSizeInBytes);
        var i = -1;
        var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes /= 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

    const handleDownload = async (url, name) => {
        try {
            const image = await fetch(getAPIroot() + url);
            const imageBlog = await image.blob();
            const imageUrl = await URL.createObjectURL(imageBlog)

            const anchor = document.createElement('a');
            anchor.href = imageUrl;
            anchor.download = name;

            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)

            URL.revokeObjectURL(imageUrl)
        } catch (error) {
            setShowErr({
                visible: true,
                msg: error
            })
        }
        // document.getElementById('file-link').click();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const messagesArr = [...messages,
        {
            user: memorizedUserID.id,
            text,
            sent_datetime: moment().toISOString(),
            file: null,
            send: true
        }];

        messagesArr.map(x => {
            delete x.send
            return x
        })

        setMessages(prev => {
            return [
                ...prev,
                {
                    user: {
                        id: memorizedUserID.id,
                        username: memorizedUserID.username
                    },
                    text,
                    sent_datetime: moment().toISOString(),
                    file: null,
                    send: true
                }
            ]
        })

        const payload = {
            messages: messagesArr.filter(x => x !== null)
        }

        try {
            setText('')
            scrollbottom()
            const response = await putChats(payload, chat_id);
            await post("/custom/push-chat-messages", {
                chatID: response.id,
                currentMessage: response.messages[response.messages.length - 1],
            });
            dispatch({
                type: ACTION_TYPE.SET_CONSULTATION,
                payload: {
                    data: {
                        ...prescriptionDataLayer.consultation,
                        chat: {
                            ...prescriptionDataLayer.consultation.chat,
                            messages: response.messages
                        },
                    },
                    role: globalData.global.role
                }
            })


        } catch (error) {
            setShowErr({
                visible: true,
                msg: error
            })
            const messagesArr = messages.filter(x => x.id).filter(x => x.user);

            setMessages(messagesArr)
        }
    }

    const handleUpload = () => {
        document.getElementById('uploadFile').click();
    }

    const uploadFile = async (value) => {
        try {
            let messagesArr = messages
            if (value && chat_id) {
                let formImg = new FormData();
                formImg.append(`files`, value);
                const responseImg = await postUploadImage(formImg);

                if (responseImg) {
                    messagesArr.push({
                        user: memorizedUserID.id,
                        text: '',
                        sent_datetime: moment().toISOString(),
                        file: responseImg[0].id,
                    })

                    messagesArr.map(x => {
                        delete x.send
                        return x
                    })

                    const payload = {
                        messages: messagesArr
                    }

                    const response = await putChats(payload, chat_id);
                    if (response) {
                        await post("/custom/push-chat-messages", {
                            chatID: response.id,
                            currentMessage: response.messages[response.messages.length - 1],
                        });
                        dispatch({
                            type: ACTION_TYPE.SET_CONSULTATION,
                            payload: {
                                data: {
                                    ...prescriptionDataLayer.consultation,
                                    chat: {
                                        ...prescriptionDataLayer.consultation.chat,
                                        messages: response.messages
                                    },
                                },
                                role: globalData.global.role
                            }
                        })
                        scrollbottom()
                    }
                }
            }
        } catch (error) {
            setShowErr({
                visible: true,
                msg: error
            })
            const messagesArr = messages.filter(x => x.id);

            setMessages(messagesArr)
        }
    }

    const memorizedMessages = prescriptionDataLayer.chat;

    const handler = useCallback(() => {
        // if (memorizedMessages && memorizedMessages.messages && memorizedMessages.messages.length > 0) {
        //     const filterMsg = memorizedMessages.messages.filter(x => x !== null && x !== undefined).filter(x => x.text !== undefined).map(x => x.text)
        //     const findText = filterMsg.find(x => x.includes("Thank you for choosing us. We are always there to care!"))
        //     if (findText !== undefined && findText !== null) {
        //         setShowChat(false)
        //         setShowInputText(false)
        //         setChatEnded(true)
        //     }
        // }

        if (!memorizedMessages || !memorizedMessages.messages.length) {
            return;
        }

        const filterMsg = memorizedMessages.messages.filter(x => typeof x !== undefined || x !== null)

        if (!filterMsg || !filterMsg.length) {
            return;
        }
        const findText = filterMsg.find(x => x && x.text && x.text.includes("Thank you for choosing us. We are always there to care!"))
        if (!findText) {
            return;
        }
        setShowChat(false)
        setShowInputText(false)
        setChatEnded(true)
    }, [memorizedMessages])

    useEffect(() => {
        handler()
    }, [handler])
    return (
        <div id='chat-popup' className={!showChat ? 'position-absolute popup-chat show hide-from-print' : 'position-absolute popup-chat show hide-from-print'}>
            <div className='position-relative'>
                <div className='header-chat bg-primary' onClick={handleShowChat}>
                    <div className='text-white px-3 w-100'>
                        <div className='d-flex justify-content-between '>
                            <div className='text-truncate'>
                                <Header data={prescriptionDataLayer.patient} />
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className='button-arrow-up'><i className={showChat ? 'ri-arrow-down-s-line align-middle' : 'ri-arrow-up-s-line align-middle'}></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='chat-container' className={!showChat ? 'body-chat border-top' : 'body-chat border-top px-3 py-1'} style={{ height: !showChat ? 0 : 'auto' }}>
                    {loading ? (<div className='d-flex justify-content-center align-items-center text-muted italic p-4'><em>Loading...</em></div>) :
                        memorizedUserID && memorizedUserID.id && messages && messages.length > 0 ?
                            <div>
                                {messages.filter(x => x.id).filter(x => x.user).map((message, index) => {
                                    return message.user.id === memorizedUserID.id ?
                                        <div key={index} className='justify-content-end d-flex w-100 overflow-hidden mb-3'>
                                            {!message.file ? <div className='d-block positio-relative'>
                                                <div className='bubble d-flex justify-content-between align-items-center my-1 w-100 opacity-1'>
                                                    <div>{avatar(message.user.username, 'bg-primary text-white font-weight-bold', message.user.image)}</div>
                                                    <div className='ml-2 w-100 position-relative d-block border border-primary bg-primary shadow-sm rounded py-2 px-3' style={{ minWidth: 200, opacity: message.send ? '.5' : '1' }}>
                                                        <div className='w-100 position-relative d-block'>
                                                            <div className='w-100 d-flex justify-content-between' style={{ fontSize: 10 }}>
                                                                <p className='font-weight-light m-0 text-light mr-3'>{message.send ? 'Sending...' : capitalizeFirstLetter('you')}</p></div>
                                                        </div>
                                                        <div className='font-weight-bold rounded w-100 py-2' style={{ fontSize: 16 }}><p className='text-white m-0'>{message.text}</p></div>
                                                        <div><p className='font-weight-light m-0 text-light text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                    </div>
                                                </div>
                                            </div> :
                                                <div className='d-block positio-relative w-100 h-100'>
                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100'>
                                                        <div>{avatar(message.user.username, 'bg-primary text-white font-weight-bold', message.user.image)}</div>

                                                        <div className='ml-2 w-100 position-relative d-block border bg-primary rounded shadow-sm' style={{ minWidth: 200 }}>
                                                            <div className='w-100 position-relative d-block pt-2 px-3'>
                                                                <div className='w-100 d-flex justify-content-between'>
                                                                    <p className='font-weight-light m-0 text-light mr-3'>{message.send ? 'Sending...' : capitalizeFirstLetter('you')}</p>
                                                                </div>
                                                            </div>
                                                            {message.file && message.file.url && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className='mt-2 is-cursor cursor-img position-relative'><img src={getAPIroot() + message.file.url} alt="" style={{ width: '100%' }} /><Button className='btn-view m-auto position-absolute btn-circle h-100 w-100 rounded-0' style={{ top: 0, opacity: 0.8 }} color='primary' outline onClick={() => toggle(getAPIroot() + message.file.url, message.file.name)}><i className="ri-eye-line align-middle mr-1"></i>View</Button></div> : null}
                                                            {message.file && message.file.ext ? handleIcon(message.file.ext, message.file.url, message.file.name) : null}
                                                            {message.file && message.file.name && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className='d-flex justify-content-between' style={{ backgroundColor: '#3d4ab3' }}><p className='py-2 px-3 text-light small m-0 is-cursor d-inline-block text-truncate' style={{ width: '65%' }}>{message.file.name}</p><p className='py-2 px-3 text-light small m-0 is-cursor text-truncate'>{sizeFormating(message.file.size)}</p></div> : null}
                                                            {message.file && message.file.name && (message.file.ext.includes('pdf') || message.file.ext.includes('docx') || message.file.ext.includes('xlsx') || message.file.ext.includes('json') || message.file.ext.includes('doc') || message.file.ext.includes('txt') || message.file.ext.includes('ppt') || message.file.ext.includes('csv')) ? <div className='bg-light'><a href={getAPIroot() + message.file.url} className='py-2 px-3 text-primary small m-0 is-cursor'>{message.file.name}</a></div> : null}
                                                            {message.text ? <div className='font-weight-bold rounded w-100 px-3 pt-2' style={{ fontSize: 16 }}><p className='text-white m-0 is-cursor'>{message.text}</p></div> : null}
                                                            <div className='py-2 px-3'><p className='font-weight-light m-0 text-light text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div> : <div key={index} className='justify-content-start d-flex w-100 overflow-hidden mb-3'>
                                            {!message.file ?
                                                <div className='d-block positio-relative'>
                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100'>
                                                        <div>{avatar(message.user.username, 'bg-white text-muted font-weight-bold', message.user.image)}</div>
                                                        <div className='ml-2 w-100 position-relative d-block border bg-white rounded shadow-sm py-2 px-3' style={{ minWidth: 200 }}>
                                                            <div className='w-100 position-relative d-block'>
                                                                <div className='w-100 d-flex justify-content-between'>
                                                                    <p className='font-weight-light m-0 text-primary mr-3' style={{ fontSize: 10 }}>{capitalizeFirstLetter(message.user.username)}</p>
                                                                </div>
                                                            </div>
                                                            <div className='font-weight-bold rounded w-100 py-2' style={{ fontSize: 16 }}><p className='text-secondary m-0'>{message.text}</p></div>
                                                            <div><p className='font-weight-light m-0 text-muted text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='d-block positio-relative w-100 h-100'>
                                                    <div className='bubble d-flex justify-content-between align-items-center my-1 w-100'>
                                                        <div>{avatar(message.user.username, 'bg-white text-muted font-weight-bold', message.user.image)}</div>
                                                        <div className='font-weight-bold rounded w-100 py-2' style={{ fontSize: 16 }}><p className='text-secondary m-0'>{message.text}</p></div>
                                                        <div className='ml-2 w-100 position-relative d-block border bg-white rounded shadow-sm' style={{ minWidth: 200 }}>
                                                            <div className='w-100 position-relative d-block pt-2 px-3'>
                                                                <div className='w-100 d-flex justify-content-between'>
                                                                    <p className='font-weight-light m-0 text-primary mr-3' style={{ fontSize: 10 }}>{capitalizeFirstLetter(message.user.username)}</p>
                                                                </div>
                                                            </div>
                                                            {message.file && message.file.url && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className="mt-2 is-cursor cursor-img position-relative"><img src={getAPIroot() + message.file.url} alt="" style={{ width: '100%' }} /><Button className='btn-view m-auto position-absolute btn-circle h-100 w-100 rounded-0' style={{ top: 0, opacity: 0.8 }} color='light' outline onClick={() => toggle(getAPIroot() + message.file.url, message.file.name)}><i className="ri-eye-line align-middle mr-1"></i>View</Button></div> : null}
                                                            {message.file && message.file.ext ? handleIcon(message.file.ext, message.file.url, message.file.name) : null}
                                                            {message.file && message.file.name && (message.file.ext.includes('png') || message.file.ext.includes('jpg') || message.file.ext.includes('jpeg')) ? <div className='bg-light d-flex justify-content-between'><p className='py-2 px-3 text-muted small m-0 is-cursor d-inline-block text-truncate' style={{ width: '65%' }}>{message.file.name}</p><p className='py-2 px-3 text-truncate text-muted small m-0 is-cursor'>{sizeFormating(message.file.size)}</p></div> : null}
                                                            {message.file && message.file.name && (message.file.ext.includes('pdf') || message.file.ext.includes('docx') || message.file.ext.includes('xlsx') || message.file.ext.includes('json') || message.file.ext.includes('doc') || message.file.ext.includes('txt') || message.file.ext.includes('ppt') || message.file.ext.includes('csv')) ? <div className='bg-light  d-flex justify-content-between'><a id='file-link' href={getAPIroot() + message.file.url} className='py-2 px-3 text-primary small m-0 is-cursor d-inline-block text-truncate' style={{ width: '65%' }}>{message.file.name}</a><p className='py-2 px-3 text-muted small m-0 is-cursor'>{sizeFormating(message.file.size)}</p></div> : null}
                                                            {message.text ? <div className='font-weight-bold rounded w-100 px-3 pt-2' style={{ fontSize: 16 }}><p className='text-dark m-0 is-cursor'>{message.text}</p></div> : null}
                                                            <div className='py-2 px-3'><p className='font-weight-light m-0 text-muted text-right' style={{ fontSize: 10 }}>{moment(message.sent_datetime).format('MMM DD, YYYY h:mm A')}</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                })}
                            </div> :
                            <div>
                                <div className='d-flex justify-content-center align-items-center text-center text-light p-4'>
                                    <div><i className="ri-inbox-line align-middle ri-2x"></i>
                                        <p><em>Empty</em></p>
                                    </div>
                                </div>
                            </div>}

                    <div id='bottom-container' style={{ height: 1 }}></div>
                </div>

                {showInputText && showChat ?
                    <div className='footer-chat px-3 py-2'>
                        <div className='rounded border p-2 position-relative d-flex justify-content-between align-items-center w-100'>
                            <Input
                                placeholder='Type something...'
                                type='textarea'
                                value={text}
                                onChange={e => setText(e.target.value)}
                                bsSize='sm'
                                className='rounded border-0'
                                onKeyDown={(event) => {
                                    if (!event.shiftKey && event.keyCode === 13) {
                                        handleSubmit(event);
                                    }

                                    if (event.shiftKey && event.keyCode === 13) {
                                        event.target.value += "\n";
                                        // console.log("Shift + Enter pressed");
                                    }
                                }}
                            />
                            <div className='d-flex'>
                                <Button size='sm' onClick={handleUpload} color='light' className='rounded-circle text-center mr-2' ><i className="ri-attachment-line align-middle ri-1x"></i></Button>
                                <Input
                                    id="uploadFile"
                                    className='d-none'
                                    name="file"
                                    type="file"
                                    value={selectedFile}
                                    onChange={(e) => uploadFile(e.target.files[0])}
                                />
                                <Button size='sm' disabled={!text} onClick={handleSubmit} color='primary' className='rounded-circle text-center' ><i className="ri-send-plane-fill align-middle ri-1x"></i></Button>
                            </div>
                        </div>
                    </div>
                    : null}
                {chatEnded ? <p className='border-top small text-muted px-4 pt-3 text-center'><i>Chat has ended</i></p> : null}
            </div>
            <Modal isOpen={modalImg.visible} size="xl" fade={true} toggle={() => { setModalImg({ visible: false, url: null, name: null }) }}>
                <ModalHeader toggle={() => { setModalImg({ visible: false, url: null, name: null }) }}>{modalImg.name}</ModalHeader>
                <ModalBody className='p-1' style={{ height: 590 }}>
                    <div className='position-relative h-100 d-flex justify-content-center w-100 overflow-hidden rounded'  >
                        <img src={modalImg.url} alt="" height="100%" width="auto" />
                    </div>
                </ModalBody>
            </Modal>

            <AlertError
                setErrMsg={setShowErr} errMsg={showErr} title="Error!"
            />
        </div>
    )
}

export default PopupChat