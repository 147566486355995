import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { ratingUI } from "../../NursePage"

const Feedback = ({
    dataSourceFeedBack,
    setModalFeedback,
    modalFeedback
}) => {
    return (
        <div>
            {!dataSourceFeedBack.feedback_rates &&
                <Button color="primary" onClick={() => setModalFeedback(!modalFeedback)}>Add New Feedback</Button>}
            {dataSourceFeedBack.feedback_rates !== undefined && dataSourceFeedBack.feedback_rates.length > 0 &&
                <Row>
                    <Col md={4}>
                        <p>Customer Comment</p>
                    </Col>
                    <Col md={8}>
                        <p >{dataSourceFeedBack.customer_comment}</p>
                    </Col>
                </Row>
            }

            {dataSourceFeedBack.feedback_rates !== undefined && dataSourceFeedBack.feedback_rates.length > 0 && dataSourceFeedBack.feedback_rates.map((x, i) => {
                return <Row key={i}>
                    <Col md={4}>
                        <p>{x.name}</p>
                    </Col>
                    <Col md={8}>
                        {ratingUI(x.rate)}
                    </Col>
                </Row>
            })}

        </div>
    )
}

export default Feedback
