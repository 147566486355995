import React, { useState } from 'react'
import { Col, Row, Input } from 'reactstrap'
import ApplicationsCount from '../ApplicationsCount'
// import ChartApplicationStatus from './ClinicChartApplicationStatus'
import ClinicChart from '../ClinicChart'
import Select from "react-select"
// import GeneralCareAideCount from '../GeneralCareAideCount'
// import MedicalAssistant from '../MedicalAssistant'
// import NurseCount from '../NurseCount'
// import PhiseotheraphyCount from '../PhiseotheraphyCount'


// const initialData = {
//     labels: [
//         "New",
//         "Approved",
//         "KIV",
//         "Rejected"
//     ],
//     datasets: [
//         {
//             data: [0, 0, 0, 0],
//             backgroundColor: [
//                 "rgba(235, 239, 242, 1)",
//                 "rgba(25, 171, 79, 1)",
//                 'RGBA(74,163,255,1)',
//                 'rgba(255, 61, 96, 1)',
//             ],
//             hoverBackgroundColor: [
//                 "rgba(235, 239, 242, 0.5)",
//                 "rgba(25, 171, 79, 0.5)",
//                 'RGBA(74,163,255,0.5)',
//                 'rgba(255, 61, 96, 1)',
//             ],
//             hoverBorderColor: "rgba(255, 255, 255, 1)"
//         }]
// };


const ClinicContent = ({
    clinic,
    selectedCorporate,
    onCorporateChange,
    startDate,
    endDate,
    onStartChange,
    onEndChange,
    selectedYear,
    onYearChange,
    yearOptions,
    onTypeChange,
    selectedMonth,
    onMonthChange,
    monthOptions
}) => {
    const [selectedOption, setSelectedOption] = useState({ value: 'monthly', label: 'Monthly' });

    const handlePrimarySelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const corporateOptions = [
        { value: null, label: 'All Clinics' },
        ...clinic.corporates.data
    ];

    // console.log(clinic.typeOfProvider.analytics)
    //console.log(selectedCorporate)
    // console.log(selectedYear);
    return (
        <div>
            <h4 className='mb-4'>{`Dashboard - Clinic`}</h4>
            <Row style={{ paddingBottom: '20px' }}>
                <Col md={3}>
                    <label>List of Clinic</label>
                    <div>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={corporateOptions}
                            value={
                                selectedCorporate
                                    ? selectedCorporate
                                    : corporateOptions[0]
                            }
                            onChange={onCorporateChange}
                            placeholder="Select Clinic..."
                        />
                    </div>
                </Col>
                <Col md={4}>
                    <label>Date Range</label>
                    <Row style={{ paddingBottom: '10px' }}>
                        {(selectedOption && selectedOption.value !== 'custom') || (!selectedOption) ? (
                            <Col>
                                <Select
                                    options={yearOptions()}
                                    onChange={onYearChange}
                                    value={
                                        selectedYear
                                            ? selectedYear
                                            : monthOptions()[0]
                                    }
                                    placeholder="Select Year..."
                                />
                            </Col>
                        ) : null}
                        <Col>
                            <Select
                                options={[
                                    { value: 'annual', label: 'Annually' },
                                    { value: 'monthly', label: 'Monthly' },
                                    { value: 'custom', label: 'Custom' },
                                ]}
                                onChange={(selectedType) => {
                                    onTypeChange(selectedType, selectedYear, selectedMonth)
                                    handlePrimarySelectChange(selectedType)
                                }}
                                defaultValue={{ value: 'monthly', label: 'Monthly' }}
                                placeholder="Select Date Type..."
                            />
                        </Col>
                        <Col>
                            {selectedOption && selectedOption.value === 'monthly' && (
                                <Select
                                    options={monthOptions()}
                                    onChange={onMonthChange}
                                    value={selectedMonth}
                                    placeholder="Select Month..."
                                />
                            )}
                        </Col>
                    </Row>
                    {selectedOption && selectedOption.value === 'custom' && (<Row>
                        <Col>
                            <Input
                                type="date"
                                value={startDate}
                                onChange={(event) => onStartChange(event.target.value)}
                            />
                        </Col>
                        <Col>
                            <Input
                                type="date"
                                value={endDate}
                                onChange={(event) => onEndChange(event.target.value)}
                            />
                        </Col>
                    </Row>)}
                </Col>
            </Row>
            <Row>
                <Col md={7}>
                    <ApplicationsCount
                        icon="ri-article-line"
                        title="Total Invoice"
                        count={clinic.totalClinicOrder.total}
                        loading={clinic.totalClinicOrder.loading}
                    />
                </Col>

            </Row>
            <Row>
                <Col md={7}>
                    <ClinicChart
                        title='Total Order'
                        loading={clinic.typeOfProvider.loading}
                        typeOfProvider={clinic.typeOfProvider.analytics}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ClinicContent
