import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";

import "../../Store/SelectCustomStyle.scss";
import { TEXT_CANCEL, TEXT_SAVE } from "../../../../../../Text";
import { ACTION_TYPE } from "../../Store/ActionType";
import AlertSuccess from "../../../../../CustomComponent/Alert/AlertSuccess";

// DatePicker
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";

// Timepicker
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import { get, post, update } from "../../../../../../helpers/API";
import {
  capitalizeFirstLetter,
  converPriceWithCommas,
} from "../../../../../../store/CustomFunctions";
import { optionFee, optionRate } from "../../Store/Option";
import { handleGenerateInvoice } from "../../Store/generateQuotation";

const optionDay = [];

const styles = {
  contentBox: {
    padding: "30px 10px",
    border: "1px solid #eff2f7",
  },
  title: {
    position: "absolute",
    background: "#FFFFFF",
    marginTop: 8,
    top: 0,
    padding: "0px 15px",
    left: 23,
  },
};

for (let i = 0; i < 30; i++) {
  optionDay.push({
    label: i + 1,
    value: i + 1,
  });
}

const Schdule = ({
  formValues,
  isEditMode,
  dispatch,
  dataSource,
  setFormValues,
  sweetTimmer,
  setSweetTimmer,
  setErrMsg,
  errMsg,
  calendarEvents,
  setVisibleModal,
  visibleModal,
  getProviderJobs,
  disableDate,
  selectedStartDate,
  setErrorMsgFormSchedule,
  errorMsgFormSchedule,
  handleUpdateBooking,
  setCustomScheduleRate,
  customScheduleRate,
  totalSelectedDays,
  customScheduleSetting,
  setCustomScheduleSetting,
}) => {
  const [temporary, setTemporary] = useState(formValues);
  const [modalData, setModalData] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    assignTo: "",
  });

  const [providerListData, setProviderListData] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    setModalData((prev) => {
      return {
        ...prev,
        startDate: selectedStartDate,
      };
    });
  }, [selectedStartDate]);

  useEffect(() => {
    getProvider();
  }, []);

  const getProvider = () => {
    get(`/providers?_sort=createdAt:desc`)
      .then((res) => {
        const data = res.map((x) => {
          return {
            label: x.fullname,
            value: x.id,
            data: x,
          };
        });
        setProviderListData(data);
      })
      .catch(() => {});
  };

  const handleSubmitModal = () => {
    let startD = null;
    let endD = null;

    startD = moment(`${modalData.startDate}T${modalData.startTime}`);
    endD = moment(`${modalData.endDate}T${modalData.endTime}`);

    let data = {
      provider: modalData.assignTo.value,
      booking_form: formValues.id,
      type_of_job: "assigned",
      status: "ongoing",
      schedule_start_datetime: startD,
      schedule_end_datetime: endD,
    };

    switch (true) {
      case modalData.startDate === "":
        setErrorMsgFormSchedule("Start Date is required");
        break;
      case modalData.endDate === "":
        setErrorMsgFormSchedule("End Date is required");
        break;
      case modalData.startTime === "":
        setErrorMsgFormSchedule("Start Time is required");
        break;
      case modalData.endTime === "":
        setErrorMsgFormSchedule("End Time is required");
        break;
      default:
        setErrorMsgFormSchedule("");
        break;
    }

    if (
      modalData.startDate !== "" &&
      modalData.startTime !== "" &&
      modalData.endDate !== "" &&
      modalData.endTime !== ""
    ) {
      setVisibleModal(false);
      generateProviderJob(data);
      setModalData({
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        assignTo: "",
      });
    }
  };

  const generateProviderJob = (data) => {
    if (data.provider !== null && data.provider !== undefined) {
      post("/provider-booking-jobs", data).then((res) => {
        if (res) {
          getProviderJobs(temporary, calendarEvents.length);
        }
      });
    }
  };

  const handleSubmit = () => {
    setLoadingSubmit(true);
    let data = {};

    if (
      customScheduleRate.totalHours &&
      customScheduleRate.totalHours.label === "Custom"
    ) {
      data = {
        custom_schedule_rate: !customScheduleRate.totalHours
          ? ""
          : customScheduleRate.totalHours.label,
        custom_schedule_days: !customScheduleRate.totalDays
          ? ""
          : customScheduleRate.totalDays.toString(),
        custom_schedule_custom_rate: {
          days: !customScheduleRate.totalDays
            ? 0
            : customScheduleRate.totalDays,
          hours: !customScheduleRate.totalPerDay
            ? 0
            : customScheduleRate.totalPerDay,
          price_rate: !customScheduleRate.totalPerHours
            ? 0
            : customScheduleRate.totalPerHours,
          locum: !customScheduleRate.locum ? 0 : customScheduleRate.locum,
        },
      };
    } else {
      data = {
        custom_schedule_custom_rate: null,
        custom_schedule_rate: !customScheduleRate.totalHours
          ? ""
          : customScheduleRate.totalHours.label,
        custom_schedule_days: !customScheduleRate.totalDays
          ? ""
          : customScheduleRate.totalDays.toString(),
      };
    }

    if (customScheduleSetting.type.label === "Custom") {
      const obj = {
        ...data,
        custom_schedule_fee_setting: {
          type: !customScheduleSetting.type
            ? null
            : customScheduleSetting.type.value,
          processing_fee: !customScheduleSetting.processing_fee
            ? 0
            : customScheduleSetting.processing_fee,
          deposit_fee: !customScheduleSetting.deposit_fee
            ? 0
            : customScheduleSetting.deposit_fee,
          transportation_fee: !customScheduleSetting.transportation_fee
            ? 0
            : customScheduleSetting.transportation_fee,
        },
      };
      data = obj;
    } else {
      const obj = {
        ...data,
        custom_schedule_fee_setting: {
          type: "default",
          processing_fee: 0,
          deposit_fee: 0,
          transportation_fee: 0,
        },
      };
      data = obj;
    }

    update(`/booking-forms/${formValues.id}`, data)
      .then((res) => {
        const scheduleObj = {
          totalDays: !res.custom_schedule_days
            ? null
            : parseInt(res.custom_schedule_days),
          totalBills: !res.custom_schedule_rate
            ? 0
            : optionRate.find((x) => x.label === res.custom_schedule_rate)
                .price,
          totalHours: !res.custom_schedule_rate
            ? 0
            : optionRate.find((x) => x.label === res.custom_schedule_rate),
          totalPerDay:
            res.custom_schedule_custom_rate === null
              ? 0
              : res.custom_schedule_custom_rate.hours,
          totalPerHours:
            res.custom_schedule_custom_rate === null
              ? 0
              : res.custom_schedule_custom_rate.price_rate,
          locum:
            res.custom_schedule_custom_rate === null
              ? 0
              : res.custom_schedule_custom_rate.locum,
        };

        const settingObj = {
          type: !res.custom_schedule_fee_setting
            ? null
            : optionFee.find(
                (x) => x.value === res.custom_schedule_fee_setting.type
              ),
          processing_fee: !res.custom_schedule_fee_setting
            ? 0
            : res.custom_schedule_fee_setting.processing_fee,
          deposit_fee: !res.custom_schedule_fee_setting
            ? 0
            : res.custom_schedule_fee_setting.deposit_fee,
          transportation_fee: !res.custom_schedule_fee_setting
            ? 0
            : res.custom_schedule_fee_setting.transportation_fee,
        };

        generateQuotation(
          handleGenerateInvoice(res, scheduleObj, settingObj),
          res
        );

        setFormValues(res);

        setTemporary((prev) => {
          return {
            ...prev,
            ...data,
          };
        });

        setCustomScheduleSetting(settingObj);
        setCustomScheduleRate(scheduleObj);

        dispatch({
          type: ACTION_TYPE.ACTION_UPDATE_FORM,
          payload: {
            data: res,
            loading: false,
          },
        });
        // setSweetTimmer(!sweetTimmer)
      })
      .catch((err) => {
        setLoadingSubmit(false);
        setErrMsg({
          visible: !errMsg.visible,
          msg: err,
        });
      });
  };

  const generateQuotation = (pdfData, source) => {
    const currentDate = moment().format("YYYYMMDD");

    let copy_data = {
      ...pdfData,
      invoice_number: `Quotation${currentDate}-${source.invoices.length}`,
      status: null,
    };

    delete copy_data.days;
    delete copy_data.file_name;

    post("/invoices", copy_data)
      .then(() => {
        let payload = {
          booking_mode: "provider",
          booking_status: "pending",
        };
        dispatch({
          type: ACTION_TYPE.ACTION_EDIT_MODE,
          payload: {
            tab: null,
            mode: !dataSource.editMode.mode,
          },
        });
        setLoadingSubmit(false);
        handleUpdateBooking(payload, ACTION_TYPE.ACTION_UPDATE_FORM);
      })
      .catch((err) => {
        setLoadingSubmit(false);
        setErrMsg({
          visible: !errMsg.visible,
          msg: err,
        });
      });
  };
  return (
    <div>
      <div>
        {formValues.schedule_mode === "custom" && (
          <Alert color="warning">
            <i className="mdi mdi-alert-outline mr-2 align-middle" />
            Client requested custom schedule.
          </Alert>
        )}
      </div>

      <div className="pb-4">
        {isEditMode.tab === "Schedule" ? (
          <FormSchedule
            setCustomScheduleRate={setCustomScheduleRate}
            customScheduleRate={customScheduleRate}
            setCustomScheduleSetting={setCustomScheduleSetting}
            customScheduleSetting={customScheduleSetting}
          />
        ) : temporary.schedule_mode !== "custom" ? (
          <Row>
            <Col md={6}>
              <h5 className="font-size-15 text-dark" style={styles.title}>
                RATE FEE
              </h5>
              <div className="product-box">
                <FormGroup row>
                  <Label className="col-md-3 col-form-label">Date</Label>
                  <Col md={9}>
                    {temporary.schedule_start_datetime !== null &&
                    temporary.schedule_end_datetime !== null ? (
                      <p className="mb-0 mt-2">
                        {moment(temporary.schedule_start_datetime).format(
                          "DD MMM YYYY"
                        ) ===
                        moment(temporary.schedule_end_datetime).format(
                          "DD MMM YYYY"
                        )
                          ? moment(temporary.schedule_start_datetime).format(
                              "DD MMM YYYY"
                            )
                          : moment(temporary.schedule_start_datetime).format(
                              "DD MMM YYYY"
                            ) +
                            " to " +
                            moment(temporary.schedule_end_datetime).format(
                              "DD MMM YYYY"
                            )}
                      </p>
                    ) : (
                      <p className="mb-0 mt-2">-</p>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="col-md-3 col-form-label">Time</Label>
                  <Col md={9}>
                    {temporary.schedule_start_datetime !== null &&
                    temporary.schedule_end_datetime !== null ? (
                      <p className="mb-0 mt-2">
                        {moment(temporary.schedule_start_datetime).format(
                          "h:mm A"
                        )}{" "}
                        to{" "}
                        {moment(temporary.schedule_end_datetime).format(
                          "h:mm A"
                        )}
                      </p>
                    ) : (
                      <p className="mb-0 mt-2">-</p>
                    )}
                  </Col>
                </FormGroup>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={6}>
              <h5 className="font-size-15 text-dark" style={styles.title}>
                RATE FEE
              </h5>
              <div className="mt-3" style={styles.contentBox}>
                <FormGroup row>
                  <Label className="col-md-5 offset-1 col-form-label">
                    Rate
                  </Label>
                  <Col md={5} className="offset-right-1">
                    {!temporary.custom_schedule_rate ? (
                      <p className="mb-0 mt-2">-</p>
                    ) : (
                      <p className="mb-0 mt-2">
                        {temporary.custom_schedule_rate}{" "}
                        {customScheduleRate.totalHours.label !== "Custom"
                          ? ""
                          : `(Booking for ${
                              !customScheduleRate.totalDays
                                ? ""
                                : customScheduleRate.totalDays
                            } days, 
                                        ${
                                          !customScheduleRate.totalPerDay
                                            ? ""
                                            : customScheduleRate.totalPerDay
                                        } hours per day)`}
                      </p>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="col-md-5 offset-1 col-form-label">
                    How many days
                  </Label>
                  <Col md={5} className="offset-right-1">
                    <p className="mb-0 mt-2">
                      {!temporary.custom_schedule_days
                        ? 0
                        : temporary.custom_schedule_days}
                    </p>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="col-md-5 offset-1 col-form-label">
                    Total available days
                  </Label>
                  <Col md={5} className="offset-right-1">
                    {
                      <p className="mb-0 mt-2">
                        {!totalSelectedDays
                          ? !temporary.custom_schedule_days
                            ? 0
                            : temporary.custom_schedule_days
                          : (!temporary.custom_schedule_days
                              ? 0
                              : temporary.custom_schedule_days) -
                            totalSelectedDays}
                      </p>
                    }
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="col-md-5 offset-1 col-form-label">
                    Total
                  </Label>
                  <Col md={5} className="offset-right-1">
                    <p className="mb-0 mt-2">
                      {`RM ${
                        !customScheduleRate.totalHours
                          ? "0"
                          : customScheduleRate.totalHours.label !== "Custom"
                          ? converPriceWithCommas(
                              customScheduleRate.totalBills *
                                customScheduleRate.totalHours.totalHours *
                                customScheduleRate.totalDays
                            )
                          : converPriceWithCommas(
                              customScheduleRate.totalPerDay *
                                customScheduleRate.totalPerHours *
                                customScheduleRate.totalDays
                            )
                      }`}
                    </p>
                  </Col>
                </FormGroup>
              </div>
            </Col>
            <Col md={6}>
              <h5 className="font-size-15 text-dark" style={styles.title}>
                SETTING FEE
              </h5>
              <div className="mt-3" style={styles.contentBox}>
                <FormGroup row>
                  <Label className="col-md-5 offset-1 col-form-label">
                    Fee
                  </Label>
                  <Col md={5} className="offset-right-1">
                    <p className="mb-0 mt-2">
                      {!temporary.custom_schedule_fee_setting
                        ? "-"
                        : capitalizeFirstLetter(
                            temporary.custom_schedule_fee_setting.type
                          )}
                    </p>
                  </Col>
                </FormGroup>
                {temporary.custom_schedule_fee_setting &&
                  temporary.custom_schedule_fee_setting.type === "custom" && (
                    <div>
                      <FormGroup row>
                        <Label className="col-md-5 offset-1 col-form-label">
                          Processing fee
                        </Label>
                        <Col md={5} className="offset-right-1">
                          <p className="mb-0 mt-2">
                            {!temporary.custom_schedule_fee_setting
                              ? `RM 0`
                              : `RM ${converPriceWithCommas(
                                  temporary.custom_schedule_fee_setting
                                    .processing_fee
                                )}`}
                          </p>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="col-md-5 offset-1 col-form-label">
                          Deposit fee
                        </Label>
                        <Col md={5} className="offset-right-1">
                          <p className="mb-0 mt-2">
                            {!temporary.custom_schedule_fee_setting
                              ? `RM 0`
                              : `RM ${converPriceWithCommas(
                                  temporary.custom_schedule_fee_setting
                                    .deposit_fee
                                )}`}
                          </p>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label className="col-md-5 offset-1 col-form-label">
                          Transportation fee
                        </Label>
                        <Col md={5} className="offset-right-1">
                          <p className="mb-0 mt-2">
                            {!temporary.custom_schedule_fee_setting
                              ? `RM 0`
                              : `RM ${converPriceWithCommas(
                                  temporary.custom_schedule_fee_setting
                                    .transportation_fee
                                )}`}
                          </p>
                        </Col>
                      </FormGroup>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        )}
      </div>

      {isEditMode.tab === "Schedule" && (
        <CardBody
          className="border-bottom pr-0"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <FormGroup className="mb-2">
            <Button
              className="float-right align-middle"
              type="submit"
              disabled={loadingSubmit}
              onClick={() => {
                handleSubmit();
              }}
              color="primary"
            >
              {loadingSubmit && (
                <Spinner
                  color="light"
                  style={{ height: "1.3rem", width: "1.3rem" }}
                  className="mr-2"
                  size="sm"
                />
              )}
              {TEXT_SAVE}
            </Button>
            <Button
              className="float-right mr-2"
              color="light"
              disabled={loadingSubmit}
              onClick={() => {
                setTemporary(formValues);

                dispatch({
                  type: ACTION_TYPE.ACTION_EDIT_MODE,
                  payload: {
                    tab: null,
                    mode: !dataSource.editMode.mode,
                  },
                });
              }}
            >
              {TEXT_CANCEL}
            </Button>
          </FormGroup>
        </CardBody>
      )}

      <Modal isOpen={visibleModal} toggle={() => setVisibleModal(false)}>
        <ModalHeader toggle={() => setVisibleModal(false)}>
          Set schedule
        </ModalHeader>
        <ModalBody>
          <ModalContent
            providerListData={providerListData}
            setModalData={setModalData}
            modalData={modalData}
            handleSubmitModal={handleSubmitModal}
            disableDate={disableDate}
            selectedStartDate={selectedStartDate}
            errorMsgFormSchedule={errorMsgFormSchedule}
            totalSelectedDays={totalSelectedDays}
            temporary={temporary}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              setVisibleModal(false);
              setModalData({
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                assignTo: "",
              });
              setErrorMsgFormSchedule("");
            }}
            color="light"
            className="waves-effect"
          >
            Close
          </Button>
          <Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={handleSubmitModal}
          >
            {TEXT_SAVE}
          </Button>
        </ModalFooter>
      </Modal>

      <AlertSuccess
        title="Saved!"
        setSweetTimmer={setSweetTimmer}
        sweetTimmer={sweetTimmer}
      />
    </div>
  );
};

export default Schdule;

export const ModalContent = ({
  providerListData,
  setModalData,
  modalData,
  handleSubmitModal,
  disableDate,
  selectedStartDate,
  errorMsgFormSchedule,
  totalSelectedDays,
  temporary,
}) => {
  const format = "h:mm a";
  const [selectedStartDays, setselectedStartDays] = useState(
    moment(selectedStartDate)._d
  );
  const [selectedEndDays, setselectedEndDays] = useState("");
  const [available_date] = useState(
    (!temporary.custom_schedule_days ? 0 : temporary.custom_schedule_days) -
      totalSelectedDays
  );

  const handleOnChange = (value, property) => {
    setModalData({
      ...modalData,
      [property]: value,
    });
  };
  const handleSelectedStartDate = (day, modifiers = {}) => {
    let d = moment(day).format("DD");
    let startD = parseInt(d);
    let d2 = moment(selectedEndDays).format("DD");
    let endD = parseInt(d2);

    if (modifiers.disabled) {
      return;
    }

    let arrNumber = [];
    for (let i = 0; i < available_date; i++) {
      arrNumber.push(i + startD);
    }

    const fil = arrNumber.filter((x) => x === endD);
    if (fil.length === 0) {
      setselectedEndDays("");
    }

    // setselectedDisableSelectedDay(
    //     [{
    //         after: new Date(moment(day).add(available_date - 1, 'days').format('YYYY'), moment(day).add(available_date - 1, 'days').format('MM') - 1, moment(day).add(available_date - 1, 'days').format('DD')),
    //         before: new Date(moment().endOf('year').format('YYYY'), moment().endOf('year').format('MM') - 1, moment().endOf('year').format('DD')),
    //     }]
    // )

    setselectedStartDays(day);
    handleOnChange(moment(day).format("YYYY-MM-DD"), "startDate");
  };

  const handleSelectedEndDate = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    setselectedEndDays(day);
    handleOnChange(moment(day).format("YYYY-MM-DD"), "endDate");
  };

  return (
    <form onSubmit={handleSubmitModal}>
      {errorMsgFormSchedule && (
        <Alert color="danger">
          <i className="mdi mdi-alert-outline mr-2 align-middle" />
          {errorMsgFormSchedule}
        </Alert>
      )}

      <FormGroup row>
        <Label className="col-md-3 col-form-label">Start Date</Label>
        <Col md={9}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <DayPickerInput
              value={selectedStartDays}
              onDayChange={handleSelectedStartDate}
              dayPickerProps={{
                selectedDays: new Date(
                  moment(selectedStartDays).format("YYYY"),
                  moment(selectedStartDays).format("MM") - 1,
                  moment(selectedStartDays).format("DD")
                ),
                disabledDays: [...disableDate],
              }}
            />
            <i
              className="ri-calendar-2-line"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "absolute",
                right: 15,
                zIndex: 2,
              }}
            />
          </div>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label className="col-md-3 col-form-label">End Date</Label>
        <Col md={9}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <DayPickerInput
              value={selectedEndDays}
              onDayChange={handleSelectedEndDate}
              dayPickerProps={{
                selectedDays: new Date(
                  moment(selectedEndDays).format("YYYY"),
                  moment(selectedEndDays).format("MM") - 1,
                  moment(selectedEndDays).format("DD")
                ),
                disabledDays: [
                  ...disableDate,
                  {
                    after: new Date(
                      moment().startOf("year").format("YYYY"),
                      moment().startOf("year").format("MM") - 1,
                      moment().startOf("year").format("DD")
                    ),
                    before: new Date(
                      moment(selectedStartDays).format("YYYY"),
                      moment(selectedStartDays).format("MM") - 1,
                      moment(selectedStartDays).format("DD")
                    ),
                  },
                  {
                    after: new Date(
                      moment(selectedStartDays)
                        .add(available_date - 1, "days")
                        .format("YYYY"),
                      moment(selectedStartDays)
                        .add(available_date - 1, "days")
                        .format("MM") - 1,
                      moment(selectedStartDays)
                        .add(available_date - 1, "days")
                        .format("DD")
                    ),
                    before: new Date(
                      moment().endOf("year").format("YYYY"),
                      moment().endOf("year").format("MM") - 1,
                      moment().endOf("year").format("DD")
                    ),
                  },
                ],
              }}
            />
            <i
              className="ri-calendar-2-line"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "absolute",
                right: 15,
                zIndex: 2,
              }}
            />
          </div>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label className="col-md-3 col-form-label">Start Time</Label>
        <Col md={9}>
          <TimePicker
            className="custom-timepicker"
            showSecond={false}
            use12Hours={true}
            format={format}
            inputIcon={
              !modalData.startTime && (
                <i
                  className="ri-timer-2-line"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    right: 15,
                    top: 9,
                    zIndex: 2,
                  }}
                />
              )
            }
            onChange={(value) =>
              handleOnChange(moment(value).format("HH:mm"), "startTime")
            }
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label className="col-md-3 col-form-label">End Time</Label>
        <Col md={9}>
          <TimePicker
            className="custom-timepicker"
            showSecond={false}
            use12Hours={true}
            format={format}
            inputIcon={
              !modalData.endTime && (
                <i
                  className="ri-timer-2-line"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    right: 15,
                    top: 9,
                    zIndex: 2,
                  }}
                />
              )
            }
            onChange={(value) =>
              handleOnChange(moment(value).format("HH:mm"), "endTime")
            }
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label className="col-md-3 col-form-label">Assign to</Label>
        <Col md={9}>
          <Select
            options={providerListData}
            classNamePrefix="select2-selection"
            placeholder="Assign To"
            value={!modalData.assignTo ? "" : modalData.assignTo}
            onChange={(e) => handleOnChange(e, "assignTo")}
          />
        </Col>
      </FormGroup>
    </form>
  );
};

export const FormSchedule = ({
  customScheduleRate,
  setCustomScheduleRate,
  customScheduleSetting,
  setCustomScheduleSetting,
}) => {
  const [visibleCustomRate, setVisibleCustomRate] = useState(
    customScheduleRate.totalHours === null ||
      customScheduleRate.totalHours === undefined
      ? false
      : customScheduleRate.totalHours.label === "Custom"
      ? true
      : false
  );
  const [visibleSettingFee, setVisibleSettingFee] = useState(
    customScheduleSetting.type === null ||
      customScheduleSetting.type === undefined
      ? false
      : customScheduleSetting.type.label === "Custom"
      ? true
      : false
  );

  const handleRates = (val) => {
    setVisibleCustomRate(val.label === "Custom" ? true : false);
    setCustomScheduleRate((prev) => {
      return {
        ...prev,
        totalBills: val.price,
        totalHours: val,
      };
    });
  };

  const handleType = (val) => {
    setVisibleSettingFee(val.label === "Custom" ? true : false);
    setCustomScheduleSetting((prev) => {
      return {
        ...prev,
        type: val,
      };
    });
  };

    return (
        <div style={{ zIndex: 10 }}>
            <Row>
                <Col md={6}>
                    <div className="mt-3" style={styles.contentBox}>
                        <h5
                            className="font-size-15 text-dark"
                            style={styles.title}>RATE FEE</h5>
                        <dl className="row mb-0">
                            <dt className="col-sm-4 mt-2 offset-1">Rate</dt>
                            <dd className="col-sm-6 offset-right-1">
                                <Select
                                    className="customize-select"
                                    options={optionRate}
                                    classNamePrefix="select2-selection"
                                    placeholder="e.g: 0-4 HRS"
                                    value={customScheduleRate.totalHours}
                                    onChange={val => handleRates(val)}
                                />
                            </dd>
                        </dl>
                        <dl className="row mb-0">
                            <dt className="col-sm-4 mt-2 offset-1">How many days</dt>
                            <dd className="col-sm-6 offset-right-1">
                                <Input
                                    type='number'
                                    placeholder="e.g: 10"
                                    value={!customScheduleRate.totalDays ? "" : customScheduleRate.totalDays}
                                    min={0}
                                    onChange={e => {
                                        setCustomScheduleRate({
                                            ...customScheduleRate,
                                            totalDays: e.target.value
                                        })
                                    }}
                                />
                            </dd>
                        </dl>
                        {visibleCustomRate &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Hours per day</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='number'
                                        placeholder="e.g: 10"
                                        value={!customScheduleRate.totalPerDay ? "" : customScheduleRate.totalPerDay}
                                        onChange={e => {
                                            setCustomScheduleRate({
                                                ...customScheduleRate,
                                                totalPerDay: e.target.value
                                            })
                                        }}
                                    />
                                </dd>
                            </dl>}
                        {visibleCustomRate &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Rate per Hour</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='number'
                                        placeholder="e.g: 10"
                                        value={!customScheduleRate.totalPerHours ? "" : customScheduleRate.totalPerHours}
                                        onChange={e => {
                                            setCustomScheduleRate({
                                                ...customScheduleRate,
                                                totalPerHours: e.target.value
                                            })
                                        }}
                                    />
                                </dd>
                            </dl>}
                        {visibleCustomRate &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Locum rate</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='number'
                                        placeholder="e.g: 10"
                                        value={!customScheduleRate.locum ? "" : customScheduleRate.locum}
                                        onChange={e => {
                                            setCustomScheduleRate({
                                                ...customScheduleRate,
                                                locum: e.target.value
                                            })
                                        }}
                                    />
                                </dd>
                            </dl>}
                        {visibleCustomRate &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Total</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='text'
                                        value={(customScheduleRate.totalHours === null || customScheduleRate.totalHours === undefined) ? '0' :
                                            `RM ${!visibleCustomRate ? converPriceWithCommas(customScheduleRate.totalBills * customScheduleRate.totalHours.totalHours * customScheduleRate.totalDays) :
                                                converPriceWithCommas((customScheduleRate.totalPerDay * customScheduleRate.totalPerHours * customScheduleRate.totalDays))}`}
                                        disabled={true}
                                    />

                                </dd>
                            </dl>}
                    </div>
                </Col>
                <Col md={6}>
                    <div className="mt-3" style={styles.contentBox}>
                        <h5
                            className="font-size-15 text-dark"
                            style={styles.title}>SETTING FEE</h5>
                        <dl className="row mb-0">
                            <dt className="col-sm-4 mt-2 offset-1">Fee</dt>
                            <dd className="col-sm-6 offset-right-1">
                                <Select
                                    className="customize-select"
                                    options={optionFee}
                                    classNamePrefix="select2-selection"
                                    placeholder="e.g: Default"
                                    value={!customScheduleSetting.type ? null : customScheduleSetting.type}
                                    onChange={val => handleType(val)}
                                />
                            </dd>
                        </dl>
                        {visibleSettingFee &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Processing fee</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='number'
                                        placeholder="e.g: 10"
                                        value={!customScheduleSetting.processing_fee ? 0 : customScheduleSetting.processing_fee}
                                        onChange={e => {
                                            setCustomScheduleSetting({
                                                ...customScheduleSetting,
                                                processing_fee: e.target.value
                                            })
                                        }}
                                    />
                                </dd>
                            </dl>}
                        {visibleSettingFee &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Deposit fee</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='number'
                                        placeholder="e.g: 10"
                                        value={!customScheduleSetting.deposit_fee ? 0 : customScheduleSetting.deposit_fee}
                                        onChange={e => {
                                            setCustomScheduleSetting({
                                                ...customScheduleSetting,
                                                deposit_fee: e.target.value
                                            })
                                        }}
                                    />
                                </dd>
                            </dl>}
                        {visibleSettingFee &&
                            <dl className="row mb-0">
                                <dt className="col-sm-4 mt-2 offset-1">Transportation fee</dt>
                                <dd className="col-sm-6 offset-right-1">
                                    <Input
                                        type='number'
                                        placeholder="e.g: 10"
                                        value={!customScheduleSetting.transportation_fee ? 0 : customScheduleSetting.transportation_fee}
                                        onChange={e => {
                                            setCustomScheduleSetting({
                                                ...customScheduleSetting,
                                                transportation_fee: e.target.value
                                            })
                                        }}
                                    />
                                </dd>
                            </dl>}
                    </div>
                </Col>
            </Row>

        </div>
    )
}