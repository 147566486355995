export const initialDataTable = {
    columns: [
        {
            label: "Client",
            field: "client",
            sort: "asc",
            width: 150
        },
        {
            label: "Ref ID",
            field: "refId",
            sort: "asc",
            width: 250
        },
        {
            label: "Booking",
            field: "booking_mode",
            sort: "asc",
            width: 120
        },
        {
            label: "Payment Status",
            field: "financial_status",
            sort: "asc",
            width: 150
        },
        {
            label: "Assigned to",
            field: "assignedTo",
            sort: "asc",
            width: 200
        },
        {
            label: 'Rate',
            field: 'rating',
            sort: 'asc',
            width: 200
        },
        {
            label: "Provider Job Status",
            field: "booking_status",
            sort: "asc",
            width: 200
        },
    ]
}