import React from 'react'
import { TEXT_GENERAL_CARE_AIDE, TEXT_MEDICAL_ASSISTANT, TEXT_NURSE, TEXT_PHYSIOTHERAPIST, TEXT_REGISTRATION, TEXT_REGISTRATION_TYPES } from '../../Text'
import '../Templete/Tables/Styles/customStyle.scss'

const RegistrationType = ({ dataSource, handleViewFile, changeHandler, handleSubmission, selectedFile, checkingFileName }) => {
    return (
        <>
            <dt className="col-sm-3">{TEXT_REGISTRATION_TYPES}</dt>
            <dd className="col-sm-9">{!dataSource.registration_type ? "-" :
                <>

                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{`${TEXT_REGISTRATION} ${TEXT_NURSE.toLowerCase()}`}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_type.is_registered_nurse ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_registered_nurse.loading ? "Uploading..." : checkingFileName(dataSource.registration_type.registered_nurse_file, selectedFile.is_registered_nurse)}</span>
                                {dataSource.registration_type.registered_nurse_file &&
                                    <button
                                        disabled={!dataSource.registration_type.is_registered_nurse}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_type.registered_nurse_file.url, `${TEXT_REGISTRATION} ${TEXT_NURSE.toLowerCase()}`)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_type', 'is_registered_nurse', 'registered_nurse_file', TEXT_REGISTRATION_TYPES)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{TEXT_MEDICAL_ASSISTANT}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_type.is_medical_assistant ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_medical_assistant.loading ? "Uploading..." : checkingFileName(dataSource.registration_type.medical_assistant_file, selectedFile.is_medical_assistant)}</span>
                                {dataSource.registration_type.medical_assistant_file &&
                                    <button
                                        disabled={!dataSource.registration_type.is_medical_assistant}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_type.medical_assistant_file.url, TEXT_MEDICAL_ASSISTANT)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_type', 'is_medical_assistant', 'medical_assistant_file', TEXT_REGISTRATION_TYPES)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{TEXT_PHYSIOTHERAPIST}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_type.is_physiotherapist ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_physiotherapist.loading ? "Uploading..." : checkingFileName(dataSource.registration_type.physiotherapist_file, selectedFile.is_physiotherapist)}</span>
                                {dataSource.registration_type.physiotherapist_file &&
                                    <button
                                        disabled={!dataSource.registration_type.is_physiotherapist}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_type.physiotherapist_file.url, TEXT_PHYSIOTHERAPIST)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_type', 'is_physiotherapist', 'physiotherapist_file', TEXT_REGISTRATION_TYPES)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="pb-2" >
                        <div className="wrapper-text">
                            <span className="title">{TEXT_GENERAL_CARE_AIDE}:</span>
                            <div className="box-1">
                                <span className={!dataSource.registration_type.is_general_care_aide ? "text-muted none" : "text-muted secondary none"}>{selectedFile.is_general_care_aide.loading ? "Uploading..." : checkingFileName(dataSource.registration_type.is_general_care_aide, selectedFile.general_care_aide_file)}</span>
                                {dataSource.registration_type.general_care_aide_file &&
                                    <button
                                        disabled={!dataSource.registration_type.is_general_care_aide}
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() => handleViewFile(dataSource.registration_type.general_care_aide_file.url, TEXT_GENERAL_CARE_AIDE)}>
                                        <i className="ri-eye-line"></i>
                                    </button>}
                            </div>
                            <div className="wrapper">
                                <button className="btn btn-sm btn-light ml-2 btn-upload" onClick={handleSubmission}>
                                    <input type="file" onChange={(e) => changeHandler(e, e.target.files[0].name, 'registration_type', 'is_general_care_aide', 'general_care_aide_file', TEXT_REGISTRATION_TYPES)} className="input-hide" />
                                    <i className=" ri-attachment-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }</dd>
        </>
    )
}

export default RegistrationType
