import React, { useState } from 'react'
import { Col, FormGroup, Label, Input, CardBody, Button } from 'reactstrap'
import { TEXT_CANCEL, TEXT_SAVE } from '../../../../../../../Text'
import { ACTION_TYPE } from '../../../Store/ActionType'
import { update } from '../../../../../../../helpers/API';
import { converPriceWithCommas } from '../../../../../../../store/CustomFunctions';



const styleTextLineThrough = {
    textDecoration: 'line-through',
    opacity: .5
}

const TypeOfService = ({ data, isEditMode, dispatch, dataSource, setFormValues, activeTab, setSweetTimmer, sweetTimmer }) => {
    const [temporary, setTemporary] = useState(data)


    const handleUpdateForm = (value, id, property) => {
        const copyData = temporary.type_of_services.map(item => {
            return {
                ...item,
                custom_price: item.custom_price ? item.custom_price : 0,
            }
        })

        const index = copyData.findIndex(item => item.id === id)
        const item = copyData[index]

        if (property === 'custom_price') {
            let newData = {
                ...item,
                [property]: value,
            }
            copyData.splice(index, 1, { ...item, ...newData })
            copyData.map(item => {
                return {
                    ...item,
                    custom_price: item.is_tick ? parseFloat(item.custom_price) : 0,
                }
            })

            setTemporary({
                ...temporary,
                type_of_services: copyData
            })
        } else {
            let newData = {
                ...item,
                [property]: value,
                service_notes: item.is_tick ? value : "",
                custom_price: !item.is_tick ? parseFloat(item.custom_price) : 0,
            }
            copyData.splice(index, 1, { ...item, ...newData })
            setTemporary({
                ...temporary,
                type_of_services: copyData
            })
        }


    }

    const handleSubmit = () => {
        update(`/booking-forms/${data.id}`, { type_of_services: temporary.type_of_services }).then(res => {
            setFormValues(res)
            dispatch({
                type: ACTION_TYPE.ACTION_UPDATE_FORM,
                payload: {
                    data: res,
                    loading: false
                }
            })
            dispatch({
                type: ACTION_TYPE.ACTION_EDIT_MODE,
                payload: {
                    tab: null,
                    childeTab: null,
                    mode: !dataSource.editMode.mode
                }
            })
        }).catch(err => { })


        setSweetTimmer(!sweetTimmer)
    }
    return (
        <div>{!temporary.type_of_services ? null : temporary.type_of_services.map((item, index) => {
            return (
                !item.service ? null :
                    <FormGroup row key={index}>
                        <Col md={12} className="ml-auto">
                            <div className="mt-4 mt-lg-0 w-100" style={{ minHeight: 44 }}>
                                <div className="custom-control custom-checkbox custom-control-right w-100">
                                    <Input type="checkbox" className="custom-control-input" checked={!item.is_tick ? false : item.is_tick} disabled={isEditMode.childeTab === activeTab ? false : true} onChange={() => false} />
                                    <Label className="custom-control-label w-100"
                                        disabled={isEditMode.childeTab === activeTab ? false : true}
                                        onClick={(event) => {
                                            if (isEditMode.childeTab === activeTab) {
                                                handleUpdateForm(!item.is_tick, item.id, 'is_tick')
                                            }
                                        }}
                                    >{!item.service ? "" : item.service.service}</Label>
                                    {!item.service && item.service.description ? '' : <Label className="w-100 font-size-11 text-muted">{item.service.description}</Label>}
                                    <div className='d-flex mb-2'>
                                        <div className='d-flex  badge badge-pill badge-light'>
                                            <div className='mr-2'><Label style={item.custom_price ? styleTextLineThrough : null} className={!item.custom_price ? "w-100 font-size-11 m-0 text-muted font-weight-bolder" : "w-100 font-size-11 text-muted m-0 font-weight-normal"}>Default Price: RM {item.service.price ? converPriceWithCommas(item.service.price) : converPriceWithCommas(0)}</Label></div>
                                            <div><Label className='font-size-11 m-0'>|</Label></div>
                                            <div className='ml-2'><Label style={!item.custom_price ? styleTextLineThrough : null} className={item.custom_price ? "w-100 font-size-11 m-0 text-muted font-weight-bolder" : "w-100 font-size-11 text-muted m-0 font-weight-normal"}>Custom Price: RM {item.custom_price ? converPriceWithCommas(item.custom_price) : converPriceWithCommas(0)}</Label></div>
                                        </div>
                                    </div>
                                </div>


                                {!isEditMode.mode && item.service.service === 'Home to Hospital Assistance' ?
                                    !item.service_notes ? null : <Label className="w-100 font-size-11 text-muted">Note: {item.service_notes}</Label> :
                                    item.service.service && item.service.service === 'Home to Hospital Assistance' ?
                                        !item.is_tick ? null :
                                            <div >
                                                <Label className='form-label' >Note</Label>
                                                <textarea
                                                    className="form-control mt-2 mb-2"
                                                    type="text"
                                                    disabled={isEditMode.childeTab === activeTab ? false : true}
                                                    rows={3}
                                                    value={item.service_notes}
                                                    onChange={e => handleUpdateForm(e.target.value, item.id, 'service_notes')}
                                                    placeholder={`Note...`} />
                                            </div> :
                                        null
                                }

                                {!isEditMode.mode ? null : !item.is_tick ?
                                    null :
                                    <div >
                                        <Label className='form-label' >Custom Price</Label>
                                        <Input type="number" value={item.custom_price ? converPriceWithCommas(item.custom_price) : converPriceWithCommas(0)} min={0} step={0.01} onChange={e => handleUpdateForm(e.target.value, item.id, 'custom_price')} />
                                    </div>
                                }

                            </div>
                        </Col>
                    </FormGroup>
            )
        })
        }
            {isEditMode.tab === 'Assessment' && isEditMode.childeTab === activeTab &&
                <CardBody className="border-top pr-0">
                    <FormGroup className="mb-2">
                        <Button className="float-right" type="submit" onClick={handleSubmit} color='primary'>{TEXT_SAVE}</Button>
                        <Button className="float-right mr-2" color='light' onClick={() => {
                            setTemporary(data)
                            dispatch({
                                type: ACTION_TYPE.ACTION_EDIT_MODE,
                                payload: {
                                    tab: null,
                                    childeTab: null,
                                    mode: !dataSource.editMode.mode
                                }
                            })
                        }}>{TEXT_CANCEL}</Button>
                    </FormGroup>
                </CardBody>}
        </div>
    )

}

export default TypeOfService
