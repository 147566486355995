import React, { useState, useEffect, useContext } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { GlobalContext } from "./store/PageWrapper/GlobalContext";
import useLocalStorage from "./helpers/useLocalStorage";
import LockScreen from "./LockScreen";

Sentry.init({
	dsn: "https://525e4529b79e5355551bbbdb1bc7b23a@o4505874133942272.ingest.sentry.io/4505874418696192",
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

//Fake backend
// import fakeBackend from './helpers/AuthType/fakeBackend';

const App = (props) => {
	const { globalData } = useContext(GlobalContext)
	const [layout, setLayout] = useState(VerticalLayout);
	const [restricted, setRestricted] = useState([])

	useEffect(() => {
		let certInfo = !useLocalStorage.getItem('certInfo') ? !globalData.global.certInfo ? null : globalData.global.certInfo : useLocalStorage.getItem('certInfo')
		if (!certInfo) {
			useLocalStorage.setItem('lock', true)
		} else {
			useLocalStorage.setItem('lock', false)
		}

	}, [globalData.global.certInfo])

	useEffect(() => {
		const newData = [...authProtectedRoutes];
		if (globalData.global.role === 'doctor') {
			// if (!globalData.global.certInfo || !globalData.global.signature) {
			if (!globalData.global.certInfo) {
				const data1 = newData.filter(x => x.path === '/')
				const data2 = newData.filter(x => x.path.includes('/account'))
				setRestricted([...data2, ...data1])
			} else {
				setRestricted(newData)
			}
		} else {
			setRestricted(newData)
			useLocalStorage.setItem('lock', false)
		}
	// }, [globalData.global.signature, globalData.global.certInfo, globalData.global.role])
	}, [globalData.global.certInfo, globalData.global.role])

	useEffect(() => {
		let layoutCls = VerticalLayout;

		if (props.layout) {
			switch (props.layout.layoutType) {
				case "horizontal":
					layoutCls = HorizontalLayout;
					break;
				default:
					layoutCls = VerticalLayout;
					break;
			}
			setLayout(layoutCls)
		}
	}, [props.layout])
	return (
		<React.Fragment>
			{useLocalStorage.getItem('lock') ? <LockScreen /> :
				<Router>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{restricted.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>))}
					</Switch>
				</Router>}
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps, null)(App);