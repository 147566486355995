import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import {
    apiError,
    loginUserSuccessful,
    // logoutUserSuccess 
} from './actions';

// AUTH related methods
import { postLogin } from '../../../helpers/API';
// import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import useLocalStorage from '../../../helpers/useLocalStorage';

// crypted string
import Cryptr from 'cryptr'

// cookie
import Cookies from 'js-cookie'

//Initilize firebase
// const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.

let PATHNAME;
if (window.location.hostname !== 'localhost') {
    PATHNAME = '/auth/local'
} else {
    // PATHNAME = '/auth/login'
    PATHNAME = '/auth/local'
}

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(postLogin, PATHNAME, { identifier: user.username, password: user.password });
        if (response) {
            const cryptr = new Cryptr('myTotallySecretKey');
            const encryptedString = cryptr.encrypt(response.jwt);

            if (user.rememberMe) {
                Cookies.set('token', encryptedString)
                Cookies.set('name', response.user.username)
                Cookies.set('remember_me', user.rememberMe)
                Cookies.set('email', response.user.email)
                Cookies.set('id', response.user.id)
                Cookies.set('role', response.user.role.type.toLowerCase())
                Cookies.set("image", !response.user.image ? null : response.user.image.url);
            }

            useLocalStorage.setItem("authUser", JSON.stringify(response.user));
            useLocalStorage.setItem("accessToken", response.jwt);
            useLocalStorage.setItem("id", response.user.id);
            useLocalStorage.setItem("image", !response.user.image ? null : response.user.image.url);
            useLocalStorage.setItem("role", response.user.role.type.toLowerCase());
            useLocalStorage.setItem('signature', false)
            useLocalStorage.setItem('certInfo', false)
            useLocalStorage.setItem('certDetails', null)

            yield put(loginUserSuccessful(response));

            if (response.user.role.type === "administrator") {
                history.push('/');
            } else if (response.user.role.type === "corporate_clinic") {
                history.push('/all-invoice');
            } else if (response.user.role.type === "corporate_super_admin") {
                history.push('/all-invoice');
            } else if (response.user.role.type === "corporate_hq") {
                history.push('/all-invoice');
            } else if (response.user.role.type === "corporate_dental") {
                history.push('/all-invoice');
            } else if (response.user.role.type === "corporate_admin") {
                history.push('/all-invoice');
            } else if (response.user.role.type === "corporate_pharmacist") {
                history.push('/order-list');
            } else if (response.user.role.type === "pharmacist") {
                history.push('/e-prescription/patient');
            } else if (response.user.role.type === "customer_n_pharmacist") {
                history.push('/store/products');
            } else if (response.user.role.type === "corporate_admin_n_pharmacist") {
                history.push('/store/products');
            } else if (response.user.role.type === "doctor") {
                useLocalStorage.setItem('lock', true)
                history.push('/tele-medicine');
            } else if (response.user.role.type === "admin_pharmacy") {
                history.push('/account');
            } else {
                yield put(apiError('Not Authorized!'));
                setTimeout(() => {
                    history.push('/logout');
                }, 4000);
            }
        }
    } catch (error) {
        const errMsg = "Error: Connection Failed, We are unable to establish a connection with the server. Please check your network settings and try again. If the problem persists, please contact your system administrator for assistance."
        if (!error.response) return yield put(apiError(errMsg));
        let err = error.response.status;
        if (err === 400) {
            var message = error.response.data.data[0].messages[0].message;
            if (message) {
                yield put(apiError(message));
            }
        }
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        useLocalStorage.clear();

        // if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        //     const response = yield call(fireBaseBackend.logout);
        //     yield put(logoutUserSuccess(response));
        // }

        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default loginSaga;