import React, { createContext, useEffect, useReducer } from 'react'
import { get } from '../../../helpers/API'
import { ACTION_TYPE } from './ActionType'
import { reducer, data } from './Reducer'

export const BookingSettingContext = createContext()


const BookingSettingProvider = (props) => {
    const [dataSource, dispatch] = useReducer(reducer, data)

    useEffect(() => {
        get('/booking-setting').then(response => {
            dispatch({
                type: ACTION_TYPE.GET_BOOKING_SERVICE_PRICING_NURSE,
                payload: response.service_pricing
            });
        })
    }, [])
    return (
        <BookingSettingContext.Provider value={[dataSource, dispatch]}>
            {props.children}
        </BookingSettingContext.Provider>
    )
}

export default BookingSettingProvider