import moment from 'moment'
import React from 'react'
import { Button } from 'reactstrap'

const MedicalCertificate = ({ showModal, dataSource, editMode, status }) => {
  return (
    <React.Fragment>
      <div className='position-relative d-flex justify-content-between align-items-center'>
        <h5>Medical Certificate</h5>
        {dataSource.length === 0 && editMode && <Button color='light' onClick={showModal}>Add<i className="ri-file-line ml-2 align-middle"></i></Button>}
        {dataSource.length > 0 && status === 'accepted' ? <Button color='light' onClick={showModal}>Edit<i className="ri-file-line ml-2 align-middle"></i></Button> : null}
      </div>
      <div>
        {dataSource.map((item, index) => {
          return (
            <div key={index} className='d-flex justify-content-between border mt-3 p-3'>
              <div className='mr-2'><h6><i className="ri-file-list-3-line align-middle ri-2x mr-2"></i></h6></div>
              <div className='w-100'>
                <h6 className='m-0'>Medical Certificate for {item.patient_name}</h6>
                <p className='m-0 small text-muted'>Date: {moment(item.datetime).format('MMM DD, YYYY h:mm A')}</p>

                <div className='mt-2'>
                  <p className='m-0 text-muted'>This is to certify that<b className='text-dark mx-1'>{item.patient_name} (I/C {item.patient_nric})</b>of<b className='text-dark mx-1'>{item.patient_company}</b>will be take<b className='text-dark mx-1'>{item.day_leave_count}</b>day absence for<b className='text-dark mx-1'>{item.reason}</b>starting on<b className='text-dark mx-1'>{moment(item.datetime).format('MMM DD, YYYY')}</b>to<b className='text-dark mx-1'>{moment(item.day_leave_to).format('MMM DD, YYYY')}</b></p>
                  <p className='m-0 text-muted'>Doctor on duty: <b className='m-0 text-dark'>{item.doctor_name}</b></p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default MedicalCertificate