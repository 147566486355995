import React, { useMemo } from 'react'

const Header = ({ data }) => {
  const name = useMemo(() => {
    const fullname = data?.user?.firstname;
    return !fullname ? '' : <span> with <b>{fullname}</b></span>
  }, [data])
  return (
    <div className='text-truncate'><i className="ri-chat-1-line ri-1x mr-2 align-middle"></i>Chat{name}</div>
  )
}

export default Header