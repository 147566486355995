import { ACTION_TYPE } from "./ActionType";

export const showNHideMenu = (_data, _role) => {
    switch (_role) {
        case 'administrator':
            return {
                ..._data,
                dashboard: true,
                manage: true,
                ecommerce: true,
                invoice: true,
                store: true,
                tele_medicine: true,
                // prescription: {
                //     title: true,
                //     patient: true,
                //     createNew: true,
                //     waitingForApproval: true
                // },
                setting: {
                    account: true
                }
            }
        case 'corporate_admin':
            return {
                ..._data,
                invoice: true,
                setting: {
                    account: true
                }
            }
        case 'corporate_clinic':
            return {
                ..._data,
                tele_medicine: false,
                invoice: true,
                ecommerce: true,
                setting: {
                    account: true
                }
            }
        case 'corporate_super_admin':
            return {
                ..._data,
                tele_medicine: false,
                ecommerce: true,
                setting: {
                    account: true
                }
            }
        case 'corporate_hq':
            return {
                ..._data,
                tele_medicine: false,
                invoice: true,
                ecommerce: true,
                setting: {
                    account: true
                }
            }
        case 'corporate_dental':
            return {
                ..._data,
                tele_medicine: false,
                ecommerce: true,
                invoice: true,
                setting: {
                    account: true
                }
            }
        case 'corporate_pharmacist':
            return {
                ..._data,
                tele_medicine: false,
                ecommerce: true,
                invoice: true,
                // prescription: {
                //     title: true,
                //     patient: true,
                //     createNew: true,
                //     waitingForApproval: true
                // },
                setting: {
                    account: true
                }
            }
        case 'pharmacist':
            return {
                ..._data,
                // ecommerce: true,
                // invoice: true,
                store: true,
                // prescription: {
                //     title: true,
                //     patient: true,
                //     createNew: true,
                //     waitingForApproval: true
                // },
                setting: {
                    account: true
                }
            }
        case 'customer_n_pharmacist':
            return {
                ..._data,
                // ecommerce: true,
                // invoice: true,
                tele_medicine: false,
                store: true,
                setting: {
                    account: true
                }
            }
        case 'corporate_admin_n_pharmacist':
            return {
                ..._data,
                tele_medicine: false,
                ecommerce: true,
                invoice: true,
                store: true,
                setting: {
                    account: true
                }
            }
        case 'doctor':
            return {
                ..._data.visibility_menu_sidebar,
                tele_medicine: !_data.certInfo || !_data.signature ? false : true,
                // prescription: {
                //     ..._data.prescription,
                //     title: !_data.certInfo || !_data.signature ? false : true,
                //     waitingForApproval: !_data.certInfo || !_data.signature ? false : true
                // },
                setting: {
                    account: true
                }
            }
        case 'admin_pharmacy': {
            return {
                ..._data.visibility_menu_sidebar,
                appointment: true,
                setting: {
                    account: true
                }
            }
        }
        default:
            return _data
    }
}

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case ACTION_TYPE.UPDATE_DOCTOR_STATUS:
            return {
                ...state,
                global: {
                    ...state.global,
                    user: {
                        ...state.global.user,
                        doctor: {
                            ...state.global.user.doctor,
                            is_online: payload.status
                        }
                    },
                    availability_doctor: payload.status
                }
            }
        case ACTION_TYPE.GET_USER_DATA:
            if (payload.role) {
                return {
                    token: payload.token,
                    global: {
                        ...state.global,
                        user: payload.user,
                        role: payload.role,
                        visibility_menu_sidebar: showNHideMenu(payload, payload.role),
                        image: payload.image,
                        availability_doctor: !payload.user.doctor || !payload.user.doctor.signature ? false : payload.availability_doctor,
                        certInfo: !state.certInfo ? payload.certInfo : state.certInfo,
                        signature: payload.signature
                    }
                }
            } else {
                return {
                    token: payload.token,
                    global: {
                        ...state.global,
                        user: payload.user,
                        image: payload.image,
                        availability_doctor: payload.availability_doctor
                    }
                }
            }

        case ACTION_TYPE.GET_NURSING_MANAGEMENT_COUNT_PROVIDER:
            if (state.global.role === 'administrator') {
                return {
                    ...state,
                    global: {
                        ...state.global,
                        nursing_management: {
                            ...state.global.nursing_management,
                            booking: {
                                nurse: {
                                    count: payload[0]
                                },
                                physio: {
                                    count: payload[1]
                                },
                                medical_assistant: {
                                    count: payload[2]
                                },
                                care_aide: {
                                    count: payload[3]
                                },
                                confinement: {
                                    count: payload[4]
                                }
                            }
                        }
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        case ACTION_TYPE.GET_CONSULTATION_COUNT:
            const permissionRole = state.global.role === 'doctor' || state.global.role === 'administrator';
            if (permissionRole) {
                return {
                    ...state,
                    global: {
                        ...state.global,
                        tele_medicine: {
                            count: payload
                        }
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        case ACTION_TYPE.GET_PROVIDER_COUNT:
            if (state.global.role === 'administrator') {
                return {
                    ...state,
                    global: {
                        ...state.global,
                        nursing_management: {
                            ...state.global.nursing_management,
                            applications: {
                                provider: {
                                    count: payload
                                }
                            }
                        }
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        case ACTION_TYPE.UPDATE_CORPORATE_ORGANISATION:
            return {
                ...state,
                global: {
                    ...state.global,
                    user: {
                        ...state.global.user,
                        corporate_organisation: payload
                    },
                }
            }
        case ACTION_TYPE.UPDATE_NOTIFICATION:
            return {
                ...state,
                global: {
                    ...state.global,
                    notification: {
                        visibility: payload.visibility,
                        list: payload.list
                    },
                }
            }
        default:
            return []
    }
}