import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, Spinner, Input, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, Button, Modal, ModalHeader, ModalBody, Label, FormGroup } from "reactstrap";
import classNames from 'classnames';
import "../../Templete/Tables/Styles/datatables.scss";
import { initialDataTable } from './tableColumn';
import { get } from '../../../helpers/API';
import { capitalizeFirstLetter, converPriceWithCommas, handleStatusStyle } from '../../../store/CustomFunctions';
import moment from 'moment';
import ServerSidePaginations from '../../../components/Table/ServerSidePaginations'
import { TabOrder } from './TabOrder';
import { Link } from 'react-router-dom';
import { exportTableToExcel } from './ExportExcel';
import Select from "react-select";
import { OptionExportStatus, OptionMonth } from './store/Option';
import AlertError from '../../CustomComponent/Alert/AlertError';

let initialValue = {
    sortBy: 'createdAt:desc',
    orderBy: 'all',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}

const contentBody = {
    path: '/store/orders',
    title: "Orders",
    backUrl: "/store/orders",
    filter: "orders"
}

const Order = (props) => {
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    const [progress, setProgress] = useState({
        count: 0,
        text: 'Export'
    });
    const [timer, setTimer] = useState(null);
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(() => false);
    const [initialParams, setInitialParams] = useState(initialValue)
    const [showDropdown, setShowDropdown] = useState(false)
    const [showExportModal, setshowExportModal] = useState(false)
    const [optionYear] = useState([
        {
            label: moment().year() - 1,
            value: moment().year() - 1,
        },
        {
            label: moment().year(),
            value: moment().year(),
        },
    ])
    const [fileItem, setFileItem] = useState({
        nameFile: `Selcare_${moment().format('YYYYMMDD')}`,
        month: OptionMonth.find(x => x.value === month.toString()),
        year: optionYear.find(x => x.value === year),
        status: {
            label: 'All',
            value: 'all'
        }
    })
    const [errMsg, setErrMsg] = useState({
        visible: false,
        msg: ''
    })

    const handleTabFilter = (key) => {
        setActiveTab(key);
        getOrder(
            initialParams.sortBy,
            TabOrder.find(x => x.key === key).value,
            initialParams.limit,
            0,
            initialParams.total,
            initialParams.category,
            initialParams.keywords,
        )
    }

    const downloadOrder = async () => {
        setshowExportModal(!showExportModal)

        const covertFormatMonth = (value) => {
            if (value < 10) {
                return '0' + value
            } else return value
        }

        let params = ""
        let url = new URLSearchParams(params)

        const date = new Date();
        const year = fileItem.year.value
        const month = fileItem.month.value;
        let currentMonth = (date.getMonth() + 1)

        url.set('_sort', 'createdAt:desc')
        if (fileItem.status.value !== "all") {
            url.set('financial_status', fileItem.status.value)
        }

        let startOfMonth = moment([year, month - 1]).format()

        let endOfMonth = moment(startOfMonth).clone().endOf('month').format()

        url.set('_limit', '-1')
        url.set('createdAt_gte', startOfMonth)
        url.set('createdAt_lte', endOfMonth)

        const orders = await get(`/orders?${url.toString()}`)
        if (orders.length > 0) {
            var tempValue = orders.length
            var currentProgress = tempValue
            currentProgress = 100 / tempValue;

            for (let i = 0; i < orders.length; i++) {
                setProgress(prev => {
                    return {
                        count: (prev.count + currentProgress),
                        text: `Downloading ${parseInt((prev.count + currentProgress))}%`
                    }
                })
                if (orders[i].line_item && orders[i].line_item.length > 0) {
                    for (let j = 0; j < orders[i].line_item.length; j++) {
                        await get(`/products/${orders[i].line_item[j].product_id}`).then(product => {
                            if (product !== undefined) {

                                if (product.categories && product.categories.length > 0) {
                                    let arr = product.categories.map(x => { return x.title })
                                    orders[i].line_item[j].categories = arr.toString()
                                } else {
                                    orders[i].line_item[j].categories = "-"
                                }
                            }
                        }).catch(err => {
                            orders[i].line_item[j].categories = "-"
                        })
                    }
                }
            }

            const newArray = orders.map(x => {
                return {
                    orderNumber: !x.name ? "" : x.name,
                    orderName: `${!x.delivery_address ? "-" : (`${!x.delivery_address.firstname ? "-" : x.delivery_address.firstname} ${!x.delivery_address.lastname ? "" : x.delivery_address.lastname}`)}`,
                    orderEmail: !x.email ? "-" : x.email,
                    orderPhoneNumber: !x.delivery_address ? "-" : x.delivery_address.phone,
                    orderDateTime: !x.createdAt ? "-" : moment(x.createdAt).format("DD MMM YYYY"),
                    orderTax: !x.total_tax ? 0 : x.total_tax,
                    orderStatus: !x.fulfillment_status ? "-" : capitalizeFirstLetter(handleStatusStyle(x.fulfillment_status).text),
                    orderPaymentStatus: !x.financial_status ? "-" : capitalizeFirstLetter(handleStatusStyle(x.financial_status).text),
                    orderItems: x.line_item && x.line_item.length > 0 ? x.line_item : [],
                }
            })
            setProgress({
                count: 100,
                text: `Success`
            })
            exportTableToExcel(newArray, fileItem.nameFile);

            setFileItem({
                nameFile: `Selcare_${moment().format('YYYYMMDD')}`,
                month: OptionMonth.find(x => x.value === covertFormatMonth(currentMonth).toString()),
                status: {
                    label: 'All',
                    value: 'all'
                }
            })
        } else {
            setFileItem({
                nameFile: `Selcare_${moment().format('YYYYMMDD')}`,
                month: OptionMonth.find(x => x.value === covertFormatMonth(currentMonth).toString()),
                status: {
                    label: 'All',
                    value: 'all'
                }
            })
            setErrMsg({
                visible: !errMsg.visible,
                msg: 'No Data for selection month'
            })
        }
    }

    const progressBar = useMemo(() => {
        let obj = {
            count: parseInt(progress.count),
            text: progress.text
        }
        return obj
    }, [progress])

    useEffect(() => {
        if (progressBar.count === 100) {
            setProgress(prev => {
                return {
                    ...prev,
                    text: 'Success'
                }
            })
            setTimeout(() => {
                setProgress({
                    count: 0,
                    text: 'Export'
                })
            }, 1000);
        }
    }, [progressBar])

    const getOrder = useCallback((
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords,
    ) => {
        setLoading(true);
        setDataSource(prev => {
            return {
                ...prev,
                rows: [{
                    order_no: <div className="d-flex align-items-center text-muted"><Spinner size="sm" className="mr-2" color="primary" />Loading...</div>
                }]
            }
        })

        let params = "";
        let url = new URLSearchParams(params);

        url.set('_limit', limit)
        url.set('_start', page === 0 ? page : (page * 10) - 10)
        url.set('_sort', sortBy)

        switch (orderBy) {
            case 'pending':
                url.set('fulfillment_status', orderBy)
                break;
            case 'fulfilled':
                url.set('fulfillment_status', orderBy)
                break;
            case 'unfulfilled':
                url.set('fulfillment_status', orderBy)
                break;
            case 'refund':
                url.set('is_refunded', true)
                break;
            case 'flag':
                url.set('is_flag', true)
                break;
            default:
                break;
        }

        if (keywords) {
            url.set('name', keywords)
        }

        get(`/orders/count?${url.toString()}`).then(count => {
            get(`/orders?${url.toString()}`).then(res => {
                let data = [...res];
                const mapping = data.map((item, index) => {
                    return {
                        // index: page === 1 ? (index + 1) : (index + (10 * page) - 9),
                        order_no: <Link to={{ pathname: `${contentBody.path}/${item.id}` }} className="text-dark font-weight-bold">{!item.name ? "-" : item.name}</Link>,
                        name: `${!item.delivery_address ? "-" : !item.delivery_address.firstname ? "-" : capitalizeFirstLetter(item.delivery_address.firstname)} ${!item.delivery_address ? "" : !item.delivery_address.lastname ? "" : capitalizeFirstLetter(item.delivery_address.lastname)}`,
                        customer: !item.email ? "-" : item.email,
                        phoneNumber: !item.delivery_address ? "-" : item.delivery_address.phone,
                        total_price: !item.total_price ? "-" : `RM ${converPriceWithCommas(item.total_price)}`,
                        createdAt: !item.createdAt ? "-" : moment(item.createdAt).format("DD MMM YYYY"),
                        fulfillment_status: !item.fulfillment_status ? "-" : <div className={handleStatusStyle(item.fulfillment_status === 'pending' ? 'in_delivery' : item.fulfillment_status).badgeClass}>{item.fulfillment_status === "pending" ? 'In Delivery' : capitalizeFirstLetter(handleStatusStyle(item.fulfillment_status).text)}</div>,
                        financial_status: !item.financial_status ? "-" : <div className={handleStatusStyle(item.financial_status).badgeClass}>{capitalizeFirstLetter(handleStatusStyle(item.financial_status).text)}</div>,
                        clickEvent: () => {
                            if (props.history !== undefined || props.history !== null) {
                                return props.history.push(`${contentBody.path}/${item.id}`)
                            }
                        }
                    }
                })

                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    page,
                    total: count,
                    category,
                    keywords,
                })

                setDataSource(prev => {
                    return {
                        ...prev,
                        rows: mapping
                    }
                })
                setLoading(false)

            }).catch(() => {
                setLoading(false)
            })
        }).catch(() => {
            setLoading(false);
        })
    }, [props.history])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getOrder(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                initialValue.keywords
            )
        }

        return () => {
            mount = true
        }
    }, [getOrder])

    const handleSearching = (value) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                getOrder(
                    initialParams.sortBy,
                    initialParams.orderBy,
                    initialParams.limit,
                    0,
                    initialParams.total,
                    initialParams.category,
                    value,
                )
            }, 1000)
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>

                    <h4>{!contentBody.title ? "" : contentBody.title}</h4>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0" style={{ overflow: 'hidden' }}>

                                    <Nav tabs className="nav-tabs-custom mb-4">
                                        {TabOrder.map((item, index) => {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink disabled={loading} onClick={() => { handleTabFilter(item.key); }} className={classNames({ active: activeTab === item.key }, "font-weight-bold p-3")}>{item.name}</NavLink>
                                                </NavItem>
                                            )
                                        })}

                                        <div className='constainer-box-actions'>
                                            <div className="custom-searching-box mr-2">
                                                <Input
                                                    type="text"
                                                    placeholder="Search..."
                                                    onChange={e => handleSearching(e.target.value)}
                                                />
                                                <i className="ri-search-line"></i>
                                            </div>
                                            <div>
                                                <ButtonGroup className="mb-2">
                                                    <ButtonDropdown
                                                        isOpen={showDropdown}
                                                        toggle={() =>
                                                            setShowDropdown(!showDropdown)
                                                        }
                                                    >
                                                        <Button className="meter" id="caret" color={parseInt(progress.count) === 100 ? "success" : "primary"}>
                                                            <span className="animate-label">{progress.text}</span>
                                                            <span className="animate-bg-color" style={{ width: `${parseInt(progress.count)}%` }}></span>

                                                        </Button>
                                                        <DropdownToggle disabled={parseInt(progress.count) !== 0} tag="button" className={parseInt(progress.count) === 100 ? "btn btn-success" : "btn btn-primary"} style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4 }}>
                                                            <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="custom-dropdown-menu-right">
                                                            <DropdownItem onClick={() => setshowExportModal(!showExportModal)}><i className="ri-file-excel-2-line mr-2 align-middle"></i> Excel</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </ButtonGroup>
                                            </div>
                                        </div>


                                    </Nav>

                                    <ServerSidePaginations
                                        loading={loading}
                                        dataSource={dataSource}
                                        getItems={(params) => {
                                            getOrder(
                                                params.sortBy,
                                                params.orderBy,
                                                params.limit,
                                                params.page,
                                                params.total,
                                                params.category,
                                                params.keywords,
                                            )
                                        }}
                                        type="online_Store_order"
                                        emptyMessage={`There are no ${!contentBody.title ? "" : contentBody.title.toLowerCase()} yet`}
                                        obj={contentBody}
                                        initialParams={initialParams}
                                        setLoading={setLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>

                <Modal
                    isOpen={showExportModal}
                    toggle={() => setshowExportModal(!showExportModal)}
                >
                    <ModalHeader>
                        Export
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row className="mb-3">
                            <Label className="col-md-3 col-form-label pr-1">Name</Label>
                            <Col md={9}>
                                <Input value={fileItem.nameFile} onChange={e => {
                                    setFileItem({
                                        ...fileItem,
                                        nameFile: e.target.value
                                    })
                                }} />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-3">
                            <Label className="col-md-3 col-form-label pr-1">Status</Label>
                            <Col md={9}>
                                <Select
                                    options={OptionExportStatus}
                                    value={!fileItem.status ? null : fileItem.status}
                                    onChange={val => {
                                        setFileItem({
                                            ...fileItem,
                                            status: val
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-3">
                            <Label className="col-md-3 col-form-label pr-1">Month</Label>
                            <Col md={9}>
                                <Select
                                    options={OptionMonth}
                                    value={!fileItem.month ? null : fileItem.month}
                                    onChange={val => {
                                        setFileItem({
                                            ...fileItem,
                                            month: val
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row className="mb-3">
                            <Label className="col-md-3 col-form-label pr-1">Year</Label>
                            <Col md={9}>
                                <Select
                                    options={optionYear}
                                    value={!fileItem.year ? null : fileItem.year}
                                    onChange={val => {
                                        setFileItem({
                                            ...fileItem,
                                            year: val
                                        })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <div className="text-right">
                            <Button color="primary" onClick={downloadOrder}><i className="ri-file-excel-2-line mr-2 align-middle"></i> Excel</Button>
                        </div>
                    </ModalBody>
                </Modal>
                <AlertError
                    title="Error!"
                    setErrMsg={setErrMsg}
                    errMsg={errMsg}
                />
            </div>
        </React.Fragment>
    )
}

export default Order
