import React, { useEffect, useState } from 'react'
import { Button, CardBody, Col, Row } from 'reactstrap'
import { get } from '../../../../../../helpers/API'
import { capitalizeFirstLetter } from '../../../../../../store/CustomFunctions'

const ProviderBookingList = ({handleCheckType, item, index, isEditMode, handleAssigned, temporary }) => {
    const [selectedProvider, setSelectedProvider] = useState([])

    useEffect(() => {
        if (item.provider) {
            get(`/providers/${item.provider}`).then(res => {
                if (res) {
                    setSelectedProvider(res)
                }
            }).catch(err => console.log(err))
        }
    }, [item.provider])

    return (
        <div>
            <CardBody key={index} className='p-0 mb-4'>
                <div className='custom-card'>
                    <div className='custom-card-body size-img'>{!selectedProvider.fullname ? "-" : capitalizeFirstLetter(selectedProvider.fullname[0])}</div>
                    <div className='size-content'>
                        <Row>
                            <Col sm={12} md={8}>
                                <h6>{!selectedProvider.fullname ? "-" : selectedProvider.fullname}</h6>
                                <p className='text-muted mb-0'>{!selectedProvider.nurse_application ? "-" : handleCheckType(selectedProvider.nurse_application.registration_type)}</p>
                                <p className='text-muted'>{!selectedProvider.contact_number ? "-" : selectedProvider.contact_number}</p>
                            </Col>
                            <Col sm={12} md={4}>
                                {(isEditMode.mode && temporary && temporary.assignedData && temporary.assignedData.length === 0) &&
                                    <Button
                                        disabled={!isEditMode.mode}
                                        className="rs-float-right"
                                        color='primary'
                                        onClick={() => {
                                            handleAssigned(item.provider)
                                        }}
                                    >
                                        Assigned</Button>}
                            </Col>
                        </Row>
                    </div>
                </div>
            </CardBody>
        </div>
    )
}

export default ProviderBookingList
