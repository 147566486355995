import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, Spinner } from 'reactstrap'
import { GlobalContext } from '../../../../../store/PageWrapper/GlobalContext'
import { ACTION_TYPE } from '../../../../../store/prescriptions/ActionType'
import { PrescriptionContext } from '../../../../../store/prescriptions/PrescriptionData'
import { putPatient, putUser } from '../../../../../store/prescriptions/_api'

const ModalPatient = ({ visible, isClosed }) => {
    const { globalData } = useContext(GlobalContext)
    const { prescriptionDataLayer, dispatch } = useContext(PrescriptionContext);
    const [loading, setLoading] = useState(false)
    const [errorFullName, setErrorFullName] = useState(false)
    const [error, setError] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [validNumber, setValidNumber] = useState(false)
    const [formData, setFormData] = useState({
        fullName: '',
        nric: '',
        phone_number: '',
        email: '',
        remark: ''
    })
    const [errMsg, setErrMsg] = useState({
        msg: '',
        visible: ''
    })

    const onSubmit = async (patientData, userData) => {
        try {
            await putUser(userData, prescriptionDataLayer.patient.user.id).then(response => {
                setError(false);
                setErrorFullName(false);
                setErrMsg({
                    visible: false,
                    msg: ''
                })
                setLoading(false)
            }).catch(err => {
                setErrorFullName(true);
                setErrMsg({
                    visible: true,
                    msg: err.includes('username') ? 'Fullname already taken!' : err
                })
            })
            await putPatient(patientData, prescriptionDataLayer.patient.id).then(response => {
                dispatch({
                    type: ACTION_TYPE.SET_PATIENT,
                    payload: {
                        data: response,
                        role: globalData.global.role
                    }
                })
                isClosed();
            }).catch(err => {

            })
        } catch (error) {

        }
    }

    useEffect(() => {
        if (prescriptionDataLayer.patient) {
            setFormData({
                fullName: prescriptionDataLayer.patient.user && prescriptionDataLayer.patient.user.username,
                nric: prescriptionDataLayer.patient.nric,
                phone_number: prescriptionDataLayer.patient.default_phone,
                email: prescriptionDataLayer.patient.user && prescriptionDataLayer.patient.user.email,
                remark: prescriptionDataLayer.patient && prescriptionDataLayer.patient.remark
            })
        }
    }, [prescriptionDataLayer.patient])

    const updateForm = (field, value) => {

        if (field === 'phone_number') {
            checkNumber(value)
        } else if (field === 'email') {
            checkingEmail(value)
        }

        setFormData(prev => {
            return {
                ...prev,
                [field]: value
            }
        })
    }

    const checkingEmail = (value) => {
        var validRegex = /\S+@\S+\.\S+/;

        if (value) {
            if (value.match(validRegex)) {
                setValidEmail(false)
            } else {
                setValidEmail(true)
            }
        }
    }

    const checkNumber = (value) => {
        if (value) {
            var isANumber = isNaN(value) === false;
            if (!isANumber) {
                setValidNumber(true)
            } else {
                setValidNumber(false)
            }
        }
    }

    const checkingYear = (value) => {
        const currentYear = moment().format('YYYY')
        const lastNum = parseFloat(currentYear.slice(2, 4))
        if (lastNum > value) {
            return '20' + value
        } else {
            return '19' + value
        }
    }

    const checkingOddNum = (num) => {
        const length = num.length
        const lastIndex = num[length - 1]
        return parseInt(lastIndex) % 2
    }

    return (
        <Modal centered isOpen={visible}>
            <ModalBody>
                <h4 className='mb-4'>{`Patient Personal Info`}</h4>

                {errMsg.visible && <Alert color="danger">{errMsg.msg}</Alert>}
                <div>
                    <FormGroup className='mb-3'>
                        <Label>Full Name (Based on IC)</Label>
                        <Input disabled={loading} type="text" invalid={(error && !formData.fullName) || errorFullName} value={formData.fullName} onChange={(e) => updateForm('fullName', e.target.value)} />
                        {error && !formData.fullName && <FormFeedback>Please Insert your fullname</FormFeedback>}
                        {errorFullName && <FormFeedback>This Fullname is not available.</FormFeedback>}
                    </FormGroup>

                    <FormGroup className='mb-3'>
                        <Label>IC Number</Label>
                        <Input disabled={loading} min={12} type="text" invalid={error && !formData.nric} value={formData.nric} onChange={(e) => updateForm('nric', e.target.value)} />
                        {error && !formData.nric && <FormFeedback>Please Insert your IC number</FormFeedback>}
                    </FormGroup>

                    <FormGroup className='mb-3'>
                        <Label >Phone Number</Label>
                        <Input disabled={loading} type="text" invalid={(error && !formData.phone_number) || validNumber} value={formData.phone_number} onChange={(e) => updateForm('phone_number', e.target.value)} />
                        {validNumber && <FormFeedback>Phone Number must be a number</FormFeedback>}
                        {error && !formData.phone_number && <FormFeedback>Please Insert your phone number</FormFeedback>}
                    </FormGroup>

                    <FormGroup className='mb-3'>
                        <Label>Email</Label>
                        <Input disabled={loading} type="email" invalid={(error && !formData.email) || validEmail} value={formData.email} onChange={(e) => updateForm('email', e.target.value)} />
                        {validEmail && <FormFeedback>You have entered an invalid email address!</FormFeedback>}
                        {error && !formData.email && <FormFeedback>Please Insert your email</FormFeedback>}
                    </FormGroup>

                    <FormGroup className='mb-3'>
                        <Label>Remarks</Label>
                        <Input disabled={loading} type="textarea" invalid={(error && !formData.remark)} value={formData.remark} onChange={(e) => updateForm('remark', e.target.value)} />
                    </FormGroup>

                    <div className='text-right'>
                        <Button color='light' onClick={isClosed} className="mr-2">Cancel</Button>
                        <Button color='primary' disabled={loading} onClick={() => {
                            if (formData.fullName && formData.email && formData.phone_number && formData.nric && !validNumber && formData.remark &&
                                !validEmail) {
                                let d_year = formData.nric.toString().slice(0, 2)
                                let d_month = formData.nric.toString().slice(2, 4)
                                let d_day = formData.nric.toString().slice(4, 6)

                                let payloadUser = {
                                    username: formData.fullName
                                }
                                let payloadCustomer = {
                                    nric: formData.nric,
                                    default_phone: formData.phone_number,
                                    date_of_birth: moment(`${checkingYear(d_year)}-${d_month}-${d_day}`).add(1, 'days').toISOString(),
                                    gender: checkingOddNum(formData.nric) !== 0 ? 'male' : 'female',
                                    billing_address: {
                                        lastname: formData.fullName,
                                        phone: formData.phone_number,
                                        firstname: formData.fullName,
                                        email: formData.email
                                    },
                                    user: prescriptionDataLayer.patient.user.id,
                                    remark: formData.remark
                                }
                                onSubmit(payloadCustomer, payloadUser)
                            } else {
                                // setLoading(false)
                                setError(true)
                            }

                        }}>{loading ? <div className='align-middle'><Spinner className="align-middle mr-2" size={"sm"} />Loading...</div> : 'Save'}</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalPatient