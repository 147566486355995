import { TEXT_BUDHA, TEXT_CHINESE, TEXT_CHRISTIAN, TEXT_ENGLISH, TEXT_FEMALE, TEXT_HINDU, TEXT_INDIAN, TEXT_ISLAM, TEXT_MALAY, TEXT_MALE, TEXT_MANDARIN, TEXT_OTHERS, TEXT_TAMIL } from "../../../../../Text";
import moment from 'moment'
import { AdditionalTreatmentRequired, initialTypeOfService, intialAssessment } from "./AssessmentData";

export const optionFee = [
    {
        label: "Default",
        value: "default"
    },
    {
        label: "Custom",
        value: "custom"
    }
]

export const OptionHonorific = [
    {
        label: "Mr",
        value: "Mr"
    },
    {
        label: "Mrs",
        value: "Mrs"
    }
]

export const optionRate = [
    {
        label: '0-4 HRS',
        value: '0-4 HRS',
        price: 35,
        totalHours: 4
    },
    {
        label: 'ADD HR (4-12 HRS)',
        value: 'ADD HR (4-12 HRS)',
        price: 30,
        totalHours: 12
    },
    {
        label: 'DAY RATE HR (0-24 HRS)',
        value: 'DAY RATE HR (0-24 HRS)',
        price: 20,
        totalHours: 24
    },
    {
        label: 'WEEK RATE HR  (7 DAYS) 12Hrs',
        value: 'WEEK RATE HR  (7 DAYS) 12Hrs',
        price: 18,
        totalHours: 12
    },
    {
        label: 'WEEK RATE HR (7 DAYS) 24Hrs',
        value: 'WEEK RATE HR (7 DAYS) 24Hrs',
        price: 14,
        totalHours: 24
    },
    {
        label: 'MONTH (5DYS/WEEK) 12Hrs',
        value: 'MONTH (5DYS/WEEK) 12Hrs',
        price: 12,
        totalHours: 12
    },
    {
        label: 'MONTH (6DYS/WEEK) 12Hrs',
        value: 'MONTH (6DYS/WEEK) 12Hrs',
        price: 11.8,
        totalHours: 12
    },
    {
        label: 'MONTH (7DYS/WEEK) 12Hrs',
        value: 'MONTH (7DYS/WEEK) 12Hrs',
        price: 11.5,
        totalHours: 12
    },
    {
        label: 'MONTH (5DYS/WEEK) 24Hrs',
        value: 'MONTH (5DYS/WEEK) 24Hrs',
        price: 10,
        totalHours: 24
    },
    {
        label: 'MONTH (6DYS/WEEK) 24Hrs',
        value: 'MONTH (6DYS/WEEK) 24Hrs',
        price: 9.8,
        totalHours: 24
    },
    {
        label: 'MONTH (7DYS/WEEK) 24Hrs',
        value: 'MONTH (7DYS/WEEK) 24Hrs',
        price: 9.5,
        totalHours: 24
    },
    {
        label: 'Custom',
        value: 'Custom',
        price: 0,
        totalHours: 0
    }
]

export const optionGender = [
    { label: TEXT_MALE, value: "male" },
    { label: TEXT_FEMALE, value: "female" },
];

export const optionLanguage = [
    { label: TEXT_MALAY, value: "malay" },
    { label: TEXT_ENGLISH, value: "english" },
    { label: TEXT_MANDARIN, value: "mandarin" },
    { label: TEXT_TAMIL, value: "tamil" },
]

export const optionReligion = [
    { label: TEXT_ISLAM, value: "islam" },
    { label: TEXT_BUDHA, value: "budha" },
    { label: TEXT_HINDU, value: "hindu" },
    { label: TEXT_CHRISTIAN, value: "christian" },
    { label: TEXT_OTHERS, value: "others" },
]

export const optionRace = [
    { label: TEXT_MALAY, value: "malay" },
    { label: TEXT_CHINESE, value: "chinese" },
    { label: TEXT_INDIAN, value: "indian" },
    { label: TEXT_OTHERS, value: "others" },
]

export const TabArr = [
    {
        key: 'RegisterBy',
        title: 'Register By'
    },
    {
        key: 'Patient',
        title: 'Patient'
    },
    {
        key: 'Assessment',
        title: 'Assessment'
    },
    {
        key: 'Schedule',
        title: 'Schedule'
    },
    {
        key: 'Provider',
        title: 'Provider'
    },
    {
        key: 'Billing',
        title: 'Billing'
    },
    {
        key: 'Feedback',
        title: 'Feedback'
    }
]

export const TabArr2 = [
    {
        key: 'Patient',
        title: 'Patient'
    },
    {
        key: 'Schedule',
        title: 'Schedule'
    },
    {
        key: 'Billing',
        title: 'Billing'
    }
]

export const fakeData = [
    {
        id: moment().format('DDmmyyyyhhmmss').toLocaleString(),
        identification: true,
        refId: 'D79182310',
        booking: 'Site visit',
        paymentStatus: 'pending',
        assignedTo: 'alex',
        status: 'pending',
        date_submited: moment().format('DD MMM YYYY').toLocaleString(),
        name: 'John',
        nric: "891223103212",
        gender: "male",
        race: "malay",
        religion: "islam",
        phone_number: '0129922112',
        alternate_phoneNumber: "0129922112",
        email: "John@example.com",
        patientDiagnosis: "-",
        height: "180",
        weight: "60",
        note: "headache",
        patient: {
            name: 'Alex',
            identification: true,
            gender: 'male',
            race: 'malay',
            religion: 'islam',
            nric: {
                no: '92091291012912'
            },
            phone_number: '0129922112',
            alternate_phoneNumber: "0129922112",
            email: "alex@example.com",
            patientDiagnosis: "-",
            height: "180",
            weight: "60",
            note: "headache",
            street_name_one: "F-12",
            street_name_two: "Petaling Jaya",
            state: "Selangor",
            city: "Petaling Jaya",
            postcode: "40170",
            allergies: false,
            allergiesNote: '',
            psh: false,
            pmh: true
        },
        assessment: {
            type_of_service: initialTypeOfService,
            additional_treatment: AdditionalTreatmentRequired,
            assessment_data: intialAssessment
        }
    }
]
