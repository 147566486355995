
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, FormGroup, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { resetUser, userResetPasswordError, resetUserSuccessful } from '../../store/actions';

// import images
import logodark from "../../assets/images/selcare.png";

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        this.props.userResetPasswordError('');
        this.props.resetUserSuccessful('')
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        if (this.props.match.params && this.props.match.params.id) {
            let data = {
                ...values,
                code: this.props.match.params.id
            }
            this.props.resetUser(data, this.props.history);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div>
                <div>
                    <Container fluid className="p-0">
                        <Row className="no-gutters">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/" className="logo"><img src={logodark} height="100" alt="logo" /></Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                        {/* <p className="text-muted">Reset your password to Selcare.</p> */}
                                                    </div>

                                                    <div className="p-2 mt-5">
                                                        {this.props.resetError && this.props.resetError ?
                                                            <Alert color="danger" className="mb-4">{this.props.resetError}</Alert> : null}
                                                        {!this.props.resetError && this.props.message ?
                                                            <Alert color="success" className="mb-4">{this.props.message}</Alert> : null
                                                        }
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="password">Password</Label>
                                                                <AvField
                                                                    name="password"
                                                                    value={this.state.password}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="password"
                                                                    placeholder="Enter password"
                                                                    validate={{
                                                                        required: { value: true, errorMessage: 'Please enter a password' },
                                                                        minLength: { value: 6, errorMessage: 'Your name must be between 6 and 16 characters' },
                                                                        maxLength: { value: 16, errorMessage: 'Your name must be between 6 and 16 characters' }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="passwordConfirmation">Confirm Password</Label>
                                                                <AvField
                                                                    name="passwordConfirmation"
                                                                    value={this.state.passwordConfirmation}
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="passwordConfirmation"
                                                                    placeholder="Enter password confirmation"
                                                                    validate={{
                                                                        required: { value: true, errorMessage: 'Please enter a password confirmation' },
                                                                        minLength: { value: 6, errorMessage: 'Your name must be between 6 and 16 characters' },
                                                                        maxLength: { value: 16, errorMessage: 'Your name must be between 6 and 16 characters' },
                                                                        match:{value:'password', errorMessage: 'Password are not matching'}
                                                                    }}
                                                                />
                                                            </FormGroup>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" disabled={this.props.loading} style={{ background: '#0B5FFF' }} className="w-md waves-effect waves-light" type="submit">{this.props.loading ? "Loading..." : "Reset"}</Button>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>Don't have an account ? <Link to="/login" className="font-weight-medium text-primary"> Log in </Link> </p>
                                                        {/* <p>© 2020 Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p> */}
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, resetError, loading } = state.Reset;
    return { message, resetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { resetUser, userResetPasswordError, resetUserSuccessful })(ResetPasswordPage)
);
