import React, { useCallback, useContext, useEffect } from "react";
import { Button } from "reactstrap";
import { GlobalContext } from "./GlobalContext";

const PageWrapper = ({ children }) => {
    const { globalData, fetchingUserData } = useContext(GlobalContext);

    const getUser = useCallback(() => {
        if (globalData.global.user === null) {
            fetchingUserData();
        }
    }, [globalData, fetchingUserData])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getUser();
        }

        return () => {
            mount = true
        }
    }, [getUser])

    return (
        <div>
            <div className="position-fixed top-0 right-0 p-3" style={{ zIndex: 5, right: 0, top: 65 }}>
                <div id="liveToast" className="toast hide rounded border-0" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
                    <div className={globalData.global.availability_doctor ? "bg-success toast-body rounded text-white" : "bg-danger toast-body rounded text-white"}>
                        {globalData.global.availability_doctor ? `You're online now!` : `You're offline now!`}
                    </div>
                </div>
                {globalData.global.notification.visibility && globalData.global.notification.list ?
                    <div id="liveToast2" className="toast show rounded overflow-hidden border-0" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
                        <div className="toast-header"><i className="ri-notification-line align-middle mr-1"></i>Notification {`(${globalData.global.notification.list.id})`}</div>
                        <div className="bg-white toast-body text-dark">
                            <h6 className={globalData.global.notification.list.status === 'pending' ? 'text-primary' : 'text-danger'}>{globalData.global.notification.list.status === 'pending' ? 'New Telemedicine!' : 'Cancelled Telemedicine'}</h6>
                            {globalData.global.notification.list.status === 'pending' ? <p className="mb-1">New telemedicine request{!globalData.global.notification.list.customer ? '.' : !globalData.global.notification.list.customer.full_name ? '.' : ` from ${globalData.global.notification.list.customer.full_name}.`} Please press the button below to view details and accept.</p> : <p>Telemedicine request{!globalData.global.notification.list.customer ? '.' : !globalData.global.notification.list.customer.full_name ? '.' : ` from ${globalData.global.notification.list.customer.full_name}.`} has been cancelled.</p>}
                        </div>
                        {globalData.global.notification.list.status === 'pending' ? <Button
                            color="light"
                            size="sm"
                            className="mr-2 mb-2 float-right"
                            onClick={() => {
                                window.location = `/tele-medicine/consultation/${globalData.global.notification.list.id}`;
                            }}>
                            View Telemedicine
                        </Button> : null}
                    </div>
                    : null}
            </div>
            {children}
        </div>
    )
}

export default PageWrapper
