// import moment from 'moment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import { get, getAPIroot } from '../../../../../helpers/API'

const OptionsDropdown = ({ option, selected, handleSelect, show }) => {
    return show && <div className="position-absolute w-100 bg-white shadow mt-2 custom-dropItem">
        <ul className="list-group shadow-0">
            {option.map((x, i) => {
                return <li
                    onClick={() => handleSelect(x.label)}
                    className={selected === x.label ? "list-group-item cursor-p active text-white" : "list-group-item cursor-p "}
                    key={i}>
                    {x.label}
                </li>
            })}
        </ul>
    </div>
}

const ModalDiagnosis = ({ visible, isClosed, selectedItem, onSubmitDiagnosis, onSubmitUpdateDiagnosis }) => {
    const [option, setOption] = useState([])
    const [show, setShow] = useState(false)
    const [dataSource, setDataSource] = useState([]);
    const [searching, setSearching] = useState('')
    const [formValue, setFormValue] = useState({
        icd: "",
        remarks: ""
    })

    useEffect(() => {
        let isMounted = true

        get(`/diagnosis-setting`).then(response => {
            const currentYear = moment().format('YYYY');
            const data = response.icd_list && response.icd_list.length > 0 && response.icd_list;
            
            let filterListCurrentYear = data && data.find(x => x.year === currentYear)
            if (!filterListCurrentYear) filterListCurrentYear = data.find(x => x.year === 2022);

            const fileUrl = filterListCurrentYear.icd_json_file && filterListCurrentYear.icd_json_file.url
            const jsonURL = getAPIroot() + fileUrl;

            if (isMounted && jsonURL) {
                loadJSON(jsonURL, 'jsonp', isMounted);
            }
        })

        return () => isMounted = false
    }, [])

    const loadJSON = (path, success, error) => {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText)
                    // setLoading(false)
                    const mapping = data.map(x => {
                        return {
                            title: x.title,
                            code: x.code
                        }
                    })
                    setDataSource(mapping)
                }
                else {
                    error(xhr);
                }
            }
        };
        xhr.open('GET', path, true);
        xhr.send();
    }

    useEffect(() => {
        let isMounted = true;

        if (selectedItem) {
            if (isMounted) {
                setFormValue(prev => {
                    return {
                        ...prev,
                        icd: selectedItem.icd,
                        remarks: selectedItem.remarks
                    }
                })
                setSearching(selectedItem.icd)
            }
        }

        return () => isMounted = false
    }, [selectedItem])

    const handleSearching = (value) => {
        setSearching(value)
        if (value.length > 0) {
            setShow(true)
            const data = dataSource
            const filter = data.map(x => { return { ...x, title: `${x.code} - ${x.title}` } }).filter(x => x.title.includes(value));
            // console.log(dataSource);
            const mapping = filter.map(x => {
                return {
                    label: x.title,
                    value: x.title,
                }
            })

            setOption(mapping.filter((x, i) => i < 10))
        } else {
            setShow(false)
        }
    }

    const updateForm = (field, value) => {
        setFormValue(prev => {
            return {
                ...prev,
                [field]: value,
            }
        })
    }

    const handleSubmit = () => {
        isClosed()
        if (formValue.icd) {
            onSubmitDiagnosis(formValue);
            reset()
        }
    }

    const handleUpdate = () => {
        let data = {
            ...formValue,
            index: selectedItem.index
        }
        if (formValue.icd) {
            onSubmitUpdateDiagnosis(data)
            reset()
        }
    }

    const reset = () => {
        setFormValue({
            icd: "",
            remarks: ""
        })
        setSearching('')
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={visible}
            >
                <ModalBody>
                    <h4>{selectedItem ? 'Update Diagnosis' : 'Add Diagnosis'}</h4>

                    <div>
                        <FormGroup>
                            <Label>Search ICD</Label>
                            <div className='position-relative d-block'>
                                <Input
                                    onChange={e => {
                                        handleSearching(e.target.value)
                                        setSearching(e.target.value)
                                        updateForm('icd', e.target.value)

                                    }}
                                    value={searching}
                                />
                                <OptionsDropdown
                                    option={option}
                                    show={show}
                                    selected={formValue.icd}
                                    handleSelect={(value) => {
                                        setShow(false)
                                        updateForm('icd', value)
                                        setSearching(value)
                                    }}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label>Remark</Label>
                            <Input
                                type='textarea'
                                value={formValue.remarks}
                                onChange={e => {
                                    updateForm('remarks', e.target.value)
                                }} />
                        </FormGroup>
                    </div>

                    <div className='d-flex justify-content-between'>
                        <Button
                            color='light'
                            className='w-50 mr-2'
                            onClick={() => {
                                isClosed()
                                reset()
                            }}>Cancel</Button>
                        <Button
                            color='primary'
                            className='w-50'
                            onClick={() => {
                                if (selectedItem) {
                                    handleUpdate()
                                } else {
                                    handleSubmit()
                                }
                            }}
                        >{selectedItem ? 'Save' : 'Add'}
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default ModalDiagnosis