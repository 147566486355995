import React, { useContext, useEffect, useState } from 'react'
import { Container, Modal, ModalHeader, ModalBody, Button, ModalFooter, Spinner } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { GlobalContext } from '../../store/PageWrapper/GlobalContext';
import PageWrapper from '../../store/PageWrapper/PageWrapper';
import { get, getAPIroot, update } from '../../helpers/API';
// import useLocalStorage from '../../helpers/useLocalStorage';
import { TEXT_PROVIDER } from '../../Text';
import CurrentEmployment from './CurrentEmployment';

import PersonelInformation from './PersonelInformation';
import PreviousEmployment from './PreviousEmployment';
import Reference from './Reference';
import WorkExperience from './WorkExperience';

const ProviderDetailPage = (props) => {
    const pathname = window.location.pathname.split('/')

    let breadcrumbItems = []
    if (props.history.location.state && props.history.location.state.from) {
        breadcrumbItems = [
            { title: TEXT_PROVIDER, link: `/${pathname[1]}` },
            { title: "Details", link: "#" },
        ]
    } else {
        breadcrumbItems = [
            { title: "Applications", link: "#" },
            { title: TEXT_PROVIDER, link: `/${pathname[1]}/${pathname[2]}` },
            { title: "Details", link: "#" },
        ]
    }

    const { globalData } = useContext(GlobalContext)
    const { fetchingProviderCount } = useContext(GlobalContext)
    const [dataSource, setDataSource] = useState([])
    const [visibleModal, setVisibleModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imgData, setImgData] = useState({
        imgURL: null,
        title: ""
    })

    useEffect(() => {
        setLoading(true)
        if (props.match.params && props.match.params.id) {
            get(`/nurse-applications/${props.match.params.id}`).then(res => {
                setDataSource(res)
                setLoading(false)
            })
        }
    }, [props.match.params])

    const handleViewFile = (imgURL, title) => {
        if (imgURL !== undefined && imgURL !== null) {
            const url = getAPIroot() + imgURL
            if (imgURL.includes('.jpeg') ||
                imgURL.includes('.png') ||
                imgURL.includes('.jpg')) {
                setImgData({
                    imgURL: url,
                    title
                })

                tog_center()
            } else {
                window.open(url)
            }
        }
    }

    const tog_center = () => {
        setVisibleModal(true)
    }

    const handleApplication = async (id, type) => {
        let user = globalData.global.user;
        let data = {
            status: type,
            checked_by: user.id
        }

        if (id && data.checked_by) {
            const res = await update(`/nurse-applications/${id}`, data)
            if (res) {
                props.history.push('/application/provider');
                // globalData.setFetching(true)
                fetchingProviderCount()
            }
        }
    }

    return (
        <React.Fragment>
            <PageWrapper>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={TEXT_PROVIDER} breadcrumbItems={breadcrumbItems} />
                        {loading ? <div style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40 }}>
                            <Spinner className="mr-2" color="primary" />
                        </div> :
                            <div>
                                <PersonelInformation dataSource={dataSource} handleViewFile={handleViewFile} handleApplication={handleApplication} setDataSource={setDataSource} isProviderApproved={props.match.url} />
                                <CurrentEmployment dataSource={dataSource} handleViewFile={handleViewFile} />
                                <PreviousEmployment dataSource={dataSource} handleViewFile={handleViewFile} />
                                <WorkExperience dataSource={dataSource} handleViewFile={handleViewFile} />
                                <Reference dataSource={dataSource} handleViewFile={handleViewFile} />
                            </div>}


                        <Modal
                            size="lg"
                            isOpen={visibleModal}
                            toggle={tog_center}
                        >
                            <ModalHeader toggle={() => setVisibleModal(false)}>
                                {imgData.title}
                            </ModalHeader>
                            <ModalBody className="p-0">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f1f5f7" }}>
                                    <img src={imgData.imgURL} alt="img" height="100%" width="100%" />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => setVisibleModal(false)}>Close</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </PageWrapper>
        </React.Fragment>
    )
}

export default ProviderDetailPage
