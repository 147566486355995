import React, { useCallback, useEffect, useState } from 'react'
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, Button, Input } from "reactstrap";
import { TEXT_PRODUCT } from '../../../Text';
import classnames from 'classnames';
import { get, post } from '../../../helpers/API';

import "../../Templete/Tables/Styles/datatables.scss";
import "../../Templete/Tables/Styles/customStyle.scss";
import { initialDataTable } from './tableColumn';
import { ServerSideEditablePaginations } from '../../../components/Table/ServerSideEditablePaginations';
import { ProductModal } from './ProductModal';
import { ProductSendToPromo } from './ProductSendToPromo';
import AlertSuccess from '../../CustomComponent/Alert/AlertSuccess';

let initialValue = {
    sortBy: 'createdAt:DESC',
    orderBy: 'all',
    limit: 20,
    page: 0,
    total: 0,
    category: '',
    keywords: ''
}
const obj = {
    path: '/store/products',
    title: "Products",
    filter: "Products"
}
const Product = (props) => {
    const [timer, setTimer] = useState(null);
    const [dataSource, setDataSource] = useState(initialDataTable)
    const [activeTab, setActiveTab] = useState(initialValue.orderBy);
    const [loading, setLoading] = useState(() => false);
    const [contentBody] = useState(obj)
    const [initialParams, setInitialParams] = useState(initialValue)
    const [visibleModalAddProduct, setVisibleModalAddProduct] = useState(false);
    const [selectedItem, setSelectedItem] = useState({})
    const [listOfSelectedProduct, setListOfSelectedProduct] = useState([])
    const [visibleModalSelection, setvisibleModalSelection] = useState(false)
    const [visiblePagination, setVisiblePagination] = useState(true)
    const [sweetTimmer, setSweetTimmer] = useState({
        status: false,
        title: ""
    })

    const getProducts = useCallback((
        sortBy,
        orderBy,
        limit,
        page,
        total,
        category,
        keywords) => {
        setLoading(true);
        setDataSource({
            ...initialDataTable,
            rows: []
        })
        let params = '';
        let url = new URLSearchParams(params)
        let url2 = new URLSearchParams(params)

        url.set('_limit', limit)
        url2.set('_limit', limit)
        url.set('_start', page === 0 ? page : (page * 10) - 10)
        url2.set('_start', page === 0 ? page : (page * 10) - 10)
        url.set('_sort', sortBy)
        url2.set('_sort', sortBy)
        url.set('filter', 'all')

        if (keywords) {
            url.set('title', keywords)
            url2.set('title', keywords)
        }

        if (orderBy !== 'all') {
            url.set(orderBy, true)
            url2.set('title', keywords)
        }

        get(`/products/count?${url2.toString()}`).then(count => {
            get(`/products?${url.toString()}`).then(res => {
                setInitialParams({
                    sortBy,
                    orderBy,
                    limit,
                    page,
                    total: count,
                    category,
                    keywords,
                })
                setDataSource({
                    ...initialDataTable,
                    rows: res.products
                })
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        })
    }, [])

    useEffect(() => {
        let mount = false;

        if (!mount) {
            getProducts(
                initialValue.sortBy,
                initialValue.orderBy,
                initialValue.limit,
                initialValue.page,
                initialValue.total,
                initialValue.category,
                initialValue.keywords
            )
        }

        return () => {
            mount = true
        }
    }, [getProducts])

    const handleSearching = (value) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setLoading(true)
        setTimer(
            setTimeout(() => {
                if (value === "") {
                    getProducts(
                        initialParams.sortBy,
                        initialParams.orderBy,
                        initialParams.limit,
                        0,
                        initialParams.total,
                        initialParams.category,
                        value
                    )
                    setVisiblePagination(true)
                } else {
                    setVisiblePagination(false)
                    post(`/products/search`, { keyword: value }).then(response => {
                        setDataSource({
                            ...initialDataTable,
                            rows: response.products
                        })
                        setLoading(false)
                    }).catch(() => { })
                }
            }, 1000)
        );
    }

    const handleTab = (key) => {
        setActiveTab(key);

        getProducts(
            initialParams.sortBy,
            key,
            initialParams.limit,
            0,
            initialParams.total,
            initialParams.category,
            initialParams.keywords
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <h4>{TEXT_PRODUCT}</h4>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0">
                                    <Nav tabs className="nav-tabs-custom mb-4">
                                        <NavItem>
                                            <NavLink onClick={() => { handleTab('all'); }} className={classnames({ active: activeTab === 'all' }, "font-weight-bold p-3")}>All</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { handleTab('is_sold_out'); }} className={classnames({ active: activeTab === 'is_sold_out' }, "font-weight-bold p-3")}>Out of stock</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { handleTab('is_hidden'); }} className={classnames({ active: activeTab === 'is_hidden' }, "font-weight-bold p-3")}>Hidden</NavLink>
                                        </NavItem>

                                        <div className='constainer-box-actions'>
                                            <div className="custom-searching-box mr-2">
                                                <Input
                                                    type="text"
                                                    placeholder="Search..."
                                                    onChange={e => handleSearching(e.target.value)}
                                                />
                                                <i className="ri-search-line"></i>
                                            </div>
                                            <div className='child-box'>

                                                <Button
                                                    color="light"
                                                    className=" mr-2"
                                                    style={{ position: "relative", opacity: listOfSelectedProduct && listOfSelectedProduct.length === 0 && ".3" }}
                                                    disabled={listOfSelectedProduct && listOfSelectedProduct.length === 0}
                                                    onClick={() => setvisibleModalSelection(!visibleModalSelection)}
                                                >
                                                    <i className="align-middle ri-list-check-2" />
                                                    {listOfSelectedProduct.length > 0 &&
                                                        <span
                                                            className="badge badge-pill badge-danger align-middle"
                                                            style={{
                                                                position: "absolute",
                                                                top: "-8px",
                                                                left: "-8px"
                                                            }}
                                                        >{listOfSelectedProduct.length}</span>
                                                    }
                                                </Button>
                                                <Button
                                                    color="light"
                                                    className="waves-effect ml-1"
                                                    onClick={() => setVisibleModalAddProduct(!visibleModalAddProduct)}
                                                    style={{ width: 180 }}
                                                >
                                                    <i className="align-middle ri-add-fill mr-2" />Add New product
                                                </Button>
                                            </div>
                                        </div>
                                    </Nav>


                                    <ServerSideEditablePaginations
                                        loading={loading}
                                        visiblePagination={visiblePagination}
                                        getItems={(params) => {
                                            getProducts(
                                                params.sortBy,
                                                params.orderBy,
                                                params.limit,
                                                params.page,
                                                params.total,
                                                params.category,
                                                params.keywords
                                            )
                                        }}
                                        dataSource={dataSource}
                                        emptyMessage={`There are no ${!contentBody.title ? "" : contentBody.title.toLowerCase()} yet`}
                                        obj={contentBody}
                                        initialParams={initialParams}
                                        setLoading={setLoading}
                                        setDataSource={setDataSource}
                                        setVisibleModalAddProduct={setVisibleModalAddProduct}
                                        visibleModalAddProduct={visibleModalAddProduct}
                                        setSelectedItem={setSelectedItem}
                                        listOfSelectedProduct={listOfSelectedProduct}
                                        setListOfSelectedProduct={setListOfSelectedProduct}
                                    />

                                    <ProductModal
                                        visibleModalAddProduct={visibleModalAddProduct}
                                        setVisibleModalAddProduct={setVisibleModalAddProduct}
                                        initialParams={initialParams}
                                        getProducts={getProducts}
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                    />

                                    <ProductSendToPromo
                                        visibleModalSelection={visibleModalSelection}
                                        setvisibleModalSelection={setvisibleModalSelection}
                                        listOfSelectedProduct={listOfSelectedProduct}
                                        setListOfSelectedProduct={setListOfSelectedProduct}
                                        setSweetTimmer={setSweetTimmer}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <AlertSuccess
                        title={sweetTimmer.title}
                        sweetTimmer={sweetTimmer.status}
                        setSweetTimmer={() => {
                            setSweetTimmer({
                                title: "",
                                status: !sweetTimmer
                            })
                        }}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Product
